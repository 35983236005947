import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useUpdateUserMutation,// Updated import
} from "../redux/api/userApi";
import { useUpdatePasswordByAdminMutation } from "../redux/api/authApi";
import {
  useAddUserToDepartmentMutation,
  useGetDepartmentsQuery,
  useRemoveUserFromDepartmentMutation,
} from "../redux/api/departmentsApi";
import DropdownRoles from "./Dropdown/DropdownRoles";
import DropdownDepartments from "../components/Dropdown/DropdowDepartments";
import { notify } from "./utils/notificationManager";

const FormEditUserManagement = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [theme2, setTheme2] = useState(localStorage.getItem("theme"));
  const [
    removeUserFromDepartment,
    {
      isLoading: isRemovingFromDepartment,
      error: removeDepartmentError,
      data: removeDepartmentData,
    },
  ] = useRemoveUserFromDepartmentMutation();
  const {
    data: departmentsData,
    error: departmentsError,
    isLoading: isLoadingDepartments,
  } = useGetDepartmentsQuery();

  useEffect(() => {
    const handleStorageChange = () => {
      setTheme2(localStorage.getItem("theme"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleOpen = () => {
    initializeForm(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "85%",
    bgcolor: theme2 === "dark" ? "#2B2A49" : "#FAFAFE",
    boxShadow: 24,
    p: 4,
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    department: "",
    role: "",
  });

  const [passwordFormData, setPasswordFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const { name, email, department, role } = formData;
  const { newPassword, confirmPassword } = passwordFormData;

  const [
    updateUser,
    { isLoading: isUpdatingUser, error: userError, data: userData },
  ] = useUpdateUserMutation();
  const [
    updatePassword,
    {
      isLoading: isUpdatingPassword,
      error: passwordError,
      data: passwordResponseData,
    },
  ] = useUpdatePasswordByAdminMutation(); // Updated mutation hook

  const [
    addUserToDepartment,
    {
      isLoading: isAddingToDepartment,
      error: departmentError,
      data: departmentData,
    },
  ] = useAddUserToDepartmentMutation();

  useEffect(() => {
    if (userError) {
      notify(userError?.data?.message, 2, null, null, true);
    }
  }, [userError]);

  useEffect(() => {
    if (userData) {
      notify("User updated successfully!", 0, null, null, true);
      handleClose();
    }
  }, [userData]);

  useEffect(() => {
    if (passwordError) {
      notify(passwordError?.data?.message, 2, null, null, true);
    }
  }, [passwordError]);

  useEffect(() => {
    if (passwordResponseData) {
      notify("Password updated successfully!", 0, null, null, true);
      handleClose();
    }
  }, [passwordResponseData]);

  useEffect(() => {
    if (departmentError) {
      notify(departmentError?.data?.message, 2, null, null, true);
    }
  }, [departmentError]);

  useEffect(() => {
    if (departmentData) {
      notify("User added to department successfully!", 0, null, null, true);
    }
  }, [departmentData]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const updatedData = {
      name,
      email,
      department,
      role,
    };

    const departmentsArray = Array.isArray(departmentsData)
      ? departmentsData
      : departmentsData?.departments;

    if (!departmentsArray || !Array.isArray(departmentsArray)) {
      notify(
        "Departments data is not in the expected format",
        2,
        null,
        null,
        true
      );

      return;
    }

    const selectedDepartment = departmentsArray.find(
      (dept) => dept.departmentName === department
    );

    if (!selectedDepartment) {
      notify("Invalid department selected", 2, null, null, true);
      return;
    }
    const departmentId = selectedDepartment._id;
    // Remove user from current department
    if (matchingDepartment && matchingDepartment._id !== departmentId) {
      await removeUserFromDepartment({
        id: matchingDepartment._id,
        userId: user._id,
      });
    }

    await updateUser({ id: user._id, body: updatedData });
    await addUserToDepartment({ id: departmentId, userId: user._id });
  };
  
  const passwordSubmitHandler = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      notify("Passwords do not match", 2, null, null, true);
      return;
    }
    const passwords = {
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    updatePassword({ id: user._id, body: passwords }); // Corrected the payload
  };
  
  const onPasswordChange = (e) => {
    setPasswordFormData({
      ...passwordFormData,
      [e.target.name]: e.target.value,
    });
  };
  

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const setDepartment = (department) => {
    setFormData({ ...formData, department });
  };

  const setRole = (role) => {
    setFormData({ ...formData, role });
  };

  const matchingDepartment = departmentsData?.departments.find((department) =>
    department.departmentUsers.includes(user._id)
  );

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      department: "",
      role: "",
    });
    setPasswordFormData({
      newPassword: "",
      confirmPassword: "",
    });
  };

  const initializeForm = (user) => {
    setFormData({
      name: user.name || "",
      email: user.email || "",
      department: matchingDepartment?.departmentName || "",
      role: user.role || "",
    });
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        style={{
          padding: 0,
          minWidth: "auto",
          background: "none",
          boxShadow: "none",
        }}
      >
        <img
          src="/images/edit-usermanagement.svg"
          alt=""
          width={20}
          height={20}
        />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="flex justify-between mb-[34px]">
              <p className="text-[#4A488E] dark:text-[#EDEDFB]">Edit user</p>

              <svg
                width="30"
                height="30"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={handleClose}
              >
                <g id="ph:x">
                  <path
                    id="Vector"
                    d="M29.724 28.0884C29.8314 28.1958 29.9166 28.3233 29.9747 28.4637C30.0329 28.604 30.0628 28.7545 30.0628 28.9064C30.0628 29.0583 30.0329 29.2088 29.9747 29.3491C29.9166 29.4895 29.8314 29.617 29.724 29.7245C29.6165 29.8319 29.489 29.9171 29.3486 29.9752C29.2083 30.0334 29.0578 30.0633 28.9059 30.0633C28.754 30.0633 28.6036 30.0334 28.4632 29.9752C28.3228 29.9171 28.1953 29.8319 28.0879 29.7245L18.4997 20.1348L8.91146 29.7245C8.6945 29.9414 8.40024 30.0633 8.09342 30.0633C7.78659 30.0633 7.49233 29.9414 7.27537 29.7245C7.05841 29.5075 6.93652 29.2132 6.93652 28.9064C6.93652 28.5996 7.05841 28.3053 7.27537 28.0884L16.865 18.5002L7.27537 8.91195C7.05841 8.69499 6.93652 8.40073 6.93652 8.0939C6.93652 7.78708 7.05841 7.49282 7.27537 7.27586C7.49233 7.0589 7.78659 6.93701 8.09342 6.93701C8.40024 6.93701 8.6945 7.0589 8.91146 7.27586L18.4997 16.8655L28.0879 7.27586C28.3048 7.0589 28.5991 6.93701 28.9059 6.93701C29.2127 6.93701 29.507 7.0589 29.724 7.27586C29.9409 7.49282 30.0628 7.78708 30.0628 8.0939C30.0628 8.40073 29.9409 8.69499 29.724 8.91195L20.1343 18.5002L29.724 28.0884Z"
                    fill="#4A488E"
                    className={`fill-current dark:text-[#EDEDFB]`}
                  />
                </g>
              </svg>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form
              onSubmit={submitHandler}
              className="flex gap-[16px] flex-col mb-[10px]"
            >
              <div className="mb-3 flex items-center px-[34px] gap-[101px]">
                <label
                  htmlFor="name_field"
                  className="form-label text-[18px] text-[#434343] dark:text-[#EDEDFB]"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control w-full h-[47px] border-none px-[10px] text-[16px] bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
                  name="name"
                  value={name}
                  onChange={onChange}
                />
              </div>
              <div className="mb-3 flex items-center px-[34px] gap-[101px]">
                <label
                  htmlFor="email_field"
                  className="form-label text-[18px] text-[#434343] dark:text-[#EDEDFB] "
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control w-full h-[47px] border-none px-[10px] text-[16px] bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
                  name="email"
                  value={email}
                  onChange={onChange}
                />
              </div>
              <div className="mb-3 flex items-center px-[34px] gap-[40px]">
                <label
                  htmlFor="department_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  Department
                </label>
                <div className="w-full h-[47px] border-none px-[10px] text-[16px] bg-[#E9E9FF] items-center flex dark:bg-[#3A3A68] dark:text-[#EDEDFB]">
                  <DropdownDepartments
                    setDepartment={setDepartment}
                    department={department}
                  />
                </div>
              </div>
              <div className="mb-3 flex items-center px-[34px] gap-[105px]">
                <label
                  htmlFor="role_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  Role
                </label>
                <div className="w-full h-[47px] border-none px-[10px] text-[16px] bg-[#E9E9FF] items-center flex dark:bg-[#3A3A68] dark:text-[#EDEDFB]">
                  <DropdownRoles
                    setCategory={setRole}
                    setIsCategoryChosen={() => {}}
                    roleUser={role}
                  />
                </div>
              </div>
              <div className="flex justify-end w-full">
                <button
                  id="login_button"
                  type="submit"
                  className="btn w-100 py-[12px] px-[22px] bg-[#8D8BDB] rounded-[6px] text-white"
                  disabled={isUpdatingUser || isAddingToDepartment}
                >
                  {isUpdatingUser || isAddingToDepartment
                    ? "Save..."
                    : "Save all changes"}
                </button>
              </div>
            </form>

            <form
              onSubmit={passwordSubmitHandler}
              className="flex gap-[16px] flex-col"
            >
              <div className="mb-3 flex items-center px-[34px] gap-[70px]">
                <label
                  htmlFor="new_password_field"
                  className="form-label text-[18px] text-[#434343] dark:text-[#EDEDFB]"
                >
                  New Password
                </label>
                <input
                  type="password"
                  id="new_password_field"
                  className="form-control w-full h-[47px] border-none px-[10px] text-[16px] bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
                  name="newPassword"
                  value={newPassword}
                  onChange={onPasswordChange}
                />
              </div>
              <div className="mb-3 flex items-center px-[34px] gap-[48px]">
                <label
                  htmlFor="confirm_password_field"
                  className="form-label text-[18px] text-[#434343] dark:text-[#EDEDFB]"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirm_password_field"
                  className="form-control w-full h-[47px] border-none px-[10px] text-[16px] bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onPasswordChange}
                />
              </div>
              <div className="flex justify-end w-full">
                <button
                  id="password_button"
                  type="submit"
                  className="btn w-100 py-[12px] px-[22px] bg-[#8D8BDB] rounded-[6px] text-white"
                  disabled={isUpdatingPassword}
                >
                  {isUpdatingPassword ? "Save..." : "Save"}
                </button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default FormEditUserManagement;
