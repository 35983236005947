import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import NotificationComponent from "../NotificationComponent";
import Datepicker from "react-tailwindcss-datepicker";
import { PieChart } from "@mui/x-charts";
import {
  useCreateExpenseMutation,
  useGetExpensesQuery,
  useUpdateExpenseMutation,
  useDeleteExpensesMutation,
} from "../../redux/api/finance/expenses";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useCreateNotificationMutation } from "../../redux/api/notificationApi";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { useSelector } from "react-redux";
import { notify } from "../utils/notificationManager";
import FuseSpecialDropdown from "./ui/specialDropdown";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const getCurrentMonthDateRange = () => {
  const startDate = dayjs().startOf("month").format("YYYY-MM-DD");
  const endDate = dayjs().endOf("month").format("YYYY-MM-DD");
  return { startDate, endDate };
};

const Expenses = () => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [loadingExpenses, setLoadingExpenses] = useState([]);
  const [postNotification] = useCreateNotificationMutation();
  const { data: usersData } = useGetAllUsersQuery();
  const [updateExpense] = useUpdateExpenseMutation();
  const [deleteExpenses] = useDeleteExpensesMutation();
  const [postExpense] = useCreateExpenseMutation();
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [newExpenseOpen, setNewExpenseOpen] = useState(false);
  const [expenseDate, setExpenseDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [newExpense, setNewExpense] = useState({
    name: "",
    value: "",
    startDate: null,
    endDate: null,
    status: "",
    recurring: false,
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const { data: expensesData, refetch } = useGetExpensesQuery({
    page: 1,
    limit: 10000,
    sort: "name",
    order: "asc",
    search: "",
    status: "",
  });

  useEffect(() => {
    setSelectedDate(getCurrentMonthDateRange());
  }, []);

  const handleExpenseOpen = () => {
    setNewExpenseOpen(!newExpenseOpen);
  };

  const handleChange = (e) => {
    setNewExpense({
      ...newExpense,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeRecurring = (e) => {
    const { name, checked } = e.target;
    setNewExpense({
      ...newExpense,
      [name]: checked,
    });
  };

  const handleExpenseDate = (date) => {
    setExpenseDate(date);
  };

  const handleExpenseSubmit = async () => {
    setLoading(true);
    try {
      const expenseToSubmit = {
        ...newExpense,
        startDate: expenseDate.startDate,
        endDate: expenseDate.endDate,
      };
      if (selectedExpense) {
        await updateExpense({ id: selectedExpense._id, updates: expenseToSubmit }).unwrap();
        notify("Expense updated successfully!", 0, null, null, true);
      } else {
        await postExpense(expenseToSubmit).unwrap();
        notify("Expense posted successfully!", 0, null, null, true);
      }
      refetch();
      setNewExpense({
        name: "",
        value: "",
        startDate: null,
        endDate: null,
        status: "",
        recurring: false,
      });
      setSelectedExpense(null);
      handleExpenseOpen();
    } catch (error) {
      console.error("Failed to submit expense:", error);
      notify("Failed to submit expense", 2, null, null, true);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateExpenseOpen = (expense) => {
    setSelectedExpense(expense);
    setNewExpense({
      name: expense?.name || "",
      value: expense?.value || "",
      startDate: expense?.startDate || null,
      endDate: expense?.endDate || null,
      status: expense?.status || "",
      recurring: expense?.recurring || false,
    });
    setExpenseDate({
      startDate: expense?.startDate || null,
      endDate: expense?.endDate || null,
    });
    setNewExpenseOpen(true);
  };

  const handleStatusChange = async (newStatus, expenseId) => {
    setLoadingExpenses((prev) => [...prev, expenseId]);
    const expenseToUpdate = expensesData?.expenses.find(
      (exp) => exp._id === expenseId
    );
    if (expenseToUpdate) {
      const updatedExpense = { ...expenseToUpdate, status: newStatus };
      await updateExpense({ id: expenseId, updates: updatedExpense });
      refetch();
    }
    setLoadingExpenses((prev) => prev.filter((id) => id !== expenseId));
  };

  const filteredExpenses =
    expensesData?.expenses.filter((expense) => {
      const expenseStartDate = dayjs(expense?.startDate);
      const expenseEndDate = dayjs(expense?.endDate);
      const filterStartDate = dayjs(selectedDate?.startDate);
      const filterEndDate = dayjs(selectedDate?.endDate);
      return (
        (expenseStartDate.isSameOrAfter(filterStartDate) &&
          expenseStartDate.isBefore(filterEndDate)) ||
        (expenseEndDate.isSameOrBefore(filterEndDate) &&
          expenseEndDate.isAfter(filterStartDate)) ||
        (expenseStartDate.isBefore(filterStartDate) &&
          expenseEndDate.isAfter(filterEndDate))
      );
    }) || [];

  const finalExpenses = filteredExpenses?.filter(
    (e) => e.status !== "Canceled"
  );

  const getTotalExpensesValue = () => {
    const total = finalExpenses.reduce(
      (total, expense) => total + expense.value,
      0
    );
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);
  };

  const hslToHex = (h, s, l) => {
    l /= 100;
    s /= 100;
    let a = s * Math.min(l, 1 - l);
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, "0");
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  };

  const generateColor = (index) => {
    const hue = (index * 137.5) % 360;
    return hslToHex(hue, 70, 50);
  };

  const pieChartData =
    finalExpenses.map((expense, index) => ({
      id: expense.name,
      label: expense.name,
      value: expense.value,
      color: generateColor(index),
    })) || [];

  const today = new Date();

  const customShortcuts = {
    Q1: {
      text: "Q1",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 2, 31),
      },
    },
    Q2: {
      text: "Q2",
      period: {
        start: new Date(today.getFullYear(), 3, 1),
        end: new Date(today.getFullYear(), 5, 30),
      },
    },
    Q3: {
      text: "Q3",
      period: {
        start: new Date(today.getFullYear(), 6, 1),
        end: new Date(today.getFullYear(), 8, 30),
      },
    },
    Q4: {
      text: "Q4",
      period: {
        start: new Date(today.getFullYear(), 9, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
    H1: {
      text: "H1",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 5, 30),
      },
    },
    H2: {
      text: "H2",
      period: {
        start: new Date(today.getFullYear(), 6, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
    FY: {
      text: "FY",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
  };

  const configs = {
    shortcuts: customShortcuts,
    footer: {
      cancel: "Cancel",
      apply: "Apply",
    },
  };

  const LoadingSpinner = () => {
    return (
      <div className="w-full min-h-screen flex justify-center items-center mt-0">
        <div className="w-20 h-20 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  };

  if (!expensesData) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full h-screen p-[30px] dark:bg-[#2B2A49] overflow-scroll scrolling-touch">
      <div className="flex justify-between pb-[25px]">
        <ul className="flex items-center">
          <Breadcrumbs
            aria-label="breadcrumb"
            className="w-[380px] text-[#7674C2] dark:text-[#EDEDFB]"
          >
            <Link
              underline="hover"
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              to="/dashboard"
            >
              Fuse Digital
            </Link>
            <Link
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              underline="hover"
              to={`/finance/expenses`}
            >
              Finance / <b> Expenses</b>
            </Link>
          </Breadcrumbs>
        </ul>
        <NotificationComponent />
      </div>

      <div className="w-full pb-[45px]">
        <h1 className="dark:text-[#EDEDFB] text-[#4A488E] text-[30px]">
          Expenses
        </h1>
      </div>

      <div className="w-full flex justify-end pb-[35px]">
        <div className="w-[50%] xl:w-[40%] flex justify-end items-center gap-[24px]">
          <button
            onClick={() => {
              setSelectedExpense(null);
              setNewExpense({
                name: "",
                value: "",
                startDate: null,
                endDate: null,
                status: "",
                recurring: false,
              });
              setExpenseDate({
                startDate: null,
                endDate: null,
              });
              setNewExpenseOpen(true);
            }}
            className="h-[38px] px-5 py-2.5 bg-[#ededfb] rounded-[5px] justify-center items-center gap-2.5 inline-flex"
          >
            <div className="min-w-36 text-[#4a488e] text-[15px] font-medium ">
              + Add expenses
            </div>
          </button>
          <Datepicker
            showShortcuts={true}
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            asSingle={false}
            displayFormat={"YYYY-MM-DD"}
            className="h-[55px] bg-[#E8E8FF]  border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg"
            configs={configs}
          />
        </div>
      </div>

      <>
        <div className="mb-[12px]">
          <div className="w-full py-[33px] px-[28px] dark:bg-[#3A3A68] bg-[#E7E7F9] rounded-[21px] flex gap-[26px]">
            <div className="w-[32%] dark:bg-[#4D4D77] bg-[#FAFAFE] py-[59px] flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
              <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                Total Expenses
              </p>
              <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px]">
                {getTotalExpensesValue()} RON
              </p>
            </div>
            <div className="w-[66%] dark:bg-[#4D4D77] bg-[#FAFAFE] p-5 flex justify-start items-start rounded-[21px] flex-col gap-5">
              <div className="dark:text-[#d4d4eb] text-[#4A488E] text-xl font-normal">
                Expenses summary
              </div>
              <div className="flex flex-row items-start justify-center w-full gap-4">
                <PieChart
                  width={330}
                  height={330}
                  series={[
                    {
                      data: pieChartData,
                      colorField: "color",
                    },
                  ]}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                />

                <div className="w-48 h-80 overflow-y-auto">
                  {pieChartData.map((item) => (
                    <div key={item.id} className="flex items-center mb-2">
                      <div
                        className="w-4 h-4 mr-2"
                        style={{ backgroundColor: item.color }}
                      ></div>
                      <span
                        className={`text-sm ${
                          theme === "dark" ? "text-gray-300" : "text-gray-700"
                        }`}
                      >
                        {item.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full dark:bg-[#3A3A68] bg-[#e7e7f9] rounded-[21px] p-8">
          <h2 className="dark:text-white text-[#4A488E] font-semibold text-lg pb-[21px] border-b border-[#CAC9F1] dark:border-[#e2e2ff52]">
            All activities
          </h2>
          <table className="w-full text-center text-sm text-white mt-6">
            <thead className="dark:bg-[#3A3A68] text-[#AFAFDD]">
              <tr>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Transactions
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Value
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Date
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Due Date
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF] ">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="dark:bg-[#3A3A68] bg-[#e7e7f9]">
              {filteredExpenses.map((item, index) => (
                <tr
                  key={index}
                  className="border-t dark:border-[#e2e2ff52] border-[#CAC9F1] py-[25px]"
                >
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {item?.name}
                    <button
                      onClick={() => handleUpdateExpenseOpen(item)}
                      className="text-sm text-blue-500 hover:underline pl-2"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {item?.value}
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {dayjs(item?.startDate).format("DD.MM.YYYY")}
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {dayjs(item?.endDate).format("DD.MM.YYYY")}
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    <div className="flex flex-row items-center justify-center gap-3">
                      <FuseSpecialDropdown
                        currentStatus={item?.status}
                        onChange={(newStatus) =>
                          handleStatusChange(newStatus, item?._id)
                        }
                        loading={loadingExpenses.includes(item._id)}
                        type="expense"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
      <Dialog
        open={newExpenseOpen}
        onClose={handleExpenseOpen}
        fullWidth
        maxWidth="md"
        PaperProps={{
          elevation: 0,
          sx: {
            paddingY: 3,
            paddingX: 4,
            color: "#FDFDFD",
            backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
            borderRadius: "14px",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "var(--tw-bg-opacity, #2B2A49)",
            },
          },
          className:
            "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white",
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className="flex flex-row justify-between">
          <div className="dark:text-[#ededfb] text-[#4A488E] text-[25px] font-normal font-['Inter']">
            {selectedExpense ? "Edit Expense" : "Add Expense"}
          </div>
          <svg
            onClick={handleExpenseOpen}
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
          >
            <path
              className="fill-current text-[#4A488E] dark:text-[#EDEDFB]"
              d="M29.7249 28.0888C29.8324 28.1963 29.9176 28.3238 29.9757 28.4642C30.0339 28.6045 30.0638 28.755 30.0638 28.9069C30.0638 29.0588 30.0339 29.2093 29.9757 29.3496C29.9176 29.49 29.8324 29.6175 29.7249 29.7249C29.6175 29.8324 29.49 29.9176 29.3496 29.9757C29.2093 30.0339 29.0588 30.0638 28.9069 30.0638C28.755 30.0638 28.6045 30.0339 28.4642 29.9757C28.3238 29.9176 28.1963 29.8324 28.0888 29.7249L18.5006 20.1353L8.91244 29.7249C8.69548 29.9419 8.40122 30.0638 8.09439 30.0638C7.78757 30.0638 7.49331 29.9419 7.27635 29.7249C7.05939 29.508 6.9375 29.2137 6.9375 28.9069C6.9375 28.6001 7.05939 28.3058 7.27635 28.0888L16.866 18.5006L7.27635 8.91244C7.05939 8.69548 6.9375 8.40122 6.9375 8.09439C6.9375 7.78757 7.05939 7.49331 7.27635 7.27635C7.49331 7.05939 7.78757 6.9375 8.09439 6.9375C8.40122 6.9375 8.69548 7.05939 8.91244 7.27635L18.5006 16.866L28.0888 7.27635C28.3058 7.05939 28.6001 6.9375 28.9069 6.9375C29.2137 6.9375 29.508 7.05939 29.7249 7.27635C29.9419 7.49331 30.0638 7.78757 30.0638 8.09439C30.0638 8.40122 29.9419 8.69548 29.7249 8.91244L20.1353 18.5006L29.7249 28.0888Z"
            />
          </svg>
        </DialogTitle>

        <DialogContent>
          {loading ? (
            <div className="flex flex-col gap-4 justify-center items-center">
              <p className="text-[#8d8bdb] dark:text-white">Processing...</p>
              <CircularProgress />
            </div>
          ) : (
            <div className="h-fit justify-start items-start gap-[20px] flex flex-col mt-10 p-[10%]">
              <div className="w-full h-fit flex-row justify-start items-center gap-4 inline-flex ">
                <div className="w-[150px] text-[#8A8A8A] dark:text-white text-[19px] font-normal ">
                  Expense name
                </div>
                <input
                  name="name"
                  value={newExpense.name}
                  onChange={handleChange}
                  className="w-full self-stretch text-[#4A488E] dark:text-white h-[45px] px-5 py-3.5 bg-[#E9E9FF] dark:bg-[#3a3a68] rounded-md justify-start items-center gap-2.5 inline-flex border-none"
                />
              </div>
              <div className="w-full h-fit flex-row justify-start items-center gap-4 inline-flex ">
                <div className="w-[150px] text-[#8A8A8A] dark:text-white text-[19px] font-normal ">
                  Value
                </div>
                <input
                  type="number"
                  name="value"
                  value={newExpense.value}
                  onChange={handleChange}
                  className="w-full self-stretch text-[#4A488E] dark:text-white h-[45px] px-5 py-3.5 bg-[#E9E9FF] dark:bg-[#3a3a68] rounded-md justify-start items-center gap-2.5 inline-flex border-none"
                />
              </div>
              <div className="w-full h-fit flex-row justify-start items-center gap-4 inline-flex">
                <div className="w-[150px] text-[#8A8A8A] dark:text-white text-[19px] font-normal">
                  Status
                </div>
                <select
                  name="status"
                  value={newExpense.status}
                  onChange={handleChange}
                  className="w-full self-stretch text-[#4A488E] dark:text-white h-[45px] px-5 py-2 bg-[#E9E9FF] dark:bg-[#3a3a68] rounded-md border-none"
                >
                  <option value="" disabled>
                    Select status
                  </option>
                  <option value="Completed">Completed</option>
                  <option value="Active">Active</option>
                  <option value="Canceled">Canceled</option>
                </select>
              </div>
              <div className="w-full h-fit flex-row justify-start items-center gap-4 inline-flex ">
                <div className="w-[150px] text-[#8A8A8A] dark:text-white text-[19px] font-normal ">
                  Due date
                </div>
                <Datepicker
                  popoverDirection="up"
                  value={expenseDate}
                  onChange={(newDate) => handleExpenseDate(newDate)}
                  asSingle={false}
                  displayFormat={"YYYY-MM-DD"}
                  className="h-[55px] bg-[#E8E8FF] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] z-[99] text-lg"
                />
              </div>
              <div className="w-full h-fit flex-row justify-start items-center gap-4 inline-flex">
                <div className="w-[125px] text-[#8A8A8A] dark:text-white text-[19px] font-normal">
                  Recurring
                </div>
                <input
                  name="recurring"
                  type="checkbox"
                  checked={newExpense.recurring || false}
                  onChange={handleChangeRecurring}
                />
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <button
            disabled={loading}
            onClick={handleExpenseSubmit}
            className="h-12 px-[22px] py-3 bg-[#8d8bdb] rounded-md justify-center items-center gap-2.5 inline-flex"
          >
            <div className="text-white text-xl font-normal font-['Inter']">
              {loading ? "Processing..." : selectedExpense ? "Update" : "Add"}
            </div>
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Expenses;
