import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import { useTheme } from "@mui/material/styles";

import OutlinedInput from "@mui/material/OutlinedInput";

import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";

import Select from "@mui/material/Select";

import dayjs from "dayjs";

import advancedFormat from "dayjs/plugin/advancedFormat";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import {
  useGetProjectsQuery,
  useUpdateProjectMutation,
} from "../redux/api/projectsApi";

import { useGetClientsQuery } from "../redux/api/clientsApi";

import { useGetAllUsersQuery } from "../redux/api/authApi";

import { Checkbox, ListSubheader, TextField } from "@mui/material";

import { toast } from "react-toastify";

import { useCreateNotificationMutation } from "../redux/api/notificationApi";

import { useSelector } from "react-redux";

import { useGetAccountsQuery } from "../redux/api/crm/accountsApi";

import {
  ProjectAssignedIcon,
  ProjectClientIcon,
  ProjectDateIcon,
  ProjectDepartmentIcon,
  ProjectDescriptionIcon,
  ProjectPriorityIcon,
  ProjectStatusIcon,
  ProjectTagsIcon,
  ProjectTitleIcon,
} from "./sidebar/svgs";

dayjs.extend(advancedFormat);

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

      width: 250,

      backgroundColor: "#E6E5FF",

      paddingTop: ITEM_PADDING_TOP,

      paddingBottom: ITEM_PADDING_TOP,
    },

    sx: {
      backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",

      borderRadius: "14px",

      "@media (prefers-color-scheme: dark)": {
        backgroundColor: "var(--tw-bg-opacity, #2b2a49)", // Example dark mode background color
      },
    },

    className: "bg-[#E6E5FF] dark:bg-[#2B2A49] text-black dark:text-white", // Tailwind dark mode classes
  },
};

const departmentsList = [
  "Development",
  "SEO",
  "PPC",
  "SMM",
  "Sales",
  "Graphic Design",
  "Copywriter",
];

const priority = ["Low", "Medium", "High"];

const status = ["To do", "In Progress", "Completed"];

const tags = ["Website", "App", "Ceva"];

const tagsListDefault = [
  { name: "Mentenanta", color: "#7E22CE" },

  { name: "E-mail MKT", color: "#7E22CE" },

  { name: "App", color: "#7E22CE" },
  { name: "Website", color: "#7E22CE" },
  { name: "Articole ", color: "#7E22CE" },
];

function getStyles(name, selectedNames, theme) {
  return {
    fontWeight:
      selectedNames?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const getStyles2 = (priority, selectedPriorities, theme) => {
  return {
    marginTop: "10px",

    backgroundColor:
      priority === "Low"
        ? "#38BDF8" // color for Low
        : priority === "Medium"
        ? "#FACC15" // color for Medium
        : priority === "High"
        ? "#FF3939"
        : undefined, // color for High

    fontWeight:
      selectedPriorities?.indexOf(priority) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const getStyles3 = (status, selectedStatuses, theme) => {
  return {
    marginTop: "10px",

    backgroundColor:
      status === "To do"
        ? "#2ECC71" // color for To do
        : status === "In Progress"
        ? "#C026D3" // color for In Progress
        : status === "Completed"
        ? "#F472B6"
        : undefined, // color for Completed

    fontWeight:
      selectedStatuses?.indexOf(status) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const getStyles4 = (tag, selectedTags, theme) => {
  return {
    marginTop: "10px",

    backgroundColor: "#7E22CE", // Default color for all tags

    fontWeight:
      selectedTags?.indexOf(tag) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const style = {
  position: "absolute",

  top: "50%",

  left: "50%",

  transform: "translate(-50%, -50%)",

  width: "100%",

  height: "100%",

  bgcolor: "#CAC9F1",

  boxShadow: 24,

  p: 4,
};

const buttonStyle = {
  display: "flex",

  justifyContent: "flex-start",

  padding: "6px 0px",
};

const ProjectEditForm = ({ project }) => {
  const { data: clientsData } = useGetClientsQuery();

  const { data: projectsData } = useGetProjectsQuery();

  const { data: usersData, refetch: refetchUsers } = useGetAllUsersQuery();

  const {
    data: accountsData,

    error: accountsError,

    isLoading: accountsLoading,
    refetch: refetchAccounts,
  } = useGetAccountsQuery();

  useEffect(() => {
    if (accountsData && usersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetchAccounts();
        refetchUsers();
      }, 4000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [accountsData, usersData, refetchAccounts, refetchUsers]);

  const [updateProject, { isLoading, isSuccess }] = useUpdateProjectMutation();

  const [postNotification] = useCreateNotificationMutation();

  const [title, setTitle] = useState("");

  const [description, setDescription] = useState();

  const [client, setClient] = useState("");

  const [departments, setDepartments] = useState([]);

  const [assignedUsers, setAssignedUsers] = useState([]);

  const [selectedPriority, setSelectedPriority] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedTags, setSelectedTags] = useState([]);

  const [dueDate, setDueDate] = useState();

  const [newTag, setNewTag] = useState("");

  const [isCreatingTag, setIsCreatingTag] = useState(false);

  const [tagsList, setTagsList] = useState(tagsListDefault);

  const [isDeactivated, setIsDeactivated] = useState(false);

  const [theme2, setTheme2] = useState(localStorage.getItem("theme"));
  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsDeactivated(checked);
  };

  useEffect(() => {
    if (project?.tags) {
      const combinedTags = [
        ...tagsListDefault,
        ...project.tags.map((tag) => ({ name: tag, color: "#7E22CE" })),
      ];

      // Remove duplicates
      const uniqueTags = combinedTags.filter(
        (tag, index, self) =>
          index === self.findIndex((t) => t.name === tag.name)
      );

      setTagsList(uniqueTags);
    }
  }, [project]);

  useEffect(() => {
    setTitle(project?.nume);

    setClient(project?.client);

    setDescription(project?.description);

    setDepartments(project?.department || []);

    setAssignedUsers(project?.assignedTo || []);

    setSelectedPriority(project?.priority || "");

    setSelectedStatus(project?.status || "");

    setSelectedTags(project?.tags || []);

    setDueDate(dayjs(project?.dueDate) || dayjs());

    setIsDeactivated(
      project?.isActive === true
        ? false
        : project?.isActive === false
        ? true
        : false || false
    );
  }, [project]);

  const [searchText, setSearchText] = useState("");

  const user = useSelector((state) => state.user);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const theme = useTheme();

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleChange = (setter) => (event) => {
    const {
      target: { value },
    } = event;

    setter(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (projectsData && accountsData) {
      const projectClient = projectsData.projects.find(
        (proj) => proj._id === project?._id
      )?.client;

      const clientData = accountsData.accounts.find(
        (cli) => cli._id === projectClient
      );

      // setClient(clientData ? clientData.nameComp : "");
    }
  }, [projectsData, accountsData, project?._id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedProject = {
      nume: title,

      description,

      client,

      department: departments,

      assignedTo: assignedUsers,

      priority: selectedPriority,

      status: selectedStatus,

      tags: selectedTags,

      isActive: !isDeactivated,

      dueDate: dueDate.toISOString(), // Ensure the date is in the correct format
    };

    try {
      await updateProject({ id: project._id, body: updatedProject }).unwrap();

      await Promise.all(
        assignedUsers.map(async (userId) => {
          const notification = {
            message: "assigned you a new task " + `${title}`,

            toPage: "/taskslist/My-Tasks",

            senderId: user.user._id,

            receiverId: userId,
          };

          toast.success("Project updated successfully");

          return postNotification(notification).unwrap();
        })
      );

      handleClose();
    } catch (error) {
      console.error("Failed to update project: ", error);
    }
  };

  const handleAddTag = () => {
    if (newTag) {
      const newTagItem = { name: newTag, color: "#7E22CE" };

      setTagsList([...tagsList, newTagItem]);

      setSelectedTags([...selectedTags, newTag]);

      setNewTag("");

      setIsCreatingTag(false);
    }
  };

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      <button
        className="py-[12px] px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
        onClick={handleOpen}
      >
        Edit
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            WebkitOverflowScrolling: "touch",
            height: "100%", // or whatever height is appropriate
          }}
          className="bg-[#E6E5FF] dark:bg-[#3A3A68] text-[#4A488E] dark:text-[#EDEDFB] overflow-y-auto  scrolling-touch"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="flex justify-between mb-[34px]">
              <p className="text-white">Edit project</p>

              <div className="text-[#4A488E] dark:text-white">
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleClose}
                >
                  <g id="ph:x">
                    <path
                      id="Vector"
                      d="M29.724 28.0884C29.8314 28.1958 29.9166 28.3233 29.9747 28.4637C30.0329 28.604 30.0628 28.7545 30.0628 28.9064C30.0628 29.0583 30.0329 29.2088 29.9747 29.3491C29.9166 29.4895 29.8314 29.617 29.724 29.7245C29.6165 29.8319 29.489 29.9171 29.3486 29.9752C29.2083 30.0334 29.0578 30.0633 28.9059 30.0633C28.754 30.0633 28.6036 30.0334 28.4632 29.9752C28.3228 29.9171 28.1953 29.8319 28.0879 29.7245L18.4997 20.1348L8.91146 29.7245C8.6945 29.9414 8.40024 30.0633 8.09342 30.0633C7.78659 30.0633 7.49233 29.9414 7.27537 29.7245C7.05841 29.5075 6.93652 29.2132 6.93652 28.9064C6.93652 28.5996 7.05841 28.3053 7.27537 28.0884L16.865 18.5002L7.27537 8.91195C7.05841 8.69499 6.93652 8.40073 6.93652 8.0939C6.93652 7.78708 7.05841 7.49282 7.27537 7.27586C7.49233 7.0589 7.78659 6.93701 8.09342 6.93701C8.40024 6.93701 8.6945 7.0589 8.91146 7.27586L18.4997 16.8655L28.0879 7.27586C28.3048 7.0589 28.5991 6.93701 28.9059 6.93701C29.2127 6.93701 29.507 7.0589 29.724 7.27586C29.9409 7.49282 30.0628 7.78708 30.0628 8.0939C30.0628 8.40073 29.9409 8.69499 29.724 8.91195L20.1343 18.5002L29.724 28.0884Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <div className="mb-[34px]">
                <label
                  htmlFor="title"
                  className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[5px] mb-[10px]"
                >
                  <ProjectTitleIcon />
                  Project title
                </label>

                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full h-[40px] border-none mt-[10px] rounded-[6px] bg-[#FAFAFE] dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                />
              </div>

              <div className="mb-[34px]">
                <label
                  htmlFor="description"
                  className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[5px] mb-[10px]"
                >
                  <ProjectDescriptionIcon />
                  Project description
                </label>

                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  className="h-[250px] overflow-auto mb-[10px] bg-white dark:bg-[#545492] text-black"
                />
              </div>

              <div className="w-full flex gap-[45px]">
                <div className="w-6/12">
                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full ">
                      <label
                        htmlFor="client"
                        className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                      >
                        <ProjectClientIcon />
                        Client
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          value={client}
                          onChange={(e) => setClient(e.target.value)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            const clientData = accountsData?.accounts.find(
                              (client) => client._id === selected
                            );

                            return clientData ? clientData.nameComp : selected;
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <ListSubheader className="bg-[#E6E5FF] dark:bg-[#2b2a49]">
                            <TextField
                              size="small"
                              className="placeholder-yellow-400"
                              placeholder="Search client"
                              fullWidth
                              value={searchText}
                              onChange={handleSearchChange}
                              onClick={(e) => e.stopPropagation()} // Prevent menu from closing
                              InputProps={{
                                sx: {
                                  border: "none",

                                  bgcolor: "white",

                                  dark: { bgcolor: "#383757" },

                                  borderRadius: "52px",

                                  position: "relative",
                                },

                                classes: {
                                  input: "placeholder-indigo-800", // Applying class to input element
                                },
                              }}
                            />

                            <img
                              src="/images/blue-search.svg"
                              alt=""
                              className="absolute top-2 right-7"
                            />
                          </ListSubheader>

                          {accountsData?.accounts
                            .filter(
                              (clientItem) => clientItem.isActive !== false
                            )
                            .map((clientItem) => (
                              <MenuItem
                                className={`text-[#4A488E] dark:text-[#EDEDFB] ${
                                  client === clientItem._id
                                    ? "dark:bg-violet-700"
                                    : ""
                                }`}
                                key={clientItem._id}
                                value={clientItem._id}
                                style={getStyles(
                                  clientItem.nameComp,
                                  [client],
                                  theme
                                )}
                              >
                                {clientItem.nameComp}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full ">
                      <label
                        htmlFor="department"
                        className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                      >
                        <ProjectDepartmentIcon />
                        Department
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          multiple
                          displayEmpty
                          value={departments}
                          onChange={handleChange(setDepartments)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          {departmentsList.map((department) => (
                            <MenuItem
                              className={`text-[#4A488E] dark:text-[#EDEDFB] ${
                                departments.includes(department)
                                  ? "dark:bg-violet-700"
                                  : ""
                              }`}
                              key={department}
                              value={department}
                              style={getStyles(department, departments, theme)}
                            >
                              {department}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full ">
                      <label
                        htmlFor="assignedUsers"
                        className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                      >
                        <ProjectAssignedIcon />
                        Assigned to
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          multiple
                          displayEmpty
                          value={assignedUsers}
                          onChange={handleChange(setAssignedUsers)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            const selectedUsers = usersData?.users.filter(
                              (user) => selected.includes(user._id)
                            );

                            return selectedUsers
                              ? selectedUsers

                                  .map((user) => user.name)

                                  .join(", ")
                              : selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>

                          {usersData?.users.map((user) => (
                            <MenuItem
                              className={`text-[#4A488E] dark:text-[#EDEDFB] ${
                                assignedUsers.includes(user._id)
                                  ? "dark:bg-violet-700"
                                  : ""
                              }`}
                              key={user._id}
                              value={user._id}
                              style={getStyles(user.name, assignedUsers, theme)}
                            >
                              {user.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full ">
                      <label
                        htmlFor="priority"
                        className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                      >
                        <ProjectPriorityIcon />
                        Priority
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          value={selectedPriority}
                          onChange={(e) => setSelectedPriority(e.target.value)}
                          input={<OutlinedInput />}
                          renderValue={(selected) =>
                            selected || (
                              <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                Placeholder
                              </em>
                            )
                          }
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>

                          {priority.map((p) => (
                            <MenuItem
                              className={`rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 ${
                                selectedPriority === p
                                  ? "dark:bg-violet-700"
                                  : ""
                              }`}
                              key={p}
                              value={p}
                              style={getStyles2(p, selectedPriority, theme)}
                            >
                              {p}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="w-6/12">
                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full ">
                      <label
                        htmlFor="status"
                        className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                      >
                        <ProjectStatusIcon />
                        Status
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          input={<OutlinedInput />}
                          renderValue={(selected) =>
                            selected || (
                              <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                Placeholder
                              </em>
                            )
                          }
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>

                          {status.map((s) => (
                            <MenuItem
                              className={`rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 ${
                                selectedStatus === s ? "dark:bg-violet-700" : ""
                              }`}
                              key={s}
                              value={s}
                              style={getStyles3(s, selectedStatus, theme)}
                            >
                              {s}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full ">
                      <label
                        htmlFor="tags"
                        className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                      >
                        <ProjectTagsIcon />
                        Tags
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          multiple
                          displayEmpty
                          value={selectedTags}
                          onChange={handleChange(setSelectedTags)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <ListSubheader className="bg-[#E6E5FF] dark:bg-[#383757]">
                            {isCreatingTag && (
                              <div className="flex justify-center gap-[5px]  items-center  bg-[#e6e5ff]  dark:text-[#EDEDFB] dark:bg-[#383757]">
                                <TextField
                                  value={newTag}
                                  onKeyDown={handleStopPropagation}
                                  onClick={handleStopPropagation}
                                  onMouseDown={handleStopPropagation}
                                  onChange={(e) => setNewTag(e.target.value)}
                                  placeholder="New tag name"
                                  className=" bg-[#e6e5ff] dark:text-[#EDEDFB] dark:bg-[#383757]"
                                  InputProps={{
                                    className: "dark:text-[#EDEDFB]",
                                  }}
                                />

                                <button
                                  onClick={handleAddTag}
                                  className=" py-1 px-3 bg-[#4A488E] dark:bg-[#383757] text-white rounded text-[20px] "
                                >
                                  +
                                </button>
                              </div>
                            )}
                          </ListSubheader>

                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>

                          {tagsList.map((tag) => (
                            <MenuItem
                              className={`rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 ${
                                selectedTags.includes(tag.name)
                                  ? "dark:bg-violet-700"
                                  : ""
                              }`}
                              key={tag.name}
                              value={tag.name}
                              style={{
                                marginTop: "10px",

                                backgroundColor: tag.color,

                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {tag.name}
                            </MenuItem>
                          ))}

                          <ListSubheader className="bg-[#e6e5ff] dark:bg-[#383757]">
                            <MenuItem
                              onClick={() => setIsCreatingTag(true)}
                              className="bg-[#e6e5ff] dark:bg-[#383757] dark:text-[#EDEDFB]"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 4.5v15m7.5-7.5h-15"
                                />
                              </svg>
                              Add new tag
                            </MenuItem>
                          </ListSubheader>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full ">
                      <label
                        htmlFor="dueDate"
                        className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                      >
                        <ProjectDateIcon />
                        Due date
                      </label>
                    </div>

                    <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="ml-5 relative">
                          <p
                            onClick={toggleCalendar}
                            className="text-[15px] text-[#4A488E] dark:text-[#EDEDFB]"
                            style={{ cursor: "pointer" }}
                          >
                            {dueDate?.format("dddd, Do MMMM")}
                          </p>

                          {isCalendarOpen && (
                            <div className="absolute z-10 top-[-342px] left-[-148px] bg-white dark:bg-[#383757]">
                              <DateCalendar
                                value={dueDate}
                                onChange={(newValue) => {
                                  setDueDate(newValue);
                                  setIsCalendarOpen(false); // Auto-close the calendar after selecting a date
                                }}
                                sx={{
                                  "& .MuiPickersDay-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersDay-root.Mui-selected": {
                                    backgroundColor: theme2
                                      ? "#8D8BDB"
                                      : "#0074FD",
                                  },

                                  "& .MuiTypography-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersCalendarHeader-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiIconButton-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersArrowSwitcher-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersYear-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </LocalizationProvider>
                    </div>
                  </div>
                  {user?.user?.role === "Admin" ? (
                    <div>
                      Deactivate project?{" "}
                      <Checkbox
                        checked={isDeactivated}
                        onChange={handleCheckboxChange}
                      />{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex justify-end gap-[31px]">
                    <button
                      onClick={handleClose}
                      className="py-[12px] px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="py-[12px] px-[22px] text-[#4A488E] border  border-[#4A488E] bg-[#E7E7F9] rounded-[6px]"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectEditForm;
