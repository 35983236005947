import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useGetClientsQuery } from "../../redux/api/clientsApi";
import { useGetProjectsQuery } from "../../redux/api/projectsApi";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { useGetDealsQuery } from "../../redux/api/crm/dealsApi";
import { useGetLeadsQuery } from "../../redux/api/crm/leads";
import { useGetTasksQuery } from "../../redux/api/tasksApi";
import dayjs from "dayjs";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext"; // import
import { useContext } from "react";
import { useSelector } from "react-redux";
export const ClientsCard = ({ client }) => {
  const userData = useSelector((state) => state.user.user);

  const {
    data: clientsData,
    error: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useGetClientsQuery();
  const {
    data: projectsData,
    error: projectsError,
    isLoading: projectsLoading,
    refetch: refetchProjects,
  } = useGetProjectsQuery();
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useGetAllUsersQuery();
  const {
    data: dealsData,
    error: dealsError,
    isLoading: dealsLoading,
  } = useGetDealsQuery();
  const {
    data: leadsData,
    error: leadsError,
    isLoading: leadsLoading,
  } = useGetLeadsQuery();

  const {
    data: tasksData,
    error: tasksError,
    isLoading: tasksLoading,
  } = useGetTasksQuery();

  useEffect(() => {
    if (window.socket) {
      const handleClientUpdate = () => {
        refetchClients(); // Refetch clients when the 'clientPing' event occurs
      };

      window.socket.on("clientPing", handleClientUpdate);

      return () => {
        window.socket.off("clientPing", handleClientUpdate); // Clean up event listener on unmount
      };
    }
  }, [refetchClients]);

  // Helper function to get the phone number of the lead from the first or only deal
  const getLeadPhone = (dealIds) => {
    // Normalize dealIds to always be an array
    const dealIdArray = Array.isArray(dealIds) ? dealIds : [dealIds];

    // Get the first deal from the array
    const deal = dealsData?.deals?.find((deal) => deal._id === dealIdArray[0]);

    // Find the lead associated with the first deal
    const lead = leadsData?.leads?.find((lead) => lead._id === deal?.lead);

    return lead ? lead.phone : "Unknown phone";
  };

  // Helper function to get the email of the lead from the first or only deal
  const getLeadEmail = (dealIds) => {
    // Normalize dealIds to always be an array
    const dealIdArray = Array.isArray(dealIds) ? dealIds : [dealIds];

    // Get the first deal from the array
    const deal = dealsData?.deals?.find((deal) => deal._id === dealIdArray[0]);

    // Find the lead associated with the first deal
    const lead = leadsData?.leads?.find((lead) => lead._id === deal?.lead);

    return lead ? lead.email : "Unknown email";
  };

  const dealOfAccount = Array.isArray(client.deal)
    ? dealsData?.deals.filter((deal) => client.deal.includes(deal._id)) // Get all deals if multiple are linked
    : [dealsData?.deals.find((deal) => deal._id === client.deal)]; // Wrap single deal in an array

  const getProjects = (clientId) => {
    const projects = projectsData?.projects?.filter(
      (p) => p.client === clientId
    );
    return projects ? projects : "No projects";
  };

  const getTasks = (clientId) => {
    const tasks = tasksData?.tickets?.filter((t) => t.client === clientId);
    return tasks ? tasks : "No tasks";
  };

  const getUsername = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user ? user.name : "Unknown user";
  };

  const updatedServices = Object.keys(client.services).reduce((acc, key) => {
    if (client.services[key].length > 0) {
      acc[key] = client.services[key];
    }
    return acc;
  }, {});

  const isMobileView = useContext(ViewTypeContext); // true daca e mobil/tableta

  const calculateTotalContractValue = (deals) => {
    if (!deals || deals.length === 0) return 0; // Handle case where there are no deals

    return deals.reduce((total, deal) => {
      if (deal.frequency === "monthly") {
        const startDate = new Date(deal.closeDate.startDate);
        const endDate = new Date(deal.closeDate.endDate);
        const months =
          (endDate.getFullYear() - startDate.getFullYear()) * 12 +
          (endDate.getMonth() - startDate.getMonth()) +
          1;

        return total + deal.dealValue * months; // Multiply dealValue by the number of months
      } else {
        return total + deal.dealValue; // Add the dealValue as-is for non-monthly deals
      }
    }, 0); // Initialize total to 0
  };

  // si il folosesc ca rendering condition cu {isMobileView? ( tableta ) : ( desktop ) }.

  // in acelasi mod si in tailwind cu ${isMobileView ? "text-xs" : "text-sm"}

  if (clientsLoading || projectsLoading || tasksLoading)
    return <div>Loading...</div>;
  if (clientsError)
    return <div>Error fetching clients: {clientsError.message}</div>;
  if (projectsError)
    return <div>Error fetching projects: {projectsError.message}</div>;

  return (
    <motion.div
      className="w-[100%] h-fit relative group"
      whileHover={{ scale: 0.99, boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}
      transition={{ duration: 0.2 }}
    >
      <Link to={`/clients/${client.nameComp.replace(/ /g, "-")}`}>
        <div
          className={`w-full ${
            isMobileView ? "h-fit" : "h-[330px]"
          }  bg-[#FFFFFF] dark:bg-[#383757] rounded-[8px] p-[24px] flex flex-col gap-[20px]`}
        >
          <div id="projectStatsTags" className="flex flex-row justify-between">
            <div
              className={`flex flex-row justify-start gap-[10px] ${
                isMobileView ? " flex-wrap" : ""
              }`}
            >
              <div className="w-fit h-7 bg-[#D1D0FE] dark:bg-[#646497] rounded-[20px] flex items-center justify-center">
                <div className="text-[#4A488E] dark:text-[#FFFFFF] text-[11px] font-normal px-[14px] py-[8px]">
                  {
                    getTasks(client?._id)?.filter((p) => p.priority === "High")
                      .length
                  }{" "}
                  high priority tasks
                </div>
              </div>
              <div className="w-fit h-7 bg-[#D1D0FE] dark:bg-[#646497] rounded-[20px] flex items-center justify-center">
                <div className="text-[#4A488E] dark:text-[#FFFFFF] text-[11px] font-normal px-[14px] py-[8px]">
                  {
                    getTasks(client?._id)?.filter(
                      (p) => p.status === "In Progress"
                    ).length
                  }{" "}
                  tasks active
                </div>
              </div>
              <div className="w-fit h-7 bg-[#D1D0FE] dark:bg-[#646497] rounded-[20px] flex items-center justify-center">
                <div className="text-[#4A488E] dark:text-[#FFFFFF] text-[11px] font-normal px-[14px] py-[8px]">
                  {
                    getTasks(client?._id)?.filter(
                      (p) => p.status === "Completed"
                    ).length
                  }{" "}
                  tasks completed
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-row justify-start items-center gap-[10px] ${
              isMobileView ? " flex-wrap" : ""
            }`}
          >
            <div className="text-black dark:text-[#FFFFFF] text-[17px] font-normal">
              {client.nameComp}
            </div>
            {Object.keys(updatedServices).map((s) => (
              <div
                key={s}
                className={`w-fit h-7 ${
                  s === "SEO"
                    ? "bg-[#6366F1]"
                    : s === "SMM" || s === "Graphic Design"
                    ? "bg-[#1ABC9C]"
                    : s === "Development"
                    ? "bg-[#0074FD]"
                    : s === "PPC"
                    ? "bg-[#FF7918]"
                    : ""
                } rounded-[20px] flex items-center justify-center`}
              >
                <div className="text-white text-[15px] font-normal px-[14px] py-[8px]">
                  {s}
                </div>
              </div>
            ))}
          </div>

          <div className="text-[#000000] dark:text-[#FFFFFF] text-[14px] font-normal">
            Account manager: {getUsername(client.accountManager)}
          </div>
          <div className="text-[#000000] dark:text-[#FFFFFF] text-[14px] font-normal">
            Email: {getLeadEmail(client.deal)}
          </div>
          <div className="text-[#000000] dark:text-[#FFFFFF] text-[14px] font-normal">
            Phone number: {getLeadPhone(client.deal)}
          </div>
          <div className="flex flex-row justify-start items-center gap-[10px]">
            <div className="w-[80px] text-[#000000] dark:text-[#FFFFFF] text-[14px] font-normal">
              Due date:
            </div>
            <div className="w-fit h-7 bg-[#F39C12] rounded-[20px] flex items-center justify-center">
              <div className="text-white text-[15px] font-normal px-[14px] py-[18px]">
                {dealOfAccount &&
                  (() => {
                    const deals = Array.isArray(dealOfAccount)
                      ? dealOfAccount
                      : [dealOfAccount];

                    // Calculate the earliest start date and the latest end date
                    const earliestStartDate = deals.length
                      ? dayjs(
                          Math.min(
                            ...deals.map(
                              (deal) => new Date(deal.closeDate.startDate)
                            )
                          )
                        ).format("DD-MM-YYYY")
                      : "";

                    const latestEndDate = deals.length
                      ? dayjs(
                          Math.max(
                            ...deals.map(
                              (deal) => new Date(deal.closeDate.endDate)
                            )
                          )
                        ).format("DD-MM-YYYY")
                      : "";

                    return `${earliestStartDate} - ${latestEndDate}`;
                  })()}
              </div>
            </div>
          </div>
          {userData?.role !== "Admin" ? (
            ""
          ) : (
            <div className="flex flex-row justify-start items-center gap-[10px]">
              <div className="w-fit text-[#000000] dark:text-[#FFFFFF] text-[14px] font-normal">
                Contract value:
              </div>
              <div className="w-fit h-7 bg-[#F39C12] rounded-[20px] flex items-center justify-center">
                <div className="text-white text-[15px] font-normal px-[14px] py-[8px]">
                  {calculateTotalContractValue(dealOfAccount) || "Unknown"}
                </div>
              </div>
            </div>
          )}
        </div>
      </Link>
    </motion.div>
  );
};
