"use client";
import React, { useState } from "react";
import SubDropdown from "./SubDropdown"; // Import the SubDropdown component

const DropdownNotification = ({ setCategory, setIsCategoryChosen }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setCategory(option);
    setIsCategoryChosen(true);
  };

  return (
    <div className="relative inline-block text-left z-50">
      <div>
        <img
          className="cursor-pointer"
          src="/images/dots-x-white.svg"
          width={30}
          height={30}
          alt=""
          onClick={toggleDropdown}
        />
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-[-150px] mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            <ul className="flex pl-[27px] gap-[27px]  flex-col z-30">
              <span
                className="no-underline text-[#595959]  text-[17px]  "
                onClick={() => {
                  toggleDropdown();
                  setCategory("Digital Marketing");
                  setIsCategoryChosen(true);
                }}
              >
                <li>Mark all as read</li>
              </span>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownNotification;
