import React, { useState, useEffect, useContext } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TicketCard from "../TicketCard";
import TicketForm from "../TicketForm";
import Drawer from "../Notification";
import Dropdown from "../Dropdown/Dropdown";
import {
  useGetTasksQuery,
  useUpdateTaskStatusMutation,
  useDeleteTaskMutation,
} from "../../redux/api/tasksApi";
import { useUpdateProjectStatusMutation } from "../../redux/api/projectsApi";
import { useGetMeQuery } from "../../redux/api/userApi";
import Placeholder from "../utils/panels/Placeholder";
import DraggingContext from "../utils/panels/DraggingContext";
import SearchBar from "../utils/SearchBar";
import DropZone from "../utils/panels/DropZone";
import NotificationComponent from "../NotificationComponent";
import { TouchBackend } from "react-dnd-touch-backend";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import { io } from "socket.io-client";
import backend_URL from "../../config";

const TicketsPanel = ({ projectWithTasks }) => {
  const isMobileView = useContext(ViewTypeContext);

  const navigate = useNavigate(); // Ensure this is at the top level of the component

  const { data: meData, error : meError, isLoading: meLoading, refetch: refetchMe } = useGetMeQuery();

  const [openRight, setOpenRight] = useState(false);
  const { category } = useParams();
  const { data, refetch, isLoading } = useGetTasksQuery();
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const [deleteTask] = useDeleteTaskMutation();
  const [searchResults, setSearchResults] = useState([]);
  const [cards, setCards] = useState({
    todo: [],
    inProgress: [],
    completed: [],
  });
  const [selectedCategory, setSelectedCategory] = useState({
    Priority: [],
    Members: [],
    "Due date": [],
    Client: [],
    Status: [],
    Tag: [],
  });


  const [activeCount, setActiveCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [ticketFormOpen, setTicketFormOpen] = useState(false);
  const [initialStatus, setInitialStatus] = useState("");

  // useEffect(() => {
  //   if (data) {
  //     // Only refetch if the initial query has been successfully loaded
  //     const intervalId = setInterval(() => {
  //       refetch();
  //     }, 5000);

  //     return () => clearInterval(intervalId); // Cleanup interval on unmount
  //   }
  // }, [data, refetch]);

  useEffect(() => {

    const socket = io(backend_URL);
    window.socket = socket;
  
    socket.on('taskPing', (data) => {
      refetch();
    });

    socket.on('userPing', (data) => {
      refetchMe();
    });


    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  useEffect(() => {
    if (data && data.tickets) {
      filterTasks(data.tickets);
    }
  }, [data, selectedCategory]);

  const filterTasks = (tasks) => {
    const classifiedTasks = {
      todo: [],
      inProgress: [],
      completed: [],
    };

    tasks.forEach((task) => {
      if (
        (!selectedCategory.Priority.length ||
          selectedCategory.Priority.includes(task.priority)) &&
        (!selectedCategory.Members.length ||
          selectedCategory.Members.some((memberId) =>
            task.persoana.includes(memberId)
          )) &&
        (!selectedCategory["Due date"].length ||
          selectedCategory["Due date"].includes(task.dueDate)) &&
        (!selectedCategory.Client.length ||
          selectedCategory.Client.includes(task.client)) &&
        (!selectedCategory.Status.length ||
          selectedCategory.Status.includes(task.status)) &&
        (!selectedCategory.Tag.length ||
          selectedCategory.Tag.includes(task.tag)) &&
        (!projectWithTasks || task.proiect === projectWithTasks) // Check projectWithTasks here
      ) {
        if (task.status === "To do") {
          classifiedTasks.todo.push(task);
        } else if (task.status === "In Progress") {
          classifiedTasks.inProgress.push(task);
        } else if (task.status === "Completed") {
          classifiedTasks.completed.push(task);
        }
      }
    });

    // Reverse the task order
    classifiedTasks.todo.reverse();
    classifiedTasks.inProgress.reverse();
    classifiedTasks.completed.reverse();

    // Keep only the latest 10 completed tasks
    const latestCompletedTasks = classifiedTasks.completed.slice(0, 10);
    const historicalCompletedTasks = classifiedTasks.completed.slice(10);

    setCards({
      ...classifiedTasks,
      completed: latestCompletedTasks,
    });

    if (projectWithTasks) {
      let activeProject = [];
      const todoTasks = classifiedTasks.todo.filter(
        (t) => t.proiect === projectWithTasks
      );
      const inProgressTasks = classifiedTasks.inProgress.filter(
        (t) => t.proiect === projectWithTasks
      );
      const completedTasks = classifiedTasks.completed.filter(
        (t) => t.proiect === projectWithTasks
      );
      const allTasks = tasks.filter((t) => t.proiect === projectWithTasks);

      activeProject = [...todoTasks, ...inProgressTasks];
      setActiveCount(activeProject.length);
      setCompletedCount(completedTasks.length);
      setTotalCount(allTasks.length);
    } else {
      setActiveCount(
        classifiedTasks.todo.length + classifiedTasks.inProgress.length
      );
      setCompletedCount(classifiedTasks.completed.length);
      setTotalCount(tasks.length);
    }
  };

  const [updateProjectStatus] = useUpdateProjectStatusMutation();

  const moveCard = async (id, toColumn) => {
    const fromColumn = Object.keys(cards).find((key) =>
      cards[key].some((task) => task._id === id)
    );
    if (!fromColumn) {
      console.error(`Task with id ${id} not found in any column`);
      return;
    }
    if (fromColumn === toColumn) return;
    const taskToMove = cards[fromColumn]?.find((task) => task._id === id);
    if (!taskToMove) {
      console.error(`Task with id ${id} not found in column ${fromColumn}`);
      return;
    }
    const updatedStatus =
      toColumn === "todo"
        ? "To do"
        : toColumn === "inProgress"
        ? "In Progress"
        : "Completed";
    try {
      await updateTaskStatus({
        id,
        status: updatedStatus,
      });

      // If the task is moved to "In Progress", update the project status
      if (updatedStatus === "In Progress" && taskToMove.proiect) {
        await updateProjectStatus({
          id: taskToMove.proiect,
          status: "In Progress",
        });
      }

      setCards((prevCards) => ({
        ...prevCards,
        [fromColumn]: prevCards[fromColumn].filter((task) => task._id !== id),
        [toColumn]: [
          ...prevCards[toColumn],
          { ...taskToMove, status: updatedStatus },
        ],
      }));
    } catch (error) {
      console.error(`Failed to update task ${id} status:`, error);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await deleteTask(taskId).unwrap();
      setCards((prevCards) => {
        const updatedCards = { ...prevCards };
        Object.keys(updatedCards).forEach((key) => {
          updatedCards[key] = updatedCards[key].filter(
            (task) => task._id !== taskId
          );
        });
        return updatedCards;
      });
    } catch (error) {
      console.error(`Failed to delete task ${taskId}:`, error);
    }
  };

  const handleOptionClick = (category, option) => {
    setSelectedCategory((prev) => {
      const categoryOptions = prev[category];
      if (categoryOptions.includes(option)) {
        return {
          ...prev,
          [category]: categoryOptions.filter((opt) => opt !== option),
        };
      } else {
        return {
          ...prev,
          [category]: [...categoryOptions, option],
        };
      }
    });
  };

  const getFilteredTasks = (tasks, category, userId) => {
    switch (category) {
      case "My-Tasks":
        return tasks.filter((t) => t.persoana.includes(userId));
      case "SMM-Graphic-Design":
        return tasks.filter((t) =>
          t.department.some(
            (team) => team === "SMM" || team === "Graphic Design"
          )
        );
      case "Development":
        return tasks.filter((t) =>
          t.department.some((team) => team === "Development")
        );
      case "SEO":
        return tasks.filter((t) => t.department.some((team) => team === "SEO"));
      case "PPC":
        return tasks.filter((t) => t.department.some((team) => team === "PPC"));
      default:
        return tasks;
    }
  };

  const renderColumn = (columnName, title, count) => {
    const titleColors = {
      "To do": "#2ECC71",
      "In Progress": "#C026D3",
      Completed: "#F472B6",
    };

    const tasksToRender = searchResults.length
      ? searchResults
      : cards[columnName];
    const tasks = getFilteredTasks(tasksToRender, category, meData?._id);
    const LoadingSpinner = () => {
      return (
        <div className="w-full min-h-screen flex justify-center items-center mt-0">
          <div className="w-20 h-20 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
        </div>
      );
    };

    if (!data) {
      return <LoadingSpinner />;
    }
    return (
      <div className="w-4/12 dark:bg-[#2B2A49] bg-[#CAC9F1]">
        <div className="py-[24px] bg-[#FAFAFE] dark:bg-[#3A3A68] rounded-[8px] flex justify-between">
          <div className="flex gap-[13px]">
            <p className="text-[18px] text-[#8A8A8A] ml-3 dark:text-[#FFF]">
              {title}
            </p>
            <span
              className="text-white rounded-full flex justify-center items-center w-[30px] h-[30px]"
              style={{ backgroundColor: titleColors[title] }}
            >
              {count}
            </span>
          </div>
          <img src="/images/dots.svg" width={30} height={30} alt="" />
        </div>
        <div
          className="py-[24px] my-[10px] bg-[#FAFAFE] rounded-[8px] flex justify-center cursor-pointer dark:bg-[#3A3A68]"
          onClick={() => {
            setTicketFormOpen(true);
            setInitialStatus(title);
          }}
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="ep:plus">
              <path
                id="Vector"
                d="M9.375 10.625V17.5C9.375 17.6658 9.44085 17.8247 9.55806 17.9419C9.67527 18.0592 9.83424 18.125 10 18.125C10.1658 18.125 10.3247 18.0592 10.4419 17.9419C10.5592 17.8247 10.625 17.6658 10.625 17.5V10.625H17.5C17.6658 10.625 17.8247 10.5592 17.9419 10.4419C18.0592 10.3247 18.125 10.1658 18.125 10C18.125 9.83424 18.0592 9.67527 17.9419 9.55806C17.8247 9.44085 17.6658 9.375 17.5 9.375H10.625V2.5C10.625 2.33424 10.5592 2.17527 10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875C9.83424 1.875 9.67527 1.94085 9.55806 2.05806C9.44085 2.17527 9.375 2.33424 9.375 2.5V9.375H2.5C2.33424 9.375 2.17527 9.44085 2.05806 9.55806C1.94085 9.67527 1.875 9.83424 1.875 10C1.875 10.1658 1.94085 10.3247 2.05806 10.4419C2.17527 10.5592 2.33424 10.625 2.5 10.625H9.375Z"
                fill="#656565"
                className={`fill-current dark:text-[#EDEDFB]`}
              />
            </g>
          </svg>
        </div>
        <DropZone onDrop={moveCard} columnName={columnName}>
          <div className="py-[24px] bg-[#CAC9F1] rounded-[8px] dark:bg-[#2B2A49] flex flex-col gap-5">
            {projectWithTasks
              ? tasks
                  .filter((t) => t.proiect === projectWithTasks)
                  .map((task) => (
                    <TicketCard
                      key={task._id}
                      id={task._id}
                      task={task}
                      onDelete={handleDeleteTask}
                    />
                  ))
              : tasks.map((task) => (
                  <TicketCard
                    key={task._id}
                    id={task._id}
                    task={task}
                    onDelete={handleDeleteTask}
                  />
                ))}
          </div>
        </DropZone>
      </div>
    );
  };

  return (
    <DraggingContext.Provider value={{ dragging, setDragging }}>
      <DndProvider backend={isMobileView ? TouchBackend : HTML5Backend}>
        <div
          className={`pr-[54px] pt-[25px] h-[100vh] ${
            projectWithTasks ? "" : "overflow-y-auto scrolling-touch "
          } bg-[#CAC9F1] dark:bg-[#2B2A49]`}
        >
          <div className="w-full">
            {projectWithTasks ? (
              ""
            ) : (
              <>
                <div className="p-4 pl-0 flex items-center flex-wrap bg-[#CAC9F1] dark:bg-[#2B2A49] justify-between">
                  <ul className="flex items-center">
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      className="bg-[#CAC9F1] dark:bg-[#2B2A49] dark:text-[#EDEDFB]"
                    >
                      <Link
                        underline="hover"
                        className="text-[#4A488E] dark:text-[#EDEDFB]"
                        to="/dashboard"
                      >
                        Fuse Digital
                      </Link>
                      <Link
                        underline="hover"
                        className="text-[#4A488E] dark:text-[#EDEDFB]"
                        to={`/taskslist/my-tasks`}
                      >
                        Tasks list
                      </Link>
                      <Typography className="text-[#4A488E] font-semibold dark:text-[#EDEDFB]">
                        {category}
                      </Typography>
                    </Breadcrumbs>
                  </ul>
                  <NotificationComponent />
                </div>
                <p className="text-[30px] text-[#4A488E] mt-[39px] mb-[34px] dark:text-[#EDEDFB]">
                  {category.replace(/-/g, " ")}
                </p>
              </>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between gap-[90px]">
                <SearchBar
                  data={data?.tickets}
                  onSearchResults={handleSearchResults}
                />
                <div className="flex items-center">
                  <div className="flex gap-4 ml-[10px]">
                    <span className="px-[10px] py-[8px] bg-[#FAFAFE] text-[#4A488E] font-medium text-[11px] rounded-[20px] dark:bg-[#33335A] dark:text-[#FFFFFF]">
                      Active: {activeCount} tasks
                    </span>
                    <span className="px-[10px] py-[8px] bg-[#FAFAFE] text-[#4A488E] font-medium text-[11px] rounded-[20px] dark:bg-[#33335A] dark:text-[#FFFFFF]">
                      Completed: {completedCount} tasks
                    </span>
                    <span className="px-[10px] py-[8px] bg-[#FAFAFE] text-[#4A488E] font-medium text-[11px] rounded-[20px] dark:bg-[#33335A] dark:text-[#FFFFFF]">
                      Total: {totalCount} tasks
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 items-center">
                <div>
                  <button
                    onClick={() => navigate("/taskslist/completed/history")}
                    className="px-[10px] py-[8px] bg-[#FAFAFE] text-[#4A488E] font-medium text-[11px] rounded-[20px] dark:bg-[#33335A] dark:text-[#FFFFFF]"
                  >
                    View Completed History
                  </button>
                </div>
                <Dropdown
                  setCategory={handleOptionClick}
                  selectedCategory={selectedCategory}
                />
              </div>
            </div>
            <div className="py-[34px] flex gap-[15px] flex-wrap">
              <div className="flex w-full gap-[15px]">
                {renderColumn(
                  "todo",
                  "To do",
                  searchResults.length
                    ? searchResults.filter((task) => task.status === "To do")
                        .length
                    : cards.todo.length
                )}
                {renderColumn(
                  "inProgress",
                  "In Progress",
                  searchResults.length
                    ? searchResults.filter(
                        (task) => task.status === "In Progress"
                      ).length
                    : cards.inProgress.length
                )}
                {renderColumn(
                  "completed",
                  "Completed",
                  searchResults.length
                    ? searchResults.filter(
                        (task) => task.status === "Completed"
                      ).length
                    : cards.completed.length
                )}
              </div>
            </div>
          </div>
        </div>
        <TicketForm
          initialStatus={initialStatus}
          open={ticketFormOpen}
          projectWithTasks={projectWithTasks} // Pass the project with tasks
          onClose={() => setTicketFormOpen(false)}
        />
      </DndProvider>
    </DraggingContext.Provider>
  );
};

export default TicketsPanel;
