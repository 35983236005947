import React from 'react';

const FuseButton = ({ label, onClick, type = 'button', style = {}, className = '' }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`flex justify-center items-center bg-[#8886C9] text-white rounded-[8px] py-[24px] px-[155px] gap-[10px] w-[200px] h-[60px] ${className}`}
      style={style}
    >
      {label}
    </button>
  );
};

export default FuseButton;
