import React, { useContext } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { BenefitsCard } from "./AdminHR/BenefitsCard";
import { useGetProvidersQuery } from "../../redux/api/provider";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
export const Benefits = () => {
  const {
    data: providersData,
    error: providersError,
    isLoading: providersLoading,
    refetch,
  } = useGetProvidersQuery();
  const isMobileView = useContext(ViewTypeContext);
  React.useEffect(() => {
    if (providersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetch();
      }, 5000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [providersData, refetch]);
  return (
    <div
      className={
        isMobileView
          ? "pr-[54px] pl-[54px] pt-[25px] ml-[-59px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
          : "pr-[54px] pt-[25px] ml-[-59px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
      }
    >
      <div className="w-full px-[40px] md:mb-40">
        <div className="p-4 flex items-center flex-wrap">
          <ul className="flex items-center ">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="text-[#4A488E] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/HR`}
              >
                HR
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/HR/Benefits`}
              >
                Benefits
              </Link>
            </Breadcrumbs>
          </ul>
        </div>
        <div className="text-stone-500 text-3xl font-normal dark:text-[#EDEDFB]  mt-[40px] mb-[53px]">
          Benefits
        </div>
        <div className="flex flex-row flex-wrap gap-4 ml-[20px]">
          {providersData?.providers.map((p) => (
            <BenefitsCard key={p.id} provider={p} isAdmin={false} />
          ))}
        </div>
      </div>
    </div>
  );
};
