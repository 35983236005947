import React from "react";
import { Link } from "react-router-dom";

// Helper function to convert seconds to hh:mm:ss format
const formatSecondsToTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

const ProjectTimeTracking = ({ project, setIsDetalied, time }) => {
  if (time?.totalSeconds === 0) {
    return null; // Return nothing if totalSeconds is 0
  }

  return (
    <div className="w-full border-b dark:border-[#d4d4eb57] border-[#CAC9F1] py-[20px] flex justify-between items-center">
      <div onClick={() => setIsDetalied(true)}>
        <Link to={`/reports/operational-reports/${project.projectId}`}>
          <p className="text-[18px] dark:text-[#D4D4EB]">
            {project.projectName}
          </p>
        </Link>
      </div>
      <p className="text-[18px] dark:text-[#D4D4EB]">
        {time.totalTime.hours.toString().padStart(2, "0") +
          ":" +
          time.totalTime.minutes.toString().padStart(2, "0") +
          ":" +
          time.totalTime.seconds.toString().padStart(2, "0")}
      </p>
    </div>
  );
};

export default ProjectTimeTracking;
