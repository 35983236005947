import React, { useState, useEffect, useContext } from "react";
import {
  Breadcrumbs,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import {
  useGetAllAppraisalsQuery,
  useSubmitAdminFeedbackMutation,
  useDeleteAdminFeedbackMutation,
} from "../../redux/api/appraisailsApi";
import TextEditor from "../utils/TextEditor/TextEditor";
import RenderedContent from "../utils/TextEditor/RenderedContent";
import { notify } from "../utils/notificationManager";

const feedbackMapping = {
  "Reflect on the Past": "reflectOnPast",
  "Plan for the Future": "planForFuture",
  "Opportunities for Growth": "opportunitiesForGrowth",
};

export const AppraisalAdmView = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [expandedUserIndex, setExpandedUserIndex] = useState(null);
  const [selectedAppraisal, setSelectedAppraisal] = useState(null);
  const [feedbackState, setFeedbackState] = useState({});
  const [editingFeedback, setEditingFeedback] = useState({});
  const [submitAdminFeedback] = useSubmitAdminFeedbackMutation();
  const [deleteAdminFeedback] = useDeleteAdminFeedbackMutation();
  const isMobileView = useContext(ViewTypeContext);

  const currentYear = new Date().getFullYear();
  const [selectedPeriod, setSelectedPeriod] = useState("");

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
    const year = e.target.value === "This Year" ? currentYear : currentYear + 1;
    setSelectedDate({
      startDate: `${year}-01-01`,
      endDate: `${year}-12-31`,
    });
    refetchAppraisals();
  };

  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
    isFetching: usersFetching,
    refetch: refetchUsers,
  } = useGetAllUsersQuery({
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
  });

  const {
    data: appraisalsData,
    isLoading: appraisalsLoading,
    refetch: refetchAppraisals,
  } = useGetAllAppraisalsQuery({
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
  });

  useEffect(() => {
    if (usersData) {
      refetchUsers();
    }
  }, [refetchUsers]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleAccordion = (index, userId) => {
    setExpandedUserIndex((prevIndex) => (prevIndex === index ? null : index));
    if (appraisalsData && userId) {
      const appraisal = appraisalsData.appraisals.find((a) =>
        a.departments?.Development?.userResponses.some(
          (ur) => ur.userId === userId
        )
      );
      setSelectedAppraisal(appraisal);
    }
  };

  const handleFeedbackChange = (responseId, value) => {
    setFeedbackState((prev) => ({
      ...prev,
      [responseId]: value,
    }));
  };

  const handleFeedbackSubmit = (userId, department, half, category) => {
    const mappedCategory = feedbackMapping[category];
    const responseId = `${userId}-${half}-${category}`;

    const feedbackPayload = {
      id: selectedAppraisal._id,
      department: department,
      userId: userId,
      feedback: feedbackState[responseId] || "",
      half: half === "firstHalf" ? "first" : "second",
      category: mappedCategory,
    };

    submitAdminFeedback(feedbackPayload)
      .unwrap()
      .then(() => {
        refetchAppraisals().then((newData) => {
          setSelectedAppraisal(
            newData?.data?.appraisals?.find(
              (a) => a._id === selectedAppraisal._id
            )
          );
          notify("Feedback submitted successfully", 0, null, null, true);
          setFeedbackState((prev) => ({ ...prev, [responseId]: "" }));
          setEditingFeedback((prev) => ({ ...prev, [responseId]: false }));
        });
      })
      .catch((error) => {
        console.error("Failed to submit feedback:", error);
      });
  };

  const handleDeleteFeedback = (userId, department, half, category) => {
    const mappedCategory = feedbackMapping[category];
    const responseId = `${userId}-${half}-${category}`;

    deleteAdminFeedback({
      id: selectedAppraisal._id,
      department: department,
      userId: userId,
      half: half === "firstHalf" ? "first" : "second",
      category: mappedCategory,
    })
      .unwrap()
      .then(() => {
        setFeedbackState((prev) => {
          const newState = { ...prev };
          delete newState[responseId];
          return newState;
        });

        setEditingFeedback((prev) => ({
          ...prev,
          [responseId]: false,
        }));
        notify("Feedback deleted successfully", 0, null, null, true);
        refetchAppraisals().then((newData) => {
          setSelectedAppraisal(
            newData?.data?.appraisals?.find(
              (a) => a._id === selectedAppraisal._id
            )
          );
        });
      })
      .catch((error) => {
        console.error("Failed to delete feedback:", error);
      });
  };

  const renderFeedbackEditorOrResponse = (category, content, userId, half) => {
    const responseId = `${userId}-${half}-${category}`;
    const isEditing = editingFeedback[responseId];

    if (isEditing || !content) {
      return (
        <>
          <TextEditor
            initialValue={feedbackState[responseId] || content || ""}
            onChange={(value) => handleFeedbackChange(responseId, value)}
          />
          <div className="mt-4 w-full flex items-end justify-end">
            <Button
              onClick={() =>
                setEditingFeedback((prev) => ({ ...prev, [responseId]: false }))
              }
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                handleFeedbackSubmit(userId, "Development", half, category)
              }
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <RenderedContent
            content={content}
            className="text-[#484878] dark:text-white font-medium"
          />
          <Button
            variant="text"
            color="primary"
            className="mt-4"
            onClick={() =>
              setEditingFeedback((prev) => ({ ...prev, [responseId]: true }))
            }
          >
            Edit
          </Button>
        </div>
      );
    }
  };

  const renderSubAccordion = (category, content, userId, half) => {
    const responseId = `${userId}-${half}-${category}`;
    const adminFeedback =
      selectedAppraisal.departments.Development.userResponses.find(
        (response) => response.userId === userId
      )?.[half]?.adminFeedback?.[feedbackMapping[category]]?.feedback || "";

    const isEditing = editingFeedback[responseId] || false;

    return (
      <Accordion key={category} className="bg-transparent mt-2" elevation={0}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon className="text-[#484878] dark:text-white" />
          }
        >
          <Typography className="text-lg font-semibold text-[#484878] dark:text-white">
            {category}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex gap-4">
            <div className="flex-1 bg-[#f4f4fc] dark:bg-transparent rounded-lg p-2">
              <Typography className="text-[#484878] dark:text-white font-semibold mb-1">
                Response:
              </Typography>
              <RenderedContent
                content={content}
                className="text-[#484878] dark:text-white font-medium"
              />
            </div>
            <div className="flex-1 bg-[#f4f4fc] dark:bg-transparent rounded-lg p-2">
              {adminFeedback && !isEditing ? (
                <>
                  <Typography className="text-[#484878] dark:text-white font-semibold mb-1">
                    Your response:
                  </Typography>
                  <RenderedContent
                    content={adminFeedback}
                    className="text-[#484878] dark:text-white font-medium"
                  />
                  <div className="flex flex-row items-end justify-center w-full gap-2">
                    <button
                      onClick={() =>
                        handleDeleteFeedback(
                          userId,
                          "Development",
                          half,
                          category
                        )
                      }
                      className="h-[38px] px-5 py-2.5 bg-[#ededfb] rounded-[5px] justify-center items-center gap-2.5 inline-flex"
                    >
                      <p className="text-[#2b2a49] text-[15px] font-normal ">
                        Delete
                      </p>
                    </button>
                    <button
                      onClick={() =>
                        setEditingFeedback((prev) => ({
                          ...prev,
                          [responseId]: true,
                        }))
                      }
                      className="h-[38px] px-5 py-2.5 bg-[#ededfb] rounded-[5px] justify-center items-center gap-2.5 inline-flex"
                    >
                      <p className="text-[#2b2a49] text-[15px] font-normal ">
                        Edit
                      </p>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <Typography className="text-[#484878] dark:text-white font-semibold mb-1">
                    Write your response:
                  </Typography>
                  {renderFeedbackEditorOrResponse(
                    category,
                    adminFeedback,
                    userId,
                    half
                  )}
                </>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderHalfAccordion = (half, expectations, userResponse) => {
    if (!expectations || !userResponse?.[half]) return null;

    const { reflectOnPast, planForFuture, opportunitiesForGrowth } =
      userResponse[half] || {};

    return (
      <Accordion className="bg-transparent mt-4" elevation={0}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon className="text-[#484878] dark:text-white" />
          }
        >
          <Typography>
            <div className="text-lg font-bold underline text-[#484878] dark:text-white">
              {half === "firstHalf" ? "First Half" : "Second Half"}
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RenderedContent
            content={expectations || "No expectations available."}
            className="text-[#484878] dark:text-white font-medium"
          />
          {reflectOnPast &&
            renderSubAccordion(
              "Reflect on the Past",
              reflectOnPast,
              userResponse.userId,
              half
            )}
          {planForFuture &&
            renderSubAccordion(
              "Plan for the Future",
              planForFuture,
              userResponse.userId,
              half
            )}
          {opportunitiesForGrowth &&
            renderSubAccordion(
              "Opportunities for Growth",
              opportunitiesForGrowth,
              userResponse.userId,
              half
            )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderExpectationsAndResponses = (userId) => {
    if (!selectedAppraisal || !appraisalsData?.appraisals?.length) return null;

    const { departments } = selectedAppraisal;
    const department = departments?.Development;

    if (!department) return <Typography>No data available</Typography>;

    const expectations = department.expectations || {};
    const userResponse = department.userResponses.find(
      (response) => response.userId === userId
    );

    return (
      <>
        {renderHalfAccordion("firstHalf", expectations.first, userResponse)}
        {renderHalfAccordion("secondHalf", expectations.second, userResponse)}
      </>
    );
  };

  const filteredUsers = usersData?.users?.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className={
        isMobileView
          ? "pr-[54px] pl-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch bg-[#FFF] dark:bg-[#2B2A49]"
          : "pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch bg-[#FFF] dark:bg-[#2B2A49]"
      }
    >
      <div className="w-full px-[40px]">
        <div className="bg-white p-4 flex dark:bg-[#2B2A49]">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-[300px] text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Typography
                component="span"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
              >
                Fuse Digital
              </Typography>
              <Typography
                component="span"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
              >
                HR
              </Typography>
              <Typography
                component="span"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
              >
                Appraisal
              </Typography>
            </Breadcrumbs>
          </ul>
        </div>
        <p className="text-[30px] text-[#4A488E] mt-[39px] mb-[34px] ml-[20px] dark:text-[#EDEDFB]">
          Appraisal Admin
        </p>
      </div>
      <div className="px-[40px] flex items-center gap-4 mb-6">
        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-[411px] h-[55px] bg-[#E8E8FF] dark:bg-[#33335A] border-none rounded-[52px] placeholder-[#979BE2] dark:placeholder-[#B0B3D1] text-[#4A488E] dark:text-[#E8E8FF] text-lg focus:outline-none"
        />

        {/* Select Dropdown */}
        <select
          value={selectedPeriod}
          onChange={handlePeriodChange}
          className="w-[220px] h-[55px] bg-[#E8E8FF] dark:bg-[#33335A] border-none rounded-[52px] text-[#4A488E] dark:text-[#E8E8FF] text-lg focus:outline-none"
        >
          <option value="This Year">This Year</option>
          <option value="Next Year">Next Year</option>
        </select>
      </div>
      <div className="px-[40px] md:mb-40">
        {usersLoading || usersFetching ? (
          <div className="flex justify-center items-center h-full">
            <div>Loading...</div>
          </div>
        ) : usersError ? (
          <div className="text-red-500">Error loading users.</div>
        ) : (
          filteredUsers?.map((user, index) => (
            <div key={user._id} className="mb-4">
              <div className="bg-[#E8E8FF] p-4 rounded-lg dark:bg-[#3A3A68]">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {user.avatar?.url ? (
                      <img
                        src={user.avatar.url}
                        alt={user.name}
                        className="rounded-full w-10 h-10 object-cover"
                      />
                    ) : (
                      <div className="bg-purple-500 text-white rounded-full w-10 h-10 flex items-center justify-center">
                        {user.name.charAt(0)}
                      </div>
                    )}
                    <div className="ml-4">
                      <p className="font-medium text-lg dark:text-[#EDEDFB]">
                        {user.name}
                      </p>
                    </div>
                  </div>
                  <div
                    className="flex items-center justify-center w-6 h-6 cursor-pointer"
                    onClick={() => toggleAccordion(index, user._id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="10"
                      fill="none"
                      className={`transform transition-transform ${
                        expandedUserIndex === index ? "rotate-180" : "rotate-0"
                      }`}
                    >
                      <path
                        d="M0 0.62341C0 0.465263 0.0671356 0.303421 0.197455 0.182053C0.458092 -0.0606832 0.88459 -0.0606831 1.14523 0.182053L10.0661 8.49026L18.8567 0.303424C19.1174 0.0606875 19.5439 0.0606876 19.8045 0.303424C20.0652 0.54616 20.0652 0.943366 19.8045 1.1861L10.54 9.81795C10.2794 10.0607 9.8529 10.0607 9.59226 9.81795L0.197454 1.06841C0.0631864 0.943363 0 0.785234 0 0.62341Z"
                        fill="#8D8BDB"
                      />
                    </svg>
                  </div>
                </div>
                {expandedUserIndex === index && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: "auto" }}
                    className="mt-4"
                  >
                    {renderExpectationsAndResponses(user._id)}
                  </motion.div>
                )}
              </div>
            </div>
          ))
        )}
        {!appraisalsLoading && appraisalsData?.appraisals.length === 0 && (
          <div className="text-center text-[#4A488E] dark:text-white mt-8">
            No appraisals found for the selected date range.
          </div>
        )}
      </div>
    </div>
  );
};

export default AppraisalAdmView;
