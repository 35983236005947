import React, { useState, useEffect } from "react";
import SquareInput from "../UI/input";
import SquareDropdown from "../UI/dropdown";
import FuseButton from "../UI/button";
import FuseDatepicker from "../UI/datepicker";
import ProductSearchBar from "../UI/productSearchBar";
import { notify } from "../../utils/notificationManager";

const RestockModal = ({
  isOpen,
  onClose,
  onSave,
  restock = null,
  onProductSearch,
  filteredProducts,
}) => {
  const [formData, setFormData] = useState({
    productName: "",
    productId: null, // Store the productId as well
    priority: "",
    restockQuantity: 0,
    supplier: "",
    orderDate: "",
    expectedDelivery: "",
    comment: "",
    status: "Waiting", // Default status for new restock
  });

  useEffect(() => {
    if (restock) {
      setFormData({
        productName: restock.product.name || "", // Pre-fill product name
        productId: restock.product._id || null, // Pre-fill product ID
        priority: restock.priority || "",
        restockQuantity: restock.restockQuantity || 0,
        supplier: restock.supplier || "",
        orderDate: restock.orderDate || "",
        expectedDelivery: restock.expectedDelivery || "",
        comment: restock.comment || "",
        status: restock.status || "Waiting",
      });
    } else {
      resetForm(); // Reset the form when creating a new restock
    }
  }, [restock]);

  // Reset form data
  const resetForm = () => {
    setFormData({
      productName: "",
      productId: null, // Reset productId as well
      priority: "",
      restockQuantity: 0,
      supplier: "",
      orderDate: "",
      expectedDelivery: "",
      comment: "",
      status: "Waiting",
    });
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Ensure a product has been selected
    if (!formData.productId) {
      notify("Please select a product.", 2, null, null, true);
      return;
    }

    // Ensure required fields are filled in
    if (
      formData.productName &&
      formData.priority &&
      formData.restockQuantity &&
      formData.supplier &&
      formData.orderDate &&
      formData.expectedDelivery
    ) {
      // Check if it's an edit or a new restock
      if (restock && restock._id) {
        // Call the update function since we have an existing restock
        onSave({ ...formData, _id: restock._id }); // Pass formData and the restock ID to update
      } else {
        // Call the save function to create a new restock
        onSave(formData);
      }

      onClose(); // Close the modal
    }
  };

  if (!isOpen) return null; // Don't render modal if not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="max-w-[90%] w-[803px] max-h-[90vh] overflow-y-auto scrolling-touch  px-[20px] py-10 bg-white dark:bg-[#2b2a49] rounded-[14px]">
        <div className="w-full flex flex-col">
          <div className="flex justify-between">
            <div className="text-[#2B2A49] dark:text-[#ededfb] text-[25px] font-normal">
              {restock ? "Edit Restock Request" : "New Restock Request"}
            </div>
            <button className="dark:text-white text-2xl" onClick={onClose}>
              &times;
            </button>
          </div>

          <div className="flex-col justify-start items-start gap-[15px] mt-10 space-y-4">
            {/* Product Search */}
            <ProductSearchBar
              label="Search Product"
              products={filteredProducts} // Pass filtered products from the parent
              onSearchQuery={onProductSearch} // Send the search query back to the parent
              onSelectProduct={(selectedProduct) => {
                setFormData((prevData) => ({
                  ...prevData,
                  productName: selectedProduct.name, // Set product name when selected
                  productId: selectedProduct._id, // Store the selected product's _id as well
                }));
              }}
              selectedProduct={
                restock
                  ? { _id: restock.product._id, name: restock.product.name }
                  : null
              } // Pass correct pre-selected product when editing
            />

            {/* Priority Dropdown */}
            <SquareDropdown
              label="Priority"
              value={formData.priority}
              onChange={(value) =>
                setFormData({ ...formData, priority: value })
              }
              options={[
                { value: "Low", label: "Low" },
                { value: "Medium", label: "Medium" },
                { value: "High", label: "High" },
              ]}
              placeholder="Select priority"
            />

            {/* Restock Quantity */}
            <SquareInput
              label="Restock Quantity"
              type="number"
              value={formData.restockQuantity}
              onChange={(value) =>
                handleChange({ target: { name: "restockQuantity", value } })
              }
              placeholder="Enter restock quantity"
            />

            {/* Supplier Input */}
            <SquareInput
              label="Supplier"
              type="text"
              value={formData.supplier}
              onChange={(value) =>
                handleChange({ target: { name: "supplier", value } })
              }
              placeholder="Enter supplier name"
            />

            {/* Order Date Picker */}
            <FuseDatepicker
              label="Order Date"
              value={formData.orderDate}
              onChange={(value) =>
                handleChange({ target: { name: "orderDate", value } })
              }
            />

            {/* Expected Delivery Date Picker */}
            <FuseDatepicker
              label="Expected Delivery"
              value={formData.expectedDelivery}
              onChange={(value) =>
                handleChange({ target: { name: "expectedDelivery", value } })
              }
            />

            {/* Comment Field */}
            <SquareInput
              label="Comment"
              type="textarea"
              value={formData.comment}
              onChange={(value) =>
                handleChange({ target: { name: "comment", value } })
              }
              placeholder="Enter any additional details"
            />

            {/* Status Dropdown */}
            <SquareDropdown
              label="Status"
              value={formData.status}
              onChange={(value) => setFormData({ ...formData, status: value })}
              options={[
                { value: "Waiting", label: "Waiting" },
                { value: "Completed", label: "Completed" },
                { value: "Canceled", label: "Canceled" },
              ]}
            />
          </div>

          {/* Save Button */}
          <div className="flex justify-end mt-4">
            <FuseButton
              label={restock ? "Save" : "Create"}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestockModal;
