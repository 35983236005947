import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const DropdownReportsUsers = ({
  setSelectedUserType,
  setCurrentUserId,
  selectedUserType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const userTypes = ["Team", "Only Me"]; // Define your user types here
  const userData = useSelector((state) => state.user.user); // Assuming user data is stored in Redux

  // Create a ref for the dropdown container
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (type) => {
    setSelectedUserType(type); // Set the selected user type

    if (type === "Only Me") {
      setCurrentUserId(userData?._id); // Set the current user ID if "Only Me" is selected
    } else if (type === "Team") {
      setCurrentUserId(null); // Set user ID to null if "Team" is selected
    }

    setIsOpen(false); // Close the dropdown after selection
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdownRef
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      // Add event listener when dropdown is open
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="justify-center min-w-[120px] rounded-md flex items-center py-[10px] px-[15px] border border-[#7674C2] bg-[#E7E7F9] dark:bg-[#E7E7F9] gap-[10px]"
        onClick={toggleDropdown}
      >
        {selectedUserType ? selectedUserType : "Select User Type"}
        <span>
          <div className="text-[#4A488E] dark:text-[#4A488E]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="currentColor"
            >
              <path
                d="M2.37217 4.59942C2.31347 4.54636 2.2449 4.50538 2.17036 4.47882C2.09583 4.45226 2.01679 4.44064 1.93776 4.44462C1.85874 4.44861 1.78127 4.46812 1.70978 4.50204C1.6383 4.53597 1.57419 4.58364 1.52113 4.64234C1.46807 4.70104 1.42709 4.76961 1.40053 4.84415C1.37397 4.91868 1.36235 4.99772 1.36633 5.07675C1.37032 5.15577 1.38983 5.23324 1.42375 5.30473C1.45767 5.37621 1.50535 5.44032 1.56404 5.49338L7.09741 10.4926C7.21156 10.5959 7.36091 10.6515 7.51479 10.6481C7.66867 10.6447 7.81541 10.5825 7.92489 10.4744L13.2325 5.23545C13.2901 5.18017 13.3361 5.11401 13.3679 5.04081C13.3997 4.96761 13.4166 4.88882 13.4176 4.80902C13.4187 4.72922 13.4039 4.65001 13.3741 4.57598C13.3443 4.50195 13.3001 4.43458 13.244 4.37778C13.188 4.32098 13.1212 4.27589 13.0476 4.24512C12.9739 4.21435 12.8949 4.19852 12.8151 4.19855C12.7353 4.19858 12.6563 4.21446 12.5827 4.24527C12.5091 4.27609 12.4423 4.32123 12.3863 4.37806L7.48316 9.21758L2.37217 4.59942Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </span>
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-full rounded-md shadow-lg bg-white z-50 dark:bg-[#383757]">
          <ul className="py-1">
            {userTypes.map((type) => (
              <li
                key={type}
                className="px-4 py-2 cursor-pointer hover:bg-[#404074] flex items-center dark:text-[#EDEDFB] text-[#5B5B5B]"
                onClick={() => handleOptionClick(type)}
              >
                {type}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownReportsUsers;
