import React, { useContext, useEffect, useState } from "react";
import {
  useUploadContractMutation,
  useUpdateProviderMutation,
  useDeleteProviderMutation,
} from "../../../redux/api/provider";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Menu,
  MenuItem,
} from "@mui/material";
import { notify } from "../../utils/notificationManager";
import { motion } from "framer-motion";
import { ViewTypeContext } from "../../utils/contexts/viewTypeContext";

export const BenefitsCard = ({ provider, isAdmin, setShouldRefetch }) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editedProvider, setEditedProvider] = useState({
    name: "",
    description: "",
    documente: [],
  });
  const isMobileView = useContext(ViewTypeContext);
  useEffect(() => {
    setEditedProvider(provider);
  }, [provider]);

  const handleOpenEditDialog = () => {
    setOpenEditDialog(!openEditDialog);
    handleCloseMenu();
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(!openDeleteDialog);
    handleCloseMenu();
  };

  const handleFileChange = (e) => {
    setContractFile(e.target.files[0]);
  };
  const [uploadContract] = useUploadContractMutation();
  const [updateProvider] = useUpdateProviderMutation();
  const [deleteProvider] = useDeleteProviderMutation();

  const handleSave = async () => {
    let documente = [...editedProvider.documente];

    if (contractFile) {
      const formData = new FormData();
      formData.append("file", contractFile);

      const res = await uploadContract(formData);
      if (!res.data || !res.data.links || res.data.links.length === 0) {
        console.error("File upload failed");
        return;
      }

      documente.push(res.data.links[0]);
    }

    const benefitData = {
      ...provider,
      name: editedProvider.name,
      description: editedProvider.description,
      documente: documente,
    };

    try {
      await updateProvider({ id: provider._id, ...benefitData });
      notify("Provider updated successfully", 0, null, null, true);
      setOpenEditDialog(false);
      setShouldRefetch(true); // Trigger the refetch
    } catch (error) {
      console.error("Failed to update provider", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProvider((prevProvider) => ({ ...prevProvider, [name]: value }));
  };

  const removeDoc = (docIndex) => {
    setEditedProvider((prevProvider) => ({
      ...prevProvider,
      documente: prevProvider.documente.filter(
        (_, index) => index !== docIndex
      ),
    }));
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await deleteProvider(provider._id);
      notify("Provider deleted successfully", 0, null, null, true);
      setOpenDeleteDialog(false);
      setShouldRefetch(true); // Trigger the refetch
    } catch (error) {
      console.error("Failed to delete provider", error);
    }
  };

  return (
    <motion.div
      className=" h-fit relative group"
      whileHover={{ scale: 0.99, boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}
      transition={{ duration: 0.2 }}
    >
      <div
        className={
          isMobileView
            ? "w-[480px] h-[226px] bg-[#E8E8FF] dark:bg-[#3A3A68] rounded-lg p-[27px]"
            : "w-[508px] h-[226px] bg-[#E8E8FF] dark:bg-[#3A3A68] rounded-lg p-[27px]"
        }
      >
        <div>
          <div className=" text-[#4A488E] dark:text-[#EDEDFB] text-xl font-semibold w-full mb-[27px] flex flex-row justify-between">
            {provider.name}
            {isAdmin ? (
              <>
                <div
                  id="benefitCardOptions"
                  onClick={handleOpenMenu}
                  className="cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="5"
                    viewBox="0 0 18 5"
                    fill="none"
                  >
                    <path
                      className="fill-current dark:text-[#EDEDFB]"
                      d="M4 2.49935C4 3.05188 3.78929 3.58179 3.41421 3.97249C3.03914 4.36319 2.53043 4.58268 2 4.58268C1.46957 4.58268 0.960859 4.36319 0.585786 3.97249C0.210714 3.58179 0 3.05188 0 2.49935C0 1.94681 0.210714 1.41691 0.585786 1.02621C0.960859 0.635509 1.46957 0.416016 2 0.416016C2.53043 0.416016 3.03914 0.635509 3.41421 1.02621C3.78929 1.41691 4 1.94681 4 2.49935ZM11 2.49935C11 3.05188 10.7893 3.58179 10.4142 3.97249C10.0391 4.36319 9.53043 4.58268 9 4.58268C8.46957 4.58268 7.96086 4.36319 7.58579 3.97249C7.21071 3.58179 7 3.05188 7 2.49935C7 1.94681 7.21071 1.41691 7.58579 1.02621C7.96086 0.635509 8.46957 0.416016 9 0.416016C9.53043 0.416016 10.0391 0.635509 10.4142 1.02621C10.7893 1.41691 11 1.94681 11 2.49935ZM18 2.49935C18 3.05188 17.7893 3.58179 17.4142 3.97249C17.0391 4.36319 16.5304 4.58268 16 4.58268C15.4696 4.58268 14.9609 4.36319 14.5858 3.97249C14.2107 3.58179 14 3.05188 14 2.49935C14 1.94681 14.2107 1.41691 14.5858 1.02621C14.9609 0.635509 15.4696 0.416016 16 0.416016C16.5304 0.416016 17.0391 0.635509 17.4142 1.02621C17.7893 1.41691 18 1.94681 18 2.49935Z"
                      fill="#4A488E"
                    />
                  </svg>
                </div>
                <Menu
                  id="benefit-card-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    className:
                      "bg-white dark:bg-[#1c1c30] text-black dark:text-[#EDEDFB]",
                  }}
                >
                  <MenuItem
                    onClick={handleOpenEditDialog}
                    className="flex flex-row gap-2 dark:bg-[#1c1c30]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_730_8311)">
                        <path
                          d="M11.2893 3.273L9.33262 1.30633C9.20332 1.17769 9.02835 1.10547 8.84595 1.10547C8.66356 1.10547 8.48858 1.17769 8.35929 1.30633L1.42262 8.233L0.789286 10.9663C0.767439 11.0662 0.768188 11.1698 0.791479 11.2694C0.81477 11.369 0.860014 11.4621 0.923908 11.542C0.987801 11.6218 1.06873 11.6864 1.16078 11.731C1.25283 11.7756 1.35368 11.799 1.45595 11.7997C1.50361 11.8045 1.55163 11.8045 1.59929 11.7997L4.36262 11.1663L11.2893 4.24633C11.4179 4.11703 11.4901 3.94206 11.4901 3.75966C11.4901 3.57727 11.4179 3.4023 11.2893 3.273ZM4.02929 10.5663L1.43929 11.1097L2.02929 8.56966L7.21929 3.39966L9.21929 5.39966L4.02929 10.5663ZM9.66595 4.91633L7.66595 2.91633L8.82595 1.763L10.7926 3.763L9.66595 4.91633Z"
                          fill="#8D8BDB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_730_8311">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="text-neutral-400 dark:text-[#EDEDFB] text-[13px] font-normal">
                      Edit
                    </span>
                  </MenuItem>
                  <MenuItem
                    onClick={handleOpenDeleteDialog}
                    className="flex flex-row gap-2 dark:bg-[#1c1c30]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_730_8316)">
                        <path
                          d="M5.30844 0.486328C4.40475 0.486328 3.67875 1.23725 3.67875 2.1451V2.34679H1.8469C1.5926 2.34679 1.38537 2.55402 1.38537 2.80833V3.26987H0.923828V4.19294H1.8469V11.116C1.8469 11.8753 2.47229 12.5006 3.23152 12.5006H8.76998C9.52921 12.5006 10.1546 11.8753 10.1546 11.116V4.19294H11.0777V3.26987H10.6161V2.80833C10.6161 2.55402 10.4089 2.34679 10.1546 2.34679H8.32275V2.14464C8.32275 1.23725 7.59675 0.486328 6.69306 0.486328H5.30844ZM5.30844 1.43802H6.69306C7.06414 1.43802 7.37106 1.74079 7.37106 2.14464V2.34679H4.62998V2.14464C4.62998 1.74079 4.9369 1.43848 5.30798 1.43848L5.30844 1.43802ZM2.76998 4.19294H5.13537C5.1926 4.19894 5.24937 4.20725 5.30844 4.20725H6.69306C6.75214 4.20725 6.80844 4.19894 6.86614 4.19294H9.23152V11.116C9.23152 11.3759 9.02983 11.5776 8.76998 11.5776H3.23152C2.97167 11.5776 2.76998 11.3759 2.76998 11.116V4.19294ZM3.69306 5.11602V10.6545H4.61614V5.11602H3.69306ZM5.53921 5.11602V10.6545H6.46229V5.11602H5.53921ZM7.38537 5.11602V10.6545H8.30844V5.11602H7.38537Z"
                          fill="#8D8BDB"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_730_8316">
                          <rect
                            width="12"
                            height="12"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="text-neutral-400 dark:text-[#EDEDFB] text-[13px] font-normal">
                      Delete
                    </span>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-row justify-between items-start">
            <div className="w-[229px] text-[#8D8BDB] dark:text-[#EDEDFB] text-[15px] font-normal ">
              {provider.description}
            </div>
            <div className="flex flex-col items-center">
              <div className="flex flex-row gap-4">
                {provider?.documente.map((d, index) => (
                  <a
                    target="_blank"
                    href={d}
                    className="flex flex-col items-center"
                    key={index}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="59"
                      viewBox="0 0 50 59"
                      fill="none"
                    >
                      <path
                        className="fill-current dark:text-[#EDEDFB]"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.7 4.46667C6.10768 4.46667 5.53963 4.70197 5.1208 5.1208C4.70196 5.53963 4.46667 6.10769 4.46667 6.7V51.3667C4.46667 51.959 4.70196 52.527 5.1208 52.9459C5.53963 53.3647 6.10768 53.6 6.7 53.6H42.4333C43.0257 53.6 43.5937 53.3647 44.0125 52.9459C44.4314 52.527 44.6667 51.959 44.6667 51.3667V22.3333H29.0333C28.441 22.3333 27.873 22.098 27.4541 21.6792C27.0353 21.2604 26.8 20.6923 26.8 20.1V4.46667H6.7ZM31.2667 7.6246L41.5087 17.8667H31.2667V7.6246ZM0 6.7C0 4.92305 0.705891 3.21888 1.96238 1.96239C3.21888 0.705895 4.92305 3.56215e-06 6.7 3.56215e-06H29.0333C29.3268 -0.000520588 29.6175 0.0568047 29.8888 0.168702C30.1601 0.2806 30.4067 0.444876 30.6145 0.652137L48.4812 18.5188C48.6885 18.7266 48.8527 18.9732 48.9646 19.2445C49.0765 19.5158 49.1339 19.8065 49.1333 20.1V51.3667C49.1333 53.1436 48.4274 54.8478 47.171 56.1043C45.9145 57.3608 44.2103 58.0667 42.4333 58.0667H6.7C4.92305 58.0667 3.21888 57.3608 1.96238 56.1043C0.705891 54.8478 0 53.1436 0 51.3667V6.7Z"
                        fill="#4A488E"
                      />
                      <path
                        className=" stroke-current dark:text-[#EDEDFB]"
                        d="M15.5664 40.5332V42.5332C15.5664 43.0636 15.7903 43.5723 16.1888 43.9474C16.5873 44.3225 17.1278 44.5332 17.6914 44.5332H30.4414C31.005 44.5332 31.5455 44.3225 31.944 43.9474C32.3425 43.5723 32.5664 43.0636 32.5664 42.5332V40.5332M18.7539 34.5332L24.0664 39.5332M24.0664 39.5332L29.3789 34.5332M24.0664 39.5332V27.5332"
                        stroke="#4A488E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="w-[74px] h-[13px] dark:text-[#EDEDFB] text-[#4A488E] text-[11px] font-normal  mt-[10px]">
                      Discount DOC
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          fullWidth
          maxWidth="md"
          id="editDialog"
          open={openEditDialog}
          onClose={handleOpenEditDialog}
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
              borderRadius: "14px",
              "@media (prefers-color-scheme: dark)": {
                backgroundColor: "var(--tw-bg-opacity, #2B2A49)", // Example dark mode background color
              },
            },
            className:
              "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white", // Tailwind dark mode classes
          }}
          BackdropProps={{
            sx: {
              backgroundColor: "#2B2A49",
              boxShadow: "none",
              "@media (prefers-color-scheme: dark)": {
                backgroundColor: "rgba(0, 0, 0, 0.75)", // Example dark mode backdrop color
              },
            },
            className:
              "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]", // Tailwind dark mode classes
          }}
        >
          <div className="py-[36px] px-[55px]">
            <DialogTitle className="text-indigo-800 dark:text-[#EDEDFB] text-[25px] flex flex-row justify-between">
              <span>Edit benefit</span>
              <svg
                className="cursor-pointer"
                onClick={handleOpenEditDialog}
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                viewBox="0 0 37 37"
                fill="none"
              >
                <path
                  className="fill-current dark:text-[#EDEDFB]"
                  d="M29.7249 28.0888C29.8324 28.1963 29.9176 28.3238 29.9757 28.4642C30.0339 28.6045 30.0638 28.755 30.0638 28.9069C30.0638 29.0588 30.0339 29.2093 29.9757 29.3496C29.9176 29.49 29.8324 29.6175 29.7249 29.7249C29.6175 29.8324 29.49 29.9176 29.3496 29.9757C29.2093 30.0339 29.0588 30.0638 28.9069 30.0638C28.755 30.0638 28.6045 30.0339 28.4642 29.9757C28.3238 29.9176 28.1963 29.8324 28.0888 29.7249L18.5006 20.1353L8.91244 29.7249C8.69548 29.9419 8.40122 30.0638 8.09439 30.0638C7.78757 30.0638 7.49331 29.9419 7.27635 29.7249C7.05939 29.508 6.9375 29.2137 6.9375 28.9069C6.9375 28.6001 7.05939 28.3058 7.27635 28.0888L16.866 18.5006L7.27635 8.91244C7.05939 8.69548 6.9375 8.40122 6.9375 8.09439C6.9375 7.78757 7.05939 7.49331 7.27635 7.27635C7.49331 7.05939 7.78757 6.9375 8.09439 6.9375C8.40122 6.9375 8.69548 7.05939 8.91244 7.27635L18.5006 16.866L28.0888 7.27635C28.3058 7.05939 28.6001 6.9375 28.9069 6.9375C29.2137 6.9375 29.508 7.05939 29.7249 7.27635C29.9419 7.49331 30.0638 7.78757 30.0638 8.09439C30.0638 8.40122 29.9419 8.69548 29.7249 8.91244L20.1353 18.5006L29.7249 28.0888Z"
                  fill="#4A488E"
                />
              </svg>
            </DialogTitle>
            <DialogContent>
              <div className="flex flex-col gap-[16px] mt-[67px]">
                <div className="flex flex-row justify-between items-center">
                  <div className="text-neutral-700 dark:text-[#EDEDFB] text-lg font-normal">
                    Title
                  </div>
                  <input
                    name="name"
                    value={editedProvider.name}
                    onChange={handleInputChange}
                    className="w-[545px] h-[47px] px-[25px] py-3.5 bg-violet-100 dark:bg-[#3A3A68] rounded border-none"
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="text-neutral-700 dark:text-[#EDEDFB] text-lg font-normal">
                    Description
                  </div>
                  <input
                    name="description"
                    value={editedProvider.description}
                    onChange={handleInputChange}
                    className="w-[545px] h-[47px] px-[25px] py-3.5 bg-violet-100 dark:bg-[#3A3A68] rounded border-none"
                  />
                </div>
                <div className="flex flex-row items-center">
                  <div className="text-neutral-700 dark:text-[#EDEDFB] text-lg font-normal mr-[108px] ">
                    Document*
                  </div>
                  {editedProvider?.documente.length > 0
                    ? editedProvider.documente.map((d, index) => (
                        <div
                          className="flex flex-col items-center mr-4"
                          key={index}
                        >
                          <div className="w-[9px] h-[9px] relative left-6">
                            <svg
                              onClick={() => removeDoc(index)}
                              id="delete-single-doc"
                              className="cursor-pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_730_9608)">
                                <path
                                  className="fill-current dark:text-[#EDEDFB]"
                                  d="M8.86813 8.23142C8.90994 8.27322 8.9431 8.32286 8.96573 8.37748C8.98835 8.4321 9 8.49065 9 8.54977C9 8.6089 8.98835 8.66744 8.96573 8.72207C8.9431 8.77669 8.90994 8.82632 8.86813 8.86813C8.82632 8.90994 8.77669 8.9431 8.72207 8.96573C8.66744 8.98835 8.6089 9 8.54977 9C8.49065 9 8.4321 8.98835 8.37748 8.96573C8.32286 8.9431 8.27322 8.90994 8.23142 8.86813L4.5 5.13615L0.768582 8.86813C0.684149 8.95257 0.569632 9 0.450225 9C0.330818 9 0.216301 8.95257 0.131868 8.86813C0.0474343 8.7837 2.3538e-09 8.66918 0 8.54977C-2.3538e-09 8.43037 0.0474343 8.31585 0.131868 8.23142L3.86385 4.5L0.131868 0.768582C0.0474343 0.684149 -8.89652e-10 0.569632 0 0.450225C8.89652e-10 0.330818 0.0474343 0.216301 0.131868 0.131868C0.216301 0.0474343 0.330818 8.89653e-10 0.450225 0C0.569632 -8.89652e-10 0.684149 0.0474343 0.768582 0.131868L4.5 3.86385L8.23142 0.131868C8.31585 0.0474343 8.43037 -2.3538e-09 8.54977 0C8.66918 2.3538e-09 8.7837 0.0474343 8.86813 0.131868C8.95257 0.216301 9 0.330818 9 0.450225C9 0.569632 8.95257 0.684149 8.86813 0.768582L5.13615 4.5L8.86813 8.23142Z"
                                  fill="#7674C2"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_730_9608">
                                  <rect width="9" height="9" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>{" "}
                          </div>
                          <a href={d} target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="36"
                              height="42"
                              viewBox="0 0 36 42"
                              fill="none"
                            >
                              <path
                                className="fill-current dark:text-[#EDEDFB]"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.90909 3.23077C4.4751 3.23077 4.05888 3.40096 3.75201 3.70391C3.44513 4.00685 3.27273 4.41773 3.27273 4.84616V37.1538C3.27273 37.5823 3.44513 37.9932 3.75201 38.2961C4.05888 38.599 4.4751 38.7692 4.90909 38.7692H31.0909C31.5249 38.7692 31.9411 38.599 32.248 38.2961C32.5549 37.9932 32.7273 37.5823 32.7273 37.1538V16.1538H21.2727C20.8387 16.1538 20.4225 15.9837 20.1156 15.6807C19.8088 15.3778 19.6364 14.9669 19.6364 14.5385V3.23077H4.90909ZM22.9091 5.51493L30.4135 12.9231H22.9091V5.51493ZM0 4.84616C0 3.56088 0.517206 2.32824 1.43784 1.41941C2.35847 0.510578 3.60712 2.57652e-06 4.90909 2.57652e-06H21.2727C21.4878 -0.000376545 21.7008 0.0410872 21.8996 0.122023C22.0983 0.20296 22.279 0.321781 22.4313 0.471695L35.5222 13.3948C35.674 13.5451 35.7944 13.7234 35.8764 13.9197C35.9584 14.1159 36.0004 14.3262 36 14.5385V37.1538C36 38.4391 35.4828 39.6718 34.5622 40.5806C33.6415 41.4894 32.3929 42 31.0909 42H4.90909C3.60712 42 2.35847 41.4894 1.43784 40.5806C0.517206 39.6718 0 38.4391 0 37.1538V4.84616Z"
                                fill="#8D8BDB"
                              />
                            </svg>
                          </a>
                          <div className="w-[74px] h-[13px] text-indigo-400  dark:text-[#EDEDFB] text-[11px] font-normal mt-2">
                            Discount DOC
                          </div>
                        </div>
                      ))
                    : ""}
                  <div className="w-[74px] h-[74px] bg-[#E9E9FF] dark:bg-[#3A3A68] rounded flex items-center justify-center cursor-pointer">
                    <label htmlFor="file-upload">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                      >
                        <path
                          className="stroke-current dark:text-[#EDEDFB]"
                          d="M4.83398 20.5418V22.9585C4.83398 23.5994 5.0886 24.2141 5.54181 24.6673C5.99502 25.1205 6.60971 25.3752 7.25065 25.3752H21.7507C22.3916 25.3752 23.0063 25.1205 23.4595 24.6673C23.9127 24.2141 24.1673 23.5994 24.1673 22.9585V20.5418M8.45898 13.2918L14.5007 19.3335M14.5007 19.3335L20.5423 13.2918M14.5007 19.3335V4.8335"
                          stroke="#8D8BDB"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleSave}
                className="w-[151px] h-[54px] bg-indigo-400 rounded-[3px] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-white text-lg font-normal">Edit</div>
              </button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          id="deleteDialog"
          open={openDeleteDialog}
          onClose={handleOpenDeleteDialog}
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
              borderRadius: "14px",
              "@media (prefers-color-scheme: dark)": {
                backgroundColor: "var(--tw-bg-opacity, #2B2A49)", // Example dark mode background color
              },
            },
            className:
              "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white", // Tailwind dark mode classes
          }}
          BackdropProps={{
            sx: {
              backgroundColor: "#2B2A49",
              boxShadow: "none",
              "@media (prefers-color-scheme: dark)": {
                backgroundColor: "rgba(0, 0, 0, 0.75)", // Example dark mode backdrop color
              },
            },
            className:
              "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]", // Tailwind dark mode classes
          }}
        >
          <div className="py-[26px] px-[45px]">
            <DialogTitle className="text-indigo-800 dark:text-[#EDEDFB] text-[25px] flex flex-row justify-between">
              <span>Delete benefit</span>
            </DialogTitle>
            <DialogContent>
              <div className="flex flex-col gap-[16px] mt-[67px]">
                <div className="text-neutral-700 dark:text-[#EDEDFB] text-lg font-normal">
                  Are you sure you want to delete this benefit?
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <button
                onClick={handleDelete}
                className="w-[151px] h-[54px] bg-red-400 rounded-[3px] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-white text-lg font-normal">Delete</div>
              </button>
              <button
                onClick={handleOpenDeleteDialog}
                className="w-[151px] h-[54px] bg-gray-400 rounded-[3px] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-white text-lg font-normal">Cancel</div>
              </button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </motion.div>
  );
};
