import React, { useEffect, useState } from "react";
import { useRegisterMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import both eye icons
import { motion, AnimatePresence } from "framer-motion"; // Import Framer Motion

const Register = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const { name, email, password, confirmPassword } = user;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [register, { isLoading, error, data }] = useRegisterMutation();
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
    if (error) {
      toast.error(error?.data?.message);
    }
  }, [error, isAuthenticated, navigate]);

  useEffect(() => {
    if (data) {
      toast.success("Successfully registered!");
      navigate("/dashboard");
    }
  }, [data, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    const signUpData = {
      name,
      email,
      password,
    };
    register(signUpData);
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="flex justify-center h-screen items-center">
        <div className="w-[472px] h-fit border rounded-[14px] shadow-2xl bg-white dark:border-[#2B2A49] dark:bg-[#3A3A68]">
          <div className="border-b dark:border-[#2B2A49]">
            <p className="text-[34px] py-[34px] px-[31px] dark:text-[#EDEDFB]">
              Register
            </p>
          </div>
          <div className="pt-[31px]">
            <form onSubmit={submitHandler}>
              <div className="mb-3 flex flex-col px-[34px] gap-[19px]">
                <label
                  htmlFor="name_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control dark:bg-[#2B2A49] dark:text-[#EDEDFB]  dark:border-none rounded-[69px] h-[60px] border-2 px-[10px] text-[16px]"
                  name="name"
                  value={name}
                  onChange={onChange}
                />
              </div>
              <div className="mb-3 flex flex-col px-[34px] gap-[19px]">
                <label
                  htmlFor="email_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control dark:bg-[#2B2A49] dark:text-[#EDEDFB]  dark:border-none  rounded-[69px] h-[60px] border-2 px-[10px] text-[16px]"
                  name="email"
                  value={email}
                  onChange={onChange}
                />
              </div>

              <div className="mb-3 flex flex-col px-[34px] gap-[19px] relative">
                <label
                  htmlFor="password_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password_field"
                  className="form-control  dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none  rounded-[69px] h-[60px] border-2 px-[10px] pr-[40px] text-[16px]"
                  name="password"
                  value={password}
                  onChange={onChange}
                />
                <div
                  className="absolute inset-y-0 top-12 
right-10 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <AnimatePresence>
                    {showPassword ? (
                      <motion.div
                        key="eyeSlash"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEyeSlash className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    ) : (
                      <motion.div
                        key="eye"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEye className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>

              <div className="mb-3 flex flex-col px-[34px] gap-[19px] relative">
                <label
                  htmlFor="confirm-password_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  Confirm password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password_field"
                  className="form-control  dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none rounded-[69px] h-[60px] border-2 px-[10px] pr-[40px] text-[16px]"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChange}
                />
                <div
                  className="absolute inset-y-0 top-12 
right-10 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <AnimatePresence>
                    {showConfirmPassword ? (
                      <motion.div
                        key="eyeSlash"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEyeSlash className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    ) : (
                      <motion.div
                        key="eye"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEye className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>

              <div className="flex justify-center py-[30px] w-full">
                <button
                  id="register_button"
                  type="submit"
                  className="btn w-100 py-[15px] px-[63px] bg-[#025d52] dark:bg-white dark:text-[#8684CC] rounded-[66px] text-white"
                  disabled={isLoading}
                >
                  {isLoading ? "Creating..." : "Register"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
