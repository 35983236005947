import React, { useState } from "react";
import { useGetNotificationsForReceiverQuery } from "../redux/api/notificationApi";
import { Link } from "react-router-dom";
import { Badge } from "@mui/material";
import { useGetMeQuery } from "../redux/api/userApi";
import Drawer from "./Notification";

const NotificationComponent = () => {
  const [openRight, setOpenRight] = useState(false);
  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
  } = useGetMeQuery();
  const { data: notificationsData } = useGetNotificationsForReceiverQuery(
    meData?._id
  );
  let unreadNotifications = notificationsData?.notifications.filter(
    (n) => !n.notification.isRead
  );
  return (
    <>
      <div className="flex gap-2 z-20">
        <Badge
          badgeContent={unreadNotifications?.length}
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <button
            className="bg-[#FAFAFE] dark:bg-[#3A3A68] text-black p-[13px] rounded-[9px]"
            onClick={() => setOpenRight(!openRight)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <g clip-path="url(#clip0_309_3018)">
                <path
                  className="fill-current text-[#4A488E] dark:text-[#EDEDFB]"
                  d="M18.1902 14.8324C18.1254 14.7543 18.0617 14.6762 17.9992 14.6008C17.1398 13.5613 16.6199 12.934 16.6199 9.99141C16.6199 8.46797 16.2554 7.21797 15.5371 6.28047C15.0074 5.58789 14.2914 5.0625 13.3476 4.67422C13.3355 4.66746 13.3246 4.6586 13.3156 4.64805C12.9761 3.51133 12.0472 2.75 10.9996 2.75C9.95193 2.75 9.02342 3.51133 8.68396 4.64687C8.67492 4.65706 8.66422 4.66564 8.65232 4.67227C6.44998 5.57891 5.37967 7.31836 5.37967 9.99023C5.37967 12.934 4.86052 13.5613 4.00037 14.5996C3.93787 14.675 3.8742 14.7516 3.80935 14.8313C3.64185 15.0333 3.53573 15.279 3.50354 15.5394C3.47135 15.7999 3.51444 16.0641 3.62771 16.3008C3.86873 16.8086 4.3824 17.1238 4.96873 17.1238H17.0347C17.6183 17.1238 18.1285 16.809 18.3703 16.3035C18.4841 16.0668 18.5276 15.8023 18.4956 15.5416C18.4637 15.2809 18.3577 15.0348 18.1902 14.8324ZM10.9996 20.25C11.5641 20.2495 12.1179 20.0963 12.6023 19.8066C13.0867 19.5168 13.4837 19.1014 13.7512 18.6043C13.7637 18.5805 13.77 18.5538 13.7692 18.5269C13.7685 18.4999 13.7607 18.4736 13.7468 18.4506C13.7329 18.4275 13.7133 18.4084 13.6898 18.3952C13.6663 18.3819 13.6398 18.375 13.6129 18.375H8.38709C8.36011 18.3749 8.33356 18.3818 8.31003 18.395C8.2865 18.4082 8.2668 18.4273 8.25284 18.4504C8.23887 18.4735 8.23113 18.4998 8.23035 18.5268C8.22958 18.5537 8.2358 18.5804 8.24842 18.6043C8.51581 19.1013 8.91273 19.5167 9.39709 19.8065C9.88145 20.0962 10.4352 20.2495 10.9996 20.25Z"
                />
              </g>
              <defs>
                <clipPath id="clip0_309_3018">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </Badge>

        <button className="bg-[#FAFAFE] dark:bg-[#3A3A68] text-black p-[13px] rounded-[9px]">
          {meData?.role === "Admin" ? (
            <Link to="/AdminPanel">
              <svg
                width="20"
                height="20"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="fill-current dark:text-[#EDEDFB]"
                  d="M6.5013 10.2917V16.625H0.167969C0.167969 14.9453 0.835229 13.3344 2.02296 12.1467C3.21069 10.9589 4.8216 10.2917 6.5013 10.2917ZM6.5013 9.5C3.87693 9.5 1.7513 7.37438 1.7513 4.75C1.7513 2.12562 3.87693 0 6.5013 0C9.12568 0 11.2513 2.12562 11.2513 4.75C11.2513 7.37438 9.12568 9.5 6.5013 9.5ZM13.6263 12.6667H14.418V16.625H8.08464V12.6667H8.8763V11.875C8.8763 11.2451 9.12652 10.641 9.57192 10.1956C10.0173 9.75022 10.6214 9.5 11.2513 9.5C11.8812 9.5 12.4853 9.75022 12.9307 10.1956C13.3761 10.641 13.6263 11.2451 13.6263 11.875V12.6667ZM12.043 12.6667V11.875C12.043 11.665 11.9596 11.4637 11.8111 11.3152C11.6626 11.1667 11.4613 11.0833 11.2513 11.0833C11.0413 11.0833 10.84 11.1667 10.6915 11.3152C10.543 11.4637 10.4596 11.665 10.4596 11.875V12.6667H12.043Z"
                  fill="#4A488E"
                />
              </svg>
            </Link>
          ) : (
            <img src="/images/settings.svg" width={20} height={20} alt="" />
          )}
        </button>
        <Drawer open={openRight} side="right" setOpen={setOpenRight} />
      </div>
    </>
  );
};

export default NotificationComponent;
