import React, { useRef } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { useSelector } from "react-redux";

export const EditLeadDialog = ({
  handleSaveDialog,
  openDialog,
  handleCloseDialog,
  Transition,
  dialogLead,
  isMobileView,
  handleAddComment,
  currentComment,
  handleCurrentCommentChange,
  updatedLeadId,
}) => {
  const { data: usersData } = useGetAllUsersQuery();
  const user = useSelector((state) => state.user.user);

  const leadTitleRef = useRef();
  const companyRef = useRef();
  const nameRef = useRef();
  const roleRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const leadSourceRef = useRef();
  const statusRef = useRef();

  const getUsername = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user ? user.name : "Unknown User";
  };

  const handleSave = () => {
    // Combine existing comments with the new comment, if any
    const updatedComments = dialogLead.commentsLead
      ? [...dialogLead.commentsLead]
      : [];

    // Add a new comment only if `currentComment` is not empty
    if (currentComment.trim()) {
      updatedComments.push({
        user: user._id,
        isEdited: false,
        comment: currentComment,
        createdAt: new Date(),
      });
    }

    const updatedLead = {
      _id: updatedLeadId._id, // Ensure _id is being passed
      leadTitle: leadTitleRef.current.value,
      Company: companyRef.current.value,
      name: nameRef.current.value,
      role: roleRef.current.value,
      phone: phoneRef.current.value,
      email: emailRef.current.value,
      leadSource: leadSourceRef.current.value,
      status: statusRef.current.value,
      commentsLead: updatedComments, // Use the combined comments array
    };
    handleSaveDialog(updatedLead);
  };

  return (
    <Dialog
      id="editLeadDialog"
      className="py-[36px] px-[55px]"
      fullWidth
      maxWidth="xl"
      open={openDialog}
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: "#FAFAFE",
          borderRadius: "14px",
          "@media (prefers-color-scheme: dark)": {
            backgroundColor: "#2B2A49",
          },
        },
        className: "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white",
      }}
      BackdropProps={{
        sx: {
          backgroundColor: "#2B2A49",
          boxShadow: "none",
          "@media (prefers-color-scheme: dark)": {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        },
        className:
          "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]",
      }}
    >
      <AppBar
        elevation={0}
        sx={{
          position: "relative",
          backgroundColor: "#FAFAFE",
          color: "black",
          "@media (prefers-color-scheme: dark)": {
            backgroundColor: "#2B2A49",
            color: "white",
          },
        }}
        className="bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white"
      >
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            className="text-[#4A488E] dark:text-[#EDEDFB] text-[25px] font-normal"
          >
            {dialogLead.leadTitle} | {dialogLead.Company}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleCloseDialog}>
            <CloseIcon sx={{ color: "#4A488E" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className="px-[55px]">
        <div
          className={`flex ${
            isMobileView ? "flex-col" : "flex-row"
          } justify-between`}
        >
          <div
            className={`flex flex-col ${
              isMobileView ? "w-full" : "w-[50%]"
            } gap-[16px]`}
          >
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Name
              </label>
              <input
                ref={leadTitleRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                defaultValue={dialogLead.leadTitle}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Company name
              </label>
              <input
                ref={companyRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                defaultValue={dialogLead.Company}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Contact person
              </label>
              <input
                ref={nameRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                defaultValue={dialogLead.name}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Role
              </label>
              <input
                ref={roleRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                defaultValue={dialogLead.role}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Phone
              </label>
              <input
                ref={phoneRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                defaultValue={dialogLead.phone}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Email
              </label>
              <input
                ref={emailRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                defaultValue={dialogLead.email}
                readOnly
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Lead source
              </label>
              <input
                ref={leadSourceRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                defaultValue={dialogLead.leadSource}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Status
              </label>
              <div className="relative w-[70%]">
                <select
                  ref={statusRef}
                  className="appearance-none border-none dark:bg-[#3A3A68] dark:text-[#EDEDFB] bg-[#E9E9FF] text-[#4A488E] rounded-[4px] py-[14px] px-[25px] w-full"
                  defaultValue={dialogLead.status}
                >
                  <option value="DEAL">DEAL</option>
                  <option value="DEAD">DEAD</option>
                  <option value="PROSPECT">PROSPECT</option>
                </select>
              </div>
            </div>

            <div className="flex flex-row items-start justify-between">
              <label
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                New Comment
              </label>
              <div className="w-[70%] flex flex-col justify-center items-end">
                <textarea
                  className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] rounded-[4px] py-[14px] px-[25px] w-full border-none dark:placeholder-[#EDEDFB] resize-none"
                  value={currentComment}
                  onChange={handleCurrentCommentChange}
                  placeholder="Write your comment here..."
                  rows={4}
                />
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={handleAddComment}
                  className="mt-2 text-white py-2 px-4 rounded w-fit bg-[#7270BB]"
                >
                  ADD
                </Button>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col gap-[24px] ${
              isMobileView ? "w-full" : "w-[40%]"
            }`}
          >
            <div className="text-[#434343] text-lg font-normal dark:text-[#EDEDFB]">
              Comments
            </div>
            <div className="h-[560px] flex flex-col gap-[24px]">
              {dialogLead?.commentsLead?.map((c, index) => (
                <div
                  key={index}
                  className="w-[90%] h-[126px] px-5 py-[23px] dark:bg-[#3A3A68] bg-[#E9E9FF] rounded justify-center items-center gap-2.5 inline-flex"
                >
                  <div className="w-[446px] dark:text-[#EDEDFB] text-[#4A488E] text-[13px] font-normal flex flex-col">
                    <span className="mb-2">{getUsername(c.user)}</span>
                    <span className="mb-2">{c.comment}</span>
                    <span className="block mt-1 text-xs text-gray-500 dark:text-[#EDEDFB]">
                      {dayjs(c.createdAt).format("DD/MM/YY HH:mm")}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{ fontSize: 16 }}
          className="mt-2 text-white py-2 px-4 rounded w-fit bg-[#7270BB]"
          autoFocus
          color="inherit"
          onClick={handleSave}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
