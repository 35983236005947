import {
  Breadcrumbs,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Link } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./customCalendar.css";
import {
  useGetHolidaysQuery,
  useRequestHolidayMutation,
} from "../../redux/api/holidayApi";
import {
  romanianHolidays2024,
  romanianHolidays2025,
} from "../utils/Holiday/HolidayDictionary";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";

export const ScheduleAnnualLeave = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const getAllWeekendsInYear = (year) => {
    const dates = [];
    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 11, 31);

    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const day = date.getDay();
      if (day === 0 || day === 6) {
        dates.push(new Date(date));
      }
    }

    return dates;
  };

  const isMobileView = useContext(ViewTypeContext);

  const weekends2024 = getAllWeekendsInYear(2024);
  const weekends2025 = getAllWeekendsInYear(2025);

  const { user } = useSelector((state) => state.user);
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });
  const [holidays, setHolidays] = useState([]);
  const [holidayType, setHolidayType] = useState("vacation");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);

  const { data: users = [], refetch: refetchAllUsers } = useGetAllUsersQuery();
  const { data: holidayData, refetch } = useGetHolidaysQuery(user?._id);

  React.useEffect(() => {
    if (holidayData && users) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetch();
        refetchAllUsers();
      }, 4000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [holidayData, users, refetch, refetchAllUsers]);

  const [requestHoliday] = useRequestHolidayMutation();

  const nationalHolidays = [...romanianHolidays2024, ...romanianHolidays2025];

  useEffect(() => {
    if (holidayData && holidayData.holidays) {
      setHolidays(holidayData.holidays);
    }
  }, [holidayData]);

  const disabledDates = [
    ...romanianHolidays2024.map((holiday) => ({
      startDate: holiday.date,
      endDate: holiday.date,
    })),
    ...romanianHolidays2025.map((holiday) => ({
      startDate: holiday.date,
      endDate: holiday.date,
    })),
    ...holidays.map((holiday) => ({
      startDate: new Date(holiday.startDate),
      endDate: new Date(holiday.endDate),
    })),
    ...weekends2024.map((weekend) => ({
      startDate: weekend,
      endDate: weekend,
    })),
    ...weekends2025.map((weekend) => ({
      startDate: weekend,
      endDate: weekend,
    })),
    // { startDate: today, endDate: new Date("1999-02-05") },
  ];

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  const handleHolidayTypeChange = (event) => {
    setHolidayType(event.target.value);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const isDateConflict = (newHoliday) => {
    return holidays.some((holiday) => {
      const existingStart = dayjs(holiday.startDate);
      const existingEnd = dayjs(holiday.endDate);
      const newStart = dayjs(newHoliday.startDate);
      const newEnd = dayjs(newHoliday.endDate);

      const isOverlapping =
        (newStart.isBetween(existingStart, existingEnd, null, "[)") ||
          newEnd.isBetween(existingStart, existingEnd, null, "[)") ||
          existingStart.isBetween(newStart, newEnd, null, "[)") ||
          existingEnd.isBetween(newStart, newEnd, null, "[)")) &&
        (holiday.status === "pending" || holiday.status === "approved");

      return isOverlapping;
    });
  };

  const handleAddAndSaveHoliday = async () => {
    if (!user || !user._id) {
      console.error("User not found");
      return;
    }

    const newHoliday = {
      userId: user._id,
      startDate: value.startDate,
      endDate: value.endDate,
      type: holidayType,
    };

    if (isDateConflict(newHoliday)) {
      toast.error("Selected dates conflict with an existing holiday period.");
      return;
    }

    try {
      await requestHoliday(newHoliday).unwrap();
      toast.success("Holiday request sent successfully");
      refetch();
    } catch (error) {
      console.error("Failed to send holiday request", error);
      toast.error("Holiday request could not be sent. Please try again later!");
    }

    handleDialogClose();
  };

  const allHolidays = users?.users?.flatMap((user) => user.holidays) || [];

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const day = dayjs(date);

      const isWeekend = date.getDay() === 0 || date.getDay() === 6;
      const isNationalHoliday = nationalHolidays.some((holiday) =>
        day.isSame(dayjs(holiday.date), "day")
      );
      const isUserHoliday = holidays.some((holiday) => {
        const start = dayjs(holiday.startDate);
        const end = dayjs(holiday.endDate);
        return day.isBetween(start, end, "day", "[]");
      });
      const isOtherUserHoliday = allHolidays.some((holiday) => {
        const start = dayjs(holiday.startDate);
        const end = dayjs(holiday.endDate);
        return day.isBetween(start, end, "day", "[]");
      });

      if (isNationalHoliday) {
        return "national-holiday-tile";
      } else if (isWeekend) {
        return "weekend-day";
      } else if (isUserHoliday) {
        return "user-holiday-tile";
      } else if (isOtherUserHoliday) {
        return "other-holiday-tile";
      }
    }
    return null;
  };

  const handleDateClick = (value, event) => {
    const clickedDay = dayjs(value);

    const userHoliday = holidays.find((holiday) => {
      const start = dayjs(holiday.startDate);
      const end = dayjs(holiday.endDate);
      return clickedDay.isBetween(start, end, "day", "[]");
    });

    const otherUserHoliday = allHolidays.find((holiday) => {
      const start = dayjs(holiday.startDate);
      const end = dayjs(holiday.endDate);
      return clickedDay.isBetween(start, end, "day", "[]");
    });

    const nationalHoliday = nationalHolidays.find((holiday) =>
      clickedDay.isSame(dayjs(holiday.date), "day")
    );

    if (nationalHoliday) {
      setSelectedHoliday({
        userName: "National Holiday",
        type: nationalHoliday.comment,
        startDate: nationalHoliday.date,
        endDate: nationalHoliday.date,
      });
    } else if (userHoliday) {
      setSelectedHoliday({
        userName: user.name,
        type: userHoliday.type,
        startDate: userHoliday.startDate,
        endDate: userHoliday.endDate,
      });
    } else if (otherUserHoliday) {
      const holidayUser = users.users.find((u) =>
        u.holidays.some(
          (h) =>
            h.startDate === otherUserHoliday.startDate &&
            h.endDate === otherUserHoliday.endDate
        )
      );
      setSelectedHoliday({
        userName: holidayUser.name,
        type: otherUserHoliday.type,
        startDate: otherUserHoliday.startDate,
        endDate: otherUserHoliday.endDate,
      });
    } else {
      setSelectedHoliday(null);
    }
  };

  const calculateDaysTaken = (type) => {
    return holidays
      .filter((holiday) => holiday.type === type)
      .reduce((total, holiday) => {
        const start = dayjs(holiday.startDate);
        const end = dayjs(holiday.endDate);
        let daysCount = 0;

        for (
          let date = start;
          date.isBefore(end) || date.isSame(end, "day");
          date = date.add(1, "day")
        ) {
          const dayOfWeek = date.day();
          if (dayOfWeek !== 0 && dayOfWeek !== 6) {
            daysCount++;
          }
        }

        return total + daysCount;
      }, 0);
  };

  const vacationDaysTaken = calculateDaysTaken("vacation");
  const sickDaysTaken = calculateDaysTaken("sickday");
  const specialDaysTaken = calculateDaysTaken("special day");

  const remainingLeaveDays = user?.vacationDays - vacationDaysTaken;

  const getUpcomingHolidays = () => {
    const today = dayjs();
    let upcomingHolidays = [];

    users?.users?.forEach((user) => {
      user.holidays
        .filter((holiday) => dayjs(holiday.endDate).isAfter(today))
        .forEach((holiday) => {
          upcomingHolidays.push({
            ...holiday,
            userName: user.name,
          });
        });
    });

    upcomingHolidays.sort((a, b) =>
      dayjs(a.startDate).diff(dayjs(b.startDate))
    );

    return upcomingHolidays;
  };

  const upcomingHolidays = getUpcomingHolidays();

  return (
    <div
      className={
        isMobileView
          ? "pr-[54px] pl-[54px] pt-[25px] ml-[-59px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
          : "pr-[54px] pt-[25px] ml-[-59px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
      }
    >
      <div className="w-full px-[10px] xl:px-[100px] md:mb-40">
        <div className="p-4 pl-0 flex items-center flex-wrap">
          <ul className="flex items-center ">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="text-[#4A488E] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/HR`}
              >
                HR
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/HR/Schedule-annual-leave`}
              >
                Schedule Annual Leave
              </Link>
            </Breadcrumbs>
          </ul>
        </div>
        <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal   mt-[40px] mb-[40px] ">
          Schedule Annual Leave
        </div>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
              borderRadius: "14px",
              "@media (prefers-color-scheme: dark)": {
                backgroundColor: "var(--tw-bg-opacity, #2B2A49)",
              },
            },
            className:
              "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white",
          }}
          BackdropProps={{
            sx: {
              backgroundColor: "#2B2A49",
              boxShadow: "none",
              "@media (prefers-color-scheme: dark)": {
                backgroundColor: "rgba(0, 0, 0, 0.75)",
              },
            },
            className:
              "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]",
          }}
        >
          <DialogTitle
            sx={{ color: "#4A488E" }}
            className="dark:text-[#EDEDFB]"
          >
            Schedule Holiday
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="dark:text-[#EDEDFB]">
              Select the type of holiday and the dates.
            </DialogContentText>
            <FormControl fullWidth margin="normal" className="w-full my-4">
              <InputLabel
                id="holiday-type-label"
                className="dark:text-[#EDEDFB]"
              >
                Holiday Type
              </InputLabel>
              <Select
                labelId="holiday-type-label"
                value={holidayType}
                label="Holiday Type"
                onChange={handleHolidayTypeChange}
                className="dark:text-[#EDEDFB] dark:bg-[#484878]"
                MenuProps={{
                  classes: {
                    paper: "dark:bg-[#484878] dark:text-[#EDEDFB]",
                  },
                }}
              >
                <MenuItem value="vacation" className="dark:text-[#EDEDFB]">
                  Vacation
                </MenuItem>
                <MenuItem value="sickday" className="dark:text-[#EDEDFB]">
                  Sick Day
                </MenuItem>
                <MenuItem value="special day" className="dark:text-[#EDEDFB]">
                  Special Day
                </MenuItem>
              </Select>
            </FormControl>
            <div className="w-full h-[440px] mt-4">
              <Datepicker
                value={value}
                onChange={handleValueChange}
                displayFormat={"DD/MM/YYYY"}
                popoverDirection="down"
                startWeekOn="mon"
                startDate={today}
                // disabledDates={disabledDates}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <button
              className="bg-[#c17781] text-white font-semibold p-[13px] rounded-[9px]"
              onClick={handleDialogClose}
            >
              Cancel
            </button>
            <button
              className="bg-[#7977C1] text-white font-semibold p-[13px] rounded-[9px]"
              onClick={handleAddAndSaveHoliday}
            >
              Add and Save Holiday
            </button>
          </DialogActions>
        </Dialog>

        <div
          className={
            isMobileView
              ? "flex flex-row justify-between mt-4"
              : "flex flex-row gap-[32px] mt-4"
          }
        >
          <div className="flex flex-col gap-[15px]">
            <div className="text-indigo-800 dark:text-[#EDEDFB] text-[25px] font-normal  ">
              My Holidays
            </div>

            <div className="w-[330px] h-fit px-5 py-[23px] dark:bg-[#484878] bg-violet-100 rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex">
              <div className="flex-col justify-start items-start gap-[18px] flex">
                <div className="text-indigo-800  dark:text-[#EDEDFB] text-xl font-normal  ">
                  Days off: {user?.vacationDays}
                </div>
                <div className="text-indigo-800 dark:text-[#EDEDFB] text-xl font-normal  ">
                  Vacation days taken: {vacationDaysTaken}
                </div>
                <div className="justify-start items-center gap-[15px] inline-flex">
                  <div className="text-indigo-800 dark:text-[#EDEDFB] text-xl font-normal   flex flex-row gap-2">
                    Remaining leave days:{" "}
                    <div className="bg-[#6C6BA2] dark:bg-[#8886C9] w-[29px] rounded-full text-white flex items-center justify-center">
                      {remainingLeaveDays
                        ? remainingLeaveDays
                        : user?.vacationDays}
                    </div>
                  </div>
                </div>
                <div className="text-indigo-800 dark:text-[#EDEDFB] text-xl font-normal  ">
                  Sick leave taken: {sickDaysTaken}
                </div>
                <div className="text-indigo-800 dark:text-[#EDEDFB] text-xl font-normal  ">
                  Special days taken: {specialDaysTaken}
                </div>
              </div>
            </div>
            {upcomingHolidays.length > 0 ? (
              <>
                {" "}
                <div className="text-indigo-800 dark:text-[#EDEDFB] text-[25px] font-normal  ">
                  My Team
                </div>
                <div className="w-[330px] h-fit px-5 py-[5px] dark:bg-[#484878] bg-violet-100 rounded-[10px] flex-col justify-start items-start gap-2.5 inline-flex">
                  {upcomingHolidays.slice(0, 5).map((holiday, index) => (
                    <div key={index}>
                      <div className="text-indigo-800 dark:text-[#EDEDFB] text-xl font-normal   p-[17px] flex items-center">
                        {holiday.userName}:{" "}
                        {dayjs(holiday.startDate).format("MMM D")} -{" "}
                        {dayjs(holiday.endDate).format("MMM D")}
                      </div>
                      {index === 4 ? (
                        ""
                      ) : (
                        <Divider className="dark:bg-[#EDEDFB]" />
                      )}{" "}
                    </div>
                  ))}
                </div>{" "}
              </>
            ) : (
              ""
            )}
          </div>
          <div
            className={`${
              selectedHoliday && !isMobileView ? "w-[903px]" : "w-[503px]"
            }  flex flex-col xl:flex-row justify-between items-center xl:items-start mt-[60px]`}
          >
            <div className="w-[503px] flex flex-col gap-[30px] ">
              <Calendar
                tileClassName={tileClassName}
                onClickDay={handleDateClick}
              />
              <button
                onClick={handleDialogOpen}
                className="mx-auto w-[300px] h-[50px] px-[30px] py-3 bg-[#7977C1] rounded-[10px] justify-center items-center"
              >
                <div className="text-white text-[18px] font-normal">
                  Schedule annual leave
                </div>
              </button>
            </div>
            {selectedHoliday && (
              <div className=" h-fit w-fit text-nowrap bg-[#F39C12] text-white text-[18px] rounded-[13px] mt-10 xl:mt-0 p-4">
                <div>
                  {" "}
                  {selectedHoliday.userName} -{" "}
                  {dayjs(selectedHoliday.startDate).format("MMM D")} -{" "}
                  {dayjs(selectedHoliday.endDate).format("MMM D")}
                </div>
                <div>Type: {selectedHoliday.type}</div>
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
