import React, { useState } from 'react';
import { ArrowDownIcon } from '../svgs';

const SquareDropdown = ({
  label,
  value,
  onChange,
  options = [],
  placeholder = "Nothing Selected"
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (selectedValue) => {
    onChange(selectedValue === placeholder ? "" : selectedValue);
    setIsOpen(false);
  };

  const getDisplayValue = () => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? selectedOption.label : placeholder;
  };

  return (
    <div className="relative w-full mb-4">
      <div className="flex flex-row items-center w-full gap-4">
        <div
          className="text-[#4A488E] dark:text-[#EDEDFB] text-[18px] font-normal w-[150px]" // Set a fixed width for the label
          style={{ wordWrap: 'break-word' }}
        >
          {label}
        </div>
        <div
          className={`relative bg-[#E9E9FF] dark:bg-[#3A3A68] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-[600px] cursor-pointer flex items-center justify-between ${isOpen ? "ring-2 ring-indigo-500" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{getDisplayValue()}</span>
          <ArrowDownIcon className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
        </div>
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-2 w-[600px] left-[160px] bg-[#E9E9FF] dark:bg-[#3A3A68] rounded-[4px] shadow-lg">
          <div
            onClick={() => handleSelect("")}
            className={`py-2 px-4 cursor-pointer text-[#4A488E] dark:text-[#EDEDFB] hover:bg-[#CAC9F1] dark:hover:bg-[#5A5A8E] ${
              value === "" ? "bg-[#CAC9F1] dark:bg-[#5A5A8E]" : ""
            }`}
          >
            {placeholder}
          </div>
          {options.length > 0 ? (
            options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelect(option.value)}
                className={`py-2 px-4 cursor-pointer text-[#4A488E] dark:text-[#EDEDFB] hover:bg-[#CAC9F1] dark:hover:bg-[#5A5A8E] ${
                  value === option.value ? "bg-[#CAC9F1] dark:bg-[#5A5A8E]" : ""
                }`}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className="py-2 px-4 text-[#4A488E] dark:text-[#EDEDFB]">
              No options available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SquareDropdown;
