export const HandleForceLogout = async (logout) => {
  try {
    console.log("Attempting to log out...");
    await logout();

    console.log("User logged out successfully.");
    window.location.href = "/";  // Redirect to login page after successful logout
  } catch (error) {
    console.error("Failed to log out:", error);
  }
};
