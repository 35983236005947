import React, { useContext, useMemo } from "react";
import { useGetDealsQuery } from "../../redux/api/crm/dealsApi";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Helper function to determine the current quarter
const getCurrentQuarter = (month) => {
  if (month >= 0 && month <= 2) return 1; // Jan - Mar (Q1)
  if (month >= 3 && month <= 5) return 2; // Apr - Jun (Q2)
  if (month >= 6 && month <= 8) return 3; // Jul - Sep (Q3)
  if (month >= 9 && month <= 11) return 4; // Oct - Dec (Q4)
};

// Helper function to generate an array of weeks in a given month
const generateWeeksInMonth = (month, year) => {
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const weeks = [
    {
      week: `Week 1 of ${month + 1}`,
      days: [1, 2, 3, 4, 5, 6, 7],
      value: 0,
      deals: [],
    },
    {
      week: `Week 2 of ${month + 1}`,
      days: [8, 9, 10, 11, 12, 13, 14],
      value: 0,
      deals: [],
    },
    {
      week: `Week 3 of ${month + 1}`,
      days: [15, 16, 17, 18, 19, 20, 21],
      value: 0,
      deals: [],
    },
    {
      week: `Week 4 of ${month + 1}`,
      days: [22, 23, 24, 25, 26, 27, 28],
      value: 0,
      deals: [],
    },
    { week: `Week 5 of ${month + 1}`, days: [29, 30, 31], value: 0, deals: [] },
  ];

  return weeks.filter((week) => week.days.some((day) => day <= daysInMonth));
};

// Helper function to generate weeks for an entire quarter
const generateWeeksInQuarter = (quarter, year) => {
  const monthsInQuarter = {
    1: [0, 1, 2], // Jan, Feb, Mar
    2: [3, 4, 5], // Apr, May, Jun
    3: [6, 7, 8], // Jul, Aug, Sep
    4: [9, 10, 11], // Oct, Nov, Dec
  };

  return monthsInQuarter[quarter].flatMap((month) =>
    generateWeeksInMonth(month, year)
  );
};

export const SalesOverview = () => {
  const { data: dealsData } = useGetDealsQuery();
  const isMobileView = useContext(ViewTypeContext);

  // Helper to filter deals by expected >= 75 and close date in the current quarter
  const getExpectedDealsForCurrentQuarter = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-indexed (January is 0, December is 11)
    const currentYear = currentDate.getFullYear();
    const currentQuarter = getCurrentQuarter(currentMonth);

    const monthsInQuarter = {
      1: [0, 1, 2], // Jan, Feb, Mar
      2: [3, 4, 5], // Apr, May, Jun
      3: [6, 7, 8], // Jul, Aug, Sep
      4: [9, 10, 11], // Oct, Nov, Dec
    };

    return dealsData?.deals.filter((deal) => {
      const dealCloseDate = new Date(deal.closeDate.endDate);
      const dealMonth = dealCloseDate.getMonth();

      return (
        deal.expected >= 75 &&
        deal.stage !== "Dead" &&
        monthsInQuarter[currentQuarter].includes(dealMonth) && // Deal is in the current quarter
        dealCloseDate.getFullYear() === currentYear // Deal is in the current year
      );
    });
  };

  // Calculate total expected value for the current quarter
  const totalExpectedValue = useMemo(() => {
    const deals = getExpectedDealsForCurrentQuarter();
    return deals?.reduce((total, deal) => total + deal.dealValue, 0) || 0;
  }, [dealsData]);

  // Prepare data for the chart, aggregated by week within the current quarter
  const chartData = useMemo(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const currentQuarter = getCurrentQuarter(currentMonth);

    // Generate weeks for the entire quarter
    const weeksInQuarter = generateWeeksInQuarter(currentQuarter, currentYear);

    // Get expected deals for the current quarter
    const deals = getExpectedDealsForCurrentQuarter();

    // Aggregate the deal values and names by week of the quarter
    deals?.forEach((deal) => {
      const dealCloseDate = new Date(deal.closeDate.endDate);
      const day = dealCloseDate.getDate();
      const month = dealCloseDate.getMonth();

      // Find the corresponding week and add the deal value and name
      const weekData = weeksInQuarter.find(
        (week) => week.week.includes(`${month + 1}`) && week.days.includes(day)
      );
      if (weekData) {
        weekData.value += deal.dealValue; // Accumulate the deal values for that week
        weekData.deals.push({
          dealTitle: deal.dealTitle,
          closeDate: deal.closeDate, // Include the close date in the deal object
        });
      }
    });

    return weeksInQuarter; // Return the updated array with deal values and names per week
  }, [dealsData]);

  // Get the current quarter
  const currentDate = new Date();
  const currentQuarter = getCurrentQuarter(currentDate.getMonth());

  // Custom Tooltip for displaying week, deal value, deal names, and close dates
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { week, value, deals } = payload[0].payload;
      return (
        <div className="custom-tooltip bg-white p-2 rounded shadow-lg">
          <p className="label font-semibold">{`${week}`}</p>
          <p>{`Total Deal Value: ${value} RON`}</p>
          <p>{`Deals: `}</p>
          <ul className="list-disc ml-4">
            {deals.map((deal, index) => (
              <li key={index}>
                {deal.dealTitle} -{" "}
                {new Date(deal.closeDate.endDate).toLocaleDateString()}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`w-full ${
        isMobileView ? "h-fit" : "h-[330px]"
      } bg-[#FFFFFF] dark:bg-[#383757] rounded-[8px] p-[24px] flex flex-col gap-[20px]`}
    >
      {/* Display total expected value with the current quarter */}
      <p className="text-xl text-indigo-800 dark:text-white font-bold text-center">
        Total Expected Value (Q{currentQuarter}):{" "}
        {totalExpectedValue.toFixed(2)} RON
      </p>

      {chartData && chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height={isMobileView ? 290 : 250}>
          <BarChart data={chartData}>
            <XAxis
              dataKey="week"
              stroke="currentColor"
              className="fill-current text-black dark:text-white"
            />{" "}
            <YAxis
              stroke="currentColor"
              className="fill-current text-black dark:text-white"
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <p>No deals expected for this quarter</p>
      )}
    </div>
  );
};
