// ViewTypeContext.js
import React, { createContext, useState, useEffect } from "react";
import { isMobile, isTablet, isMobileOnly } from "react-device-detect";
import "./rotateTablet.css";

// Create the context for managing view type
const ViewTypeContext = createContext();

/**
 * ViewTypeProvider is a context provider that detects the device type and
 * provides the view type to all children components.
 * It uses the react-device-detect package to detect the device type.
 * The view type is updated based on the window size and a breakpoint value.
 */
const ViewTypeProvider = ({ children }) => {
  // State to store the current view type (mobile or desktop)
  const [isMobileView, setIsMobileView] = useState(isMobile || isTablet);

  // State to track if the message should be displayed
  const [showRotateMessage, setShowRotateMessage] = useState(false);

  // Breakpoint values
  const mobileBreakpoint = 767;
  const tabletBreakpoint = 1280;

  // Function to update the view type based on window size and device type
  const updateViewType = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth <= mobileBreakpoint) {
      // Mobile phone detected based on width
      setShowRotateMessage(true);
    } else if (
      screenWidth > mobileBreakpoint &&
      screenWidth <= tabletBreakpoint
    ) {
      if (screenWidth < screenHeight) {
        // Tablet in portrait mode detected

        setShowRotateMessage(true);
      } else {
        // Tablet in landscape mode

        setShowRotateMessage(false);
      }
    } else {
      // Desktop or larger devices
      setShowRotateMessage(false);
    }

    setIsMobileView(screenWidth <= tabletBreakpoint || isMobile || isTablet);
  };

  // Effect to add and clean up the resize event listener
  useEffect(() => {
    window.addEventListener("resize", updateViewType);
    updateViewType(); // Initial check

    return () => {
      window.removeEventListener("resize", updateViewType);
    };
  }, []);

  // Conditionally render the message or the children
  if (showRotateMessage) {
    return (
      <div className="container">
        {" "}
        {/* Ensure this container has the flex styles */}
        <div className="phone"></div>
        <div className="message">
          Please rotate your device to landscape mode !
        </div>
      </div>
    );
  }

  // Provide the current view type to all children components
  return (
    <ViewTypeContext.Provider value={isMobileView}>
      {children}
    </ViewTypeContext.Provider>
  );
};

// Export both the context and provider
export { ViewTypeContext, ViewTypeProvider };
