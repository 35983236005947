// components/Holiday/DenyHoliday.js

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDenyHolidayMutation } from "../../../redux/api/holidayApi";

const DenyHoliday = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [denyHoliday] = useDenyHolidayMutation();

  useEffect(() => {
    const denyHolidayRequest = async () => {
      try {
        await denyHoliday(token).unwrap();
        toast.success("Holiday request denied successfully!", {
          autoClose: 2000,
        });
      } catch (error) {
        console.error("Failed to deny holiday request", error);
        toast.error("Failed to deny holiday request. Please try again later.", {
          autoClose: 2000,
        });
      } finally {
        navigate("/");
      }
    };

    denyHolidayRequest();
  }, [token, navigate, denyHoliday]);

  return null;
};

export default DenyHoliday;
