import React, { useState } from "react";
import {
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Avatar,
  Popover,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  SvgIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import NotificationComponent from "../NotificationComponent";
import {
  useGetServicesQuery,
  usePostServiceMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
  useUploadServiceImageMutation,
} from "../../redux/api/servicesApi";
import { useGetAppointmentsQuery } from "../../redux/api/appointmentsApi";
import { notify } from "../utils/notificationManager";
import Datepicker from "react-tailwindcss-datepicker";
import dayjs from "dayjs"; // Import dayjs
import relativeTime from "dayjs/plugin/relativeTime"; // Import relativeTime plugin

dayjs.extend(relativeTime);

export const Services = () => {
  const { data: servicesData, refetch: refetchServices } =
    useGetServicesQuery();
  const [updateService] = useUpdateServiceMutation();
  const { data: appointmentsData } = useGetAppointmentsQuery();
  const [postService] = usePostServiceMutation();
  const [uploadServiceImage] = useUploadServiceImageMutation(); // Mutation for image upload
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setNewService({
      name: "",
      image: "",
      duration: "",
      cost: "",
      location: "",
      provider: "",
    });
    setImagePreview("https://via.placeholder.com/144x144");
  };
  const handleUpdateService = async () => {
    try {
      let imageLink = newService.image;

      if (contractFile) {
        const formData = new FormData();
        formData.append("file", contractFile);
        const res = await uploadServiceImage(formData).unwrap();
        if (!res || !res.links || res.links.length === 0) {
          console.error("File upload failed");
          notify("File upload failed", 2, null, null, true);
          return;
        }
        imageLink = res.links[0];
      }

      let formattedDuration = "";
      if (newService.duration.startDate && newService.duration.endDate) {
        formattedDuration = `${newService.duration.startDate} - ${newService.duration.endDate}`;
      }

      const updatedServiceData = {
        ...newService,
        image: imageLink,
        duration: formattedDuration,
      };

      await updateService({
        id: selectedServiceId,
        ...updatedServiceData,
      }).unwrap();
      notify("Service updated successfully", 0, null, null, true);

      setIsEditDialogOpen(false);
      setSelectedServiceId(null);
      refetchServices();
    } catch (error) {
      console.error("Error updating service:", error);
      notify("Failed to update service", 2, null, null, true);
    }
  };

  // State to hold form input values
  const [newService, setNewService] = useState({
    name: "",
    image: "", // This will eventually hold the uploaded image URL
    duration: "",
    cost: "",
    location: "",
    provider: "",
  });

  // State for image preview and file
  const [imagePreview, setImagePreview] = useState(
    "https://via.placeholder.com/144x144"
  );
  const [contractFile, setContractFile] = useState(null);

  const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);

  const handleIsNewDialogOpen = () => {
    setIsNewDialogOpen(!isNewDialogOpen);
  };

  // Handle datepicker change
  // Handle datepicker change
  const handleDateChange = (value) => {
    setNewService({
      ...newService,
      duration: value, // Store the object from Datepicker (it has startDate and endDate)
    });
  };

  // Handle Save: Upload Image then Save Service with formatted duration
  const handleSave = async () => {
    try {
      let imageLink = ""; // This will store the uploaded image URL

      // If a file is selected, upload the image
      if (contractFile) {
        const formData = new FormData();
        formData.append("file", contractFile);

        // Upload the image and get the link
        const res = await uploadServiceImage(formData).unwrap();
        if (!res || !res.links || res.links.length === 0) {
          console.error("File upload failed");
          notify("File upload failed", 2, null, null, true);
          return;
        }
        imageLink = res.links[0]; // Use the first returned link
      }

      // Format the duration (startDate - endDate)
      let formattedDuration = "";
      if (
        newService.duration &&
        newService.duration.startDate &&
        newService.duration.endDate
      ) {
        formattedDuration = `${newService.duration.startDate} - ${newService.duration.endDate}`;
      }

      // Set the image link and formatted duration in the new service object
      const serviceData = {
        ...newService,
        image: imageLink ? imageLink : newService.image, // Use the uploaded image link
        duration: formattedDuration, // Use the formatted duration string
      };

      // Save the new service
      await postService(serviceData);
      notify("Service created successfully", 0, null, null, true);

      // Reset form state
      setNewService({
        name: "",
        image: "",
        duration: "",
        cost: "",
        location: "",
        provider: "",
      });
      setImagePreview("https://via.placeholder.com/144x144");
      setContractFile(null);
      setIsNewDialogOpen(false); // Close the dialog
      refetchServices(); // Refetch the services list after creation
    } catch (error) {
      console.error("Error creating service:", error);
      notify("Failed to create service", 2, null, null, true);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    setNewService({
      ...newService,
      [e.target.name]: e.target.value,
    });
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Read the file as Data URL for preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setContractFile(file); // Store the file for uploading later
    }
  };
  // Function to display the exact time period in days between startDate and endDate
  const displayExactDuration = (duration) => {
    if (duration) {
      const [startDate, endDate] = duration.split(" - ");

      // Convert to dayjs object and calculate the difference in days
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const daysDifference = end.diff(start, "day"); // Get the difference in days

      return `${daysDifference} days`; // Return the exact duration in days
    }
    return "No duration available";
  };

  // State for managing the popover anchor element
  const [anchorElOptions, setAnchorElOptions] = useState(null);

  // State for managing the popover anchor element and the selected service
  const [currentService, setCurrentService] = useState(null); // New state to store the selected service

  // Open the popover with options and store the selected service
  const handlePopoverOpenOptions = (event, service) => {
    setAnchorElOptions(event.currentTarget); // Set the clicked element as anchor
    setCurrentService(service); // Store the clicked service data
  };

  // Close the popover with options
  const handlePopoverCloseOptions = () => {
    setAnchorElOptions(null);
    setCurrentService(null); // Reset the selected service
  };

  // Handle Edit option using the stored currentService
  const handleEditOptions = () => {
    if (currentService) {
      // Pre-fill the form with the selected service's data
      setNewService({
        name: currentService.name || "",
        image: currentService.image || "",
        duration: {
          startDate: currentService.duration?.split(" - ")[0] || "",
          endDate: currentService.duration?.split(" - ")[1] || "",
        },
        cost: currentService.cost || "",
        location: currentService.location || "",
        provider: currentService.provider || "",
      });

      setImagePreview(
        currentService.image || "https://via.placeholder.com/144x144"
      );

      // Set the selected service ID
      setSelectedServiceId(currentService._id);

      // Open the edit dialog
      setIsEditDialogOpen(true);

      // Close the popover
      handlePopoverCloseOptions();
    }
  };

  // Handle Duplicate option
  // Handle Duplicate option
  const handleDuplicateOptions = (service) => {
    const duplicatedService = {
      ...service,
      name: `${service.name} (Copy)`, // Modify name to indicate it's a duplicate
      image: service.image,
    };
    setNewService(duplicatedService); // Pre-fill the form with the duplicated service
    setImagePreview(service.image || "https://via.placeholder.com/144x144");
    setIsNewDialogOpen(true); // Open the dialog for saving the duplicate
  };

  const [deleteService] = useDeleteServiceMutation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  // Handle Delete option
  // Handle Delete option
  const handleDeleteOptions = (serviceId) => {
    setSelectedServiceId(serviceId); // Store the service ID to be deleted
    setDeleteDialogOpen(true); // Open the delete dialog
  };
  // Confirm the deletion of the service
  const handleConfirmDelete = async () => {
    try {
      if (selectedServiceId) {
        await deleteService(selectedServiceId).unwrap(); // Call API to delete the service
        notify("Service deleted successfully", 0, null, null, true);
        refetchServices(); // Refetch the services list after deletion
      }
      setDeleteDialogOpen(false); // Close the delete dialog
      setSelectedServiceId(null); // Reset the selected service ID
    } catch (error) {
      console.error("Error deleting service:", error);
      notify("Failed to delete service", 2, null, null, true);
    }
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false); // Close the delete dialog
    setSelectedServiceId(null); // Reset the selected service ID
  };

  const getUsersByService = (serviceId) => {
    const usersSet = new Set(); // To avoid duplicates

    // Filter appointments related to the service
    const relatedAppointments = appointmentsData?.filter(
      (appointment) =>
        appointment.service.some((service) => service._id === serviceId) // Check if any service object matches the serviceId
    );

    // Collect unique user names and avatars from the related appointments
    const usersArray = [];
    relatedAppointments?.forEach((appointment) => {
      appointment.users.forEach((user) => {
        // Add a combination of the user's name and avatar to the array
        usersArray.push({
          name: user.name,
          avatar: user.avatar?.url || "https://via.placeholder.com/31x31", // Use placeholder if no avatar
        });
      });
    });

    // Filter out duplicates by using Set with unique names
    const uniqueUsers = Array.from(
      new Set(usersArray.map((user) => user.name))
    ).map((name) => usersArray.find((user) => user.name === name)); // Map unique names to their corresponding avatars

    return uniqueUsers; // Return the array of unique users
  };

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  dark:bg-[#2B2A49] dark:text-[#E0E0FF] relative">
      <div className="w-full">
        <div className="p-4 pl-0 flex items-center flex-wrap justify-between">
          <ul className="flex items-center">
            <li className="inline-flex items-center">
              <Breadcrumbs
                aria-label="breadcrumb"
                className="dark:text-[#E0E0FF]"
              >
                <Link underline="hover" color="#4A488E" to="/dashboard">
                  <span className="text-[#4A488E] dark:text-[#E0E0FF]">
                    Fuse Digital
                  </span>
                </Link>
                <Link underline="hover" color="#4A488E" to={`/appointments`}>
                  <span className="text-[#4A488E] dark:text-[#E0E0FF] font-normal">
                    Appointments
                  </span>
                </Link>
                <Link
                  underline="hover"
                  color="#4A488E"
                  to={`/appointments/services`}
                >
                  <span className="text-[#4A488E] dark:text-[#E0E0FF] font-bold">
                    Services
                  </span>
                </Link>
              </Breadcrumbs>
            </li>
          </ul>
          <NotificationComponent />
        </div>
        <p className="text-[30px] text-[#4A488E] dark:text-[#E0E0FF] mt-[39px] mb-[34px]">
          Services{" "}
        </p>
        <div className="flex items-center justify-between"></div>
      </div>
      <div className="w-full flex items-end justify-end">
        <button
          onClick={handleIsNewDialogOpen}
          className="h-[38px] px-[15px] py-2.5 bg-[#e7e7f9] rounded-md border border-[#7674c2] justify-center items-center gap-2.5 inline-flex"
        >
          <div className="text-[#4a488e] text-[15px] font-medium ">
            + New service
          </div>
        </button>
      </div>
      <div>
        <div className="flex flex-col  gap-4 mt-10">
          {servicesData?.map((s, index) => (
            <div
              key={index}
              className="w-full flex flex-row justify-between bg-[#E8E8FF] dark:bg-[#3A3A68] py-4 px-5 h-24 rounded-[6px] border-l-[5px] border-l-[#8D8BDB]"
            >
              <div className="flex flex-row gap-5">
                {" "}
                <div
                  className="w-[72px] h-[72px] rounded-full mr-[17px]"
                  style={{
                    backgroundImage: `url(${s?.image || "/images/avatar.svg"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <div className="flex flex-col gap-5">
                  <div className="text-[#484878] dark:text-[#ededfb] text-lg font-normal ">
                    {s?.name}
                  </div>
                  <div className="text-[#9e9ebe] text-[15px] font-normal ">
                    {displayExactDuration(s?.duration)} - {s?.cost}
                  </div>
                </div>
              </div>
              <div className="h-full flex items-center">
                {getUsersByService(s._id).length > 2 && (
                  <div className="w-fit h-[39px] px-1.5 py-1 rounded-[36px] flex justify-start items-start gap-2.5 ">
                    {getUsersByService(s._id).map((user, index) => (
                      <div key={index} className="flex items-center gap-2.5">
                        <img
                          className="w-[31px] h-[31px] rounded-full bg-cover bg-center"
                          src={user.avatar}
                          alt={user.name}
                        />
                      </div>
                    ))}{" "}
                  </div>
                )}

                {getUsersByService(s._id).length > 0 &&
                  getUsersByService(s._id).length < 3 && (
                    <div className="flex flex-row gap-2">
                      {" "}
                      {getUsersByService(s._id).map((user, index) => (
                        <div className="w-fit h-[39px] px-1.5 py-1 bg-white dark:bg-[#e9e9e9]/90 rounded-[36px] flex justify-start items-start gap-2.5 ">
                          <div
                            key={index}
                            className="flex items-center gap-2.5"
                          >
                            <img
                              className="w-[31px] h-[31px] rounded-full bg-cover bg-center"
                              src={user.avatar}
                              alt={user.name}
                            />
                            <div className="text-black text-base font-normal">
                              {user.name}
                            </div>
                          </div>{" "}
                        </div>
                      ))}
                    </div>
                  )}
                <svg
                  aria-controls="options-popover"
                  aria-haspopup="true"
                  onClick={(e) => handlePopoverOpenOptions(e, s)}
                  id="optionsBtn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    className="fill-current stroke-current text-[#4A488E] dark:text-white"
                    d="M14 5.6875C14.7249 5.6875 15.3125 5.09987 15.3125 4.375C15.3125 3.65013 14.7249 3.0625 14 3.0625C13.2751 3.0625 12.6875 3.65013 12.6875 4.375C12.6875 5.09987 13.2751 5.6875 14 5.6875Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    className="fill-current stroke-current text-[#4A488E] dark:text-white"
                    d="M14 15.3125C14.7249 15.3125 15.3125 14.7249 15.3125 14C15.3125 13.2751 14.7249 12.6875 14 12.6875C13.2751 12.6875 12.6875 13.2751 12.6875 14C12.6875 14.7249 13.2751 15.3125 14 15.3125Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    className="fill-current stroke-current text-[#4A488E] dark:text-white"
                    d="M14 24.9375C14.7249 24.9375 15.3125 24.3499 15.3125 23.625C15.3125 22.9001 14.7249 22.3125 14 22.3125C13.2751 22.3125 12.6875 22.9001 12.6875 23.625C12.6875 24.3499 13.2751 24.9375 14 24.9375Z"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <Popover
                PaperProps={{
                  className: `
                    w-[152px] 
                    p-4 
                    flex 
                    flex-col 
                    gap-4 
                    justify-start 
                    items-start 
                    bg-[#F9F9F9] 
                    text-gray-700 
                    dark:bg-[#383757] 
                    dark:text-white
                  `,
                }}
                open={Boolean(anchorElOptions)}
                anchorEl={anchorElOptions}
                onClose={handlePopoverCloseOptions}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <span
                  onClick={() => handleEditOptions()}
                  className="text-[#555555] dark:text-[#ededfb] text-[13px] font-normal flex flex-row items-center gap-2 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1417_10610)">
                      <path
                        className="fill-current text-[#555555] dark:text-[#EDEDFB]"
                        d="M11.2893 2.773L9.33262 0.806331C9.20332 0.677686 9.02835 0.605469 8.84595 0.605469C8.66356 0.605469 8.48858 0.677686 8.35929 0.806331L1.42262 7.733L0.789286 10.4663C0.767439 10.5662 0.768188 10.6698 0.791479 10.7694C0.81477 10.869 0.860014 10.9621 0.923908 11.042C0.987801 11.1218 1.06873 11.1864 1.16078 11.231C1.25283 11.2756 1.35368 11.299 1.45595 11.2997C1.50361 11.3045 1.55163 11.3045 1.59929 11.2997L4.36262 10.6663L11.2893 3.74633C11.4179 3.61703 11.4901 3.44206 11.4901 3.25966C11.4901 3.07727 11.4179 2.9023 11.2893 2.773ZM4.02929 10.0663L1.43929 10.6097L2.02929 8.06966L7.21929 2.89966L9.21929 4.89966L4.02929 10.0663ZM9.66595 4.41633L7.66595 2.41633L8.82595 1.263L10.7926 3.263L9.66595 4.41633Z"
                        fill="#EDEDFB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1417_10610">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Edit</span>
                </span>
                <span
                  onClick={() => handleDuplicateOptions(s)}
                  className="text-[#555555] dark:text-[#ededfb] text-[13px] font-normal flex flex-row items-center gap-2 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1417_10630)">
                      <path
                        className="fill-current text-[#555555] dark:text-[#EDEDFB]"
                        d="M8.76562 2.40625H3.95312C3.54287 2.40625 3.14941 2.56922 2.85932 2.85932C2.56922 3.14941 2.40625 3.54287 2.40625 3.95312V8.76562C2.40625 9.17588 2.56922 9.56934 2.85932 9.85943C3.14941 10.1495 3.54287 10.3125 3.95312 10.3125H8.76562C9.17588 10.3125 9.56934 10.1495 9.85943 9.85943C10.1495 9.56934 10.3125 9.17588 10.3125 8.76562V3.95312C10.3125 3.54287 10.1495 3.14941 9.85943 2.85932C9.56934 2.56922 9.17588 2.40625 8.76562 2.40625ZM8.06846 6.70312H6.70312V8.06846C6.70312 8.25344 6.5609 8.41221 6.37592 8.42145C6.32944 8.42369 6.28299 8.41646 6.23939 8.40022C6.19578 8.38398 6.15593 8.35905 6.12224 8.32695C6.08855 8.29485 6.06173 8.25625 6.04341 8.21348C6.02508 8.1707 6.01563 8.12466 6.01562 8.07812V6.70312H4.65029C4.46531 6.70312 4.30654 6.5609 4.2973 6.37592C4.29507 6.32944 4.30229 6.28299 4.31853 6.23939C4.33477 6.19578 4.3597 6.15593 4.3918 6.12224C4.4239 6.08855 4.4625 6.06173 4.50527 6.04341C4.54805 6.02508 4.59409 6.01563 4.64062 6.01562H6.01562V4.65029C6.01562 4.46531 6.15785 4.30654 6.34283 4.2973C6.38931 4.29507 6.43576 4.30229 6.47936 4.31853C6.52297 4.33477 6.56282 4.3597 6.59651 4.3918C6.6302 4.4239 6.65702 4.4625 6.67534 4.50527C6.69367 4.54805 6.70312 4.59409 6.70312 4.64062V6.01562H8.07812C8.12469 6.01557 8.17079 6.02498 8.21361 6.04328C8.25643 6.06158 8.29509 6.08839 8.32723 6.12208C8.35938 6.15578 8.38435 6.19565 8.40061 6.23928C8.41688 6.28292 8.42412 6.3294 8.42188 6.37592C8.41285 6.5609 8.25344 6.70312 8.06846 6.70312Z"
                        fill="#EDEDFB"
                      />
                      <path
                        className="fill-current text-[#555555] dark:text-[#EDEDFB]"
                        d="M8.50524 1.71875C8.39828 1.41738 8.2007 1.15648 7.9396 0.971843C7.6785 0.787209 7.36666 0.687886 7.04688 0.6875H2.23438C1.82412 0.6875 1.43066 0.850474 1.14057 1.14057C0.850474 1.43066 0.6875 1.82412 0.6875 2.23438V7.04688C0.687886 7.36666 0.787209 7.6785 0.971843 7.9396C1.15648 8.2007 1.41738 8.39828 1.71875 8.50524V3.4375C1.71875 2.98166 1.89983 2.54449 2.22216 2.22216C2.54449 1.89983 2.98166 1.71875 3.4375 1.71875H8.50524Z"
                        fill="#EDEDFB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1417_10630">
                        <rect width="11" height="11" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Duplicate</span>
                </span>
                <span
                  onClick={() => handleDeleteOptions(s?._id)}
                  className="text-[#555555] dark:text-[#ededfb] text-[13px] font-normal flex flex-row items-center gap-2 cursor-pointer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1417_10619)">
                      <path
                        className="fill-current text-[#555555] dark:text-[#EDEDFB]"
                        d="M5.30649 -0.015625C4.4028 -0.015625 3.6768 0.735298 3.6768 1.64314V1.84484H1.84495C1.59064 1.84484 1.38341 2.05207 1.38341 2.30638V2.76791H0.921875V3.69099H1.84495V10.6141C1.84495 11.3733 2.47034 11.9987 3.22957 11.9987H8.76803C9.52726 11.9987 10.1526 11.3733 10.1526 10.6141V3.69099H11.0757V2.76791H10.6142V2.30638C10.6142 2.05207 10.407 1.84484 10.1526 1.84484H8.3208V1.64268C8.3208 0.735298 7.5948 -0.015625 6.69111 -0.015625H5.30649ZM5.30649 0.936067H6.69111C7.06218 0.936067 7.36911 1.23884 7.36911 1.64268V1.84484H4.62803V1.64268C4.62803 1.23884 4.93495 0.936529 5.30603 0.936529L5.30649 0.936067ZM2.76803 3.69099H5.13341C5.19064 3.69699 5.24741 3.7053 5.30649 3.7053H6.69111C6.75018 3.7053 6.80649 3.69699 6.86418 3.69099H9.22957V10.6141C9.22957 10.8739 9.02788 11.0756 8.76803 11.0756H3.22957C2.96972 11.0756 2.76803 10.8739 2.76803 10.6141V3.69099ZM3.69111 4.61407V10.1525H4.61418V4.61407H3.69111ZM5.53726 4.61407V10.1525H6.46034V4.61407H5.53726ZM7.38341 4.61407V10.1525H8.30649V4.61407H7.38341Z"
                        fill="#EDEDFB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1417_10619">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Delete</span>
                </span>
              </Popover>
            </div>
          ))}
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isNewDialogOpen}
        PaperProps={{
          elevation: 0,
          sx: {
            paddingY: 7,
            paddingX: 5,
            color: "#FDFDFD",
            backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
            borderRadius: "14px",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "var(--tw-bg-opacity, #2B2A49)", // Example dark mode background color
            },
          },
          className:
            "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white", // Tailwind dark mode classes
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#2B2A49",
            boxShadow: "none",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Example dark mode backdrop color
            },
          },
          className:
            "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]", // Tailwind dark mode classes
        }}
      >
        <DialogTitle className="w-full flex items-end justify-end">
          <svg
            onClick={handleIsNewDialogOpen}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
          >
            <path
              className="stroke-current text-[#4A488E] dark:text-white"
              d="M31.5 2L2 31.5M2 2L31.5 31.5"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-row gap-5 items-center justify-start ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
            >
              <path
                className="stroke-current text-[#4A488E] dark:text-[#EDEDFB]"
                d="M16.125 3.86873L20.25 7.99372M13.375 23.1187H24.375M2.375 17.6187L1 23.1187L6.5 21.7437L22.4308 5.81297C22.9463 5.29727 23.2359 4.59793 23.2359 3.86873C23.2359 3.13952 22.9463 2.44018 22.4308 1.92448L22.1942 1.68798C21.6785 1.17243 20.9792 0.882812 20.25 0.882812C19.5208 0.882813 18.8215 1.17243 18.3058 1.68798L2.375 17.6187Z"
                stroke="#EDEDFB"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            <div className="text-[#4A488E] dark:text-white text-[25px] font-normal  ">
              Create new service
            </div>
          </div>
          <div className="h-32 justify-start items-start gap-[47px] inline-flex mt-10">
            <div className="flex flex-col items-center">
              <label htmlFor="upload-image" className="cursor-pointer">
                <img
                  className="w-32 h-32 rounded-full"
                  src={imagePreview}
                  alt="Service Image"
                />
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="mt-4 text-white"
                style={{ display: "none" }}
                id="upload-image"
              />
            </div>

            <div className="w-[546px] h-32 flex-col justify-start items-end gap-4 inline-flex ">
              <input
                name="name"
                value={newService.name}
                onChange={handleChange}
                className="self-stretch text-[#4A488E] dark:text-white h-[45px] px-5 py-3.5 bg-[#E9E9FF] dark:bg-[#3a3a68] rounded-md justify-start items-center gap-2.5 inline-flex border-none"
              ></input>
              <div className="w-[526px] text-[#8A8A8A] dark:text-white text-[19px] font-normal ">
                Description
              </div>
            </div>
          </div>
          <div className="mt-7 flex flex-col gap-7 items-center justify-center w-full">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row gap-1 items-center justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1422_11426)">
                    <path
                      className="fill-current text-[#585858] dark:text-white"
                      d="M10.9987 1.83203C16.0614 1.83203 20.1654 5.93595 20.1654 10.9987C20.1654 16.0614 16.0614 20.1654 10.9987 20.1654C5.93595 20.1654 1.83203 16.0614 1.83203 10.9987C1.83203 5.93595 5.93595 1.83203 10.9987 1.83203ZM10.9987 3.66536C9.05378 3.66536 7.18851 4.43798 5.81325 5.81325C4.43798 7.18851 3.66536 9.05378 3.66536 10.9987C3.66536 12.9436 4.43798 14.8089 5.81325 16.1841C7.18851 17.5594 9.05378 18.332 10.9987 18.332C12.9436 18.332 14.8089 17.5594 16.1841 16.1841C17.5594 14.8089 18.332 12.9436 18.332 10.9987C18.332 9.05378 17.5594 7.18851 16.1841 5.81325C14.8089 4.43798 12.9436 3.66536 10.9987 3.66536ZM10.9987 5.4987C11.2232 5.49873 11.4399 5.58116 11.6077 5.73035C11.7755 5.87955 11.8827 6.08513 11.9089 6.30811L11.9154 6.41536V10.6192L14.3968 13.1006C14.5612 13.2656 14.6566 13.4869 14.6637 13.7197C14.6708 13.9525 14.5891 14.1793 14.4351 14.354C14.281 14.5286 14.0663 14.6381 13.8344 14.6602C13.6026 14.6823 13.371 14.6153 13.1868 14.4729L13.1006 14.3968L10.3506 11.6468C10.2081 11.5042 10.1166 11.3186 10.0903 11.1188L10.082 10.9987V6.41536C10.082 6.17225 10.1786 5.93909 10.3505 5.76718C10.5224 5.59527 10.7556 5.4987 10.9987 5.4987Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1422_11426">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[#585858] dark:text-white text-[19px] font-normal ">
                  Duration
                </div>
              </div>
              <Datepicker
                name="duration" // This name isn't needed for Datepicker since you're not using event-based change
                value={newService.duration}
                onChange={handleDateChange} // Use the new handler for Datepicker
                showShortcuts={true}
                primaryColor={"blue"}
                popoverDirection="up"
                asSingle={false}
                className="h-[45px] bg-[#3a3a68] rounded-md w-full border-none"
              />

              {/* <input
                name="duration"
                value={newService.duration}
                onChange={handleChange}
                className="h-[45px] bg-[#3a3a68] rounded-md w-full border-none"
              /> */}
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row gap-1 items-center justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1422_11430)">
                    <path
                      className="stroke-current text-[#585858] dark:text-white"
                      d="M14.668 14.6654C14.668 13.6524 11.7951 12.832 8.2513 12.832M14.668 14.6654C14.668 15.6783 11.7951 16.4987 8.2513 16.4987C4.70747 16.4987 1.83464 15.6783 1.83464 14.6654M14.668 14.6654V19.1909C14.668 20.235 11.7951 21.082 8.2513 21.082C4.70747 21.082 1.83464 20.2359 1.83464 19.1909V14.6654M14.668 14.6654C18.1733 14.6654 21.0846 13.7606 21.0846 12.832V3.66536M8.2513 12.832C4.70747 12.832 1.83464 13.6524 1.83464 14.6654M8.2513 12.832C4.20147 12.832 0.917969 11.9273 0.917969 10.9987V6.41536M8.2513 4.58203C4.20147 4.58203 0.917969 5.40245 0.917969 6.41536M0.917969 6.41536C0.917969 7.42828 4.20147 8.2487 8.2513 8.2487C8.2513 9.17728 11.2332 10.082 14.7386 10.082C18.2439 10.082 21.0846 9.17728 21.0846 8.2487M21.0846 3.66536C21.0846 2.65245 18.243 1.83203 14.7386 1.83203C11.2341 1.83203 8.39247 2.65245 8.39247 3.66536M21.0846 3.66536C21.0846 4.67828 18.243 5.4987 14.7386 5.4987C11.2341 5.4987 8.39247 4.67828 8.39247 3.66536M8.39247 3.66536V12.9842"
                      stroke="white"
                      stroke-width="2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1422_11430">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div className="text-[#585858] dark:text-white text-[19px] font-normal ">
                  Cost
                </div>
              </div>
              <input
                type="number"
                name="cost"
                value={newService.cost}
                onChange={handleChange}
                className="h-[45px] text-[#4A488E] dark:text-white bg-[#E9E9FF] dark:bg-[#3a3a68]  rounded-md w-full border-none"
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row gap-1 items-center justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    className="fill-current text-[#585858] dark:text-white"
                    d="M11.0013 1.83203C6.96797 1.83203 3.66797 5.13203 3.66797 9.16536C3.66797 14.1154 10.0846 19.707 10.3596 19.982C10.543 20.0737 10.818 20.1654 11.0013 20.1654C11.1846 20.1654 11.4596 20.0737 11.643 19.982C11.918 19.707 18.3346 14.1154 18.3346 9.16536C18.3346 5.13203 15.0346 1.83203 11.0013 1.83203ZM11.0013 18.057C9.0763 16.2237 5.5013 12.282 5.5013 9.16536C5.5013 6.14036 7.9763 3.66536 11.0013 3.66536C14.0263 3.66536 16.5013 6.14036 16.5013 9.16536C16.5013 12.1904 12.9263 16.2237 11.0013 18.057ZM11.0013 5.4987C8.98464 5.4987 7.33464 7.1487 7.33464 9.16536C7.33464 11.182 8.98464 12.832 11.0013 12.832C13.018 12.832 14.668 11.182 14.668 9.16536C14.668 7.1487 13.018 5.4987 11.0013 5.4987ZM11.0013 10.9987C9.99297 10.9987 9.16797 10.1737 9.16797 9.16536C9.16797 8.15703 9.99297 7.33203 11.0013 7.33203C12.0096 7.33203 12.8346 8.15703 12.8346 9.16536C12.8346 10.1737 12.0096 10.9987 11.0013 10.9987Z"
                    fill="white"
                  />
                </svg>
                <div className="text-[#585858] dark:text-white text-[19px] font-normal ">
                  Location
                </div>
              </div>
              <input
                name="location"
                value={newService.location}
                onChange={handleChange}
                className="h-[45px] text-[#4A488E] dark:text-white bg-[#E9E9FF] dark:bg-[#3a3a68] rounded-md w-full border-none"
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row gap-1 items-center justify-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    className="fill-current text-[#585858] dark:text-white"
                    d="M19 2H5C4.46957 2 3.96086 2.21071 3.58579 2.58579C3.21071 2.96086 3 3.46957 3 4V18C3 19.1 3.9 20 5 20H9L11.29 22.29C11.68 22.68 12.31 22.68 12.7 22.29L15 20H19C20.1 20 21 19.1 21 18V4C21 2.9 20.1 2 19 2ZM12 5.3C13.49 5.3 14.7 6.51 14.7 8C14.7 9.49 13.49 10.7 12 10.7C10.51 10.7 9.3 9.49 9.3 8C9.3 6.51 10.51 5.3 12 5.3ZM18 16H6V15.1C6 13.1 10 12 12 12C14 12 18 13.1 18 15.1V16Z"
                    fill="white"
                  />
                </svg>
                <div className="text-[#585858] dark:text-white text-[19px] font-normal ">
                  Provider
                </div>
              </div>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  backgroundColor: "#3a3a68",
                  borderRadius: "8px",
                  color: "#fff",
                }}
              >
                <Select
                  name="provider"
                  value={newService.provider}
                  onChange={handleChange}
                  className="
    border-[#E9E9FF]
    bg-[#E9E9FF] 
    text-[#4A488E] 
    dark:bg-[#3a3a68] 
        dark:border-[#3a3a68]

    dark:text-white
    focus:outline-none
    focus:ring-2
    focus:ring-[#8885c9]
    hover:ring-1
    hover:ring-gray-400
  "
                  MenuProps={{
                    PaperProps: {
                      className: `
                      
        bg-[#E9E9FF] 
        text-[#4A488E] 
        dark:bg-[#3a3a68] 
        dark:text-white
      `,
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select an option
                  </MenuItem>
                  <MenuItem value="In person">
                    <SvgIcon viewBox="0 0 20 21" sx={{ marginRight: 1 }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          className="fill-current text-[#555555] dark:text-[#FDFDFD]"
                          d="M2.50089 7.52007C2.5008 6.79491 2.68096 6.0811 3.02518 5.44284C3.36939 4.80459 3.86685 4.2619 4.47283 3.86359C5.0788 3.46528 5.77428 3.22384 6.49672 3.16099C7.21915 3.09815 7.94587 3.21586 8.61152 3.50354C9.27718 3.79122 9.86088 4.23985 10.3101 4.80908C10.7594 5.37831 11.0601 6.05029 11.1853 6.76457C11.3104 7.47885 11.256 8.21303 11.027 8.90109C10.798 9.58914 10.4016 10.2095 9.87339 10.7063C10.8693 11.1898 11.7339 11.9062 12.3941 12.7949C13.0543 13.6836 13.4905 14.7183 13.6659 15.8113C13.6852 15.933 13.6803 16.0572 13.6516 16.177C13.6229 16.2967 13.5708 16.4096 13.4985 16.5093C13.4261 16.6089 13.3348 16.6934 13.2298 16.7577C13.1248 16.8221 13.0082 16.8652 12.8865 16.8844C12.7649 16.9037 12.6406 16.8989 12.5209 16.8701C12.4011 16.8414 12.2882 16.7894 12.1885 16.717C12.0889 16.6446 12.0045 16.5533 11.9401 16.4484C11.8757 16.3434 11.8327 16.2267 11.8134 16.1051C11.6259 14.9305 11.0258 13.8612 10.1208 13.0892C9.21589 12.3172 8.06537 11.8932 6.87589 11.8932C5.68641 11.8932 4.53588 12.3172 3.63093 13.0892C2.72598 13.8612 2.12587 14.9305 1.93839 16.1051C1.91902 16.2267 1.87588 16.3433 1.81144 16.4483C1.74699 16.5533 1.66251 16.6445 1.5628 16.7168C1.46309 16.7891 1.35012 16.8411 1.23033 16.8698C1.11054 16.8984 0.986276 16.9032 0.864639 16.8838C0.743003 16.8645 0.626372 16.8213 0.521407 16.7569C0.416441 16.6924 0.325197 16.6079 0.252884 16.5082C0.18057 16.4085 0.128603 16.2956 0.0999503 16.1758C0.0712974 16.056 0.0665195 15.9317 0.0858895 15.8101C0.260605 14.7171 0.696632 13.6825 1.35691 12.7941C2.01718 11.9058 2.88219 11.19 3.87839 10.7076Z"
                          fill="#FDFDFD"
                        />
                      </svg>
                    </SvgIcon>
                    In person
                  </MenuItem>
                  <MenuItem value="Virtual">
                    <SvgIcon viewBox="0 0 19 19" sx={{ marginRight: 1 }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1422_12298)">
                          <path
                            className="stroke-current text-[#555555] dark:text-[#FDFDFD]"
                            d="M9.50112 18.3225C11.8407 18.3225 14.0845 17.3931 15.7388 15.7388C17.3931 14.0845 18.3225 11.8407 18.3225 9.50112C18.3225 7.16153 17.3931 4.91776 15.7388 3.26342C14.0845 1.60909 11.8407 0.679688 9.50112 0.679688C7.16153 0.679688 4.91776 1.60909 3.26342 3.26342C1.60909 4.91776 0.679688 7.16153 0.679688 9.50112C0.679688 11.8407 1.60909 14.0845 3.26342 15.7388C4.91776 17.3931 7.16153 18.3225 9.50112 18.3225Z"
                            stroke="#FDFDFD"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            className="stroke-current text-[#555555] dark:text-[#FDFDFD]"
                            d="M13.756 7.12565L11.6931 8.03901V6.94244C11.6928 6.74878 11.6158 6.56313 11.479 6.42606C11.3422 6.289 11.1567 6.21165 10.963 6.21094H5.48014C5.28648 6.21165 5.10098 6.289 4.96417 6.42606C4.82735 6.56313 4.75036 6.74878 4.75 6.94244V12.0589C4.75036 12.2528 4.82754 12.4386 4.96465 12.5757C5.10175 12.7128 5.2876 12.79 5.4815 12.7904H10.9644C11.158 12.7896 11.3435 12.7123 11.4803 12.5752C11.6171 12.4382 11.6941 12.2525 11.6945 12.0589V10.9623L13.756 11.8757C13.8115 11.8968 13.8714 11.9041 13.9304 11.8969C13.9894 11.8898 14.0458 11.8684 14.0946 11.8346C14.1435 11.8008 14.1835 11.7556 14.211 11.703C14.2385 11.6503 14.2529 11.5917 14.2527 11.5323V7.46901C14.2531 7.40947 14.2389 7.35073 14.2115 7.29789C14.184 7.24505 14.1441 7.19969 14.0952 7.16574C14.0463 7.13179 13.9898 7.11027 13.9307 7.10305C13.8716 7.09583 13.8117 7.10448 13.756 7.12565Z"
                            stroke="#FDFDFD"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1422_12298">
                            <rect width="19" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </SvgIcon>
                    Virtual
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-full flex items-end justify-end mt-4">
            {" "}
            <button
              onClick={handleSave}
              className="w-[225px] h-[50px] px-[155px] py-6 bg-[#8885c9] rounded-lg justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-white text-xl font-normal ">Update</div>
            </button>{" "}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        fullWidth
        maxWidth="md"
        // PaperProps={{ sx: { backgroundColor: "#2B2A49" } }}
        PaperProps={{
          elevation: 0,
          sx: {
            paddingY: 7,
            paddingX: 5,
            color: "#FDFDFD",
            backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
            borderRadius: "14px",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "var(--tw-bg-opacity, #383757)", // Example dark mode background color
            },
          },
          className:
            "bg-[#FAFAFE] dark:bg-[#383757] text-black dark:text-white", // Tailwind dark mode classes
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#2B2A49",
            boxShadow: "none",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Example dark mode backdrop color
            },
          },
          className:
            "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]", // Tailwind dark mode classes
        }}
      >
        <DialogTitle className="text-[#4A488E] dark:text-[#ededfb] text-[25px] font-semibold">
          Delete Service?
        </DialogTitle>
        <DialogContent className="text-[#4A488E] dark:text-[#ededfb] text-lg font-normal">
          This service will be deleted from your account but previously-booked
          appointments will remain in your calendar.
        </DialogContent>
        <DialogActions className="flex flex-row justify-end gap-16">
          <button
            className="text-[#bdbdbd] text-xl font-normal"
            onClick={handleDeleteDialogClose} // Close the dialog without deleting
          >
            Cancel
          </button>

          <button
            onClick={handleConfirmDelete} // Confirm deletion
            className="h-[60px] text-white text-xl font-normal px-[60px] py-[18px] bg-[#8885c9] rounded-lg justify-center items-center gap-2.5 inline-flex"
          >
            Yes, delete
          </button>
        </DialogActions>
      </Dialog>
      {/* Edit Service Dialog */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={isEditDialogOpen}
        onClose={handleEditDialogClose}
        PaperProps={{
          elevation: 0,
          sx: {
            paddingY: 7,
            paddingX: 5,
            color: "#FDFDFD",
            backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
            borderRadius: "14px",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "var(--tw-bg-opacity, #2B2A49)", // Example dark mode background color
            },
          },
          className:
            "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white", // Tailwind dark mode classes
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#2B2A49",
            boxShadow: "none",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Example dark mode backdrop color
            },
          },
          className:
            "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]", // Tailwind dark mode classes
        }}
      >
        <DialogTitle className="w-full flex items-end justify-end">
          <svg
            onClick={handleEditDialogClose}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
          >
            <path
              className="stroke-current text-[#4A488E] dark:text-white"
              d="M31.5 2L2 31.5M2 2L31.5 31.5"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-row gap-5 items-center justify-start ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
            >
              <path
                className="stroke-current text-[#4A488E] dark:text-[#EDEDFB]"
                d="M16.125 3.86873L20.25 7.99372M13.375 23.1187H24.375M2.375 17.6187L1 23.1187L6.5 21.7437L22.4308 5.81297C22.9463 5.29727 23.2359 4.59793 23.2359 3.86873C23.2359 3.13952 22.9463 2.44018 22.4308 1.92448L22.1942 1.68798C21.6785 1.17243 20.9792 0.882812 20.25 0.882812C19.5208 0.882813 18.8215 1.17243 18.3058 1.68798L2.375 17.6187Z"
                stroke="#EDEDFB"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            <div className="text-[#4A488E] dark:text-white text-[25px] font-normal ">
              Edit service
            </div>
          </div>
          <div className="h-32 justify-start items-start gap-[47px] inline-flex mt-10">
            <div className="flex flex-col items-center">
              <label htmlFor="upload-image" className="cursor-pointer">
                <img
                  className="w-32 h-32 rounded-full"
                  src={imagePreview}
                  alt="Service Image"
                />
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="mt-4  text-white"
                style={{ display: "none" }}
                id="upload-image"
              />
            </div>
            <div className="w-[546px] h-32 flex-col justify-start items-end gap-4 inline-flex ">
              <input
                name="name"
                value={newService.name}
                onChange={handleChange}
                className="self-stretch h-[45px] px-5  text-[#4A488E] dark:text-white  py-3.5 bg-[#E9E9FF] dark:bg-[#3a3a68] rounded-md justify-start items-center gap-2.5 inline-flex border-none"
              />
            </div>
          </div>
          <div className="mt-7 flex flex-col gap-7 items-center justify-center w-full">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row gap-1 items-center justify-start">
                <div className="text-[#8A8A8A] dark:text-white text-[19px] font-normal ">
                  Duration
                </div>
              </div>
              <Datepicker
                name="duration"
                value={newService.duration}
                onChange={handleDateChange}
                showShortcuts={true}
                primaryColor={"blue"}
                popoverDirection="up"
                asSingle={false}
                className="h-[45px] bg-[#E9E9FF] dark:bg-[#3a3a68] ounded-md w-full border-none"
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="dark:text-white text-[#585858] text-[19px] font-normal ">
                Cost
              </div>
              <input
                type="number"
                name="cost"
                value={newService.cost}
                onChange={handleChange}
                className="h-[45px] bg-[#E9E9FF] dark:bg-[#3a3a68]  text-[#4A488E] dark:text-white rounded-md w-full border-none"
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="dark:text-white text-[#585858] text-[19px] font-normal ">
                Location
              </div>
              <input
                name="location"
                value={newService.location}
                onChange={handleChange}
                className="h-[45px] bg-[#E9E9FF] dark:bg-[#3a3a68]  text-[#4A488E] dark:text-white  rounded-md w-full border-none"
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="dark:text-white text-[#585858] text-[19px] font-normal ">
                Provider
              </div>

              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  backgroundColor: "#3a3a68",
                  borderRadius: "8px",
                  color: "#fff",
                }}
              >
                <Select
                  name="provider"
                  value={newService.provider}
                  onChange={handleChange}
                  className="
                  border-[#E9E9FF]
                  bg-[#E9E9FF] 
                  text-[#4A488E] 
                  dark:bg-[#3a3a68] 
                      dark:border-[#3a3a68]
              
                  dark:text-white
                  focus:outline-none
                  focus:ring-2
                  focus:ring-[#8885c9]
                  hover:ring-1
                  hover:ring-gray-400
                "
                  MenuProps={{
                    PaperProps: {
                      className: `
                                    
                      bg-[#E9E9FF] 
                      text-[#4A488E] 
                      dark:bg-[#3a3a68] 
                      dark:text-white
                    `,
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Select an option
                  </MenuItem>
                  <MenuItem value="In person">
                    <SvgIcon viewBox="0 0 20 21" sx={{ marginRight: 1 }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          className="fill-current text-[#585858] dark:text-[#FDFDFD]"
                          d="M2.50089 7.52007C2.5008 6.79491 2.68096 6.0811 3.02518 5.44284C3.36939 4.80459 3.86685 4.2619 4.47283 3.86359C5.0788 3.46528 5.77428 3.22384 6.49672 3.16099C7.21915 3.09815 7.94587 3.21586 8.61152 3.50354C9.27718 3.79122 9.86088 4.23985 10.3101 4.80908C10.7594 5.37831 11.0601 6.05029 11.1853 6.76457C11.3104 7.47885 11.256 8.21303 11.027 8.90109C10.798 9.58914 10.4016 10.2095 9.87339 10.7063C10.8693 11.1898 11.7339 11.9062 12.3941 12.7949C13.0543 13.6836 13.4905 14.7183 13.6659 15.8113C13.6852 15.933 13.6803 16.0572 13.6516 16.177C13.6229 16.2967 13.5708 16.4096 13.4985 16.5093C13.4261 16.6089 13.3348 16.6934 13.2298 16.7577C13.1248 16.8221 13.0082 16.8652 12.8865 16.8844C12.7649 16.9037 12.6406 16.8989 12.5209 16.8701C12.4011 16.8414 12.2882 16.7894 12.1885 16.717C12.0889 16.6446 12.0045 16.5533 11.9401 16.4484C11.8757 16.3434 11.8327 16.2267 11.8134 16.1051C11.6259 14.9305 11.0258 13.8612 10.1208 13.0892C9.21589 12.3172 8.06537 11.8932 6.87589 11.8932C5.68641 11.8932 4.53588 12.3172 3.63093 13.0892C2.72598 13.8612 2.12587 14.9305 1.93839 16.1051C1.91902 16.2267 1.87588 16.3433 1.81144 16.4483C1.74699 16.5533 1.66251 16.6445 1.5628 16.7168C1.46309 16.7891 1.35012 16.8411 1.23033 16.8698C1.11054 16.8984 0.986276 16.9032 0.864639 16.8838C0.743003 16.8645 0.626372 16.8213 0.521407 16.7569C0.416441 16.6924 0.325197 16.6079 0.252884 16.5082C0.18057 16.4085 0.128603 16.2956 0.0999503 16.1758C0.0712974 16.056 0.0665195 15.9317 0.0858895 15.8101C0.260605 14.7171 0.696632 13.6825 1.35691 12.7941C2.01718 11.9058 2.88219 11.19 3.87839 10.7076Z"
                          fill="#FDFDFD"
                        />
                      </svg>
                    </SvgIcon>
                    In person
                  </MenuItem>
                  <MenuItem value="Virtual">
                    <SvgIcon viewBox="0 0 19 19" sx={{ marginRight: 1 }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1422_12298)">
                          <path
                            className="stroke-current text-[#585858] dark:text-[#FDFDFD]"
                            d="M9.50112 18.3225C11.8407 18.3225 14.0845 17.3931 15.7388 15.7388C17.3931 14.0845 18.3225 11.8407 18.3225 9.50112C18.3225 7.16153 17.3931 4.91776 15.7388 3.26342C14.0845 1.60909 11.8407 0.679688 9.50112 0.679688C7.16153 0.679688 4.91776 1.60909 3.26342 3.26342C1.60909 4.91776 0.679688 7.16153 0.679688 9.50112C0.679688 11.8407 1.60909 14.0845 3.26342 15.7388C4.91776 17.3931 7.16153 18.3225 9.50112 18.3225Z"
                            stroke="#FDFDFD"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            className="stroke-current text-[#585858] dark:text-[#FDFDFD]"
                            d="M13.756 7.12565L11.6931 8.03901V6.94244C11.6928 6.74878 11.6158 6.56313 11.479 6.42606C11.3422 6.289 11.1567 6.21165 10.963 6.21094H5.48014C5.28648 6.21165 5.10098 6.289 4.96417 6.42606C4.82735 6.56313 4.75036 6.74878 4.75 6.94244V12.0589C4.75036 12.2528 4.82754 12.4386 4.96465 12.5757C5.10175 12.7128 5.2876 12.79 5.4815 12.7904H10.9644C11.158 12.7896 11.3435 12.7123 11.4803 12.5752C11.6171 12.4382 11.6941 12.2525 11.6945 12.0589V10.9623L13.756 11.8757C13.8115 11.8968 13.8714 11.9041 13.9304 11.8969C13.9894 11.8898 14.0458 11.8684 14.0946 11.8346C14.1435 11.8008 14.1835 11.7556 14.211 11.703C14.2385 11.6503 14.2529 11.5917 14.2527 11.5323V7.46901C14.2531 7.40947 14.2389 7.35073 14.2115 7.29789C14.184 7.24505 14.1441 7.19969 14.0952 7.16574C14.0463 7.13179 13.9898 7.11027 13.9307 7.10305C13.8716 7.09583 13.8117 7.10448 13.756 7.12565Z"
                            stroke="#FDFDFD"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1422_12298">
                            <rect width="19" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </SvgIcon>
                    Virtual
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-full flex items-end justify-end mt-4">
            <button
              onClick={handleUpdateService}
              className="w-[225px] h-[50px] px-[155px] py-6 bg-[#8885c9] rounded-lg justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-white text-xl font-normal ">Update</div>
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
