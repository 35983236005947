import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie"; // Import js-cookie
import backend_URL from "../../config";

// Base query with authorization header
const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token"); // Get token from cookies
    if (token) {
      headers.set("Authorization", `Bearer ${token}`); // Set Authorization header
    }
    return headers;
  },
});

// Create the workingHoursApi
export const workingHoursApi = createApi({
  reducerPath: "workingHoursApi",
  baseQuery,
  endpoints: (builder) => ({
    // Get working hours
    getWorkingHours: builder.query({
      query: () => ({
        url: `/working-hours`,
        method: "GET",
      }),
    }),

    // Update working hours
    updateWorkingHours: builder.mutation({
      query: ({ id, data }) => ({
        url: `/working-hours/${id}`,
        method: "PUT",
        body: data,
      }),
    }), // Post working hours
    postWorkingHours: builder.mutation({
      query: (newWorkingHours) => ({
        url: `/working-hours/`,
        method: "POST",
        body: newWorkingHours,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetWorkingHoursQuery,
  useUpdateWorkingHoursMutation,
  usePostWorkingHoursMutation,
} = workingHoursApi;
