// App.js
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ViewTypeProvider,
  ViewTypeContext,
} from "./components/utils/contexts/viewTypeContext";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Dashboard from "./components/Dashboard/Dashboard";
import TicketsPanel from "./components/TicketsPanel/TicketsPanel";
import Sidebar from "./components/sidebar/sidebar";
import { TasksList } from "./components/TasksList";
import ProjectsPanel from "./components/ProjectsPanel/ProjectsPanel";
import Clients from "./components/ClientsPanel/Clients";
import { HR } from "./components/HR/HR";
import { EmployeeDetails } from "./components/HR/EmployeeDetails";
import { Appraisal } from "./components/HR/Appraisals";
import { Benefits } from "./components/HR/Benefits";
import { CertificatePage } from "./components/HR/CertificatePage";
import { ScheduleAnnualLeave } from "./components/HR/ScheduleAnnualLeave";
import { Leads } from "./components/CRM/Leads";
import { Deals } from "./components/CRM/Deals";
import { Accounts } from "./components/CRM/Accounts";
import { Settings } from "./components/Settings";
import Comments from "./components/Comments";
import { ProjectWithTasks } from "./components/ProjectWithTasks";
import { SoloClient } from "./components/ClientsPanel/SoloClient";
import { Contacts } from "./components/CRM/Contacts";
import ProjectViewMode from "./components/ProjectViewMode";
import ProjectCard from "./components/ProjectCard";
import { AdminPanel } from "./components/admin/AdminPanel";
import { MyEmployees } from "./components/HR/AdminHR/MyEmployees";
import { UserPage } from "./components/HR/AdminHR/UserPage";
// import { AppraisalAdmin } from "./components/HR/AdminHR/Appraisal";
import { HRDetails } from "./components/HR/AdminHR/HRDetails";
import { EmployeeSetting } from "./components/HR/AdminHR/EmployeeSetting";
import UserManagement from "./components/UserManagement";
import { Benefits as AdminBenefits } from "./components/HR/AdminHR/Benefits";
import RoleManagement from "./components/RoleManagement";
import RoleManagementDepartment from "./components/RoleManagementDepartment";
import InputEmail from "./components/auth/PasswordRecovery/inputEmail";
import FinishPasswordRecovery from "./components/auth/PasswordRecovery/recoverPassword";
import "./components/custom-scrollbar.css";
import { Appointments } from "./components/appointments/Appointments";
import AccountPreferences from "./components/settings/accountPreferences";
import ProfileSettings from "./components/settings/profileSettings";
import Security from "./components/settings/security";
import Notifications from "./components/settings/notification";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import AcceptHoliday from "./components/utils/Holiday/acceptHoliday";
import DenyHoliday from "./components/utils/Holiday/denyHoliday";
import ArchievedTicketsPanel from "./components/TicketsPanel/ArchievedTicketsPanel";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import ProductDashboard from "./components/Inventory/Dashboard/ProductDashboard";
import ProductCategory from "./components/Inventory/ProductCategory/ProductCategory";
import RestockDashboard from "./components/Inventory/Restock/RestockDashboard";

import ReportsDashboard from "./components/Reports/ReportsDashboard";
import OperationalReports from "./components/Reports/OperationalReports";

import { Services } from "./components/appointments/Services";
import FinanceDashboard from "./components/finance/financeDashboard";
import FinanceDetailsComponent from "./components/finance/financeDetailsComponent";
import Expenses from "./components/finance/Expenses";
import AppraisalAdmin from "./components/HR/AdminHR/Appraisal";
import io from "socket.io-client";
import backend_URL from "./config";
import { HandleForceLogout } from "./components/utils/sockets/LogOutInactive";
import { useLogoutMutation } from "./redux/api/authApi"; // Import the logout mutation

function App() {
  const [isSidebarLocked, setIsSidebarLocked] = useState(() => {
    const savedState = Cookies.get("sidebarLocked");
    return savedState === "true" || false;
  });

  const handleSidebarLockToggle = (newLockState) => {
    setIsSidebarLocked(newLockState);
    Cookies.set("sidebarLocked", newLockState, { expires: 7 });
  };

  useEffect(() => {
    const savedState = Cookies.get("sidebarLocked") === "true";
    if (savedState !== isSidebarLocked) {
      setIsSidebarLocked(savedState);
    }
  }, [isSidebarLocked]);

  const [logout] = useLogoutMutation(); // Get the logout mutation

useEffect(() => {

  const socket = io(backend_URL);
  window.socket = socket;

  socket.on('forceLogout', (data) => {
    HandleForceLogout(logout); // Call the logout function
  });

  return () => {
    socket.disconnect();
  };
}, []);

  

  return (
    <ViewTypeProvider>
      <Helmet>
        <title>Fuse Digital</title>
        <link
          rel="icon"
          type="image/png"
          href="%PUBLIC_URL%/images/favicon_green_logo.png"
          sizes="16x16"
        />
      </Helmet>
      <Router>
        <Sidebar
          isLocked={isSidebarLocked}
          onLockToggle={handleSidebarLockToggle} // Pass the toggle handler to Sidebar
        />
        <MainContent isSidebarLocked={isSidebarLocked} />
      </Router>
    </ViewTypeProvider>
  );
}

const MainContent = ({ isSidebarLocked }) => {
  const isMobileView = useContext(ViewTypeContext);
  const [paddingLeft, setPaddingLeft] = useState(
    isMobileView ? "120px" : isSidebarLocked ? "360px" : "120px"
  );

  useEffect(() => {
    console.log(isSidebarLocked);
    if (isMobileView) {
      setPaddingLeft("120px");
    } else {
      setPaddingLeft(isSidebarLocked ? "360px" : "120px");
    }
  }, [isSidebarLocked, isMobileView]);

  return (
    <div
      className={`scrollbar-thin bg-[#CAC9F1] dark:bg-[#2B2A49]`}
      style={{ paddingLeft }}
    >
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-recovery/email" element={<InputEmail />} />
        <Route
          path="/password-recovery/finish/:token"
          element={<FinishPasswordRecovery />}
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/taskslist/:category/"
          element={
            <ProtectedRoute>
              <TicketsPanel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/taskslist/completed/history"
          element={
            <ProtectedRoute>
              <ArchievedTicketsPanel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/projects/:category/"
          element={
            <ProtectedRoute>
              <ProjectsPanel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:client"
          element={
            <ProtectedRoute>
              <SoloClient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR"
          element={
            <ProtectedRoute>
              <HR />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/Employee-Details"
          element={
            <ProtectedRoute>
              <EmployeeDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/AppraisalAdmin"
          element={
            <ProtectedRoute>
              <AppraisalAdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/Appraisal"
          element={
            <ProtectedRoute>
              <Appraisal />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/Benefits"
          element={
            <ProtectedRoute>
              <Benefits />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/My-Employees"
          element={
            <ProtectedRoute>
              <MyEmployees />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/HR-Details/:category"
          element={
            <ProtectedRoute>
              <EmployeeSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/My-Employees/:category"
          element={
            <ProtectedRoute>
              <UserPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/projectViewMode"
          element={
            <ProtectedRoute>
              <ProjectViewMode />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/Certificate-Upload-Page"
          element={
            <ProtectedRoute>
              <CertificatePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HR/Schedule-Annual-Leave"
          element={
            <ProtectedRoute>
              <ScheduleAnnualLeave />
            </ProtectedRoute>
          }
        />
        <Route path="/HR/acceptHoliday/:token" element={<AcceptHoliday />} />
        <Route path="/HR/denyHoliday/:token" element={<DenyHoliday />} />
        <Route
          path="/CRM/Leads"
          element={
            <ProtectedRoute>
              <Leads />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CRM/Deals"
          element={
            <ProtectedRoute>
              <Deals />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CRM/Deals/:leadId"
          element={
            <ProtectedRoute>
              <Deals />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CRM/Accounts"
          element={
            <ProtectedRoute>
              <Accounts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CRM/Accounts/:dealId"
          element={
            <ProtectedRoute>
              <Accounts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CRM/Contacts"
          element={
            <ProtectedRoute>
              <Contacts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/project"
          element={
            <ProtectedRoute>
              <ProjectCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/dashboard-reports"
          element={
            <ProtectedRoute>
              <ReportsDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/operational-reports"
          element={
            <ProtectedRoute>
              <OperationalReports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports/operational-reports/:id/"
          element={
            <ProtectedRoute>
              <OperationalReports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/account-preferences"
          element={
            <ProtectedRoute>
              <AccountPreferences />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/profile-settings"
          element={
            <ProtectedRoute>
              <ProfileSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/security"
          element={
            <ProtectedRoute>
              <Security />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/notifications"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/projects/My-Projects/:category/"
          element={
            <ProtectedRoute>
              <ProjectWithTasks />
            </ProtectedRoute>
          }
        />
        <Route
          path="/appointments/"
          element={
            <ProtectedRoute>
              <Appointments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/appointments/services"
          element={
            <ProtectedRoute>
              <Services />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdminPanel"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdminPanel/UserManagement"
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdminPanel/RoleManagement"
          element={
            <ProtectedRoute>
              <RoleManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdminPanel/RoleManagement/:deptKey"
          element={
            <ProtectedRoute>
              <RoleManagementDepartment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdminPanel/Benefits"
          element={
            <ProtectedRoute>
              <AdminBenefits />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdminPanel/HR-Details"
          element={
            <ProtectedRoute>
              <HRDetails />
            </ProtectedRoute>
          }
        />
        {/* Routes For Inventory */}
        <Route
          path="/inventory/product-dashboard"
          element={
            <ProtectedRoute>
              <ProductDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inventory/product-categories"
          element={
            <ProtectedRoute>
              <ProductCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inventory/low-stock"
          element={
            <ProtectedRoute>
              <ProductDashboard isLowStockPage={true} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inventory/restock"
          element={
            <ProtectedRoute>
              <RestockDashboard />
            </ProtectedRoute>
          }
        />
        {/* Finance  */}
        <Route
          path="/finance/finance-dashboard"
          element={
            <ProtectedRoute>
              <FinanceDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/finance/revenue"
          element={
            <ProtectedRoute>
              <FinanceDetailsComponent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/finance/expenses"
          element={
            <ProtectedRoute>
              <Expenses />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
