import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../../config";
import Cookies from "js-cookie"; // Import js-cookie

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const activitiesApi = createApi({
  reducerPath: "activitiesApi",
  baseQuery,
  endpoints: (builder) => ({
    getActivities: builder.query({
      query() {
        return {
          url: "/activities",
          method: "GET",
        };
      },
    }),
    getActivityDetails: builder.query({
      query(activity) {
        return {
          url: `/activities/${activity.id}`,
          method: "GET",
        };
      },
    }),
    postActivity: builder.mutation({
      query(activity) {
        return {
          url: "/activities",
          method: "POST",
          body: activity,
        };
      },
    }),
    updateActivity: builder.mutation({
      query(activity) {
        return {
          url: `/activities/${activity.id}`,
          method: "PUT",
          body: activity,
        };
      },
    }),
    deleteActivity: builder.mutation({
      query(activity) {
        return {
          url: `/activities/${activity.id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetActivityDetailsQuery,
  useGetActivitiesQuery,
  usePostActivityMutation,
  useDeleteActivityMutation,
  useUpdateActivityMutation,
} = activitiesApi;
