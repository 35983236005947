import React, { useState } from "react";
import DropdownRoles from "./Dropdown/DropdownRoles";
import FormEditUserManagement from "./FormEditUserManagement";
import { motion } from "framer-motion";
import { useDeleteUserMutation } from "../redux/api/authApi";
import { TrashcanSVG } from "./utils/generalSVGS";
import { notify } from "./utils/notificationManager";

const CardUserManagement = ({ user }) => {
  const [category, setCategory] = useState("CEva");
  const [isCategoryChosen, setIsCategoryChosen] = useState(false);
  const [deleteUser] = useDeleteUserMutation(); // Initialize the delete user mutation

  const userName = user?.name || "Unknown User";
  const userAvatar = user?.avatar?.url || "/images/users.svg";

  const handleDeleteConfirmation = async () => {
    // Trigger the notify function in advanced mode
    notify(
      `Are you sure you want to delete ${userName}?`, // Custom message
      2, // Harshness level 2 (error/red)
      async () => {
        // Go ahead callback (confirm delete)
        try {
          await deleteUser(user._id).unwrap();
          notify("User deleted successfully", 0, null, null, true); // Show success toast
        } catch (error) {
          notify("Failed to delete the user", 2, null, null, true); // Show error toast
        }
      },
      () => {
        // Cancel callback (user canceled)
        notify("User deletion canceled", 1, null, null, true); // Show warning toast
      }
    );
  };

  return (
    <div
      key={user?._id}
      className="w-full bg-[#E8E8FF] dark:bg-[#3A3A68] mt-[32px] py-[16px] px-[20px] flex items-center justify-between rounded-[5px] shadow-none hover:shadow-md hover:bg-[#E1E1FF] dark:hover:bg-[#333357] transition-all"
    >
      <div className="flex items-center">
        <div
          className="w-[66px] h-[66px] rounded-full mr-[17px]"
          style={{
            backgroundImage: `url(${userAvatar})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <p className="text-[18px] text-[#4A488E] font-semibold dark:text-[#EDEDFB]">
          {userName}
        </p>
      </div>
      <div className="flex items-center gap-[7px]">
        <DropdownRoles
          setCategory={setCategory}
          setIsCategoryChosen={setIsCategoryChosen}
          roleUser={user?.role}
          userId={user?._id}
        />
      </div>
      <div className="flex flex-row gap-2 items-center justify-center content-center">
        <FormEditUserManagement user={user} />
        <button onClick={handleDeleteConfirmation}>
          <TrashcanSVG />
        </button>
      </div>
    </div>
  );
};

export default CardUserManagement;
