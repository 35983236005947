import React from "react";
import { motion } from "framer-motion";

const Placeholder = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-[23px] px-[20px] bg-[#CAC9F1] dark:bg-[#484878] rounded-[8px] border-2 border-dashed border-[#7E22CE]"
      style={{ height: "100px" }}
    />
  );
};

export default Placeholder;
