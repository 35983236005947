import React, { useEffect, useState } from "react";
import SquareInput from "../UI/input";
import FuseButton from "../UI/button";

const CategoryModal = ({ isOpen, onClose, onSave, category = null }) => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');

  // If a category is passed, populate the inputs for editing
  useEffect(() => {
    if (category) {
      setCategoryName(category.name);
      setCategoryDescription(category.description);
    } else {
      // If it's a new category, reset the inputs
      setCategoryName('');
      setCategoryDescription('');
    }
  }, [category]);

  const handleSave = () => {
    if (categoryName && categoryDescription) {
      onSave({ _id: category?._id, name: categoryName, description: categoryDescription }); // Use _id when editing
      onClose(); // Close the modal after saving
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="w-[803px] h-[556px] px-[68px] py-10 bg-[#2b2a49] rounded-[14px] justify-start items-center gap-2.5 inline-flex">
        <div className="w-full flex flex-col">
          <div className="flex justify-between">
            <div className="text-[#ededfb] text-[25px] font-normal">
              {category ? "Edit Category" : "New Category"}
            </div>
            <button className="text-white text-2xl" onClick={onClose}>
              &times;
            </button>
          </div>
          <div className="flex-col justify-start items-start gap-[15px] mt-10">
            <SquareInput
              label="Category name"
              value={categoryName}
              onChange={setCategoryName}
              type="text"
            />
            <SquareInput
              label="Category description"
              value={categoryDescription}
              onChange={setCategoryDescription}
              type="textarea"
            />
            <div className="flex justify-end mt-4">
              <FuseButton
                label={category ? "Save" : "Create"}
                onClick={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
