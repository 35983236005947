import React, { useState, useEffect } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationComponent from "../../NotificationComponent";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DataTable from "../DataTable";
import RestockModal from "./RestockDashboardForm";
import {
  useGetRestocksQuery,
  usePostRestockMutation,
  useUpdateRestockMutation,
  useDeleteRestocksMutation,
  useUpdateRestockStatusMutation,
} from "../../../redux/api/inventory/restock";
import { useGetProductsQuery } from "../../../redux/api/inventory/products";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { InventoryArrowLeftIcon, InventoryArrowRightIcon } from "../svgs";
import BackendSearchBar from "../UI/backendSearchBar";
import FilterComponent from "../Dashboard/FilterDropdown";

const columnMapping = {
  productName: "Product Name",
  SKUNumber: "SKU Number",
  priority: "Priority",
  currentStock: "Current Stock",
  restockQuantity: "Restock Quantity",
  supplier: "Supplier",
  orderDate: "Order Date",
  expectedDelivery: "Expected Delivery",
  comment: "Comment",
  status: "Status",
};

// Loading spinner component using Tailwind CSS
const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center mt-4">
      <div className="w-8 h-8 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
};

export const RestockDashboard = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [productSearchQuery, setProductSearchQuery] = useState(""); // Query for product search
  const [filteredProducts, setFilteredProducts] = useState([]); // Products filtered from search query
  const [selectedRestocks, setSelectedRestocks] = useState([]);
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [dialogRestock, setDialogRestock] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(5); // Number of restocks per page
  const [isUpdating, setIsUpdating] = useState(false); // Track updating status
  const [isDeleting, setIsDeleting] = useState(false); // Track deleting status
  // New states for filters and visible columns
  const [filters, setFilters] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState({
    productName: true,
    SKUNumber: true,
    priority: true,
    currentStock: true,
    restockQuantity: true,
    supplier: true,
    orderDate: true,
    expectedDelivery: true,
    comment: true,
    status: true,
  });

  const {
    data: restocksData,
    error: restockError,
    isLoading: isRestockLoading,
    refetch,
  } = useGetRestocksQuery({
    page: currentPage,
    limit,
    search: searchQuery,
    filters, // Pass filters to the API request
  });

  // Fetching products based on the search query from the product search bar
  const { data: productsData } = useGetProductsQuery({
    search: productSearchQuery,
  });

  const [createRestock] = usePostRestockMutation();
  const [updateRestock] = useUpdateRestockMutation();
  const [deleteRestocks] = useDeleteRestocksMutation();
  const [updateRestockStatus] = useUpdateRestockStatusMutation();

  useEffect(() => {
    if (restocksData) {
      setTotalPages(restocksData.totalPages); // Set total pages from API response
    }
  }, [restocksData]);

  useEffect(() => {
    if (productsData) {
      setFilteredProducts(productsData.products); // Update filtered products from API response
    }
  }, [productsData]);

  const restocks = restocksData?.restocks || [];

  //   const handleStatusChange = async (id, newRestockStatus) => {
  //     const restockToUpdate = restocks.find((restock) => restock._id === id);
  //     if (restockToUpdate) {
  //       const updatedRestock = {
  //         ...restockToUpdate,
  //         status: newRestockStatus,
  //       };
  //       setIsUpdating(true); // Start update spinner
  //       try {
  //         await updateRestock({ restockId: id, restockData: updatedRestock }).unwrap();
  //         refetch();
  //       } catch (error) {
  //         console.error("Error updating restock:", error);
  //       } finally {
  //         setIsUpdating(false); // Stop update spinner
  //       }
  //     }
  //   };

  const handleStatusChange = async (id, newRestockStatus) => {
    setIsUpdating(true); // Start update spinner
    try {
      await updateRestockStatus({
        restockId: id,
        status: newRestockStatus,
      }).unwrap(); // Use updateRestockStatus
      refetch(); // Refetch restocks after update
    } catch (error) {
      console.error("Error updating restock status:", error);
    } finally {
      setIsUpdating(false); // Stop update spinner
    }
  };

  const handlePriorityChange = async (id, newPriorityStatus) => {
    const restockToUpdate = restocks.find((restock) => restock._id === id);
    if (restockToUpdate) {
      const updatedRestock = {
        ...restockToUpdate,
        priority: newPriorityStatus,
      };
      setIsUpdating(true); // Start update spinner
      try {
        await updateRestock({
          restockId: id,
          restockData: updatedRestock,
        }).unwrap();
        refetch();
      } catch (error) {
        console.error("Error updating priority status:", error);
      } finally {
        setIsUpdating(false); // Stop update spinner
      }
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allRestockIds = restocks.map((restock) => restock._id);
      setSelectedRestocks(allRestockIds);
    } else {
      setSelectedRestocks([]);
    }
  };

  const handleSelectItem = (event, restockId) => {
    if (event.target.checked) {
      setSelectedRestocks((prevSelected) => [...prevSelected, restockId]);
    } else {
      setSelectedRestocks((prevSelected) =>
        prevSelected.filter((id) => id !== restockId)
      );
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("landscape");
    const selectedRestockData = restocks.filter((restock) =>
      selectedRestocks.includes(restock._id)
    );
    const tableColumn = Object.keys(restocks[0]).map(
      (key) => columnMapping[key] || key
    );
    const tableRows = [];

    selectedRestockData.forEach((restock) => {
      const restockData = Object.values(restock);
      tableRows.push(restockData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: "grid",
      headStyles: { fillColor: [22, 160, 133] },
      margin: { top: 20 },
      styles: { overflow: "linebreak" },
    });
    doc.save("restocks.pdf");
  };

  const handleEditRestocks = () => {
    if (selectedRestocks.length === 1) {
      const restockToEdit = restocks.find(
        (restock) => restock._id === selectedRestocks[0]
      );

      // Ensure restockToEdit contains both the productId and productName
      setDialogRestock(restockToEdit);
      setOpenDialogNew(true); // Open the modal with the selected restock
    }
  };

  const handleDeleteRestocks = async () => {
    setIsDeleting(true); // Start delete spinner
    try {
      await deleteRestocks({ ids: selectedRestocks }).unwrap();
      setSelectedRestocks([]);
      refetch();
    } catch (error) {
      console.error("Error deleting restocks:", error);
    } finally {
      setIsDeleting(false); // Stop delete spinner
    }
  };

  const handleSaveRestock = async (newRestock) => {
    try {
      if (newRestock._id) {
        await updateRestock({
          restockId: newRestock._id,
          restockData: newRestock,
        }).unwrap();
      } else {
        await createRestock(newRestock).unwrap();
      }

      setOpenDialogNew(false);
      setDialogRestock(null);
      refetch();
    } catch (error) {
      console.error("Failed to save restock:", error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1); // This automatically triggers a new fetch due to useGetRestocksQuery
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1); // This also triggers a new fetch
    }
  };

  const handleProductSearch = (query) => {
    setProductSearchQuery(query); // Trigger product search when user types in product search bar
  };

  // Define default filters based on the page type
  const defaultProductFilters = {
    productName: true,
    status: true,
    description: true,
    skuNumber: true,
    stock: true,
    expiryDate: true,
    uploadDate: true,
    unitPrice: true,
    totalPrice: true,
  };

  const defaultRestockFilters = {
    productName: true,
    skuNumber: true,
    priority: true,
    currentStock: true,
    restockQuantity: true,
    supplier: true,
    orderDate: true,
    expectedDelivery: true,
    comment: true,
    status: true,
  };

  const [selectedFilters, setSelectedFilters] = useState(defaultRestockFilters);
  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  pb-[20px] dark:bg-[#2B2A49]">
      <div className="flex justify-between items-center">
        <Breadcrumbs
          className="text-[#4A488E] dark:text-[#EDEDFB]"
          aria-label="breadcrumb"
        >
          <Link className="text-[#4A488E] dark:text-[#EDEDFB]" to="/dashboard">
            Fuse Digital
          </Link>
          <Link
            className="text-[#4A488E] dark:text-[#EDEDFB]"
            to={`/inventory/restock`}
          >
            Inventory
          </Link>
          <Link
            className="text-[#4A488E] dark:text-[#EDEDFB]"
            to={`/inventory/restock`}
          >
            <b>Restock Dashboard</b>
          </Link>
        </Breadcrumbs>
        <NotificationComponent />
      </div>

      <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal mt-[40px] mb-[53px]">
        Restock
      </div>

      <div className="flex items-center justify-between mb-4">
        <div className="relative">
          <BackendSearchBar onSearchQuery={(query) => setSearchQuery(query)} />
        </div>
        <div className="h-[34px] flex items-center gap-[15px]">
          <div
            className="w-fit h-[38px] px-[15px] py-2.5 bg-violet-100 rounded-md border border-indigo-500 justify-center items-center gap-2.5 flex cursor-pointer"
            onClick={() => setOpenDialogNew(true)}
          >
            <div className="text-[#4A488E] text-[15px] font-medium">
              + Restock Item
            </div>
          </div>
          <FilterComponent
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            filters={filters}
            setFilters={setFilters}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            isRestockPage={true}
          />
        </div>
      </div>

      {isRestockLoading || isUpdating || isDeleting ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          selectedFilters={selectedFilters}
          data={restocks.map((restock) => ({
            ...restock,
            productName: restock.productName || "Unknown Product",
            SKUNumber: restock.sku || "N/A",
            priority: restock.priority || "Unknown Priority",
            status: restock.status || "Unknown Status",
          }))}
          isLoading={isRestockLoading}
          error={restockError}
          selectedItems={selectedRestocks}
          handleSelectAll={handleSelectAll}
          handleSelectItem={handleSelectItem}
          onStatusChange={handleStatusChange} // Pass the status change handler
          onPriorityChange={handlePriorityChange} // Pass the priority change handler
          handleEditItems={handleEditRestocks}
          handleDeleteItems={handleDeleteRestocks}
          isRestockPage={true}
        />
      )}

      {/* Pagination Controls */}
      <div className="flex justify-end items-center mt-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="px-4 dark:text-white"
        >
          <InventoryArrowLeftIcon />
        </button>
        <span className="dark:text-white">
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="dark:text-white px-4"
        >
          <InventoryArrowRightIcon />
        </button>
      </div>

      <RestockModal
        isOpen={openDialogNew || !!dialogRestock}
        onClose={() => {
          setOpenDialogNew(false);
          setDialogRestock(null);
        }}
        onSave={handleSaveRestock}
        restock={dialogRestock}
        onProductSearch={handleProductSearch} // Pass product search handler
        filteredProducts={filteredProducts} // Pass filtered products to the modal
      />
    </div>
  );
};

export default RestockDashboard;
