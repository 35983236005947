import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Link } from "react-router-dom";
import { BenefitsCard } from "./BenefitsCard";
import { useGetProvidersQuery } from "../../../redux/api/provider";
import {
  useUploadContractMutation,
  usePostProviderMutation,
} from "../../../redux/api/provider";
import { notify } from "../../utils/notificationManager";

import NotificationComponent from "../../NotificationComponent";

import { motion } from "framer-motion";

export const Benefits = () => {
  const {
    data: providersData,
    error: providersError,
    isLoading: providersLoading,
    refetch,
  } = useGetProvidersQuery();
  React.useEffect(() => {
    if (providersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetch();
      }, 4000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [providersData, refetch]);
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const [newProvider, setNewProvider] = useState({
    name: "",
    description: "",
    documente: [],
  });

  const [shouldRefetch, setShouldRefetch] = useState(false);

  useEffect(() => {
    if (providersError) {
      notify(providersError?.data?.message, 2, null, null, true);
    }
  }, [providersError]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [shouldRefetch, refetch]);

  const handleSetOpenNewDialog = () => {
    setOpenNewDialog(!openNewDialog);
  };

  const handleFileChange = (e) => {
    setContractFile(e.target.files[0]);
  };

  const [uploadContract] = useUploadContractMutation();
  const [postProvider] = usePostProviderMutation();

  const handleSave = async () => {
    notify(
      "Are you sure you want to save?",
      1,
      async () => {
        let documente = [...newProvider.documente];

        if (contractFile) {
          const formData = new FormData();
          formData.append("file", contractFile);

          const res = await uploadContract(formData);
          if (!res.data || !res.data.links || res.data.links.length === 0) {
            console.error("File upload failed");
            notify("File upload failed", 2, null, null, true);
            return;
          }

          documente.push(res.data.links[0]);
        }

        const benefitData = { ...newProvider, documente };

        try {
          await postProvider(benefitData);
          notify("Provider updated successfully", 0, null, null, true);
          setNewProvider({ name: "", description: "", documente: [] });
          setContractFile(null);
          setOpenNewDialog(false);
          setShouldRefetch(true); // Trigger the refetch
        } catch (error) {
          console.error("Failed to update provider", error);
          notify("Failed to update provider", 2, null, null, true);
        }
      },
      () => {
        notify("Save cancelled", 2, null, null, true);
      }
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProvider((prevProvider) => ({ ...prevProvider, [name]: value }));
  };
  const removeDoc = () => {
    setContractFile(null);
  };

  return (
    <div className="pr-[54px] pt-[25px] ml-[-59px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]">
      <div className="w-full px-[40px] md:mb-40">
        <div className="p-4 flex items-center flex-wrap justify-between">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="dark:text-[#EDEDFB] text-[#4A488E]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/adminpanel`}
              >
                Admin Panel
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/adminpanel/benefits`}
              >
                <b>Benefits</b>
              </Link>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>
        <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal mt-[40px] mb-[53px] ml-[20px]">
          Benefits
        </div>
        <motion.button
          onClick={handleSetOpenNewDialog}
          className="w-[162px] h-[38px] px-[15px] py-2.5 bg-violet-100 rounded-md justify-center items-center gap-2.5 inline-flex ml-[20px] mb-10"
          whileHover={{ scale: 0.95 }} // Scale down the button on hover
          whileTap={{ scale: 0.9 }} // Scale down the button more when clicked
        >
          <div className="text-indigo-800 text-[15px] font-medium">
            + Add new benefit
          </div>
        </motion.button>
        <div className="flex flex-row flex-wrap gap-4 ml-[20px]">
          {providersData?.providers.map((p) => (
            <BenefitsCard
              key={p.id}
              provider={p}
              isAdmin={true}
              setShouldRefetch={setShouldRefetch}
            />
          ))}
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        id="newDialog"
        open={openNewDialog}
        onClose={handleSetOpenNewDialog}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
            borderRadius: "14px",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "var(--tw-bg-opacity, #2B2A49)", // Example dark mode background color
            },
          },
          className:
            "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white", // Tailwind dark mode classes
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#2B2A49",
            boxShadow: "none",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Example dark mode backdrop color
            },
          },
          className:
            "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]", // Tailwind dark mode classes
        }}
      >
        <div className="py-[36px] px-[55px]">
          <DialogTitle className="text-indigo-800 dark:text-[#EDEDFB] text-[25px] flex flex-row justify-between">
            <span>New benefit</span>
            <svg
              className="cursor-pointer"
              onClick={handleSetOpenNewDialog}
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
            >
              <path
                className="fill-current dark:text-[#EDEDFB]"
                d="M29.7249 28.0888C29.8324 28.1963 29.9176 28.3238 29.9757 28.4642C30.0339 28.6045 30.0638 28.755 30.0638 28.9069C30.0638 29.0588 30.0339 29.2093 29.9757 29.3496C29.9176 29.49 29.8324 29.6175 29.7249 29.7249C29.6175 29.8324 29.49 29.9176 29.3496 29.9757C29.2093 30.0339 29.0588 30.0638 28.9069 30.0638C28.755 30.0638 28.6045 30.0339 28.4642 29.9757C28.3238 29.9176 28.1963 29.8324 28.0888 29.7249L18.5006 20.1353L8.91244 29.7249C8.69548 29.9419 8.40122 30.0638 8.09439 30.0638C7.78757 30.0638 7.49331 29.9419 7.27635 29.7249C7.05939 29.508 6.9375 29.2137 6.9375 28.9069C6.9375 28.6001 7.05939 28.3058 7.27635 28.0888L16.866 18.5006L7.27635 8.91244C7.05939 8.69548 6.9375 8.40122 6.9375 8.09439C6.9375 7.78757 7.05939 7.49331 7.27635 7.27635C7.49331 7.05939 7.78757 6.9375 8.09439 6.9375C8.40122 6.9375 8.69548 7.05939 8.91244 7.27635L18.5006 16.866L28.0888 7.27635C28.3058 7.05939 28.6001 6.9375 28.9069 6.9375C29.2137 6.9375 29.508 7.05939 29.7249 7.27635C29.9419 7.49331 30.0638 7.78757 30.0638 8.09439C30.0638 8.40122 29.9419 8.69548 29.7249 8.91244L20.1353 18.5006L29.7249 28.0888Z"
                fill="#4A488E"
              />
            </svg>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-[16px] mt-[67px]">
              <div className="flex flex-row justify-between items-center">
                <div className="text-neutral-700 dark:text-[#EDEDFB] text-lg font-normal">
                  Title
                </div>
                <input
                  name="name"
                  value={newProvider.name}
                  onChange={handleInputChange}
                  className="w-[545px] h-[47px] px-[25px] py-3.5 bg-violet-100 dark:bg-[#3A3A68] rounded border-none"
                />
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="text-neutral-700 dark:text-[#EDEDFB] text-lg font-normal">
                  Description
                </div>
                <input
                  name="description"
                  value={newProvider.description}
                  onChange={handleInputChange}
                  className="w-[545px] h-[47px] px-[25px] py-3.5 bg-violet-100 dark:bg-[#3A3A68]  rounded border-none"
                />
              </div>
              <div className="flex flex-row  items-center">
                <div className="text-neutral-700 dark:text-[#EDEDFB] mr-[108px] text-lg font-normal">
                  Document*
                </div>
                <div className="flex flex-row gap-4 items-center">
                  {contractFile && (
                    <div>
                      <div className="w-[9px] h-[9px] relative left-11">
                        <svg
                          onClick={removeDoc}
                          id="delete-single-doc"
                          className="cursor-pointer"
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_730_9608)">
                            <path
                              className="fill-current dark:text-[#EDEDFB]"
                              d="M8.86813 8.23142C8.90994 8.27322 8.9431 8.32286 8.96573 8.37748C8.98835 8.4321 9 8.49065 9 8.54977C9 8.6089 8.98835 8.66744 8.96573 8.72207C8.9431 8.77669 8.90994 8.82632 8.86813 8.86813C8.82632 8.90994 8.77669 8.9431 8.72207 8.96573C8.66744 8.98835 8.6089 9 8.54977 9C8.49065 9 8.4321 8.98835 8.37748 8.96573C8.32286 8.9431 8.27322 8.90994 8.23142 8.86813L4.5 5.13615L0.768582 8.86813C0.684149 8.95257 0.569632 9 0.450225 9C0.330818 9 0.216301 8.95257 0.131868 8.86813C0.0474343 8.7837 2.3538e-09 8.66918 0 8.54977C-2.3538e-09 8.43037 0.0474343 8.31585 0.131868 8.23142L3.86385 4.5L0.131868 0.768582C0.0474343 0.684149 -8.89652e-10 0.569632 0 0.450225C8.89652e-10 0.330818 0.0474343 0.216301 0.131868 0.131868C0.216301 0.0474343 0.330818 8.89653e-10 0.450225 0C0.569632 -8.89652e-10 0.684149 0.0474343 0.768582 0.131868L4.5 3.86385L8.23142 0.131868C8.31585 0.0474343 8.43037 -2.3538e-09 8.54977 0C8.66918 2.3538e-09 8.7837 0.0474343 8.86813 0.131868C8.95257 0.216301 9 0.330818 9 0.450225C9 0.569632 8.95257 0.684149 8.86813 0.768582L5.13615 4.5L8.86813 8.23142Z"
                              fill="#7674C2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_730_9608">
                              <rect width="9" height="9" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>{" "}
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="59"
                        viewBox="0 0 50 59"
                        fill="none"
                      >
                        <path
                          className="fill-current dark:text-[#EDEDFB]"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.7 4.46667C6.10768 4.46667 5.53963 4.70197 5.1208 5.1208C4.70196 5.53963 4.46667 6.10769 4.46667 6.7V51.3667C4.46667 51.959 4.70196 52.527 5.1208 52.9459C5.53963 53.3647 6.10768 53.6 6.7 53.6H42.4333C43.0257 53.6 43.5937 53.3647 44.0125 52.9459C44.4314 52.527 44.6667 51.959 44.6667 51.3667V22.3333H29.0333C28.441 22.3333 27.873 22.098 27.4541 21.6792C27.0353 21.2604 26.8 20.6923 26.8 20.1V4.46667H6.7ZM31.2667 7.6246L41.5087 17.8667H31.2667V7.6246ZM0 6.7C0 4.92305 0.705891 3.21888 1.96238 1.96239C3.21888 0.705895 4.92305 3.56215e-06 6.7 3.56215e-06H29.0333C29.3268 -0.000520588 29.6175 0.0568047 29.8888 0.168702C30.1601 0.2806 30.4067 0.444876 30.6145 0.652137L48.4812 18.5188C48.6885 18.7266 48.8527 18.9732 48.9646 19.2445C49.0765 19.5158 49.1339 19.8065 49.1333 20.1V51.3667C49.1333 53.1436 48.4274 54.8478 47.171 56.1043C45.9145 57.3608 44.2103 58.0667 42.4333 58.0667H6.7C4.92305 58.0667 3.21888 57.3608 1.96238 56.1043C0.705891 54.8478 0 53.1436 0 51.3667V6.7Z"
                          fill="#4A488E"
                        />
                        <path
                          className="stroke-current dark:text-[#EDEDFB]"
                          d="M15.5664 40.5332V42.5332C15.5664 43.0636 15.7903 43.5723 16.1888 43.9474C16.5873 44.3225 17.1278 44.5332 17.6914 44.5332H30.4414C31.005 44.5332 31.5455 44.3225 31.944 43.9474C32.3425 43.5723 32.5664 43.0636 32.5664 42.5332V40.5332M18.7539 34.5332L24.0664 39.5332M24.0664 39.5332L29.3789 34.5332M24.0664 39.5332V27.5332"
                          stroke="#4A488E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                  <div className="w-[74px] h-[74px] bg-[#E9E9FF] dark:bg-[#3A3A68] rounded flex items-center justify-center">
                    <label htmlFor="file-upload" className="cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                      >
                        <path
                          className="stroke-current dark:text-[#EDEDFB]"
                          d="M4.83398 20.5418V22.9585C4.83398 23.5994 5.0886 24.2141 5.54181 24.6673C5.99502 25.1205 6.60971 25.3752 7.25065 25.3752H21.7507C22.3916 25.3752 23.0063 25.1205 23.4595 24.6673C23.9127 24.2141 24.1673 23.5994 24.1673 22.9585V20.5418M8.45898 13.2918L14.5007 19.3335M14.5007 19.3335L20.5423 13.2918M14.5007 19.3335V4.8335"
                          stroke="#8D8BDB"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleSave}
              className="w-[151px] h-[54px] bg-indigo-400 rounded-[3px] justify-center items-center gap-2.5 inline-flex"
            >
              <div className="text-white text-lg font-normal">Upload</div>
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
