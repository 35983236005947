// OperationalReportsDetails.js

import React, { useEffect, useState } from "react";
import { useGetProjectsQuery } from "../../redux/api/projectsApi";
import { useGetTasksQuery } from "../../redux/api/tasksApi";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { useParams } from "react-router-dom"; // Import useParams

// Helper function to convert total seconds to HH:MM:SS format
const formatSecondsToTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

const OperationalReportsDetails = ({
  users,
  clients,
  projects,
  tasks,
  tags,
  statuses,
  totalTime,
  selectedDate,
}) => {
  const { data: usersData } = useGetAllUsersQuery();
  const { data: projectsData } = useGetProjectsQuery();
  const { data: ticketsData } = useGetTasksQuery();

  const { id } = useParams(); // Get project ID from URL

  const [tickets, setTickets] = useState([]);

  const getUsername = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user ? user.name : "Unknown User";
  };

  // Extract client IDs for filtering
  const clientIds = clients;
  const taskIds = tasks;

  // Filter and sort tickets based on various criteria
  const filterAndSortTickets = (tickets) => {
    return tickets
      .filter((ticket) => {
        // Filter by project
        const isProjectMatch = id
          ? ticket.proiect === id // If ID is present, match tickets of that project
          : projects.length === 0 || projects.includes(ticket.proiect);

        // Filter by client
        const isClientMatch =
          clientIds.length === 0 || clientIds.includes(ticket.client);

        // Filter by task
        const isTaskMatch =
          taskIds.length === 0 || taskIds.includes(ticket._id);

        // Filter by tags
        const isTagMatch =
          tags.length === 0 || tags.some((tag) => ticket.tags?.includes(tag));

        // Filter by statuses
        const isStatusMatch =
          statuses.length === 0 || statuses.includes(ticket.status);

        // Filter by date range
        const isDateMatch = ticket.timp.some((timpEntry) => {
          if (!selectedDate?.startDate || !selectedDate?.endDate) {
            return true;
          }

          const startDate = new Date(selectedDate.startDate);
          const endDate = new Date(selectedDate.endDate);
          const entryStartDate = new Date(timpEntry.dataStart);
          const entryEndDate = new Date(timpEntry.dataEnd);

          return entryStartDate <= endDate && entryEndDate >= startDate;
        });

        // Filter by users
        const isUserMatch =
          users.length === 0 ||
          ticket.timp.some((timpEntry) => users.includes(timpEntry.user));

        return (
          isProjectMatch &&
          isClientMatch &&
          isTagMatch &&
          isStatusMatch &&
          isDateMatch &&
          isUserMatch &&
          isTaskMatch
        );
      })
      .map((ticket) => {
        // Filter and sort time entries within each ticket
        const filteredTimp = (ticket.timp || [])
          .filter((timpEntry) => {
            const isUserInArray =
              users.length === 0 || users.includes(timpEntry.user);

            if (!selectedDate?.startDate || !selectedDate?.endDate) {
              return isUserInArray;
            }

            const startDate = new Date(selectedDate.startDate);
            const endDate = new Date(selectedDate.endDate);
            const entryStartDate = new Date(timpEntry.dataStart);
            const entryEndDate = new Date(timpEntry.dataEnd);

            return (
              entryStartDate <= endDate &&
              entryEndDate >= startDate &&
              isUserInArray
            );
          })
          .sort((a, b) => new Date(b.dataStart) - new Date(a.dataStart));

        return {
          ...ticket,
          timp: filteredTimp,
        };
      })
      .filter((ticket) => ticket.timp.length > 0)
      .sort((a, b) => {
        const aMostRecentDate = new Date(a.timp[0]?.dataStart || 0);
        const bMostRecentDate = new Date(b.timp[0]?.dataStart || 0);
        return bMostRecentDate - aMostRecentDate;
      });
  };

  useEffect(() => {
    const ticketsForProj = ticketsData?.tickets || [];
    setTickets(filterAndSortTickets(ticketsForProj));
  }, [
    id, // Include id in dependencies
    projects,
    ticketsData,
    users,
    clients,
    tags,
    statuses,
    selectedDate,
    tasks,
  ]);

  // Calculate total time from the filtered tickets
  const totalSeconds = tickets.reduce((total, ticket) => {
    return (
      total + ticket.timp.reduce((sum, timpEntry) => sum + timpEntry.seconds, 0)
    );
  }, 0);

  return (
    <div className="dark:bg-[#3A3A68] bg-[#E7E7F9] py-[17px] px-[20px] rounded-[20px] w-full">
      {/* Header */}
      <div className="flex justify-between items-center pb-[33px] border-[#2B2A49]">
        <span className="dark:text-white font-bold text-xl text-[#4A488E]">
          <span className="dark:text-white text-[18px] text-[#7271AB]">
            Total:
          </span>{" "}
          {totalTime}
        </span>
      </div>

      {/* Table */}
      <div className="w-full mt-4">
        {tickets.map((entry, index) =>
          entry.timp.map((timpEntry, timpIndex) => (
            <div
              key={`${index}-${timpEntry._id || timpIndex}`}
              className="flex justify-between items-center dark:bg-[#3A3A68] bg-[#E7E7F9] text-white p-4 mb-2 border-b border-[#CAC9F1] dark:border-[#d4d4eb57]"
            >
              {/* Task Name */}
              <div className="w-1/3 dark:bg-[#505084] bg-[#FFF] py-[10px] px-[16px] rounded-[3px]">
                <p className="truncate dark:text-[#F1F1F1] text-[#7271AB] text-[18px]">
                  {entry.nume}
                </p>
              </div>

              {/* Department */}
              <div className="w-1/6 text-center">
                <p className="text-[18px] dark:text-[#F1F1F1] text-[#656565]">
                  {entry.department?.join(" & ")}
                </p>
              </div>

              {/* Person */}
              <div className="w-1/6 text-center">
                <p className="text-[18px] dark:text-[#F1F1F1] text-[#4A488E]">
                  {getUsername(timpEntry.user)}
                </p>
              </div>

              {/* Time and Date */}
              <div className="w-1/6 text-sm text-right flex flex-col gap-[5px]">
                <div className="flex gap-[3px] justify-end">
                  <span className="dark:bg-[#505084] bg-[#FFF] p-[4px] rounded-[3px] text-[#7271AB] dark:text-white">
                    {new Date(timpEntry.dataStart).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <span className="dark:bg-[#505084] bg-[#FFF] p-[4px] rounded-[3px] text-[#7271AB] dark:text-white">
                    {new Date(timpEntry.dataEnd).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                <div>
                  <span className="dark:bg-[#505084] bg-[#FFF] px-[7px] py-[2px] rounded-[3px] text-[#7271AB] dark:text-white">
                    {new Date(timpEntry.dataStart).toLocaleDateString("en-GB")}
                  </span>
                </div>
              </div>

              {/* Duration */}
              <div className="w-1/6 text-right flex justify-end">
                <p className="text-[18px] dark:text-[#F1F1F1] p-[10px] text-[#7271AB] dark:bg-[#505084] w-fit rounded-[3px] bg-[#FFF]">
                  {formatSecondsToTime(timpEntry.seconds)}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default OperationalReportsDetails;
