import React from "react";
import { useGetAllUsersQuery } from "../redux/api/authApi";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { useMarkNotificationAsReadMutation } from "../redux/api/notificationApi";
import { Tooltip } from "@mui/material";
import { motion } from "framer-motion"; // Import motion from Framer Motion

dayjs.extend(relativeTime);

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const NotificationCard = ({ notification, onRemove }) => {
  const [markAsRead] = useMarkNotificationAsReadMutation();

  const readNotification = async () => {
    onRemove(notification.notification._id); // Remove the notification optimistically
    await markAsRead(notification.notification._id); // Perform the API call
  };

  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useGetAllUsersQuery();

  const getAvatar = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user ? user?.avatar?.url : null; // Return null if the user doesn't exist
  };

  const notificationDate = new Date(notification.notification.timeStamp);
  const fillOpacity = isToday(notificationDate) ? "1" : "0.5";

  // Failback for missing user details (deleted user scenario)
  const senderExists = notification.sender && notification.sender._id;
  const userAvatar = senderExists ? getAvatar(notification.sender._id) : null;
  const senderName = senderExists ? notification.sender.name : "Deleted User";
  const message = notification?.notification?.message || "";

  return (
    <motion.div
      className="w-full bg-[#C5C5ED] dark:bg-[#4D4D77] flex items-center rounded-[12px] mb-[16px] flex-row justify-between"
      initial={{ opacity: 1, x: 0 }} // Initial state
      animate={{ opacity: 1, x: 0 }} // Animate to this state
      exit={{ opacity: 0, x: 300 }} // Exit state for swipe out
      transition={{ duration: 0.3 }} // Transition duration for the animation
    >
      <div className="flex flex-row">
        <div className="w-fit flex items-center py-[12px] px-[19px]">
          <div
            className="w-[63px] h-[63px] rounded-full"
            style={{
              backgroundImage: userAvatar
                ? `url(${userAvatar})`
                : `url('/images/default_avatar.png')`, // Failback avatar
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
        <div className="pt-[24px] pb-[19px]">
          <div className="flex gap-[10px]">
            <p className="text-[#6361AF] dark:text-white">
              {senderName} {message}
            </p>
          </div>
          <div className="flex">
            <p className="text-[#6361AF] dark:text-white">
              {dayjs(notification.notification.timeStamp).fromNow()}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-[50px] flex gap-[16px] mr-6">
        <Tooltip
          title="Mark as read"
          placement="bottom"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#383757",
                color: "white",
              },
            },
            arrow: {
              sx: {
                color: "#383757",
              },
            },
          }}
        >
          <svg
            onClick={readNotification}
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.33333 17C4.405 17 2.44083 17 1.22 15.7792C0 14.56 0 12.595 0 8.66671C0 4.73837 0 2.77421 1.22 1.55337C2.44167 0.333374 4.405 0.333374 8.33333 0.333374C12.2617 0.333374 14.2258 0.333374 15.4458 1.55337C16.6667 2.77504 16.6667 4.73837 16.6667 8.66671C16.6667 12.595 16.6667 14.5592 15.4458 15.7792C14.2267 17 12.2617 17 8.33333 17ZM10.395 5.68254C10.5234 5.78732 10.6049 5.9388 10.6216 6.10366C10.6383 6.26853 10.5889 6.43328 10.4842 6.56171L5.7225 12.395C5.66389 12.4668 5.59003 12.5247 5.50629 12.5645C5.42255 12.6042 5.33102 12.6248 5.23833 12.6248C5.14564 12.6248 5.05411 12.6042 4.97037 12.5645C4.88663 12.5247 4.81278 12.4668 4.75417 12.395L2.84917 10.0617C2.74862 9.93288 2.70254 9.76979 2.72078 9.60739C2.73903 9.445 2.82016 9.2962 2.94678 9.1929C3.07341 9.08959 3.23546 9.03999 3.39821 9.05472C3.56097 9.06945 3.71148 9.14735 3.8175 9.27171L5.23833 11.0117L9.51583 5.77171C9.62062 5.64333 9.77209 5.56182 9.93696 5.5451C10.1018 5.52838 10.2666 5.57782 10.395 5.68254ZM13.7283 5.68254C13.8567 5.78732 13.9382 5.9388 13.9549 6.10366C13.9717 6.26853 13.9222 6.43328 13.8175 6.56171L9.05583 12.395C8.9937 12.4714 8.91443 12.5319 8.82447 12.5718C8.7345 12.6117 8.63639 12.6298 8.53811 12.6246C8.43984 12.6194 8.34418 12.5911 8.25893 12.5419C8.17367 12.4928 8.10123 12.4242 8.0475 12.3417L7.81 11.9767C7.7386 11.8671 7.70337 11.738 7.70926 11.6073C7.71516 11.4767 7.76188 11.3512 7.84285 11.2485C7.92382 11.1458 8.03497 11.0711 8.16063 11.0349C8.28629 10.9987 8.42014 11.0028 8.54333 11.0467L12.8492 5.77171C12.954 5.64333 13.1054 5.56182 13.2703 5.5451C13.4352 5.52838 13.5999 5.57782 13.7283 5.68254Z"
              fillOpacity={fillOpacity}
              />
              </svg>
            </Tooltip>
          </div>
        </motion.div>
      );
    };
    
    export default NotificationCard;
    