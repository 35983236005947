import React, { useEffect, useState } from "react";
import { Typography, IconButton, Divider } from "@mui/material";
import {
  useGetPersonalGoalsByUserAndTimeRangeQuery,
  usePostPersonalGoalsMutation,
  useUpdatePersonalGoalsMutation,
  useDeletePersonalGoalsMutation,
} from "./../../../redux/api/appraisailsApi";
import { useGetUserTasksByDateIntervalQuery } from "../../../redux/api/tasksApi";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { redirect } from "react-router-dom";

export const YourActivity = ({
  user,
  canEdit,
  startDate,
  endDate,
  adminView,
}) => {
  const {
    data: apiResponse,
    error: tasksError,
    isLoading: tasksLoading,
  } = useGetUserTasksByDateIntervalQuery(
    {
      userId: user._id,
      startdate: startDate || "",
      enddate: endDate || "",
    },
    { skip: !startDate || !endDate }
  );

  const {
    data: personalGoalsData,
    error: goalsError,
    isLoading: goalsLoading,
    refetch,
  } = useGetPersonalGoalsByUserAndTimeRangeQuery(
    {
      userId: user._id,
      startDate: startDate || "",
      endDate: endDate || "",
    },
    { skip: !startDate || !endDate }
  );

  const [postPersonalGoals] = usePostPersonalGoalsMutation();
  const [updatePersonalGoals] = useUpdatePersonalGoalsMutation();
  const [deletePersonalGoals] = useDeletePersonalGoalsMutation();

  const [editMode, setEditMode] = useState(null);
  const [newGoal, setNewGoal] = useState("");
  const [isAddingNewGoal, setIsAddingNewGoal] = useState(false);
  const [personalGoals, setPersonalGoals] = useState([]);

  const [userActivity, setUserActivity] = useState({
    taskPerformance: "?",
    projects: "?",
    inProgress: "?",
    done: "?",
    personalGoals: [],
  });

  useEffect(() => {
    if (startDate && endDate) {
      if (apiResponse && Array.isArray(apiResponse.tickets)) {
        const tickets = apiResponse.tickets;
        const projects = tickets.length;
        const inProgress = tickets.filter(
          (ticket) => ticket.status === "In Progress"
        ).length;
        const done = tickets.filter(
          (ticket) => ticket.status === "Completed"
        ).length;
        const taskPerformance = projects > 0 ? (done / projects) * 100 : 0;

        setUserActivity((prevState) => ({
          ...prevState,
          taskPerformance,
          projects,
          inProgress,
          done,
        }));
      }
    } else {
      setUserActivity({
        taskPerformance: "?",
        projects: "?",
        inProgress: "?",
        done: "?",
        personalGoals: [],
      });
    }
  }, [apiResponse, startDate, endDate]);

  useEffect(() => {
    if (personalGoalsData && personalGoalsData.success) {
      setPersonalGoals(
        personalGoalsData.personalGoals.length > 0
          ? personalGoalsData.personalGoals[0].goals
          : []
      );
    } else {
      setPersonalGoals([]);
    }
  }, [personalGoalsData]);

  useEffect(() => {
    if (startDate && endDate) {
      refetch();
    }
  }, [startDate, endDate, refetch]);

  const handleCancel = () => {
    setEditMode(null);
    setNewGoal("");
    setIsAddingNewGoal(false);
  };

  const handleEdit = (index) => {
    setEditMode(index);
    setNewGoal(personalGoals[index].goal);
    setIsAddingNewGoal(false);
  };

  const handleSave = async () => {
    const updatedGoals = [...personalGoals];
    if (editMode !== null) {
      updatedGoals[editMode] = { goal: newGoal };
    } else {
      updatedGoals.push({ goal: newGoal });
    }

    try {
      if (
        personalGoalsData &&
        personalGoalsData.success &&
        personalGoalsData.personalGoals.length > 0
      ) {
        await updatePersonalGoals({
          id: personalGoalsData.personalGoals[0]._id,
          userId: user._id,
          startDate,
          endDate,
          goals: updatedGoals,
        });
      } else {
        await postPersonalGoals({
          userId: user._id,
          startDate,
          endDate,
          goals: updatedGoals,
        });
      }

      setPersonalGoals(updatedGoals);
      setEditMode(null);
      setNewGoal("");
      setIsAddingNewGoal(false);
      refetch();
    } catch (error) {
      console.error("Failed to save personal goals", error);
    }
  };

  const handleInputChange = (value) => {
    setNewGoal(value);
  };

  const addGoalInput = () => {
    setEditMode(null);
    setNewGoal("");
    setIsAddingNewGoal(true);
  };

  const handleDelete = async (index) => {
    const updatedGoals = personalGoals.filter((_, i) => i !== index);
    try {
      if (
        personalGoalsData &&
        personalGoalsData.success &&
        personalGoalsData.personalGoals.length > 0
      ) {
        await updatePersonalGoals({
          id: personalGoalsData.personalGoals[0]._id,
          userId: user._id,
          startDate,
          endDate,
          goals: updatedGoals,
        });
      }
      setPersonalGoals(updatedGoals);
      refetch();
    } catch (error) {
      console.error("Failed to delete personal goal", error);
    }
  };

  if (tasksLoading || goalsLoading) return <div>Loading...</div>;
  if (tasksError || goalsError)
    return <div>Error: {tasksError?.message || goalsError?.message}</div>;

  const taskClick = () => {
    redirect("/taskslist/My-Tasks");
  };

  return (
    <div className={`mt-0 mb-12 ${adminView ? "bg-[#4A488E] text-white" : ""}`}>
      <Typography
        className={`text-xl font-medium mb-6 dark:text-[#EDEDFB] ${
          adminView ? "text-white" : "text-[#4A488E]"
        }`}
      >
        Your activity
      </Typography>
      <div className="flex space-x-4">
        <div
          className={`p-6 rounded-lg flex-1 flex flex-col relative  dark:bg-[#484878] ${
            adminView ? "bg-[#4A488E] text-white" : "bg-[#D0D0FF]"
          } bg-section`}
          style={{ maxWidth: "400px" }}
        >
          <div className="flex-grow">
            <div className="flex items-center justify-between mb-4 ">
              <Typography
                className={`text-lg font-medium  dark:text-[#EDEDFB] ${
                  adminView ? "text-white" : "text-[#5C59B2]"
                }`}
              >
                Task performance
              </Typography>
            </div>
            <div className="flex items-center space-x-36 mb-2 ">
            <Typography
  className={`text-6xl font-bold dark:text-[#EDEDFB] ${
    adminView ? "text-white" : "text-[#5C59B2]"
  }`}
>
  {typeof userActivity?.taskPerformance === "number"
    ? userActivity.taskPerformance.toFixed(2)
    : "0.00"}
  %
</Typography>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="47"
                height="47"
                viewBox="0 0 47 47"
                fill="none"
              >
                <path
                  d="M41.1253 39.166H39.167V9.79102C39.167 9.27163 38.9607 8.77352 38.5934 8.40627C38.2262 8.03901 37.728 7.83268 37.2087 7.83268C36.6893 7.83268 36.1912 8.03901 35.8239 8.40627C35.4566 8.77352 35.2503 9.27163 35.2503 9.79102V39.166H31.3337V17.6243C31.3337 17.105 31.1273 16.6069 30.7601 16.2396C30.3928 15.8723 29.8947 15.666 29.3753 15.666C28.8559 15.666 28.3578 15.8723 27.9906 16.2396C27.6233 16.6069 27.417 17.105 27.417 17.6243V39.166H23.5003V25.4577C23.5003 24.9383 23.294 24.4402 22.9267 24.0729C22.5595 23.7057 22.0614 23.4993 21.542 23.4993C21.0226 23.4993 20.5245 23.7057 20.1572 24.0729C19.79 24.4402 19.5837 24.9383 19.5837 25.4577V39.166H15.667V33.291C15.667 32.7716 15.4607 32.2735 15.0934 31.9063C14.7262 31.539 14.228 31.3327 13.7087 31.3327C13.1893 31.3327 12.6912 31.539 12.3239 31.9063C11.9566 32.2735 11.7503 32.7716 11.7503 33.291V39.166H7.83366V5.87435C7.83366 5.35497 7.62734 4.85686 7.26008 4.4896C6.89282 4.12234 6.39471 3.91602 5.87533 3.91602C5.35594 3.91602 4.85783 4.12234 4.49057 4.4896C4.12332 4.85686 3.91699 5.35497 3.91699 5.87435V41.1243C3.91699 41.6437 4.12332 42.1418 4.49057 42.5091C4.85783 42.8764 5.35594 43.0827 5.87533 43.0827H41.1253C41.6447 43.0827 42.1428 42.8764 42.5101 42.5091C42.8773 42.1418 43.0837 41.6437 43.0837 41.1243C43.0837 40.605 42.8773 40.1069 42.5101 39.7396C42.1428 39.3723 41.6447 39.166 41.1253 39.166Z"
                  fill={adminView ? "#FFFFFF" : "#5C59B2"}
                  className={`fill-current text-[#5C59B2] dark:text-[#EDEDFB]`}
                />
              </svg>
            </div>
            <Typography
              className={`text-base font-normal mb-6 dark:text-[#EDEDFB] ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              {userActivity.taskPerformance}% of your tasks for this period have
              been completed.
            </Typography>
          </div>
          {/* <div
            className={`absolute bottom-0 left-0 right-0 rounded-b-lg text-center py-4 cursor-pointer ${
              adminView ? "bg-white text-[#4A488E]" : "bg-[#5C59B2] text-white"
            }`}
            onClick={taskClick}
          >
            See all tasks
          </div> */}
        </div>
        <div className="flex flex-col space-y-4">
          <div
            className={`p-6 rounded-lg text-center flex-1 bg-[#484878] dark:bg-[#484878]  ${
              adminView ? "bg-[#4A488E] text-white" : "bg-[#D0D0FF]"
            }`}
          >
            <Typography
              className={`text-4xl font-bold mb-2 dark:text-[#EDEDFB] ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              {userActivity.projects}
            </Typography>
            <Typography
              className={`text-base font-normal dark:text-[#EDEDFB] dark:bg-[#484878]  ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              Projects
            </Typography>
          </div>
          <div
            className={`p-6 rounded-lg text-center flex-1 dark:bg-[#484878] ${
              adminView ? "bg-[#4A488E] text-white" : "bg-[#D0D0FF]"
            }`}
          >
            <Typography
              className={`text-4xl font-bold mb-2 dark:text-[#EDEDFB] ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              {userActivity.inProgress}
            </Typography>
            <Typography
              className={`text-base font-normal dark:text-[#EDEDFB] ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              In progress
            </Typography>
          </div>
          <div
            className={`p-6 rounded-lg text-center flex-1 dark:bg-[#484878] ${
              adminView ? "bg-[#4A488E] text-white" : "bg-[#D0D0FF]"
            }`}
          >
            <Typography
              className={`text-4xl font-bold mb-2 dark:text-[#EDEDFB] ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              {userActivity.done}
            </Typography>
            <Typography
              className={`text-base font-normal dark:text-[#EDEDFB] ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              Done
            </Typography>
          </div>
        </div>
        <div
          className={`p-6 rounded-lg flex-1 dark:bg-[#484878] dark:text-[#EDEDFB] ${
            adminView ? "bg-[#4A488E] text-white" : "bg-[#D0D0FF]"
          }`}
        >
          <div className="flex justify-between items-start mb-4">
            <Typography
              className={`text-lg font-medium dark:text-[#EDEDFB] ${
                adminView ? "text-white" : "text-[#5C59B2]"
              }`}
            >
              Personal goals
            </Typography>
            {canEdit && (
              <IconButton
                onClick={addGoalInput}
                disabled={editMode !== null || isAddingNewGoal}
              >
                <AddIcon
                  className={`${
                    adminView
                      ? "text-white"
                      : "text-[#5C59B2] dark:text-[#FBFBFD]"
                  } ${
                    editMode !== null || isAddingNewGoal ? "text-gray-400" : ""
                  }`}
                />
              </IconButton>
            )}
          </div>
          <ul className="list-disc pl-4">
            {personalGoals.length > 0 ? (
              personalGoals.map((goal, index) => (
                <React.Fragment key={index}>
                  <li className="flex justify-between items-center mb-2">
                    {editMode === index ? (
                      <textarea
                        value={newGoal}
                        onChange={(e) => handleInputChange(e.target.value)}
                        className="w-full px-2 py-1 rounded-md border text-gray-800 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent bg-white"
                        rows="2"
                      />
                    ) : (
                      <Typography
                        className={`text-base font-normal dark:text-[#EDEDFB] ${
                          adminView ? "text-white" : "text-[#5C59B2]"
                        }`}
                      >
                        {goal.goal || "No answer yet"}
                      </Typography>
                    )}
                    <div>
                      {editMode === index ? (
                        <>
                          <IconButton onClick={handleSave}>
                            <SaveIcon
                              className={`${
                                adminView
                                  ? "text-white"
                                  : "text-[#5C59B2] dark:text-[#FBFBFD]"
                              }`}
                            />
                          </IconButton>
                          <IconButton onClick={handleCancel}>
                            <CancelIcon
                              className={`${
                                adminView
                                  ? "text-white"
                                  : "text-[#5C59B2] dark:text-[#FBFBFD]"
                              }`}
                            />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          {canEdit && (
                            <>
                              <IconButton onClick={() => handleEdit(index)}>
                                <EditIcon
                                  className={`${
                                    adminView
                                      ? "text-white"
                                      : "text-[#5C59B2] dark:text-[#FBFBFD]"
                                  }`}
                                />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(index)}>
                                <DeleteIcon
                                  className={`${
                                    adminView
                                      ? "text-white"
                                      : "text-[#5C59B2] dark:text-[#FBFBFD]"
                                  }`}
                                />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                  <Divider sx={{ backgroundColor: "darkPurple" }} />
                </React.Fragment>
              ))
            ) : (
              <li
                className={`text-base font-normal mb-2 dark:text-[#EDEDFB] ${
                  adminView ? "text-white" : "text-[#5C59B2]"
                }`}
              >
                No personal goals set
              </li>
            )}
          </ul>
          {isAddingNewGoal && (
            <div className="flex justify-between items-center mb-2">
              <textarea
                value={newGoal}
                onChange={(e) => handleInputChange(e.target.value)}
                className="w-full px-2 py-1 rounded-md border text-gray-800 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent bg-white"
                rows="2"
                placeholder="Add a new goal"
              />
              <div>
                <IconButton onClick={handleSave}>
                  <SaveIcon
                    className={`${adminView ? "text-red" : "text-[#5C59B2]"}`}
                  />
                </IconButton>
                <IconButton onClick={handleCancel}>
                  <CancelIcon
                    className={`${adminView ? "text-white" : "text-[#5C59B2]"}`}
                  />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default YourActivity;
