import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../../config";
import Cookies from "js-cookie"; // Import js-cookie

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const dealsApi = createApi({
  reducerPath: "dealsApi",
  baseQuery,
  endpoints: (builder) => ({
    getDeals: builder.query({
      query() {
        return {
          url: "/deals",
          method: "GET",
        };
      },
    }),
    getDealDetails: builder.query({
      query(dealId) {
        return {
          url: `/deals/${dealId}`,
          method: "GET",
        };
      },
    }),
    postDeal: builder.mutation({
      query(deal) {
        return {
          url: "/deals",
          method: "POST",
          body: deal,
        };
      },
    }),
    updateDeal: builder.mutation({
      query(deal) {
        return {
          url: `/deals/${deal._id}`,
          method: "PUT",
          body: deal,
        };
      },
    }),
    deleteDeal: builder.mutation({
      query(dealId) {
        return {
          url: `/deals/${dealId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetDealDetailsQuery,
  useGetDealsQuery,
  usePostDealMutation,
  useDeleteDealMutation,
  useUpdateDealMutation,
} = dealsApi;
