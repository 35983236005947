import React, { useState } from "react";
import {
  useGetCategoriesQuery,
  usePostCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} from "../../../redux/api/inventory/category";
import NotificationComponent from "../../NotificationComponent";
import SearchBar from "../../utils/SearchBar";
import FuseAccordion from "../UI/acordion";
import CategoryModal from "./NewCategoryModal";
import { notify } from "../../utils/notificationManager";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";

export const ProductCategory = () => {
  const { data, error, isLoading, refetch } = useGetCategoriesQuery(); // Fetch categories using Redux query
  const [postCategory] = usePostCategoryMutation(); // Mutation for posting a new category
  const [updateCategory] = useUpdateCategoryMutation(); // Mutation for updating a category
  const [deleteCategory] = useDeleteCategoryMutation(); // Mutation for deleting a category
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null); // For editing categories

  // Ensure categories is an array by accessing the 'categories' property of the response
  const categories = data?.categories || [];

  // Handle creation of a new category
  const handleCreateCategory = async (newCategory) => {
    try {
      await postCategory(newCategory).unwrap(); // Add new category via the API
      setOpenDialogNew(false); // Close the modal after successful creation
      refetch(); // Refetch categories after successful creation
      notify("Category created successfully", 0, null, null, true); // Notify success
    } catch (error) {
      console.error("Failed to create a new category:", error);
      notify("Failed to create a category", 2, null, null, true); // Notify error
    }
  };

  // Handle saving (updating) a category
  const handleSaveCategory = async (updatedCategory) => {
    try {
      // Ensure both categoryId and categoryData are passed correctly
      await updateCategory({
        categoryId: updatedCategory._id, // Use _id here instead of id
        categoryData: {
          name: updatedCategory.name,
          description: updatedCategory.description,
        },
      }).unwrap(); // Update the category via the API

      setEditingCategory(null); // Close the modal after successful update
      refetch(); // Refetch categories after successful update
      notify("Category updated successfully", 0, null, null, true); // Notify success
    } catch (error) {
      console.error("Failed to update the category:", error);
      notify("Failed to update the category", 2, null, null, true); // Notify error
    }
  };

  // Handle deletion of a category with confirmation
  const handleDeleteCategory = (categoryId) => {
    notify(
      "Are you sure you want to delete this category?",
      1,
      async () => {
        try {
          await deleteCategory(categoryId).unwrap(); // Delete the category via the API
          refetch(); // Refetch categories after successful deletion
          notify("Category deleted successfully", 0, null, null, true); // Notify success
        } catch (error) {
          console.error("Failed to delete the category:", error);
          notify("Failed to delete the category", 2, null, null, true); // Notify error
        }
      },
      () => {
        notify("Category deletion canceled", 1, null, null, true); // Notify canceled
      }
    );
  };

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  pb-[20px] dark:bg-[#2B2A49]">
      <div className="flex justify-between items-center">
        <Breadcrumbs
          className="text-[#4A488E] dark:text-[#EDEDFB]"
          aria-label="breadcrumb"
        >
          <Link className="text-[#4A488E] dark:text-[#EDEDFB]" to="/dashboard">
            Fuse Digital
          </Link>
          <Link
            className="text-[#4A488E] dark:text-[#EDEDFB]"
            to={`/inventory/product-categories`}
          >
            Inventory
          </Link>
          <Link
            className="text-[#4A488E] dark:text-[#EDEDFB]"
            to={`/inventory/product-categories`}
          >
            <b> Product Categories</b>
          </Link>
        </Breadcrumbs>
        <NotificationComponent />
      </div>

      <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal mt-[40px] mb-[53px]">
        Product Categories
      </div>

      <div className="flex items-center justify-between mb-4">
        <div className="relative">
          <SearchBar data={categories} onSearchResults={console.log} />
        </div>
        <div className="h-[34px] flex items-center gap-[15px]">
          <div
            className="w-fit h-[38px] px-[15px] py-2.5 bg-violet-100 rounded-md border border-indigo-500 justify-center items-center gap-2.5 flex cursor-pointer"
            onClick={() => {
              setEditingCategory(null); // Ensure old values are cleared for new category
              setOpenDialogNew(true);
            }}
          >
            <div className="text-[#4A488E] text-[15px] font-medium">
              + New category
            </div>
          </div>
        </div>
      </div>

      {/* Dynamically render categories */}
      {categories.length > 0 ? (
        categories.map((category, index) => (
          <FuseAccordion
            key={index}
            title={category.name}
            content={category.description}
            onEdit={() => setEditingCategory(category)} // Set category for editing
            onDelete={() => handleDeleteCategory(category._id)} // Delete category on click
          />
        ))
      ) : (
        <div className="text-[#4A488E] px-2 dark:text-[#EDEDFB] text-[18px] font-normal mt-4">
          No categories available.
        </div>
      )}

      {/* Modal for adding a new category */}
      <CategoryModal
        isOpen={openDialogNew || !!editingCategory} // Show modal for both creating and editing
        onClose={() => {
          setEditingCategory(null);
          setOpenDialogNew(false);
        }}
        category={editingCategory} // Ensure this is null when creating
        onSave={editingCategory ? handleSaveCategory : handleCreateCategory} // Different save logic for create and edit
      />
    </div>
  );
};

export default ProductCategory;
