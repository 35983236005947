import React, { useState, useEffect, useRef } from "react";
import { useGetAllUsersQuery } from "../../redux/api/authApi";

const DropdownOperationalReportsTeam = ({
  setUsers,
  users,
  toggleDropdown,
  isOpen,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]); // Track selected users
  const { data: usersData } = useGetAllUsersQuery(); // Fetch users data from API

  // Create a ref for the dropdown container
  const dropdownRef = useRef(null);

  const handleOptionClick = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      const isSelected = prevSelectedUsers.includes(user._id);
      if (isSelected) {
        // Remove user from the selection
        return prevSelectedUsers.filter((id) => id !== user._id);
      } else {
        // Add user to the selection
        return [...prevSelectedUsers, user._id];
      }
    });
  };

  // Call the setUsers function in the parent component to update the state
  useEffect(() => {
    setUsers(selectedUsers);
  }, [selectedUsers, setUsers]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdownRef
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleDropdown(); // Close dropdown
      }
    };

    if (isOpen) {
      // Add event listener when dropdown is open
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="justify-center w-full flex items-center py-[10px] px-[15px] border-r border-[#7674C2] dark:border-[#D4D4EB] gap-[10px] dark:text-[#D4D4EB]"
        onClick={toggleDropdown}
      >
        {selectedUsers.length > 0
          ? `${selectedUsers.length} User${selectedUsers.length > 1 ? "s" : ""}`
          : "Team"}
        <span>
          <div className="text-[#7271AB] dark:text-[#D4D4EB]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="currentColor"
            >
              <path
                d="M7.4997 9.81926L2.04532 4.51176C2.00137 4.46806 1.94918 4.43351 1.89179 4.41012C1.83439 4.38672 1.77293 4.37494 1.71095 4.37545C1.64897 4.37597 1.58771 4.38877 1.53071 4.41312C1.47371 4.43746 1.42211 4.47287 1.37889 4.51729C1.33566 4.56172 1.30168 4.61427 1.2789 4.67191C1.25612 4.72955 1.245 4.79114 1.24618 4.85311C1.24736 4.91508 1.26082 4.9762 1.28577 5.03293C1.31073 5.08967 1.34669 5.14089 1.39157 5.18363L7.17282 10.8086C7.26033 10.8938 7.37761 10.9414 7.4997 10.9414C7.62179 10.9414 7.73906 10.8938 7.82657 10.8086L13.6078 5.18363C13.6527 5.14089 13.6887 5.08967 13.7136 5.03293C13.7386 4.9762 13.752 4.91508 13.7532 4.85311C13.7544 4.79114 13.7433 4.72955 13.7205 4.67191C13.6977 4.61427 13.6637 4.56171 13.6205 4.51729C13.5773 4.47287 13.5257 4.43746 13.4687 4.41312C13.4117 4.38877 13.3504 4.37597 13.2884 4.37545C13.2265 4.37494 13.165 4.38672 13.1076 4.41012C13.0502 4.43351 12.998 4.46806 12.9541 4.51176L7.4997 9.81926Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </span>
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-[200px] rounded-md shadow-lg bg-[#E9E9FF] z-50 dark:bg-[#3A3A68]">
          <ul className="py-1">
            {usersData?.users?.map((user) => (
              <li
                key={user._id} // Use user._id as the key
                className="px-4 py-2 cursor-pointer hover:bg-[#404074] flex items-center"
              >
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user._id)}
                  onChange={() => handleOptionClick(user)}
                  className="mr-2"
                />
                <span className="text-black dark:text-white">{user.name}</span>{" "}
                {/* Display the user's name */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownOperationalReportsTeam;
