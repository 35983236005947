import React, { useState, useEffect } from "react";
import { Breadcrumbs, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationComponent from "../NotificationComponent";
import Datepicker from "react-tailwindcss-datepicker";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import { useGetExpensesQuery } from "../../redux/api/finance/expenses";
import { useGetRevenuesQuery } from "../../redux/api/finance/revenue";
import { useCreateNotificationMutation } from "../../redux/api/notificationApi";
import BarChartDashboard from "./BarChart";
import dayjs from "dayjs";
import FuseSpecialDropdown from "./ui/specialDropdown";
import { useUpdateExpenseMutation } from "../../redux/api/finance/expenses";
import { useUpdateRevenueMutation } from "../../redux/api/finance/revenue";

const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#7271AB",
  },
});
const useTheme = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "theme") {
        setTheme(event.newValue || "light");
      }
    };

    // Initialize theme from local storage
    const storedTheme = localStorage.getItem("theme") || "light";
    setTheme(storedTheme);

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return theme;
};
const AntTab = styled((props) => {
  const theme = useTheme(); // get the current theme
  return <Tab disableRipple {...props} />;
})(({ theme }) => {
  const currentTheme = useTheme(); // get the current theme

  return {
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: currentTheme === "dark" ? "#D4D4EB" : "#4A488E", // adjust colors based on theme
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: currentTheme === "dark" ? "#00FFFFFF" : "#4A488E", // adjust hover color
      opacity: 1, // remove grey tint by setting opacity to 1
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      color: currentTheme === "dark" ? "#D4D4EB" : "#4A488E", // adjust selected color
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: currentTheme === "dark" ? "#444" : "#4A488E", // adjust focus background
    },
  };
});

// Function to format total seconds into a human-readable time string
const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours}:${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
};

// Function to get start and end of current month
const getCurrentMonthDateRange = () => {
  const startDate = dayjs().startOf("month").format("YYYY-MM-DD");
  const endDate = dayjs().endOf("month").format("YYYY-MM-DD");
  return { startDate, endDate };
};

const FinanceDashboard = () => {
  const [postNotification] = useCreateNotificationMutation();
  const [updateExpense] = useUpdateExpenseMutation();
  const [updateRevenue] = useUpdateRevenueMutation();
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [tabValue, setTabValue] = useState(0);
  const [activities, setActivities] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Loading states for revenues and expenses
  const [loadingRevenues, setLoadingRevenues] = useState([]);
  const [loadingExpenses, setLoadingExpenses] = useState([]);

  // Function to close the dropdown
  const closeDropdownOnScroll = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      // Close the dropdown on scroll
      closeDropdownOnScroll();
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the scroll event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures it only runs once when the component mounts

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedDate, setSelectedDate] = useState(null);
  const [view, setView] = useState(1); // Toggle between views
  useEffect(() => {
    setSelectedDate(getCurrentMonthDateRange());
  }, []);
  // Calculate the start and end dates for each shortcut
  const today = new Date();

  const customShortcuts = {
    Q1: {
      text: "Q1",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 2, 31),
      },
    },
    Q2: {
      text: "Q2",
      period: {
        start: new Date(today.getFullYear(), 3, 1),
        end: new Date(today.getFullYear(), 5, 30),
      },
    },
    Q3: {
      text: "Q3",
      period: {
        start: new Date(today.getFullYear(), 6, 1),
        end: new Date(today.getFullYear(), 8, 30),
      },
    },
    Q4: {
      text: "Q4",
      period: {
        start: new Date(today.getFullYear(), 9, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
    H1: {
      text: "H1",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 5, 30),
      },
    },
    H2: {
      text: "H2",
      period: {
        start: new Date(today.getFullYear(), 6, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
    FY: {
      text: "FY",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
  };

  // Configuration object
  const configs = {
    shortcuts: customShortcuts,
    footer: {
      cancel: "Cancel",
      apply: "Apply",
    },
  };

  const {
    data: expensesData,
    refetch: refetchExpenses, // Renamed to refetchExpenses for clarity
  } = useGetExpensesQuery({
    page: 1,
    limit: 10000,
    sort: "name",
    order: "asc",
    search: "",
    status: "",
  });

  const {
    data: revenueData,
    refetch: refetchRevenue, // Renamed to refetchRevenue for clarity
  } = useGetRevenuesQuery({
    page: 1,
    limit: 10000,
    sort: "name",
    order: "asc",
    search: "",
    status: "",
  });

  const filteredExpenses =
    expensesData?.expenses.filter((expense) => {
      const expenseStartDate = dayjs(expense?.startDate);
      const expenseEndDate = dayjs(expense?.endDate);
      const filterStartDate = dayjs(selectedDate?.startDate);
      const filterEndDate = dayjs(selectedDate?.endDate);

      return (
        (expenseStartDate.isSameOrAfter(filterStartDate) &&
          expenseStartDate.isBefore(filterEndDate)) ||
        (expenseEndDate.isSameOrBefore(filterEndDate) &&
          expenseEndDate.isAfter(filterStartDate)) ||
        (expenseStartDate.isBefore(filterStartDate) &&
          expenseEndDate.isAfter(filterEndDate))
      );
    }) || [];

  const filteredRevenue =
    revenueData?.revenues.filter((revenue) => {
      const revenueStartDate = dayjs(revenue?.startDate);
      const revenueEndDate = dayjs(revenue?.endDate);
      const filterStartDate = dayjs(selectedDate?.startDate);
      const filterEndDate = dayjs(selectedDate?.endDate);

      return (
        (revenueStartDate.isSameOrAfter(filterStartDate) &&
          revenueStartDate.isBefore(filterEndDate)) ||
        (revenueEndDate.isSameOrBefore(filterEndDate) &&
          revenueEndDate.isAfter(filterStartDate)) ||
        (revenueStartDate.isBefore(filterStartDate) &&
          revenueEndDate.isAfter(filterEndDate))
      );
    }) || [];

  const finalRevenue = filteredRevenue?.filter((e) => e.status !== "Canceled");
  const finalExpenses = filteredExpenses?.filter(
    (e) => e.status !== "Canceled"
  );
  const getTotalRevenueValue = () => {
    // Calculate the total value
    const total = finalRevenue.reduce(
      (total, revenue) => total + revenue.value,
      0
    );

    // Format the total with thousands separators and two decimal places
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);
  };
  const getTotalExpensesValue = () => {
    // Calculate the total value
    const total = finalExpenses.reduce(
      (total, expense) => total + expense.value,
      0
    );

    // Format the total with thousands separators and two decimal places
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);
  };

  // Function to convert formatted string to number
  const parseFormattedNumber = (formattedNumber) => {
    return parseFloat(formattedNumber.replace(/,/g, ""));
  };

  // Calculate the difference between total revenue and total expenses
  const calculateProfit = () => {
    // Get formatted values
    const totalRevenueFormatted = getTotalRevenueValue();
    const totalExpensesFormatted = getTotalExpensesValue();

    // Parse formatted values to numeric
    const totalRevenue = parseFormattedNumber(totalRevenueFormatted);
    const totalExpenses = parseFormattedNumber(totalExpensesFormatted);

    // Calculate profit
    const profit = totalRevenue - totalExpenses;

    // Format the profit for display
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(profit);
  };

  const updateTheme = () => {
    const newTheme = localStorage.getItem("theme") || "light";
    setTheme(newTheme);
  };

  useEffect(() => {
    updateTheme();
    const handleStorageChange = (event) => {
      if (event.key === "theme") {
        updateTheme();
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleDocumentClick = () => {
      updateTheme();
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // Function to handle pagination
  const paginate = (pageNumber) => {
    if (pageNumber >= 1) {
      setCurrentPage(pageNumber);
    }
  };

  // Handle view change
  const handleViewChange = (direction) => {
    if (direction === "next") {
      setView(2);
    } else if (direction === "prev") {
      setView(1);
    }
  };

  const allActivities = [
    ...(filteredRevenue?.map((rev) => ({ ...rev, revenueId: rev._id })) || []),
    ...(filteredExpenses?.map((exp) => ({ ...exp, expenseId: exp._id })) || []),
  ];

  useEffect(() => {
    if (tabValue === 0) {
      setActivities(allActivities);
    } else if (tabValue === 1) {
      setActivities(expensesData?.expenses);
    } else if (tabValue === 2) {
      setActivities(revenueData?.revenues);
    }
  }, [tabValue, expensesData, revenueData]);

  const handleStatusChange = async (newStatus, id) => {
    const isRevenue = revenueData?.revenues.find((rev) => rev._id === id);
    const isExpense = expensesData?.expenses.find((exp) => exp._id === id);

    if (isRevenue) {
      setLoadingRevenues((prev) => [...prev, id]);
      const updatedRevenue = { ...isRevenue, status: newStatus };
      await updateRevenue({ id, updates: updatedRevenue }).unwrap();
      await refetchRevenue();
      // Remove from loadingRevenues after a slight delay
      setTimeout(() => {
        setLoadingRevenues((prev) => prev.filter((loadingId) => loadingId !== id));
      }, 500);
    } else if (isExpense) {
      setLoadingExpenses((prev) => [...prev, id]);
      const updatedExpense = { ...isExpense, status: newStatus };
      await updateExpense({ id, updates: updatedExpense }).unwrap();
      await refetchExpenses();
      // Remove from loadingExpenses after a slight delay
      setTimeout(() => {
        setLoadingExpenses((prev) => prev.filter((loadingId) => loadingId !== id));
      }, 500);
    }
  };

  // Loading spinner component using Tailwind CSS
  const LoadingSpinner = () => {
    return (
      <div className="w-full min-h-screen flex justify-center items-center mt-0">
        <div className="w-20 h-20 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  };

  if (!revenueData && !expensesData) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full h-screen p-[30px] dark:bg-[#2B2A49] overflow-scroll scrolling-touch">
      {/* Breadcrumbs and Header */}
      <div className="flex justify-between pb-[25px]">
        <ul className="flex items-center">
          <Breadcrumbs
            aria-label="breadcrumb"
            className="w-[380px] text-[#7674C2] dark:text-[#EDEDFB]"
          >
            <Link
              underline="hover"
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              to="/dashboard"
            >
              Fuse Digital
            </Link>
            <Link
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              underline="hover"
              to={`/finance/finance-dashboard`}
            >
              Finance / <b> Dashboard</b>
            </Link>
          </Breadcrumbs>
        </ul>
        <NotificationComponent />
      </div>

      {/* Title */}
      <div className="w-full pb-[45px]">
        <h1 className="dark:text-[#EDEDFB] text-[#4A488E] text-[30px]">
          Finance Dashboard
        </h1>
      </div>

      {/* Dropdowns */}
      <div className="w-full flex justify-end pb-[35px]">
        <div className="w-[35%] flex items-center gap-[24px]">
          <Datepicker
            showShortcuts={true}
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            asSingle={false}
            displayFormat={"YYYY-MM-DD"}
            className="h-[55px] bg-[#E8E8FF] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg"
            configs={configs} // Pass the config object
          />
          <div className="flex gap-[24px]">
            <span
              onClick={() => handleViewChange("prev")}
              className={`cursor-pointer ${
                view === 1 ? "text-gray-500" : "text-white"
              }`}
            >
              <ArrowLeftIcon />
            </span>
            <span
              onClick={() => handleViewChange("next")}
              className={`cursor-pointer ${
                view === 2 ? "text-gray-500" : "text-white"
              }`}
            >
              <ArrowRightIcon />
            </span>
          </div>
        </div>
      </div>

      {/* First View with Chart */}
      {view === 1 ? (
        <>
          <div className="mb-[12px]">
            <div className="w-full flex flex-col gap-5 py-[33px] px-[28px] dark:bg-[#3A3A68] bg-[#E7E7F9] rounded-[21px]">
              <div className="w-full  flex flex-row gap-[26px]">
                <div className="w-[32%] dark:bg-[#4D4D77] bg-[#FAFAFE] py-[59px] px-[30px]  flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
                  <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                    Total Expenses
                  </p>
                  <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px]">
                    {getTotalExpensesValue()} RON{" "}
                  </p>
                </div>
                <div className="w-[32%] dark:bg-[#4D4D77]  bg-[#FAFAFE] py-[59px]  px-[30px]  flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
                  <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                    Total Revenue
                  </p>
                  <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px] ">
                    {getTotalRevenueValue()} RON{" "}
                  </p>
                </div>
                <div className="w-[32%] dark:bg-[#4D4D77]  bg-[#FAFAFE]  py-[59px] px-[30px] flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
                  <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                    Profit
                  </p>
                  <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px]">
                    {calculateProfit()} RON
                  </p>
                </div>
              </div>
              <div className="dark:text-[#d4d4eb] text-[#4A488E] text-[23px] font-medium ">
                Statistics
              </div>

              <div className="w-full dark:bg-[#4D4D77] bg-[#FAFAFE] py-[59px] px-[30px] rounded-[21px]">
                {revenueData && expensesData ? (
                  <BarChartDashboard
                    revenueData={filteredRevenue}
                    expensesData={filteredExpenses}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* Mock Bar Chart */}
          </div>
        </>
      ) : (
        <>
          {/* Second View */}
          <div className="w-full dark:bg-[#3A3A68] bg-[#e7e7f9] rounded-[21px] p-8">
            <AntTabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <AntTab label="All activities" />
              <AntTab label="Expenses" />
              <AntTab label="Revenue" />
            </AntTabs>
            {/* <h2 className="dark:text-white text-[#4A488E] font-semibold text-lg pb-[21px] border-b border-[#CAC9F1] dark:border-[#e2e2ff52]">
              All activities
            </h2> */}
            <table className="w-full text-center text-sm  text-white mt-6 ">
              <thead className="dark:bg-[#515185] bg-[#fafafe] text-[#AFAFDD] ">
                <tr className="">
                  <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF] rounded-tl-3xl">
                    Transactions
                  </th>
                  <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                    Value
                  </th>
                  <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                    Date
                  </th>
                  <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                    Due Date
                  </th>
                  <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF] rounded-tr-3xl">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="dark:bg-[#515185] bg-[#fafafe]">
                {activities.map((item, index) => (
                  <tr
                    key={index}
                    className="border-t dark:border-[#e2e2ff52] border-[#CAC9F1] py-[25px]"
                  >
                    <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                      {item?.name}
                    </td>
                    <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                      {item?.value}
                    </td>
                    <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                      {dayjs(item?.startDate).format("DD.MM.YYYY")}
                    </td>
                    <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                      {dayjs(item?.endDate).format("DD.MM.YYYY")}
                    </td>
                    <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                      <div className="flex flex-row items-center justify-center gap-3">
                        {item?.revenueId ? (
                          <FuseSpecialDropdown
                            currentStatus={item?.status}
                            onChange={(newStatus) =>
                              handleStatusChange(newStatus, item?._id)
                            }
                            loading={loadingRevenues.includes(item._id)}
                            type="revenue"
                          />
                        ) : (
                          <FuseSpecialDropdown
                            currentStatus={item?.status}
                            onChange={(newStatus) =>
                              handleStatusChange(newStatus, item?._id)
                            }
                            loading={loadingExpenses.includes(item._id)}
                            type="expense"
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default FinanceDashboard;
