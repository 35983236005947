import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../config";
import Cookies from "js-cookie"; // Import js-cookie

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const providerApi = createApi({
  reducerPath: "providerApi",
  baseQuery,
  endpoints: (builder) => ({
    getProviders: builder.query({
      query() {
        return {
          url: "/providers",
          method: "GET",
        };
      },
    }),
    getProviderDetails: builder.query({
      query(provider) {
        return {
          url: `/providers/${provider.id}`,
          method: "GET",
        };
      },
    }),
    postProvider: builder.mutation({
      query(provider) {
        return {
          url: "/providers",
          method: "POST",
          body: provider,
        };
      },
    }),
    updateProvider: builder.mutation({
      query(provider) {
        return {
          url: `/providers/${provider.id}`,
          method: "PUT",
          body: provider,
        };
      },
    }),
    deleteProvider: builder.mutation({
      query(providerId) {
        return {
          url: `/providers/${providerId}`,
          method: "DELETE",
        };
      },
    }),
    uploadContract: builder.mutation({
      query: (body) => ({
        url: `/providers/upload_contract`,
        method: "POST", // Ensure method matches the route definition
        body,
      }),
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useGetProviderDetailsQuery,
  usePostProviderMutation,
  useUpdateProviderMutation,
  useDeleteProviderMutation,
  useUploadContractMutation,
} = providerApi;
