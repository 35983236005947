import React, { useState, useEffect, createContext, useContext } from "react";
import Placeholder from "./Placeholder";
import DraggingContext from './DraggingContext'
import { DndProvider, useDrop, useDrag } from "react-dnd";

 
 const DropZone = ({ onDrop, children, columnName }) => {
    const [isOver, setIsOver] = useState(false);
    const { dragging } = useContext(DraggingContext);
  
    const [{ canDrop, isOverCurrent }, ref] = useDrop({
      accept: "CARD",
      drop: (item) => {
        onDrop(item.id, columnName);
        setIsOver(false);
      },
      hover: (item, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          setIsOver(true);
        } else {
          setIsOver(false);
        }
      },
      collect: (monitor) => ({
        isOverCurrent: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });
  
    return (
      <div ref={ref} className="w-full h-full relative">
        {isOver && dragging && <Placeholder />}
        {children}
      </div>
    );
  };
export default DropZone;