import React, { useState } from "react";
import { useGetAllUsersQuery } from "../../../redux/api/authApi";
import { useGetAccountsQuery } from "../../../redux/api/crm/accountsApi";
import { useGetProjectsQuery } from "../../../redux/api/projectsApi";

const Dropdown = ({ setCategory, selectedCategory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: usersData } = useGetAllUsersQuery();
  const { data: projectsData } = useGetProjectsQuery();
  const { data: accountsData } = useGetAccountsQuery();

  // Calculate the next three years dynamically
  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear - 1, currentYear - 2];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (category, option) => {
    setCategory(category, option);
  };

  const memberOptions = usersData
    ? usersData.users.map((user) => ({ id: user._id, name: user.name }))
    : [];
  const clientsOptions = accountsData
    ? accountsData.accounts.map((client) => ({
        id: client._id,
        name: client.nameComp,
      }))
    : [];

  const tagsOptions = projectsData
    ? [...new Set(projectsData.projects.flatMap((project) => project.tags))]
    : [];

  const categories = {
    Priority: ["High", "Medium", "Low"],
    Members: memberOptions,
    Year: years.map(String), // Convert years to strings
    Client: clientsOptions,
    Tag: tagsOptions,
  };

  return (
    <div className="relative inline-block text-left z-[99]">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md gap-[10px] text-[15px] px-4 py-2 font-medium text-gray-700 dark:text-[#E0E0FF]"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={toggleDropdown}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`fill-current dark:text-[#EDEDFB]`}
          >
            <g id="mage:filter">
              <path
                id="Vector"
                d="M17.7087 10.5H7.41283M3.77866 10.5H2.29199M3.77866 10.5C3.77866 10.0182 3.97006 9.55611 4.31075 9.21542C4.65144 8.87473 5.11352 8.68333 5.59533 8.68333C6.07714 8.68333 6.53921 8.87473 6.8799 9.21542C7.22059 9.55611 7.41199 10.0182 7.41199 10.5C7.41199 10.9818 7.22059 11.4439 6.8799 11.7846C6.53921 12.1253 6.07714 12.3167 5.59533 12.3167C5.11352 12.3167 4.65144 12.1253 4.31075 11.7846C3.97006 11.4439 3.77866 10.9818 3.77866 10.5ZM17.7087 16.0058H12.9187M12.9187 16.0058C12.9187 16.4878 12.7268 16.9504 12.386 17.2911C12.0453 17.6319 11.5831 17.8233 11.1012 17.8233C10.6193 17.8233 10.1573 17.6311 9.81658 17.2904C9.47589 16.9497 9.28449 16.4876 9.28449 16.0058M12.9187 16.0058C12.9187 15.5239 12.7268 15.0621 12.386 14.7214C12.0453 14.3806 11.5831 14.1892 11.1012 14.1892C10.6193 14.1892 10.1573 14.3806 9.81658 14.7213C9.47589 15.0619 9.28449 15.524 9.28449 16.0058M9.28449 16.0058H2.29199M17.7087 4.99416H15.1212M11.487 4.99416H2.29199M11.487 4.99416C11.487 4.51235 11.6784 4.05028 12.0191 3.70959C12.3598 3.3689 12.8218 3.1775 13.3037 3.1775C13.5422 3.1775 13.7785 3.22449 13.9989 3.31578C14.2193 3.40708 14.4195 3.54089 14.5882 3.70959C14.7569 3.87828 14.8907 4.07855 14.982 4.29896C15.0733 4.51936 15.1203 4.7556 15.1203 4.99416C15.1203 5.23273 15.0733 5.46896 14.982 5.68937C14.8907 5.90978 14.7569 6.11005 14.5882 6.27874C14.4195 6.44743 14.2193 6.58125 13.9989 6.67255C13.7785 6.76384 13.5422 6.81083 13.3037 6.81083C12.8218 6.81083 12.3598 6.61943 12.0191 6.27874C11.6784 5.93805 11.487 5.47597 11.487 4.99416Z"
                stroke="#4A488E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                className={`fill-current dark:text-[#EDEDFB]`}
              />
            </g>
          </svg>
          Filter
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-[#383757] ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            <ul className="flex pl-[27px] py-[20px] gap-[27px] flex-col z-30">
              {Object.keys(categories).map((category) => (
                <DropdownItem
                  key={category}
                  label={category}
                  options={categories[category]}
                  selectedOptions={selectedCategory[category] || []} // Use default empty array
                  onOptionClick={(option) =>
                    handleOptionClick(category, option)
                  }
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ label, options, selectedOptions = [], onOptionClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSubDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (option) => {
    onOptionClick(option);
  };

  return (
    <div className="relative">
      <span
        className="no-underline text-[#595959] dark:text-[#E0E0FF] text-[17px] cursor-pointer flex items-center"
        onClick={toggleSubDropdown}
      >
        <li className="flex items-center">
          {label}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            className="ml-2 fill-current dark:text-[#E0E0FF]"
          >
            <path
              d="M0.0957031 3.69454C0.0957031 3.59376 0.135547 3.49062 0.212891 3.41328C0.367578 3.25859 0.620703 3.25859 0.775391 3.41328L6.06992 8.70781L11.2871 3.49062C11.4418 3.33594 11.6949 3.33594 11.8496 3.49062C12.0043 3.64531 12.0043 3.89844 11.8496 4.05312L6.35117 9.55391C6.19648 9.70859 5.94336 9.70859 5.78867 9.55391L0.212891 3.97813C0.133203 3.89844 0.0957031 3.79767 0.0957031 3.69454Z"
              fill="#4A488E"
              className="fill-current dark:text-[#E0E0FF]"
            />
          </svg>
        </li>
      </span>
      {isOpen && (
        <div className="origin-top-left absolute top-5 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-[#383757] ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <ul className="py-1">
            {options.map((option, index) => (
              <li
                key={index}
                className="cursor-pointer px-4 py-2 text-gray-700 dark:text-[#E0E0FF]"
              >
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option.id || option)}
                    onChange={() => handleCheckboxChange(option.id || option)}
                    className="mr-2"
                  />
                  {option.name ? option.name : option}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
