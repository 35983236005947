import React, { useContext } from "react";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import MobileSidebar from "./mobileSidebar";
import DesktopSidebar from "./refactoredDesktopSidebar";

const Sidebar = ({ isLocked, onLockToggle }) => {
  // Access the current view type from the context
  const isMobileView = useContext(ViewTypeContext);

  // Pass props down to the appropriate sidebar component
  return isMobileView ? (
    <MobileSidebar />
  ) : (
    <DesktopSidebar isLocked={isLocked} onLockToggle={onLockToggle} />
  );
};

export default Sidebar;
