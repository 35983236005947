import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../../config";
import Cookies from "js-cookie"; // Import js-cookie

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery,
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query() {
        return {
          url: "/accounts",
          method: "GET",
        };
      },
    }),
    getAccountDetails: builder.query({
      query(account) {
        return {
          url: `/accounts/${account._id}`,
          method: "GET",
        };
      },
    }),
    postAccount: builder.mutation({
      query(account) {
        return {
          url: "/accounts",
          method: "POST",
          body: account,
        };
      },
    }),
    updateAccount: builder.mutation({
      query(account) {
        return {
          url: `/accounts/${account._id}`,
          method: "PUT",
          body: account,
        };
      },
    }),
    deleteAccount: builder.mutation({
      query(accountId) {
        return {
          url: `/accounts/${accountId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAccountDetailsQuery,
  useGetAccountsQuery,
  usePostAccountMutation,
  useDeleteAccountMutation,
  useUpdateAccountMutation,
} = accountsApi;
