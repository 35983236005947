import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { clsx } from "clsx";
import DropdownNotification from "./Dropdown/DropdownNotifications";
import DropdownUsersNotification from "./Dropdown/DropdownUsersNotification";
import NotificationCard from "./NotificationCard";
import {
  useGetNotificationsForReceiverQuery,
  useMarkNotificationAsReadMutation,
  useReadAllNotificationsMutation,
  useUpdateNotificationMutation,
} from "../redux/api/notificationApi";
import { MdMarkEmailRead, MdRuleFolder } from "react-icons/md";
import { motion } from "framer-motion";
import { Tooltip } from "@mui/material";
import { FaStackExchange } from "react-icons/fa6";
import { TbClockBolt } from "react-icons/tb";
import { RiFileUserLine } from "react-icons/ri";
import { PiNotepadBold } from "react-icons/pi";
import { GoGraph } from "react-icons/go";
import { io } from "socket.io-client";
import backend_URL from "../config";

const openClassNames = {
  right: "translate-x-0",
  left: "translate-x-0",
  top: "translate-y-0",
  bottom: "translate-y-0",
};
const closeClassNames = {
  right: "translate-x-full",
  left: "-translate-x-full",
  top: "-translate-y-full",
  bottom: "translate-y-full",
};
const classNames = {
  right: "inset-y-0 right-0",
  left: "inset-y-0 left-0",
  top: "inset-x-0 top-0",
  bottom: "inset-x-0 bottom-0",
};
const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const Drawer = ({ open, setOpen, side = "right" }) => {
  const receiverId = useSelector((state) => state.user.user?._id);
  const user = useSelector((state) => state.user.user);
  const {
    data: notificationsData = { notifications: [] },
    isLoading,
    error,
    refetch,
    isUninitialized,
  } = useGetNotificationsForReceiverQuery(receiverId);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isCategoryChosen, setIsCategoryChosen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [localNotifications, setLocalNotifications] = useState([]);
  const [showReadNotifications, setShowReadNotifications] = useState(false);
  const [isPersonalNotification, setIsPersonalNotification] = useState(false);
  const [isChangelogNotification, setIsChangelogNotification] = useState(false);
  const [isClockifyNotification, setIsClockifyNotification] = useState(false);
  const [isCRMNotification, setIsCRMNotification] = useState(false);
  const [isFinanceNotification, setIsFinanceNotification] = useState(false);

  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
  const [readAllNotifications] = useReadAllNotificationsMutation();

  const markAllAsRead = async () => {
    try {
      await readAllNotifications(receiverId).unwrap();
      // Optionally refetch notifications or update local state here
    } catch (error) {
      console.error("Failed to mark all notifications as read:", error);
    }
  };

  useEffect(() => {
    if (notificationsData.notifications) {
      setLocalNotifications(notificationsData.notifications);
    }
  }, [notificationsData]);

  useEffect(() => {
    const socket = io(backend_URL);
    window.socket = socket;

    socket.on("newNotification", (data) => {
      refetch();
    }
    );

  }, [receiverId, refetch, isUninitialized]);
  

  const filteredNotifications = localNotifications.filter((n) =>
    showReadNotifications ? n.notification?.isRead : !n.notification?.isRead
  );

  let userFilteredNotifications = filteredNotifications;

  if (isCategoryChosen && selectedUserId) {
    userFilteredNotifications = filteredNotifications.filter(
      (n) => n.sender._id === selectedUserId
    );
  }

  if (isPersonalNotification) {
    userFilteredNotifications = userFilteredNotifications.filter((n) => {
      const message = n.notification.message.toLowerCase();
      const isClockify = ["time on task", "timer on task"].some((keyword) =>
        message.includes(keyword)
      );
      const isCRM = [" lead ", " deal ", " account "].some((keyword) =>
        message.includes(keyword)
      );
      const isFinance = [" expense "].some((keyword) =>
        message.includes(keyword)
      );
      const isChangelog = message.includes("following changes were made");
      return !isClockify && !isChangelog && !isCRM && !isFinance;
    });
  } else if (isChangelogNotification) {
    userFilteredNotifications = userFilteredNotifications.filter((n) =>
      n.notification.message
        .toLowerCase()
        .includes("following changes were made")
    );
  } else if (isClockifyNotification) {
    userFilteredNotifications = userFilteredNotifications.filter((n) =>
      ["time on task", "timer on task"].some((keyword) =>
        n.notification.message.toLowerCase().includes(keyword)
      )
    );
  } else if (isCRMNotification) {
    userFilteredNotifications = userFilteredNotifications.filter((n) =>
      [" lead ", " deal ", " account "].some((keyword) =>
        n.notification.message.toLowerCase().includes(keyword)
      )
    );
  } else if (isFinanceNotification) {
    userFilteredNotifications = userFilteredNotifications.filter((n) =>
      [" expense "].some((keyword) =>
        n.notification.message.toLowerCase().includes(keyword)
      )
    );
  }

  if (searchQuery) {
    userFilteredNotifications = userFilteredNotifications.filter(
      (n) =>
        n.notification.message
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        n.sender.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const todayNotifications = userFilteredNotifications
    .filter((n) => isToday(new Date(n.notification.timeStamp)))
    .sort(
      (a, b) =>
        new Date(b.notification.timeStamp) - new Date(a.notification.timeStamp)
    );

  const olderNotifications = userFilteredNotifications.filter(
    (n) => !isToday(new Date(n.notification.timeStamp))
  );

  const handleRemoveNotification = (id) => {
    setLocalNotifications((prev) =>
      prev.map((notification) =>
        notification.notification._id === id
          ? {
              ...notification,
              notification: { ...notification.notification, isRead: true },
            }
          : notification
      )
    );
  };

  const handleMarkAllAsRead = () => {
    markAllAsRead();

    setLocalNotifications((prev) =>
      prev.map((notification) => ({
        ...notification,
        notification: { ...notification.notification, isRead: true },
      }))
    );
  };

  const toggleReadNotifications = () => {
    setShowReadNotifications((prev) => !prev);
  };

  // Function to apply styles based on the active filter
  const getButtonClass = (isActive) =>
    clsx(
      "flex items-center justify-center text-white p-2 rounded-full cursor-pointer",
      { "bg-blue-500": isActive }, // Active state style
      { "bg-gray-700": !isActive } // Inactive state style
    );

  return (
    <div
      id={`dialog-${side}`}
      className="relative z-10"
      aria-labelledby="slide-over"
      role="dialog"
      aria-modal="true"
      onClick={() => setOpen(!open)}
    >
      <div
        className={clsx(
          "fixed inset-0 bg-gray-500 bg-opacity-75 transition-all",
          {
            "opacity-100 duration-500 ease-in-out visible": open,
          },
          { "opacity-0 duration-500 ease-in-out invisible": !open }
        )}
      ></div>
      <div className={clsx({ "fixed inset-0 overflow-hidden": open })}>
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={clsx(
              "pointer-events-none fixed max-w-full",
              classNames[side]
            )}
          >
            <div
              className={clsx(
                "pointer-events-auto relative w-full h-full transform transition ease-in-out duration-500",
                { [closeClassNames[side]]: !open },
                { [openClassNames[side]]: open }
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div
                className={clsx(
                  "flex flex-col h-full overflow-y-scroll scrolling-touch bg-[#8684CC] dark:bg-[#383757] w-[600px] py-[36px] px-[27px] shadow-xl rounded-lg"
                )}
              >
                <div className="flex justify-between">
                  <div className="flex gap-[10px] items-center">
                    <p className="text-[25px] text-white">Notifications</p>
                    <DropdownNotification />
                  </div>
                  <img
                    src="/images/close.svg"
                    alt="Close"
                    onClick={() => setOpen(!open)}
                    className="cursor-pointer"
                  />
                </div>
                <div className="mt-[44px] flex justify-between items-center">
                  <div className="flex gap-[20px]">
                    <DropdownUsersNotification
                      setCategory={setSelectedUserId}
                      setIsCategoryChosen={setIsCategoryChosen}
                    />
                  </div>
                  <div className="mr-[-20px] flex gap-[2px]">
                    <Tooltip
                      title="Mark all as read"
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#383757",
                            color: "white",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#383757",
                          },
                        },
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.9 }}
                        className="flex items-center justify-center text-white p-2 rounded-full cursor-pointer"
                        onClick={handleMarkAllAsRead}
                      >
                        <MdMarkEmailRead size={24} />
                      </motion.button>
                    </Tooltip>
                    <Tooltip
                      title={
                        showReadNotifications ? "Show Unread" : "Show Read"
                      }
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#383757",
                            color: "white",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#383757",
                          },
                        },
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.9 }}
                        className="flex items-center justify-center text-white p-2 rounded-full cursor-pointer"
                        onClick={toggleReadNotifications}
                      >
                        <MdRuleFolder size={24} />
                      </motion.button>
                    </Tooltip>
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search notification"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="h-[55px] bg-[#c5c5ed5e] border-[#7674c2] text-white rounded-[52px] p-5 relative placeholder:text-[#DBDDFF] dark:text-[#EDEDFB]"
                    />
                    <img
                      src="/images/search-2.svg"
                      alt="Search Icon"
                      className="absolute top-[27px] right-[20px] transform -translate-y-1/2"
                    />
                  </div>
                </div>
                {user?.role === "Admin" ? (
                  <div className="flex flex-row gap-3">
                    <Tooltip
                      title="Personal notifications"
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#383757",
                            color: "white",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#383757",
                          },
                        },
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.9 }}
                        className={getButtonClass(isPersonalNotification)}
                        onClick={() => {
                          setIsFinanceNotification(false);

                          setIsPersonalNotification(true);
                          setIsChangelogNotification(false);
                          setIsClockifyNotification(false);
                          setIsCRMNotification(false);
                        }}
                      >
                        <RiFileUserLine size={24} />
                      </motion.button>
                    </Tooltip>
                    <Tooltip
                      title="Changelog notifications"
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#383757",
                            color: "white",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#383757",
                          },
                        },
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.9 }}
                        className={getButtonClass(isChangelogNotification)}
                        onClick={() => {
                          setIsFinanceNotification(false);

                          setIsPersonalNotification(false);
                          setIsChangelogNotification(true);
                          setIsClockifyNotification(false);
                          setIsCRMNotification(false);
                        }}
                      >
                        <FaStackExchange size={24} />
                      </motion.button>
                    </Tooltip>
                    <Tooltip
                      title="Time tracking notifications"
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#383757",
                            color: "white",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#383757",
                          },
                        },
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.9 }}
                        className={getButtonClass(isClockifyNotification)}
                        onClick={() => {
                          setIsFinanceNotification(false);

                          setIsPersonalNotification(false);
                          setIsChangelogNotification(false);
                          setIsClockifyNotification(true);
                          setIsCRMNotification(false);
                        }}
                      >
                        <TbClockBolt size={24} />
                      </motion.button>
                    </Tooltip>
                    <Tooltip
                      title="CRM notifications"
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#383757",
                            color: "white",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#383757",
                          },
                        },
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.9 }}
                        className={getButtonClass(isCRMNotification)}
                        onClick={() => {
                          setIsFinanceNotification(false);

                          setIsPersonalNotification(false);
                          setIsChangelogNotification(false);
                          setIsClockifyNotification(false);
                          setIsCRMNotification(true);
                        }}
                      >
                        <PiNotepadBold size={24} />
                      </motion.button>
                    </Tooltip>
                    <Tooltip
                      title="Finance notifications"
                      placement="bottom"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#383757",
                            color: "white",
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#383757",
                          },
                        },
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 0.98 }}
                        whileTap={{ scale: 0.9 }}
                        className={getButtonClass(isFinanceNotification)}
                        onClick={() => {
                          setIsPersonalNotification(false);
                          setIsChangelogNotification(false);
                          setIsClockifyNotification(false);
                          setIsCRMNotification(false);
                          setIsFinanceNotification(true);
                        }}
                      >
                        <GoGraph size={24} />
                      </motion.button>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
                <div className="mt-[34px]">
                  {!showReadNotifications && (
                    <>
                      <p className="text-[18px] mb-[16px] text-white">Today</p>
                      {todayNotifications.map((notification) => (
                        <NotificationCard
                          key={notification.notification._id}
                          notification={notification}
                          onRemove={handleRemoveNotification}
                        />
                      ))}
                      <p className="text-[18px] mb-[16px] text-white">
                        Older Notifications
                      </p>
                      {!isLoading &&
                        !error &&
                        olderNotifications.length === 0 && (
                          <p className="text-white">
                            No older unread notifications
                          </p>
                        )}
                      {!isLoading &&
                        !error &&
                        olderNotifications.map((notification) => (
                          <NotificationCard
                            key={notification.notification._id}
                            notification={notification}
                            onRemove={handleRemoveNotification}
                          />
                        ))}
                    </>
                  )}
                  {showReadNotifications && (
                    <>
                      <p className="text-[18px] mb-[16px] text-white">
                        Read Notifications
                      </p>
                      {userFilteredNotifications.length === 0 && (
                        <p className="text-white">
                          No read notifications available
                        </p>
                      )}
                      {userFilteredNotifications
                        .reverse()
                        .map((notification) => (
                          <NotificationCard
                            key={notification.notification._id}
                            notification={notification}
                            onRemove={handleRemoveNotification}
                          />
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
