import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useCreateUserByAdminMutation,
  useGetAllUsersQuery,
} from "../redux/api/authApi";
import DropdownRoles from "./Dropdown/DropdownRoles";
import DropdownDepartments from "../components/Dropdown/DropdowDepartments";
import {
  useGetDepartmentsQuery,
  useAddUserToDepartmentMutation,
} from "../redux/api/departmentsApi";
import { notify } from "./utils/notificationManager";
import { TrashcanSVG } from "./utils/generalSVGS";

const FormUserManagement = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [theme2, setTheme2] = useState(localStorage.getItem("theme"));
  const {
    data: dataDepartments,
    error: errorDepartments,
    loading: loadingDepartments,
  } = useGetDepartmentsQuery();
  const [addUserToDepartment] = useAddUserToDepartmentMutation();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const handleStorageChange = () => {
      setTheme2(localStorage.getItem("theme"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    department: "",
    role: "",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted for small screens
    maxWidth: "600px", // Max width for larger screens
    bgcolor: theme2 === "dark" ? "#2B2A49" : "#FAFAFE",
    boxShadow: 24,
    p: 4,
  };

  const { name, email, password, department, role } = user;
  const [
    createUserByAdmin,
    { isLoading: isCreateUserLoading, error: createUserError, data: createUserData },
  ] = useCreateUserByAdminMutation();
  const { data: usersData, refetch: refetchUsers } = useGetAllUsersQuery();

  useEffect(() => {
    if (createUserError) {
      notify(createUserError?.data?.message, 2, null, null, true); // Replaced toast.error
    }
  }, [createUserError]);

  useEffect(() => {
    if (createUserData) {
      notify("User created successfully by admin!", 0, null, null, true); // Replaced toast.success
      refetchUsers(); // Fetch all users after successful registration
    }
  }, [createUserData]);

  useEffect(() => {
    if (usersData) {
      const newUser = usersData?.users.find((user) => user.email === email);
      if (newUser) {
        const deptId = dataDepartments.departments.find(
          (dept) => dept.departmentName === department
        );
        setUserId(newUser._id);
        addUserToDepartment({
          userId: newUser._id,
          id: deptId._id,
        })
          .then(() => {
            notify("User added to department successfully!", 0, null, null, true); // Replaced toast.success
            handleClose();
          })
          .catch((error) => {
            notify("Failed to add user to department.", 2, null, null, true); // Replaced toast.error
          });
      }
    }
  }, [usersData]);

  const submitHandler = (e) => {
    e.preventDefault();
    const signUpData = {
      name,
      email,
      password,
      role,
    };
    createUserByAdmin(signUpData);
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const setDepartment = (department) => {
    setUser({ ...user, department });
  };

  const setRole = (role) => {
    setUser({ ...user, role });
  };

  const resetForm = () => {
    setUser({
      name: "",
      email: "",
      password: "",
      department: "",
      role: "",
    });
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <button className="py-2 px-4 bg-[#E7E7F9] border border-[#7674C2] rounded-md text-[#4A488E] text-base font-medium ">
          + New user
        </button>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="flex justify-between mb-8">
              <p className="text-[#4A488E] dark:text-[#EDEDFB]">New user</p>
              <svg
                width="30"
                height="30"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={handleClose}
              >
                <g id="ph:x">
                  <path
                    id="Vector"
                    d="M29.724 28.0884C29.8314 28.1958 29.9166 28.3233 29.9747 28.4637C30.0329 28.604 30.0628 28.7545 30.0628 28.9064C30.0628 29.0583 30.0329 29.2088 29.9747 29.3491C29.9166 29.4895 29.8314 29.617 29.724 29.7245C29.6165 29.8319 29.489 29.9171 29.3486 29.9752C29.2083 30.0334 29.0578 30.0633 28.9059 30.0633C28.754 30.0633 28.6036 30.0334 28.4632 29.9752C28.3228 29.9171 28.1953 29.8319 28.0879 29.7245L18.4997 20.1348L8.91146 29.7245C8.6945 29.9414 8.40024 30.0633 8.09342 30.0633C7.78659 30.0633 7.49233 29.9414 7.27537 29.7245C7.05841 29.5075 6.93652 29.2132 6.93652 28.9064C6.93652 28.5996 7.05841 28.3053 7.27537 28.0884L16.865 18.5002L7.27537 8.91195C7.05841 8.69499 6.93652 8.40073 6.93652 8.0939C6.93652 7.78708 7.05841 7.49282 7.27537 7.27586C7.49233 7.0589 7.78659 6.93701 8.09342 6.93701C8.40024 6.93701 8.6945 7.0589 8.91146 7.27586L18.4997 16.8655L28.0879 7.27586C28.3048 7.0589 28.5991 6.93701 28.9059 6.93701C29.2127 6.93701 29.507 7.0589 29.724 7.27586C29.9409 7.49282 30.0628 7.78708 30.0628 8.0939C30.0628 8.40073 29.9409 8.69499 29.724 8.91195L20.1343 18.5002L29.724 28.0884Z"
                    fill="#4A488E"
                    className={`fill-current dark:text-[#EDEDFB]`}
                  />
                </g>
              </svg>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={submitHandler} className="flex flex-col gap-4">
              <div className="flex flex-col md:flex-row items-center justify-between gap-4 px-4">
                <label
                  htmlFor="name_field"
                  className="form-label text-lg text-[#434343] dark:text-[#EDEDFB]"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control w-full h-[47px] px-3 text-base bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
                  name="name"
                  value={name}
                  onChange={onChange}
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between gap-4 px-4">
                <label
                  htmlFor="email_field"
                  className="form-label text-lg text-[#434343] dark:text-[#EDEDFB]"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email_field"
                  className="form-control w-full h-[47px] px-3 text-base bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
                  name="email"
                  value={email}
                  onChange={onChange}
                />
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between gap-4 px-4">
                <label
                  htmlFor="password_field"
                  className="form-label text-lg text-[#434343] dark:text-[#EDEDFB]"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password_field"
                  className="form-control w-full h-[47px] px-3 text-base bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
                  name="password"
                  value={password}
                  onChange={onChange}
                />
              </div>

              <div className="flex flex-col md:flex-row items-center justify-between gap-4 px-4">
                <label
                  htmlFor="department_field"
                  className="form-label text-lg text-[#434343] dark:text-[#EDEDFB]"
                >
                  Department
                </label>
                <div className="w-full h-[47px] px-3 text-base bg-[#E9E9FF] flex items-center dark:bg-[#3A3A68] dark:text-[#EDEDFB]">
                  <DropdownDepartments
                    setDepartment={setDepartment}
                    department={department}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-between gap-4 px-4">
                <label
                  htmlFor="role_field"
                  className="form-label text-lg text-[#434343] dark:text-[#EDEDFB]"
                >
                  Role
                </label>
                <div className="w-full h-[47px] px-3 text-base bg-[#E9E9FF] flex items-center dark:bg-[#3A3A68] dark:text-[#EDEDFB]">
                  <DropdownRoles
                    setCategory={setRole}
                    setIsCategoryChosen={() => {}}
                    roleUser={role}
                  />
                </div>
              </div>

              <div className="flex justify-end w-full px-4">
                <button
                  id="login_button"
                  type="submit"
                  className="btn w-full md:w-auto py-2 px-5 bg-[#8D8BDB] rounded-md text-white"
                  disabled={isCreateUserLoading}
                >
                  {isCreateUserLoading ? "Creating..." : "Add new User"}
                </button>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default FormUserManagement;
