import React, { useState, useEffect } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import moment from 'moment';

const FuseDatepicker = ({ label, value, onChange }) => {
  const [date, setDate] = useState(value ? moment(value).format("DD.MM.YYYY") : ""); // Initialize date with formatted value or empty string

  // Ensure the date is formatted and displayed correctly
  useEffect(() => {
    if (value) {
      setDate(moment(value).format("DD.MM.YYYY"));
    }
  }, [value]);

  const handleValueChange = (newDate) => {
    const formattedDate = moment(newDate.startDate).format("DD.MM.YYYY"); // Format the date as DD.MM.YYYY
    setDate(formattedDate); // Set the formatted date
    onChange(newDate.startDate); // Pass the raw date to the onChange handler
  };

  return (
    <div className="flex flex-row items-center w-full gap-4 mb-4">
      <div
        className="text-[#4A488E] dark:text-[#EDEDFB] text-[18px] font-normal w-[150px]"
        style={{ wordWrap: 'break-word' }}
      >
        {label}
      </div>
      <div className="w-[600px]">
        <Datepicker
          value={{ startDate: date ? moment(date, "DD.MM.YYYY").toDate() : null, endDate: null }} // Ensure proper date format
          onChange={handleValueChange}
          displayFormat="DD.MM.YYYY" // Display format for the date
          inputClassName="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-full"
          containerClassName="relative"
          toggleClassName="absolute right-[10px] top-[15px] text-[#4A488E] dark:text-[#EDEDFB]"
          popperOptions={{
            strategy: "fixed",
          }}
          popperPlacement="bottom-end"
          startFrom={new Date()} // Set the initial date
          primaryColor="indigo" // Customize the color to match your theme
          useRange={false} // Disable date range selection
          placeholder={date || 'Select date'} // Display the formatted date or placeholder
          asSingle={true} // Ensure this is treated as a single date picker
        />
      </div>
    </div>
  );
};

export default FuseDatepicker;
