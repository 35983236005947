import React, { useState, useEffect, useContext } from "react";
import {
  Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import {
  useGetAppraisalsQuery,
  useSubmitUserResponseMutation,
  useDeleteUserResponseMutation,
} from "../../redux/api/appraisailsApi";
import { useGetDepartmentsForUserQuery } from "../../redux/api/departmentsApi";
import { useSelector } from "react-redux";
import YourActivity from "../utils/appraisal/YourActivity";
import { notify } from "../utils/notificationManager";
import TextEditor from "../utils/TextEditor/TextEditor";
import RenderedContent from "../utils/TextEditor/RenderedContent";

const departmentMapping = {
  DEV: "Development",
  SEO: "SEO",
  SMM: "SMM",
  PPC: "PPC",
  SALES: "Sales",
};

const responseCategories = [
  { label: "1. Reflect on the past", value: "reflectOnPast" },
  { label: "2. Plan for the future", value: "planForFuture" },
  { label: "3. Opportunities for growth", value: "opportunitiesForGrowth" },
];

export const AppraisalView = () => {
  const isMobileView = useContext(ViewTypeContext);
  const user = useSelector((state) => state.user.user);
  const userId = user?._id;
  const userSeniority = user?.seniority?.toLowerCase();

  const [departmentName, setDepartmentName] = useState("");
  const [appraisalId, setAppraisalId] = useState("");
  const [firstHalfExpectations, setFirstHalfExpectations] = useState("");
  const [secondHalfExpectations, setSecondHalfExpectations] = useState("");
  const [appraisalPeriod, setAppraisalPeriod] = useState("");
  const [halfPeriodPassed, setHalfPeriodPassed] = useState(false);
  const [userResponses, setUserResponses] = useState({});
  const [editingCategory, setEditingCategory] = useState(null);
  const [responseText, setResponseText] = useState({});

  const { data: departmentData, error: departmentError } =
    useGetDepartmentsForUserQuery({ id: userId }, { skip: !userId });

  useEffect(() => {
    if (departmentData && departmentData.success) {
      const departmentCode = departmentData.departments[0]?.departmentName;
      if (departmentCode) {
        const mappedDepartmentName = departmentMapping[departmentCode];
        setDepartmentName(mappedDepartmentName);
      }
    }
  }, [departmentData, departmentError]);

  const getCurrentMonthDates = () => {
    const now = new Date();
    const year = now.getFullYear();
  
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;
  
    return { startDate, endDate };
  };

  const { startDate, endDate } = getCurrentMonthDates();

  const {
    data: appraisalsData,
    error: appraisalsError,
    refetch,
  } = useGetAppraisalsQuery(
    {
      department: departmentName,
      seniority: userSeniority,
      startDate: startDate,
      endDate: endDate,
    },
    { skip: !departmentName || !userSeniority }
  );

  const [submitUserResponse] = useSubmitUserResponseMutation();
  const [deleteUserResponse] = useDeleteUserResponseMutation();

  useEffect(() => {
    if (appraisalsData && appraisalsData.appraisals.length > 0) {
      const appraisal = appraisalsData.appraisals[0];
      const { startDate, endDate, halfPeriodPassed } = appraisal;

      setAppraisalId(appraisal._id);
      setHalfPeriodPassed(halfPeriodPassed);

      const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
      };
      setAppraisalPeriod(`${formatDate(startDate)} - ${formatDate(endDate)}`);

      const expectations =
        appraisal?.departments[departmentName]?.expectations || {};
      setFirstHalfExpectations(
        expectations.first || "No expectations available for the first half."
      );
      setSecondHalfExpectations(
        expectations.second || "No expectations available for the second half."
      );

      const allResponses =
        appraisal?.departments[departmentName]?.userResponses || [];

      const userSpecificResponses = allResponses.filter(
        (response) => response.userId === userId
      );

      const mappedResponses = {};

      userSpecificResponses.forEach((response) => {
        if (response.firstHalfResponse) {
          responseCategories.forEach((category) => {
            const key = `${category.value}_first_half`;
            mappedResponses[key] =
              response.firstHalfResponse[category.value] || "";
          });
        }
        if (response.secondHalfResponse) {
          responseCategories.forEach((category) => {
            const key = `${category.value}_second_half`;
            mappedResponses[key] =
              response.secondHalfResponse[category.value] || "";
          });
        }
      });

      setUserResponses(mappedResponses);
    }
  }, [appraisalsData, departmentName, appraisalsError, userId]);

  const handleSaveResponse = async (categoryValue, half) => {
    const halfToSend = half === "first_half" ? "first" : "second";

    try {
      await submitUserResponse({
        id: appraisalId,
        department: departmentName,
        responseType: categoryValue,
        responseText: responseText[`${categoryValue}_${half}`],
        half: halfToSend,
      }).unwrap();

      setEditingCategory(null);
      setResponseText((prev) => ({
        ...prev,
        [`${categoryValue}_${half}`]: "",
      }));

      setUserResponses((prev) => ({
        ...prev,
        [`${categoryValue}_${half}`]: responseText[`${categoryValue}_${half}`],
      }));
      notify("Response saved successfully", 0, null, null, true);

      refetch();
    } catch (error) {
      console.error("Failed to submit response:", error);
    }
  };

  const handleDeleteResponse = async (categoryValue, half) => {
    const halfToSend = half === "first_half" ? "first" : "second";

    try {
      await deleteUserResponse({
        id: appraisalId,
        department: departmentName,
        category: categoryValue,
        half: halfToSend,

        userId: userId,

      }).unwrap();

      const responseKey = `${categoryValue}_${half}`;
      setUserResponses((prev) => {
        const updated = { ...prev };
        delete updated[responseKey];
        return updated;
      });
      notify("Response deleted successfully", 0, null, null, true);

      refetch();
    } catch (error) {
      console.error("Failed to delete response:", error);
    }
  };

  const renderEditorOrResponse = (category, half) => {
    const userResponse = appraisalsData?.appraisals[0]?.departments[departmentName]?.userResponses?.find(
      (response) => response.userId === userId
    );
  
    let existingResponse = "";
    let adminFeedback = "";
  
    if (userResponse) {
      if (half === "first_half") {
        existingResponse = userResponse.firstHalf
          ? userResponse.firstHalf[category.value]
          : "";
      } else if (half === "second_half") {
        existingResponse = userResponse.secondHalf
          ? userResponse.secondHalf[category.value]
          : "";

        adminFeedback = userResponse.secondHalf?.adminFeedback?.[category.value]?.feedback || "";

      }
    }
  
    // Ensure the responseText state is updated when the editor is opened
    const openEditor = () => {
      setEditingCategory(`${category.value}_${half}`);
      setResponseText((prev) => ({
        ...prev,
        [`${category.value}_${half}`]: existingResponse || "", // Set the existing content or empty if new
      }));
    };
  
    // Handle rendering logic based on admin feedback, existing responses, or whether the editor is open
    if (halfPeriodPassed && half === "first_half" && !existingResponse) {

      return <Typography className="text-red-500 font-medium">Uncompleted</Typography>;

    }
  
    if (adminFeedback) {
      return (
        <div>

          <RenderedContent content={existingResponse} className="text-[#484878] dark:text-white font-medium pt-2" />
          <br />

          <Typography className="text-[#484878] dark:text-white font-light ml-4">
            <strong>Admin Feedback:</strong>
          </Typography>
          <RenderedContent content={adminFeedback} className="text-[#484878] dark:text-white font-light ml-4" />
        </div>
      );
    }
  
    if (editingCategory === `${category.value}_${half}` || !existingResponse) {
      return (
        <div>
          <TextEditor
            initialValue={responseText[`${category.value}_${half}`] || ""}
            onChange={(text) =>
              setResponseText((prev) => ({
                ...prev,
                [`${category.value}_${half}`]: text,
              }))
            }
          />
          <div className="flex justify-end items-center space-x-4 mt-4">
            <div
              onClick={() => setEditingCategory(null)}
              className="text-gray-500 dark:text-white/60 text-xl font-normal font-['Inter'] cursor-pointer"
            >
              Cancel
            </div>

            <div
              onClick={() => handleSaveResponse(category.value, half)}
              className="h-12 px-[22px] py-3 bg-[#7977c1] rounded-md justify-center items-center gap-2.5 inline-flex cursor-pointer"
            >
              <div className="text-white text-xl font-normal">Save</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Typography className="text-[#484878] dark:text-white font-medium pt-2">
            <strong>Response:</strong>
          </Typography>
          <RenderedContent content={existingResponse} className="text-[#484878] dark:text-white font-medium pt-2" />
          <div className="flex justify-end items-center space-x-4 mt-4">
            <div

              onClick={openEditor}

              className="h-12 px-[22px] py-3 bg-[#7977c1] rounded-md justify-center items-center gap-2.5 inline-flex cursor-pointer"
            >
              <div className="text-white text-xl font-normal">Edit</div>
            </div>

            <div
              onClick={() => handleDeleteResponse(category.value, half)}
              className="text-gray-500 dark:text-white/60 text-xl font-normal font-['Inter'] cursor-pointer"
            >
              Delete
            </div>
          </div>
        </div>
      );
    }
  };
  

  const renderNestedAccordion = (half) =>
    responseCategories.map((category, index) => (
      <Accordion
        key={index}
        className="bg-transparent"
        elevation={0}
        sx={{
          "&:before": {
            display: "none",
          },
          padding: 0,
        }}
        disableGutters
      >
        <AccordionSummary
          className="p-0"
          expandIcon={
            <ExpandMoreIcon className="text-[#484878] dark:text-white" />
          }
        >
          <Typography>
            <div className="text-lg text-[25px] font-medium text-[#484878] dark:text-white">
              {category.label}
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="bg-[#D0D0FF] dark:bg-[#484878] rounded-[6px] px-5 py-8">
          {renderEditorOrResponse(category, half)}
        </AccordionDetails>
      </Accordion>
    ));

  return (
    <div
      className={
        isMobileView
          ? "pr-[54px] pt-[25px] h-[100vh] overflow-x-hidden bg-white dark:bg-[#2B2A49] md:mb-40"
          : "pr-[54px] ml-[-64px] pt-[25px] h-[100vh] overflow-auto bg-white dark:bg-[#2B2A49]"
      }
    >
      <div className="w-full ml-12 pr-12 md:mb-40">
        <div className="bg-white p-4 flex items-center flex-wrap dark:bg-[#2B2A49]">
          <ul className="flex items-center">
            <Breadcrumbs aria-label="breadcrumb" className="dark:text-[#EDEDFB]">
              <Link
                underline="hover"
                className="dark:text-[#EDEDFB]"
                color="inherit"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                underline="hover"
                className="dark:text-[#EDEDFB]"
                color="inherit"
                to="/HR"
              >
                HR
              </Link>
              <Link
                underline="hover"
                className="dark:text-[#EDEDFB]"
                color="inherit"
                to="/HR/Appraisal"
              >
                Appraisal
              </Link>
            </Breadcrumbs>
          </ul>
        </div>

        <div>
          <h1 className="text-[#4A488E] p-4 text-3xl font-normal mt-[40px] mb-[10px] dark:text-[#EDEDFB]">
            Appraisal
          </h1>

          {appraisalPeriod && (
            <h2 className="text-[#484878] p-4 dark:text-white text-lg font-medium mb-4">
              {appraisalPeriod}
            </h2>
          )}

          {!appraisalsData || appraisalsData.appraisals.length === 0 ? (
            <p className="text-[#484878] dark:text-white">
              No appraisals yet set. Please wait for an administrator to set the
              appraisal period and expectations.
            </p>
          ) : (
            <>
              <Accordion
                className="bg-transparent border-none"
                disableGutters
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon className="text-[#484878] dark:text-white" />
                  }
                >
                  <Typography>
                    <div className="text-lg font-bold underline text-[#484878] dark:text-white">
                      First Half
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RenderedContent
                    content={firstHalfExpectations}
                    className="text-[#484878] dark:text-white font-medium"
                  />
                  {renderNestedAccordion("first_half")}
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="bg-transparent"
                disableGutters
                elevation={0}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon className="text-[#484878] dark:text-white" />
                  }
                >
                  <Typography>
                    <div className="text-lg font-bold underline text-[#484878] dark:text-white">
                      Second Half
                    </div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RenderedContent
                    content={secondHalfExpectations}
                    className="text-[#484878] dark:text-white font-medium pb-4"
                  />
                  {renderNestedAccordion("second_half")}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          <div className="p-4">
            <YourActivity
              user={user}
              canEdit={true}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

