import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useUpdateUserMutation,
  useUploadAvatarMutation,
} from "../../redux/api/userApi";

const ProfileSettings = () => {
  const { user } = useSelector((state) => state.user);
  const [userData, setUserData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    job: user?.job || "",
    phone: user?.phone || "",
    city: user?.city || "",
    address: user?.address || "",
    postalCode: user?.postalCode || "",
  });

  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState(user?.avatar?.url || "");
  const [updateUser, { isLoading: isLoadingUser, isError: isErrorUser }] =
    useUpdateUserMutation();
  const [
    uploadAvatar,
    {
      isLoading: isLoadingAvatar,
      error: errorAvatar,
      isSuccess: isSuccessAvatar,
    },
  ] = useUploadAvatarMutation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await uploadAvatar({ avatar });
      toast.success("Avatar uploaded successfully");
    } catch (error) {
      toast.error("Failed to upload avatar");
    }
  };

  const onChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setAvatar(reader.result);
      setAvatarPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUserUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateUser({ id: user._id, body: userData });
      if (isErrorUser) {
        toast.error("Failed to update user");
      } else {
        toast.success("User updated successfully");
      }
    } catch (error) {
      console.error("Failed to update user:", error);
      toast.error("Failed to update user");
    }
  };

  return (
    <div className="flex flex-col h-[100vh] items-start gap-8 dark:bg-[#2B2A49] p-6 pb-64 overflow-y-auto scrolling-touch ">
      <h1 className="text-2xl font-semibold text-gray-700 dark:text-[#EDEDFB]">
        Profile Settings
      </h1>

      <div className="w-full border border-gray-200 bg-white dark:bg-[#2B2A49] rounded-lg shadow-lg p-6 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div
            className="w-16 h-16 rounded-full bg-center bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(${avatarPreview})` }}
          />
          <div>
            <h2 className="text-lg font-medium text-gray-800 dark:text-[#EDEDFB]">
              {user?.name}
            </h2>
            <p className="text-sm text-gray-500 dark:text-[#EDEDFB]">
              {user?.job}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <form
            onSubmit={submitHandler}
            className="flex flex-col items-center gap-2"
          >
            <input
              name="avatar"
              type="file"
              accept="image/*"
              className="block w-full text-sm text-gray-500 dark:text-[#EDEDFB] file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
              onChange={onChange}
            />
            <button
              type="submit"
              className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md shadow-md"
              disabled={isLoadingAvatar}
            >
              {isLoadingAvatar ? "Uploading..." : "Upload"}
            </button>
          </form>
        </div>
      </div>

      <form
        onSubmit={handleUserUpdate}
        className="w-full border border-gray-200 bg-white dark:bg-[#2B2A49] rounded-lg shadow-lg p-6"
      >
        <div className="flex flex-col gap-6">
          <h2 className="text-xl font-medium text-gray-700 dark:text-[#EDEDFB]">
            Personal Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-500 dark:text-[#EDEDFB]">
                Name
              </label>
              <input
                className="mt-1 p-2 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:text-[#EDEDFB]"
                name="name"
                value={userData?.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-500 dark:text-[#EDEDFB]">
                Phone
              </label>
              <input
                className="mt-1 p-2 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:text-[#EDEDFB]"
                name="phone"
                value={userData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-500 dark:text-[#EDEDFB]">
                Job
              </label>
              <input
                className="mt-1 p-2 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:text-[#EDEDFB]"
                name="job"
                readOnly
                value={userData?.job}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-500 dark:text-[#EDEDFB]">
                Email Address
              </label>
              <input
                className="mt-1 p-2 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:text-[#EDEDFB]"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md shadow-md"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>

      <form
        onSubmit={handleUserUpdate}
        className="w-full border border-gray-200 bg-white dark:bg-[#2B2A49] rounded-lg shadow-lg p-6"
      >
        <div className="flex flex-col gap-6">
          <h2 className="text-xl font-medium text-gray-700 dark:text-[#EDEDFB]">
            Address
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-500 dark:text-[#EDEDFB]">
                City
              </label>
              <input
                className="mt-1 p-2 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:text-[#EDEDFB]"
                name="city"
                value={userData.city}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-500 dark:text-[#EDEDFB]">
                Postal Code
              </label>
              <input
                className="mt-1 p-2 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:text-[#EDEDFB]"
                name="postalCode"
                value={userData.postalCode}
                onChange={handleInputChange}
              />
            </div>
            <div className="flex flex-col md:col-span-2">
              <label className="text-sm font-medium text-gray-500 dark:text-[#EDEDFB]">
                Address
              </label>
              <input
                className="mt-1 p-2 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:text-[#EDEDFB] w-full"
                name="address"
                value={userData.address}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-md shadow-md"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProfileSettings;
