import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// Example code: Ensure only valid actions are imported
import {
  setUser,
  setIsAuthenticated,
  setToken,
  clearAuth,
  setLoading,
} from "../features/userSlice";
import backend_URL from "../../config";
import Cookies from "js-cookie"; // Import js-cookie

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => `/me`,
      transformResponse: (result) => result.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
          dispatch(setIsAuthenticated(true));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
        }
      },
      providesTags: ["Users"], // Asigurăm că această interogare furnizează tag-ul "Users"
    }),
    updatePassword: builder.mutation({
      query: (passwords) => ({
        url: `/password/update`,
        method: "PUT",
        body: passwords,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
        }
      },
    }),
    uploadAvatar: builder.mutation({
      query: (body) => ({
        url: `/me/upload_avatar`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Users"], // Asigurăm că această mutație invalidează tag-ul "Users"
    }),
    uploadContract: builder.mutation({
      query: (body) => ({
        url: `/user/upload_contract`,
        method: "POST", // Ensure method matches the route definition
        body,
      }),
    }),

    updateUser: builder.mutation({
      query({ id, body }) {
        return {
          url: `/user/${id}`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
          // Optionally update the cache for user if needed
          dispatch(
            userApi.util.updateQueryData("getMe", null, (draft) => {
              Object.assign(draft, data.user);
            })
          );
        } catch (error) {
          console.error("Error updating user:", error);
        }
      },
      invalidatesTags: ["Users"], // Asigurăm că această mutație invalidează tag-ul "Users"
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdatePasswordMutation,
  useUploadAvatarMutation,
  useUpdateUserMutation,
  useUploadContractMutation,
} = userApi;
