import React from "react";

export const SearchSVG = ({ onClick }) => (
  <svg
    onClick={onClick}
    className="absolute left-[370px] top-[27px] transform -translate-y-1/2"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      className=""
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9847 13.0889C10.5807 14.2107 8.80047 14.7523 7.0096 14.6025C5.21874 14.4527 3.55321 13.6228 2.35508 12.2834C1.15696 10.9439 0.517191 9.19656 0.567176 7.40014C0.617162 5.60371 1.35311 3.89462 2.62386 2.62386C3.89462 1.35311 5.60371 0.617162 7.40014 0.567176C9.19656 0.517191 10.9439 1.15696 12.2834 2.35508C13.6228 3.55321 14.4527 5.21874 14.6025 7.0096C14.7523 8.80047 14.2107 10.5807 13.0889 11.9847L18.4597 17.3545C18.5365 17.426 18.598 17.5123 18.6407 17.6081C18.6834 17.704 18.7064 17.8074 18.7083 17.9123C18.7101 18.0172 18.6908 18.1214 18.6515 18.2187C18.6122 18.316 18.5537 18.4043 18.4796 18.4785C18.4054 18.5527 18.317 18.6112 18.2197 18.6505C18.1224 18.6898 18.0182 18.7091 17.9133 18.7072C17.8084 18.7054 17.705 18.6824 17.6092 18.6397C17.5133 18.597 17.4271 18.5354 17.3556 18.4587L11.9847 13.0889ZM3.72847 11.4618C2.96393 10.6972 2.44321 9.72308 2.23213 8.66261C2.02105 7.60213 2.12907 6.50288 2.54255 5.50378C2.95602 4.50468 3.6564 3.65058 4.55515 3.04941C5.45391 2.44824 6.5107 2.12699 7.59198 2.12627C8.67326 2.12554 9.73049 2.44536 10.6301 3.04532C11.5296 3.64528 12.2311 4.49844 12.646 5.49698C13.0608 6.49553 13.1703 7.59463 12.9606 8.65539C12.751 9.71615 12.2316 10.6909 11.4681 11.4566L11.4628 11.4618L11.4576 11.466C10.4317 12.4895 9.04141 13.064 7.59219 13.0632C6.14297 13.0625 4.75331 12.4865 3.72847 11.4618Z"
      fill="#4A488E"
    />
  </svg>
);

export const NewLeadSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      className="stroke-current text-[#4A488E] dark:text-white"
      d="M17.7087 10.5002H7.41283M3.77866 10.5002H2.29199M3.77866 10.5002C3.77866 10.0184 3.97006 9.55635 4.31075 9.21566C4.65144 8.87497 5.11352 8.68357 5.59533 8.68357C6.07714 8.68357 6.53921 8.87497 6.8799 9.21566C7.22059 9.55635 7.41199 10.0184 7.41199 10.5002C7.41199 10.982 7.22059 11.4441 6.8799 11.7848C6.53921 12.1255 6.07714 12.3169 5.59533 12.3169C5.11352 12.3169 4.65144 12.1255 4.31075 11.7848C3.97006 11.4441 3.77866 10.982 3.77866 10.5002ZM17.7087 16.0061H12.9187M12.9187 16.0061C12.9187 16.488 12.7268 16.9506 12.386 17.2914C12.0453 17.6321 11.5831 17.8236 11.1012 17.8236C10.6193 17.8236 10.1573 17.6313 9.81658 17.2906C9.47589 16.95 9.28449 16.4879 9.28449 16.0061M12.9187 16.0061C12.9187 15.5241 12.7268 15.0624 12.386 14.7216C12.0453 14.3808 11.5831 14.1894 11.1012 14.1894C10.6193 14.1894 10.1573 14.3808 9.81658 14.7215C9.47589 15.0622 9.28449 15.5243 9.28449 16.0061M9.28449 16.0061H2.29199M17.7087 4.9944H15.1212M11.487 4.9944H2.29199M11.487 4.9944C11.487 4.51259 11.6784 4.05051 12.0191 3.70982C12.3598 3.36913 12.8218 3.17773 13.3037 3.17773C13.5422 3.17773 13.7785 3.22472 13.9989 3.31602C14.2193 3.40732 14.4195 3.54113 14.5882 3.70982C14.7569 3.87852 14.8907 4.07878 14.982 4.29919C15.0733 4.5196 15.1203 4.75583 15.1203 4.9944C15.1203 5.23297 15.0733 5.4692 14.982 5.68961C14.8907 5.91002 14.7569 6.11028 14.5882 6.27898C14.4195 6.44767 14.2193 6.58149 13.9989 6.67278C13.7785 6.76408 13.5422 6.81107 13.3037 6.81107C12.8218 6.81107 12.3598 6.61967 12.0191 6.27898C11.6784 5.93829 11.487 5.47621 11.487 4.9944Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
);

export const Pagination1SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clipPath="url(#clip0_252_1223)">
      <path
        d="M8.80546 11.9043C8.90624 11.9043 9.00938 11.8645 9.08672 11.7871C9.24141 11.6324 9.24141 11.3793 9.08672 11.2246L3.79219 5.93008L9.00938 0.71289C9.16406 0.558202 9.16406 0.305078 9.00938 0.15039C8.85469 -0.00429814 8.60156 -0.00429814 8.44688 0.15039L2.94609 5.64883C2.79141 5.80352 2.79141 6.05664 2.94609 6.21133L8.52187 11.7871C8.60156 11.8668 8.70233 11.9043 8.80546 11.9043Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_252_1223">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 12) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Pagination2SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clipPath="url(#clip0_252_1221)">
      <path
        d="M3.19454 0.0957029C3.09376 0.0957029 2.99062 0.135547 2.91328 0.21289C2.75859 0.367578 2.75859 0.620703 2.91328 0.77539L8.20781 6.06992L2.99062 11.2871C2.83594 11.4418 2.83594 11.6949 2.99062 11.8496C3.14531 12.0043 3.39844 12.0043 3.55312 11.8496L9.05391 6.35117C9.20859 6.19648 9.20859 5.94336 9.05391 5.78867L3.47813 0.21289C3.39844 0.133203 3.29767 0.0957029 3.19454 0.0957029Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_252_1221">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(12) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const DeleteSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clip-path="url(#clip0_257_3684)">
      <path
        className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
        d="M11.1149 -0.0292969C9.30755 -0.0292969 7.85555 1.47255 7.85555 3.28824V3.69163H4.19186C3.68324 3.69163 3.26878 4.10609 3.26878 4.6147V5.53778H2.3457V7.38393H4.19186V21.2301C4.19186 22.7486 5.44263 23.9993 6.96109 23.9993H18.038C19.5565 23.9993 20.8072 22.7486 20.8072 21.2301V7.38393H22.6534V5.53778H21.7303V4.6147C21.7303 4.10609 21.3159 3.69163 20.8072 3.69163H17.1435V3.28732C17.1435 1.47255 15.6915 -0.0292969 13.8842 -0.0292969H11.1149ZM11.1149 1.87409H13.8842C14.6263 1.87409 15.2402 2.47963 15.2402 3.28732V3.69163H9.75801V3.28732C9.75801 2.47963 10.3719 1.87501 11.114 1.87501L11.1149 1.87409ZM6.03801 7.38393H10.7688C10.8832 7.39593 10.9968 7.41255 11.1149 7.41255H13.8842C14.0023 7.41255 14.1149 7.39593 14.2303 7.38393H18.9611V21.2301C18.9611 21.7498 18.5577 22.1532 18.038 22.1532H6.96109C6.4414 22.1532 6.03801 21.7498 6.03801 21.2301V7.38393ZM7.88416 9.23009V20.307H9.73032V9.23009H7.88416ZM11.5765 9.23009V20.307H13.4226V9.23009H11.5765ZM15.2688 9.23009V20.307H17.1149V9.23009H15.2688Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_257_3684">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const ExportSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
      d="M13.5 2H5.5C4.4 2 3.5 2.9 3.5 4V20C3.5 21.1 4.4 22 5.5 22H17.5C18.6 22 19.5 21.1 19.5 20V8L13.5 2ZM17.5 20H5.5V4H12.5V9H17.5V20ZM15.5 11V18.1L13.4 16L10.6 18.8L7.8 16L10.6 13.2L8.4 11H15.5Z"
    />
  </svg>
);

export const CloseBarSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
  >
    <path
      className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
      d="M29.724 28.0888C29.8314 28.1963 29.9166 28.3238 29.9747 28.4642C30.0329 28.6045 30.0628 28.755 30.0628 28.9069C30.0628 29.0588 30.0329 29.2093 29.9747 29.3496C29.9166 29.49 29.8314 29.6175 29.724 29.7249C29.6165 29.8324 29.489 29.9176 29.3486 29.9757C29.2083 30.0339 29.0578 30.0638 28.9059 30.0638C28.754 30.0638 28.6036 30.0339 28.4632 29.9757C28.3228 29.9176 28.1953 29.8324 28.0879 29.7249L18.4997 20.1353L8.91146 29.7249C8.6945 29.9419 8.40024 30.0638 8.09342 30.0638C7.78659 30.0638 7.49233 29.9419 7.27537 29.7249C7.05841 29.508 6.93652 29.2137 6.93652 28.9069C6.93652 28.6001 7.05841 28.3058 7.27537 28.0888L16.865 18.5006L7.27537 8.91244C7.05841 8.69548 6.93652 8.40122 6.93652 8.09439C6.93652 7.78757 7.05841 7.49331 7.27537 7.27635C7.49233 7.05939 7.78659 6.9375 8.09342 6.9375C8.40024 6.9375 8.6945 7.05939 8.91146 7.27635L18.4997 16.866L28.0879 7.27635C28.3048 7.05939 28.5991 6.9375 28.9059 6.9375C29.2127 6.9375 29.507 7.05939 29.724 7.27635C29.9409 7.49331 30.0628 7.78757 30.0628 8.09439C30.0628 8.40122 29.9409 8.69548 29.724 8.91244L20.1343 18.5006L29.724 28.0888Z"
    />
  </svg>
);
