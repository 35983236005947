import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import both eye icons
import { motion, AnimatePresence } from "framer-motion"; // Import Framer Motion
import { useResetPasswordMutation } from "../../../redux/api/authApi";
import { notify } from "../../utils/notificationManager";

const FinishPasswordRecovery = () => {
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams(); // Extract token from URL
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!passwordRegex.test(password)) {
      notify("Password must be at least 8 characters long and include uppercase and lowercase letters, numbers, and special characters.", 2, () => {}, () => {});
      return;
    }
    if (password !== verifyPassword) {
      notify("Passwords do not match.", 2, () => {}, () => {});
      return;
    }

    try {
      await resetPassword({ token, body: { password, confirmPassword: verifyPassword } }).unwrap();
      notify("Password changed successfully!", 0, () => navigate("/"), () => {});
    } catch (error) {
      notify("Failed to change password. Please try again.", 2, () => {}, () => {});
    }
  };

  return (
    <>
      <div className="flex justify-center h-screen items-center">
        <div className="w-[472px] h-[667px] border rounded-[14px] shadow-2xl bg-white dark:bg-[#3A3A68] dark:border-[#2B2A49]">
          <div className="border-b dark:border-[#2B2A49]">
            <p className="text-[34px] py-[34px] px-[31px] dark:text-[#EDEDFB]">
              Change Password
            </p>
          </div>
          <div className="pt-[91px]">
            <form onSubmit={submitHandler}>
              <div className="mb-3 flex flex-col px-[34px] gap-[19px] relative">
                <label
                  htmlFor="password_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  New Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password_field"
                  className="form-control rounded-[69px] h-[60px] border-2 px-[10px] text-[16px] dark:text-[#EDEDFB] dark:bg-[#2B2A49] dark:border-none"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 top-12 right-10 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <AnimatePresence>
                    {showPassword ? (
                      <motion.div
                        key="eyeSlash"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEyeSlash className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    ) : (
                      <motion.div
                        key="eye"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEye className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>

              <div className="mb-3 flex flex-col px-[34px] gap-[19px] relative">
                <label
                  htmlFor="verify_password_field"
                  className="form-label text-[20px] dark:text-[#EDEDFB]"
                >
                  Verify Password
                </label>
                <input
                  type={showVerifyPassword ? "text" : "password"}
                  id="verify_password_field"
                  className="form-control rounded-[69px] h-[60px] border-2 px-[10px] text-[16px] dark:text-[#EDEDFB] dark:bg-[#2B2A49] dark:border-none"
                  name="verifyPassword"
                  value={verifyPassword}
                  onChange={(e) => setVerifyPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 top-12 right-10 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                  onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                >
                  <AnimatePresence>
                    {showVerifyPassword ? (
                      <motion.div
                        key="eyeSlash"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEyeSlash className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    ) : (
                      <motion.div
                        key="eye"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <FaEye className="dark:text-[#EDEDFB]" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>

              <div className="flex justify-center py-[30px] w-full">
                <button
                  id="change_password_button"
                  type="submit"
                  className="btn w-100 py-[15px] px-[63px] bg-[#025d52] dark:bg-white dark:text-[#8684CC] rounded-[66px] text-white"
                  disabled={isLoading}
                >
                  {isLoading ? "Changing..." : "Change Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinishPasswordRecovery;
