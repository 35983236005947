import React, { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa'; // Import icons
import { motion } from 'framer-motion'; // Keep framer-motion for smooth accordion animations
import { CategoryAcordionIcon } from "../svgs"; // Assuming this is your dropdown icon

const FuseAccordion = ({ title, content, onEdit, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-[#E8E8FF] dark:bg-[#3a3a68]  rounded-[9px] mb-4 p-4">
      <div className="flex justify-between items-center">
        <div className="text-[#4A488E] dark:text-[#ededfb] text-xl font-semibold">{title}</div>
        <div className="flex items-center gap-4">
          {/* Edit and Delete Icons */}
          <FaEdit
            className="text-[#4A488E] dark:text-white hover:opacity-80 cursor-pointer"
            size={18}
            onClick={onEdit}
            title="Edit Category"
          />
          <FaTrash
            className="text-[#4A488E] dark:text-white hover:opacity-80 cursor-pointer"
            size={18}
            onClick={onDelete}
            title="Delete Category"
          />
          {/* Dropdown Toggle Icon */}
          <motion.div
            className="transform cursor-pointer"
            animate={{ rotate: isOpen ? 90 : 0 }}
            transition={{ duration: 0.3 }}
            onClick={handleToggle}
          >
            <CategoryAcordionIcon />
          </motion.div>
        </div>
      </div>

      {/* Accordion Content */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
          className="mt-2 bg-[#ededfb] text-[#2B2A49] dark:text-[#ededfb] dark:bg-[#2B2A49] p-4 rounded-md"
        >
          {content}
        </motion.div>
      )}
    </div>
  );
};

export default FuseAccordion;
