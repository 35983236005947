import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Breadcrumbs,
  Autocomplete,
  Chip,
  TextField,
  Divider,
  Button,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
} from "@mui/material";

import { Link } from "react-router-dom";
import {
  useGetAccountsQuery,
  useUpdateAccountMutation,
  usePostAccountMutation,
} from "../../redux/api/crm/accountsApi";
import { useGetMeQuery } from "../../redux/api/userApi";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { useGetDealsQuery } from "../../redux/api/crm/dealsApi";
import {
  useGetLeadDetailsQuery,
  useGetLeadsQuery,
} from "../../redux/api/crm/leads";
import { useUpdateDealMutation } from "../../redux/api/crm/dealsApi";
import { useUpdateLeadMutation } from "../../redux/api/crm/leads";
import { useDeleteAccountMutation } from "../../redux/api/crm/accountsApi";
import { useCreateNotificationMutation } from "../../redux/api/notificationApi";
import { useSelector } from "react-redux";
import { notify } from "./../utils/notificationManager";
import NotificationComponent from "../NotificationComponent";
import { io } from "socket.io-client";
import backend_URL from "../../config";
import { SearchSVG } from "./CRMSVG";

const AccordionItem = ({
  account,
  index,
  isOpen,
  toggleAccordion,
  dealsData,
  usersData,
  leadsData,
  accountToBeUpdated,
  setAccountToBeUpdated,
  updateAccount,
  refetchAccounts,
}) => {
  const [inputValues, setInputValues] = useState({
    generalResults: "",
    activities: "",
    services: "",
    expectations: "",
  });
  const [tags, setTags] = useState({
    generalResults: account.generalResults || [],
    activities: account.activities || [],
    services: [],
    expectations: account.expectations || [],
  });
  const [serviceInputValues, setServiceInputValues] = useState({});
  const [serviceTags, setServiceTags] = useState({});
  const [deleteAccount] = useDeleteAccountMutation();
  const [isDeactivated, setIsDeactivated] = useState(
    account?.isActive === true
      ? false
      : account?.isActive === false
      ? true
      : false || false
  );

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsDeactivated(checked);

    setAccountToBeUpdated({
      ...accountToBeUpdated,
      isActive: !checked,
    });
  };
  const handleDeleteAccount = async () => {
    notify(
      "Are you sure you want to delete this account?",
      2,
      async () => {
        await deleteAccount(account._id);

        const receiverIds = usersData.users
          .filter((user) => user.role === "Admin") // Filter users with "Admin" role
          .map((user) => user._id); // Extract the _id field

        await Promise.all(
          receiverIds.map(async (receiverId) => {
            const notification = {
              message: ` deleted the account '${account.nameComp}'`,
              toPage: "/taskslist/My-Tasks",
              senderId: user._id,
              receiverId: receiverId,
            };

            return postNotification(notification).unwrap();
          })
        );
        notify("Account deleted successfully", 0, null, null, true);
        refetchAccounts();
        // Optionally handle any additional actions like updating the UI after deletion
      },
      () => {
        notify("Account deletion canceled", 1, null, null, true);
      }
    );
  };

  const [postNotification] = useCreateNotificationMutation();
  const user = useSelector((state) => state.user.user);

  const handleServiceInputChange = (service, value) => {
    setServiceInputValues((prevValues) => ({
      ...prevValues,
      [service]: value,
    }));
  };

  const handleAddServiceTag = (service) => {
    if (serviceInputValues[service]?.trim() !== "") {
      setAccountToBeUpdated((prevAccount) => ({
        ...prevAccount,
        services: {
          ...prevAccount.services,
          [service]: [
            ...(prevAccount.services[service] || []),
            serviceInputValues[service].trim(),
          ],
        },
      }));
      setServiceInputValues((prevValues) => ({
        ...prevValues,
        [service]: "",
      }));
    }
  };

  const handleDeleteServiceTag = (service, tagToDelete) => {
    setAccountToBeUpdated((prevAccount) => ({
      ...prevAccount,
      services: {
        ...prevAccount.services,
        [service]: prevAccount.services[service].filter(
          (tag) => tag !== tagToDelete
        ),
      },
    }));
  };

  const handleAddTag = (type) => {
    if (inputValues[type].trim() !== "") {
      setAccountToBeUpdated((prevAccount) => ({
        ...prevAccount,
        [type]: [...prevAccount[type], inputValues[type].trim()],
      }));
      setTags((prevTags) => ({
        ...prevTags,
        [type]: [...prevTags[type], inputValues[type].trim()],
      }));
      setInputValues((prevValues) => ({
        ...prevValues,
        [type]: "",
      }));
    }
  };

  const handleDeleteTag = (type, tagToDelete) => {
    setAccountToBeUpdated((prevAccount) => ({
      ...prevAccount,
      [type]: prevAccount[type].filter((tag) => tag !== tagToDelete),
    }));
    setTags((prevTags) => ({
      ...prevTags,
      [type]: prevTags[type].filter((tag) => tag !== tagToDelete),
    }));
  };

  const getDealTitle = (dealId) => {
    const deal = dealsData?.deals?.find((deal) => deal._id === dealId);
    return deal ? deal.dealTitle : "No deal";
  };

  const getUsername = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user ? user.name : "Unknown user";
  };

  const getLeadPhone = (dealId) => {
    const deal = dealsData?.deals?.find((deal) => deal._id === dealId);
    const lead = leadsData?.leads?.find((lead) => lead._id === deal.lead);
    return lead ? lead.phone : "Unknown phone";
  };

  const getLeadEmail = (dealId) => {
    const deal = dealsData?.deals?.find((deal) => deal._id === dealId);
    const lead = leadsData?.leads?.find((lead) => lead._id === deal.lead);
    return lead ? lead.email : "Unknown phone";
  };

  const servicesOptions = ["SEO", "PPC", "Development", "SMM"];

  const updatedServices = Object.keys(accountToBeUpdated.services).reduce(
    (acc, key) => {
      if (accountToBeUpdated.services[key].length > 0) {
        acc[key] = accountToBeUpdated.services[key];
      }
      return acc;
    },
    {}
  );

  const [leadInfo, setLeadInfo] = useState({
    phone: "",
    email: "",
  });

  useEffect(() => {
    if (isOpen && account?.deal) {
      // Ensure account.deal is treated as an array, whether it has one or multiple deals
      const dealIds = Array.isArray(account.deal)
        ? account.deal
        : [account.deal];

      // Get the first deal based on the dealIds array
      const firstDeal = dealsData?.deals?.find(
        (deal) => deal?._id === dealIds[0]
      );

      if (firstDeal) {
        // Find the lead associated with the first deal
        const lead = leadsData?.leads?.find(
          (lead) => lead?._id === firstDeal?.lead
        );
        if (lead) {
          setLeadInfo({ phone: lead.phone, email: lead.email }); // Set lead info
        }
      }
    }
  }, [isOpen, account.deal, dealsData, leadsData]);

  const handleLeadInfoChange = (field, value) => {
    setLeadInfo((prevInfo) => ({
      ...prevInfo,
      [field]: value,
    }));
  };

  const [updateLead] = useUpdateLeadMutation();

  const handleUpdateLeadInfo = async () => {
    try {
      // Ensure accountToBeUpdated.deal is an array (handle both single and multiple deals)
      const dealIds = Array.isArray(accountToBeUpdated.deal)
        ? accountToBeUpdated.deal
        : [accountToBeUpdated.deal];

      // Find all associated deals for the account
      const associatedDeals = dealsData?.deals?.filter((deal) =>
        dealIds.includes(deal._id)
      );

      if (!associatedDeals || associatedDeals.length === 0) {
        notify("No associated deals found", 2, null, null, true);
        return;
      }

      // Loop through each deal and update its lead information
      for (const deal of associatedDeals) {
        const leadId = deal ? deal.lead : null;
        const lead = leadsData.leads.find((l) => l._id === leadId);

        if (lead) {
          const updatedLead = {
            ...lead,
            phone: leadInfo.phone, // Update phone
            email: leadInfo.email, // Update email
          };

          // Update the lead in the system
          await updateLead(updatedLead);
        } else {
          notify(
            `Lead not found for deal ${deal.dealTitle}`,
            2,
            null,
            null,
            true
          );
        }
      }

      // Check if the account manager has changed
      const currentAccountManagerId = account.accountManager;
      const newAccountManagerId = accountToBeUpdated.accountManager;

      if (currentAccountManagerId !== newAccountManagerId) {
        for (const deal of associatedDeals) {
          const notification = {
            message: `Assigned you as account manager to a new account: ${account.nameComp}`,
            toPage: "/CRM/Accounts",
            senderId: user._id,
            receiverId: newAccountManagerId,
          };

          // Send notification to the new account manager
          await postNotification(notification).unwrap();
        }
      }

      notify("Lead information updated successfully", 0, null, null, true);
    } catch (error) {
      notify("Error updating lead info", 2, null, null, true);
    }
  };

  return (
    <div key={account._id} className="">
      <div
        className={`flex justify-between items-center cursor-pointer py-[12px] px-[27px] mr-8 bg-[#FAFAFE] dark:bg-[#3A3A68] ${
          isOpen ? "rounded-t-lg" : "rounded-lg"
        }`}
        onClick={() => toggleAccordion(index, account)}
      >
        <div className="text-[#4A488E] dark:text-[#EDEDFB] text-lg font-normal  ">
          {account.nameComp}
        </div>
        <div>
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_402_2858)">
                <path
                  d="M15.8721 11.7406C15.8721 11.875 15.8189 12.0125 15.7158 12.1156C15.5096 12.3219 15.1721 12.3219 14.9658 12.1156L7.90644 5.05625L0.950193 12.0125C0.743942 12.2187 0.406443 12.2187 0.200193 12.0125C-0.00605749 11.8062 -0.00605746 11.4687 0.200193 11.2625L7.53144 3.92812C7.73769 3.72187 8.07519 3.72187 8.28144 3.92812L15.7158 11.3625C15.8221 11.4687 15.8721 11.6031 15.8721 11.7406Z"
                  fill="#4A488E"
                />
              </g>
              <defs>
                <clipPath id="clip0_402_2858">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 16 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M0.12793 4.25939C0.12793 4.12502 0.181055 3.9875 0.28418 3.88437C0.49043 3.67812 0.82793 3.67812 1.03418 3.88437L8.09356 10.9437L15.0498 3.9875C15.2561 3.78125 15.5936 3.78125 15.7998 3.9875C16.0061 4.19375 16.0061 4.53125 15.7998 4.7375L8.46856 12.0719C8.26231 12.2781 7.92481 12.2781 7.71856 12.0719L0.28418 4.6375C0.17793 4.53125 0.12793 4.39689 0.12793 4.25939Z"
                fill="#4A488E"
              />
            </svg>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="mr-8 py-[35px] px-[29px] rounded-b-lg bg-[#FAFAFE] dark:bg-[#3A3A68]">
          <div className="flex flex-row gap-[57px]">
            <div className="flex flex-col w-[45%] gap-[15px]">
              <div className="flex flex-row items-center justify-between">
                <div className=" text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB] ">
                  Client name
                </div>
                <input
                  className="rounded-[4px] dark:bg-[#2B2A49] bg-[#E9E9FF] dark:text-[#EDEDFB]  text-[#7674C2] border-none h-[56px] w-[300px] p-4"
                  value={accountToBeUpdated.nameComp}
                  onChange={(e) =>
                    setAccountToBeUpdated({
                      ...accountToBeUpdated,
                      nameComp: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB]  ">
                  Phone number
                </div>
                <input
                  className="rounded-[4px]  dark:bg-[#2B2A49] bg-[#E9E9FF] dark:text-[#EDEDFB]  text-[#7674C2] border-none h-[56px] w-[300px] p-4"
                  value={leadInfo.phone}
                  onChange={(e) =>
                    handleLeadInfoChange("phone", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex flex-col w-[45%] gap-[15px]">
              <div className="flex flex-row items-center justify-between">
                <div className=" text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB]  ">
                  Account Manager
                </div>
                <Autocomplete
                  options={usersData?.users || []}
                  getOptionLabel={(option) => option.name || ""}
                  className="border-none rounded-[4px] w-[300px] dark:bg-[#2B2A49] bg-[#E9E9FF] dark:text-[#EDEDFB]"
                  value={
                    usersData?.users?.find(
                      (user) => user._id === accountToBeUpdated.accountManager
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setAccountToBeUpdated({
                      ...accountToBeUpdated,
                      accountManager: newValue ? newValue._id : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select User"
                      className="h-[56px] p-[9px] bg-[#E9E9FF] dark:bg-[#2B2A49] dark:text-[#EDEDFB]"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        sx: { color: "#7674C2" },
                      }}
                    />
                  )}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB]  ">
                  Email
                </div>
                <input
                  className="rounded-[4px] dark:text-[#EDEDFB] dark:bg-[#2B2A49]  bg-[#E9E9FF] text-[#7674C2]  border-none h-[56px] w-[300px] p-4"
                  value={leadInfo.email}
                  onChange={(e) =>
                    handleLeadInfoChange("email", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <Divider className="my-[35px]" sx={{ backgroundColor: "#CAC9F1" }} />
          <div
            className="
           text-zinc-600 text-lg font-normal dark:text-[#EDEDFB]  "
          >
            Footprint overview
          </div>
          <div className="flex flex-row justify-between gap-[57px]">
            <div className="flex flex-col w-full py-[45px] gap-[16px]">
              <div className="text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB]  ">
                General results
              </div>
              <div className="flex items-center">
                <TextField
                  className="w-[300px] bg-[#E9E9FF] dark:bg-[#2B2A49] dark:text-[#EDEDFB] border-none rounded-[4px]"
                  variant="outlined"
                  placeholder="Add a tag"
                  value={inputValues.generalResults}
                  onChange={(e) =>
                    setInputValues({
                      ...inputValues,
                      generalResults: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      backgroundColor: "inherit", // inherit from Tailwind's classes
                      color: "inherit", // inherit from Tailwind's classes
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#D9D9D9",
                      },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddTag("generalResults")}
                  style={{
                    marginLeft: "10px",
                    borderRadius: "4px",
                    height: "50px",
                    backgroundColor: "#7270BB",
                    color: "white",
                    border: "2px solid #7270BB",
                    textTransform: "none",
                  }}
                >
                  Apply
                </Button>
              </div>
              <div className="flex flex-col gap-2 mt-2">
                {tags.generalResults.map((tag, index) => (
                  <Chip
                    deleteIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <path
                          d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                          fill="currentColor" // Use currentColor to inherit text color
                        />
                      </svg>
                    }
                    className="w-fit p-2 bg-[#E9E9FF] dark:bg-[#2B2A49] dark:text-[#EDEDFB] text-[#7674C2]"
                    key={index}
                    label={tag}
                    onDelete={() => handleDeleteTag("generalResults", tag)}
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "inherit", // Inherit the text color
                      },
                    }}
                  />
                ))}
              </div>
              <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal   mt-[70px] mb-[17px]">
                Collaboration details
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB]">
                  Duration
                </div>
                <input
                  readOnly
                  className="rounded-[4px] dark:bg-[#2B2A49] bg-[#E9E9FF] dark:text-[#EDEDFB] text-[#7674C2] border-none h-[56px] w-[300px] p-4"
                  value={accountToBeUpdated.duration}
                  onChange={(e) =>
                    setAccountToBeUpdated({
                      ...accountToBeUpdated,
                      duration: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="flex flex-col w-full py-[45px] gap-[16px]">
              <div className="text-zinc-600 text-[15px] dark:text-[#EDEDFB]  font-normal  ">
                Activities
              </div>
              <div className="flex items-center">
                <TextField
                  className="w-[300px] bg-[#E9E9FF] dark:bg-[#2B2A49] dark:text-[#EDEDFB] border-none rounded-[4px]"
                  variant="outlined"
                  placeholder="Add a tag"
                  value={inputValues.activities}
                  onChange={(e) =>
                    setInputValues({
                      ...inputValues,
                      activities: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      backgroundColor: "inherit", // inherit from Tailwind's classes
                      color: "inherit", // inherit from Tailwind's classes
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#D9D9D9",
                      },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddTag("activities")}
                  style={{
                    marginLeft: "10px",
                    borderRadius: "4px",
                    height: "50px",
                    backgroundColor: "#7270BB",
                    color: "white",
                    border: "2px solid #7270BB",
                    textTransform: "none",
                  }}
                >
                  Apply
                </Button>
              </div>
              <div className="flex flex-col gap-2 mt-2">
                {tags.activities.map((tag, index) => (
                  <Chip
                    deleteIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <path
                          d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                          fill="currentColor" // Use currentColor to inherit text color
                        />
                      </svg>
                    }
                    className="w-fit p-2 bg-[#E9E9FF] dark:bg-[#2B2A49] dark:text-[#EDEDFB] text-[#7674C2]"
                    key={index}
                    label={tag}
                    onDelete={() => handleDeleteTag("activities", tag)}
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "inherit", // Inherit the text color
                      },
                    }}
                  />
                ))}
              </div>
              <div className="text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB]   mt-[70px] mb-[17px]">
                Prices info
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="text-zinc-600 text-[15px] font-normal dark:text-[#EDEDFB]">
                  Price
                </div>
                <input
                  readOnly
                  className="rounded-[4px] dark:bg-[#2B2A49] bg-[#E9E9FF] dark:text-[#EDEDFB] text-[#7674C2] border-none h-[56px] w-[300px] p-4"
                  value={accountToBeUpdated.price}
                  onChange={(e) =>
                    setAccountToBeUpdated({
                      ...accountToBeUpdated,
                      price: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <Divider sx={{ backgroundColor: "#CAC9F1" }} />
          <div className="text-zinc-600 dark:text-[#EDEDFB] text-[15px] font-normal   py-[38px]">
            Services and expectations
          </div>
          <div className="flex flex-row gap-[87px]">
            <div className="flex flex-col w-[87%] ">
              <div className="pb-[38px] text-zinc-600 dark:text-[#EDEDFB] text-[15px] font-normal  ">
                Services
              </div>
              {Object.keys(accountToBeUpdated.services).map((serviceKey) => (
                <div key={serviceKey}>
                  <div className="text-zinc-600 dark:text-[#EDEDFB] text-[13px] font-normal   mt-4 mb-2">
                    {serviceKey}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <TextField
                        className="w-[300px] bg-[#E9E9FF] dark:bg-[#2B2A49] dark:text-[#EDEDFB] border-none rounded-[4px]"
                        variant="outlined"
                        placeholder="Add a tag"
                        value={serviceInputValues[serviceKey] || ""}
                        onChange={(e) =>
                          handleServiceInputChange(serviceKey, e.target.value)
                        }
                        InputProps={{
                          style: {
                            backgroundColor: "inherit", // inherit from Tailwind's classes
                            color: "inherit", // inherit from Tailwind's classes
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#D9D9D9",
                            },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                            },
                            "&:hover fieldset": {
                              border: "none",
                            },
                            "&.Mui-focused fieldset": {
                              border: "none",
                            },
                          },
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={() => handleAddServiceTag(serviceKey)}
                        style={{
                          marginLeft: "10px",
                          borderRadius: "4px",
                          height: "50px",
                          backgroundColor: "#7270BB",
                          color: "white",
                          border: "2px solid #7270BB",
                          textTransform: "none",
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                    <div className="flex flex-col gap-2 mt-2">
                      {(accountToBeUpdated.services[serviceKey] || []).map(
                        (tag, index) => (
                          <Chip
                            deleteIcon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                              >
                                <path
                                  d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                                  fill="currentColor" // Use currentColor to inherit text color
                                />
                              </svg>
                            }
                            className="w-fit p-2 bg-[#E9E9FF] dark:bg-[#2B2A49] text-[#7674C2] dark:text-[#EDEDFB]"
                            key={index}
                            label={tag}
                            onDelete={() =>
                              handleDeleteServiceTag(serviceKey, tag)
                            }
                            sx={{
                              "& .MuiChip-deleteIcon": {
                                color: "inherit !important", // Inherit the text color
                              },
                            }}
                            style={{
                              backgroundColor: "inherit", // inherit from Tailwind's classes
                              color: "inherit", // inherit from Tailwind's classes
                            }}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-col w-full gap-[15px]">
              <div className="pb-[38px] text-zinc-600 dark:text-[#EDEDFB] text-[15px] font-normal  ">
                Client Expectations
              </div>
              <div className="flex flex-row justify-between items-start mb-[28px]">
                <div className="flex flex-col">
                  <div className="flex items-end">
                    <TextField
                      className="w-[400px] h-[100px] bg-[#E9E9FF] dark:bg-[#2B2A49] dark:text-[#EDEDFB] border-none rounded-[4px]"
                      variant="outlined"
                      placeholder="Add a tag"
                      value={inputValues.expectations}
                      onChange={(e) =>
                        setInputValues({
                          ...inputValues,
                          expectations: e.target.value,
                        })
                      }
                      InputProps={{
                        style: {
                          backgroundColor: "inherit", // inherit from Tailwind's classes
                          color: "inherit", // inherit from Tailwind's classes
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#D9D9D9",
                          },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddTag("expectations")}
                      style={{
                        marginLeft: "10px",
                        borderRadius: "4px",
                        height: "50px",
                        backgroundColor: "#7270BB",
                        color: "white",
                        border: "2px solid #7270BB",
                        textTransform: "none",
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    {tags.expectations.map((tag, index) => (
                      <Chip
                        deleteIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11"
                            height="11"
                            viewBox="0 0 11 11"
                            fill="none"
                          >
                            <path
                              d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                              fill="currentColor" // Use currentColor to inherit text color
                            />
                          </svg>
                        }
                        className="w-full flex justify-between h-fit bg-[#E9E9FF] dark:bg-[#2B2A49] text-[#7674C2] dark:text-[#EDEDFB] rounded-[6px] p-[15px]"
                        key={index}
                        label={tag}
                        onDelete={() => handleDeleteTag("expectations", tag)}
                        sx={{
                          "& .MuiChip-deleteIcon": {
                            color: "inherit !important", // Inherit the text color
                          },
                        }}
                        style={{
                          backgroundColor: "inherit", // inherit from Tailwind's classes
                          color: "inherit", // inherit from Tailwind's classes
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>{" "}
              {user?.role === "Admin" ? (
                <div className="text-black dark:text-white">
                  Deactivate Account?{" "}
                  <Checkbox
                    checked={isDeactivated}
                    onChange={handleCheckboxChange}
                  />{" "}
                </div>
              ) : (
                ""
              )}
              <div className="flex flex-row items-center self-end gap-[15px]">
                {user?.role === "Admin" ? (
                  <Button
                    variant="contained"
                    onClick={handleDeleteAccount}
                    style={{
                      borderRadius: "4px",
                      height: "50px",
                      backgroundColor: "#7270BB",
                      color: "white",
                      border: "2px solid #7270BB",
                      textTransform: "none",
                    }}
                  >
                    Delete Account
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  variant="contained"
                  onClick={() => {
                    updateAccount();

                    handleUpdateLeadInfo();
                  }}
                  style={{
                    borderRadius: "4px",
                    height: "50px",
                    backgroundColor: "#7270BB",
                    color: "white",
                    border: "2px solid #7270BB",
                    textTransform: "none",
                  }}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const Accounts = () => {
  const { dealId } = useParams();
  const { data: accountsData, refetch: refetchAccounts } =
    useGetAccountsQuery();
  const [searchParams] = useSearchParams(); // Get query parameters
  const deactivated = searchParams.get("deactivated");
  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
    refetch: refetchMe,
  } = useGetMeQuery();

  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
    refetch: refetchUsers,
  } = useGetAllUsersQuery();

  const { data: dealsData, refetch: refetchDeals } = useGetDealsQuery();

  const { data: leadsData, refetch: refetchLeads } = useGetLeadsQuery();

  const {
    data: leadData,
    error: leadError,
    isLoading: leadLoading,
    refetch: refetchLead,
  } = useGetLeadDetailsQuery();
  const [postNotification] = useCreateNotificationMutation();
  const user = useSelector((state) => state.user.user);
  const [openAccordions, setOpenAccordions] = useState({});
  const [accountToBeUpdated, setAccountToBeUpdated] = useState({
    nameComp: "",
    priority: "High",
    industry: "",
    deal: "",
    accountManager: "",
    generalResults: [],
    activities: [],
    services: {}, // Initialize as an empty object
    duration: "",
    price: "",
    expectations: [],
  });
  const [updateAccount] = useUpdateAccountMutation();
  const [updateDeal] = useUpdateDealMutation();
  const [postAccount] = usePostAccountMutation();
  const [serviceInputValues, setServiceInputValues] = useState({});
  const searchInputRef = useRef(null); // Use ref to access the input value directly
  const [searchQuery, setSearchQuery] = useState(""); // Still needed for debounce

  // Trigger search on Enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchQuery(searchInputRef.current?.value || ""); // Set the query from the uncontrolled input
    }
  };

  // Trigger search on clicking the SearchSVG
  const handleSearchClick = () => {
    setSearchQuery(searchInputRef.current?.value || ""); // Set the query from the uncontrolled input
  };

  const filteredAccounts = accountsData?.accounts
    .slice() // Create a shallow copy of the array
    .reverse() // Reverse the array (if still needed)
    .filter((account) => {
      if (deactivated === "true") {
        return account.isActive === false; // Show only deactivated accounts
      } else {
        return account.isActive !== false; // Show only active accounts
      }
    })
    .filter((account) =>
      account.nameComp.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) =>
      a.nameComp.toLowerCase().localeCompare(b.nameComp.toLowerCase())
    );

  const [openDialog, setOpenDialog] = useState(false);
  const [newAccount, setNewAccount] = useState({
    nameComp: "",
    priority: "High",
    industry: "",
    deal: "",
    accountManager: "",
    generalResults: [],
    activities: [],
    services: {
      SEO: [],
      PPC: [],
      SMM: [],
      Development: [],
    },
    duration: "",
    price: "",
    expectations: [],
  });

  const [temporaryInputs, setTemporaryInputs] = useState({
    generalResults: "",
    activities: "",
    expectations: "",
  });
  const dealToAccount = dealsData?.deals?.find((deal) => deal._id === dealId);
  const accountOfDeal = accountsData?.accounts?.find((a) => a.deal === dealId);

  useEffect(() => {
    const socket = io(backend_URL);
    window.socket = socket;

    // Check if data is present before refetching
    socket.on("updateAccounts", () => {
      if (accountsData) {
        refetchAccounts();
      }
    });

    socket.on("updateMe", () => {
      if (meData) {
        refetchMe();
      }
    });

    socket.on("updateUsers", () => {
      if (usersData) {
        refetchUsers();
      }
    });

    socket.on("updateDeals", () => {
      if (dealsData) {
        refetchDeals();
      }
    });

    socket.on("updateLeads", () => {
      if (leadsData) {
        refetchLeads();
      }
    });

    socket.on("updateLeadDetails", () => {
      if (leadData) {
        refetchLead();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [
    refetchAccounts,
    refetchMe,
    refetchUsers,
    refetchDeals,
    refetchLeads,
    refetchLead,
  ]);

  useEffect(() => {
    if (dealId && dealToAccount && !accountOfDeal) {
      setOpenDialog(true);
      setNewAccount({
        nameComp: dealToAccount.dealTitle,
        priority: "High",
        industry: "",
        deal: dealToAccount._id,
        accountManager: "",
        generalResults: [],
        activities: [],
        services: {
          SEO: [],
          PPC: [],
          SMM: [],
          Development: [],
        },
        duration: "",
        price: "",
        expectations: [],
      });
      setSelectedDeal([dealToAccount]);
      // clientNameRef?.current?.value = dealToAccount.dealTitle;
    }
  }, [dealId, dealToAccount, accountOfDeal]);

  const getUserId = (username) => {
    const user = usersData?.users?.find((user) => user.name === username);
    return user ? user._id : null;
  };

  const [selectedDeal, setSelectedDeal] = useState([]); // Initialize as an empty array
  const [selectedAccountManager, setSelectedAccountManager] = useState(""); // Track selected account manager ID

  // Uncontrolled input references
  const clientNameRef = useRef(null);
  const generalResultsRef = useRef(null);
  const activitiesRef = useRef(null);
  const expectationsRef = useRef(null);

  const handleAddTag = (ref, field) => {
    const value = ref.current.value.trim();
    if (value !== "") {
      setNewAccount((prevAccount) => ({
        ...prevAccount,
        [field]: [...(prevAccount[field] || []), value],
      }));
      ref.current.value = ""; // Clear input after adding tag
    }
  };

  const handleDeleteTag = (field, tagToDelete) => {
    setNewAccount((prevAccount) => ({
      ...prevAccount,
      [field]: prevAccount[field].filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleServiceInputChange = (serviceKey, value) => {
    setServiceInputValues((prevValues) => ({
      ...prevValues,
      [serviceKey]: value,
    }));
  };

  const handleAddServiceTag = (serviceKey) => {
    const value = serviceInputValues[serviceKey].trim();
    if (value !== "") {
      setNewAccount((prevAccount) => ({
        ...prevAccount,
        services: {
          ...prevAccount.services,
          [serviceKey]: [...(prevAccount.services[serviceKey] || []), value],
        },
      }));
      setServiceInputValues((prevValues) => ({
        ...prevValues,
        [serviceKey]: "",
      }));
    }
  };

  const handleDeleteServiceTag = (serviceKey, tagToDelete) => {
    setNewAccount((prevAccount) => ({
      ...prevAccount,
      services: {
        ...prevAccount.services,
        [serviceKey]: prevAccount.services[serviceKey].filter(
          (tag) => tag !== tagToDelete
        ),
      },
    }));
  };

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  // const handleNewAccountChange = (field, value) => {
  //   setNewAccount((prevAccount) => ({
  //     ...prevAccount,
  //     [field]: value,
  //   }));
  // };

  const handleNewAccountChange = (field, value) => {
    setNewAccount((prevAccount) => ({
      ...prevAccount,
      [field]: value,
    }));
  };

  const handleTemporaryInputChange = (field, value) => {
    setTemporaryInputs((prevInputs) => ({
      ...prevInputs,
      [field]: value,
    }));
  };

  const handleCreateAccount = async () => {
    // Check if selectedDeal is a single deal or an array of deals
    const dealData = Array.isArray(selectedDeal)
      ? selectedDeal
      : [selectedDeal];

    const accountData = {
      nameComp: clientNameRef.current.value,
      accountManager: selectedAccountManager, // Use the selected account manager ID
      deal: dealData, // Use the selected deal ID
      generalResults: newAccount.generalResults,
      activities: newAccount.activities,
      services: newAccount.services,
      expectations: newAccount.expectations,
    };
    const userId = accountData.accountManager;
    if (userId) {
      const accountWithUserId = { ...accountData, accountManager: userId };

      try {
        await postAccount(accountWithUserId);

        const notification = {
          message: `Assigned you as account manager to a new account: ${accountData.nameComp}`,
          toPage: "/CRM/Accounts",
          senderId: user._id,
          receiverId: userId,
        };

        await postNotification(notification).unwrap();

        const receiverIds = usersData.users
          .filter((user) => user.role === "Admin") // Filter users with "Admin" role
          .map((user) => user._id); // Extract the _id field

        await Promise.all(
          receiverIds.map(async (receiverId) => {
            const notification = {
              message: ` created a new account '${accountData.nameComp}'`,
              toPage: "/taskslist/My-Tasks",
              senderId: user._id,
              receiverId: receiverId,
            };

            return postNotification(notification).unwrap();
          })
        );
        refetchAccounts();
        refetchDeals();
        refetchLeads();
        handleDialogClose();
        notify("Account created successfully", 0, null, null, true);
        setNewAccount({
          nameComp: "",
          priority: "High",
          industry: "",
          deal: "",
          accountManager: "",
          generalResults: [],
          activities: [],
          services: {
            SEO: [],
            PPC: [],
            SMM: [],
            Development: [],
          },
          duration: "",
          price: "",
          expectations: [],
        });
      } catch (error) {
        notify(
          "Failed to create account or send notification",
          2,
          null,
          null,
          true
        );
      }
    } else {
      notify("User not found", 2, null, null, true);
    }
  };

  const toggleAccordion = (index, account) => {
    setOpenAccordions({ [index]: !openAccordions[index] });
    if (!openAccordions[index]) {
      // Handle both single deal ID or array of deal IDs
      const dealIds = Array.isArray(account.deal)
        ? account.deal
        : [account.deal];

      // Get the relevant deals from the dealsData
      const linkedDeals = dealsData?.deals
        ?.filter((deal) => dealIds.includes(deal._id))
        .filter((d) => d.stage === "Won");

      // Calculate duration range by finding the earliest start date and latest end date
      const duration = linkedDeals?.length
        ? `${
            new Date(
              Math.min(
                ...linkedDeals.map((deal) => new Date(deal.closeDate.startDate))
              )
            )
              .toISOString()
              .split("T")[0]
          } - ${
            new Date(
              Math.max(
                ...linkedDeals.map((deal) => new Date(deal.closeDate.endDate))
              )
            )
              .toISOString()
              .split("T")[0]
          }`
        : "";

      // Calculate total price by summing up all deal values
      const totalPrice = linkedDeals?.reduce(
        (acc, deal) => acc + (deal.dealValue || 0),
        0
      );

      setAccountToBeUpdated({
        _id: account._id,
        nameComp: account.nameComp,
        priority: account.priority,
        industry: account.industry,
        deal: account.deal, // Can be single deal ID or array of deal IDs
        accountManager: account.accountManager,
        generalResults: account.generalResults,
        activities: account.activities,
        services: account.services,
        isActive:
          account?.isActive === true
            ? true
            : account?.isActive === false
            ? false
            : true || false,
        duration: duration, // Set the calculated duration
        price: totalPrice > 0 ? totalPrice : "", // Set the total price, or empty string if no price
        expectations: account.expectations,
        industry: "Technology", // Set industry to "Technology" as before
      });
    }
  };

  const handleUpdate = async () => {
    const updatedServices = Object.keys(accountToBeUpdated.services).reduce(
      (acc, key) => {
        if (accountToBeUpdated.services[key].length > 0) {
          acc[key] = accountToBeUpdated.services[key];
        }
        return acc;
      },
      {}
    );
    const deal = dealsData?.deals?.find(
      (deal) => deal._id === accountToBeUpdated.deal
    );
    const changedDeal = {
      ...accountToBeUpdated,
      services: Object.keys(updatedServices),
    };
    if (changedDeal) {
      await updateDeal(changedDeal);
      refetchAccounts();
      refetchDeals();
      refetchLeads();
      await updateAccount(accountToBeUpdated);

      const receiverIds = usersData.users
        .filter((user) => user.role === "Admin") // Filter users with "Admin" role
        .map((user) => user._id); // Extract the _id field

      await Promise.all(
        receiverIds.map(async (receiverId) => {
          const notification = {
            message: ` updated the account '${accountToBeUpdated.nameComp}'`,
            toPage: "/taskslist/My-Tasks",
            senderId: user._id,
            receiverId: receiverId,
          };

          return postNotification(notification).unwrap();
        })
      );
      notify("Account updated successfully", 0, null, null, true);
    }
  };

  // const handleAddTag = (type) => {
  //   if (temporaryInputs[type].trim() !== "") {
  //     setNewAccount((prevAccount) => ({
  //       ...prevAccount,
  //       [type]: [...prevAccount[type], temporaryInputs[type].trim()],
  //     }));
  //     setTemporaryInputs((prevInputs) => ({
  //       ...prevInputs,
  //       [type]: "",
  //     }));
  //   }
  // };

  // const handleDeleteTag = (type, tagToDelete, setAccount) => {
  //   setAccount((prevAccount) => ({
  //     ...prevAccount,
  //     [type]: prevAccount[type].filter((tag) => tag !== tagToDelete),
  //   }));
  // };

  // const handleServiceInputChange = (service, value, setServiceInputValues) => {
  //   setServiceInputValues((prevValues) => ({
  //     ...prevValues,
  //     [service]: value,
  //   }));
  // };

  // const handleAddServiceTag = (
  //   service,
  //   value,
  //   setAccount,
  //   setServiceInputValues
  // ) => {
  //   if (value.trim() !== "") {
  //     setAccount((prevAccount) => ({
  //       ...prevAccount,
  //       services: {
  //         ...prevAccount.services,
  //         [service]: [...(prevAccount.services[service] || []), value.trim()],
  //       },
  //     }));
  //     setServiceInputValues((prevValues) => ({
  //       ...prevValues,
  //       [service]: "",
  //     }));
  //   }
  // };

  // const handleDeleteServiceTag = (service, tagToDelete, setAccount) => {
  //   setAccount((prevAccount) => ({
  //     ...prevAccount,
  //     services: {
  //       ...prevAccount.services,
  //       [service]: prevAccount.services[service].filter(
  //         (tag) => tag !== tagToDelete
  //       ),
  //     },
  //   }));
  // };

  // if (accountsLoading) return <div>Loading...</div>;
  // if (accountsError)
  //   return <div>Error fetching accounts: {accountsError.message}</div>;

  const associatedDeals = new Set(
    accountsData?.accounts.map((acc) => acc.deal)
  );
  const unassociatedDeals = dealsData?.deals.filter(
    (deal) => !associatedDeals.has(deal._id)
  );

  // console.log(unassociatedDeals);
  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch ">
      <div className="w-full ">
        <div className="p-4 pl-0 flex items-center flex-wrap justify-between">
          <ul className="flex items-center ">
            {/* <li className="inline-flex items-center"> */}
            {/* <Link
                to="/dashboard"
                className="text-gray-600 hover:text-blue-500"
              >
                <svg
                  className="w-5 h-auto fill-current mx-2 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                </svg>
              </Link>

              <span className="mx-4 h-auto text-gray-400 font-medium">/</span>
            </li> */}

            <Breadcrumbs
              aria-label="breadcrumb"
              className="dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                color="inherit"
                to="/dashboard"
                className="dark:text-[#EDEDFB]"
              >
                <span
                  style={{ color: "#4A488E" }}
                  className="dark:text-[#EDEDFB]"
                >
                  {" "}
                  Fuse Digital{" "}
                </span>
              </Link>
              <Link underline="hover" color="inherit" to={`/CRM/Accounts`}>
                <span
                  style={{ color: "#4A488E" }}
                  className="dark:text-[#EDEDFB]"
                >
                  {" "}
                  CRM / <b>Accounts</b>{" "}
                </span>
              </Link>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>
        <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal   mt-[40px] mb-[53px]">
          Accounts
        </div>
      </div>

      <div className="flex items-center justify-between mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="w-[411px] ml-1 h-[55px] bg-[#E7E7F9] dark:bg-[#33335A] dark:placeholder-[#8C8CB0] dark:text-[#8C8CB0] border-none rounded-[52px] p-5 relative placeholder-[#979BE2]"
            ref={searchInputRef} // Attach ref to the input for uncontrolled behavior
            onKeyDown={handleKeyDown} // Trigger search on Enter key press
          />
          <SearchSVG onClick={handleSearchClick} />{" "}
          {/* Trigger search on SVG click */}
        </div>
        {user?.role === "Admin" ? (
          <Link
            to={
              deactivated ? "/CRM/Accounts" : "/CRM/Accounts?deactivated=true"
            }
          >
            <button className="px-[10px] py-[8px] bg-[#FAFAFE] dark:bg-[#7373ca] text-[#4A488E] dark:text-white font-medium text-[11px] rounded-[20px]">
              {deactivated ? "Active accounts" : "Inactive accounts"}
            </button>
          </Link>
        ) : (
          ""
        )}
        <div className="w-fit h-[34px] justify-start items-center gap-[52px] inline-flex">
          <div
            className="w-fit h-[38px] px-[15px] py-2.5 bg-violet-100 rounded-md border border-indigo-500 justify-center items-center gap-2.5 inline-flex"
            onClick={handleDialogOpen}
          >
            <div className="text-[#4A488E] text-[15px] font-normal  ">
              + New account
            </div>
          </div>
          {/* <div className="justify-center items-center gap-2.5 flex">
            <div className="w-5 h-5 relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M17.7087 10.5002H7.41283M3.77866 10.5002H2.29199M3.77866 10.5002C3.77866 10.0184 3.97006 9.55635 4.31075 9.21566C4.65144 8.87497 5.11352 8.68357 5.59533 8.68357C6.07714 8.68357 6.53921 8.87497 6.8799 9.21566C7.22059 9.55635 7.41199 10.0184 7.41199 10.5002C7.41199 10.982 7.22059 11.4441 6.8799 11.7848C6.53921 12.1255 6.07714 12.3169 5.59533 12.3169C5.11352 12.3169 4.65144 12.1255 4.31075 11.7848C3.97006 11.4441 3.77866 10.982 3.77866 10.5002ZM17.7087 16.0061H12.9187M12.9187 16.0061C12.9187 16.488 12.7268 16.9506 12.386 17.2914C12.0453 17.6321 11.5831 17.8236 11.1012 17.8236C10.6193 17.8236 10.1573 17.6313 9.81658 17.2906C9.47589 16.95 9.28449 16.4879 9.28449 16.0061M12.9187 16.0061C12.9187 15.5241 12.7268 15.0624 12.386 14.7216C12.0453 14.3808 11.5831 14.1894 11.1012 14.1894C10.6193 14.1894 10.1573 14.3808 9.81658 14.7215C9.47589 15.0622 9.28449 15.5243 9.28449 16.0061M9.28449 16.0061H2.29199M17.7087 4.9944H15.1212M11.487 4.9944H2.29199M11.487 4.9944C11.487 4.51259 11.6784 4.05051 12.0191 3.70982C12.3598 3.36913 12.8218 3.17773 13.3037 3.17773C13.5422 3.17773 13.7785 3.22472 13.9989 3.31602C14.2193 3.40732 14.4195 3.54113 14.5882 3.70982C14.7569 3.87852 14.8907 4.07878 14.982 4.29919C15.0733 4.5196 15.1203 4.75583 15.1203 4.9944C15.1203 5.23297 15.0733 5.4692 14.982 5.68961C14.8907 5.91002 14.7569 6.11028 14.5882 6.27898C14.4195 6.44767 14.2193 6.58149 13.9989 6.67278C13.7785 6.76408 13.5422 6.81107 13.3037 6.81107C12.8218 6.81107 12.3598 6.61967 12.0191 6.27898C11.6784 5.93829 11.487 5.47621 11.487 4.9944Z"
                  stroke="#4A488E"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="text-indigo-800 text-[15px] font-normal  ">
              Filter
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col gap-[15px] mt-[35px] h-[600px] w-full md:mb-40 overflow-y-auto scrolling-touch ">
        {filteredAccounts.map((account, index) => (
          <AccordionItem
            refetchAccounts={refetchAccounts}
            key={account._id}
            account={account}
            index={index}
            isOpen={openAccordions[index]}
            toggleAccordion={toggleAccordion}
            dealsData={dealsData}
            usersData={usersData}
            leadsData={leadsData}
            accountToBeUpdated={accountToBeUpdated}
            setAccountToBeUpdated={setAccountToBeUpdated}
            updateAccount={handleUpdate}
          />
        ))}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "inherit", // inherit from Tailwind's classes
            borderRadius: "14px",
          },
          className: "bg-[#FAFAFE] dark:bg-[#2B2A49]", // Use Tailwind classes for background color
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#2B2A49",
            boxShadow: "none",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "rgba(0, 0, 0, 0.75)", // Example dark mode backdrop color
            },
          },
          className:
            "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]", // Tailwind dark mode classes
        }}
      >
        <DialogTitle sx={{ color: "#4A488E" }} className="dark:text-[#EDEDFB]">
          Add New Account
        </DialogTitle>
        <DialogContent>
          <div className="flex flex-row gap-[57px]">
            <div className="flex flex-col w-[45%] gap-[15px]">
              <div className="flex flex-row items-center justify-between">
                <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal  ">
                  Client name
                </div>
                <input
                  className="bg-[#E9E9FF] h-[56px]  dark:text-[#EDEDFB] dark:bg-[#3A3A68] text-[#4A488E] border-none rounded-[4px] w-[300px]"
                  ref={clientNameRef}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal">
                  Deal
                </div>
                <FormControl className="w-fit rounded-[4px] h-[56px]">
                  <Select
                    className="bg-[#E9E9FF]  dark:bg-[#3A3A68] text-[#7674C2] dark:text-[#EDEDFB] border-none w-[300px]"
                    multiple // Enable multi-selection
                    value={selectedDeal} // Track selected deals
                    onChange={(e) => setSelectedDeal(e.target.value)} // Track selected deal IDs
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) =>
                            dealsData?.deals
                              ?.filter((d) => d.stage === "Won")
                              .find((deal) => deal._id === id)?.dealTitle
                        )
                        .join(", ")
                    } // Render selected deals as a comma-separated string
                  >
                    {dealsData?.deals
                      ?.filter((deal) => {
                        // Filter out deals that are already associated with an account
                        const isLinkedToAccount = accountsData?.accounts?.some(
                          (account) => account.deal.includes(deal._id)
                        );
                        return deal.stage === "Won" && !isLinkedToAccount;
                      })
                      .map((deal) => (
                        <MenuItem key={deal._id} value={deal._id}>
                          <Checkbox
                            checked={selectedDeal.indexOf(deal._id) > -1}
                          />
                          {deal.dealTitle}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="flex flex-col w-[45%] gap-[15px]">
              <div className="flex flex-row items-center justify-between">
                <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal">
                  Account Manager
                </div>
                <Autocomplete
                  className="bg-[#A3A3A3] dark:bg-[#3A3A68] rounded-[4px] w-[300px] dark:text-[#EDEDFB]"
                  value={
                    usersData?.users?.find(
                      (user) => user._id === newAccount.accountManager
                    ) || null
                  }
                  options={usersData?.users || []}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={(event, newValue) => {
                    setSelectedAccountManager(newValue ? newValue._id : ""); // Track selected account manager ID
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        className: "dark:text-[#EDEDFB]",
                      }}
                      variant="outlined"
                      className="w-[300px]  bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] dark:text-[#EDEDFB]"
                      placeholder="Select User"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <Divider className="my-[35px]" sx={{ backgroundColor: "#CAC9F1" }} />
          <div className="text-zinc-600 dark:text-[#EDEDFB]  text-lg font-normal">
            Footprint overview
          </div>
          <div className="flex flex-row justify-between gap-[57px]">
            <div className="flex flex-col w-full py-[45px] gap-[16px]">
              <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal">
                General results
              </div>
              <div className="flex items-center">
                <input
                  className="w-[300px] bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] border-none rounded-[4px]"
                  ref={generalResultsRef}
                />
                <Button
                  variant="contained"
                  onClick={() =>
                    handleAddTag(generalResultsRef, "generalResults")
                  }
                  style={{
                    marginLeft: "10px",
                    borderRadius: "4px",
                    height: "50px",
                    backgroundColor: "#7270BB",
                    color: "white",
                  }}
                >
                  Apply
                </Button>
              </div>

              <div className="flex flex-col gap-2 mt-2">
                {newAccount.generalResults.map((tag, index) => (
                  <Chip
                    deleteIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <path
                          d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                          fill="currentColor" // Use currentColor to inherit text color
                        />
                      </svg>
                    }
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "inherit !important", // Inherit the text color
                      },
                    }}
                    className="w-fit bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] dark:text-[#EDEDFB] border-none rounded-[4px]"
                    style={{
                      backgroundColor: "inherit", // inherit from Tailwind's classes
                      color: "inherit", // inherit from Tailwind's classes
                    }}
                    key={index}
                    label={tag}
                    onDelete={() => handleDeleteTag("generalResults", tag)}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col w-full py-[45px] gap-[16px]">
              <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal">
                Activities
              </div>
              <div className="flex items-center">
                <input
                  className="w-[300px] bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] border-none rounded-[4px]"
                  ref={activitiesRef}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddTag(activitiesRef, "activities")}
                  style={{
                    marginLeft: "10px",
                    borderRadius: "4px",
                    height: "50px",
                    backgroundColor: "#7270BB",
                    color: "white",
                  }}
                >
                  Apply
                </Button>
              </div>

              <div className="flex flex-col gap-2 mt-2">
                {newAccount.activities.map((tag, index) => (
                  <Chip
                    deleteIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <path
                          d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                          fill="currentColor" // Use currentColor to inherit text color
                        />
                      </svg>
                    }
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "inherit !important", // Inherit the text color
                      },
                    }}
                    className="w-fit bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] dark:text-[#EDEDFB] border-none rounded-[4px]"
                    style={{
                      backgroundColor: "inherit", // inherit from Tailwind's classes
                      color: "inherit", // inherit from Tailwind's classes
                    }}
                    key={index}
                    label={tag}
                    onDelete={() => handleDeleteTag("activities", tag)}
                  />
                ))}
              </div>
            </div>
          </div>

          <Divider sx={{ backgroundColor: "#CAC9F1" }} />
          <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal py-[38px]">
            Services and expectations
          </div>
          <div className="flex flex-row gap-[87px]">
            <div className="flex flex-col w-[87%]">
              <div className="pb-[38px] text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal">
                Services
              </div>
              {Object.keys(newAccount.services).map((serviceKey) => (
                <div key={serviceKey}>
                  <div className="text-zinc-600 dark:text-[#EDEDFB]  text-[13px] font-normal mt-4">
                    {serviceKey}
                  </div>
                  <div className="flex items-center">
                    <input
                      className="w-[300px] bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] border-none rounded-[4px]"
                      value={serviceInputValues[serviceKey] || ""}
                      onChange={(e) =>
                        handleServiceInputChange(serviceKey, e.target.value)
                      }
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAddServiceTag(serviceKey)}
                      style={{
                        marginLeft: "10px",
                        borderRadius: "4px",
                        height: "50px",
                        backgroundColor: "#7270BB",
                        color: "white",
                        border: "2px solid #7270BB",
                        textTransform: "none",
                      }}
                    >
                      Apply
                    </Button>
                  </div>

                  <div className="flex flex-col gap-2 mt-2">
                    {(newAccount.services[serviceKey] || []).map(
                      (tag, index) => (
                        <Chip
                          deleteIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                            >
                              <path
                                d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                                fill="currentColor" // Use currentColor to inherit text color
                              />
                            </svg>
                          }
                          sx={{
                            "& .MuiChip-deleteIcon": {
                              color: "inherit !important", // Inherit the text color
                            },
                          }}
                          className="w-fit bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] dark:text-[#EDEDFB] border-none rounded-[4px]"
                          style={{
                            backgroundColor: "inherit", // inherit from Tailwind's classes
                            color: "inherit", // inherit from Tailwind's classes
                          }}
                          key={index}
                          label={tag}
                          onDelete={() =>
                            handleDeleteServiceTag(serviceKey, tag)
                          }
                        />
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-col w-full gap-[15px]">
              <div className="pb-[38px] text-zinc-600 dark:text-[#EDEDFB]  text-[15px] font-normal">
                Client Expectations
              </div>
              <div className="flex items-end">
                <input
                  className="w-[400px] h-[100px] bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] border-none rounded-[4px]"
                  ref={expectationsRef}
                />
                <Button
                  variant="contained"
                  onClick={() => handleAddTag(expectationsRef, "expectations")}
                  style={{
                    marginLeft: "10px",
                    borderRadius: "4px",
                    height: "50px",
                    backgroundColor: "#7270BB",
                    color: "white",
                    border: "2px solid #7270BB",
                    textTransform: "none",
                  }}
                >
                  Apply
                </Button>
              </div>

              <div className="flex flex-col gap-2 mt-2">
                {newAccount.expectations.map((tag, index) => (
                  <Chip
                    deleteIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                      >
                        <path
                          d="M8.83715 8.35074C8.86908 8.38268 8.89442 8.42059 8.9117 8.46232C8.92899 8.50405 8.93788 8.54878 8.93788 8.59394C8.93788 8.63911 8.92899 8.68383 8.9117 8.72556C8.89442 8.76729 8.86908 8.80521 8.83715 8.83715C8.80521 8.86908 8.76729 8.89442 8.72556 8.9117C8.68383 8.92899 8.63911 8.93788 8.59394 8.93788C8.54878 8.93788 8.50405 8.92899 8.46232 8.9117C8.42059 8.89442 8.38268 8.86908 8.35074 8.83715L5.50019 5.98617L2.64964 8.83715C2.58514 8.90165 2.49766 8.93788 2.40644 8.93788C2.31522 8.93788 2.22774 8.90165 2.16324 8.83715C2.09874 8.77264 2.0625 8.68516 2.0625 8.59394C2.0625 8.50272 2.09874 8.41524 2.16324 8.35074L5.01421 5.50019L2.16324 2.64964C2.09874 2.58514 2.0625 2.49766 2.0625 2.40644C2.0625 2.31522 2.09874 2.22774 2.16324 2.16324C2.22774 2.09874 2.31522 2.0625 2.40644 2.0625C2.49766 2.0625 2.58514 2.09874 2.64964 2.16324L5.50019 5.01421L8.35074 2.16324C8.41524 2.09874 8.50272 2.0625 8.59394 2.0625C8.68516 2.0625 8.77264 2.09874 8.83715 2.16324C8.90165 2.22774 8.93788 2.31522 8.93788 2.40644C8.93788 2.49766 8.90165 2.58514 8.83715 2.64964L5.98617 5.50019L8.83715 8.35074Z"
                          fill="currentColor" // Use currentColor to inherit text color
                        />
                      </svg>
                    }
                    sx={{
                      "& .MuiChip-deleteIcon": {
                        color: "inherit !important", // Inherit the text color
                      },
                    }}
                    className="w-fit bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#7674C2] dark:text-[#EDEDFB] border-none rounded-[4px]"
                    style={{
                      backgroundColor: "inherit", // inherit from Tailwind's classes
                      color: "inherit", // inherit from Tailwind's classes
                    }}
                    key={index}
                    label={tag}
                    onDelete={() => handleDeleteTag("expectations", tag)}
                  />
                ))}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              marginLeft: "10px",
              borderRadius: "4px",
              height: "50px",
              color: "#7270BB",
              border: "2px solid #7270BB",
              textTransform: "none",
            }}
            variant="outlined"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: "10px",
              borderRadius: "4px",
              height: "50px",
              backgroundColor: "#7270BB",
              color: "white",
              border: "2px solid #7270BB",
              textTransform: "none",
            }}
            onClick={handleCreateAccount}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Accounts;
