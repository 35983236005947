import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ListSubheader } from "@mui/material";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    className: "bg-[#E6E5FF] dark:bg-[#2B2A49]", // Ensure the dropdown background color is applied
  },
};

const SelectInput = ({
  label,
  items = [],
  selected,
  setSelected,
  getName,
  getColor = () => "#e6e5ff", // Provide a default color function
  customMenuItemClasses = "", // Additional prop for custom classes
  isMultiple = true,
  createTags = [],
  setCreateTags,
}) => {
  const theme = useTheme();
  const [newTag, setNewTag] = useState("");
  const [isCreatingTag, setIsCreatingTag] = useState(false);

  const combinedItems = [...items, ...createTags];
  const renderValue = (selected) => {
    if (isMultiple) {
      if (selected.length === 0) {
        return (
          <em className="text-[#4A488E] dark:text-[#EDEDFB]">Placeholder</em>
        );
      }
      return selected
        .map((id) => {
          const item = combinedItems.find((i) => i._id === id || i.name === id);
          return item ? getName(item) : id;
        })
        .join(", ");
    } else {
      if (selected.length === 0) {
        return (
          <em className="text-[#4A488E] dark:text-[#EDEDFB]">Placeholder</em>
        );
      }
      const item = combinedItems.find(
        (i) => i._id === selected[0] || i.name === selected[0]
      );
      return item ? getName(item) : "";
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  const handleAddTag = () => {
    if (newTag) {
      const newTagItem = { name: newTag, color: "#7E22CE" };
      setCreateTags([...createTags, newTagItem]);
      setSelected([...selected, newTag]);
      setNewTag("");
      setIsCreatingTag(false);
    }
  };
  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };
  return (
    <div className="mb-[20px] flex w-full items-center">
      <FormControl sx={{ width: 400 }}>
        <Select
          multiple={isMultiple}
          displayEmpty
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={renderValue}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
        >
          <ListSubheader className="bg-[#e6e5ff] dark:bg-[#2B2A49]">
            {isCreatingTag && (
              <div className="flex justify-center gap-[5px]  items-center  bg-[#e6e5ff]  dark:text-[#EDEDFB] dark:bg-[#2B2A49]">
                <TextField
                  value={newTag}
                  onKeyDown={handleStopPropagation}
                  onClick={handleStopPropagation}
                  onMouseDown={handleStopPropagation}
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="New tag name"
                  className=" bg-[#e6e5ff] dark:text-[#EDEDFB] dark:bg-[#2B2A49]"
                  InputProps={{
                    className: "dark:text-[#EDEDFB]",
                  }}
                />
                <button
                  onClick={handleAddTag}
                  className=" py-1 px-3 bg-[#4A488E] dark:bg-[#2B2A49] text-white rounded text-[20px] "
                >
                  +
                </button>
              </div>
            )}
          </ListSubheader>
          <MenuItem disabled value="">
            <em className="text-[#4A488E] dark:text-[#EDEDFB]">Placeholder</em>
          </MenuItem>
          {combinedItems.map((item) => {
            const isSelected = selected.includes(item._id || item.name);
            return (
              <MenuItem
                className={`text-[#4A488E] dark:text-[#EDEDFB]  ${
                  isSelected ? "dark:bg-violet-700" : customMenuItemClasses
                }`}
                key={item._id || item.name}
                value={item._id || item.name}
                style={{
                  backgroundColor: getColor(item),
                  fontWeight:
                    selected.indexOf(item._id || item.name) === -1
                      ? theme.typography.fontWeightRegular
                      : theme.typography.fontWeightMedium,
                }}
              >
                {getName(item)}
              </MenuItem>
            );
          })}
          <ListSubheader className="bg-[#e6e5ff] dark:bg-[#2B2A49]">
            {label === "Tags" && (
              <MenuItem
                onClick={() => setIsCreatingTag(true)}
                className="bg-[#e6e5ff] dark:bg-[#2B2A49] dark:text-[#EDEDFB]"
              >
                <AddIcon /> Add new tag
              </MenuItem>
            )}
          </ListSubheader>
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectInput;
