import { FaSignInAlt, FaUserPlus } from 'react-icons/fa';

const unprotectedDictionary = [
  {
    label: "Login",
    icon: <FaSignInAlt />,
    path: "/",
  },
  {
    label: "Register",
    icon: <FaUserPlus />,
    path: "/register",
  },
];

export default unprotectedDictionary;
