// Notifications.js
import React from "react";

const Notifications = () => {
  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#CAC9F1]">
      <div className="text-zinc-500 text-xl font-normal">
        Notification Settings
      </div>
      <div className="w-full flex flex-col gap-[25px] mt-[20px]">
        <div className="flex flex-col gap-[20px]">
          <div className="text-zinc-500 text-lg font-normal">
            Email Notifications
          </div>
          <div className="flex flex-col gap-[10px]">
            <label className="flex items-center">
              <input type="checkbox" className="mr-[10px]" />
              Receive notifications for new messages
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-[10px]" />
              Receive notifications for updates
            </label>
          </div>
        </div>
        <div className="flex flex-col gap-[20px]">
          <div className="text-zinc-500 text-lg font-normal">
            Push Notifications
          </div>
          <div className="flex flex-col gap-[10px]">
            <label className="flex items-center">
              <input type="checkbox" className="mr-[10px]" />
              Enable push notifications
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="mr-[10px]" />
              Show previews of notifications
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
