import React, { useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Drawer from "../Notification";
import { useGetMeQuery } from "../../redux/api/userApi";
import NotificationComponent from "../NotificationComponent";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
export const HR = () => {
  const [openRight, setOpenRight] = useState(false);
  const isMobileView = useContext(ViewTypeContext);
  const { category } = useParams;
  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
  } = useGetMeQuery();

  let hrCategories = [];

  if (meData?.role === "Admin") {
    hrCategories = [
      "My Employees",
      // "Certificate upload page",
      "Schedule annual leave",
      "Benefits",
      "Appraisal",
    ];
  } else {
    hrCategories = [
      "Employee details",
      // "Certificate upload page",
      "Schedule annual leave",
      "Benefits",
      "Appraisal",
    ];
  }

  return (
    <div
      className={
        isMobileView
          ? "pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49] pl-[54px]"
          : "pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
      }
    >
      <div className="w-full px-[40px]">
        <div className=" p-4 flex justify-between ">
          <ul className="flex items-center ">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-[300px] text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/hr`}
              >
                HR
              </Link>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>
        <p className="text-[30px] text-[#4A488E] dark:text-[#EDEDFB] mt-[39px] mb-[34px] ml-[20px]">
          HR
        </p>

        {hrCategories.map((item, index) => (
          <Link to={`/HR/${item.replace(/ /g, "-")}`}>
            <div
              key={index}
              className="py-[13px] px-[33px] flex flex-row justify-between items-center rounded-[52px] hover:bg-[#E8E8FF] dark:hover:bg-[#484878] "
            >
              <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal w-full hover:text-[#7674C2] dark:hover:text-[#EDEDFB]">
                {item}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  className="fill-current dark:text-[#EDEDFB]"
                  d="M3.19454 11.9038C3.09376 11.9038 2.99062 11.864 2.91328 11.7866C2.75859 11.6319 2.75859 11.3788 2.91328 11.2241L8.20781 5.92959L2.99062 0.712402C2.83594 0.557714 2.83594 0.30459 2.99062 0.149902C3.14531 -0.00478623 3.39844 -0.00478624 3.55312 0.149902L9.05391 5.64834C9.20859 5.80303 9.20859 6.05615 9.05391 6.21084L3.47813 11.7866C3.39844 11.8663 3.29767 11.9038 3.19454 11.9038Z"
                  fill="#8A8A8A"
                />
              </svg>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
