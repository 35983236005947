import React, { useState, useEffect } from "react";
import IOSSwitch from "../sidebar/IOSSwitch";
const AccountPreferences = () => {
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("theme") === "dark";
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#CAC9F1] dark:bg-[#2B2A49]">
      <div className="text-zinc-500 text-xl font-normal dark:text-[#EDEDFB]">
        Display
      </div>
      <div className="w-full flex flex-row gap-[26px] text-zinc-500 dark:text-[#EDEDFB] text-base font-normal ml-[37px] mr-[38px]">
        <span className="content-around">Dark mode</span>
        <IOSSwitch
          sx={{ m: 1 }}
          checked={darkMode}
          onChange={handleThemeChange}
        />
      </div>
      <div className="mt-[43px] flex flex-col gap-[25px]">
        <div className="text-zinc-500 dark:text-[#EDEDFB] text-xl font-normal">
          General preferences
        </div>
        <div className="flex flex-col gap-[14px]">
          <div className="text-zinc-500 dark:text-[#EDEDFB] text-[15px] dark:text-[#EDEDFB]font-normal">
            Language
          </div>
          <div className="text-zinc-500 text-[13px] dark:text-[#EDEDFB] font-normal">
            Select the language you want to use on “Fuse Digital”
          </div>
          <select className="w-[340px] py-[12px] px-[25px] border-none rounded-[52px] bg-[#D9D9D9] dark:bg-[#484878] dark:text-[#EDEDFB]">
            <option value="option1">Romana</option>
            <option value="option2">English</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default AccountPreferences;
