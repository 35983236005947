import React, { useState } from "react";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { motion, AnimatePresence } from "framer-motion"; // Import motion and AnimatePresence

const DropdownUsersNotification = ({ setCategory, setIsCategoryChosen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useGetAllUsersQuery();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setCategory(option);
    setIsCategoryChosen(true);
    toggleDropdown();
  };

  const handleAllClick = () => {
    setIsCategoryChosen(false);
    toggleDropdown();
    setCategory(null);
  };

  return (
    <div className="relative inline-block text-left z-40">
      <div>
        <button
          type="button"
          className="justify-center w-full rounded-md flex items-center gap-[8px] pr-4 py-2 font-medium text-white"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={toggleDropdown}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="mage:filter">
              <path
                className="dark:stroke-white"
                id="Vector"
                d="M17.7087 10.5H7.41283M3.77866 10.5H2.29199M3.77866 10.5C3.77866 10.0182 3.97006 9.55611 4.31075 9.21542C4.65144 8.87473 5.11352 8.68333 5.59533 8.68333C6.07714 8.68333 6.53921 8.87473 6.8799 9.21542C7.22059 9.55611 7.41199 10.0182 7.41199 10.5C7.41199 10.9818 7.22059 11.4439 6.8799 11.7846C6.53921 12.1253 6.07714 12.3167 5.59533 12.3167C5.11352 12.3167 4.65144 12.1253 4.31075 11.7846C3.97006 11.4439 3.77866 10.9818 3.77866 10.5ZM17.7087 16.0058H12.9187M12.9187 16.0058C12.9187 16.4878 12.7268 16.9504 12.386 17.2911C12.0453 17.6319 11.5831 17.8233 11.1012 17.8233C10.6193 17.8233 10.1573 17.6311 9.81658 17.2904C9.47589 16.9497 9.28449 16.4876 9.28449 16.0058M12.9187 16.0058C12.9187 15.5239 12.7268 15.0621 12.386 14.7214C12.0453 14.3806 11.5831 14.1892 11.1012 14.1892C10.6193 14.1892 10.1573 14.3806 9.81658 14.7213C9.47589 15.0619 9.28449 15.524 9.28449 16.0058M9.28449 16.0058H2.29199M17.7087 4.99416H15.1212M11.487 4.99416H2.29199M11.487 4.99416C11.487 4.51235 11.6784 4.05028 12.0191 3.70959C12.3598 3.3689 12.8218 3.1775 13.3037 3.1775C13.5422 3.1775 13.7785 3.22449 13.9989 3.31578C14.2193 3.40708 14.4195 3.54089 14.5882 3.70959C14.7569 3.87828 14.8907 4.07855 14.982 4.29896C15.0733 4.51936 15.1203 4.7556 15.1203 4.99416C15.1203 5.23273 15.0733 5.46896 14.982 5.68937C14.8907 5.90978 14.7569 6.11005 14.5882 6.27874C14.4195 6.44743 14.2193 6.58125 13.9989 6.67255C13.7785 6.76384 13.5422 6.81083 13.3037 6.81083C12.8218 6.81083 12.3598 6.61943 12.0191 6.27874C11.6784 5.93805 11.487 5.47597 11.487 4.99416Z"
                stroke="#4A488E"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
            </g>
          </svg>
          Filter by person
          <img src="/images/arrow-down.svg" alt="Arrow down" />
        </button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div role="none">
              <ul className="flex flex-col z-30 h-[160px] overflow-y-scroll scrolling-touch">
                <li
                  onClick={handleAllClick}
                  className="p-2 hover:bg-[#4d4d77] text-[#595959] hover:text-white cursor-pointer"
                >
                  <span className="no-underline text-[17px]">All</span>
                </li>
                {usersData?.users.map((u) => (
                  <span
                    key={u._id}
                    className="no-underline hover:bg-[#4d4d77] text-[#595959] hover:text-white text-[17px] p-2 cursor-pointer"
                    onClick={() => handleOptionClick(u._id)}
                  >
                    <li>{u.name}</li>
                  </span>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DropdownUsersNotification;
