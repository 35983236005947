import React from 'react';
import { TextField } from '@mui/material';

const TextInput = ({ label, value, onChange }) => (
  <TextField
    className="rounded-[4px] bg-[#A3A3A3] dark:bg-[#444464] w-[300px]"
    label={label}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    variant="outlined"
  />
);

export default TextInput;
