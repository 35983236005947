import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
// import SquareIcon from "@mui/icons-material/Square";

import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useGetTasksQuery } from "../redux/api/tasksApi";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "52px",
  backgroundColor: "#D9D9D9",

  marginLeft: 0,
  width: "328px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "328px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: 12,
    width: "260px",
    paddingLeft: 20,
    paddingRight: 10,
  },
}));

export const TasksList = () => {
  const { category } = useParams();

  return (
    <div className=" w-full bg-[#CAC9F1]">
      <div className="mt-[53px] ml-[49px] mb-[39px]">
        <Breadcrumbs
          aria-label="breadcrumb"
          className="bg-[#CAC9F1] dark:bg-[#2B2A49] dark:text-[#EDEDFB]"
        >
          <Link underline="hover" color="inherit" to="/dashboard">
            Fuse Digital
          </Link>
          <Link underline="hover" color="inherit" to={`/taskslist/${category}`}>
            Tasks list
          </Link>

          <Typography color="text.primary">{category}</Typography>
        </Breadcrumbs>
      </div>
      <div className="text-stone-500 text-3xl font-normal   ml-[49px] mb-[34px] ">
        My Tasks
      </div>
      <div className="flex items-center gap-[15px]">
        <Search className="ml-[49px]">
          <StyledInputBase
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            endAdornment={<SearchIcon sx={{ color: "#7F7F7F" }} />}
          />
        </Search>
        <div className="flex ">
          <div className="flex justify-between">
            <div className="w-[113px] h-[27px] bg-zinc-600 rounded-[20px] flex justify-center items-center">
              <div className="text-white text-[11px] font-normal   ">
                Active: 17 tasks
              </div>
            </div>
            <div className="w-[134px] h-[27px] bg-zinc-600 rounded-[20px] flex justify-center items-center">
              <div className="text-white text-[11px] font-normal   ">
                Completed: 15 tasks
              </div>
            </div>
            <div className="w-[114px] h-[27px] bg-zinc-600 rounded-[20px] flex justify-center items-center">
              <div className="text-white text-[11px] font-normal   ">
                Total: 32 tasks
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-[119px] h-7 bg-zinc-300 rounded-[3px] flex items-center justify-center ">
              <div className="text-zinc-500 text-[15px] font-normal  ">
                + New task
              </div>
            </div>
            <div className="ml-[19px] flex items-center justify-end ">
              {/* <SquareIcon className="text-[#8A8A8A]" />{" "} */}
              <div
                style={{
                  color: "#8A8A8A",
                  fontSize: 15,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  ml: 11,
                }}
              >
                Filter
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
