import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useGetAccountsQuery,
  usePostAccountMutation,
} from "../../redux/api/crm/accountsApi";
import { useGetClientsQuery } from "../../redux/api/clientsApi";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { useGetDealsQuery } from "../../redux/api/crm/dealsApi";
import { motion } from "framer-motion";
import Drawer from "../Notification";
import AccountForm from "../utils/clientsPanel/AccountForm";
import { ClientsCard } from "./ClientsCard";
import { Link, useSearchParams } from "react-router-dom";
import SearchBar from "../utils/SearchBar";
import NotificationComponent from "../NotificationComponent";
import { useSelector } from "react-redux";

const Clients = () => {
  const user = useSelector((state) => state.user.user);
  const [searchParams] = useSearchParams(); // Get query parameters
  const deactivated = searchParams.get("deactivated");
  const { data: usersData } = useGetAllUsersQuery();
  const { data: dealsData } = useGetDealsQuery();
  const {
    data: clientsData,
    error: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useGetClientsQuery();

  React.useEffect(() => {
    if (clientsData) {
      const intervalId = setInterval(() => {
        refetchClients();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [clientsData, refetchClients]);

  const {
    data: accountsData,
    error: accountsError,
    isLoading: accountsLoading,
  } = useGetAccountsQuery();
  const [postAccount] = usePostAccountMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [filterByDuration, setFilterByDuration] = useState("All");
  const [filterByAccountManager, setFilterByAccountManager] = useState("All");
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);

  // State for sorting
  const [sortOption, setSortOption] = useState("alphabetical");
  const [sortedAccounts, setSortedAccounts] = useState([]);

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const handleFilterClick = () => {
    setIsFilterDropdownOpen((prev) => !prev);
  };

  const handleCreateAccount = async (newAccount) => {
    const userId = usersData?.users?.find(
      (user) => user.name === newAccount.accountManager
    )?._id;
    if (userId) {
      const accountWithUserId = { ...newAccount, accountManager: userId };
      await postAccount(accountWithUserId);
      handleDialogClose();
    } else {
      console.error("User not found");
    }
  };

  useEffect(() => {
    if (accountsData && accountsData.accounts) {
      sortAccounts(accountsData.accounts, sortOption);
    }
  }, [accountsData, sortOption, searchResults]);

  const sortAccounts = (accounts, option) => {
    let sortedList = [...accounts];

    if (option === "alphabetical") {
      sortedList.sort((a, b) => a.nameComp.localeCompare(b.nameComp));
    } else if (option === "dealValue") {
      sortedList.sort((a, b) => {
        const dealA = dealsData?.deals.find((deal) => deal._id === a.deal);
        const dealB = dealsData?.deals.find((deal) => deal._id === b.deal);
        return (dealA?.dealValue || 0) - (dealB?.dealValue || 0);
      });
    }

    setSortedAccounts(sortedList);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
  };

  if (clientsLoading || accountsLoading) return <div>Loading...</div>;
  if (clientsError)
    return <div>Error fetching clients: {clientsError.message}</div>;
  if (accountsError)
    return <div>Error fetching accounts: {accountsError.message}</div>;

  const filteredAccounts = (
    searchResults.length > 0 ? searchResults : sortedAccounts
  ).filter((account) => {
    const matchesDuration =
      filterByDuration === "All" || account.duration === filterByDuration;
    const matchesAccountManager =
      filterByAccountManager === "All" ||
      account.accountManager === filterByAccountManager;

    return matchesDuration && matchesAccountManager;
  });

  const uniqueDurations = [
    ...new Set(accountsData?.accounts.map((a) => a.duration)),
  ];

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  dark:bg-[#2B2A49] dark:text-[#E0E0FF]">
      <div className="w-full">
        <div className="p-4 pl-0 flex items-center flex-wrap justify-between">
          <ul className="flex items-center">
            <li className="inline-flex items-center">
              <Breadcrumbs
                aria-label="breadcrumb"
                className="dark:text-[#E0E0FF]"
              >
                <Link underline="hover" color="#4A488E" to="/dashboard">
                  <span className="text-[#4A488E] dark:text-[#E0E0FF]">
                    Fuse Digital
                  </span>
                </Link>
                <Link underline="hover" color="#4A488E" to={`/clients`}>
                  <span className="text-[#4A488E] dark:text-[#E0E0FF] font-bold">
                    Our clients
                  </span>
                </Link>
              </Breadcrumbs>
            </li>
          </ul>
          <NotificationComponent />
        </div>
        <p className="text-[30px] text-[#4A488E] dark:text-[#E0E0FF] mt-[39px] mb-[34px]">
          Clients
        </p>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <SearchBar
              data={accountsData?.accounts}
              onSearchResults={setSearchResults}
            />
          </div>
          <div className="flex gap-5 items-center">
            {user?.role === "Admin" ? (
              <Link to={deactivated ? "/clients" : "/clients?deactivated=true"}>
                <button className="px-[10px] py-[8px] bg-[#FAFAFE] dark:bg-[#7373ca] text-[#4A488E] dark:text-white font-medium text-[11px] rounded-[20px]">
                  {deactivated ? "Active clients" : "Inactive clients"}
                </button>
              </Link>
            ) : (
              ""
            )}
            <div
              className="text-indigo-800 dark:text-[#EDEDFB] text-[15px] font-normal cursor-pointer relative"
              onClick={handleFilterClick}
            >
              Filter & Sort
            </div>
            {isFilterDropdownOpen && (
              <div className="relative top-[30px] right-[90%] w-0 h-0">
                <div className="bg-white dark:bg-[#33335A] shadow-md rounded-md p-4 z-10 flex flex-col gap-4 absolute top-0 left-0">
                  <FormControl className="w-[200px] rounded-[4px]">
                    <InputLabel>Sort By</InputLabel>
                    <Select
                      label="Sort By"
                      value={sortOption}
                      onChange={(e) => handleSortChange(e.target.value)}
                    >
                      <MenuItem value="alphabetical">Alphabetically</MenuItem>
                      <MenuItem value="dealValue">By Deal Value</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className="w-[200px] rounded-[4px]">
                    <InputLabel>Account Manager</InputLabel>
                    <Select
                      label="Account Manager"
                      value={filterByAccountManager}
                      onChange={(e) =>
                        setFilterByAccountManager(e.target.value)
                      }
                    >
                      <MenuItem value="All">All</MenuItem>
                      {usersData?.users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="w-[200px] rounded-[4px]">
                    <InputLabel>Duration</InputLabel>
                    <Select
                      label="Duration"
                      value={filterByDuration}
                      onChange={(e) => setFilterByDuration(e.target.value)}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {uniqueDurations.map((duration) => (
                        <MenuItem key={duration} value={duration}>
                          {duration}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[10px] my-[34px] md:mb-40">
        {user?.role !== "Admin"
          ? filteredAccounts
              ?.filter((client) => client.isActive !== false)

              .map((client, index) => (
                <Link
                  key={index}
                  to={`/clients/${client.nameComp.replace(/ /g, "-")}`}
                >
                  <ClientsCard client={client} />
                </Link>
              ))
          : filteredAccounts

              .filter((client) => {
                if (deactivated === "true") {
                  return client.isActive === false; // Show only deactivated accounts
                } else {
                  return client.isActive !== false; // Show only active accounts
                }
              }) // Filter out inactive accounts
              .map((client, index) => (
                <Link
                  key={index}
                  to={`/clients/${client.nameComp.replace(/ /g, "-")}`}
                >
                  <ClientsCard client={client} />
                </Link>
              ))}
      </div>
      <AccountForm
        open={openDialog}
        onClose={handleDialogClose}
        onSubmit={handleCreateAccount}
        usersData={usersData}
        dealsData={dealsData}
        initialData={{
          nameComp: "",
          priority: "High",
          industry: "",
          deal: "",
          accountManager: "",
          generalResults: [],
          activities: [],
          services: {
            SEO: [],
            PPC: [],
            SMM: [],
            Development: [],
          },
          duration: "",
          price: "",
          expectations: [],
        }}
      />
    </div>
  );
};

export default Clients;
