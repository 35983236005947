import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../../../redux/api/authApi";
import { toast } from "react-toastify";

const InputEmail = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword({ email }).unwrap();
      setSubmitted(true);
    } catch (error) {
      toast.error("Failed to send recovery email. Please check the email address and try again.");
    }
  };

  return (
    <>
      <div className="flex justify-center h-screen items-center">
        {!submitted ? (
          <div className="w-[472px] h-[516px] border rounded-[14px] shadow-2xl bg-white dark:bg-[#3A3A68] dark:border-[#2B2A49]">
            <div className="border-b dark:border-[#2B2A49]">
              <p className="text-[34px] py-[34px] px-[31px] dark:text-[#EDEDFB]">
                Password Recovery
              </p>
            </div>
            <div className="pt-[91px]">
              <form onSubmit={submitHandler}>
                <div className="mb-3 flex flex-col px-[34px] gap-[19px]">
                  <label
                    htmlFor="email_field"
                    className="pl-2 form-label text-[20px] dark:text-[#EDEDFB]"
                  >
                    Enter the email address of your account
                  </label>
                  <input
                    type="email"
                    id="email_field"
                    className="form-control rounded-[69px] dark:text-[#EDEDFB] h-[60px] border-2 px-[10px] text-[16px] dark:bg-[#2B2A49] dark:border-none"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="flex justify-center py-[30px] w-full">
                  <button
                    id="submit_button"
                    type="submit"
                    className="btn w-100 py-[15px] px-[63px] bg-[#025d52] dark:bg-white dark:text-[#8684CC] rounded-[66px] text-white"
                    disabled={isLoading}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
              <div className="flex justify-end px-[34px] underline">
                <a
                  href="#"
                  className="text-[#8A8A8A] dark:text-[#8684CC]"
                >
                  Forgot Email?
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[472px] h-[300px] border rounded-[14px] shadow-2xl bg-white dark:bg-[#3A3A68] dark:border-[#2B2A49] flex flex-col justify-center items-center">
            <p className="text-[20px] text-center px-[31px] dark:text-[#EDEDFB]">
              You will receive a mail in the next minutes to change your password.
            </p>
            <div className="flex justify-center py-[30px] w-full">
              <button
                onClick={() => navigate("/")}
                className="btn py-[10px] px-[20px] bg-[#025d52] dark:bg-white dark:text-[#8684CC] rounded-[66px] text-white"
              >
                Go to Login
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InputEmail;
