import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineCheckCircle, AiOutlineWarning, AiOutlineCloseCircle } from 'react-icons/ai';

const getNotificationClassName = (harshness) => {
  switch (harshness) {
    case 0:
      return {
        className: 'bg-white text-[#4A488E] border-green-500',
        icon: <AiOutlineCheckCircle className="text-green-500 text-3xl" />,
      };
    case 1:
      return {
        className: 'bg-white text-[#4A488E] border-yellow-500',
        icon: <AiOutlineWarning className="text-yellow-500 text-3xl" />,
      };
    case 2:
      return {
        className: 'bg-white text-[#4A488E] border-red-500',
        icon: <AiOutlineCloseCircle className="text-red-500 text-3xl" />,
      };
    default:
      return {
        className: 'bg-white text-[#4A488E] border-gray-500',
        icon: null,
      };
  }
};

export const notify = (text, harshness, goAheadCallback, cancelCallback, simple = false) => {
  const { className, icon } = getNotificationClassName(harshness);

  if (simple) {
    toast(
      <div className={`flex items-center p-4 ${className}`}>
        {icon && <div className="mr-2">{icon}</div>}
        <p>{text}</p>
      </div>,
      {
        className: 'rounded-lg shadow-lg border',
        progressClassName: 'Toastify__progress-bar',
        autoClose: 2000 // Auto close after 5 seconds
      }
    );
  } else {
    toast(
      ({ closeToast }) => (
        <div className={`flex flex-col items-start p-4 ${className}`}>
          <div className="flex items-center">
            {icon && <div className="mr-2">{icon}</div>}
            <p>{text}</p>
          </div>
          <div className="mt-2 flex">
            <button
              onClick={() => {
                goAheadCallback();
                closeToast();
              }}
              className="mr-2 bg-[#E8E8FF] text-[#4A488E] border border-[#4A488E] px-3 py-1 rounded hover:bg-[#4A488E] hover:text-white"
            >
              Go Ahead
            </button>
            <button
              onClick={() => {
                cancelCallback();
                closeToast();
              }}
              className="bg-gray-300 text-[#4A488E] border border-gray-500 px-3 py-1 rounded hover:bg-gray-500 hover:text-white"
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      {
        className: 'rounded-lg shadow-lg border',
        progressClassName: 'Toastify__progress-bar',
        autoClose: false // Do not auto close
      }
    );
  }
};
