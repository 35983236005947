import React, { useState } from 'react';
import { Popper, ClickAwayListener, Paper } from '@mui/material';
import { motion } from 'framer-motion';

const revenueOptions = [
  { label: 'Completed', color: '#00DD65', value: 'Completed' },
  { label: 'Active', color: '#FFB950', value: 'Active' },
  { label: 'Canceled', color: '#FF5555', value: 'Canceled' },
];

const expenseOptions = [
  { label: 'Completed', color: '#FF5656', value: 'Completed' },
  { label: 'Active', color: '#00B9F3', value: 'Active' },
  { label: 'Canceled', color: '#00DD65', value: 'Canceled' },
];

// Function to retrieve the correct options based on type
const getOptionsByType = (type) => {
  return type === 'revenue' ? revenueOptions : expenseOptions;
};

const FuseSpecialDropdown = ({
  currentStatus,
  onChange,
  loading,
  type = 'revenue',
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Toggle dropdown on button click
  const handleButtonClick = (event) => {
    if (loading) return; // Prevent opening if loading
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  // Handle status change
  const handleStatusChange = (newStatus) => {
    onChange(newStatus.value);
    setAnchorEl(null); // Close the dropdown
  };

  // Get options based on type
  const options = getOptionsByType(type);

  // Get the current option by matching the value with the current status
  const currentOption =
    options.find((option) => option.value === currentStatus) || options[0];

  const isOpen = Boolean(anchorEl);

  // Define the pulsating animation variants
  const pulsateVariants = {
    initial: { backgroundColor: currentOption.color },
    animate: {
      backgroundColor: [currentOption.color, '#cccccc', currentOption.color],
      transition: {
        duration: 1,
        ease: 'easeInOut',
        repeat: Infinity,
      },
    },
  };

  return (
    <>
      <motion.div
        className={`w-[140px] h-[49px] flex justify-center items-center gap-2.5 rounded-[58px] ${
          loading ? '' : 'cursor-pointer dropdown-toggle'
        }`}
        onClick={handleButtonClick}
        variants={pulsateVariants}
        animate={loading ? 'animate' : 'initial'}
        style={{
          cursor: loading ? 'default' : 'pointer',
        }}
      >
        <div className="text-white text-xs font-medium text-center w-full truncate">
          {currentOption.label}
        </div>
      </motion.div>

      <Popper open={isOpen} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Paper
            elevation={3}
            className="z-50 bg-[#F4F2F2] dark:bg-[#383757] rounded-bl-md rounded-br-md p-2.5 flex flex-col gap-2"
          >
            {options.map((option) => (
              <div
                key={option.value}
                className="w-full px-[21px] py-[15px] flex justify-center items-center gap-2.5 rounded-[29px] cursor-pointer"
                style={{ backgroundColor: option.color }}
                onClick={() => handleStatusChange(option)}
              >
                <div className="text-white text-xs font-medium text-center w-full truncate">
                  {option.label}
                </div>
              </div>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default FuseSpecialDropdown;
