import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie"; // Import js-cookie
import backend_URL from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery,
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getNotificationsForReceiver: builder.query({
      query(receiverId) {
        return {
          url: `/notifications/receiver/${receiverId}`,
          method: "GET",
        };
      },
      providesTags: ["Notifications"],
    }),
    getNotification: builder.query({
      query(id) {
        return {
          url: `/notifications/${id}`,
          method: "GET",
        };
      },
    }),
    createNotification: builder.mutation({
      query(notification) {
        return {
          url: "/notifications",
          method: "POST",
          body: notification,
        };
      },
      invalidatesTags: ["Notifications"],
    }),
    updateNotification: builder.mutation({
      query({ id, ...notification }) {
        return {
          url: `/notifications/${id}`,
          method: "PUT",
          body: notification,
        };
      },
      invalidatesTags: ["Notifications"],
    }),
    deleteNotification: builder.mutation({
      query(id) {
        return {
          url: `/notifications/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Notifications"],
    }),
    markNotificationAsRead: builder.mutation({
      query(id) {
        return {
          url: `/notifications/${id}/read`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Notifications"],
    }),

    // New endpoint for marking all notifications as read
    readAllNotifications: builder.mutation({
      query(receiverId) {
        return {
          url: `/notifications/receiver/${receiverId}/readAll`,
          method: "PUT",
        };
      },
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const {
  useGetNotificationsForReceiverQuery,
  useGetNotificationQuery,
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useMarkNotificationAsReadMutation,
  useReadAllNotificationsMutation, // Export the new hook
} = notificationApi;
