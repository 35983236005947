import React, { useState, useEffect, useContext } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProjectCard from "../ProjectCard";
import ProjectForm from "../ProjectForm";
import Drawer from "../Notification";
import {
  useGetProjectsQuery,
  useUpdateProjectStatusMutation,
  useDeleteProjectMutation,
} from "../../redux/api/projectsApi";
import { useGetMeQuery } from "../../redux/api/userApi";
import Placeholder from "../utils/panels/Placeholder";
import DropZone from "../utils/panels/DropZone";
import DraggingContext from "../utils/panels/DraggingContext";
import SearchBar from "../utils/SearchBar";
import dayjs from "dayjs";
import NotificationComponent from "../NotificationComponent";
import { useSelector } from "react-redux";
import { TouchBackend } from "react-dnd-touch-backend";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import { useTheme } from "@emotion/react";
import backend_URL from "../../config";
import { io } from "socket.io-client";
const ProjectsPanel = () => {
  const { data: meData, error: meError, isLoading:meLoading, refetch: refetchMe } = useGetMeQuery();
  const userData = useSelector((state) => state.user.user);
  const theme = useTheme();

  const [theme2, setTheme2] = useState(localStorage.getItem("theme"));
  const { category } = useParams();
  const [searchParams] = useSearchParams(); // Get query parameters
  const deactivated = searchParams.get("deactivated");

  const { data, refetch, isFetching, isLoading, isError,
  } = useGetProjectsQuery();

  const isMobileView = useContext(ViewTypeContext);

  // useEffect(() => {
  //   if (data) {
  //     // Only refetch if the initial query has been successfully loaded
  //     const intervalId = setInterval(() => {
  //       refetch();
  //     }, 5000);

  //     return () => clearInterval(intervalId); // Cleanup interval on unmount
  //   }
  // }, [data, refetch]);


  useEffect(() => {
    const socket = io(backend_URL);
    window.socket = socket;
  
    socket.on('userPing', (data) => {
      refetch();
      refetchMe();
    });

    socket.on('projectPing', (data) => {
      refetch();
    });

    return () => {
      socket.disconnect();
    };
  }, [refetch, refetchMe]);

  const [openRight, setOpenRight] = useState(false);
  const [cards, setCards] = useState({
    todo: [],
    inProgress: [],
    completed: [],
  });
  const [selectedCategory, setSelectedCategory] = useState({
    Priority: [],
    Members: [],
    "Due date": [],
    Client: [],
    Status: [],
    Tag: [],
  });
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [updateProjectStatus] = useUpdateProjectStatusMutation();
  const [deleteProject] = useDeleteProjectMutation();
  const [dragging, setDragging] = useState(false);
  const [initialStatus, setInitialStatus] = useState("");
  const [openProjectForm, setOpenProjectForm] = useState(false);

  useEffect(() => {
    if (data && data.projects) {
      filterProjects(data.projects);
    }
  }, [data, selectedCategory]);

  useEffect(() => {
    if (data && data.projects) {
      setFilteredProjects(data.projects);
    }
  }, [data]);

  const filterProjects = (projects) => {
    const today = dayjs().startOf("day");
    const tomorrow = dayjs().add(1, "day").startOf("day");
    const nextWeek = dayjs().add(7, "day").startOf("day");

    const filtered = projects.filter((project) => {
      const dueDate = dayjs(project.dueDate);

      const matchesPriority =
        !selectedCategory.Priority.length ||
        selectedCategory.Priority.includes(project.priority);
      const matchesMembers =
        !selectedCategory.Members.length ||
        selectedCategory.Members.some((memberId) =>
          project.assignedTo.includes(memberId)
        );
      const matchesDueDate =
        !selectedCategory["Due date"].length ||
        selectedCategory["Due date"].some((option) => {
          if (option === "Today") return dueDate.isSame(today, "day");
          if (option === "Tomorrow") return dueDate.isSame(tomorrow, "day");
          if (option === "Next Week")
            return dueDate.isAfter(today) && dueDate.isBefore(nextWeek);
          return false;
        });
      const matchesClient =
        !selectedCategory.Client.length ||
        selectedCategory.Client.includes(project.client);
      const matchesStatus =
        !selectedCategory.Status.length ||
        selectedCategory.Status.includes(project.status);
      const matchesTag =
        !selectedCategory.Tag.length ||
        project.tags.some((tag) => selectedCategory.Tag.includes(tag));

      return (
        matchesPriority &&
        matchesMembers &&
        matchesDueDate &&
        matchesClient &&
        matchesStatus &&
        matchesTag
      );
    });

    const classifiedProjects = {
      todo: filtered.filter((project) => project.status === "To do"),
      inProgress: filtered.filter(
        (project) => project.status === "In Progress"
      ),
      completed: filtered.filter((project) => project.status === "Completed"),
    };

    setCards(classifiedProjects);
  };

  const handleSearchResults = (results) => {
    setFilteredProjects(results);
    filterProjects(results);
  };

  const moveCard = async (id, toColumn) => {
    const fromColumn = Object.keys(cards).find((key) =>
      cards[key].some((project) => project._id === id)
    );
    if (fromColumn === toColumn) return;
    const projectToMove = cards[fromColumn].find(
      (project) => project._id === id
    );
    const updatedStatus =
      toColumn === "todo"
        ? "To do"
        : toColumn === "inProgress"
        ? "In Progress"
        : "Completed";
    try {
      await updateProjectStatus({
        id,
        status: updatedStatus,
      });
      setCards((prevCards) => ({
        ...prevCards,
        [fromColumn]: prevCards[fromColumn].filter(
          (project) => project._id !== id
        ),
        [toColumn]: [
          ...prevCards[toColumn],
          { ...projectToMove, status: updatedStatus },
        ],
      }));
    } catch (error) {
      console.error(`Failed to update project ${id} status:`, error);
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId).unwrap();
      setCards((prevCards) => {
        const updatedCards = { ...prevCards };
        Object.keys(updatedCards).forEach((key) => {
          updatedCards[key] = updatedCards[key].filter(
            (project) => project._id !== projectId
          );
        });
        return updatedCards;
      });
    } catch (error) {
      console.error(`Failed to delete project ${projectId}:`, error);
    }
  };

  const handleOptionClick = (category, option) => {
    setSelectedCategory((prev) => {
      const categoryOptions = prev[category];
      if (categoryOptions.includes(option)) {
        return {
          ...prev,
          [category]: categoryOptions.filter((opt) => opt !== option),
        };
      } else {
        return {
          ...prev,
          [category]: [...categoryOptions, option],
        };
      }
    });
  };

  const getFilteredProjects = (projects, category) => {
    switch (category) {
      case "My-Projects":
        return projects.filter((p) => p.assignedTo.includes(meData?._id));
      case "SMM-Graphic-Design":
        return projects.filter((p) =>
          p.department.some(
            (team) => team === "SMM" || team === "Graphic Design"
          )
        );
      case "Development":
        return projects.filter((p) =>
          p.department.some((team) => team === "Development")
        );
      case "SEO":
        return projects.filter((p) =>
          p.department.some((team) => team === "SEO")
        );
      case "PPC":
        return projects.filter((p) =>
          p.department.some((team) => team === "PPC")
        );
      default:
        return projects;
    }
  };

  const renderColumn = (columnName, title, count) => {
    const titleColors = {
      "To do": "#2ECC71",
      "In Progress": "#C026D3",
      Completed: "#F472B6",
    };

    const projects = getFilteredProjects(
      cards[columnName].filter((project) => {
        if (deactivated === "true") {
          return project.isActive === false; // Show only deactivated projects
        } else {
          return project.isActive !== false; // Show only active projects
        }
      }),
      category
    );
    const LoadingSpinner = () => {
      return (
        <div className="w-full min-h-screen flex justify-center items-center mt-0">
          <div className="w-20 h-20 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
        </div>
      );
    };

    if (!data) {
      return <LoadingSpinner />;
    }
    return (
      <div className="w-4/12 bg-[#CAC9F1] dark:bg-[#2B2A49]">
        <div className="py-[24px] bg-[#FAFAFE] dark:bg-[#3A3A68] rounded-[8px] flex justify-between">
          <div className="flex gap-[13px]">
            <p className="text-[18px] text-[#8A8A8A] dark:text-white ml-3">
              {title}
            </p>
            <span
              className="text-white rounded-full flex justify-center items-center w-[30px] h-[30px]"
              style={{ backgroundColor: titleColors[title] }}
            >
              {count}
            </span>
          </div>
          {/* <img src="/images/dots.svg" width={30} height={30} alt="" /> */}
        </div>
        {userData?.role === "Admin" ? (
          <div
            className="py-[24px] my-[10px] bg-[#FAFAFE] dark:bg-[#3A3A68] rounded-[8px] flex justify-center cursor-pointer"
            onClick={() => {
              setInitialStatus(title);
              setOpenProjectForm(true);
            }}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="ep:plus">
                <path
                  id="Vector"
                  d="M9.375 10.625V17.5C9.375 17.6658 9.44085 17.8247 9.55806 17.9419C9.67527 18.0592 9.83424 18.125 10 18.125C10.1658 18.125 10.3247 18.0592 10.4419 17.9419C10.5592 17.8247 10.625 17.6658 10.625 17.5V10.625H17.5C17.6658 10.625 17.8247 10.5592 17.9419 10.4419C18.0592 10.3247 18.125 10.1658 18.125 10C18.125 9.83424 18.0592 9.67527 17.9419 9.55806C17.8247 9.44085 17.6658 9.375 17.5 9.375H10.625V2.5C10.625 2.33424 10.5592 2.17527 10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875C9.83424 1.875 9.67527 1.94085 9.55806 2.05806C9.44085 2.17527 9.375 2.33424 9.375 2.5V9.375H2.5C2.33424 9.375 2.17527 9.44085 2.05806 9.55806C1.94085 9.67527 1.875 9.83424 1.875 10C1.875 10.1658 1.94085 10.3247 2.05806 10.4419C2.17527 10.5592 2.33424 10.625 2.5 10.625H9.375Z"
                  fill="#656565"
                  className="fill-current dark:text-[#EDEDFB]"
                />
              </g>
            </svg>
          </div>
        ) : (
          ""
        )}

        <DropZone onDrop={moveCard} columnName={columnName}>
          <div className="py-[24px] bg-[#CAC9F1] dark:bg-[#2B2A49] rounded-[8px] flex flex-col gap-5">
            {projects
              .slice()
              .reverse()
              .map((project) => (
                <ProjectCard
                  key={project._id}
                  project={project}
                  onDelete={handleDeleteProject}
                />
              ))}
          </div>
        </DropZone>
      </div>
    );
  };

  const activeCount = cards.todo.length + cards.inProgress.length;
  const completedCount = cards.completed.length;
  const totalCount = activeCount + completedCount;

  return (
    <DraggingContext.Provider value={{ dragging, setDragging }}>
      <DndProvider backend={isMobileView ? TouchBackend : HTML5Backend}>
        <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#CAC9F1] dark:bg-[#2B2A49] md:mb-40">
          <div className="w-full ">
            <div className="p-4 pl-0 flex items-center justify-between flex-wrap bg-[#CAC9F1] dark:bg-[#2B2A49]">
              <ul className="flex items-center">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className="dark:text-[#EDEDFB]"
                >
                  <Link
                    underline="hover"
                    className="text-[#4A488E] dark:text-[#E0E0FF]"
                    to="/dashboard"
                  >
                    Fuse Digital
                  </Link>
                  <Link
                    underline="hover"
                    className="text-[#4A488E] dark:text-[#E0E0FF]"
                    to={`/projects/All-Projects`}
                  >
                    Projects list
                  </Link>
                  <Typography className="text-[#4A488E] font-semibold dark:text-[#E0E0FF]">
                    {category}
                  </Typography>
                </Breadcrumbs>
              </ul>
              <NotificationComponent />
            </div>

            <p className="text-[30px] text-[#4A488E] dark:text-[#E0E0FF] mt-[39px] mb-[34px]">
              {category.replace(/-/g, " ")}
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between gap-[90px]">
                <SearchBar
                  data={data?.projects || []}
                  onSearchResults={handleSearchResults}
                />
                <div className="flex items-center">
                  <div className="flex gap-4 ml-[10px]">
                    <span className="px-[10px] py-[8px] bg-[#FAFAFE] dark:bg-[#363660] text-[#4A488E] dark:text-white font-medium text-[11px] rounded-[20px]">
                      Active: {activeCount} project{activeCount > 1 ? "s" : ""}
                    </span>
                    <span className="px-[10px] py-[8px] bg-[#FAFAFE] dark:bg-[#363660] text-[#4A488E] dark:text-white font-medium text-[11px] rounded-[20px]">
                      Completed: {completedCount} project
                      {completedCount > 1 ? "s" : ""}
                    </span>
                    <span className="px-[10px] py-[8px] bg-[#FAFAFE] dark:bg-[#363660] text-[#4A488E] dark:text-white font-medium text-[11px] rounded-[20px]">
                      Total: {totalCount} project{totalCount > 1 ? "s" : ""}
                    </span>
                  </div>
                </div>
              </div>
              {userData?.role === "Admin" ? (
                <Link
                  to={
                    deactivated
                      ? "/projects/All-Projects"
                      : "/projects/All-Projects?deactivated=true"
                  }
                >
                  <button className="px-[10px] py-[8px] bg-[#FAFAFE] dark:bg-[#7373ca] text-[#4A488E] dark:text-white font-medium text-[11px] rounded-[20px]">
                    {deactivated ? "Active projects" : "Inactive projects"}
                  </button>
                </Link>
              ) : (
                ""
              )}

              <div className="flex gap-5 items-center">
                <ProjectForm
                  initialStatus={initialStatus}
                  open={openProjectForm}
                  onClose={() => setOpenProjectForm(false)}
                />
                <Dropdown
                  setCategory={handleOptionClick}
                  selectedCategory={selectedCategory}
                />
              </div>
            </div>
            <div className="py-[34px] flex gap-[15px] flex-wrap">
              <div className="flex w-full gap-[15px]">
                {renderColumn("todo", "To do", cards.todo.length)}
                {renderColumn(
                  "inProgress",
                  "In Progress",
                  cards.inProgress.length
                )}
                {renderColumn("completed", "Completed", cards.completed.length)}
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
    </DraggingContext.Provider>
  );
};

export default ProjectsPanel;
