import React, { useState } from "react";
import { useLoggedChangePassMutation } from "../../redux/api/authApi";
import { useSelector } from "react-redux"; // Import useSelector to get user information
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS

const Security = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);

  const userId = useSelector((state) => state.user.user._id); // Get the user's ID from the Redux store

  const [loggedChangePass, { isLoading }] = useLoggedChangePassMutation();

  const passwordRules = [
    { rule: /.{8,}/, message: "At least 8 characters long" },
    { rule: /[A-Z]/, message: "Contains an uppercase letter" },
    { rule: /[a-z]/, message: "Contains a lowercase letter" },
    { rule: /[0-9]/, message: "Contains a number" },
    { rule: /[^A-Za-z0-9]/, message: "Contains a special character" },
  ];

  const validatePassword = (password) => {
    const errors = passwordRules
      .filter((rule) => !rule.rule.test(password))
      .map((rule) => rule.message);
    setValidationErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    if (validationErrors.length > 0) {
      toast.error("Please ensure the new password meets all requirements.");
      return;
    }

    try {
      await loggedChangePass({
        id: userId,
        oldPassword: currentPassword,
        newPassword,
        confirmPassword,
      }).unwrap();

      // Notify the user of a successful password change
      toast.success("Password changed successfully!");
      // Optionally reset form fields
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (err) {
      // Handle error and provide feedback to the user
      console.error("Failed to change password: ", err);
      toast.error(
        err.data?.message || "Failed to change password. Please try again."
      );
    }
  };

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#CAC9F1] dark:bg-[#2B2A49]">
      <div className="text-zinc-500 text-xl font-normal dark:text-[#EDEDFB]">
        Security Settings
      </div>
      <div className="w-[50%] flex flex-col gap-[25px] mt-[20px]">
        <div className="flex flex-col gap-[20px]">
          <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB]">
            Change Password
          </div>
          <form className="flex flex-col gap-[15px]" onSubmit={handleSubmit}>
            <input
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="py-[12px] px-[15px] rounded-[4px] bg-[#D9D9D9] dark:text-[#EDEDFB] border-none"
            />
            <input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                validatePassword(e.target.value);
              }}
              className="py-[12px] px-[15px] rounded-[4px] bg-[#D9D9D9] dark:text-[#EDEDFB] border-none"
            />
            <ul className="text-red-500">
              {validationErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
            <input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="py-[12px] px-[15px] rounded-[4px] bg-[#D9D9D9] dark:text-[#EDEDFB] border-none"
            />
            <button
              type="submit"
              className="mt-[10px] w-full py-[10px] rounded-[4px] bg-blue-500 text-white dark:text-[#EDEDFB]"
              disabled={isLoading || validationErrors.length > 0}
            >
              {isLoading ? "Changing..." : "Change Password"}
            </button>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </div>
  );
};

export default Security;
