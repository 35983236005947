import React, { useState, useEffect, useRef } from "react";
import { SearchIcon } from "../svgs"; // Assuming you have a custom search icon

const ProductSearchBar = ({
  label,
  onSearchQuery,
  products = [],
  onSelectProduct,
  selectedProduct = null, // Pre-selected product (for edit mode)
}) => {
  const [query, setQuery] = useState(selectedProduct?.name || ""); // Initialize with selected product name
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct.name !== query) {
        setQuery(selectedProduct.name);
      }
    }
  }, [selectedProduct]);

  // Avoid unnecessary search when the product is preselected
  useEffect(() => {
    if (selectedProduct && query === selectedProduct.name) {
      setIsDropdownVisible(false); // Close dropdown if the product is preselected
      return;
    }

    if (query.trim() === "") {
      setIsDropdownVisible(false);
      return;
    }

    onSearchQuery(query); // Trigger search query only when needed
    setIsDropdownVisible(true);
  }, [query, onSearchQuery, selectedProduct]);

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownVisible(false); // Close dropdown when clicking outside
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleProductSelect = (product) => {
    setQuery(product.name); // Set selected product name
    setIsDropdownVisible(false); // Close dropdown
    onSelectProduct(product); // Notify parent about the selected product
  };

  return (
    <div
      className="relative flex flex-row items-center w-full gap-4 mb-4"
      ref={searchRef}
    >
      {label && (
        <label className="text-[#4A488E] dark:text-[#EDEDFB] text-[18px] font-normal w-[150px]">
          {label}
        </label>
      )}

      {/* Search Input */}
      <div className="inline-flex flex-col items-start gap-2.5 px-5 py-[5px] relative bg-[#E9E9FF] dark:bg-[#363660] rounded-[52px] overflow-hidden w-[88%]">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search products..."
          className="h-11 w-full px-5 py-[13px] bg-transparent border-none placeholder-[#8b8baf] text-[#363660] dark:text-white focus:outline-none focus:ring-0"
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            outline: "none",
          }}
          onFocus={() => setIsDropdownVisible(true)} // Show dropdown when focused
        />
        <div className="absolute right-4 top-[50%] transform -translate-y-[50%]">
          <SearchIcon />
        </div>
      </div>

      {/* Render Dropdown */}
      {isDropdownVisible && products.length > 0 && (
        <div className="absolute top-full mt-2 w-full bg-[#E9E9FF] dark:bg-[#363660] rounded-lg shadow-lg z-20 max-h-60 overflow-y-auto scrolling-touch  custom-scrollbar">
          {products.slice(0, 5).map((product) => (
            <div
              key={product._id}
              onClick={() => handleProductSelect(product)}
              className="flex items-center px-4 py-2 hover:bg-[#d5d5eb] dark:hover:bg-[#31315a] cursor-pointer text-white"
            >
              <img
                src={product.image || "/default-image.png"}
                alt={product.name}
                className="w-10 h-10 object-cover rounded mr-4"
              />
              <span className="text-[#363660] dark:text-[#E9E9FF]">
                {product.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSearchBar;
