import React, { useState } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TicketCard from "../TicketCard";
import NotificationComponent from "../NotificationComponent";
import { useGetTasksQuery } from "../../redux/api/tasksApi";
import { useGetMeQuery } from "../../redux/api/userApi";
import SearchBar from "../utils/SearchBar";
import Dropdown from "../utils/ticketsPanel/archievedTicketsDropdown";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useContext } from "react";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";

const ArchivedTicketsPanel = () => {
  const isMobileView = useContext(ViewTypeContext);

  const { data: meData } = useGetMeQuery();
  const { data } = useGetTasksQuery();

  const [searchResults, setSearchResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    Priority: [],
    Members: [],
    "Due date": [],
    Client: [],
    Status: [],
    Tag: [],
  });

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  // Function to filter tasks based on the selected categories
  const filterTasks = (tasks) => {
    return tasks.filter((task) => {
      return (
        (!selectedCategory.Priority.length ||
          selectedCategory.Priority.includes(task.priority)) &&
        (!selectedCategory.Members.length ||
          selectedCategory.Members.some((memberId) =>
            task.persoana.includes(memberId)
          )) &&
        (!selectedCategory["Due date"].length ||
          selectedCategory["Due date"].includes(task.dueDate)) &&
        (!selectedCategory.Client.length ||
          selectedCategory.Client.includes(task.client)) &&
        (!selectedCategory.Status.length ||
          selectedCategory.Status.includes(task.status)) &&
        (!selectedCategory.Tag.length ||
          selectedCategory.Tag.includes(task.tag))
      );
    });
  };

  // Derive completed tasks based on search and selected filters
  const completedTasks = (() => {
    const allTasks = data?.tickets || [];
    const filteredTasks = searchResults.length
      ? filterTasks(searchResults)
      : filterTasks(allTasks);
    return filteredTasks.filter((task) => task.status === "Completed");
  })();

  // Handle category option click for filtering
  const handleOptionClick = (category, option) => {
    setSelectedCategory((prev) => {
      const categoryOptions = prev[category];
      if (categoryOptions.includes(option)) {
        return {
          ...prev,
          [category]: categoryOptions.filter((opt) => opt !== option),
        };
      } else {
        return {
          ...prev,
          [category]: [...categoryOptions, option],
        };
      }
    });
  };

  // Function to distribute tasks across columns
  const distributeTasksAcrossColumns = (tasks, numColumns) => {
    const columns = Array.from({ length: numColumns }, () => []);
    tasks.forEach((task, index) => {
      const columnIndex = index % numColumns;
      columns[columnIndex].push(task);
    });
    return columns;
  };

  // Number of columns to display tasks
  const numColumns = 3;
  const columns = distributeTasksAcrossColumns(completedTasks, numColumns);

  // Render each column with tasks distributed
  const renderColumn = (tasks, columnIndex) => {
    return (
      <div key={columnIndex} className="w-4/12 dark:bg-[#2B2A49] bg-[#CAC9F1]">
        <div className="py-[24px] bg-[#CAC9F1] rounded-[8px] dark:bg-[#2B2A49] flex flex-col gap-5">
          {tasks.map((task) => (
            <TicketCard key={task._id} id={task._id} task={task} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <DndProvider backend={isMobileView ? TouchBackend : HTML5Backend}>
      <div
        className={`pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#CAC9F1] dark:bg-[#2B2A49]`}
      >
        <div className="w-full">
          <>
            <div className="p-4 pl-0 flex items-center flex-wrap bg-[#CAC9F1] dark:bg-[#2B2A49] justify-between">
              <ul className="flex items-center">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  className="bg-[#CAC9F1] dark:bg-[#2B2A49] dark:text-[#EDEDFB]"
                >
                  <Link
                    underline="hover"
                    className="text-[#4A488E] dark:text-[#EDEDFB]"
                    to="/dashboard"
                  >
                    Fuse Digital
                  </Link>
                  <Link
                    underline="hover"
                    className="text-[#4A488E] dark:text-[#EDEDFB]"
                    to={`/taskslist/my-tasks`}
                  >
                    Tasks list
                  </Link>
                  <Typography className="text-[#4A488E] font-semibold dark:text-[#EDEDFB]">
                    Completed Ticket History
                  </Typography>
                </Breadcrumbs>
              </ul>
              <NotificationComponent />
            </div>
            <p className="text-[30px] text-[#4A488E] mt-[39px] mb-[34px] dark:text-[#EDEDFB]">
              Completed Ticket History
            </p>
          </>
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-between gap-[90px]">
              <SearchBar
                data={data?.tickets}
                onSearchResults={handleSearchResults}
              />
              <div className="flex items-center">
                <div className="flex gap-4 ml-[10px]">
                  <span className="px-[10px] py-[8px] bg-[#FAFAFE] text-[#4A488E] font-medium text-[11px] rounded-[20px] dark:bg-[#33335A] dark:text-[#FFFFFF]">
                    Completed: {completedTasks.length} tasks
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-5 items-center">
              <Dropdown
                setCategory={handleOptionClick}
                selectedCategory={selectedCategory}
              />
            </div>
          </div>
          <div className="py-[34px] flex gap-[15px] flex-wrap">
            <div className="flex w-full gap-[15px]">
              {columns.map((tasks, index) => renderColumn(tasks, index))}
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ArchivedTicketsPanel;
