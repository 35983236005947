import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useGetPaginatedTicketsQuery } from "../redux/api/tasksApi";
import { useGetAllUsersQuery } from "../redux/api/authApi";
import { useGetProjectsQuery } from "../redux/api/projectsApi";
import { useGetDepartmentsQuery } from "../redux/api/departmentsApi";
import { format } from "date-fns";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Avatar,
  Button,
} from "@mui/material";
import TaskViewMode from "./TicketViewMode";
import { ViewTypeContext } from "./utils/contexts/viewTypeContext";
import "./custom-scrollbar.css";
import { io } from "socket.io-client";
import backend_URL from "../config";

export const LastTasks = () => {
  const isMobileView = useContext(ViewTypeContext);
  const [page, setPage] = useState(1);
  const [tasksWithUserNames, setTasksWithUserNames] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "createdAt",
    direction: "desc",
  });
  const [hasMore, setHasMore] = useState(true);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskViewOpen, setIsTaskViewOpen] = useState(false);
  const { data: usersData, error: usersError, isLoading: usersLoading,refetch: refetchUsers
   } = useGetAllUsersQuery();
  const { data: departmentData, isLoading: departmentsLoading, error: departmentsError, refetch: refetchDepartments
   } = useGetDepartmentsQuery();

  const { data: tasksData, isLoading: tasksLoading, error: tasksError, refetch: refetchTasks } = useGetPaginatedTicketsQuery({ limit: 30, page });
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (tasksData && usersData?.users && departmentData) {
      const usersMap = usersData.users.reduce((acc, user) => {
        acc[user._id] = {
          name: user.name,
          avatarUrl: user.avatar?.url || "",
        };
        return acc;
      }, {});
  
      const userDepartmentNames = departmentData.departments
        .filter((dept) => dept.departmentUsers?.includes(user._id))
        .map((dept) => dept.departmentName);
  
      const enrichedTasks = tasksData.tickets.map((task) => {
        const totalSeconds = task.timp
          ? task.timp.reduce((sum, record) => sum + record.seconds, 0)
          : 0;
  
        const assignedUsers = Array.isArray(task.persoana) ? task.persoana : [task.persoana];
        const userNames = assignedUsers.map((userId) => usersMap[userId]?.name || "Unknown User");
        const userAvatars = assignedUsers.map((userId) => usersMap[userId]?.avatarUrl || "");
  
        return {
          ...task,
          userNames: userNames.length > 2
            ? `${userNames.slice(0, 2).join(", ")} and ${userNames.length - 2} more`
            : userNames.join(", "),
          userAvatars,
          formattedFinishDate: task.dataEnd ? formatDateToLongMonth(task.dataEnd) : "Invalid Date",
          duration: formatDuration(totalSeconds),
        };
      });
  
      const filteredTasks = user.role === "Admin"
        ? enrichedTasks
        : enrichedTasks.filter((task) =>
            task.department.some((dept) => {
              const mappedDept = departmentMapping[dept] || dept;
              return userDepartmentNames.includes(mappedDept);
            })
          );
  
      // Replace tasksWithUserNames instead of appending
      setTasksWithUserNames(filteredTasks);
      setHasMore(tasksData.currentPage < tasksData.totalPages);
    }
  }, [tasksData, usersData, departmentData, user]);
  

  useEffect(() => {
    const socket = io(backend_URL);
    window.socket = socket;

    socket.on("userPing", (data) => {
      refetchUsers();
      refetchDepartments();
    });

    socket.on("ticketPing", (data) => {
      refetchTasks();
    });

    socket.on("projectPing", (data) => {
      refetchProjects();
    });


    return () => {
      socket.disconnect();
    };
  }, []);

  

  const departmentMapping = {
    Development: "DEV",
    "Graphic Design": "SMM",
    Copywriter: "SMM",
    SEO: "SEO",
    PPC: "PPC",
    SMM: "SMM",
  };

  function formatDuration(seconds) {
    if (typeof seconds !== "number" || isNaN(seconds)) {
      return "Invalid duration";
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours === 0 ? `${minutes} min` : `${hours}:${minutes.toString().padStart(2, "0")} h`;
  }

  function formatDateToLongMonth(dateString) {
    const date = new Date(dateString);
    return format(date, "d MMM");
  }

  useEffect(() => {
    if (tasksData && usersData?.users && departmentData) {
      const usersMap = usersData.users.reduce((acc, user) => {
        acc[user._id] = {
          name: user.name,
          avatarUrl: user.avatar?.url || "",
        };
        return acc;
      }, {});

      const userDepartmentNames = departmentData.departments
        .filter((dept) => dept.departmentUsers?.includes(user._id))
        .map((dept) => dept.departmentName);

      const enrichedTasks = tasksData.tickets.map((task) => {
        const totalSeconds = task.timp
          ? task.timp.reduce((sum, record) => sum + record.seconds, 0)
          : 0;

        const assignedUsers = Array.isArray(task.persoana) ? task.persoana : [task.persoana];
        const userNames = assignedUsers.map((userId) => usersMap[userId]?.name || "Unknown User");
        const userAvatars = assignedUsers.map((userId) => usersMap[userId]?.avatarUrl || "");

        return {
          ...task,
          userNames: userNames.length > 2
            ? `${userNames.slice(0, 2).join(", ")} and ${userNames.length - 2} more`
            : userNames.join(", "),
          userAvatars,
          formattedFinishDate: task.dataEnd ? formatDateToLongMonth(task.dataEnd) : "Invalid Date",
          duration: formatDuration(totalSeconds),
        };
      });

      const filteredTasks = user.role === "Admin"
        ? enrichedTasks
        : enrichedTasks.filter((task) =>
            task.department.some((dept) => {
              const mappedDept = departmentMapping[dept] || dept;
              return userDepartmentNames.includes(mappedDept);
            })
          );

      setTasksWithUserNames((prevTasks) => [...prevTasks, ...filteredTasks]);
      setHasMore(tasksData.currentPage < tasksData.totalPages);
    }
  }, [tasksData, usersData, departmentData, user]);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setIsTaskViewOpen(true);
  };

  const handleTaskViewClose = () => {
    setIsTaskViewOpen(false);
    setSelectedTask(null);
  };

  const { data: projectsData, isLoading: projectsLoading, error: projectsError, refetch: refetchProjects
   } = useGetProjectsQuery();
  const getProjectName = (projectId) => {
    const project = projectsData?.projects.find((p) => p._id === projectId);
    return project?.nume || "Unknown Project";
  };

  const loadMoreTasks = () => setPage((prevPage) => prevPage + 1);

  if (tasksLoading || usersLoading || departmentsLoading) return <div>Loading...</div>;
  if (tasksError) return <div>Error fetching tasks: {tasksError.message}</div>;
  if (usersError) return <div>Error fetching users: {usersError.message}</div>;

  return (
    <div className="w-full">
      <div className="mt-[24px] bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[21px] pb-[16px]">
        <div className="flex justify-between">
          <div className="pt-[25px] ml-[33px]">
            <div className="text-[#000000] dark:text-[#EDEDFB] text-3xl font-normal">Last tasks</div>
            <div className="text-[#000000] dark:text-[#EDEDFB] text-xs font-normal mt-[12px]">
              {tasksWithUserNames.length} total tasks
            </div>
          </div>
          <div className="flex mt-[33px] mr-[33px]">
            <div className="flex flex-col items-center">
              <div className="text-black dark:text-[#EDEDFB] text-[32px] font-normal">
                {tasksData.completedCount}
              </div>
              <div className="cursor-pointer text-black dark:text-[#EDEDFB]" style={{ fontSize: 13 }}>
                Done
              </div>
            </div>
            <Divider orientation="vertical" className="mx-[20px] dark:bg-[#D1D6F4]" />
            <div className="flex flex-col items-center">
              <div className="text-black dark:text-[#EDEDFB] text-[32px] font-normal">
                {tasksData.inProgressCount}
              </div>
              <div className="cursor-pointer text-black dark:text-[#EDEDFB]" style={{ fontSize: 13 }}>
                In progress
              </div>
            </div>
          </div>
        </div>
        <TableContainer
          className="mt-[30px] bg-[#E7E7F9] dark:bg-[#3A3A68] px-[32px] rounded-[21px] pb-4 h-[350px] scrollbar-thin overflow-y-scroll w-full"
        >
          <Table
            sx={{
              width: "100%",
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
                fontSize: 14,
                fontFamily: "Inter",
                whiteSpace: "normal",
                wordWrap: "break-word",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="cursor-pointer text-black dark:text-[#EDEDFB] sticky top-0 bg-[#E7E7F9] dark:bg-[#3A3A68] z-[10]"
                  onClick={() => handleSort("nume")}
                  style={{ width: "200px", maxWidth: "200px", padding: "8px" }}
                >
                  Task name
                </TableCell>
                <TableCell
                  className="cursor-pointer text-black dark:text-[#EDEDFB] sticky top-0 bg-[#E7E7F9] dark:bg-[#3A3A68] z-[10]"
                  onClick={() => handleSort("userNames")}
                  style={{ width: "160px", maxWidth: "160px", padding: "8px" }}
                >
                  Assigned
                </TableCell>
                <TableCell
                  className="cursor-pointer text-black dark:text-[#EDEDFB] sticky top-0 bg-[#E7E7F9] dark:bg-[#3A3A68] z-[10]"
                  onClick={() => handleSort("userNames")}
                  style={{ width: "150px", maxWidth: "150px", padding: "8px" }}
                >
                  Project
                </TableCell>
                <TableCell
                  className="cursor-pointer text-black dark:text-[#EDEDFB] sticky top-0 bg-[#E7E7F9] dark:bg-[#3A3A68] z-[10]"
                  onClick={() => handleSort("status")}
                  style={{ maxWidth: "120px", width: "120px", padding: "8px" }}
                >
                  Status
                </TableCell>
                <TableCell
                  className="cursor-pointer text-black dark:text-[#EDEDFB] sticky top-0 bg-[#E7E7F9] dark:bg-[#3A3A68] z-[10]"
                  onClick={() => handleSort("duration")}
                  style={{ maxWidth: "100px", width: "100px", padding: "8px" }}
                >
                  Run time
                </TableCell>
                <TableCell
                  className="cursor-pointer text-black dark:text-[#EDEDFB] sticky top-0 bg-[#E7E7F9] dark:bg-[#3A3A68] z-[10]"
                  onClick={() => handleSort("formattedFinishDate")}
                  style={{ maxWidth: "120px", padding: "8px" }}
                >
                  Due date
                </TableCell>
                <TableCell
                  className="cursor-pointer text-black dark:text-[#EDEDFB] sticky top-0 bg-[#E7E7F9] dark:bg-[#3A3A68] z-[10]"
                  onClick={() => handleSort("createdAt")}
                  style={{ width: "100px", maxWidth: "120px", padding: "8px" }}
                >
                  {sortConfig.key === "createdAt" && sortConfig.direction === "desc" ? "Latest" : "Oldest"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasksWithUserNames.map((task) => (
                <TableRow key={task._id}>
                  <TableCell
                    className="text-black dark:text-[#EDEDFB] cursor-pointer"
                    onClick={() => handleTaskClick(task)}
                    style={{
                      maxWidth: "180px",
                      width: "180px",
                      padding: "8px",
                    }}
                  >
                    {task.nume}
                  </TableCell>
                  <TableCell
                    className="text-black dark:text-[#EDEDFB]"
                    style={{
                      width: "100px",
                      maxWidth: "100px",
                      padding: "8px",
                    }}
                  >
                    {isMobileView || task.userAvatars.length > 2 ? (
                      <div className="flex">
                        {task.userAvatars?.map((avatarUrl, index) => (
                          <Avatar
                            key={index}
                            alt={`User Avatar ${index}`}
                            src={avatarUrl}
                            sx={{
                              width: 24,
                              height: 24,
                              marginLeft: index === 0 ? 0 : -1,
                              border: "2px solid #fff",
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-row gap-2">
                        <div className="flex">
                          {task.userAvatars?.map((avatarUrl, index) => (
                            <Avatar
                              key={index}
                              alt={`User Avatar ${index}`}
                              src={avatarUrl}
                              sx={{
                                width: 24,
                                height: 24,
                                marginLeft: index === 0 ? 0 : -1,
                                border: "2px solid #fff",
                              }}
                            />
                          ))}
                        </div>
                        <span>{task.userNames}</span>
                      </div>
                    )}
                  </TableCell>
                  <TableCell
                    className="text-black dark:text-[#EDEDFB] cursor-pointer"
                    onClick={() => handleTaskClick(task)}
                    style={{
                      width: "120px",
                      maxWidth: "120px",
                      padding: "8px",
                    }}
                  >
                    {getProjectName(task.proiect)}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "120px",
                      maxWidth: "120px",
                      padding: "8px",
                    }}
                  >
                    <div
                      className={`text-white rounded-[33px] flex justify-center items-center w-fit px-[12px] py-[4px] ${
                        task.status === "In Progress" ? "bg-[#C026D3]" : task.status === "To do" ? "bg-[#2ecc71]" : "bg-[#f472b6]"
                      }`}
                    >
                      {task.status}
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-black dark:text-[#EDEDFB]"
                    style={{
                      width: "100px",
                      maxWidth: "100px",
                      padding: "8px",
                    }}
                  >
                    {task.duration}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "110px",
                      maxWidth: "120px",
                      padding: "8px",
                    }}
                  >
                    <div className={`bg-[#f39c12] text-white rounded-[33px] flex justify-center items-center w-fit px-[12px] py-[4px]`}>
                      {task.formattedFinishDate}
                    </div>
                  </TableCell>
                  <TableCell
                    className="text-black dark:text-[#EDEDFB]"
                    style={{ maxWidth: "100px", padding: "8px" }}
                  >
                    {formatDateToLongMonth(task.createdAt)}
                  </TableCell>
                </TableRow>
              ))}
              {hasMore && (
                <TableRow>
                <TableCell colSpan={6} className="text-center">
                  <span
                    onClick={loadMoreTasks}
                    className="cursor-pointer text-black dark:text-[#EDEDFB] hover:underline"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      padding: "8px",
                    }}
                  >
                    Load More
                  </span>
                </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedTask && <TaskViewMode ticket={selectedTask} openNow={isTaskViewOpen} onClose={handleTaskViewClose} />}
      </div>
    </div>
  );
};
