import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import backend_URL from "../../../config"; // Assuming backend_URL is defined in your config

// Define the base query
const baseQuery = fetchBaseQuery({
  baseUrl: `${backend_URL}/api/v1`, // Assuming your API is at /api/v1
  prepareHeaders: (headers) => {
    const token = Cookies.get("token"); // Get token from cookies for authorization
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
// Utility function to serialize filters
const serializeFilters = (filters) => {
  const queryString = Object.keys(filters)
    .map((key) => {
      const value = filters[key];
      if (typeof value === "object" && value !== null) {
        return Object.keys(value)
          .map(
            (subKey) =>
              `${encodeURIComponent(key)}[${encodeURIComponent(
                subKey
              )}]=${encodeURIComponent(value[subKey])}`
          )
          .join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
  return queryString;
};

// Define the products API
export const productsApi = createApi({
  reducerPath: "productsApi", // Unique key to mount the API slice in Redux
  baseQuery,
  endpoints: (builder) => ({
    // Fetch all products with optional filters
    getProducts: builder.query({
      query: (filters) => {
        const queryString = serializeFilters(filters);
        return {
          url: `/products?${queryString}`,
          method: "GET",
        };
      },
    }),
    // Fetch a single product by ID
    getProductById: builder.query({
      query: (productId) => ({
        url: `/products/${productId}`,
        method: "GET",
      }),
    }),

    // Create a new product
    postProduct: builder.mutation({
      query: (product) => ({
        url: "/products",
        method: "POST",
        body: product, // Send the product data in the request body
      }),
    }),

    // Update an existing product by ID
    updateProduct: builder.mutation({
      query: ({ productId, productData }) => ({
        url: `/products/${productId}`,
        method: "PUT",
        body: productData, // Send the updated product data in the request body
      }),
    }),

    // Delete one or more products (assuming you pass an array of IDs)
    deleteProducts: builder.mutation({
      query: (productIds) => ({
        url: "/products",
        method: "DELETE",
        body: { productIds }, // Send the product IDs in the request body for deletion
      }),
    }),
  }),
});

// Export hooks for the products API
export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  usePostProductMutation,
  useUpdateProductMutation,
  useDeleteProductsMutation,
} = productsApi;
