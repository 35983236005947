import React, { useState, useEffect } from 'react';

const ImageUpload = ({
  onUpload,        // Prop to handle the image upload
  onDelete,        // Prop to handle image deletion
  imageUrl = null, // The image URL in case we are in edit mode (can be a base64 string)
  uploadText = "Upload an image" // Custom text to show above the image upload area
}) => {
  const [imagePreview, setImagePreview] = useState(imageUrl); // Use the imageUrl for edit mode (can be base64)

  useEffect(() => {
    setImagePreview(imageUrl); // Update image preview when imageUrl changes
  }, [imageUrl]);

  // Handle the image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result; // Full base64 string with prefix
        setImagePreview(base64String); // Set image preview with base64 string
        onUpload(base64String); // Send the full base64 string to the parent component
      };
      reader.readAsDataURL(file); // Read file as Data URL (base64 encoded)
    }
  };

  // Handle image delete
  const handleImageDelete = () => {
    setImagePreview(null);
    onDelete(); // Trigger the onDelete action in the parent component
  };

  return (
    <div className="flex flex-row items-center justify-start gap-4 w-full">
      {/* Text and Image Container on the same row */}
      <div className="text-[#4A488E] dark:text-[#EDEDFB] text-[16px] font-normal">
        {uploadText}
      </div>
      <div className="pl-10 relative">
        {imagePreview ? (
          // Show the image preview with a delete "X" button
          <div className="relative w-[150px] h-[150px]">
            <img
              src={imagePreview} // Render the base64 Data URL as the image source
              alt="Uploaded preview"
              className="object-cover w-full h-full rounded-[8px] border border-[#E9E9FF] dark:border-[#3A3A68]"
            />
            <button
              onClick={handleImageDelete}
              className="absolute top-0 right-0 text-white text-lg bg-transparent"
            >
              &times;
            </button>
          </div>
        ) : (
          // Show the upload square when there's no image
          <label
            className="w-[150px] h-[150px] flex items-center justify-center cursor-pointer bg-[#E9E9FF] dark:bg-[#3A3A68] rounded-[8px] border border-[#CAC9F1] dark:border-[#5A5A8E]"
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange} // Handle image file change
              className="hidden"
            />
            <div className="text-[#4A488E] dark:text-[#EDEDFB]">+</div>
          </label>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
