import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import backend_URL from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const departmentsApi = createApi({
  reducerPath: "departmentsApi",
  baseQuery,
  tagTypes: ["Departments"],
  endpoints: (builder) => ({
    getDepartments: builder.query({
      providesTags: ["Departments"],
      query() {
        return {
          url: "/departments",
          method: "GET",
        };
      },
    }),
    getDepartment: builder.query({
      query(id) {
        return {
          url: `/departments/${id}`,
          method: "GET",
        };
      },
    }),
    createDepartment: builder.mutation({
      query(department) {
        return {
          url: "/departments",
          method: "POST",
          body: department,
        };
      },
    }),
    updateDepartment: builder.mutation({
      query({ id, ...department }) {
        return {
          url: `/departments/${id}`,
          method: "PUT",
          body: department,
        };
      },
    }),
    deleteDepartment: builder.mutation({
      query(id) {
        return {
          url: `/departments/${id}`,
          method: "DELETE",
        };
      },
    }),
    addUserToDepartment: builder.mutation({
      query({ id, userId }) {
        return {
          url: `/departments/${id}/users`,
          method: "POST",
          body: { userId },
        };
      },
    }),
    removeUserFromDepartment: builder.mutation({
      query({ id, userId }) {
        return {
          url: `/departments/${id}/users/${userId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Departments"],
    }),
    updateDepartmentPermissions: builder.mutation({
      query({ id, permissions }) {
        return {
          url: `/departments/${id}/update-permissions`,
          method: "PUT",
          body: { permissions },
        };
      },
      invalidatesTags: ["Departments"],
    }),
    getDepartmentsForUser: builder.query({
      query({ id }) {
        return {
          url: `/departments/user/${id}`,
          method: "GET",
        };
      },
    }),
    getVerificationForUserAccessInDepartment: builder.query({
      query({ id, departmentNames }) {
        return {
          url: `/departments/user/${id}/hasAccess`,
          method: "POST",  // Changed to POST
          body: { departmentNames },  // Include departmentNames in the body
        };
      },
    }),    
    // Changed from mutation to query
    getDepartmentPermissionByUser: builder.query({
      query({ id }) {
        return {
          url: `/departments/user/${id}/permissions`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetVerificationForUserAccessInDepartmentQuery,
  useGetDepartmentsForUserQuery,
  useGetDepartmentQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useAddUserToDepartmentMutation,
  useRemoveUserFromDepartmentMutation,
  useGetDepartmentPermissionByUserQuery, // Updated from Mutation to Query
  useUpdateDepartmentPermissionsMutation,
} = departmentsApi;
