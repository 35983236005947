import { Breadcrumbs, Drawer, Tooltip } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { IoFilter } from "react-icons/io5";
import { motion } from "framer-motion";
import TicketForm from "./TicketForm";
import Dropdown from "./Dropdown/Dropdown";
import { useGetAllUsersQuery } from "../redux/api/authApi";
import CardUserManagement from "./CardUserManagement";
import FormUserManagement from "./FormUserManagement"; // Add User form
import NotificationComponent from "./NotificationComponent";
import SearchBar from "./utils/SearchBar";
import { ViewTypeContext } from "./utils/contexts/viewTypeContext";

const UserManagement = () => {
  const isMobileView = useContext(ViewTypeContext); // Get the view type from the context
  const { data } = useGetAllUsersQuery();
  const [users, setUsers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [sortOption, setSortOption] = useState("alphabetical");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (data && data.users) {
      sortUsers(data.users, sortOption);
    }
  }, [data, sortOption]);

  const sortUsers = (users, option) => {
    let sortedUsers;
    if (option === "alphabetical") {
      sortedUsers = [...users].sort((a, b) => a.name.localeCompare(b.name));
    } else if (option === "creationDate") {
      sortedUsers = [...users].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    }
    setUsers(sortedUsers);
    setSearchResults(sortedUsers);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setDropdownOpen(false);
  };

  return (
    <div
      className={`pr-4 ${
        isMobileView ? "pl-4" : ""
      } pt-[25px] h-[100vh] overflow-y-auto scrolling-touch bg-[#FFF] dark:bg-[#2B2A49]`}
    >
      <div className="w-full px-4 md:mb-40">
        <div className="bg-white p-4 flex dark:bg-[#2B2A49] justify-between">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className={`w-full ${
                isMobileView ? "text-sm" : "w-[400px]"
              } text-[#7674C2] dark:text-[#EDEDFB]`}
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/adminpanel`}
              >
                Admin Panel
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/AdminPanel/UserManagement`}
              >
                <b>User-Management</b>
              </Link>
            </Breadcrumbs>
          </ul>
          {!isMobileView && <NotificationComponent />}
        </div>
        <p
          className={`text-[${
            isMobileView ? "24px" : "30px"
          }] text-[#4A488E] mt-[20px] mb-[20px] ml-[10px] dark:text-[#EDEDFB]`}
        >
          User management
        </p>
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-4">
            <div className="relative">
              <SearchBar data={users} onSearchResults={setSearchResults} />
            </div>
          </div>
          <div className="flex gap-5 items-center">
            <div className="relative">
              <Tooltip title="Sort users">
                <button
                  className="flex items-center justify-center text-gray-600 dark:text-white"
                  onClick={toggleDropdown}
                >
                  <IoFilter size={isMobileView ? 20 : 24} />
                </button>
              </Tooltip>
              {dropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="absolute right-0 mt-2 w-48 bg-white dark:bg-[#2B2A49] shadow-lg rounded-md z-10"
                >
                  <ul>
                    <li
                      className={`p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 ${
                        sortOption === "alphabetical"
                          ? "font-bold text-gray-800 dark:text-white"
                          : ""
                      }`}
                      onClick={() => handleSortChange("alphabetical")}
                    >
                      Alphabetically
                    </li>
                    <li
                      className={`p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 ${
                        sortOption === "creationDate"
                          ? "font-bold text-gray-800 dark:text-white"
                          : ""
                      }`}
                      onClick={() => handleSortChange("creationDate")}
                    >
                      Creation Date
                    </li>
                  </ul>
                </motion.div>
              )}
            </div>
            {/* Ensure Add User button is visible on all screens */}
            <FormUserManagement />
          </div>
        </div>
        {searchResults.map((user) => (
          <CardUserManagement key={user._id} user={user} />
        ))}
      </div>
    </div>
  );
};

export default UserManagement;
