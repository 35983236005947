import React, { useContext } from "react";
import { Breadcrumbs, Tooltip, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetMeQuery } from "../../redux/api/userApi";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import { useGetDepartmentsQuery } from "../../redux/api/departmentsApi";
import { useSelector } from "react-redux";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";

export const EmployeeDetails = () => {
  const { data } = useGetMeQuery();
  const userData = useSelector((state) => state.user.user);

  const isMobileView = useContext(ViewTypeContext);
  const { data: departmentsData } = useGetDepartmentsQuery();

  const matchingDepartment = departmentsData?.departments.find((department) =>
    department.departmentUsers.includes(userData._id)
  );

  return (
    <div
      className={
        isMobileView
          ? "pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49] pl-[54px]"
          : "pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
      }
    >
      <div className="w-full px-[40px] md:mb-40">
        <div className=" p-4 flex items-center flex-wrap">
          <ul className="flex items-center ">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Link
                key="appName"
                underline="hover"
                className="text-[#7674C2] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                key="hr"
                underline="hover"
                className="text-[#7674C2] dark:text-[#EDEDFB]"
                to="/hr"
              >
                HR
              </Link>
              <Link
                key="employeeDetails"
                underline="hover"
                className="text-[#7674C2] dark:text-[#EDEDFB]"
                to="/hr/Employee-details"
              >
                Employee Details
              </Link>
            </Breadcrumbs>
          </ul>
        </div>
        {/* <h1 className="mt-[48px] text-[30px] text-[#555]">{data?.name}</h1> */}
        <div className="text-stone-500 dark:text-[#EDEDFB] text-3xl font-normal mt-[40px] mb-[53px] pl-4">
          {data?.name}
        </div>
        <div className="flex flex-col gap-[40px] pl-4">
          <div className="flex flex-row justify-between">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              Start date
            </div>
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              {dayjs(data?.DataStart).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="flex flex-row justify-between ">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              Job title
            </div>
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              {data?.job}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              Department
            </div>
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              {matchingDepartment?.departmentName}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              Seniority
            </div>
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              {data?.seniority}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              Email adress
            </div>
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              {data?.email}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] flex items-center pl-4">
              Address
              <Tooltip title="Change address in profile settings">
                <IconButton>
                  <InfoIcon fontSize="small" className="text-[#8D8BDB] " />
                </IconButton>
              </Tooltip>
            </div>
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              {data?.address}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] flex items-center pl-4">
              Vacation Days
            </div>
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              {data?.vacationDays}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-zinc-500 text-lg font-normal dark:text-[#EDEDFB] pl-4">
              Contract
            </div>
          </div>
          <div className="flex flex-row gap-4">
            {data?.contracte?.map((url, index) => (
              <a href={url} download target="_blank" rel="noopener noreferrer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 h-12 text-[#8D8BDB] dark:text-[#EDEDFB]"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M19 2H8c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h8l6-6V4c0-1.1-.9-2-2-2zM12 19c-1.1 0-2-.9-2-2h4c0 1.1-.9 2-2 2zm4-4H8V5h8v10z" />
                </svg>
                <span className="text-[#8D8BDB] dark:text-[#EDEDFB] text-sm font-normal mt-2 pl-4">
                  Download {index + 1}
                </span>
              </a>
            )) || null}
          </div>
        </div>
      </div>
    </div>
  );
};
