import React from "react";
import { useSelector } from "react-redux";
import { AppraisalAdmView } from "./AppraisalsAdmView";
import { AppraisalView } from './AppraisalsView'

export const Appraisal = () => {
  const user = useSelector((state) => state.user.user);

  if (!user) {
    return null;
  }

  return (
    <>
    <div className="md:mb-40">
    {user.role === "Admin" ? <AppraisalAdmView /> : <AppraisalView />}
    </div>
    </>
  );
};
