// OperationalReports.js

import React, { useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import NotificationComponent from "../NotificationComponent";
import Datepicker from "react-tailwindcss-datepicker";
import DropdownOperationalReportsTeam from "../Dropdown/DropdownOperationalReportsTeam";
import DropdownOperationalReportsClient from "../Dropdown/DropdownOperationalReportsClient";
import DropdownOperationalReportsProject from "../Dropdown/DropdownOperationalReportsProject";
import DropdownOperationalReportsTask from "../Dropdown/DropdownOperationalReportsTask";
import DropdownOperationalReportsTag from "../Dropdown/DropdownOperationalReportsTag";
import DropdownOperationalReportsStatus from "../Dropdown/DropdownOperationalReportsStatus";
import { PieChart } from "@mui/x-charts";
import {
  BarChart, // Remove this import if not used elsewhere
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts"; // Remove this import if not used elsewhere
import ProjectTimeTracking from "../ProjectTimeTracking";
import { useGetProjectsQuery } from "../../redux/api/projectsApi";
import { useGetTotalTimeWithFiltersQuery } from "../../redux/api/reports/reports";
import { useGetTasksQuery } from "../../redux/api/tasksApi";
import OperationalReportsDetails from "./OperationalReportsDetails";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
// Remove unused imports
// import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts"; // Import ReactApexChart

const OperationalReports = () => {
  const isMobileView = useContext(ViewTypeContext); // true daca e mobil/tableta

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  // Function to handle changes in theme
  const updateTheme = () => {
    const newTheme = localStorage.getItem("theme") || "light";
    setTheme(newTheme);
  };

  useEffect(() => {
    // Update theme on component mount
    updateTheme();

    // Set up a listener to update theme on storage changes
    const handleStorageChange = (event) => {
      if (event.key === "theme") {
        updateTheme();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Handle theme updates manually within the same tab
  useEffect(() => {
    const handleDocumentClick = () => {
      updateTheme();
    };

    // Listen for clicks or other interactions to manually trigger theme updates
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const [users, setUsers] = useState([]); // Handle multiple users
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tags, setTags] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [totalProjTime, setTotalProjTime] = useState(null);
  const { data: tasksData } = useGetTasksQuery();
  const { data: projectsData } = useGetProjectsQuery();

  // Get the current date
  const now = new Date();

  // Get the first and last days of the current month
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Initialize state with the current month's range as ISO strings
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfMonth.toISOString(), // Convert to ISO string
    endDate: endOfMonth.toISOString(), // Convert to ISO string
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const {
    data: totalTimeData,
    error: totalTimeError,
    isLoading: totalTimeLoading,
    refetch: refetchTotalTimeData,
  } = useGetTotalTimeWithFiltersQuery({
    userIds: users,
    clientIds: clients,
    projectIds: projects,
    taskIds: tasks,
    tags: tags,
    statuses: statuses,
    startDate: selectedDate?.startDate,
    endDate: selectedDate?.endDate,
    page: currentPage,
    limit: itemsPerPage,
  });
  const totalPages = Math.ceil(
    totalTimeData?.pagination?.totalProjects / itemsPerPage
  );

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const clearFilters = () => {
    setUsers([]);
    setClients([]);
    setProjects([]);
    setTasks([]);
    setTags([]);
    setStatuses([]);
  };

  const [isDetalied, setIsDetalied] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setIsDetalied(true);
    } else {
      setIsDetalied(false);
    }
  }, [id]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
  const toggleDropdown = (dropdownId) => {
    setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
  };

  // Function to calculate total time in seconds
  function calculateTotalTime(projects) {
    // Sum up all totalSeconds from the projects array
    const totalSeconds = projects?.reduce(
      (acc, project) => acc + project.totalSeconds,
      0
    );

    // Convert total seconds to hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.round(totalSeconds % 60);

    return { hours, minutes, seconds, totalSeconds };
  }

  // Get the total time
  const totalTime = calculateTotalTime(totalTimeData?.projects || []);

  const projectColors = {
    "Fuse Digital": "#02b2af", // Assign color for "Fuse Digital"
    "Farmacia Farmex DL": "#2e96ff", // Assign color for "Project B"
    "Digital Assets": "#b800d8", // Assign color for "Project C"
    "DR Kurbe Center": "#60009b", // Assign color for "Project C"
    "HPS - Horeca Packaging Solutions": "#2731c8", // Assign color for "Project C"
    "FC Juniorul": "#03008d", // Assign color for "Project C"
    "DEER - Distributie Energie Electrica Romania": "#FF4500", // OrangeRed
    "Tomas Electric": "#FFD700", // Gold
    "Optica Tei": "#8A2BE2", // BlueViolet
    "Sales Projects - Generic": "#FF69B4", // HotPink
    "Self Consulting": "#00FA9A", // MediumSpringGreen
    Shen: "#FF6347", // Tomato
    "Mawada Kurbe Personal SMM": "#7FFF00", // Chartreuse
    "Nada Kurbe Personal SMM": "#1E90FF", // DodgerBlue
  };

  // Function to generate a random hex color
  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Function to get the color for a project
  const getProjectColor = (projectName) => {
    // Check if the project has a predefined color, otherwise generate a new one
    if (!projectColors[projectName]) {
      projectColors[projectName] = generateRandomColor(); // Assign the new random color
    }
    return projectColors[projectName];
  };

  const pieChartData =
    totalTimeData?.projects?.map((project, index) => ({
      id: index,
      value: (project.totalSeconds / 3600).toFixed(2),
      label: project.projectName,
      color: projectColors[project.projectName] || generateRandomColor(), // Default color if not in the mapping
    })) || [];

  // Helper function to convert seconds to hours, minutes, and seconds
  const convertSecondsToTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.round(totalSeconds % 60);
    return { hours, minutes, seconds };
  };

  const getProjectTimeForUsers = (projectId, usersArray, tasksData) => {
    // Filter tasks related to the project by 'proiect' key
    const projectTickets = tasksData?.tickets.filter(
      (ticket) => ticket.proiect === projectId
    );

    // Initialize total seconds accumulator
    let totalSeconds = 0;

    // Iterate through each task's 'timp' array and accumulate time
    projectTickets?.forEach((ticket) => {
      ticket.timp?.forEach((timpEntry) => {
        // Ignore time entries that have 'isStarted: true'
        if (
          !timpEntry.isStarted &&
          (usersArray.length === 0 || usersArray.includes(timpEntry.user))
        ) {
          totalSeconds += timpEntry.seconds;
        }
      });
    });

    // Convert total seconds to hours, minutes, and seconds
    const totalTime = convertSecondsToTime(totalSeconds);

    return { totalSeconds, totalTime };
  };

  // Accumulate total seconds for all filtered projects
  const totalSecondsProj = totalTimeData?.projects?.reduce((acc, project) => {
    const time = getProjectTimeForUsers(project.projectId, users, tasksData);
    return acc + time.totalSeconds;
  }, 0);

  // Convert total seconds to HH:MM:SS format
  const totalTimeAllProj = convertSecondsToTime(totalSecondsProj);

  // Modified getTimeSeriesForPeriod function
  const getTimeSeriesPerProject = (
    totalTimeData,
    selectedDate,
    users,
    tasksData
  ) => {
    const startDate = new Date(selectedDate.startDate);
    const endDate = new Date(selectedDate.endDate);
    const allDates = [];

    // Generate all dates within the selected range
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      allDates.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const projects = totalTimeData?.projects || [];
    const series = projects.map((project) => {
      const projectId = project.projectId;
      const projectName = project.projectName;

      // Initialize data array with 0 for all dates
      const data = allDates.map(() => 0);

      // Get tickets related to the project
      const projectTickets = tasksData?.tickets.filter(
        (ticket) => ticket.proiect === projectId
      );

      // Accumulate time per date for the project
      projectTickets?.forEach((ticket) => {
        ticket.timp?.forEach((timpEntry) => {
          if (
            !timpEntry.isStarted &&
            (users.length === 0 || users.includes(timpEntry.user))
          ) {
            if (timpEntry.dataStart && timpEntry.dataEnd && timpEntry.seconds) {
              const entryStartDate = new Date(timpEntry.dataStart);
              const entryEndDate = new Date(timpEntry.dataEnd);
              let entryDate = new Date(entryStartDate);

              while (entryDate <= entryEndDate) {
                const formattedDate = entryDate.toISOString().split("T")[0];
                if (
                  formattedDate >= selectedDate.startDate &&
                  formattedDate <= selectedDate.endDate
                ) {
                  const dateIndex = allDates.indexOf(formattedDate);
                  if (dateIndex !== -1) {
                    data[dateIndex] += timpEntry.seconds / 3600;
                    data[dateIndex] = parseFloat(data[dateIndex].toFixed(2)); // Convert seconds to hours
                  }
                }
                entryDate.setDate(entryDate.getDate() + 1);
              }
            }
          }
        });
      });

      return { name: projectName, data };
    });

    return { series, categories: allDates };
  };

  // Generate ApexCharts series and categories
  const { series: apexSeries, categories: apexCategories } =
    getTimeSeriesPerProject(totalTimeData, selectedDate, users, tasksData);

  // Assign colors based on project names
  const fillColors = apexSeries.map(
    (seriesItem) => projectColors[seriesItem.name] || generateRandomColor()
  );

  // Calculate the maximum 'ore' value for y-axis scaling
  const maxOre = Math.max(
    ...apexSeries.flatMap((seriesItem) => seriesItem.data)
  );
  const yAxisMax = maxOre > 0 ? Math.ceil(maxOre * 1.1) : 10; // Adjust as needed
  const fillColorsChart =
    theme === "dark"
      ? [
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
          "#fff",
        ] // Dark mode colors
      : [];
  const fillColorsChartYAxis =
    theme === "dark"
      ? ["#fff"] // Dark mode colors
      : [];
  // Define ApexCharts options
  const apexOptions = {
    chart: {
      type: "bar",

      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    sparkline: {
      enabled: true,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    grid: {
      show: false, // This disables the grid lines
    },
    xaxis: {
      show: false,
      categories: apexCategories,
      labels: {
        formatter: function (value) {
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
          /*  return value; */
        },
        style: {
          colors: fillColorsChart,
          fontSize: "12px",
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    fill: {
      opacity: 1,
      colors: fillColors,
    },
    yaxis: {
      min: 0,
      max: yAxisMax,
      title: {
        text: "Hours",
      },
      labels: {
        style: {
          colors: fillColorsChartYAxis,
          fontSize: "8px",
          cssClass: "apexcharts-yaxis-label",
        },
      },
    },

    tooltip: {
      followCursor: true,
      y: {
        formatter: function (val) {
          const hours = Math.floor(val);
          const minutes = Math.round((val - hours) * 60);
          return `${hours}h ${minutes}m`;
        },
      },
    },
  };
  const LoadingSpinner = () => {
    return (
      <div className="w-full min-h-screen flex justify-center items-center mt-0">
        <div className="w-20 h-20 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  };

  if (!totalTimeData) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <div className="w-full h-screen pb-48 xl:pb:0  dark:bg-[#2B2A49] py-[31px] pl-0 px-[30px] overflow-auto">
        <div className="flex justify-between pb-[25px]">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-[380px] text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/reports/operational-reports`}
              >
                Reports / <b> Operational reports</b>
              </Link>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>

        <div className="w-full pb-[45px]">
          <h1 className="dark:text-[#EDEDFB] text-[#4A488E] text-[30px]">
            Operational reports
          </h1>
        </div>

        <div className="w-full flex justify-end pb-[35px]">
          <div className="w-full flex items-center">
            <button
              className={`py-[11px] px-[15px] font-medium rounded-l-[6px] text-[18px] ${
                !isDetalied
                  ? "dark:bg-[#C7C7F4] bg-[#FFF] text-[#484878] dark:text-[#2D2A5B]" // Active for Summary
                  : "dark:bg-[#E7E7F9] bg-[#E7E7F9] text-[#484878]  dark:text-[#4A488E]" // Inactive for Summary
              }`}
              onClick={() => setIsDetalied(false)}
            >
              Summary
            </button>
            <button
              className={`py-[11px] px-[15px] font-medium rounded-r-[6px] text-[18px] ${
                isDetalied
                  ? "dark:bg-[#C7C7F4] bg-[#FFF] text-[#484878] dark:text-[#2D2A5B]" // Active for Detailed
                  : "dark:bg-[#E7E7F9] bg-[#E7E7F9] text-[#484878] dark:text-[#4A488E]" // Inactive for Detailed
              }`}
              onClick={() => setIsDetalied(true)}
            >
              Detailed
            </button>
          </div>
          <div className="flex gap-[24px] items-center w-[335px]">
            <Datepicker
              showShortcuts={true}
              value={selectedDate}
              onChange={handleDateChange}
              asSingle={false}
              displayFormat={"YYYY-MM-DD"}
              className="h-[55px] bg-[#E8E8FF] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg"
            />
          </div>
        </div>

        <div className="dark:bg-[#3A3A68] bg-[#E7E7F9] flex py-[17px] px-[19px] rounded-[17px] justify-between mb-[35px]">
          <div>
            <DropdownOperationalReportsTeam
              setUsers={setUsers}
              users={users}
              isOpen={openDropdown === "team"}
              toggleDropdown={() => toggleDropdown("team")}
            />
            <DropdownOperationalReportsClient
              setClients={setClients}
              clients={clients}
              isOpen={openDropdown === "client"}
              toggleDropdown={() => toggleDropdown("client")}
            />
            <DropdownOperationalReportsProject
              setProjects={setProjects}
              projects={projects}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              isOpen={openDropdown === "project"}
              toggleDropdown={() => toggleDropdown("project")}
            />
            <DropdownOperationalReportsTask
              tasks={tasks}
              setTasks={setTasks}
              isOpen={openDropdown === "task"}
              toggleDropdown={() => toggleDropdown("task")}
            />
            <DropdownOperationalReportsTag
              setTags={setTags}
              tag={tags}
              isOpen={openDropdown === "tag"}
              toggleDropdown={() => toggleDropdown("tag")}
            />
            <DropdownOperationalReportsStatus
              setStatuses={setStatuses}
              statuses={statuses}
              isOpen={openDropdown === "status"}
              toggleDropdown={() => toggleDropdown("status")}
            />
          </div>
        </div>

        <div className="w-full flex justify-end mb-[35px]">
          {/* You can uncomment the Clear filter button if needed */}
          {/* <p
            onClick={clearFilters}
            className="dark:text-[#F7F6FF] text-[#6969AC] text-[13px]"
          >
            Clear filter
          </p> */}
        </div>
        {isDetalied === true ? (
          <>
            <OperationalReportsDetails
              users={users}
              tasks={tasks}
              clients={clients}
              projects={projects}
              setProjects={setProjects}
              setSelectedProjects={setSelectedProjects}
              tags={tags}
              statuses={statuses}
              totalTime={totalTimeData?.combinedTotalTime}
              selectedDate={selectedDate}
            />
          </>
        ) : (
          <>
            <div className=" flex  pt-[15px] px-[20px] pb-[15px] dark:bg-[#3A3A68] mb-10 bg-[#E7E7F9] rounded-[20px]">
              <ReactApexChart
                options={apexOptions}
                series={apexSeries}
                type="bar"
                height={400}
                className="w-full text-white dark:text-white"
              />
            </div>
            <div className="w-full pt-[15px] px-[20px] pb-[15px] dark:bg-[#3A3A68] bg-[#E7E7F9] rounded-[20px]">
              <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px] w-fit ">
                Total time: {totalTimeData?.combinedTotalTime}
              </p>
              <div className="w-full flex gap-[20px]">
                <div className="w-[70%]">
                  {/* Render current items */}
                  {totalTimeData?.projects?.map((project, index) => (
                    <ProjectTimeTracking
                      time={getProjectTimeForUsers(
                        project.projectId,
                        users,
                        tasksData
                      )}
                      key={index}
                      project={project}
                      setIsDetalied={setIsDetalied}
                    />
                  ))}
                </div>
                <div className="min-w-[600px]">
                  <PieChart
                    className="relative"
                    series={[
                      {
                        data: pieChartData,
                        innerRadius: 60,
                        outerRadius: 130,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        border: "none",
                        highlightScope: { fade: "global", highlight: "item" },
                        faded: {
                          innerRadius: 130,
                          additionalRadius: -50,
                          color: "gray",
                        },
                      },
                    ]}
                    width={600}
                    height={300}
                    slotProps={{ legend: { hidden: true } }}
                  />
                  <p
                    className={`dark:text-[#EDEDFB] text-[#8D8BDB] text-[20px] relative ${
                      isMobileView ? "left-[37.4%]" : "left-[38%]"
                    }  w-fit top-[-165px]`}
                  >
                    {totalTimeData?.combinedTotalTime}
                  </p>
                </div>
              </div>

              {/* Pagination Controls */}
            </div>
            <div className="flex w-full justify-end gap-[12px] items-center mt-4 text-[#AFAFDD]">
              {/* <span>
                {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                  currentPage * itemsPerPage,
                  totalTimeData?.pagination?.totalProjects
                )} of ${totalTimeData?.pagination?.totalProjects}`}
              </span> */}
              <div className="flex items-center gap-[7px]">
                {/* <button
                  className={`${
                    currentPage === 1
                      ? "text-gray-500 cursor-not-allowed"
                      : "text-white"
                  }`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  
                </button>
                <button
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-500 cursor-not-allowed"
                      : "text-white"
                  }`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
               
                </button> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OperationalReports;
