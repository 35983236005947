import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./features/userSlice";
import { authApi } from "./api/authApi";
import { userApi } from "./api/userApi";
import { tasksApi } from "./api/tasksApi";
import { clientsApi } from "./api/clientsApi";
import { projectsApi } from "./api/projectsApi";
import { commentsApi } from "./api/commentsApi";
import { providerApi } from "./api/provider";
import { accountsApi } from "./api/crm/accountsApi";
import { activitiesApi } from "./api/crm/activitiesApi";
import { contactsApi } from "./api/crm/contactsApi";
import { dealsApi } from "./api/crm/dealsApi";
import { leadsApi } from "./api/crm/leads";
import { appraisalsApi } from './api/appraisailsApi';
import { departmentsApi } from "./api/departmentsApi";
import { notificationApi } from "./api/notificationApi";
import { holidayApi } from "./api/holidayApi";
import { categoriesApi } from "./api/inventory/category";
import { productsApi } from "./api/inventory/products";
import { restockApi } from "./api/inventory/restock";

import { projectsTimeApi } from "./api/reports/reports";
import { servicesApi } from "./api/servicesApi";
import { appointmentsApi } from "./api/appointmentsApi";
import { expensesApi } from "./api/finance/expenses";
import { revenueApi } from "./api/finance/revenue";
import { workingHoursApi } from "./api/workingHoursApi";
export const store = configureStore({
  reducer: {
    user: userReducer,
    [restockApi.reducerPath]: restockApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [activitiesApi.reducerPath]: activitiesApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [dealsApi.reducerPath]: dealsApi.reducer,
    [leadsApi.reducerPath]: leadsApi.reducer,
    [appraisalsApi.reducerPath]: appraisalsApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [holidayApi.reducerPath]: holidayApi.reducer,
    [projectsTimeApi.reducerPath]: projectsTimeApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [appointmentsApi.reducerPath]: appointmentsApi.reducer,
    [expensesApi.reducerPath]: expensesApi.reducer,
    [revenueApi.reducerPath]: revenueApi.reducer,
    [workingHoursApi.reducerPath]: workingHoursApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      restockApi.middleware,
      productsApi.middleware,
      categoriesApi.middleware,
      authApi.middleware,
      userApi.middleware,
      tasksApi.middleware,
      clientsApi.middleware,
      projectsApi.middleware,
      commentsApi.middleware,
      providerApi.middleware,
      accountsApi.middleware,
      activitiesApi.middleware,
      contactsApi.middleware,
      dealsApi.middleware,
      leadsApi.middleware,
      appraisalsApi.middleware,
      departmentsApi.middleware,
      notificationApi.middleware,
      holidayApi.middleware,
      projectsTimeApi.middleware,
      servicesApi.middleware,
      appointmentsApi.middleware,
      expensesApi.middleware,
      revenueApi.middleware,
      workingHoursApi.middleware,
    ]),
});
