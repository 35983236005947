import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../config";
import Cookies from "js-cookie"; // Import js-cookie for token management

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const appointmentsApi = createApi({
  reducerPath: "appointmentsApi",
  baseQuery,
  endpoints: (builder) => ({
    // Fetch all appointments
    getAppointments: builder.query({
      query() {
        return {
          url: "/appointments", // Matches router.get("/appointments", getAppointments)
          method: "GET",
        };
      },
    }),

    // Fetch details of a single appointment by ID
    getAppointmentById: builder.query({
      query(appointmentId) {
        return {
          url: `/appointments/get/${appointmentId}`, // Matches router.get("/appointments/get/:id", getAppointmentById)
          method: "GET",
        };
      },
    }),

    // Create a new appointment
    postAppointment: builder.mutation({
      query(appointment) {
        return {
          url: "/appointments/create", // Matches router.post("/appointments/create", createAppointment)
          method: "POST",
          body: appointment,
        };
      },
    }),

    // Update an existing appointment by ID
    updateAppointment: builder.mutation({
      query({ id, ...appointment }) {
        return {
          url: `/appointments/update/${id}`, // Matches router.put("/appointments/update/:id", updateAppointment)
          method: "PUT",
          body: appointment,
        };
      },
    }),

    // Delete an appointment by ID
    deleteAppointment: builder.mutation({
      query(appointmentId) {
        return {
          url: `/appointments/delete/${appointmentId}`, // Matches router.delete("/appointments/delete/:id", deleteAppointment)
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAppointmentsQuery,
  useGetAppointmentByIdQuery,
  usePostAppointmentMutation,
  useUpdateAppointmentMutation,
  useDeleteAppointmentMutation,
} = appointmentsApi;
