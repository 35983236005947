import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import backend_URL from "../../../config";

// Define the base query with token handling
const baseQuery = fetchBaseQuery({
  baseUrl: `${backend_URL}/api/v1`, // Adjust the base URL as needed
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Define the expenses API
export const expensesApi = createApi({
  reducerPath: "expensesApi",
  baseQuery,
  endpoints: (builder) => ({
    // Fetch all expenses with filtering, sorting, and pagination
    getExpenses: builder.query({
      query: ({
        page = 1,
        limit = 10000,
        sort = "name",
        order = "asc",
        search = "",
        status = "",
        ...filters
      }) => {
        let params = new URLSearchParams({
          page,
          limit,
          sort,
          order,
          search,
          status,
        });

        // Add date range filters if provided
        if (filters.startDate) params.append("startDate", filters.startDate);
        if (filters.endDate) params.append("endDate", filters.endDate);

        return {
          url: `/expenses?${params.toString()}`,
          method: "GET",
        };
      },
    }),
    // Fetch total expense value
    getTotalExpenseValue: builder.query({
      query: () => ({
        url: "/expenses/total",
        method: "GET",
      }),
    }),
    // Create a new expense
    createExpense: builder.mutation({
      query: (expense) => ({
        url: "/expenses",
        method: "POST",
        body: expense,
      }),
    }),
    // Update an existing expense by ID
    updateExpense: builder.mutation({
      query: ({ id, updates }) => ({
        url: `/expenses/${id}`,
        method: "PUT",
        body: updates,
      }),
    }),
    // Delete expenses by IDs
    deleteExpenses: builder.mutation({
      query: (expenseIds) => ({
        url: "/expenses",
        method: "DELETE",
        body: { expenseIds },
      }),
    }),
  }),
});

// Export hooks for the expenses API
export const {
  useGetExpensesQuery,
  useGetTotalExpenseValueQuery,
  useCreateExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpensesMutation,
} = expensesApi;
