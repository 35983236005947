import React from "react";
import dayjs from "dayjs";

const CustomDateHeader = ({ date }) => {
  // Get the current date
  const today = dayjs().startOf("day");

  // Check if the date passed to the component is the current date
  const isToday = dayjs(date).isSame(today, "day");

  // Check if the date is a weekend (Saturday or Sunday)
  const isWeekend = dayjs(date).day() === 6 || dayjs(date).day() === 0;

  // Format the date to have a leading zero and display day first
  const day = dayjs(date).format("DD"); // Format as "05"
  const weekday = dayjs(date).format("dddd"); // Format as "Monday"

  return (
    <div className="text-center flex flex-row gap-3 items-center justify-center py-2 h-12">
      {/* Conditionally render the day in a bubble if it's the current date */}
      {isToday ? (
        <div className="w-8 h-8 flex items-center justify-center dark:bg-[#706ea8] bg-[#8D8BDB] rounded-full text-white">
          {day}
        </div>
      ) : (
        <div
          className={`${
            isWeekend ? "text-gray-500" : "dark:text-white text-[#4A488E]"
          }`}
        >
          {day}
        </div>
      )}
      <div
        className={`${
          isWeekend ? "text-gray-500" : "dark:text-white text-[#4A488E]"
        }`}
      >
        {weekday}
      </div>
    </div>
  );
};

export default CustomDateHeader;
