import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import NotificationComponent from "../NotificationComponent";
import Datepicker from "react-tailwindcss-datepicker";
import { PieChart } from "@mui/x-charts";
import {
  useGetRevenuesQuery,
  useUpdateRevenueMutation,
} from "../../redux/api/finance/revenue";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import FuseSpecialDropdown from "./ui/specialDropdown";
import { io } from "socket.io-client";
import backend_URL from "../../config";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const Revenues = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [updateRevenue] = useUpdateRevenueMutation();

  // Loading state for revenues
  const [loadingRevenues, setLoadingRevenues] = useState([]);

  const {
    data: revenuesData,
    isLoading,
    error,
    refetch,
  } = useGetRevenuesQuery({
    page: 1,
    limit: 10000,
    sort: "name",
    order: "asc",
    search: "",
    status: "",
  });

  
  useEffect(() => {

    const socket = io(backend_URL);
    window.socket = socket;
  
    socket.on('revenuePing', (data) => {
      console.log('Revenue Ping:', data);
      refetch();
    });

    return () => {
      socket.disconnect();
    };
  }, [refetch]);

  useEffect(() => {
    const getCurrentMonthDateRange = () => {
      const startDate = dayjs().startOf("month").format("YYYY-MM-DD");
      const endDate = dayjs().endOf("month").format("YYYY-MM-DD");
      return { startDate, endDate };
    };
    setSelectedDate(getCurrentMonthDateRange());
  }, []);

  const filteredRevenues =
    revenuesData?.revenues.filter((expense) => {
      const expenseStartDate = dayjs(expense?.startDate);
      const expenseEndDate = dayjs(expense?.endDate);
      const filterStartDate = dayjs(selectedDate?.startDate);
      const filterEndDate = dayjs(selectedDate?.endDate);

      return (
        (expenseStartDate.isSameOrAfter(filterStartDate) &&
          expenseStartDate.isBefore(filterEndDate)) ||
        (expenseEndDate.isSameOrBefore(filterEndDate) &&
          expenseEndDate.isAfter(filterStartDate)) ||
        (expenseStartDate.isBefore(filterStartDate) &&
          expenseEndDate.isAfter(filterEndDate))
      );
    }) || [];

  const finalRevenues = filteredRevenues?.filter(
    (e) => e.status !== "Canceled"
  );

  const getTotalRevenuesValue = () => {
    const total = finalRevenues.reduce(
      (total, expense) => total + expense.value,
      0
    );
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(total);
  };

// Convert HSL to Hex
const hslToHex = (h, s, l) => {
  l /= 100;
  s /= 100;
  let a = s * Math.min(l, 1 - l);
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const generateColor = (index) => {
  const hue = (index * 137.5) % 360;
  return hslToHex(hue, 70, 50);
};

  const pieChartData =
    finalRevenues.map((expense, index) => ({
      id: expense.name,
      label: expense.name,
      value: expense.value,
      color: generateColor(index),
    })) || [];

  const handleStatusChange = async (newStatus, revenueId) => {
    setLoadingRevenues((prev) => [...prev, revenueId]);

    const revenueToUpdate = revenuesData?.revenues.find(
      (rev) => rev._id === revenueId
    );
    if (revenueToUpdate) {
      const updatedRevenue = { ...revenueToUpdate, status: newStatus };
      await updateRevenue({ id: revenueId, updates: updatedRevenue }).unwrap();
      await refetch();
    }

    // Remove from loadingRevenues after a slight delay
    setTimeout(() => {
      setLoadingRevenues((prev) =>
        prev.filter((id) => id !== revenueId)
      );
    }, 500);
  };

  const today = new Date();

  const customShortcuts = {
    Q1: {
      text: "Q1",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 2, 31),
      },
    },
    Q2: {
      text: "Q2",
      period: {
        start: new Date(today.getFullYear(), 3, 1),
        end: new Date(today.getFullYear(), 5, 30),
      },
    },
    Q3: {
      text: "Q3",
      period: {
        start: new Date(today.getFullYear(), 6, 1),
        end: new Date(today.getFullYear(), 8, 30),
      },
    },
    Q4: {
      text: "Q4",
      period: {
        start: new Date(today.getFullYear(), 9, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
    H1: {
      text: "H1",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 5, 30),
      },
    },
    H2: {
      text: "H2",
      period: {
        start: new Date(today.getFullYear(), 6, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
    FY: {
      text: "FY",
      period: {
        start: new Date(today.getFullYear(), 0, 1),
        end: new Date(today.getFullYear(), 11, 31),
      },
    },
  };

  // Configuration object
  const configs = {
    shortcuts: customShortcuts,
    footer: {
      cancel: "Cancel",
      apply: "Apply",
    },
  };

  // Loading spinner component using Tailwind CSS
  const LoadingSpinner = () => {
    return (
      <div className="w-full min-h-screen flex justify-center items-center mt-0">
        <div className="w-20 h-20 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  };

  if (!revenuesData) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full h-screen p-[30px] dark:bg-[#2B2A49] overflow-scroll scrolling-touch pb-[150px]">
      <div className="flex justify-between pb-[25px]">
        <ul className="flex items-center">
          <Breadcrumbs
            aria-label="breadcrumb"
            className="w-[380px] text-[#7674C2] dark:text-[#EDEDFB]"
          >
            <Link
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              to="/dashboard"
            >
              Fuse Digital
            </Link>
            <Link
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              to={`/finance/revenue`}
            >
              Finance / <b> Revenue</b>
            </Link>
          </Breadcrumbs>
        </ul>
        <NotificationComponent />
      </div>

      <div className="w-full pb-[45px]">
        <h1 className="dark:text-[#EDEDFB] text-[#4A488E] text-[30px]">
          Revenue
        </h1>
      </div>

      <div className="w-full flex justify-end pb-[35px]">
        <div className="w-[25%] xl:w-[15%] flex items-center gap-[24px]">
          <Datepicker
            showShortcuts={true}
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            asSingle={false}
            displayFormat={"YYYY-MM-DD"}
            className="h-[55px] bg-[#E8E8FF] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg"
            configs={configs} // Pass the config object
          />
        </div>
      </div>

      <>
        <div className="mb-[12px]">
          <div className="w-full py-[33px] px-[28px] dark:bg-[#3A3A68] bg-[#E7E7F9] rounded-[21px] flex gap-[26px]">
            <div className="w-[32%] dark:bg-[#4D4D77] bg-[#FAFAFE] py-[59px] flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
              <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                Total Revenue
              </p>
              <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px]">
                {getTotalRevenuesValue()} RON
              </p>
            </div>
            <div className="w-[66%] dark:bg-[#4D4D77] bg-[#FAFAFE] p-5 flex justify-start items-start rounded-[21px] flex-col gap-5">
              <div className="dark:text-[#d4d4eb] text-[#4A488E] text-xl font-normal">
                Revenue summary
              </div>

              <div className="flex flex-row items-start justify-center w-full gap-4">
                <PieChart
                  width={330}
                  height={330}
                  series={[
                    {
                      data: pieChartData,
                      colorField: "color", // Bind the color to the pie chart
                    },
                  ]}
                  slotProps={{
                    legend: {
                      hidden: true, // This hides the default legend
                    },
                  }}
                />
                <div className="w-48 h-80 overflow-y-auto">
                  {pieChartData.map((item) => (
                    <div key={item.id} className="flex items-center mb-2">
                      <div
                        className="w-4 h-4 mr-2"
                        style={{ backgroundColor: item.color }}
                      ></div>
                      <span
                        className={`text-sm ${
                          theme === "dark" ? "text-gray-300" : "text-gray-700"
                        }`}
                      >
                        {item.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full dark:bg-[#3A3A68] bg-[#e7e7f9] rounded-[21px] p-8">
          <h2 className="dark:text-white text-[#4A488E] font-semibold text-lg pb-[21px] border-b border-[#CAC9F1] dark:border-[#e2e2ff52]">
            All activities
          </h2>
          <table className="w-full text-center text-sm text-white mt-6">
            <thead className="dark:bg-[#3A3A68] text-[#AFAFDD]">
              <tr>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Transactions
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Value
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Date
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                  Due Date
                </th>
                <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF] ">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="dark:bg-[#3A3A68] bg-[#e7e7f9]">
              {filteredRevenues.map((item, index) => (
                <tr
                  key={index}
                  className="border-t dark:border-[#e2e2ff52] border-[#CAC9F1] py-[25px]"
                >
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {item?.name}
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {item?.value}
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {dayjs(item?.startDate).format("DD.MM.YYYY")}
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    {dayjs(item?.endDate).format("DD.MM.YYYY")}
                  </td>
                  <td className="py-4 dark:text-[#DFDFDF] text-[#4A488E]">
                    <div className="flex flex-row items-center justify-center gap-3">
                      <FuseSpecialDropdown
                        currentStatus={item?.status}
                        onChange={(newStatus) =>
                          handleStatusChange(newStatus, item?._id)
                        }
                        loading={loadingRevenues.includes(item._id)}
                        type="revenue"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </div>
  );
};

export default Revenues;
