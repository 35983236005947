import React, { useState, useEffect } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useGetClientsQuery } from "../../redux/api/clientsApi";
import { useGetAccountsQuery } from "../../redux/api/crm/accountsApi";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { useGetDealsQuery } from "../../redux/api/crm/dealsApi";
import Drawer from "../Notification";
import dayjs from "dayjs";
import { useContext } from "react";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import { useSelector } from "react-redux";
export const SoloClient = () => {
  const userData = useSelector((state) => state.user.user);

  const [openRight, setOpenRight] = useState(false);

  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [isExpectationsOpen, setIsExpectationsOpen] = useState(false);
  const [openServiceCategories, setOpenServiceCategories] = useState({});

  const toggleServiceCategory = (category) => {
    setOpenServiceCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const { client } = useParams();
  const clientNameNoHyphens = client.replace(/-/g, " ");
  const [currentClient, setCurrentClient] = useState(null);
  const {
    data: clientsData,
    error: clientsError,
    isLoading: clientsLoading,
    refetch: refetchClients,
  } = useGetAccountsQuery();

  React.useEffect(() => {
    if (clientsData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetchClients();
      }, 5000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [clientsData, refetchClients]);
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useGetAllUsersQuery();
  const { data: dealsData } = useGetDealsQuery();
  const groupedUsers = usersData?.users?.reduce((acc, user) => {
    const { department } = user;
    if (!acc[department]) {
      acc[department] = [];
    }
    acc[department].push(user);
    return acc;
  }, {});

  useEffect(() => {
    if (clientsData) {
      const matchedClient = clientsData.accounts.find(
        (client) => client.nameComp.replace(/-/g, " ") === clientNameNoHyphens
      );
      setCurrentClient(matchedClient);
    }
  }, [clientsData, clientNameNoHyphens]);

  let updatedServices = "";
  if (currentClient) {
    updatedServices = Object.keys(currentClient?.services).reduce(
      (acc, key) => {
        if (currentClient?.services[key].length > 0) {
          acc[key] = currentClient?.services[key];
        }
        return acc;
      },
      {}
    );
  }
  const isMobileView = useContext(ViewTypeContext); // true daca e mobil/tableta

  if (clientsLoading) return <div>Loading...</div>;
  if (clientsError)
    return <div>Error fetching clients: {clientsError.message}</div>;
  // if (!currentClient) return <div>Client not found</div>;

  const dealOfAccount = dealsData?.deals.find(
    (deal) => deal._id === currentClient?.deal
  );
  const getCombinedDuration = (allDeals, clientDeals) => {
    if (!clientDeals || !allDeals) return "No deals found";

    // Ensure clientDeals is treated as an array, even if it's a single deal string
    const dealIds = Array.isArray(clientDeals) ? clientDeals : [clientDeals];

    // Filter to get all the deals associated with the current client
    const associatedDeals = allDeals.filter((deal) =>
      dealIds.includes(deal._id)
    );

    if (associatedDeals.length === 0) return "No valid deals found";

    // Find the earliest start date and the latest end date
    const earliestStartDate = dayjs(
      Math.min(
        ...associatedDeals.map((deal) => new Date(deal.closeDate.startDate))
      )
    ).format("DD/MM/YYYY");

    const latestEndDate = dayjs(
      Math.max(
        ...associatedDeals.map((deal) => new Date(deal.closeDate.endDate))
      )
    ).format("DD/MM/YYYY");

    // Return the combined duration
    return `${earliestStartDate} - ${latestEndDate}`;
  };

  const getCombinedPrice = (allDeals, clientDeals) => {
    if (!clientDeals || !allDeals) return "Unknown";

    // Ensure clientDeals is treated as an array, even if it's a single deal string
    const dealIds = Array.isArray(clientDeals) ? clientDeals : [clientDeals];

    // Filter to get all the deals associated with the current client
    const associatedDeals = allDeals.filter((deal) =>
      dealIds.includes(deal._id)
    );

    if (associatedDeals.length === 0) return "No valid deals";

    // Sum up the deal values across all associated deals
    const totalPrice = associatedDeals.reduce((total, deal) => {
      const dealValue = parseFloat(deal.dealValue) || 0; // Ensure dealValue is a number
      return total + dealValue;
    }, 0);

    // Return the total price formatted as a string with commas
    return totalPrice > 0 ? `${totalPrice.toLocaleString()}` : "Unknown";
  };

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch ">
      <div className="w-full">
        <div className=" p-4 pl-0 flex items-center flex-wrap">
          <ul className="flex items-center ">
            {/* <li className="inline-flex items-center">
              <Link
                to="/dashboard"
                className="text-gray-600 hover:text-blue-500"
              >
                <svg
                  className="w-5 h-auto fill-current mx-2 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                </svg>
              </Link>

              <span className="mx-4 h-auto text-gray-400 font-medium">/</span>
            </li> */}

            <Breadcrumbs
              aria-label="breadcrumb"
              className="dark:text-[#EDEDFB]"
            >
              <Link underline="hover" color="#4A488E" to="/dashboard">
                <span
                  style={{ color: "#4A488E" }}
                  className="dark:text-[#EDEDFB]"
                >
                  Fuse Digital
                </span>
              </Link>
              <Link underline="hover" color="#4A488E" to={`/clients`}>
                <span
                  style={{ color: "#4A488E" }}
                  className="dark:text-[#EDEDFB]"
                >
                  Clients
                </span>
              </Link>
              <Link
                underline="hover"
                color="#4A488E"
                to={`/${clientNameNoHyphens}`}
              >
                <span
                  style={{ fontWeight: "bold", color: "#4A488E" }}
                  className="dark:text-[#EDEDFB]"
                >
                  {clientNameNoHyphens}
                </span>
              </Link>
            </Breadcrumbs>
          </ul>
        </div>
        <div className="flex w-full justify-end gap-[10px]">
          <button
            className="bg-[#FAFAFE] dark:bg-[#3A3A68] p-[13px] rounded-[9px]"
            onClick={() => setOpenRight(!openRight)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="ion:notifications">
                <path
                  className="fill-current dark:text-[#EDEDFB]"
                  id="Vector"
                  d="M17.1902 13.3324C17.1254 13.2543 17.0617 13.1762 16.9992 13.1008C16.1398 12.0613 15.6199 11.434 15.6199 8.49141C15.6199 6.96797 15.2554 5.71797 14.5371 4.78047C14.0074 4.08789 13.2914 3.5625 12.3476 3.17422C12.3355 3.16746 12.3246 3.1586 12.3156 3.14805C11.9761 2.01133 11.0472 1.25 9.99959 1.25C8.95193 1.25 8.02342 2.01133 7.68396 3.14687C7.67492 3.15706 7.66422 3.16564 7.65232 3.17227C5.44998 4.07891 4.37967 5.81836 4.37967 8.49023C4.37967 11.434 3.86052 12.0613 3.00037 13.0996C2.93787 13.175 2.8742 13.2516 2.80935 13.3313C2.64185 13.5333 2.53573 13.779 2.50354 14.0394C2.47135 14.2999 2.51444 14.5641 2.62771 14.8008C2.86873 15.3086 3.3824 15.6238 3.96873 15.6238H16.0347C16.6183 15.6238 17.1285 15.309 17.3703 14.8035C17.4841 14.5668 17.5276 14.3023 17.4956 14.0416C17.4637 13.7809 17.3577 13.5348 17.1902 13.3324ZM9.99959 18.75C10.5641 18.7495 11.1179 18.5963 11.6023 18.3066C12.0867 18.0168 12.4837 17.6014 12.7512 17.1043C12.7637 17.0805 12.77 17.0538 12.7692 17.0269C12.7685 16.9999 12.7607 16.9736 12.7468 16.9506C12.7329 16.9275 12.7133 16.9084 12.6898 16.8952C12.6663 16.8819 12.6398 16.875 12.6129 16.875H7.38709C7.36011 16.8749 7.33356 16.8818 7.31003 16.895C7.2865 16.9082 7.2668 16.9273 7.25284 16.9504C7.23887 16.9735 7.23113 16.9998 7.23035 17.0268C7.22958 17.0537 7.2358 17.0804 7.24842 17.1043C7.51581 17.6013 7.91273 18.0167 8.39709 18.3065C8.88145 18.5962 9.43519 18.7495 9.99959 18.75Z"
                  fill="#4A488E"
                />
              </g>
            </svg>
          </button>
          <button className="bg-[#FAFAFE] dark:bg-[#3A3A68] p-[13px] rounded-[9px]">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="material-symbols:settings">
                <path
                  className="fill-current dark:text-[#EDEDFB]"
                  id="Vector"
                  d="M7.70833 18.3333L7.375 15.6667C7.19444 15.5972 7.02444 15.5139 6.865 15.4167C6.70556 15.3194 6.54917 15.2153 6.39583 15.1042L3.91667 16.1458L1.625 12.1875L3.77083 10.5625C3.75694 10.4653 3.75 10.3717 3.75 10.2817V9.71916C3.75 9.6286 3.75694 9.53471 3.77083 9.43749L1.625 7.81249L3.91667 3.85416L6.39583 4.89582C6.54861 4.78471 6.70833 4.68055 6.875 4.58332C7.04167 4.4861 7.20833 4.40277 7.375 4.33332L7.70833 1.66666H12.2917L12.625 4.33332C12.8056 4.40277 12.9758 4.4861 13.1358 4.58332C13.2958 4.68055 13.4519 4.78471 13.6042 4.89582L16.0833 3.85416L18.375 7.81249L16.2292 9.43749C16.2431 9.53471 16.25 9.6286 16.25 9.71916V10.2808C16.25 10.3714 16.2361 10.4653 16.2083 10.5625L18.3542 12.1875L16.0625 16.1458L13.6042 15.1042C13.4514 15.2153 13.2917 15.3194 13.125 15.4167C12.9583 15.5139 12.7917 15.5972 12.625 15.6667L12.2917 18.3333H7.70833ZM10.0417 12.9167C10.8472 12.9167 11.5347 12.6319 12.1042 12.0625C12.6736 11.493 12.9583 10.8055 12.9583 9.99999C12.9583 9.19443 12.6736 8.50693 12.1042 7.93749C11.5347 7.36804 10.8472 7.08332 10.0417 7.08332C9.22222 7.08332 8.53111 7.36804 7.96833 7.93749C7.40556 8.50693 7.12444 9.19443 7.125 9.99999C7.12556 10.8055 7.40694 11.493 7.96917 12.0625C8.53139 12.6319 9.22222 12.9167 10.0417 12.9167Z"
                  fill="#4A488E"
                />
              </g>
            </svg>
          </button>
          <Drawer open={openRight} side="right" setOpen={setOpenRight} />
        </div>
        <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal   mt-[40px] mb-[53px]">
          {clientNameNoHyphens}
        </div>
        <div
          className={`flex ${
            isMobileView ? "flex-row flex-wrap pb-[200px]" : "flex-row"
          }  gap-[15px] mb-[20px] mr-[20px]`}
        >
          <div className="flex flex-col gap-[10px]">
            <div className="w-[328px] h-[47px] bg-white dark:bg-[#3A3A68] rounded-lg flex flex-row justify-between py-[15px] px-[20px] p-[12px]">
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] dark:bg-white rounded-full mr-[10px]" />

                <div className="text-black dark:text-[#EDEDFB] text-lg font-normal  ">
                  Footprint overview
                </div>
              </div>
            </div>
            <div className="w-[328px] h-fit bg-white dark:bg-[#3A3A68] rounded-lg py-[31px] px-[20px] flex flex-col gap-[23px] ">
              <div className="flex flex-row items-center gap-[9px] flex-wrap">
                {Object.keys(updatedServices).map((s) => (
                  <div
                    className={` ${
                      s === "SEO"
                        ? "bg-[#6366F1]"
                        : s === "PPC"
                        ? "bg-[#FF7918]"
                        : s === "Development"
                        ? "bg-[#0074FD]"
                        : "bg-[#1ABC9C]"
                    }  rounded-[20px] w-fit py-[5px] px-[18px]`}
                  >
                    <div className="text-white text-[13px] font-normal  ">
                      {s}
                    </div>
                  </div>
                ))}
                {/* 
                
                <div className=" bg-zinc-600 rounded-[20px] w-fit py-[5px] px-[18px]">
                  <div className="text-neutral-300 text-[13px] font-normal  ">
                    SMM
                  </div>
                </div>
                <div className=" bg-zinc-600 rounded-[20px] w-fit py-[5px] px-[18px]">
                  <div className="text-neutral-300 text-[13px] font-normal  ">
                    SEO
                  </div>
                </div>*/}
              </div>
              <div className="w-[126px] text-[#636363] dark:text-[#EDEDFB] text-[15px] font-normal   ">
                General results
              </div>
              {currentClient?.generalResults.map((gr) => (
                <div className="flex flex-row items-center">
                  <div className="w-1.5 h-1.5 bg-[#656565] dark:bg-white rounded-full mr-[9px]" />
                  <div className="w-[206px] text-[#636363] dark:text-[#EDEDFB] text-sm font-normal  ">
                    {gr}
                  </div>
                </div>
              ))}
              {/* <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] rounded-full mr-[9px]" />
                <div className="w-[206px] text-[#636363] text-sm font-normal  ">
                  Web Traffic Increase with X%{" "}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] rounded-full mr-[9px]" />
                <div className="w-[206px] text-[#636363] text-sm font-normal  ">
                  Conversion Increase with X%
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] rounded-full mr-[9px]" />
                <div className="w-[206px] text-[#636363] text-sm font-normal  ">
                  Sales Increase with X%
                </div>
              </div> */}
              <div className="w-[126px] text-[#636363] dark:text-[#EDEDFB] text-[15px] font-normal   ">
                Activities
              </div>
              {currentClient?.activities.map((a) => (
                <div className="flex flex-row items-center">
                  <div className="w-1.5 h-1.5 bg-[#656565] dark:bg-white rounded-full mr-[9px]" />
                  <div className="w-[206px] text-[#636363] dark:text-[#EDEDFB] text-sm font-normal  ">
                    {a}
                  </div>
                </div>
              ))}
              {/* <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] rounded-full mr-[9px]" />
                <div className="w-[206px] text-[#636363] text-sm font-normal  ">
                  Web Traffic Increase with X%{" "}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] rounded-full mr-[9px]" />
                <div className="w-[206px] text-[#636363] text-sm font-normal  ">
                  Conversion Increase with X%
                </div>
              </div>
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] rounded-full mr-[9px]" />
                <div className="w-[206px] text-[#636363] text-sm font-normal  ">
                  Sales Increase with X%
                </div>
              </div> */}
            </div>
          </div>

          <div className="flex flex-col gap-[10px]">
            <div
              className={
                isMobileView
                  ? "w-[300px] h-[47px] bg-white dark:bg-[#3A3A68] rounded-lg flex flex-row justify-between py-[15px] px-[20px] p-[12px]"
                  : "w-[328px] h-[47px] bg-white dark:bg-[#3A3A68] rounded-lg flex flex-row justify-between py-[15px] px-[20px] p-[12px]"
              }
            >
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] dark:bg-white rounded-full mr-[10px]" />

                <div className="text-black dark:text-[#EDEDFB] text-lg font-normal  ">
                  Collaboration details
                </div>
              </div>
            </div>{" "}
            <div
              className={
                isMobileView
                  ? "w-[300px] h-fit bg-white dark:bg-[#3A3A68] rounded-lg py-[31px] px-[20px] flex flex-col gap-[23px]"
                  : "w-[328px] h-fit bg-white dark:bg-[#3A3A68] rounded-lg py-[31px] px-[20px] flex flex-col gap-[23px]"
              }
            >
              <div className="w-[189px] text-[#636363] dark:text-[#EDEDFB] text-[15px] font-normal  ">
                Dedicated team members
              </div>

              {Object.keys(updatedServices).map((s) => (
                <div className="flex flex-row gap-[15px]">
                  <div
                    className={` ${
                      s === "SEO"
                        ? "bg-[#6366F1]"
                        : s === "PPC"
                        ? "bg-[#FF7918]"
                        : s === "Development"
                        ? "bg-[#0074FD]"
                        : "bg-[#1ABC9C]"
                    } rounded-[20px] w-fit py-[5px] px-[18px] flex items-center`}
                  >
                    <div className="text-white text-[13px] font-normal  ">
                      {s}
                    </div>
                  </div>
                </div>
              ))}

              <div className="flex flex-row gap-[15px] items-center">
                <div className="w-[61px] text-[#636363] dark:text-[#EDEDFB] text-[15px] font-normal">
                  Duration
                </div>
                <div className="bg-[#F39C12] rounded-[20px] w-fit py-[5px] px-[18px] flex items-center">
                  <div className="text-white text-[13px] font-normal">
                    {getCombinedDuration(dealsData?.deals, currentClient?.deal)}
                  </div>
                </div>
              </div>
              {userData?.role !== "Admin" ? (
                ""
              ) : (
                <div className="flex flex-row gap-[15px] items-center">
                  <div className="w-[61px] text-[#636363] dark:text-[#EDEDFB] text-[15px] font-normal">
                    Price
                  </div>
                  <div className="bg-[#F39C12] rounded-[20px] w-fit py-[5px] px-[18px] flex items-center">
                    <div className="text-white text-[13px] font-normal">
                      {getCombinedPrice(dealsData?.deals, currentClient?.deal)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-[10px]">
            <div
              className={
                isMobileView
                  ? "w-[300px] h-[47px] bg-white dark:bg-[#3A3A68] rounded-lg flex flex-row justify-between py-[15px] px-[20px] p-[12px]"
                  : "w-[328px] h-[47px] bg-white dark:bg-[#3A3A68] rounded-lg flex flex-row justify-between py-[15px] px-[20px] p-[12px]"
              }
            >
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 bg-[#656565] dark:bg-white rounded-full mr-[10px]" />

                <div className="text-black dark:text-[#EDEDFB] text-lg font-normal  ">
                  Services and expectations
                </div>
              </div>
            </div>{" "}
            <div
              className={
                isMobileView
                  ? "w-[300Spx] h-fit bg-white  dark:bg-[#3A3A68] rounded-lg py-[17px]"
                  : "w-[328px] h-fit bg-white  dark:bg-[#3A3A68] rounded-lg py-[17px] "
              }
            >
              <div className="services-accordion">
                <div className="accordion-item">
                  <div
                    className={`flex flex-row justify-between py-[15px] px-[20px] ${
                      isServiceOpen
                        ? "bg-[#8684CC] text-white"
                        : "text-[#636363] dark:text-[#EDEDFB]"
                    }`}
                    onClick={() => setIsServiceOpen(!isServiceOpen)}
                  >
                    <div className=" text-lg font-normal  ">Services</div>
                    <svg
                      className={`transform transition-transform ${
                        isServiceOpen ? "rotate-180" : ""
                      }`}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        className="stroke-current dark:text-white"
                        d="M4 6L8 10L12 6"
                        stroke="#4A5568"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {isServiceOpen && (
                    <div className="accordion-content">
                      {Object.keys(currentClient?.services || {})
                        .filter(
                          (serviceKey) =>
                            Array.isArray(
                              currentClient?.services[serviceKey]
                            ) && currentClient?.services[serviceKey].length > 0
                        )
                        .map((serviceKey) => (
                          <div key={serviceKey} className="service-category">
                            <div
                              className={`flex flex-row justify-between py-[15px] px-[20px] mt-1 `}
                              onClick={() => toggleServiceCategory(serviceKey)}
                            >
                              <div className="text-neutral-700 dark:text-[#EDEDFB] text-md font-normal  ">
                                {serviceKey}
                              </div>
                              <svg
                                className={`transform transition-transform ${
                                  openServiceCategories[serviceKey]
                                    ? "rotate-180"
                                    : ""
                                }`}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  className="stroke-current dark:text-white"
                                  d="M4 6L8 10L12 6"
                                  stroke="#4A5568"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            {openServiceCategories[serviceKey] && (
                              <ul className="list-disc list-inside">
                                {currentClient?.services[serviceKey].map(
                                  (service, index) => (
                                    <li
                                      key={index}
                                      className="text-neutral-600 dark:text-[#EDEDFB] text-sm font-normal   px-[20px] py-[7.5px]"
                                    >
                                      {service}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="expectations-accordion">
                <div className="accordion-item">
                  <div
                    className={`flex flex-row justify-between py-[15px] px-[20px] ${
                      isExpectationsOpen
                        ? "bg-[#8684CC] text-white "
                        : " text-[#636363] dark:text-[#EDEDFB]"
                    }`}
                    onClick={() => setIsExpectationsOpen(!isExpectationsOpen)}
                  >
                    <div className="text-lg font-normal  dark:text-[#EDEDFB] ">
                      Client Expectations
                    </div>
                    <svg
                      className={`transform transition-transform ${
                        isExpectationsOpen ? "rotate-180" : ""
                      }`}
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        className="stroke-current dark:text-white"
                        d="M4 6L8 10L12 6"
                        stroke="#4A5568"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  {isExpectationsOpen && (
                    <div className="accordion-content">
                      <ul className="list-disc list-inside">
                        {currentClient?.expectations.map(
                          (expectation, index) => (
                            <li
                              key={index}
                              className="text-neutral-600 dark:text-[#EDEDFB] text-sm font-normal   px-[20px] py-[7.5px] "
                            >
                              {expectation}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
