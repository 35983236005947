// components/Holiday/AcceptHoliday.js

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAcceptHolidayMutation } from "../../../redux/api/holidayApi";

const AcceptHoliday = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [acceptHoliday] = useAcceptHolidayMutation();

  useEffect(() => {
    const acceptHolidayRequest = async () => {
      try {
        await acceptHoliday(token).unwrap();
        toast.success("Holiday request approved successfully!", {
          autoClose: 2000,
        });
      } catch (error) {
        console.error("Failed to accept holiday request", error);
        toast.error(
          "Failed to approve holiday request. Please try again later.",
          { autoClose: 2000 }
        );
      } finally {
        // navigate('/');
      }
    };

    acceptHolidayRequest();
  }, [token, navigate, acceptHoliday]);

  return null;
};

export default AcceptHoliday;
