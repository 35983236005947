export const TrashcanSVG = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_826_8626)">
<path d="M7.07662 -0.0195312C5.8717 -0.0195312 4.9037 0.981699 4.9037 2.19216V2.46108H2.46124C2.12216 2.46108 1.84585 2.73739 1.84585 3.07647V3.69185H1.23047V4.92262H2.46124V14.1534C2.46124 15.1657 3.29508 15.9995 4.30739 15.9995H11.692C12.7043 15.9995 13.5382 15.1657 13.5382 14.1534V4.92262H14.7689V3.69185H14.1535V3.07647C14.1535 2.73739 13.8772 2.46108 13.5382 2.46108H11.0957V2.19155C11.0957 0.9817 10.1277 -0.0195312 8.92278 -0.0195312H7.07662ZM7.07662 1.24939H8.92278C9.41755 1.24939 9.82678 1.65308 9.82678 2.19155V2.46108H6.17201V2.19155C6.17201 1.65308 6.58124 1.25001 7.07601 1.25001L7.07662 1.24939ZM3.69201 4.92262H6.84585C6.92216 4.93062 6.99785 4.9417 7.07662 4.9417H8.92278C9.00155 4.9417 9.07662 4.93062 9.15355 4.92262H12.3074V14.1534C12.3074 14.4999 12.0385 14.7688 11.692 14.7688H4.30739C3.96093 14.7688 3.69201 14.4999 3.69201 14.1534V4.92262ZM4.92278 6.15339V13.538H6.15355V6.15339H4.92278ZM7.38432 6.15339V13.538H8.61508V6.15339H7.38432ZM9.84585 6.15339V13.538H11.0766V6.15339H9.84585Z" fill="#8D8BDB"/>
</g>
<defs>
<clipPath id="clip0_826_8626">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

  ); 