import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  useUpdatePasswordMutation,
  useUploadAvatarMutation,
  useUpdateUserMutation,
} from "./../redux/api/userApi";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#EDEDFB",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "gray" : "#383757",
        opacity: 1,
        border: "1px solid #EDEDFB",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#8D8BDB",
      border: "6px solid #8D8BDB",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? "#8D8BDB" : "white",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "white" : "#8D8BDB",
    border: "1px solid #D1D6F4",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const Settings = () => {
  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("theme") === "dark";
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };
  const { user } = useSelector((state) => state.auth);
  const { category } = useParams();
  const [userData, setUserData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    jobDescription: user?.jobDescription || "",
    phone: user?.phone || "",
    city: user?.city || "",
    address: user?.address || "",
    postalCode: user?.postalCode || "",
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState("");
  const [
    updatePassword,
    { isLoading: isLoadingPassword, isError: isErrorPassword },
  ] = useUpdatePasswordMutation();
  const [
    uploadAvatar,
    {
      isLoading: isLoadingAvatar,
      error: errorAvatar,
      isSuccess: isSuccessAvatar,
    },
  ] = useUploadAvatarMutation();
  const [updateUser, { isLoading: isLoadingUser, isError: isErrorUser }] =
    useUpdateUserMutation();

  useEffect(() => {
    if (errorAvatar) {
      toast.error(errorAvatar?.data.message);
    }
    if (isSuccessAvatar) {
      toast.success("Avatar Uploaded");
    }
  }, [errorAvatar, isSuccessAvatar]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (user) {
      setUserData({
        name: user.name || "",
        email: user.email || "",
        jobDescription: user.jobDescription || "",
        phone: user.phone || "",
        city: user.city || "",
        address: user.address || "",
        postalCode: user?.postalCode || "",
      });
    }
  }, [user]);

  const submitHandler = (e) => {
    e.preventDefault();
    const userData = {
      avatar,
    };

    uploadAvatar(userData);
  };

  const onChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }
    try {
      await updatePassword({
        oldPassword: currentPassword,
        password: newPassword,
      });

      if (isErrorPassword) {
        toast.error("Failed to update password");
      } else {
        toast.success("Successfully changed password");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      console.error("Failed to update password:", error);
      toast.error("Failed to update password");
    }
  };

  const handleUserUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateUser({ id: user._id, body: userData });
      if (isErrorUser) {
        toast.error("Failed to update user");
      } else {
        toast.success("User updated successfully");
      }
    } catch (error) {
      console.error("Failed to update user:", error);
      toast.error("Failed to update user");
    }
  };

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#CAC9F1] dark:bg-[#2B2A49]">
      {category === "Account-Preferences" ? (
        <>
          <div className="text-zinc-500 text-xl font-normal dark:text-[#EDEDFB] ">
            Display
          </div>
          <div className="w-full flex flex-row gap-[26px] text-zinc-500 text-base font-normal   ml-[37px] mr-[38px]">
            <span className="content-around dark:text-[#EDEDFB] ">
              Dark mode
            </span>
            <IOSSwitch
              sx={{ m: 1 }}
              checked={darkMode}
              onChange={handleThemeChange}
            />
          </div>

          <div className="mt-[43px] flex flex-col gap-[25px]">
            <div className="text-zinc-500 text-xl font-normal dark:text-[#EDEDFB]  ">
              General preferences
            </div>
            <div className="flex flex-col gap-[14px]">
              <div className="text-zinc-500 text-[15px] font-normal dark:text-[#EDEDFB]   ">
                Language
              </div>
              <div className="text-zinc-500 text-[13px] font-normal dark:text-[#EDEDFB]  ">
                Select the language you want to use on “Fuse Digital”
              </div>
              <select className="w-[340px] py-[12px] px-[25px] border-none rounded-[52px] bg-[#D9D9D9]">
                <option value="option1">Romana</option>
                <option value="option2">English</option>
              </select>
            </div>
          </div>
        </>
      ) : category === "Profile-Settings" ? (
        <div className="flex flex-col items-start gap-[20px]">
          <div className="text-zinc-500 text-xl font-normal dark:text-[#EDEDFB]  ">
            Profile settings
          </div>
          <div className="w-full border b-1-[#D9D9D9] dark:border-[#CAC9F1] dark:bg-[#2B2A49] bg-white rounded-[14px] flex flex-row items-center justify-between gap-[10px] py-[16px] px-[23px]">
            <div className="flex items-center gap-[15px]">
              <div
                className="w-[66px] h-[66px] rounded-full mr-[17px]"
                style={{
                  backgroundImage: `url(${user?.avatar?.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* Uncomment to use img tag
                <img
                  src={user?.avatar?.url}
                  alt=""
                  className="w-full h-full rounded-full"
                /> */}
              </div>
              <div>
                <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                  {user?.name}
                </div>
                <div className="text-zinc-500 dark:text-[#EDEDFB] text-[13px] font-normal  ">
                  {user?.jobDescription}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-[12px]">
              <form onSubmit={submitHandler}>
                <input
                  name="avatar"
                  type="file"
                  className="dark:text-[#EDEDFB]"
                  onChange={onChange}
                />
                <button
                  type="submit"
                  className="dark:text-[#EDEDFB]"
                  disabled={isLoadingAvatar}
                >
                  {isLoadingAvatar ? "Uploading..." : "Upload"}
                </button>
              </form>
            </div>
          </div>
          <form
            onSubmit={handleUserUpdate}
            className="w-full border b-1-[#D9D9D9] dark:border-[#CAC9F1] dark:bg-[#2B2A49] bg-white rounded-[14px] flex flex-col items-start gap-[10px] py-[16px] px-[23px]"
          >
            <div className="flex flex-col gap-[34px] w-full">
              <div className="text-zinc-500 dark:text-[#EDEDFB] text-xl font-normal  ">
                Personal information
              </div>
              <div className="flex flex-col gap-[31px]">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                      Name
                    </div>
                    <input
                      className="py-[12px] px-[15px] rounded-[4px] dark:bg-[#3A3A68] dark:text-white bg-[#D9D9D9] border-none w-[300px]"
                      name="name"
                      value={userData?.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                      Email address
                    </div>
                    <input
                      className="py-[12px] px-[15px] dark:bg-[#3A3A68] dark:text-white  rounded-[4px] bg-[#D9D9D9] border-none w-[300px]"
                      name="email"
                      readOnly
                      value={userData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-zinc-500  dark:text-[#EDEDFB] text-lg font-normal  ">
                      Job Description
                    </div>
                    <input
                      className="py-[12px] px-[15px] rounded-[4px] dark:bg-[#3A3A68] dark:text-white  bg-[#D9D9D9] border-none w-[300px]"
                      name="jobDescription"
                      readOnly
                      value={userData.jobDescription}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[139px] h-[38px] px-5 py-2.5 bg-zinc-300 rounded-[3px] justify-center items-center gap-2.5 inline-flex self-end">
              <button
                type="submit"
                className="text-neutral-500 dark:text-[#2B2A49] text-[15px] font-normal  "
              >
                Save changes
              </button>
            </div>
          </form>
          <form
            onSubmit={handleUserUpdate}
            className="w-full border b-1-[#D9D9D9] dark:border-[#CAC9F1] dark:bg-[#2B2A49] bg-white rounded-[14px] flex flex-col items-start gap-[10px] py-[16px] px-[23px]"
          >
            <div className="flex flex-col gap-[34px] w-full">
              <div className="text-zinc-500 text-xl font-normal dark:text-white ">
                Address
              </div>
              <div className="flex flex-col gap-[31px]">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-zinc-500 text-lg font-normal dark:text-white ">
                      Country
                    </div>
                    <input
                      className="py-[12px] px-[15px] rounded-[4px] dark:bg-[#3A3A68] dark:text-white  bg-[#D9D9D9] border-none w-[300px]"
                      name="country"
                      value="Romania"
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-zinc-500 dark:text-white text-lg font-normal  ">
                      Street and number
                    </div>
                    <input
                      className="py-[12px] px-[15px] rounded-[4px] dark:bg-[#3A3A68] dark:text-white  bg-[#D9D9D9] border-none w-[300px]"
                      name="address"
                      value={userData.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col gap-[15px]">
                    <div className="text-zinc-500 text-lg font-normal dark:text-white ">
                      Postal code
                    </div>
                    <input
                      className="py-[12px] px-[15px] rounded-[4px] dark:bg-[#3A3A68] dark:text-white  bg-[#D9D9D9] border-none w-[300px]"
                      name="postalCode"
                      value={userData.postalCode}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col gap-[15px]"></div>
                </div>
              </div>
            </div>
            <div className="w-[139px] h-[38px] px-5 py-2.5 bg-zinc-300 rounded-[3px] justify-center items-center gap-2.5 inline-flex self-end">
              <button
                type="submit"
                className="text-neutral-500 text-[15px] dark:text-[#2B2A49] font-normal  "
              >
                Save changes
              </button>
            </div>
          </form>
        </div>
      ) : category === "Security" ? (
        <>
          <div className="text-zinc-500 dark:text-[#EDEDFB] text-xl font-normal  ">
            Change Password
          </div>
          <form
            onSubmit={handlePasswordChange}
            className="mt-[43px] flex flex-col gap-[20px] w-[380px]"
          >
            <div className="flex flex-col gap-[24px]">
              <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                Current Password
              </div>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="py-[12px] px-[15px] rounded-[4px] bg-[#D9D9D9] dark:text-[#EDEDFB] dark:bg-[#3A3A68] border-none"
              />
            </div>
            <div className="flex flex-col gap-[24px]">
              <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                New Password
              </div>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="py-[12px] px-[15px] rounded-[4px] bg-[#D9D9D9] dark:text-[#EDEDFB] dark:bg-[#3A3A68]  border-none"
              />
            </div>
            <div className="flex flex-col gap-[24px]">
              <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                Confirm New Password
              </div>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="py-[12px] px-[15px] rounded-[4px] bg-[#D9D9D9] dark:text-[#EDEDFB] dark:bg-[#3A3A68]  border-none"
              />
            </div>
            <div className="flex flex-row justify-between w-full">
              <button
                type="button"
                className="w-[107px] h-10 px-6 py-[9px] bg-zinc-300 rounded justify-center items-center gap-2.5 inline-flex"
                onClick={() => {
                  setCurrentPassword("");
                  setNewPassword("");
                  setConfirmPassword("");
                }}
              >
                <div className="text-zinc-500 dark:text-[#3A3A68] text-lg font-normal  ">
                  Cancel
                </div>
              </button>
              <button
                type="submit"
                className="w-[159px] h-10 px-5 py-[9px] bg-zinc-300 rounded justify-center items-center gap-2.5 inline-flex"
                disabled={isLoadingPassword}
              >
                <div className="text-zinc-500 dark:text-[#3A3A68] text-lg font-normal  ">
                  Save changes
                </div>
              </button>
            </div>
          </form>
        </>
      ) : category === "Notifications" ? (
        <>
          {/* <div className="text-zinc-500 text-xl font-normal  ">
            Notifications
          </div>
          <div className="flex flex-col gap-[30px] w-[180px] mt-[30px]">
            <div className="flex flex-row items-center justify-between">
              <div className="text-zinc-500 text-lg font-normal  ">
                Mentions
              </div>{" "}
              <IOSSwitch sx={{ m: 1 }} defaultChecked />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="text-zinc-500 text-lg font-normal  ">
                Comments
              </div>{" "}
              <IOSSwitch sx={{ m: 1 }} defaultChecked />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div className="text-zinc-500 text-lg font-normal  ">Invite</div>{" "}
              <IOSSwitch sx={{ m: 1 }} defaultChecked />
            </div>
          </div> */}
        </>
      ) : (
        ""
      )}
    </div>
  );
};
