import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import backend_URL from "../../../config";

// Define the base query
const baseQuery = fetchBaseQuery({
  baseUrl: `${backend_URL}/api/v1`,  // Assuming API v1 is used
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");  // Get the token from cookies
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Define the categories API
export const categoriesApi = createApi({
  reducerPath: "categoriesApi",  // Unique key to mount the API slice in Redux
  baseQuery,
  endpoints: (builder) => ({
    // Fetch all categories
    getCategories: builder.query({
      query() {
        return {
          url: "/categories",
          method: "GET",
        };
      },
    }),
    // Fetch a single category by ID
    getCategoryById: builder.query({
      query(categoryId) {
        return {
          url: `/categories/${categoryId}`,
          method: "GET",
        };
      },
    }),
    // Create a new category
    postCategory: builder.mutation({
      query(category) {
        return {
          url: "/categories",
          method: "POST",
          body: category,
        };
      },
    }),
    // Update an existing category
    updateCategory: builder.mutation({
      query({ categoryId, categoryData }) {
        return {
          url: `/categories/${categoryId}`,
          method: "PUT",
          body: categoryData,
        };
      },
    }),
    // Delete a category by ID
    deleteCategory: builder.mutation({
      query(categoryId) {
        return {
          url: `/categories/${categoryId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

// Export hooks for the categories API
export const {
  useGetCategoriesQuery,
  useGetCategoryByIdQuery,
  usePostCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
