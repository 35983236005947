import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IOSSwitch from "./IOSSwitch";
import { motion } from "framer-motion";

import { useGetMeQuery } from "../../redux/api/userApi";
import { useLogoutMutation } from "../../redux/api/authApi";
import {
  useGetDepartmentPermissionByUserQuery,
  useGetVerificationForUserAccessInDepartmentQuery,
} from "../../redux/api/departmentsApi";
import { useSelector } from "react-redux";
import menuDictionary from "./menuDictionary";
import settingsDictionary from "./settingsDictionary";
import unprotectedDictionary from "./unprotectedDictionary";
import "../custom-scrollbar.css";
import { skipToken } from "@reduxjs/toolkit/query";

const useReloadNavigate = () => {
  const navigate = useNavigate();

  const reloadAndNavigate = (path) => {
    window.location.href = path;
  };

  return reloadAndNavigate;
};

const MobileSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: userData, error } = useGetMeQuery();
  const user = useSelector((state) => state.user.user);

  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem("theme") === "dark"
  );
  const [openMenu, setOpenMenu] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const profileMenuOpen = Boolean(profileAnchorEl);
  const [logout] = useLogoutMutation();
  const reloadAndNavigate = useReloadNavigate();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const { data: permissionsData, refetch } =
    useGetDepartmentPermissionByUserQuery(
      userData ? { id: userData._id } : skipToken
    );

  const departmentAccess =
    useGetVerificationForUserAccessInDepartmentQuery({
      id: userData?._id,
      departmentNames: ["DEV", "SMM", "PPC", "SEO", "SALES"],
    }).data?.departmentAccess || {};

  const sidebarRef = useRef(null);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  }, [darkMode]);

  useEffect(() => {
    if (userData) {
      refetch();
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
      const intervalId = setInterval(refetch, 150000);
      return () => clearInterval(intervalId);
    }
  }, [userData, refetch, location.pathname, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsCollapsed(true);
      }
    };

    if (!isCollapsed) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCollapsed]);

  const handleThemeChange = () => {
    setDarkMode((prev) => !prev);
    document.documentElement.classList.toggle("dark");
    localStorage.setItem("theme", darkMode ? "dark" : "light");
  };

  const handleOpenOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const handleOpenProfileMenu = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setProfileAnchorEl(null);
  };

  const handleNavigation = (path, disabled) => {
    if (!disabled) {
      navigate(path);
    }
  };

  const handleLogout = async () => {
    await logout();
    reloadAndNavigate("/");
  };

  const isItemSelected = (item) => {
    if (location.pathname === item.path) {
      return true;
    }
    return item.children?.some(isItemSelected) ?? false;
  };

  const handleToggle = (item) => {
    if (item.children) {
      setIsCollapsed(false);
      setOpenMenu((prev) => ({ ...prev, [item.label]: !prev[item.label] }));
    } else {
      handleNavigation(item.path, false);
    }
  };

  const handleSidebarToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  const renderMenu = (menu) => {
    return menu.map((item) => {
      const isAdmin = user && user.role === "Admin";

      if (item.isAdmin && !isAdmin) {
        return null;
      }

      const hasPermission = isAdmin || (item.permission
        ? permissionsData?.permissions[item.permission]
        : true);

      const hasDeptAccess = isAdmin || !item.departments || item.departments.some(
        (department) => departmentAccess[department] === true
      );

      const isOpen = openMenu[item.label];
      const isSelected = isItemSelected(item);
      const isDisabled = (item.permission && !hasPermission) || !hasDeptAccess;

      return (
        <React.Fragment key={item.label}>
          <ListItem
            button
            onClick={() => handleToggle(item)}
            className={`${
              isSelected
                ? "bg-[#E8E8FF] rounded-[52px] text-[#7674C2] dark:bg-[#484878] dark:text-[#EDEDFB] mt-1 "
                : "text-zinc-500 dark:text-[#EDEDFB]"
            } ${
              isDisabled && !isAdmin
                ? "cursor-not-allowed text-gray-400 dark:text-gray-400"
                : ""
            }`}
            disabled={isDisabled && !isAdmin}
          >
            <div
              className={`flex items-center ${isCollapsed ? "justify-center w-full" : ""}`}
            >
              {item.icon &&
                React.cloneElement(item.icon, {
                  style: { fontSize: isCollapsed ? "32px" : "24px" },
                })}
            </div>
            {!isCollapsed && (
              <ListItemText
                primary={item.label}
                className="ml-[28px] text-[15px] font-normal"
              />
            )}
            {item.children && !isCollapsed ? (
              isOpen ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItem>
          {!isCollapsed && item.children && (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenu(item.children)}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  const menuToRender = user
    ? location.pathname.includes("/settings")
      ? settingsDictionary
      : menuDictionary
    : unprotectedDictionary;

  return (
    <>
      {!isCollapsed && (
        <div className="fixed inset-0 bg-black opacity-50 z-30"></div>
      )}

      <motion.div
        ref={sidebarRef}
        className={`scrollbar-thin h-[100vh] dark:border-[#7271AB] flex flex-col justify-between absolute top-0 left-0 z-40 bg-white dark:bg-[#383757] overflow-auto`}
        initial={{ width: "90px" }}
        animate={{ width: isCollapsed ? "90px" : "330px" }}
        transition={{ duration: 0.3 }}
      >
        <div className="">
          <div className="flex justify-center mt-4">
            <button
              onClick={handleSidebarToggle}
              className="dark:text-white text-xl"
            >
              {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </button>
          </div>
          <div className="h-[70px] border-b dark:border-[#7271AB] flex items-center justify-center">
            <div onClick={() => navigate("/")} className="cursor-pointer">
              <img
                className={`${isCollapsed ? "h-[30px]" : "h-[50px]"}`}
                src={
                  isCollapsed
                    ? darkMode
                      ? "/images/small_logo_dark_mode.png"
                      : "/images/Logo_FuseDigital_mov.png"
                    : darkMode
                    ? "/images/logo_bw.png"
                    : "/images/Logo_mov.png"
                }
                alt="Logo"
              />
            </div>
          </div>
          <div className="mt-[10px]">
            <List component="nav">{renderMenu(menuToRender)}</List>
          </div>
        </div>
        <div className="pb-20">
          {user && (
            <div
              className="flex items-center justify-start mb-[10px] cursor-pointer ml-[26px]"
              onClick={handleOpenProfileMenu}
            >
              <div
                className={`${
                  isCollapsed ? "w-[40px] h-[40px]" : "w-[66px] h-[66px]"
                } rounded-full mr-[17px]`}
                style={{
                  backgroundImage: `url(${user?.avatar?.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              {!isCollapsed && (
                <>
                  <div className="flex-1">
                    <div className="text-zinc-600 dark:text-[#EDEDFB] text-[15px] font-normal mb-[8px]">
                      {user?.name}
                    </div>
                    <div className="text-zinc-500 dark:text-[#EDEDFB] text-[13px] font-normal">
                      {user?.email}
                    </div>
                  </div>
                  <ExpandMore className="text-zinc-500 dark:text-[#EDEDFB]" />
                </>
              )}
            </div>
          )}
          <Menu
            anchorEl={profileAnchorEl}
            open={profileMenuOpen}
            onClose={handleCloseProfileMenu}
            PaperProps={{
              elevation: 0,
              className:
                "bg-[#ECECEC] text-[#484878] dark:bg-[#1c1c30] dark:text-[#EDEDFB]",
            }}
          >
            <MenuItem
              onClick={() => {
                handleCloseProfileMenu();
                handleNavigation("/settings/profile-settings");
              }}
            >
              <div className="text-neutral-400 dark:text-[#EDEDFB] text-[13px] font-normal">
                Profile Settings
              </div>
            </MenuItem>
            <MenuItem onClick={handleCloseProfileMenu}>
              <div className="text-neutral-400 dark:text-[#EDEDFB] text-[13px] font-normal">
                Help Center
              </div>
            </MenuItem>
            <Divider
              sx={{ width: "80%", ml: 1 }}
              className="dark:bg-[#EDEDFB]"
            />
            <MenuItem onClick={handleLogout}>
              <div className="text-neutral-400 dark:text-[#EDEDFB] text-[13px] font-normal">
                Log Out
              </div>
            </MenuItem>
          </Menu>
          <div className="border-t dark:border-[#7271AB] h-[60px] flex items-center justify-center">
            <IOSSwitch
              sx={{ m: 1 }}
              checked={darkMode}
              onChange={handleThemeChange}
            />
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default MobileSidebar;
