import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie"; // Import js-cookie
import backend_URL from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const clientsApi = createApi({
  reducerPath: "clientsApi",
  baseQuery,
  endpoints: (builder) => ({
    getClients: builder.query({
      query() {
        return {
          url: "/clients",
          method: "GET",
        };
      },
    }),
    postClient: builder.mutation({
      query(client) {
        return {
          url: "/clients",
          method: "POST",
          body: client,
        };
      },
    }),
    deleteClient: builder.mutation({
      query(clientId) {
        return {
          url: `/clients/${clientId}`,
          method: "DELETE",
        };
      },
    }),
    updateClient: builder.mutation({
      query({ clientId, clientData }) {
        return {
          url: `/clients/${clientId}`,
          method: "PUT",
          body: clientData,
        };
      },
    }),
  }),
});

export const {
  useGetClientsQuery,
  usePostClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientsApi;
