import React, { useState } from "react";

const BackendSearchBar = ({ onSearchQuery }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query) => {
    setSearchQuery(query);
    onSearchQuery(query); // Send the search query back to the parent
  };

  return (
    <div className="inline-flex flex-col items-start gap-2.5 px-5 py-[5px] relative  bg-[#E7E7F9] dark:bg-[#363660] rounded-[52px] overflow-hidden">
      <div className="gap-[22px] inline-flex items-center relative flex-[0_0_auto] w-full">
        <input
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          className="w-full h-10 bg-transparent border-none rounded-lg p-2 placeholder-[#979BE2] text-[#979BE2] dark:text-white focus:outline-none"
          onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing
          style={{ outline: "none" }}
        />
            <div className="relative w-[25px] h-[25px] text-[#8C8CB0] dark:text-[#979BE2]">
            <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="basil:search-outline">
                <path
                    id="Vector"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.9847 16.0889C13.5807 17.2107 11.8005 17.7523 10.0096 17.6025C8.21874 17.4527 6.55321 16.6228 5.35508 15.2834C4.15696 13.9439 3.51719 12.1966 3.56718 10.4001C3.61716 8.60371 4.35311 6.89462 5.62386 5.62386C6.89462 4.35311 8.60371 3.61716 10.4001 3.56718C12.1966 3.51719 13.9439 4.15696 15.2834 5.35508C16.6228 6.55321 17.4527 8.21874 17.6025 10.0096C17.7523 11.8005 17.2107 13.5807 16.0889 14.9847L21.4597 20.3545C21.5365 20.426 21.598 20.5123 21.6407 20.6081C21.6834 20.704 21.7064 20.8074 21.7083 20.9123C21.7101 21.0172 21.6908 21.1214 21.6515 21.2187C21.6122 21.316 21.5537 21.4043 21.4796 21.4785C21.4054 21.5527 21.317 21.6112 21.2197 21.6505C21.1224 21.6898 21.0182 21.7091 20.9133 21.7072C20.8084 21.7054 20.705 21.6824 20.6092 21.6397C20.5133 21.597 20.4271 21.5354 20.3556 21.4587L14.9847 16.0889ZM6.72847 14.4618C5.96393 13.6972 5.44321 12.7231 5.23213 11.6626C5.02105 10.6021 5.12907 9.50288 5.54255 8.50378C5.95602 7.50468 6.6564 6.65058 7.55515 6.04941C8.45391 5.44824 9.5107 5.12699 10.592 5.12627C11.6733 5.12554 12.7305 5.44536 13.6301 6.04532C14.5296 6.64528 15.2311 7.49844 15.646 8.49698C16.0608 9.49553 16.1703 10.5946 15.9606 11.6554C15.751 12.7161 15.2316 13.6909 14.4681 14.4566L14.4628 14.4618L14.4576 14.466C13.4317 15.4895 12.0414 16.064 10.5922 16.0632C9.14297 16.0625 7.75331 15.4865 6.72847 14.4618Z"
                />
                </g>
            </svg>
            </div>
      </div>
    </div>
  );
};

export default BackendSearchBar;
