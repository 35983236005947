import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../../config";
import Cookies from "js-cookie"; // Import js-cookie

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const leadsApi = createApi({
  reducerPath: "leadsApi",
  baseQuery,
  endpoints: (builder) => ({
    getLeads: builder.query({
      query() {
        return {
          url: "/leads",
          method: "GET",
        };
      },
    }),
    getLeadDetails: builder.query({
      query(leadId) {
        return {
          url: `/leads/${leadId}`,
          method: "GET",
        };
      },
    }),
    postLead: builder.mutation({
      query(lead) {
        return {
          url: "/leads",
          method: "POST",
          body: lead,
        };
      },
    }),
    updateLead: builder.mutation({
      query(lead) {
        return {
          url: `/leads/${lead._id}`,
          method: "PUT",
          body: lead,
        };
      },
    }),
    getPaginatedLeads: builder.query({
      query: ({ page = 1, limit = 8, search = '', status, leadSource, owner }) => {
        let queryParams = `page=${page}&limit=${limit}`;
        
        if (search) {
          queryParams += `&search=${search}`;
        }
        if (status) {
          queryParams += `&status=${status}`;
        }
        if (leadSource) {
          queryParams += `&leadSource=${leadSource}`;
        }
        if (owner) {
          queryParams += `&owner=${owner}`;
        }
    
        return {
          url: `/leads/paginated?${queryParams}`,
          method: "GET",
        };
      },
    }),    
    deleteLead: builder.mutation({
      query(leadId) {
        return {
          url: `/leads/${leadId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetLeadDetailsQuery,
  useGetLeadsQuery,
  usePostLeadMutation,
  useDeleteLeadMutation,
  useUpdateLeadMutation,
  useGetPaginatedLeadsQuery,
} = leadsApi;
