import React, { useState } from "react";
import { useGetMeQuery } from "../../redux/api/userApi";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectCardDashboard from "../ProjectsCardDashboard";
import { LastTasks } from "../LastTasks";
import { useGetProjectsQuery } from "../../redux/api/projectsApi";
import { ClientsCard } from "../ClientsPanel/ClientsCard";
import { useGetAccountsQuery } from "../../redux/api/crm/accountsApi";
import { Link } from "react-router-dom";
import { useGetNotificationsForReceiverQuery } from "../../redux/api/notificationApi";
import NotificationComponent from "../NotificationComponent";
import { useContext } from "react";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import { SalesOverview } from "./SalesOverview";
import { io } from "socket.io-client";
import backend_URL from "../../config";
import { useEffect } from "react";
import { useSelector } from "react-redux";
dayjs.extend(advancedFormat);

const Dashboard = () => {
  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
    refetch: refetchMe,
  } = useGetMeQuery();

  const {
    data: accountsData,
    error: accountsError,
    isLoading: accountsLoading,
    refetch: refetchAccounts,
  } = useGetAccountsQuery();

  const { data: notificationsData, error: notificationsError, isLoading: notificationsLoading, refetch: refetchNotifications
   } = useGetNotificationsForReceiverQuery(
    meData?._id
  );

  const user = useSelector((state) => state.user.user);


  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const { data, errror, isLoading, refetch} = useGetMeQuery();

  const {
    data: projectsData,
    error: projectsError,
    isLoading: projectsLoading,
    refetch: refetchProjects,
  } = useGetProjectsQuery();


  useEffect(() => {
    const socket = io(backend_URL);
    window.socket = socket;

    socket.on('userPing', (data) => {
      refetch();
      refetchMe();
    });

    socket.on('projectPing', (data) => {
      refetchProjects();
    });

    socket.on('accountPing', (data) => {
      refetchAccounts();
    });
  

    return () => {
      socket.disconnect();
    };
  }, []);

  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderSettingsClients = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const isMobileView = useContext(ViewTypeContext); // true daca e mobil/tableta

  return (
    <>
      <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  pb-[20px] dark:bg-[#2B2A49] ">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center">
            <div className="ml-5 relative">
              <p className="text-[15px] text-[#4A488E] dark:text-[#EDEDFB] flex flex-row items-center gap-[11px]">
                {dayjs().format("dddd, Do MMMM")}
              </p>
            </div>
          </div>

          <NotificationComponent />
        </div>
        <LastTasks />
        <div className="bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[21px] py-[32px] mt-[20px] px-[33px] w-full">
          <h3 className="text-[20px] dark:text-[#EDEDFB]">
            Projects in progress
          </h3>
          <div className="mt-[20px] gap-[20px]">
            <div className="w-full">
              <Slider {...sliderSettings}>
                {projectsData?.projects
                  .filter((pr) => pr.status === "In Progress")
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort projects by creation date, latest first
                  .map((p) => (
                    <ProjectCardDashboard project={p} key={p._id} />
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        <div
          className={`flex md:mb-40 ${
            isMobileView ? " flex-row" : "flex-row"
          }  justify-between gap-[7px] `}
        >
          <div
            className={`${
              isMobileView ? " w-6/12" : "w-6/12"
            } bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[21px] py-[32px] mt-[20px] px-[30px]`}
          >
            <h3 className="text-[20px] dark:text-[#EDEDFB] mb-[24px]">
              Clients
            </h3>
            <Slider {...sliderSettingsClients}>
              {accountsData?.accounts.map((client, index) => (
                <Link
                  to={`/clients/${client.nameComp.replace(/ /g, "-")}`}
                  className="flex flex-row"
                  key={index}
                >
                  <ClientsCard client={client} />
                </Link>
              ))}
            </Slider>
          </div>
          {user?.role === "Admin" && (
          <div
            className={`${
              isMobileView ? " w-6/12" : "w-6/12"
            } bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[21px] py-[32px] mt-[20px] px-[30px]`}
          >
            <h3 className="text-[20px] mb-[24px] dark:text-[#EDEDFB]">
              Sales Overview
            </h3>
            <SalesOverview />
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
