import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import { useGetClientsQuery } from "../redux/api/clientsApi";
import { useGetAllUsersQuery } from "../redux/api/authApi";
import { useGetAccountsQuery } from "../redux/api/crm/accountsApi";
import {
  ProjectAssignedIcon,
  ProjectClientIcon,
  ProjectDateIcon,
  ProjectDepartmentIcon,
  ProjectDescriptionIcon,
  ProjectPriorityIcon,
  ProjectStatusIcon,
  ProjectTagsIcon,
  ProjectTitleIcon,
} from "./sidebar/svgs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "#FAFAFE",
  boxShadow: 24,
  overflow: "auto",
  p: 4,
};

const departmentColors = {
  development: "#0074FD",
  smm: "#1ABC9C",
  "graphic design": "#1ABC9C",
  ppc: "#FF7918",
  seo: "#6366F1",
};

const ProjectViewMode = ({ project, openNow, onClose, projectDashboard }) => {
  const { data: clientsData } = useGetClientsQuery();
  const { data: usersData } = useGetAllUsersQuery();
  const { data: accountsData } = useGetAccountsQuery();
  const [value, setValue] = useState(dayjs("2022-04-17"));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const toggleCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const clientName =
    accountsData?.accounts.find((client) => client._id === project?.client)
      ?.nameComp || "Unknown Client";

  const getUserName = (userId) => {
    const user = usersData?.users.find((user) => user._id === userId);
    return user
      ? { name: user.name, avatarUrl: user.avatar?.url }
      : { name: "Unknown User", avatarUrl: null };
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const priorityColors = {
    High: "#FF3939",
    Medium: "#FACC15",
    Low: "#38BDF8",
  };

  const statusColors = {
    "In Progress": "#C026D3",
    "To do": "#2ECC71",
    Completed: "#F472B6",
  };
  useEffect(() => {
    setOpen(openNow);
  }, [openNow]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  return (
    <div>
      {!projectDashboard ? (
        <button
          className="py-[12px]  px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
          onClick={handleOpen}
        >
          View Mode
        </button>
      ) : (
        ""
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="dark:bg-[#2B2A49] md:pb-40">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="flex justify-between mb-[34px]">
              <p className="text-[#4A488E] text-[30px] dark:text-[#EDEDFB]">
                Project
              </p>
              <div className="text-[#4A488E] dark:text-white">
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={handleClose}
                >
                  <g id="ph:x">
                    <path
                      id="Vector"
                      d="M29.724 28.0884C29.8314 28.1958 29.9166 28.3233 29.9747 28.4637C30.0329 28.604 30.0628 28.7545 30.0628 28.9064C30.0628 29.0583 30.0329 29.2088 29.9747 29.3491C29.9166 29.4895 29.8314 29.617 29.724 29.7245C29.6165 29.8319 29.489 29.9171 29.3486 29.9752C29.2083 30.0334 29.0578 30.0633 28.9059 30.0633C28.754 30.0633 28.6036 30.0334 28.4632 29.9752C28.3228 29.9171 28.1953 29.8319 28.0879 29.7245L18.4997 20.1348L8.91146 29.7245C8.6945 29.9414 8.40024 30.0633 8.09342 30.0633C7.78659 30.0633 7.49233 29.9414 7.27537 29.7245C7.05841 29.5075 6.93652 29.2132 6.93652 28.9064C6.93652 28.5996 7.05841 28.3053 7.27537 28.0884L16.865 18.5002L7.27537 8.91195C7.05841 8.69499 6.93652 8.40073 6.93652 8.0939C6.93652 7.78708 7.05841 7.49282 7.27537 7.27586C7.49233 7.0589 7.78659 6.93701 8.09342 6.93701C8.40024 6.93701 8.6945 7.0589 8.91146 7.27586L18.4997 16.8655L28.0879 7.27586C28.3048 7.0589 28.5991 6.93701 28.9059 6.93701C29.2127 6.93701 29.507 7.0589 29.724 7.27586C29.9409 7.49282 30.0628 7.78708 30.0628 8.0939C30.0628 8.40073 29.9409 8.69499 29.724 8.91195L20.1343 18.5002L29.724 28.0884Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="px-[50px] ">
              <div className="mb-[30px]">
                <div className="flex gap-[16px]">
                  <ProjectTitleIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Project title:
                  </p>
                </div>
                <div>
                  <p className="text-[#636363] text-[20px] mt-[30px] dark:text-[#EDEDFB]">
                    {project?.nume}
                  </p>
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectDescriptionIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Project description
                  </p>
                </div>
                <div>
                  <p
                    className="text-[#636363] text-[20px] mt-[30px] dark:text-[#EDEDFB]"
                    dangerouslySetInnerHTML={{ __html: project?.description }}
                  ></p>
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectClientIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Client
                  </p>
                </div>
                <div>
                  <p className="text-[#636363] text-[20px] mt-[30px] dark:text-[#EDEDFB]">
                    {clientName}
                  </p>
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectDepartmentIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Department
                  </p>
                </div>
                <div className="flex gap-[18px] mt-[30px]">
                  {project?.department?.map((department, index) => (
                    <span
                      key={index}
                      className="px-[18px] py-[5px]  text-white rounded-[20px]"
                      style={{
                        backgroundColor:
                          departmentColors[department.toLowerCase()],
                      }}
                    >
                      {department}
                    </span>
                  ))}
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectAssignedIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Assigned to
                  </p>
                </div>
                <div className="flex gap-[18px] mt-[30px]">
                  {project?.assignedTo.map((userId, index) => {
                    const { name, avatarUrl } = getUserName(userId);
                    return (
                      <span
                        key={index}
                        className="px-[15px] py-[5px] bg-[#7D7CB6] rounded-[20px] flex items-center gap-[11px] text-white"
                      >
                        <div
                          className="w-[30px] h-[30px] rounded-full "
                          style={{
                            backgroundImage: `url(${
                              avatarUrl || "/images/avatar.svg"
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                        {name}
                      </span>
                    );
                  })}
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectPriorityIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Priority
                  </p>
                </div>
                <div className="flex gap-[18px] mt-[30px]">
                  <span
                    className="px-[18px] py-[5px] text-white rounded-[20px] dark:text-[#EDEDFB]"
                    style={{
                      backgroundColor: priorityColors[project?.priority],
                    }}
                  >
                    {project?.priority}
                  </span>
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectStatusIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Status
                  </p>
                </div>
                <div className="flex gap-[18px] mt-[30px]">
                  <span
                    className="px-[15px] py-[5px] rounded-[20px] flex items-center gap-[11px] text-white"
                    style={{ backgroundColor: statusColors[project?.status] }}
                  >
                    {project?.status}
                  </span>
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectTagsIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Tags
                  </p>
                </div>
                <div className="flex gap-[18px] mt-[30px]">
                  {project?.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-[15px] py-[5px] bg-[#7E22CE] rounded-[20px] flex items-center gap-[11px] text-white"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
              <div className="mb-[30px]">
                <div className="flex gap-[16px] ">
                  <ProjectDateIcon />
                  <p className="text-[20px] text-[#4A488E] dark:text-[#EDEDFB]">
                    Due date
                  </p>
                </div>
                <div className="flex gap-[18px] mt-[30px]">
                  <span className="px-[15px] py-[5px] bg-[#F39C12] rounded-[20px] flex items-center gap-[11px] text-white">
                    {formatDate(project?.dueDate)}
                  </span>
                </div>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectViewMode;
