import React, { useState, useEffect } from "react";
import SquareInput from "../UI/input";
import SquareDropdown from "../UI/dropdown";
import FuseButton from "../UI/button";
import FuseDatepicker from "../UI/datepicker";
import ImageUpload from "../UI/fileupload";

const ProductModal = ({
  isOpen,
  onClose,
  onSave,
  product = null,
  categories = [],
}) => {
  const [formData, setFormData] = useState({
    name: "",
    category: "",
    description: "",
    status: "",
    SKUNumber: "",
    stock: 0,
    unitPrice: 0,
    expiryDate: "",
    image: null, // To handle images
    _id: null, // Include _id in form state
  });

  // Populate form if product data is passed for editing
  useEffect(() => {
    if (product) {
      setFormData({
        name: product.name || "",
        category: product.category?._id || "", // Store the category ID, not name
        description: product.description || "",
        status: product.status || "",
        SKUNumber: product.SKUNumber || "",
        stock: product.stock || 0,
        unitPrice: product.unitPrice || 0,
        expiryDate: product.expiryDate || "",
        image: product.image || null, // Use the image URL from the product object
        _id: product._id || null, // Retain the product _id for editing
      });
    } else {
      resetForm();
    }
  }, [product]);

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: "",
      category: "",
      description: "",
      status: "",
      SKUNumber: "",
      stock: 0,
      unitPrice: 0,
      expiryDate: "",
      image: null,
      _id: null, // Reset _id for new products
    });
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle image upload via the ImageUpload component
  const handleImageUpload = (base64Image) => {
    setFormData((prevData) => ({
      ...prevData,
      image: base64Image,
    }));
  };

  // Handle image deletion
  const handleImageDelete = () => {
    setFormData((prevData) => ({
      ...prevData,
      image: null,
    }));
  };

  // Handle form submission
  const handleSave = () => {
    if (
      formData.name &&
      formData.category &&
      formData.unitPrice &&
      formData.SKUNumber &&
      formData.stock
    ) {
      const selectedCategory = categories.find(
        (cat) => cat._id === formData.category
      ); // Find category by ID
      const updatedProduct = {
        ...formData,
        category: selectedCategory
          ? { _id: selectedCategory._id, name: selectedCategory.name }
          : null, // Send the correct category object
      };
      onSave(updatedProduct);
      onClose();
    }
  };

  if (!isOpen) return null; // Don't render modal if not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="max-w-[90%] w-[803px] max-h-[90vh] overflow-y-auto scrolling-touch  px-[20px] py-10 bg-white dark:bg-[#2b2a49] rounded-[14px]">
        <div className="w-full flex flex-col">
          <div className="flex justify-between">
            <div className="text-[#2B2A49] dark:text-[#ededfb] text-[25px] font-normal">
              {product ? "Edit Product" : "New Product"}
            </div>
            <button className="text-white text-2xl" onClick={onClose}>
              &times;
            </button>
          </div>

          <div className="flex-col justify-start items-start gap-[15px] mt-10 space-y-4">
            <SquareInput
              label="Name"
              value={formData.name}
              onChange={(value) =>
                handleChange({ target: { name: "name", value } })
              }
            />
            <SquareDropdown
              label="Category"
              value={formData.category} // Category ID is stored here
              onChange={(value) =>
                setFormData({ ...formData, category: value })
              } // Update category ID on change
              options={categories.map((category) => ({
                value: category._id, // Use the category ID
                label: category.name, // Display the category name
              }))}
              placeholder="Select a category"
            />
            <SquareInput
              label="Description"
              type="textarea"
              value={formData.description}
              onChange={(value) =>
                handleChange({ target: { name: "description", value } })
              }
            />
            <ImageUpload
              onUpload={handleImageUpload}
              onDelete={handleImageDelete}
              imageUrl={formData.image} // Pass the image URL for edit mode
              uploadText="Product Image" // Text to display above the upload area
            />
            <SquareDropdown
              label="Status"
              value={formData.status}
              onChange={(value) => setFormData({ ...formData, status: value })}
              options={[
                { value: "Good Stock", label: "Good Stock" },
                { value: "Low Stock", label: "Low Stock" },
                { value: "Running Low", label: "Running Low" },
                { value: "Moderate Stock", label: "Moderate Stock" },
                { value: "Sold", label: "Sold" },
                { value: "Reserved", label: "Reserved" },
              ]}
            />
            <SquareInput
              label="SKU Number"
              type="text"
              value={formData.SKUNumber}
              onChange={(value) =>
                handleChange({ target: { name: "SKUNumber", value } })
              }
            />
            <SquareInput
              label="Stock"
              type="number"
              value={formData.stock}
              onChange={(value) =>
                handleChange({ target: { name: "stock", value } })
              }
            />
            <SquareInput
              label="Unit Price"
              type="number"
              value={formData.unitPrice}
              onChange={(value) =>
                handleChange({ target: { name: "unitPrice", value } })
              }
            />
            <FuseDatepicker
              label="Expiry Date"
              value={formData.expiryDate}
              onChange={(value) =>
                handleChange({ target: { name: "expiryDate", value } })
              }
            />
          </div>

          <div className="flex justify-end mt-4">
            <FuseButton
              label={product ? "Save" : "Create"}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
