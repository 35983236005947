import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
} from "../redux/api/projectsApi";

import { useGetAllUsersQuery } from "../redux/api/authApi";

import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import SelectInput from "./utils/forms/ProjectFormInput";

import { useCreateNotificationMutation } from "../redux/api/notificationApi";

import { useGetAccountsQuery } from "../redux/api/crm/accountsApi";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import {
  ProjectAssignedIcon,
  ProjectClientIcon,
  ProjectDateIcon,
  ProjectDepartmentIcon,
  ProjectDescriptionIcon,
  ProjectPriorityIcon,
  ProjectStatusIcon,
  ProjectTagsIcon,
  ProjectTitleIcon,
} from "./sidebar/svgs";
import { useTheme } from "@emotion/react";

const departmentsList = [
  { name: "Development", color: "#e6e5ff" },

  { name: "SEO", color: "#e6e5ff" },

  { name: "PPC", color: "#e6e5ff" },
  { name: "Sales", color: "#e6e5ff" },

  { name: "SMM", color: "#e6e5ff" },

  { name: "Graphic Design", color: "#e6e5ff" },

  { name: "Copywriting", color: "#e6e5ff" },
];

const priorityList = [
  { name: "Low", color: "#38BDF8" },

  { name: "Medium", color: "#FACC15" },

  { name: "High", color: "#FF3939" },
];

const statusList = [
  { name: "To do", color: "#2ECC71" },

  { name: "In Progress", color: "#C026D3" },

  { name: "Completed", color: "#F472B6" },
];

const tagsList = [
  { name: "Mentenanta", color: "#7E22CE" },

  { name: "E-mail MKT", color: "#7E22CE" },

  { name: "App", color: "#7E22CE" },
  { name: "Website", color: "#7E22CE" },
  { name: "Articole ", color: "#7E22CE" },
];

const style = {
  position: "absolute",

  top: "50%",

  left: "50%",

  transform: "translate(-50%, -50%)",

  width: "100%",

  height: "100%",

  boxShadow: 24,

  p: 4,

  overflow: "auto",
};

const ProjectForm = ({ project, initialStatus = "", open, onClose }) => {
  const { data: usersData, refetch: refetchUsers } = useGetAllUsersQuery();

  const {
    data: accountsData,

    error: accountsError,

    isLoading: accountsLoading,
    refetch: refetchAccounts,
  } = useGetAccountsQuery();

  useEffect(() => {
    if (accountsData && usersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetchAccounts();
        refetchUsers();
      }, 4000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [accountsData, usersData, refetchAccounts, refetchUsers]);

  const [createProject] = useCreateProjectMutation();

  const [updateProject] = useUpdateProjectMutation();

  const [createTags, setCreateTags] = useState([]); // State for newly created tags

  const [value, setValue] = useState(dayjs());

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState("");

  const [departments, setDepartments] = useState([]);

  const [assignedUsers, setAssignedUsers] = useState([]);

  const [selectedPriority, setSelectedPriority] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState(
    initialStatus ? [initialStatus] : []
  );

  const [postNotification] = useCreateNotificationMutation();

  const [selectedTags, setSelectedTags] = useState([]);

  const [description, setDescription] = useState("");

  const [title, setTitle] = useState("");

  const user = useSelector((state) => state.user.user);
  const theme = useTheme();

  const [theme2, setTheme2] = useState(localStorage.getItem("theme"));
  useEffect(() => {
    if (project) {
      setTitle(project.nume || "");

      setDescription(project.description || "");

      setSelectedAccount(project.account || "");

      setDepartments(project.department || []);

      setAssignedUsers(project.assignedTo || []);

      setSelectedPriority(project.priority || "");

      setSelectedStatus(project.status || "");

      setSelectedTags(project.tags || []);

      setValue(dayjs(project.dueDate) || dayjs());
    } else if (initialStatus) {
      setSelectedStatus([initialStatus]);
    }
  }, [project, initialStatus]);

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);

  const resetForm = () => {
    setTitle("");

    setDescription("");

    setSelectedAccount("");

    setDepartments([]);

    setAssignedUsers([]);

    setSelectedPriority([]);

    setSelectedStatus(initialStatus ? [initialStatus] : []);

    setSelectedTags([]);

    setValue(dayjs());

    setCreateTags([]); // Reset new tags
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newProject = {
      nume: title,

      client: selectedAccount,

      tasks: [],

      description,

      department: departments,

      tags: [...selectedTags], // Include new tags

      status: selectedStatus[0],

      priority: selectedPriority[0],

      assignedTo: assignedUsers,

      dueDate: value.toISOString(),

      comentarii: [],
    };

    try {
      if (project) {
        await updateProject({ id: project._id, body: newProject }).unwrap();

        await Promise.all(
          assignedUsers.map(async (userId) => {
            const notification = {
              message: `assigned you a new project: ${title}`,

              toPage: "/projects/My-Projects",

              senderId: user._id,

              receiverId: userId,
            };

            toast.success("Project updated successfully");

            return postNotification(notification).unwrap();
          })
        );
      } else {
        await createProject(newProject).unwrap();

        await Promise.all(
          assignedUsers.map(async (userId) => {
            const notification = {
              message: `assigned you a new project: ${title}`,

              toPage: "/projects/My-Projects",

              senderId: user._id,

              receiverId: userId,
            };

            toast.success("Project created successfully");

            return postNotification(notification).unwrap();
          })
        );
      }

      resetForm();

      onClose();
    } catch (error) {
      console.error("Failed to save project:", error);
    }
  };

  const handleClose = () => {
    resetForm();

    onClose();
  };

  const sortedAccounts = accountsData?.accounts
    ? [...accountsData.accounts]
        .filter((account) => account.isActive !== false) // Filter out inactive accounts
        .sort((a, b) => a.nameComp.localeCompare(b.nameComp)) // Sort by nameComp
    : [];

  const sortedUsers = usersData?.users
    ? [...usersData.users].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="bg-[#E6E5FF] dark:bg-[#2B2A49] text-[#4A488E] dark:text-[#EDEDFB] overflow-y-auto scrolling-touch "
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="flex justify-between mb-[34px]">
            <p className="text-[#4A488E] dark:text-[#EDEDFB]">
              {project ? "Edit project" : "New project"}
            </p>

            <img
              className="cursor-pointer"
              src="/images/blue-close.svg"
              width={30}
              height={30}
              alt=""
              onClick={handleClose}
            />
          </div>
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <form onSubmit={handleSubmit}>
            <div className="mb-[34px]">
              <label
                htmlFor="title"
                className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[5px] mb-[10px]"
              >
                <ProjectTitleIcon />
                Project title
              </label>

              <input
                type="text"
                id="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full h-[40px] border-none mt-[10px] rounded-[6px] text-black bg-[#FAFAFE] dark:bg-[#545492]  dark:text-[#EDEDFB]"
                required
              />
            </div>

            <div className="mb-[34px]">
              <label
                htmlFor="description"
                className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[5px] mb-[10px]"
              >
                <ProjectDescriptionIcon />
                Project description
              </label>

              <ReactQuill
                theme="snow"
                className="h-[250px] text-black overflow-auto mb-[10px] bg-white dark:bg-[#545492]"
                value={description}
                onChange={setDescription}
              />
            </div>

            <div className="w-full flex gap-[45px]">
              <div className="w-6/12">
                <div className="mb-[5px] flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="account"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ProjectClientIcon />
                      Account
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Account"
                      items={sortedAccounts || []}
                      selected={selectedAccount ? [selectedAccount] : []}
                      setSelected={(val) => setSelectedAccount(val[0] || "")}
                      getName={(account) => account.nameComp} // Adjust the property name based on the account data structure
                      isMultiple={false}
                      customMenuItemClasses="dark:bg-[#1A1A33]"
                      className="text-[#4A488E] dark:text-[#EDEDFB] dark:bg-[#1A1A33]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="department"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ProjectDepartmentIcon />
                      Department
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      isMultiple={true}
                      label="Department"
                      items={departmentsList}
                      selected={departments}
                      setSelected={setDepartments}
                      getName={(dept) => dept.name}
                      getColor={(dept) => dept.color}
                      customMenuItemClasses="dark:bg-[#1A1A33]"
                      className="text-[#4A488E] dark:text-[#EDEDFB] "
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="assignedUsers"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ProjectAssignedIcon />
                      Assigned to
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      isMultiple={true}
                      label="Assigned to"
                      items={sortedUsers || []}
                      selected={assignedUsers}
                      setSelected={setAssignedUsers}
                      getName={(user) => user.name}
                      customMenuItemClasses="dark:bg-[#1A1A33]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="priority"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ProjectPriorityIcon />
                      Priority
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      isMultiple={false}
                      label="Priority"
                      items={priorityList}
                      selected={selectedPriority}
                      setSelected={setSelectedPriority}
                      getName={(priority) => priority.name}
                      getColor={(priority) => priority.color}
                      customMenuItemClasses="rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 mb-[5px]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>
              </div>

              <div className="w-6/12">
                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="status"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ProjectStatusIcon />
                      Status
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Status"
                      items={statusList}
                      selected={selectedStatus}
                      setSelected={setSelectedStatus}
                      getName={(status) => status.name}
                      getColor={(status) => status.color}
                      isMultiple={false}
                      customMenuItemClasses="rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 mb-[5px]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="tags"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ProjectTagsIcon />
                      Tags
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      isMultiple={true}
                      label="Tags"
                      items={[...tagsList, ...createTags]}
                      selected={selectedTags}
                      setSelected={setSelectedTags}
                      getName={(tag) => tag.name}
                      getColor={(tag) => tag.color}
                      customMenuItemClasses="rounded-[20px] px-[20px] w-fit py-[5px] flex justify-center items-center text-white mx-auto gap-10 mb-[5px]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                      createTags={createTags}
                      setCreateTags={setCreateTags}
                    />
                  </div>
                </div>

                <div className="mb-[20px] flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="dueDate"
                      className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[14px] items-center"
                    >
                      <ProjectDateIcon />
                      Due date
                    </label>
                  </div>

                  <div className="w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="ml-5 relative">
                        <p
                          onClick={toggleCalendar}
                          className="text-[15px] text-[#4A488E] dark:text-[#EDEDFB]"
                          style={{ cursor: "pointer" }}
                        >
                          {value.format("dddd, Do MMMM")}
                        </p>

                        {isCalendarOpen && (
                          <div className="absolute z-10 top-[-342px] left-[-148px] bg-white dark:bg-[#383757] dark:text-[#EDEDFB]">
                            <DateCalendar
                              value={value}
                              onChange={(newValue) => {
                                setValue(newValue);
                                setIsCalendarOpen(false); // Auto-close the calendar after selecting a date
                              }}
                              sx={{
                                "& .MuiPickersDay-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: theme2
                                    ? "#8D8BDB"
                                    : "#0074FD",
                                },

                                "& .MuiTypography-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersCalendarHeader-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiIconButton-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersArrowSwitcher-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersYear-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="flex justify-end gap-[31px] mt-[50px]">
                  <button
                    type="submit"
                    className="py-[12px] px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
                  >
                    {project ? "Save changes" : "Create project"}
                  </button>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="py-[12px] px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Typography>
      </Box>
    </Modal>
  );
};

export default ProjectForm;
