import React from 'react';
import { TextField, Button, Chip } from '@mui/material';

const TagInput = ({ label, tags, value, onChange, onAddTag, onDeleteTag }) => (
  <div className="flex flex-col gap-[15px]">
    <div className="text-zinc-600 text-[15px] font-normal">{label}</div>
    <div className="flex items-center">
      <TextField
        className="w-[300px] bg-[#A3A3A3] dark:bg-[#444464] rounded-[4px]"
        variant="outlined"
        placeholder="Add a tag"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          style: {
            borderRadius: '4px',
            borderColor: '#D9D9D9',
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#D9D9D9',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
        }}
      />
      <Button
        variant="contained"
        onClick={onAddTag}
        style={{
          marginLeft: '10px',
          borderRadius: '4px',
          height: '50px',
          backgroundColor: '#D9D9D9',
          color: '#000',
          border: '2px solid #A3A3A3',
        }}
      >
        Apply
      </Button>
    </div>
    <div className="flex flex-col gap-2 mt-2">
      {tags.map((tag, index) => (
        <Chip
          className="w-fit"
          key={index}
          label={tag}
          onDelete={() => onDeleteTag(tag)}
          style={{
            backgroundColor: '#EEEEEE',
            color: '#909090',
          }}
        />
      ))}
    </div>
  </div>
);

export default TagInput;
