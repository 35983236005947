import React, { useState, useEffect, useRef } from "react";
import { useGetProjectsQuery } from "../../redux/api/projectsApi";

const DropdownReportsProjects = ({
  selectedProjectIds = [], // Default to an empty array
  setSelectedProjectIds,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // Fetch projects using the API query
  const { data, isFetching } = useGetProjectsQuery();

  // Create a ref for the dropdown container
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (projectId) => {
    if (selectedProjectIds.includes(projectId)) {
      // Remove project ID if already selected
      setSelectedProjectIds(
        selectedProjectIds.filter((id) => id !== projectId)
      );
    } else {
      // Add project ID if not selected
      setSelectedProjectIds([...selectedProjectIds, projectId]);
    }
    // Keep the dropdown open to allow multiple selections
    // Remove the following line if you want to close the dropdown after selection
    // setIsOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdownRef
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      // Add event listener when dropdown is open
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        type="button"
        className="justify-center rounded-md flex items-center py-[10px] px-[15px] border border-[#7674C2] bg-[#E7E7F9] dark:bg-[#E7E7F9] gap-[10px]"
        onClick={toggleDropdown}
      >
        Projects
        <span>
          <div className="text-[#4A488E] dark:text-[#4A488E]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="currentColor"
            >
              <path
                d="M2.37217 4.59942C2.31347 4.54636 2.2449 4.50538 2.17036 4.47882C2.09583 4.45226 2.01679 4.44064 1.93776 4.44462C1.85874 4.44861 1.78127 4.46812 1.70978 4.50204C1.6383 4.53597 1.57419 4.58364 1.52113 4.64234C1.46807 4.70104 1.42709 4.76961 1.40053 4.84415C1.37397 4.91868 1.36235 4.99772 1.36633 5.07675C1.37032 5.15577 1.38983 5.23324 1.42375 5.30473C1.45767 5.37621 1.50535 5.44032 1.56404 5.49338L7.09741 10.4926C7.21156 10.5959 7.36091 10.6515 7.51479 10.6481C7.66867 10.6447 7.81541 10.5825 7.92489 10.4744L13.2325 5.23545C13.2901 5.18017 13.3361 5.11401 13.3679 5.04081C13.3997 4.96761 13.4166 4.88882 13.4176 4.80902C13.4187 4.72922 13.4039 4.65001 13.3741 4.57598C13.3443 4.50195 13.3001 4.43458 13.244 4.37778C13.188 4.32098 13.1212 4.27589 13.0476 4.24512C12.9739 4.21435 12.8949 4.19852 12.8151 4.19855C12.7353 4.19858 12.6563 4.21446 12.5827 4.24527C12.5091 4.27609 12.4423 4.32123 12.3863 4.37806L7.48316 9.21758L2.37217 4.59942Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </span>
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-[200px] rounded-md shadow-lg bg-white z-50 dark:bg-[#383757] ">
          <ul className="py-1 h-[500px] w-full overflow-auto">
            {/* Display loader if fetching */}
            {isFetching && (
              <li className="px-4 py-2 text-gray-500">Loading...</li>
            )}

            {/* Map through projects and display checkboxes */}
            {data?.projects?.map((project) => (
              <li
                key={project._id}
                className="px-4 py-2 cursor-pointer flex items-center dark:text-[#EDEDFB] text-[#5B5B5B] w-fit"
              >
                <input
                  type="checkbox"
                  checked={selectedProjectIds?.includes(project._id)}
                  onChange={() => handleCheckboxChange(project._id)}
                  className="mr-2"
                />
                {project.nume}
              </li>
            ))}

            {/* If no projects are available */}
            {!isFetching &&
              (!data?.projects || data?.projects.length === 0) && (
                <li className="px-4 py-2 text-gray-500">
                  No projects available
                </li>
              )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownReportsProjects;
