import React, { useState } from "react";
import { useUpdateUserRoleMutation } from "../../redux/api/authApi";

const DropdownRoles = ({
  setCategory,
  setIsCategoryChosen,
  roleUser,
  userId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(roleUser);
  const [updateUserRole] = useUpdateUserRoleMutation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    setCategory(option);
    setIsCategoryChosen(true);
    setIsOpen(false);

    try {
      if (userId) {
        await updateUserRole({ id: userId, role: option });
      } else {
        console.error("User ID is undefined");
      }
    } catch (error) {
      console.error("Error updating user role", error);
    }
  };

  const priorityColors = {
    Active: "#0BB94A",
    Inactive: "#FF5C5C",
    Admin: "#5653FF",
    "Super Admin": "#7E22CE",
    "IT Support": "#FF7629",
  };

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="justify-center w-full rounded-md flex items-center gap-[8px] px-4 py-2 font-medium text-black"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={toggleDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill={
              capitalizeFirstLetter(selectedOption) in priorityColors
                ? priorityColors[capitalizeFirstLetter(selectedOption)]
                : "none"
            }
          >
            <g clipPath="url(#clip0_694_8544)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 10.5C7.06087 10.5 8.07828 10.0786 8.82843 9.32843C9.57857 8.57828 10 7.56087 10 6.5C10 5.43913 9.57857 4.42172 8.82843 3.67157C8.07828 2.92143 7.06087 2.5 6 2.5C4.93913 2.5 3.92172 2.92143 3.17157 3.67157C2.42143 4.42172 2 5.43913 2 6.5C2 7.56087 2.42143 8.57828 3.17157 9.32843C3.92172 10.0786 4.93913 10.5 6 10.5ZM6 12.5C7.5913 12.5 9.11742 11.8679 10.2426 10.7426C11.3679 9.61742 12 8.0913 12 6.5C12 4.9087 11.3679 3.38258 10.2426 2.25736C9.11742 1.13214 7.5913 0.5 6 0.5C4.4087 0.5 2.88258 1.13214 1.75736 2.25736C0.632141 3.38258 0 4.9087 0 6.5C0 8.0913 0.632141 9.61742 1.75736 10.7426C2.88258 11.8679 4.4087 12.5 6 12.5Z"
                fill={
                  capitalizeFirstLetter(selectedOption) in priorityColors
                    ? priorityColors[capitalizeFirstLetter(selectedOption)]
                    : "#000"
                }
              />
            </g>
            <defs>
              <clipPath id="clip0_694_8544">
                <rect
                  width="12"
                  height="12"
                  fill="black"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <span
            style={{
              color:
                capitalizeFirstLetter(selectedOption) in priorityColors
                  ? priorityColors[capitalizeFirstLetter(selectedOption)]
                  : "#000",
            }}
          >
            {selectedOption}
          </span>
          <img src="/images/blue-arrowdown.svg" alt="" />
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-[#E9E9FF] ring-1 ring-black ring-opacity-5 focus:outline-none z-50 dark:bg-[#3A3A68]"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            <ul className="flex pl-[27px] gap-[27px] flex-col z-50">
              {" "}
              {/* Updated z-index */}
              {Object.keys(priorityColors).map((option) => (
                <span
                  key={option}
                  className="no-underline text-[#595959] text-[17px] flex items-center gap-[10px] cursor-pointer dark:text-[#EDEDFB]"
                  onClick={() => handleOptionClick(option)}
                  style={{ color: priorityColors[option] }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill={priorityColors[option]}
                  >
                    <g clipPath="url(#clip0_694_8544)">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6 10.5C7.06087 10.5 8.07828 10.0786 8.82843 9.32843C9.57857 8.57828 10 7.56087 10 6.5C10 5.43913 9.57857 4.42172 8.82843 3.67157C8.07828 2.92143 7.06087 2.5 6 2.5C4.93913 2.5 3.92172 2.92143 3.17157 3.67157C2.42143 4.42172 2 5.43913 2 6.5C2 7.56087 2.42143 8.57828 3.17157 9.32843C3.92172 10.0786 4.93913 10.5 6 10.5ZM6 12.5C7.5913 12.5 9.11742 11.8679 10.2426 10.7426C11.3679 9.61742 12 8.0913 12 6.5C12 4.9087 11.3679 3.38258 10.2426 2.25736C9.11742 1.13214 7.5913 0.5 6 0.5C4.4087 0.5 2.88258 1.13214 1.75736 2.25736C0.632141 3.38258 0 4.9087 0 6.5C0 8.0913 0.632141 9.61742 1.75736 10.7426C2.88258 11.8679 4.4087 12.5 6 12.5Z"
                        fill={priorityColors[option]}
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_694_8544">
                        <rect
                          width="12"
                          height="12"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <li>{option}</li>
                </span>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownRoles;
