// redux/api/holidayApi.js

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import backend_URL from "../../config";

// Prepare the base query with the authorization header
const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Define the holidayApi
export const holidayApi = createApi({
  reducerPath: "holidayApi",
  baseQuery,
  tagTypes: ["Holidays"],
  endpoints: (builder) => ({
    requestHoliday: builder.mutation({
      query(holidayData) {
        return {
          url: "/holiday/request",
          method: "POST",
          body: holidayData,
        };
      },
      invalidatesTags: ["Holidays"],
    }),
    acceptHoliday: builder.mutation({
      query(acceptToken) {
        return {
          url: `/holiday/accept/${acceptToken}`,
          method: "GET",
        };
      },
      invalidatesTags: ["Holidays"],
    }),
    denyHoliday: builder.mutation({
      query(denyToken) {
        return {
          url: `/holiday/deny/${denyToken}`,
          method: "GET",
        };
      },
      invalidatesTags: ["Holidays"],
    }),
    getHolidays: builder.query({
      query(userId) {
        return {
          url: `/holidays/${userId}`,
          method: "GET",
        };
      },
      providesTags: ["Holidays"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useRequestHolidayMutation,
  useAcceptHolidayMutation,
  useDenyHolidayMutation,
  useGetHolidaysQuery,
} = holidayApi;
