import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0"); // getMonth() returnează luna indexată de la 0
  const year = d.getFullYear();
  return `${day}/${month}/${year}`; // Returnează în formatul dd/mm/yyyy
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { activityDetails, revenue, expense } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff", // Tooltip background color
          padding: "10px", // Padding inside the tooltip
          border: "1px solid #ccc", // Border of the tooltip
          color: "#000", // Text color
        }}
      >
        <p>
          <strong>Activities:</strong>
        </p>
        {activityDetails.map((detail, index) => (
          <p key={index}>
            {detail.type === "revenue" ? "Revenue" : "Expense"}: {detail.name}
          </p>
        ))}
        <p>{`Total Revenue: ${revenue}`}</p>
        <p>{`Total Expense: ${expense}`}</p>
      </div>
    );
  }
  return null;
};

const BarChartDashboard = ({ revenueData, expensesData }) => {
  // Combine revenues and expenses into one dataset grouped by date
  const combinedData = {};

  // Aggregate revenue data by startDate
  revenueData?.forEach((item) => {
    const dateKey = formatDate(item.startDate); // Convert date to string format
    if (!combinedData[dateKey]) {
      combinedData[dateKey] = {
        activityDetails: [],
        revenue: 0,
        expense: 0,
        date: dateKey,
      };
    }
    combinedData[dateKey].revenue += item.value;
    combinedData[dateKey].activityDetails.push({
      name: item.name,
      type: "revenue",
    });
  });

  // Aggregate expenses data by startDate
  expensesData?.forEach((item) => {
    const dateKey = formatDate(item.startDate); // Convert date to string format
    if (!combinedData[dateKey]) {
      combinedData[dateKey] = {
        activityDetails: [],
        revenue: 0,
        expense: 0,
        date: dateKey,
      };
    }
    combinedData[dateKey].expense += item.value;
    combinedData[dateKey].activityDetails.push({
      name: item.name,
      type: "expense",
    });
  });

  // Convert the combinedData object to an array
  const data = Object.values(combinedData);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        {/*Todo:  add dark mode  */}
        <XAxis dataKey="date" tick={{ fill: "white" }} />
        <YAxis tick={{ fill: "white" }} />

        <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />

        <Legend />

        {/* Bars with no hover opacity change */}
        <Bar dataKey="revenue" fill="#8884d8" fillOpacity={1} />
        <Bar dataKey="expense" fill="#82ca9d" fillOpacity={1} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartDashboard;
