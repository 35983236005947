import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../../config";
import Cookies from "js-cookie"; // Import js-cookie

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
export const contactsApi = createApi({
  reducerPath: "contactsApi",
  baseQuery,
  endpoints: (builder) => ({
    getContacts: builder.query({
      query() {
        return {
          url: "/contacts",
          method: "GET",
        };
      },
    }),
    getContactDetails: builder.query({
      query(contact) {
        return {
          url: `/contacts/${contact._id}`,
          method: "GET",
        };
      },
    }),
    postContact: builder.mutation({
      query(contact) {
        return {
          url: "/contacts",
          method: "POST",
          body: contact,
        };
      },
    }),
    updateContact: builder.mutation({
      query(contact) {
        return {
          url: `/contacts/${contact._id}`,
          method: "PUT",
          body: contact,
        };
      },
    }),
    deleteContact: builder.mutation({
      query(contact) {
        return {
          url: `/contacts/${contact}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetContactDetailsQuery,
  useGetContactsQuery,
  usePostContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation,
} = contactsApi;
