import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  clearAuth,
  setToken,
} from "../../redux/features/userSlice";
import { jwtDecode } from "jwt-decode";

const getTokenFromCookie = () => {
  const name = "token=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

const validateToken = (token, dispatch, user) => {
  try {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp * 1000 < Date.now()) {
      dispatch(clearAuth());
      localStorage.removeItem("token");
    } else {
      dispatch(setToken(token));
      localStorage.setItem("token", token);
      dispatch(setIsAuthenticated(true));

      if (user && user._id !== decodedToken.id) {
        dispatch(clearAuth());
        localStorage.removeItem("token");
      }
    }
  } catch (error) {
    dispatch(clearAuth());
    localStorage.removeItem("token");
  }
};

const useTokenValidator = (dispatch, token, user) => {
  useEffect(() => {
    const cookieToken = getTokenFromCookie();
    if (cookieToken) {
      validateToken(cookieToken, dispatch, user);
    } else {
      dispatch(clearAuth());
      localStorage.removeItem("token");
    }

    const interval = setInterval(() => {
      const cookieToken = getTokenFromCookie();
      if (!cookieToken) {
        dispatch(clearAuth());
        localStorage.removeItem("token");
        return;
      }
      validateToken(cookieToken, dispatch, user);
    }, 1000); // Check every 1 second

    return () => clearInterval(interval);
  }, [dispatch, token, user]);
};

const ProtectedRoute = ({ children }) => {
  const { token, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useTokenValidator(dispatch, token, user);

  useEffect(() => {
    if (token) {
      localStorage.setItem("lastVisitedPage", location.pathname);
    }
  }, [token, location.pathname]);

  useEffect(() => {
    const lastVisitedPage = localStorage.getItem("lastVisitedPage");
    if (token && lastVisitedPage && location.pathname === "/") {
      navigate(lastVisitedPage);
    }
  }, [token, location.pathname, navigate]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  return localStorage.getItem("token") ? children : null;
};

export default ProtectedRoute;
