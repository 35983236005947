import React, { useState } from "react";
import { Drawer, Divider, Alert } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  useGetProjectsQuery,
  usePostCommentToProjectMutation,
} from "../redux/api/projectsApi";
import { useSelector } from "react-redux";
import { useGetAllUsersQuery } from "../redux/api/authApi";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { useCreateNotificationMutation } from "../redux/api/notificationApi";
dayjs.extend(relativeTime);

const CommentsProjectCard = ({ comments, projectId }) => {
  const { data: projectsData, refetch } = useGetProjectsQuery();
  const { data: usersData } = useGetAllUsersQuery();
  const [postCommentToProject, { error: postCommentError }] =
    usePostCommentToProjectMutation();
  const userData = useSelector((state) => state.user.user);
  const [postNotification] = useCreateNotificationMutation();

  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
    setIsEditing(false);
  };

  const getUserDetails = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user
      ? { name: user.name, avatar: user.avatar }
      : { name: "Unknown User", avatar: "/path/to/default/avatar.png" };
  };

  const handleInputClick = () => {
    setIsEditing(true);
  };

  const handlePublish = async () => {
    if (!text.trim()) return;

    if (!userData || !userData._id) {
      console.error("User data is not available.");
      return;
    }

    const newComment = {
      user: userData._id,
      comment: text,
      projectId: projectId,
      createdAt: new Date().toISOString(),
    };

    try {
      await postCommentToProject(newComment).unwrap();
      await Promise.all(
        projectDetails.assignedTo.map(async (userId) => {
          const notification = {
            message:
              "commented on the project - " +
              `${projectDetails.nume}` +
              " - that you are assigned to",
            toPage: "/taskslist/My-Tasks",
            senderId: userData._id,
            receiverId: userId,
          };

          return postNotification(notification).unwrap();
        })
      );
      setText(""); // Clear the input field after publishing
      refetch(); // Manually refetch the data
      setIsEditing(false); // Close the editor after publishing
    } catch (error) {
      console.error("Failed to add comment: ", error);
    }
  };

  const getUsername = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user ? user.name : "Unknown User";
  };

  // Find the project by projectId from projectsData
  const projectDetails = projectsData?.projects?.find(
    (project) => project._id === projectId
  );
  const commentsToDisplay = projectDetails?.comentarii || comments;

  return (
    <div>
      <svg
        className="mr-[5px] cursor-pointer "
        onClick={toggleDrawer(true)}
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="ic:baseline-comment">
          <path
            className="fill-current dark:text-white"
            id="Vector"
            d="M15.5766 2.83332C15.5766 2.05416 14.9462 1.41666 14.167 1.41666H2.83366C2.05449 1.41666 1.41699 2.05416 1.41699 2.83332V11.3333C1.41699 12.1125 2.05449 12.75 2.83366 12.75H12.7503L15.5837 15.5833L15.5766 2.83332ZM12.7503 9.91666H4.25033V8.49999H12.7503V9.91666ZM12.7503 7.79166H4.25033V6.37499H12.7503V7.79166ZM12.7503 5.66666H4.25033V4.24999H12.7503V5.66666Z"
            fill="black"
          />
        </g>
      </svg>

      <Drawer
        PaperProps={{
          sx: {
            borderRadius: "20px 0px 0px 20px",
            backgroundColor: "#8684CC",
            color: "#333",
            width: 600,
          },
          className:
            "bg-[#8684CC] dark:bg-[#2B2A49] text-black dark:text-white",
        }}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <div className="h-full px-7 py-[25px] flex-col justify-start items-start gap-2.5 inline-flex ">
          <div className="h-[37px] flex items-end w-full justify-end">
            <svg
              className="flex cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              onClick={toggleDrawer(false)}
            >
              <path
                d="M29.7243 28.0879C29.8317 28.1953 29.9169 28.3228 29.9751 28.4632C30.0332 28.6036 30.0631 28.754 30.0631 28.9059C30.0631 29.0578 30.0332 29.2083 29.9751 29.3486C29.9169 29.489 29.8317 29.6165 29.7243 29.724C29.6168 29.8314 29.4893 29.9166 29.3489 29.9747C29.2086 30.0329 29.0581 30.0628 28.9062 30.0628C28.7543 30.0628 28.6039 30.0329 28.4635 29.9747C28.3231 29.9166 28.1956 29.8314 28.0882 29.724L18.5 20.1343L8.91177 29.724C8.69481 29.9409 8.40055 30.0628 8.09372 30.0628C7.78689 30.0628 7.49263 29.9409 7.27567 29.724C7.05872 29.507 6.93683 29.2127 6.93683 28.9059C6.93683 28.5991 7.05872 28.3048 7.27567 28.0879L16.8653 18.4997L7.27567 8.91146C7.05872 8.6945 6.93683 8.40024 6.93683 8.09342C6.93683 7.78659 7.05872 7.49233 7.27567 7.27537C7.49263 7.05841 7.78689 6.93652 8.09372 6.93652C8.40055 6.93652 8.69481 7.05841 8.91177 7.27537L18.5 16.865L28.0882 7.27537C28.3051 7.05841 28.5994 6.93652 28.9062 6.93652C29.2131 6.93652 29.5073 7.05841 29.7243 7.27537C29.9412 7.49233 30.0631 7.78659 30.0631 8.09342C30.0631 8.40024 29.9412 8.6945 29.7243 8.91146L20.1346 18.4997L29.7243 28.0879Z"
                fill="#DADADA"
              />
            </svg>
          </div>
          <div className="flex-col justify-start items-start gap-[33px] flex">
            <div className="flex-col justify-start items-start gap-5 flex">
              <div className="justify-start items-start gap-[51px] inline-flex">
                <div className="text-white text-[18px] font-normal  ">
                  Comments
                </div>
              </div>
            </div>
            <Divider
              sx={{
                width: "100%",
                background:
                  "linear-gradient(to left, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1))",
                height: "2px",
              }}
            />
            {postCommentError && (
              <Alert severity="error" onClose={() => {}}>
                {typeof postCommentError.data === "object"
                  ? JSON.stringify(postCommentError.data)
                  : postCommentError.data}
              </Alert>
            )}
            <div className="w-[538px]">
              {isEditing ? (
                <div className="">
                  <ReactQuill value={text} onChange={setText} />
                  <div className="flex justify-end w-inherit ">
                    <div
                      className="w-[94px] h-9 px-[21px] py-[9px] bg-[#EDEDFB] rounded border border-neutral-200 justify-center items-center gap-2.5 inline-flex mt-[34px] cursor-pointer"
                      onClick={handlePublish}
                    >
                      <div className="text-zinc-500 text-[15px] dark:text-[#383757] r font-normal  ">
                        Publish
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="px-[25px] py-[17px] bg-[#D9D8FFC2] rounded-lg cursor-pointer dark:bg-[#4D4D77]"
                  onClick={handleInputClick}
                >
                  <div className="text-white text-lg font-normal dark:bg-[#4D4D77] ">
                    Write a comment...
                  </div>
                </div>
              )}
              <div className="mt-4">
                {commentsToDisplay?.map((comment, index) => {
                  const userDetails = getUserDetails(comment.user);
                  return (
                    <div key={index}>
                      <div className="relative py-[26px] px-[15px] mt-1 bg-[#C5C4F3] dark:bg-[#4D4D77] rounded-lg flex flex-col">
                        <div className="flex flex-row justify-between">
                          <div className="text-[#4A488E] text-sm flex flex-row items-center gap-[11px] mb-[21px]">
                            <div
                              className="w-[46px] h-[46px] rounded-full mr-[17px]"
                              style={{
                                backgroundImage: `url(${
                                  userDetails?.avatar?.url ||
                                  "/images/avatar.svg"
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            >
                              {/*     <img
                    src={user?.avatar?.url}
                    alt=""
                    className="w-full h-full  rounded-full"
                  /> */}
                            </div>
                            <span className="text-[#4A488E]text-[15px] font-normal  dark:text-[#EDEDFB]">
                              {userDetails.name}
                            </span>
                          </div>
                          <div className=" justify-start items-start gap-3 inline-flex">
                            <div className="text-[#4A488E] text-sm font-normal dark:text-[#EDEDFB] ">
                              {/* {new Date(comment.createdAt).toLocaleDateString()} */}
                              {dayjs(comment.createdAt).fromNow()}
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M11.3333 8.50065C11.3333 8.14703 11.4738 7.80789 11.7239 7.55784C11.9739 7.30779 12.313 7.16732 12.6667 7.16732C13.0203 7.16732 13.3594 7.30779 13.6095 7.55784C13.8595 7.80789 14 8.14703 14 8.50065C14 8.85427 13.8595 9.19341 13.6095 9.44346C13.3594 9.69351 13.0203 9.83398 12.6667 9.83398C12.313 9.83398 11.9739 9.69351 11.7239 9.44346C11.4738 9.19341 11.3333 8.85427 11.3333 8.50065ZM6.66667 8.50065C6.66667 8.14703 6.80714 7.80789 7.05719 7.55784C7.30724 7.30779 7.64638 7.16732 8 7.16732C8.35362 7.16732 8.69276 7.30779 8.94281 7.55784C9.19286 7.80789 9.33333 8.14703 9.33333 8.50065C9.33333 8.85427 9.19286 9.19341 8.94281 9.44346C8.69276 9.69351 8.35362 9.83398 8 9.83398C7.64638 9.83398 7.30724 9.69351 7.05719 9.44346C6.80714 9.19341 6.66667 8.85427 6.66667 8.50065ZM2 8.50065C2 8.14703 2.14048 7.80789 2.39052 7.55784C2.64057 7.30779 2.97971 7.16732 3.33333 7.16732C3.68696 7.16732 4.02609 7.30779 4.27614 7.55784C4.52619 7.80789 4.66667 8.14703 4.66667 8.50065C4.66667 8.85427 4.52619 9.19341 4.27614 9.44346C4.02609 9.69351 3.68696 9.83398 3.33333 9.83398C2.97971 9.83398 2.64057 9.69351 2.39052 9.44346C2.14048 9.19341 2 8.85427 2 8.50065Z"
                                fill="#B5B5B5"
                              />
                            </svg>
                          </div>
                        </div>
                        <span
                          className="text-[#4A488E] text-[15px] font-normal dark:text-[#EDEDFB] "
                          dangerouslySetInnerHTML={{ __html: comment.comment }}
                        />
                        <div className="absolute left-[-50px] top-1/2 transform -translate-y-1/2 -z-40">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="22"
                            viewBox="0 0 19 22"
                            fill="none"
                          >
                            <path
                              d="M-5.24537e-07 11L18.75 0.607696L18.75 21.3923L-5.24537e-07 11Z"
                              fill="#D9D9D9"
                            />
                          </svg>{" "}
                        </div>
                      </div>
                      <div className="text-[#4A488E] text-[15px] font-normal   mt-[12px] flex justify-end">
                        Reply
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default CommentsProjectCard;
