import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import Drawer from "../../Notification";
import { useGetAllUsersQuery } from "../../../redux/api/authApi";
import {
  useUpdateUserMutation,
  useUploadContractMutation,
} from "../../../redux/api/userApi";
import dayjs from "dayjs";
import { notify } from "../../utils/notificationManager";

export const EmployeeSetting = () => {
  const [openRight, setOpenRight] = useState(false);
  const [currentEmployee, setCurrentEmployee] = useState({
    name: "",
    email: "",
    jobDescription: "",
    phone: "",
    city: "",
    address: "",
    postalCode: "",
    department: "",
    job: "",
    role: "",
    DataStart: "",
    contracte: [], // Initialize as an array
    certificate: "",
    seniority: "",
    birthday: "",
  });
  const [contractFile, setContractFile] = useState(null);

  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
    refetch,
  } = useGetAllUsersQuery();
  React.useEffect(() => {
    if (usersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetch();
      }, 5000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [usersData, refetch]);
  const [updateUser] = useUpdateUserMutation();
  const [uploadContract] = useUploadContractMutation();

  const { category } = useParams();

  useEffect(() => {
    setCurrentEmployee(
      usersData?.users.find((u) => u._id === category) || currentEmployee
    );
  }, [usersData, category]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentEmployee((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setContractFile(e.target.files[0]);
  };

  const handleSave = async () => {
    notify(
      "Are you sure you want to save?",
      1,
      async () => {
        // Go ahead callback
        let contractUrls = [...currentEmployee.contracte]; // Clone the existing contract URLs array

        if (contractFile) {
          const formData = new FormData();
          formData.append("file", contractFile);

          const res = await uploadContract(formData);
          if (!res.data || !res.data.links || res.data.links.length === 0) {
            console.error("File upload failed");
            return;
          }

          contractUrls.push(res.data.links[0]); // Add the new link to the contractUrls array
        }

        const employeeData = {
          ...currentEmployee,
          DataStart: new Date(currentEmployee.DataStart),
          birthday: new Date(currentEmployee.birthday),
          contracte: contractUrls, // Update the contracte field with the new array
        };

        try {
          await updateUser({ id: currentEmployee._id, body: employeeData });
          notify("User updated successfully!", 0, null, null, true); // Replaced toast.success
        } catch (error) {
          console.error("Failed to update user", error);
          notify("Failed to update user", 2, null, null, true); // Replaced toast.error
        }
      },
      () => {
        // Cancel callback
        notify("Save cancelled", 2, null, null, true); // Notify cancellation
      }
    );
  };

  return (
    <div className="pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]">
      <div className="w-full px-[40px] md:mb-40">
        <div className=" p-4 flex ">
          <ul className="flex items-center ">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-[500px] text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/AdminPanel/HR-Details`}
              >
                Admin Panel / Employees' settings /{" "}
                <b>{currentEmployee?.name}</b>
              </Link>
            </Breadcrumbs>
          </ul>
          <div className="flex w-full justify-end gap-[10px]">
            <button
              className="bg-[#E8E8FF] dark:bg-[#3A3A68] p-[13px] rounded-[9px]"
              onClick={() => setOpenRight(!openRight)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="ion:notifications">
                  <path
                    className="fill-current dark:text-[#EDEDFB]"
                    id="Vector"
                    d="M17.1902 13.3324C17.1254 13.2543 17.0617 13.1762 16.9992 13.1008C16.1398 12.0613 15.6199 11.434 15.6199 8.49141C15.6199 6.96797 15.2554 5.71797 14.5371 4.78047C14.0074 4.08789 13.2914 3.5625 12.3476 3.17422C12.3355 3.16746 12.3246 3.1586 12.3156 3.14805C11.9761 2.01133 11.0472 1.25 9.99959 1.25C8.95193 1.25 8.02342 2.01133 7.68396 3.14687C7.67492 3.15706 7.66422 3.16564 7.65232 3.17227C5.44998 4.07891 4.37967 5.81836 4.37967 8.49023C4.37967 11.434 3.86052 12.0613 3.00037 13.0996C2.93787 13.175 2.8742 13.2516 2.80935 13.3313C2.64185 13.5333 2.53573 13.779 2.50354 14.0394C2.47135 14.2999 2.51444 14.5641 2.62771 14.8008C2.86873 15.3086 3.3824 15.6238 3.96873 15.6238H16.0347C16.6183 15.6238 17.1285 15.309 17.3703 14.8035C17.4841 14.5668 17.5276 14.3023 17.4956 14.0416C17.4637 13.7809 17.3577 13.5348 17.1902 13.3324ZM9.99959 18.75C10.5641 18.7495 11.1179 18.5963 11.6023 18.3066C12.0867 18.0168 12.4837 17.6014 12.7512 17.1043C12.7637 17.0805 12.77 17.0538 12.7692 17.0269C12.7685 16.9999 12.7607 16.9736 12.7468 16.9506C12.7329 16.9275 12.7133 16.9084 12.6898 16.8952C12.6663 16.8819 12.6398 16.875 12.6129 16.875H7.38709C7.36011 16.8749 7.33356 16.8818 7.31003 16.895C7.2865 16.9082 7.2668 16.9273 7.25284 16.9504C7.23887 16.9735 7.23113 16.9998 7.23035 17.0268C7.22958 17.0537 7.2358 17.0804 7.24842 17.1043C7.51581 17.6013 7.91273 18.0167 8.39709 18.3065C8.88145 18.5962 9.43519 18.7495 9.99959 18.75Z"
                    fill="#4A488E"
                  />
                </g>
              </svg>
            </button>
            <button className="bg-[#E8E8FF] dark:bg-[#3A3A68] p-[13px] rounded-[9px]">
              <Link to="/AdminPanel">
                <svg
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current dark:text-[#EDEDFB]"
                    d="M6.5013 10.2917V16.625H0.167969C0.167969 14.9453 0.835229 13.3344 2.02296 12.1467C3.21069 10.9589 4.8216 10.2917 6.5013 10.2917ZM6.5013 9.5C3.87693 9.5 1.7513 7.37438 1.7513 4.75C1.7513 2.12562 3.87693 0 6.5013 0C9.12568 0 11.2513 2.12562 11.2513 4.75C11.2513 7.37438 9.12568 9.5 6.5013 9.5ZM13.6263 12.6667H14.418V16.625H8.08464V12.6667H8.8763V11.875C8.8763 11.2451 9.12652 10.641 9.57192 10.1956C10.0173 9.75022 10.6214 9.5 11.2513 9.5C11.8812 9.5 12.4853 9.75022 12.9307 10.1956C13.3761 10.641 13.6263 11.2451 13.6263 11.875V12.6667ZM12.043 12.6667V11.875C12.043 11.665 11.9596 11.4637 11.8111 11.3152C11.6626 11.1667 11.4613 11.0833 11.2513 11.0833C11.0413 11.0833 10.84 11.1667 10.6915 11.3152C10.543 11.4637 10.4596 11.665 10.4596 11.875V12.6667H12.043Z"
                    fill="#4A488E"
                  />
                </svg>
              </Link>
            </button>
            <Drawer open={openRight} side="right" setOpen={setOpenRight} />
          </div>
        </div>
        <p className="text-[30px] dark:text-[#EDEDFB] text-[#555] mt-[39px] mb-[34px] ml-[20px]">
          {currentEmployee?.name}
        </p>
        <div className="flex flex-col gap-[20px] ml-[20px] mb-[40px]">
          <div className="flex flex-row justify-between w-[400px] items-center">
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              Start date
            </div>
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              <input
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none rounded-[3px] w-[250px] h-[48px]"
                type="date"
                id="DataStart"
                name="DataStart"
                value={dayjs(currentEmployee?.DataStart).format("YYYY-MM-DD")}
                min="2018-01-01"
                max="2050-12-31"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between w-[400px] items-center">
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              Job title
            </div>
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              <input
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none rounded-[3px] w-[250px] h-[48px]"
                type="text"
                name="job"
                value={currentEmployee?.job}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between w-[400px] items-center">
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              Seniority
            </div>
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              <select
                id="seniority"
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none rounded-[3px] w-[250px] h-[48px]"
                name="seniority"
                value={currentEmployee?.seniority} // Make sure this matches one of the options
                onChange={handleChange}
              >
                <option value="Junior">Junior</option>
                <option value="Middle">Middle</option>
                <option value="Senior">Senior</option>
              </select>
            </div>
          </div>
          <div className="flex flex-row justify-between w-[400px] items-center">
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              Email address
            </div>
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              <input
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none rounded-[3px] w-[250px] h-[48px]"
                type="text"
                name="email"
                value={currentEmployee?.email}
                onChange={handleChange}
              />
            </div>{" "}
          </div>

          <div className="flex flex-row justify-between w-[400px] items-center">
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal   flex items-center">
              Address
            </div>
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              <input
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] border-none rounded-[3px] w-[250px] h-[48px]"
                type="text"
                name="address"
                value={currentEmployee?.address}
                onChange={handleChange}
              />
            </div>{" "}
          </div>

          <div className="flex flex-row justify-between w-[400px] items-center">
            <div className="text-zinc-500  dark:text-[#EDEDFB] text-lg font-normal   flex items-center">
              Birthday
            </div>
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                <input
                  className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none rounded-[3px] w-[250px] h-[48px]"
                  type="date"
                  id="birthday"
                  name="birthday"
                  value={dayjs(currentEmployee?.birthday).format("YYYY-MM-DD")}
                  min="1930-01-01"
                  max="2050-12-31"
                  onChange={handleChange}
                />
              </div>{" "}
            </div>
          </div>
          <div className="flex flex-row justify-between w-[400px] items-center">
            <div className="text-zinc-500  dark:text-[#EDEDFB] text-lg font-normal   flex items-center">
              Vacation Days
            </div>
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
                <input
                  className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none rounded-[3px] w-[250px] h-[48px]"
                  type="number"
                  id="vacationDays"
                  name="vacationDays"
                  value={currentEmployee?.vacationDays}
                  onChange={handleChange}
                />
              </div>{" "}
            </div>
          </div>
          <div className="flex flex-row justify-between w-[225px] items-center">
            <div className="flex flex-row justify-between w-[225px] items-center">
              <div className="text-zinc-500 dark:text-[#EDEDFB] dark:text-[#EDEDFB]text-lg font-normal  ">
                Contract
              </div>
              <label
                htmlFor="fileInput"
                className="w-[73px] h-[73px] p-[21px] items-center bg-[#E9E9FF] dark:bg-[#3A3A68] rounded-[3px] cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                >
                  <path
                    className="fill-current dark:text-[#EDEDFB]"
                    d="M14.2096 20.666V10.1389L10.8513 13.4973L9.04297 11.6243L15.5013 5.16602L21.9596 11.6243L20.1513 13.4973L16.793 10.1389V20.666H14.2096ZM7.7513 25.8327C7.04089 25.8327 6.43294 25.5799 5.92747 25.0745C5.422 24.569 5.16883 23.9606 5.16797 23.2493V19.3743H7.7513V23.2493H23.2513V19.3743H25.8346V23.2493C25.8346 23.9598 25.5819 24.5681 25.0764 25.0745C24.571 25.5808 23.9626 25.8335 23.2513 25.8327H7.7513Z"
                    fill="#8D8BDB"
                  />
                </svg>
              </label>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                className="hidden"
              />
            </div>
          </div>
          {contractFile && (
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal mt-2">
              Selected file: {contractFile.name}
            </div>
          )}
          <div className="mt-4">
            <div className="text-zinc-500 dark:text-[#EDEDFB] text-lg font-normal  ">
              Uploaded Contracts:
            </div>
            <ul>
              {currentEmployee?.contracte.map((url, index) => (
                <li
                  key={index}
                  className="text-zinc-500 dark:text-[#EDEDFB] text-md font-normal  "
                >
                  &bull;{" "}
                  <a
                    href={url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text-[#8D8BDB] text-sm font-normal mt-2">
                      Download {index + 1}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <button
            className="w-[396px] h-[54px] px-[138px] py-4 text-white text-lg font-normal bg-[#7977C1] rounded-[3px] justify-center items-center gap-2.5 inline-flex"
            onClick={handleSave}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};
