import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../config";
import Cookies from "js-cookie"; // Import js-cookie


const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const tasksApi = createApi({
  reducerPath: "tasksApi",
  baseQuery,
  tagTypes: ["Tickets", "Comment", "TaskDetails"],
  endpoints: (builder) => ({
    getTasks: builder.query({
      providesTags: ["Tickets", "Comment", "TaskDetails"],
      query: () => ({
        url: "/tickets",
        method: "GET",
      }),
    }),
    getTaskDetails: builder.query({
      query: (taskId) => ({
        url: `/tickets/${taskId}`,
        method: "GET",
      }),
    }),
    getPaginatedTickets: builder.query({
      query: ({ page = 1, limit = 30, sortBy = "createdAt", order = "desc" }) => ({
        url: `/tickets-paginated`,
        method: "GET",
        params: { page, limit, sortBy, order },
      }),
      providesTags: ["Tickets"],
    }),
    updateTask: builder.mutation({
      query: ({ id, body }) => ({
        url: `/tickets/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tickets"],
    }),
    updateTaskStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/tickets/${id}`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: ["Tickets"],
    }),
    postTask: builder.mutation({
      query: (task) => ({
        url: "/tickets",
        method: "POST",
        body: task,
      }),
      invalidatesTags: ["Tickets"],
    }),
    deleteTask: builder.mutation({
      query: (id) => ({
        url: `/tickets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tickets"],
    }),
    addTimeToTask: builder.mutation({
      query: ({ id, userId, seconds }) => ({
        url: `/tickets/add-time/${id}`,
        method: "PUT",
        body: { userId, seconds },
      }),
      invalidatesTags: ["Tickets"],
    }),
    deleteTimeFromTask: builder.mutation({
      query: ({ ticketId, timeId }) => ({
        url: `/tickets/${ticketId}/times/${timeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tickets"],
    }),
    updateTimeInTask: builder.mutation({
      query: ({ ticketId, timeId, seconds }) => ({
        url: `/tickets/${ticketId}/times/${timeId}`,
        method: "PUT",
        body: { seconds },
      }),
      invalidatesTags: ["Tickets"],
    }),
    postComment: builder.mutation({
      query: (comment) => ({
        url: `/tickets/${comment.ticketId}/comments`,
        method: "POST",
        body: comment,
      }),
      invalidatesTags: ["Tickets"],
    }),
    getUserTasksByDateInterval: builder.query({
      query: ({ userId, startdate, enddate }) => ({
        url: `/tickets/user/${userId}`,
        method: "GET",
        params: { startdate, enddate },
      }),
      providesTags: ["Tickets"],
    }),
    // New API for startTime
    startTime: builder.mutation({
      query: ({ ticketId, userId }) => ({
        url: `/tickets/start-time/${ticketId}`,
        method: "POST",
        body: { userId },
      }),
      invalidatesTags: ["Tickets"],
    }),
    // New API for stopTime
    stopTime: builder.mutation({
      query: ({ ticketId, userId }) => ({
        url: `/tickets/stop-time/${ticketId}`,
        method: "PUT",
        body: { userId },
      }),
      invalidatesTags: ["Tickets"],
    }),
    // New API for getTimeForTask
    getTimeForTask: builder.query({
      query: ({ ticketId, userId }) => ({
        url: `/tickets/getTime/${ticketId}/${userId}`,
        method: "GET",
      }),
      providesTags: ["Tickets"],
    }),
    setTicketPending: builder.mutation({
      query: ({ ticketId, isPending }) => ({
        url: `/tickets/pending/${ticketId}`,
        method: "PUT",
        body: { isPending },
      }),
      invalidatesTags: ["Tickets"],
    }),
    setUserFinishedWithTask: builder.mutation({
      query: ({ ticketId, userId, isFinalized }) => ({
        url: `/tickets/${ticketId}/${userId}/done`,
        method: "PUT",
        body: { isFinalized },
      }),
      invalidatesTags: ["Tickets"],
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTaskDetailsQuery,
  useUpdateTaskMutation,
  usePostTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskStatusMutation,
  useAddTimeToTaskMutation,
  useDeleteTimeFromTaskMutation,
  useUpdateTimeInTaskMutation,
  usePostCommentMutation,
  useGetUserTasksByDateIntervalQuery,
  useStartTimeMutation, // New hook for startTime
  useStopTimeMutation, // New hook for stopTime
  useGetTimeForTaskQuery, // New hook for getTimeForTask
  useSetTicketPendingMutation,
  useSetUserFinishedWithTaskMutation,
  useGetPaginatedTicketsQuery,
} = tasksApi;
