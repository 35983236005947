import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from '../../config';  // Your backend URL configuration
import Cookies from "js-cookie";  // For handling authentication tokens

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const appraisalsApi = createApi({
  reducerPath: "appraisalsApi",
  baseQuery,
  endpoints: (builder) => ({
    // Check if appraisal exists in the given period and seniority
// Check if appraisal exists in the given period, department, and seniority
checkExistingAppraisal: builder.query({
  query: ({ startDate, endDate, seniority, department }) => {
    const params = new URLSearchParams();
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("seniority", seniority);
    if (department) {
      params.append("department", department); // Add the department parameter
    }
    return {
      url: `/check-existing-appraisal?${params.toString()}`,
      method: "GET",
    };
  },
}),

getAllAppraisals: builder.query({
  query: ({ startDate, endDate }) => {
    const params = new URLSearchParams();
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);

    return {
      url: `/appraisals/all?${params.toString()}`,
      method: "GET",
    };
  },
}),

// Fetch appraisals for the user's department, seniority, startDate, and endDate
getAppraisals: builder.query({
  query({ department, seniority, startDate, endDate } = {}) {
    let url = "/appraisals";
    const params = new URLSearchParams();
    if (department) params.append("department", department);
    if (seniority) params.append("seniority", seniority);
    if (startDate) params.append("startDate", startDate); // Append startDate
    if (endDate) params.append("endDate", endDate); // Append endDate

    if (params.toString()) url += `?${params.toString()}`;
    
    return {
      url,
      method: "GET",
    };
  },
}),

    // Fetch the period status (first or second half)
    checkPeriodHalf: builder.query({
      query(id) {
        return {
          url: `/appraisals/${id}/period-half`,
          method: "GET",
        };
      },
    }),

    // Admin: Create a new appraisal
    postAppraisal: builder.mutation({
      query(appraisal) {
        return {
          url: "/appraisals",
          method: "POST",
          body: appraisal,
        };
      },
    }),

    // Admin: Update department expectations in an appraisal
    updateDepartmentExpectations: builder.mutation({
      query({ id, department, expectations }) {
        return {
          url: `/appraisals/${id}/department`,
          method: "PUT",
          body: { department, expectations },
        };
      },
    }),

    submitAdminFeedback: builder.mutation({
      query({ id, department, userId, feedback, half, category }) {
        return {
          url: `/appraisals/${id}/feedback`,
          method: 'POST',
          body: { department, userId, feedback, half, category }, // half and category included
        };
      },
    }),
    

    // User: Submit a response to an appraisal's department expectations
    submitUserResponse: builder.mutation({
      query({ id, department, responseType, responseText, half}) {
        return {
          url: `/appraisals/${id}/response`,
          method: "POST",
          body: { department, responseType, responseText, half },
        };
      },
    }),

    deleteUserResponse: builder.mutation({
      query: ({ id, department, userId, category, half }) => ({
        url: `/appraisals/${id}/response`,
        method: 'DELETE',
        body: { department, userId, category, half },
      }),
    }),
    
    

    // Admin: Delete an appraisal
    deleteAppraisal: builder.mutation({
      query(id) {
        return {
          url: `/appraisals/${id}`,
          method: "DELETE",
        };
      },
    }),

    // Fetch personal goals for a specific user
    getPersonalGoalsByUser: builder.query({
      query(userId) {
        return {
          url: `/appraisals/personal-goals/user/${userId}`,
          method: "GET",
        };
      },
    }),

    // Fetch personal goals for a specific user within a time range
    getPersonalGoalsByUserAndTimeRange: builder.query({
      query({ userId, startDate, endDate }) {
        const params = new URLSearchParams();
        if (startDate) params.append("startDate", startDate);
        if (endDate) params.append("endDate", endDate);
        return {
          url: `/appraisals/personal-goals/user/${userId}/time-range?${params.toString()}`,
          method: "GET",
        };
      },
    }),

    // Create new personal goals
    postPersonalGoals: builder.mutation({
      query(personalGoals) {
        return {
          url: "/appraisals/personal-goals",
          method: "POST",
          body: personalGoals,
        };
      },
    }),

    // Update existing personal goals
    updatePersonalGoals: builder.mutation({
      query({ id, ...personalGoals }) {
        return {
          url: `/appraisals/personal-goals/${id}`,
          method: "PUT",
          body: personalGoals,
        };
      },
    }),

    deleteAdminFeedback: builder.mutation({
      query: ({ id, department, userId, half, category }) => ({
        url: `/appraisals/${id}/feedback`,
        method: 'DELETE',
        body: { department, userId, half, category },
      }),
    }),
    

    // Delete personal goals by ID
    deletePersonalGoals: builder.mutation({
      query(id) {
        return {
          url: `/appraisals/personal-goals/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAppraisalsQuery,
  useDeleteUserResponseMutation,
  useCheckPeriodHalfQuery,
  usePostAppraisalMutation,
  useUpdateDepartmentExpectationsMutation,
  useSubmitAdminFeedbackMutation,
  useSubmitUserResponseMutation,
  useDeleteAppraisalMutation,
  useGetPersonalGoalsByUserQuery,
  useGetPersonalGoalsByUserAndTimeRangeQuery,
  usePostPersonalGoalsMutation,
  useUpdatePersonalGoalsMutation,
  useDeletePersonalGoalsMutation,
  useCheckExistingAppraisalQuery,
  useGetAllAppraisalsQuery,
  useDeleteAdminFeedbackMutation
} = appraisalsApi;
