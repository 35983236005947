import React, { useState, useEffect } from "react";
import { Breadcrumbs, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { IoFilter } from "react-icons/io5"; // Import the filter icon from react-icons
import Drawer from "../../Notification";
import { useGetMeQuery } from "../../../redux/api/userApi";
import { useGetAllUsersQuery } from "../../../redux/api/authApi";
import { EmployeeSetting } from "./EmployeeSetting";
import NotificationComponent from "../../NotificationComponent";
import { motion } from "framer-motion";

export const HRDetails = () => {
  const [openRight, setOpenRight] = useState(false);
  const [sortOption, setSortOption] = useState("alphabetical");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortedUsers, setSortedUsers] = useState([]);

  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
  } = useGetMeQuery();
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
    refetch,
  } = useGetAllUsersQuery();
  React.useEffect(() => {
    if (usersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetch();
      }, 5000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [usersData, refetch]);
  useEffect(() => {
    if (usersData && usersData.users) {
      sortUsers(usersData.users, sortOption);
    }
  }, [usersData, sortOption]);

  const sortUsers = (users, option) => {
    let sortedUsers;
    if (option === "alphabetical") {
      sortedUsers = [...users].sort((a, b) => a.name.localeCompare(b.name));
    } else if (option === "creationDate") {
      sortedUsers = [...users].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    }
    setSortedUsers(sortedUsers);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setDropdownOpen(false);
  };

  return (
    <div className="pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]">
      <div className="w-full px-[40px] md:mb-40">
        <div className="p-4 flex justify-between">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-fit text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/AdminPanel`}
              >
                Admin Panel
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/AdminPanel/HR-Details`}
              >
                <b>Employees' settings</b>
              </Link>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>
        <p className="text-[30px] text-[#4A488E] dark:text-[#EDEDFB] mt-[39px] mb-[34px] ml-[20px]">
          HR Details
        </p>
        <div className="flex items-center justify-between ml-[20px] mb-[20px]">
          <div className="flex items-center gap-[10px]">
            <Tooltip title="Sort users">
              <button
                className="flex items-center justify-center text-gray-600 dark:text-white"
                onClick={toggleDropdown}
              >
                <IoFilter size={24} />
              </button>
            </Tooltip>
            {dropdownOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute mt-2 w-48 bg-white dark:bg-[#2B2A49] shadow-lg rounded-md z-10"
              >
                <ul>
                  <li
                    className={`p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 ${
                      sortOption === "alphabetical"
                        ? "font-bold text-gray-800 dark:text-white"
                        : ""
                    }`}
                    onClick={() => handleSortChange("alphabetical")}
                  >
                    Alphabetically
                  </li>
                  <li
                    className={`p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 ${
                      sortOption === "creationDate"
                        ? "font-bold text-gray-800 dark:text-white"
                        : ""
                    }`}
                    onClick={() => handleSortChange("creationDate")}
                  >
                    Creation Date
                  </li>
                </ul>
              </motion.div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-[10px] ml-[20px] md:mb-40">
          {sortedUsers.map((user) => (
            <Link to={`/HR/HR-Details/${user._id}`} key={user._id}>
              <motion.div
                className="h-fit relative group"
                whileHover={{ scale: 0.98 }}
                transition={{ duration: 0.2 }}
              >
                <div className="w-[342px] h-[71px] px-[20px] py-[13px] flex flex-row items-center gap-[15px] bg-[#E8E8FF] dark:bg-[#3A3A68] rounded-[5px]">
                  <div
                    className="w-[40px] h-[40px] rounded-full"
                    style={{
                      backgroundImage: `url(${
                        user.avatar?.url || "/images/avatar.svg"
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <div className="text-[18px] font-semibold text-[#4A488E] dark:text-[#EDEDFB]">
                    {user.name}
                  </div>
                </div>
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
