import { Breadcrumbs, Drawer } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetDepartmentsQuery } from "../redux/api/departmentsApi";
import NotificationComponent from "./NotificationComponent";

const RoleManagement = () => {
  const [openRight, setOpenRight] = useState(false);
  const { data } = useGetDepartmentsQuery();

  return (
    <>
      <div className="pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]">
        <div className="w-full px-[40px] md:mb-40">
          <div className="bg-white p-4 flex dark:bg-[#2B2A49] justify-between ">
            <ul className="flex items-center ">
              <li className="inline-flex items-center">
                <Link
                  to="/dashboard"
                  className="text-[#7674C2] hover:text-blue-500 "
                >
                  <svg
                    className="w-5 h-auto fill-current mx-2 text-[#4A488E]"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"
                      className={`fill-current dark:text-[#EDEDFB]`}
                    />
                  </svg>
                </Link>

                <span className="mx-4 h-auto text-[#4A488E] font-medium dark:text-[#EDEDFB]">
                  /
                </span>
              </li>

              <Breadcrumbs
                aria-label="breadcrumb"
                className="w-[400px] text-[#7674C2] dark:text-[#EDEDFB]"
              >
                <Link
                  underline="hover"
                  className="text-[#4A488E] dark:text-[#EDEDFB]"
                  to="/dashboard"
                >
                  Fuse Digital
                </Link>
                <Link
                  className="text-[#4A488E] dark:text-[#EDEDFB]"
                  underline="hover"
                  to={`/adminpanel`}
                >
                  Admin Panel
                </Link>
                <Link
                  className="text-[#4A488E] dark:text-[#EDEDFB]"
                  underline="hover"
                  to={`/AdminPanel/RoleManagement`}
                >
                  <b> Role-Management</b>
                </Link>
              </Breadcrumbs>
            </ul>
            <NotificationComponent />
          </div>
          <p className="text-[30px] text-[#4A488E] mt-[39px] mb-[34px] ml-[20px] dark:text-[#EDEDFB]">
            Permissions
          </p>
          <div className="flex flex-col gap-[10px] ">
            <p className="text-[30px] text-[#4A488E] mt-[39px] mb-[34px] ml-[20px] dark:text-[#EDEDFB]">
              Department
            </p>
            {data &&
              data?.departments.map((department) => (
                <Link
                  to={`/AdminPanel/RoleManagement/${department.departmentName}`}
                >
                  <div className="w-full bg-[#E8E8FF] flex items-center justify-between px-[20px] py-[16px] rounded-[5px] dark:bg-[#3A3A68]">
                    <p className="text-[#4A488E] text-[18px] font-semibold dark:text-[#EDEDFB]">
                      {department.departmentName}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="20"
                      viewBox="0 0 10 20"
                      fill="none"
                    >
                      <path
                        d="M0.623409 20C0.465262 20 0.30342 19.9329 0.182052 19.8025C-0.0606842 19.5419 -0.0606842 19.1154 0.182052 18.8548L8.49025 9.93385L0.303419 1.14325C0.0606824 0.882616 0.0606824 0.456118 0.303419 0.195481C0.546155 -0.0651584 0.94336 -0.0651584 1.1861 0.195481L9.81795 9.45997C10.0607 9.7206 10.0607 10.1471 9.81795 10.4077L1.06841 19.8025C0.943362 19.9368 0.785233 20 0.623409 20Z"
                        fill="#8D8BDB"
                      />
                    </svg>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleManagement;
