import React, { useState, useEffect, useContext } from "react";
import {
  Breadcrumbs,
  Link as MuiLink,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import NotificationComponent from "../../NotificationComponent";
import { ViewTypeContext } from "../../utils/contexts/viewTypeContext";
import TextEditor from "../../utils/TextEditor/TextEditor";
import RenderedContent from "../../utils/TextEditor/RenderedContent";

import {
  useGetAppraisalsQuery,
  useCheckExistingAppraisalQuery,
  usePostAppraisalMutation,
  useUpdateDepartmentExpectationsMutation,
} from "../../../redux/api/appraisailsApi";
import { notify } from "../../utils/notificationManager";

const hardcodedCategories = [
  { name: "SEO", expectations: { first: "", second: "" } },
  { name: "SMM", expectations: { first: "", second: "" } },
  { name: "PPC", expectations: { first: "", second: "" } },
  { name: "Development", expectations: { first: "", second: "" } },
  { name: "Sales", expectations: { first: "", second: "" } },
];

const ranks = ["Junior", "Middle", "Senior"];
const periodOptions = ["This Year", "Next Year"];

export const AppraisalAdmin = () => {
  const [categories] = useState(hardcodedCategories);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [selectedSeniority, setSelectedSeniority] = useState("");
  const [currentPeriodHalf, setCurrentPeriodHalf] = useState("");
  const [editing, setEditing] = useState({ category: null, period: null });
  const [currentContent, setCurrentContent] = useState("");
  const [overrideMessage, setOverrideMessage] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const isMobileView = useContext(ViewTypeContext);

  const seoQuery = useGetAppraisalsQuery(
    {
      department: "SEO",
      seniority: selectedSeniority,
      startDate: startDate,
      endDate: endDate,
    },
    { skip: !selectedSeniority || !startDate || !endDate }
  );

  const smmQuery = useGetAppraisalsQuery(
    {
      department: "SMM",
      seniority: selectedSeniority,
      startDate: startDate,
      endDate: endDate,
    },
    { skip: !selectedSeniority || !startDate || !endDate }
  );

  const ppcQuery = useGetAppraisalsQuery(
    {
      department: "PPC",
      seniority: selectedSeniority,
      startDate: startDate,
      endDate: endDate,
    },
    { skip: !selectedSeniority || !startDate || !endDate }
  );

  const developmentQuery = useGetAppraisalsQuery(
    {
      department: "Development",
      seniority: selectedSeniority,
      startDate: startDate,
      endDate: endDate,
    },
    { skip: !selectedSeniority || !startDate || !endDate }
  );

  const salesQuery = useGetAppraisalsQuery(
    {
      department: "Sales",
      seniority: selectedSeniority,
      startDate: startDate,
      endDate: endDate,
    },
    { skip: !selectedSeniority || !startDate || !endDate }
  );

  const appraisalDataByCategory = {
    SEO: seoQuery.data?.appraisals || [],
    SMM: smmQuery.data?.appraisals || [],
    PPC: ppcQuery.data?.appraisals || [],
    Development: developmentQuery.data?.appraisals || [],
    Sales: salesQuery.data?.appraisals || [],
  };

  const { refetch: refetchExistingAppraisal } = useCheckExistingAppraisalQuery(
    {
      startDate: startDate,
      endDate: endDate,
      seniority: selectedSeniority,
    },
    { skip: !startDate || !selectedSeniority }
  );

  const [updateDepartmentExpectations] =
    useUpdateDepartmentExpectationsMutation();
  const [postAppraisal] = usePostAppraisalMutation();

  useEffect(() => {
    if (startDate && endDate && selectedSeniority) {
      seoQuery.refetch();
      smmQuery.refetch();
      ppcQuery.refetch();
      developmentQuery.refetch();
      salesQuery.refetch();
      setOverrideMessage("");
      refetchExistingAppraisal();
    }
  }, [startDate, endDate, selectedSeniority]);

  useEffect(() => {
    if (startDate) {
      const currentDate = new Date();
      const startYear = new Date(startDate).getFullYear();

      if (currentDate.getFullYear() !== startYear) {
        setCurrentPeriodHalf("first");
      } else {
        const currentMonth = currentDate.getMonth() + 1;
        const periodHalf = currentMonth <= 6 ? "first" : "second";
        setCurrentPeriodHalf(periodHalf);
      }
    }
  }, [startDate]);

  const handlePeriodChange = (event) => {
    const period = event.target.value;
    setSelectedPeriod(period);

    const currentYear = new Date().getFullYear();
    let year;

    if (period === "This Year") {
      year = currentYear;
    } else if (period === "Next Year") {
      year = currentYear + 1;
    }

    const newStartDate = `${year}-01-01`;
    const newEndDate = `${year}-12-31`;

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setOverrideMessage("");
  };

  const handleSeniorityChange = (event) => {
    setSelectedSeniority(event.target.value);
    setOverrideMessage("");
  };

  const handleExpectationChange = (appraisalId, category, period, newText) => {
    updateDepartmentExpectations({
      id: appraisalId,
      department: category.name,
      expectations: { [period]: newText },
    })
      .unwrap()
      .then(() => {
        notify("Expectations updated!", 0, null, null, true);

        seoQuery.refetch();
        smmQuery.refetch();
        ppcQuery.refetch();
        developmentQuery.refetch();
        salesQuery.refetch();
      });
  };

  const handleEditClick = (category, period, content) => {
    setEditing({ category, period });
    setCurrentContent(content || "");
  };

  const handleCancel = () => {
    setEditing({ category: null, period: null });
    setCurrentContent("");
  };

  const handleDelete = (appraisalId, category, period) => {
    handleExpectationChange(appraisalId, category, period, "");
  };

  const handleUpload = (appraisalId, category, period) => {
    if (appraisalId < 0) {
      handleCreateAppraisal(category, period, currentContent);
    } else if (appraisalId) {
      handleExpectationChange(appraisalId, category, period, currentContent);
    }
    setEditing({ category: null, period: null });
    setCurrentContent("");
  };

  const handleCreateAppraisal = (category, period, content) => {
    const newAppraisal = {
      startDate: startDate,
      endDate: endDate,
      seniority: selectedSeniority,
      departments: {
        [category.name]: { expectations: { [period]: content } },
      },
    };

    postAppraisal(newAppraisal)
      .unwrap()
      .then(() => {
        seoQuery.refetch();
        smmQuery.refetch();
        ppcQuery.refetch();
        developmentQuery.refetch();
        salesQuery.refetch();
      });
  };

  const renderAccordions = (category) => {
    const appraisalData = appraisalDataByCategory[category.name];
    const appraisal = appraisalData.find(
      (app) => app.departments[category.name]
    );
    const appraisalId = appraisal ? appraisal._id : -1;
    const expectations = appraisal
      ? appraisal.departments[category.name].expectations
      : {};

    const isFirstHalfEmpty =
      !expectations.first || expectations.first === "<p><br></p>";
    const isSecondHalfEditable = currentPeriodHalf === "second";

    return (
      <>
        <Accordion
          sx={{ backgroundColor: "#FFF" }}
          className="dark:bg-[#3A3A68]"
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="dark:text-white text-[#3A3A68]">
              Expectations for First Half
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {currentPeriodHalf === "second" ? (
              <RenderedContent content={expectations.first || "No content"} />
            ) : isFirstHalfEmpty ||
              (editing.category === category.name &&
                editing.period === "first") ? (
              <>
                <TextEditor
                  initialValue={currentContent}
                  onChange={setCurrentContent}
                />
                <div className="flex justify-end items-center space-x-4 mt-4">
                  <div
                    onClick={handleCancel}
                    className="text-gray-500 dark:text-white/60 text-xl font-normal cursor-pointer"
                  >
                    Discard
                  </div>
                  <div
                    onClick={() => handleUpload(appraisalId, category, "first")}
                    className="h-12 px-[22px] py-3 bg-[#7977c1] rounded-md cursor-pointer"
                  >
                    <div className="dark:text-white text-[#7977c1] text-xl font-normal">
                      Upload
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <RenderedContent content={expectations.first || "No content"} />
                <div className="flex justify-end items-center space-x-4 mt-4">
                  <div
                    onClick={() =>
                      handleEditClick(
                        category.name,
                        "first",
                        expectations.first || ""
                      )
                    }
                    className="h-12 px-[22px] py-3 bg-[#7977c1] rounded-md cursor-pointer"
                  >
                    <div className="dark:text-white text-[#7977c1] text-xl font-normal">
                      Edit
                    </div>
                  </div>
                  <div
                    onClick={() => handleDelete(appraisalId, category, "first")}
                    className="text-gray-500 dark:text-white/60 text-xl font-normal cursor-pointer"
                  >
                    Delete
                  </div>
                </div>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        {isSecondHalfEditable && (
          <Accordion
            style={{ marginTop: "10px" }}
            className="dark:bg-[#3A3A68]"
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="dark:text-white text-[#3A3A68]">
                Expectations for Second Half
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {!expectations.second ||
              (editing.category === category.name &&
                editing.period === "second") ? (
                <>
                  <TextEditor
                    initialValue={currentContent}
                    onChange={setCurrentContent}
                  />
                  <div className="flex justify-end items-center space-x-4 mt-4">
                    <div
                      onClick={handleCancel}
                      className="text-gray-500 dark:text-white/60 text-xl font-normal cursor-pointer"
                    >
                      Discard
                    </div>
                    <div
                      onClick={() =>
                        handleUpload(appraisalId, category, "second")
                      }
                      className="h-12 px-[22px] py-3 bg-[#7977c1] rounded-md cursor-pointer"
                    >
                      <div className="text-white text-xl font-normal">
                        Upload
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <RenderedContent
                    content={expectations.second || "No content"}
                  />
                  <div className="flex justify-end items-center space-x-4 mt-4">
                    <div
                      onClick={() =>
                        handleEditClick(
                          category.name,
                          "second",
                          expectations.second || ""
                        )
                      }
                      className="h-12 px-[22px] py-3 bg-[#7977c1] rounded-md cursor-pointer"
                    >
                      <div className="text-white text-xl font-normal">Edit</div>
                    </div>
                    <div
                      onClick={() =>
                        handleDelete(appraisalId, category, "second")
                      }
                      className="text-gray-500 dark:text-white/60 text-xl font-normal cursor-pointer"
                    >
                      Delete
                    </div>
                  </div>
                </>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  };

  return (
    <div className="pr-14 ml-[-59px] pt-6 h-[100vh] overflow-y-auto bg-[#F6F7FB] dark:bg-[#2B2A49]">
      <div className="w-full px-10">
        <div className="p-4 flex items-center justify-between">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-72 text-[#4A488E]"
              separator={
                <span className="text-[#4A488E] dark:text-[#EDEDFB]">/</span>
              }
            >
              <MuiLink
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                component={Link}
                to="/dashboard"
              >
                Fuse Digital
              </MuiLink>
              <MuiLink
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                component={Link}
                to="/adminpanel"
              >
                Admin Panel
              </MuiLink>
              <MuiLink
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                component={Link}
                to="/HR/AppraisalAdmin"
              >
                <b>Appraisal</b>
              </MuiLink>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>

        <p className="text-3xl text-[#4A488E] dark:text-[#EDEDFB] mt-10 mb-8 ml-5">
          Appraisal
        </p>

        {overrideMessage && (
          <Typography color="error" sx={{ mb: 2 }}>
            {overrideMessage}
          </Typography>
        )}

        <div className="bg-[#FFF] dark:bg-[#3A3A68] p-4 rounded-lg space-y-4 md:mb-40">
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6} sx={{ mb: 4 }}>
              <Typography sx={{ mb: 1 }} className="dark:text-[#EDEDFB]">
                Reflection Period
              </Typography>
              <TextField
                fullWidth
                select
                value={selectedPeriod}
                onChange={handlePeriodChange}
                variant="outlined"
                sx={{
                  height: "36px", // Adjust height if needed
                  ".MuiSelect-select": {
                    padding: "10px 14px", // Adjust padding to match the input style
                  },
                  ".MuiOutlinedInput-root": {
                    backgroundColor: "#FFF", // You might want to set a different color for dark mode
                    "&.Mui-focused": {
                      border: "none",
                    },
                    "& fieldset": {
                      border: "1px solid lightgray",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid lightgray",
                    },
                    "&:hover fieldset": {
                      border: "1px solid lightgray",
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      className:
                        "bg-white dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none",
                    },
                  },
                  className:
                    "bg-white dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none", // Ensure select has the dark background color
                }}
                className="bg-white dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none" // Ensure TextField has the dark background color
              >
                {periodOptions.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    className="bg-white dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none"
                  >
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6} sx={{ mb: 4 }}>
              <Typography sx={{ mb: 1 }} className="dark:text-[#EDEDFB]">
                Seniority
              </Typography>
              <TextField
                fullWidth
                select
                value={selectedSeniority}
                onChange={handleSeniorityChange}
                variant="outlined"
                sx={{
                  height: "36px", // Adjusted to match the height of the datepicker
                  ".MuiSelect-select": {
                    padding: "10px 14px", // Adjust padding to match the input style
                  },
                  ".MuiOutlinedInput-root": {
                    backgroundColor: "#FFF",
                    "&.Mui-focused": {
                      border: "none",
                    },
                    "& fieldset": {
                      border: "1px solid lightgray",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid lightgray",
                    },
                    "&:hover fieldset": {
                      border: "1px solid lightgray",
                    },
                  },
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      className:
                        "bg-white dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none",
                    },
                  },
                  className:
                    "bg-white dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none", // Ensure select has the dark background color
                }}
                className="bg-white  dark:bg-[#2B2A49] dark:text-[#EDEDFB] dark:border-none" // Ensure TextField has the dark background color
              >
                {ranks.map((rank) => (
                  <MenuItem key={rank} value={rank}>
                    {rank}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          {categories.map((category) => (
            <Accordion
              key={category.name}
              sx={{ backgroundColor: "#E8E8FF", boxShadow: "none" }}
              className="dark:bg-[#2B2A49]"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon className="text-[#4A488E] dark:text-[#EDEDFB]" />
                }
              >
                <Typography className="text-[#4A488E] dark:text-[#EDEDFB]">
                  {category.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!startDate || !selectedSeniority ? (
                  <Typography
                    sx={{ textAlign: "center" }}
                    className="dark:text-[#EDEDFB]"
                  >
                    Please select a reflection period and seniority to proceed.
                  </Typography>
                ) : (
                  renderAccordions(category)
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppraisalAdmin;
