import React, { useContext } from "react";
import { Link } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { ViewTypeContext } from "../../utils/contexts/viewTypeContext";

dayjs.extend(relativeTime);

export const UserCard = ({ user }) => {
  const isMobileView = useContext(ViewTypeContext);
  return (
    <Link to={`/HR/My-Employees/${user._id}`}>
      <motion.div
        className={
          isMobileView
            ? "w-[300px] h-[419px] px-5 py-7 bg-violet-100 dark:bg-[#3A3A68] rounded-[10px] justify-center items-center gap-2.5 inline-flex"
            : "w-[332px] h-[419px] px-10 py-11 bg-violet-100 dark:bg-[#3A3A68] rounded-[10px] justify-center items-center gap-2.5 inline-flex"
        }
        whileHover={{
          scale: 0.98,
          boxShadow: "",
        }}
      >
        <div className="m-auto flex flex-col items-center">
          <div
            className="w-[91px] h-[91px] rounded-full"
            style={{
              backgroundImage: `url(${
                user.avatar?.url || "/images/avatar.svg"
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div className="flex flex-col gap-[15px] mt-[24px] items-center">
            <div className="text-[#4A488E] dark:text-white text-xl font-semibold">
              {user.name}
            </div>
            <div className="text-indigo-800 dark:text-[#EDEDFB] text-base font-normal">
              {user.job}
            </div>
            <div className="text-indigo-500 dark:text-[#ededfba8] text-[13px] font-normal">
              {user.email}
            </div>
          </div>
          <div className="mt-[70px] text-center dark:text-white text-indigo-800 text-lg font-medium ">
            Birthday:{" "}
            {dayjs(user.birthday).format("D MMMM") === "Invalid Date"
              ? "please set date in HR Details"
              : dayjs(user.birthday).format("D MMMM")}
          </div>
          <div className="text-center text-indigo-800 dark:text-[#EDEDFB] text-sm font-normal">
            {dayjs(user.DataStart).fromNow(true)} length of service
          </div>
        </div>
      </motion.div>
    </Link>
  );
};
