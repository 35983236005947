import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  Popper,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  useGetContactDetailsQuery,
  useGetContactsQuery,
  usePostContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation,
} from "../../redux/api/crm/contactsApi";
import { useGetDealsQuery } from "../../redux/api/crm/dealsApi";
import { useGetLeadsQuery } from "../../redux/api/crm/leads";
import { useGetMeQuery } from "../../redux/api/userApi";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import { useGetAccountsQuery } from "../../redux/api/crm/accountsApi";
import { notify } from "../utils/notificationManager";
import NotificationComponent from "../NotificationComponent";
import { io } from "socket.io-client";
import backend_URL from "../../config";
import { debounce } from "lodash";
import { useCallback } from "react";

dayjs.extend(relativeTime);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Contacts = () => {
  const { data: contactsData, refetch: refetchContacts } =
    useGetContactsQuery();

  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
    refetch: refetchMe,
  } = useGetMeQuery();
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
    refetch: refetchUsers,
  } = useGetAllUsersQuery();
  const {
    data: leadsData,
    error: leadsErr,
    isLoading: leadLoading,
    refetch: refetchLeads,
  } = useGetLeadsQuery();
  const {
    data: dealsData,
    error: dealsErr,
    isLoading: dealsLoading,
    refetch: refetchDeals,
  } = useGetDealsQuery();

  const { data: accountsData, refetch: refetchAccounts } =
    useGetAccountsQuery();

    useEffect(() => {
      const socket = io(backend_URL);
      window.socket = socket;
    
      socket.on("accountPing", (data) => {
        console.log("Account ping received:", data);
        refetchAccounts();
      });

      socket.on("contactPing", (data) => {
        console.log("Contact ping received:", data);
        refetchContacts();
      });

      socket.on("dealPing", (data) => {
        console.log("Deal ping received:", data);
        refetchDeals();
      });

      socket.on("leadPing", (data) => {
        console.log("Lead ping received:", data);
        refetchLeads();
      });

      socket.on("userPing", (data) => {
        console.log("User ping received:", data);
        refetchUsers();
      })

      return () => {
        socket.disconnect();
      };
    }, [refetchAccounts, refetchContacts, refetchDeals, refetchLeads, refetchUsers]);
    

  const getUsername = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user ? user.name : "Unknown User";
  };

  const getAccount = (accountId) => {
    const account = accountsData?.accounts?.find((a) => a._id === accountId);
    return account ? account.nameComp : "Unknown company";
  };

  const getAccountByComp = (companyName) => {
    const account = accountsData?.accounts?.find(
      (a) => a.nameComp === companyName
    );
    return account ? account._id : "Unknown company";
  };

  const getAllCompanies = () => {
    const allCompanies =
      accountsData?.accounts?.map((a) => ({
        name: a.nameComp,
        accountId: getAccountByComp(a.nameComp),
      })) || [];
    const uniqueCompanies = Array.from(
      new Set(allCompanies.map((company) => company.name))
    ).map((name) => allCompanies.find((company) => company.name === name));
    return uniqueCompanies;
  };

  const uniqueCompanies = getAllCompanies();

  const handleExportPDF = () => {
    const doc = new jsPDF("landscape");
    const selectedContactsData = updatedContacts.filter((contact) =>
      selectedContacts.includes(contact._id)
    );
    const tableColumn = ["Name", "Company", "Role", "Phone", "Email"];
    const tableRows = [];
    selectedContactsData.forEach((contact) => {
      const contactData = [
        contact.name,
        getAccount(contact.account),
        contact.role,
        contact.phone,
        contact.email,
      ];
      tableRows.push(contactData);
    });
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: "grid",
      headStyles: { fillColor: [22, 160, 133] },
      margin: { top: 20 },
      styles: { overflow: "linebreak" },
    });
    doc.save("contacts.pdf");
  };

  const [selectOptionsOpen, setSelectOptionsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(""); 

  const debounceSearch = useCallback(
    debounce((query) => {
      setDebouncedSearchQuery(query);
    }, 500), // Wait 300ms after the last input change before updating
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debounceSearch(e.target.value); // Debounce the setting of the search query
  };

  const [updatedContacts, setUpdatedContacts] = useState([]);
  const [updateContact] = useUpdateContactMutation();
  const [postContact] = usePostContactMutation();
  const [deleteContact] = useDeleteContactMutation();

  const [contactToBeUpdated, setContactToBeUpdated] = useState({
    name: "",
    account: "",
    role: "",
    email: "",
    phone: "",
  });

  const [contactToBePosted, setContactToBePosted] = useState({
    name: "",
    account: "",
    role: "",
    email: "",
    phone: "",
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogNew, setOpenDialogNew] = React.useState(false);
  const [dialogContact, setDialogContact] = useState({});

  const handleClickOpenDialog = (contact) => {
    setOpenDialog(true);
    setDialogContact(contact);
    setContactToBeUpdated(contact);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleInputChangeNew = (e) => {
    const { name, value } = e.target;
    setContactToBePosted({
      ...contactToBePosted,
      [name]: value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactToBeUpdated({
      ...contactToBeUpdated,
      [name]: value,
    });
  };

  const handlePostDialog = async () => {
    try {
      const contactWithOwner = {
        ...contactToBePosted,
      };

      await postContact(contactWithOwner);
      const newContacts = [...updatedContacts, contactWithOwner];
      setUpdatedContacts(newContacts);
      setOpenDialogNew(false);
      refetchAccounts();
      refetchContacts();
      notify("Contact created successfully", 0, null, null, true);
    } catch (error) {
      console.error("Failed to post contact:", error);
      notify("Failed to create contact", 2, null, null, true);
    }
  };

  const handleSaveDialog = () => {
    const goAheadCallback = async () => {
      try {
        await updateContact(contactToBeUpdated);
        const newContacts = updatedContacts.map((contact) =>
          contact._id === contactToBeUpdated._id ? contactToBeUpdated : contact
        );
        setUpdatedContacts(newContacts);
        refetchAccounts();
        refetchContacts();
        setOpenDialog(false);
        notify("Contact updated successfully", 0, null, null, true);
      } catch (error) {
        console.error("Failed to update contact:", error);
        notify("Failed to update contact", 2, null, null, true);
      }
    };

    const cancelCallback = () => {
      notify("Update canceled", 1, null, null, true);
    };

    notify(
      "Are you sure you want to update the contact?",
      1,
      goAheadCallback,
      cancelCallback
    );
  };

  useEffect(() => {
    if (contactsData?.contacts) {
      setUpdatedContacts(contactsData.contacts);
    }
  }, [contactsData]);

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentContactId, setCurrentContactId] = useState(null);
  const popperRef = useRef(null);

  const handlePopoverOpen = (event, contactId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentContactId(contactId);
  };

  const handlePopoverClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
    setCurrentContactId(null);
  };

  const isPopoverOpen = Boolean(anchorEl);
  const rowsPerPage = 8;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        handlePopoverClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allContactIds = updatedContacts.map((contact) => contact._id);
      setSelectedContacts(allContactIds);
      setSelectOptionsOpen(true);
    } else {
      setSelectedContacts([]);
      setSelectOptionsOpen(false);
    }
  };

  const handleSelectContact = (event, contactId) => {
    if (event.target.checked) {
      setSelectedContacts((prevSelected) => [...prevSelected, contactId]);
      setSelectOptionsOpen(true);
    } else {
      setSelectedContacts((prevSelected) =>
        prevSelected.filter((id) => id !== contactId)
      );
      if (selectedContacts.length < 2) {
        setSelectOptionsOpen(false);
      }
    }
  };

  const closeOptionsBar = () => {
    setSelectOptionsOpen(false);
    setSelectedContacts([]);
  };

  const handleDeleteContacts = () => {
    const goAheadCallback = async () => {
      try {
        if (selectedContacts.length <= 1) {
          await deleteContact(selectedContacts[0]);
        } else {
          for (const contact of selectedContacts) {
            await deleteContact(contact);
          }
        }
        let newContacts = [];
        for (let i = 0; i < selectedContacts.length; i++) {
          let filtered = updatedContacts.filter(
            (l) => l._id !== selectedContacts[i]
          );
          newContacts = filtered;
        }
        setUpdatedContacts(newContacts);
        setSelectOptionsOpen(false);
        notify("Contact(s) deleted successfully", 0, null, null, true);
        refetchContacts();
      } catch (error) {
        console.error("Failed to delete contact:", error);
        notify("Failed to delete contact(s)", 2, null, null, true);
      }
    };

    const cancelCallback = () => {
      notify("Delete action canceled", 1, null, null, true);
    };

    notify(
      "Are you sure you want to delete the selected contact(s)?",
      2,
      goAheadCallback,
      cancelCallback
    );
  };

  const reversedContacts = [...updatedContacts].reverse();

  const filteredContacts = contactsData?.contacts
  ? contactsData.contacts.filter((contact) => {
      const query = debouncedSearchQuery.toLowerCase();
      return (
        contact.name.toLowerCase().includes(query) ||
        getAccount(contact.account).toLowerCase().includes(query) ||
        contact.role.toLowerCase().includes(query) ||
        contact.phone.includes(query) ||
        contact.email.toLowerCase().includes(query)
      );
    })
  : []; // Return an empty array if contactsData is undefined
  
  const isAllSelected =
    updatedContacts.length > 0 &&
    selectedContacts.length === updatedContacts.length;

  const totalPages = Math.ceil(updatedContacts.length / rowsPerPage);

  const handleChangePage = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const paginatedContacts = filteredContacts.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const startItem = (currentPage - 1) * rowsPerPage + 1;
  const endItem = Math.min(currentPage * rowsPerPage, updatedContacts.length);

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch ">
      <div className="w-full">
        <div className="pl-0 p-4 flex items-center flex-wrap justify-between">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                color="inherit"
                to="/dashboard"
                className="dark:text-[#EDEDFB]"
              >
                <span
                  style={{ color: "#4A488E" }}
                  className="dark:text-[#EDEDFB]"
                >
                  Fuse Digital
                </span>
              </Link>
              <Link underline="hover" color="inherit" to={`/CRM/Contacts`}>
                <span
                  style={{ color: "#4A488E", fontWeight: "bold" }}
                  className="dark:text-[#EDEDFB]"
                >
                  CRM - Contacts
                </span>
              </Link>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>
        <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal mt-[40px] mb-[53px]">
          Contacts
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div className="relative">
        <div className="pl-2">
          <input
            type="text"
            placeholder="Search"
            className="w-[411px] h-[55px] bg-[#E7E7F9] dark:bg-[#33335A] border-none rounded-[52px] p-5 relative dark:text-white placeholder-[#979BE2] dark:placeholder-[#8C8CB0]"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
          <svg
            className="absolute left-[370px] top-[27px] transform -translate-y-1/2"
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9847 13.0889C10.5807 14.2107 8.80047 14.7523 7.0096 14.6025C5.21874 14.4527 3.55321 13.6228 2.35508 12.2834C1.15696 10.9439 0.517191 9.19656 0.567176 7.40014C0.617162 5.60371 1.35311 3.89462 2.62386 2.62386C3.89462 1.35311 5.60371 0.617162 7.40014 0.567176C9.19656 0.517191 10.9439 1.15696 12.2834 2.35508C13.6228 3.55321 14.4527 5.21874 14.6025 7.0096C14.7523 8.80047 14.2107 10.5807 13.0889 11.9847L18.4597 17.3545C18.5365 17.426 18.598 17.5123 18.6407 17.6081C18.6834 17.704 18.7064 17.8074 18.7083 17.9123C18.7101 18.0172 18.6908 18.1214 18.6515 18.2187C18.6122 18.316 18.5537 18.4043 18.4796 18.4785C18.4054 18.5527 18.317 18.6112 18.2197 18.6505C18.1224 18.6898 18.0182 18.7091 17.9133 18.7072C17.8084 18.7054 17.705 18.6824 17.6092 18.6397C17.5133 18.597 17.4271 18.5354 17.3556 18.4587L11.9847 13.0889ZM3.72847 11.4618C2.96393 10.6972 2.44321 9.72308 2.23213 8.66261C2.02105 7.60213 2.12907 6.50288 2.54255 5.50378C2.95602 4.50468 3.6564 3.65058 4.55515 3.04941C5.45391 2.44824 6.5107 2.12699 7.59198 2.12627C8.67326 2.12554 9.73049 2.44536 10.6301 3.04532C11.5296 3.64528 12.2311 4.49844 12.646 5.49698C13.0608 6.49553 13.1703 7.59463 12.9606 8.65539C12.751 9.71615 12.2316 10.6909 11.4681 11.4566L11.4628 11.4618L11.4576 11.466C10.4317 12.4895 9.04141 13.064 7.59219 13.0632C6.14297 13.0625 4.75331 12.4865 3.72847 11.4618Z"
              fill="#4A488E"
            />
          </svg>
        </div>
        <div className="w-[150px] h-[34px] justify-start items-center gap-[52px] inline-flex">
          <div
            className="w-fit h-[38px] px-[15px] py-2.5 bg-violet-100 rounded-md border border-indigo-500 justify-center items-center gap-2.5 inline-flex"
            onClick={() => setOpenDialogNew(true)}
          >
            <div className="text-[#4A488E] text-[15px] font-medium">
              + New contact
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex flex-col mx-[12px]">
          <div
            id="selectAllContacts"
            key={"selectAllContacts"}
            className="h-[50px] flex items-center"
          >
            <input
              type="checkbox"
              className="w-[23px] h-[23px] bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[2px] focus:ring-0"
              style={{ backgroundColor: "#D9D9D9", border: "none" }}
              checked={isAllSelected}
              onChange={handleSelectAll}
            />
          </div>
          {paginatedContacts.map((contact) => (
            <div key={contact.id} className="h-[50px] flex items-center">
              <input
                type="checkbox"
                className="w-[23px] h-[23px] bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[2px] focus:ring-0"
                style={{ backgroundColor: "#D9D9D9", border: "none" }}
                checked={selectedContacts.includes(contact._id)}
                onChange={(e) => handleSelectContact(e, contact._id)}
              />
            </div>
          ))}
        </div>
        <div className="overflow-x-auto w-full">
          <table
            id="contactsTable"
            className="min-w-full bg-[#FAFAFE] dark:bg-[#3A3A68] rounded-[15px] overflow-hidden"
          >
            <thead>
              <tr className="h-[50px]">
                <th className="py-2 px-4 text-[#4A488E] dark:text-[#EDEDFB] text-[16px] text-base font-normal border-b border-[#CAC9F1] dark:border-[#222147]">
                  Name
                </th>
                <th className="py-2 px-4 text-[#4A488E] dark:text-[#EDEDFB] text-[16px] text-base font-normal border-b border-l border-[#CAC9F1] dark:border-[#222147]">
                  Company
                </th>
                <th className="py-2 px-4 text-[#4A488E] dark:text-[#EDEDFB] text-[16px] text-base font-normal border-b border-l border-[#CAC9F1] dark:border-[#222147]">
                  Role
                </th>
                <th className="py-2 px-4 text-[#4A488E] dark:text-[#EDEDFB] text-[16px] text-base font-normal border-b border-l border-[#CAC9F1] dark:border-[#222147]">
                  Phone
                </th>
                <th className="py-2 px-4 text-[#4A488E] dark:text-[#EDEDFB] text-[16px] text-base font-normal border-b border-l border-[#CAC9F1] dark:border-[#222147]">
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedContacts.map((contact, index) => {
                const isLastRow = index === paginatedContacts.length - 1;
                return (
                  <tr key={contact._id} className="h-[50px]">
                    <td
                      className={`py-2 text-[#656565] dark:text-[#EDEDFB] text-[12px] px-4 text-center ${
                        isLastRow
                          ? "border-r border-[#CAC9F1] dark:border-[#222147]"
                          : "border-b border-[#CAC9F1] dark:border-[#222147]"
                      }`}
                    >
                      {contact.name}
                    </td>
                    <td
                      className={`py-2 px-4 text-center ${
                        isLastRow
                          ? "border-r border-[#CAC9F1] dark:border-[#222147]"
                          : "border border-[#CAC9F1] dark:border-[#222147]"
                      }`}
                    >
                      <span className="text-[#656565] dark:text-[#EDEDFB] text-[12px]">
                        {contact.account}
                      </span>
                    </td>
                    <td
                      className={`py-2 px-4 text-[#656565] dark:text-[#EDEDFB] text-[12px] text-center ${
                        isLastRow
                          ? "border-r border-[#CAC9F1] dark:border-[#222147]"
                          : "border border-[#CAC9F1] dark:border-[#222147]"
                      }`}
                    >
                      {contact.role}
                    </td>
                    <td
                      className={`py-2 px-4 text-[#656565] dark:text-[#EDEDFB] text-[12px] text-center ${
                        isLastRow
                          ? "border-r border-[#CAC9F1] dark:border-[#222147]"
                          : "border border-[#CAC9F1] dark:border-[#222147]"
                      }`}
                    >
                      {contact.phone}
                    </td>
                    <td
                      className={`py-2 px-4 text-[#656565] dark:text-[#EDEDFB] text-[12px] text-center ${
                        isLastRow
                          ? "border-r border-[#CAC9F1] dark:border-[#222147]"
                          : "border border-[#CAC9F1] dark:border-[#222147]"
                      }`}
                    >
                      {contact.email}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-end items-center mt-4 text-[#4A488E] dark:text-[#EDEDFB]">
            <span>
              {startItem}-{endItem} of {filteredContacts.length}
            </span>
            <button
              onClick={() => handleChangePage(currentPage - 1)}
              className="mx-2 py-1"
              disabled={currentPage === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <g clipPath="url(#clip0_252_1223)">
                  <path
                    d="M8.80546 11.9043C8.90624 11.9043 9.00938 11.8645 9.08672 11.7871C9.24141 11.6324 9.24141 11.3793 9.08672 11.2246L3.79219 5.93008L9.00938 0.71289C9.16406 0.558202 9.16406 0.305078 9.00938 0.15039C8.85469 -0.00429814 8.60156 -0.00429814 8.44688 0.15039L2.94609 5.64883C2.79141 5.80352 2.79141 6.05664 2.94609 6.21133L8.52187 11.7871C8.60156 11.8668 8.70233 11.9043 8.80546 11.9043Z"
                    className="fill-current dark:text-[#EDEDFB] text-[#4A488E]"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_252_1223">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(0 12) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button
              onClick={() => handleChangePage(currentPage + 1)}
              className="mx-2 py-1"
              disabled={currentPage === totalPages}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <g clipPath="url(#clip0_252_1221)">
                  <path
                    d="M3.19454 0.0957029C3.09376 0.0957029 2.99062 0.135547 2.91328 0.21289C2.75859 0.367578 2.75859 0.620703 2.91328 0.77539L8.20781 6.06992L2.99062 11.2871C2.83594 11.4418 2.83594 11.6949 2.99062 11.8496C3.14531 12.0043 3.39844 12.0043 3.55312 11.8496L9.05391 6.35117C9.20859 6.19648 9.20859 5.94336 9.05391 5.78867L3.47813 0.21289C3.39844 0.133203 3.29767 0.0957029 3.19454 0.0957029Z"
                    className="fill-current dark:text-[#EDEDFB] text-[#4A488E]"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_252_1221">
                    <rect
                      width="12"
                      height="12"
                      fill="white"
                      transform="translate(12) rotate(90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <Dialog
        id="editContactDialog"
        className="py-[36px] px-[55px]"
        fullWidth
        maxWidth="xl"
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        PaperProps={{
          elevation: 0,
          sx: { backgroundColor: "#FAFAFE", borderRadius: "14px" },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(217, 217, 217, 0.75)",
            boxShadow: "none",
          },
        }}
      >
        <AppBar
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "#FAFAFE",
            color: "black",
          }}
        >
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              className="text-[#4A488E] dark:text-[#EDEDFB] text-[25px] font-normal"
            >
              {dialogContact.contactTitle} | {dialogContact.Company}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon sx={{ color: "#4A488E" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className="px-[55px]">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col w-[50%] gap-[16px]">
              <div className="flex flex-row items-center justify-between">
                <div
                  style={{
                    color: "#434343",
                    fontSize: 18,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  Name
                </div>
                <input
                  name="contactTitle"
                  className="bg-[#E9E9FF] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-[70%]"
                  value={contactToBeUpdated.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div
                  style={{
                    color: "#434343",
                    fontSize: 18,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  Company name
                </div>
                <input
                  name="account"
                  className="bg-[#E9E9FF] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-[70%]"
                  value={contactToBeUpdated.account}
                  placeholder="Choose account"
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div
                  style={{
                    color: "#434343",
                    fontSize: 18,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  Phone
                </div>
                <input
                  name="phone"
                  className="bg-[#E9E9FF] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-[70%]"
                  value={contactToBeUpdated.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-row items-center justify-between">
                <div
                  style={{
                    color: "#434343",
                    fontSize: 18,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  Email
                </div>
                <input
                  name="email"
                  className="bg-[#E9E9FF] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-[70%]"
                  value={contactToBeUpdated.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-row items-start justify-between"></div>
            </div>
            <div className="flex flex-col gap-[24px] w-[40%]"></div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ fontSize: 16 }}
            className="mt-2 text-white py-2 px-4 rounded w-fit bg-[#7270BB]"
            autoFocus
            color="inherit"
            onClick={handleSaveDialog}
          >
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        id="newContactDialog"
        className="py-[36px] px-[55px]"
        fullWidth
        maxWidth="md"
        open={openDialogNew}
        onClose={handleCloseDialogNew}
        TransitionComponent={Transition}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",
            borderRadius: "14px",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "var(--tw-bg-opacity, #2B2A49)",
            },
          },
          className:
            "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white",
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#2B2A49",
            boxShadow: "none",
            "@media (prefers-color-scheme: dark)": {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          },
          className:
            "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]",
        }}
      >
        <AppBar
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "#FAFAFE",
            color: "black",
          }}
          className="dark:bg-[#2B2A49]"
        >
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#4A488E" }}
              variant="h6"
              component="div"
              className="dark:text-[#EDEDFB]"
            >
              New contact
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialogNew}
              aria-label="close"
            >
              <CloseIcon
                sx={{ color: "#4A488E" }}
                className="dark:text-[#EDEDFB]"
              />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className="px-[55px]">
          <div className="flex flex-col w-full gap-[16px]">
            <div className="flex flex-row items-center justify-between">
              <div
                style={{
                  color: "#434343",
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
                className="dark:text-[#EDEDFB]"
              >
                Name
              </div>
              <input
                name="name"
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#4A488E] dark:text-[#EDEDFB] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                value={contactToBePosted.name}
                onChange={handleInputChangeNew}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                style={{
                  color: "#434343",
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
                className="dark:text-[#EDEDFB]"
              >
                Company name
              </div>
              <select
                name="account"
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#4A488E] dark:text-[#EDEDFB] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                value={contactToBePosted.account}
                onChange={handleInputChangeNew}
              >
                <option value="" disabled selected>
                  Select an account
                </option>
                {uniqueCompanies.map((u) => (
                  <option key={u.accountId} value={u.name}>
                    {u.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                style={{
                  color: "#434343",
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
                className="dark:text-[#EDEDFB]"
              >
                Role
              </div>
              <input
                name="role"
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#4A488E] dark:text-[#EDEDFB] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                value={contactToBePosted.role}
                onChange={handleInputChangeNew}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                style={{
                  color: "#434343",
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
                className="dark:text-[#EDEDFB]"
              >
                Phone
              </div>
              <input
                name="phone"
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#4A488E] dark:text-[#EDEDFB] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                value={contactToBePosted.phone}
                onChange={handleInputChangeNew}
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                style={{
                  color: "#434343",
                  fontSize: 18,
                  fontFamily: "Inter",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
                className="dark:text-[#EDEDFB]"
              >
                Email
              </div>
              <input
                name="email"
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] text-[#4A488E] dark:text-[#EDEDFB] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
                value={contactToBePosted.email}
                onChange={handleInputChangeNew}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              fontSize: 16,
              backgroundColor: "#8D8BDB",
              color: "white",
              textTransform: "none",
            }}
            onClick={handlePostDialog}
          >
            Create new contact
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className={` ${
          selectOptionsOpen ? "" : "hidden"
        } w-fit h-[93px] px-[22px] py-[13px] bg-[#CAC9F1] dark:bg-[#2B2A49] rounded-md flex-row justify-between items-center gap-2.5 inline-flex absolute bottom-[10%] left-[50%]`}
      >
        <div className="flex flex-row gap-[7px] ">
          <div
            onClick={handleDeleteContacts}
            className="bg-[#FAFAFE] dark:bg-[#3A3A68] h-[67px] rounded-[5px] px-[18px] flex flex-col items-center justify-center gap-[10px]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <g clip-path="url(#clip0_257_3684)">
                <path
                  d="M11.1149 -0.0292969C9.30755 -0.0292969 7.85555 1.47255 7.85555 3.28824V3.69163H4.19186C3.68324 3.69163 3.26878 4.10609 3.26878 4.6147V5.53778H2.3457V7.38393H4.19186V21.2301C4.19186 22.7486 5.44263 23.9993 6.96109 23.9993H18.038C19.5565 23.9993 20.8072 22.7486 20.8072 21.2301V7.38393H22.6534V5.53778H21.7303V4.6147C21.7303 4.10609 21.3159 3.69163 20.8072 3.69163H17.1435V3.28732C17.1435 1.47255 15.6915 -0.0292969 13.8842 -0.0292969H11.1149ZM11.1149 1.87409H13.8842C14.6263 1.87409 15.2402 2.47963 15.2402 3.28732V3.69163H9.75801V3.28732C9.75801 2.47963 10.3719 1.87501 11.114 1.87501L11.1149 1.87409ZM6.03801 7.38393H10.7688C10.8832 7.39593 10.9968 7.41255 11.1149 7.41255H13.8842C14.0023 7.41255 14.1149 7.39593 14.2303 7.38393H18.9611V21.2301C18.9611 21.7498 18.5577 22.1532 18.038 22.1532H6.96109C6.4414 22.1532 6.03801 21.7498 6.03801 21.2301V7.38393ZM7.88416 9.23009V20.307H9.73032V9.23009H7.88416ZM11.5765 9.23009V20.307H13.4226V9.23009H11.5765ZM15.2688 9.23009V20.307H17.1149V9.23009H15.2688Z"
                  className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
                />
              </g>
              <defs>
                <clipPath id="clip0_257_3684">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div className="text-[#7674C2] dark:text-[#EDEDFB] text-[10px] font-normal">
              Delete
            </div>
          </div>
          <div
            className="bg-[#FAFAFE] dark:bg-[#3A3A68] h-[67px] rounded-[5px] px-[18px] flex flex-col items-center justify-center gap-[10px]"
            onClick={handleExportPDF}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.5 2H5.5C4.4 2 3.5 2.9 3.5 4V20C3.5 21.1 4.4 22 5.5 22H17.5C18.6 22 19.5 21.1 19.5 20V8L13.5 2ZM17.5 20H5.5V4H12.5V9H17.5V20ZM15.5 11V18.1L13.4 16L10.6 18.8L7.8 16L10.6 13.2L8.4 11H15.5Z"
                className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
              />
            </svg>
            <div className="text-[#7674C2] dark:text-[#EDEDFB] text-[10px] font-normal">
              Export
            </div>
          </div>
        </div>
        <div onClick={closeOptionsBar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
          >
            <path
              d="M29.724 28.0888C29.8314 28.1963 29.9166 28.3238 29.9747 28.4642C30.0329 28.6045 30.0628 28.755 30.0628 28.9069C30.0628 29.0588 30.0329 29.2093 29.9747 29.3496C29.9166 29.49 29.8314 29.6175 29.724 29.7249C29.6165 29.8324 29.489 29.9176 29.3486 29.9757C29.2083 30.0339 29.0578 30.0638 28.9059 30.0638C28.754 30.0638 28.6036 30.0339 28.4632 29.9757C28.3228 29.9176 28.1953 29.8324 28.0879 29.7249L18.4997 20.1353L8.91146 29.7249C8.6945 29.9419 8.40024 30.0638 8.09342 30.0638C7.78659 30.0638 7.49233 29.9419 7.27537 29.7249C7.05841 29.508 6.93652 29.2137 6.93652 28.9069C6.93652 28.6001 7.05841 28.3058 7.27537 28.0888L16.865 18.5006L7.27537 8.91244C7.05841 8.69548 6.93652 8.40122 6.93652 8.09439C6.93652 7.78757 7.05841 7.49331 7.27537 7.27635C7.49233 7.05939 7.78659 6.9375 8.09342 6.9375C8.40024 6.9375 8.6945 7.05939 8.91146 7.27635L18.4997 16.866L28.0879 7.27635C28.3048 7.05939 28.5991 6.9375 28.9059 6.9375C29.2127 6.9375 29.507 7.05939 29.724 7.27635C29.9409 7.49331 30.0628 7.78757 30.0628 8.09439C30.0628 8.40122 29.9409 8.69548 29.724 8.91244L20.1343 18.5006L29.724 28.0888Z"
              className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
