import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { userApi } from "./userApi";
import { setIsAuthenticated, setUser } from "../features/userSlice";
import backend_URL from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => "/me",
    }),
    login: builder.mutation({
      query(body) {
        return {
          url: "/login",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { token, user } = data;
          Cookies.set("token", token, { expires: 7 });
          dispatch(setUser(user));
          dispatch(setIsAuthenticated(true));
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    register: builder.mutation({
      query(body) {
        return {
          url: "/register",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { token, user } = data;
          Cookies.set("token", token, { expires: 7 });
          dispatch(setUser(user));
          dispatch(setIsAuthenticated(true));
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    createUserByAdmin: builder.mutation({
      query(body) {
        return {
          url: "/user/create",
          method: "POST",
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Optionally handle data or side effects here
          console.log("User created by admin:", data);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getAllUsers: builder.query({
      query: (params = {}) => {
        const { limit, name, department, seniority } = params;
        const queryParams = new URLSearchParams();
        if (limit) queryParams.append("limit", limit);
        if (name) queryParams.append("name", name);
        if (department) queryParams.append("department", department);
        if (seniority) queryParams.append("seniority", seniority);

        return {
          url: `/users?${queryParams.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Users"],
    }),
    updateUserRole: builder.mutation({
      query({ id, role }) {
        return {
          url: `/user/${id}/role`,
          method: "PUT",
          body: { role },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData("getMe", null, (draft) => {
              if (draft._id === data.user._id) {
                draft.role = data.user.role;
              }
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
      invalidatesTags: ["Users"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "POST",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setIsAuthenticated(false));
          dispatch(setUser(null));
          Cookies.remove("token");
        } catch (error) {
          console.log(error);
        }
      },
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "/password/forgot",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ token, body }) => ({
        url: `/password/reset/${token}`,
        method: "PUT",
        body,
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/user/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    loggedChangePass: builder.mutation({
      query: ({ id, oldPassword, newPassword, confirmPassword }) => ({
        url: `/user/${id}/changePassword`,
        method: "PUT",
        body: { oldPassword, newPassword, confirmPassword },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
          console.error("Password change error:", error);
        }
      },
      invalidatesTags: ["Users"],
    }),
    updatePasswordByAdmin: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/user/${id}/password`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useLoggedChangePassMutation,
  useLoginMutation,
  useRegisterMutation,
  useCreateUserByAdminMutation,
  useGetAllUsersQuery,
  useUpdateUserRoleMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useDeleteUserMutation,
  useUpdatePasswordByAdminMutation,
} = authApi;
