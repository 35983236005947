import React, { useRef, useState } from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = ({ initialValue, onChange }) => {
  const editor = useRef(null);
  const [content, setContent] = useState(initialValue || '');

  const config = {
    readonly: false, // Allow editing
    height: 500,     // Set editor height
    toolbar: true,   // Show toolbar

    showPoweredBy: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,

    // Customize toolbar options for large screens
    buttons: [
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'brush', 'fontsize', '|',
      'ul', 'ol', '|',
      'align', 'undo', 'redo'
    ],

    // Customize toolbar options for medium screens (e.g. tablets)
    buttonsMD: [
      'bold', 'italic', 'underline', '|',
      'ul', 'ol', '|',
      'align', 'undo', 'redo'
    ],

    // Customize toolbar options for small screens (e.g. mobile)
    buttonsSM: [
      'bold', 'italic', 'ul', 'ol', 'undo', 'redo'
    ],

    // Customize toolbar options for extra small screens
    buttonsXS: [
      'bold', 'italic', 'undo', 'redo'
    ],

    createAttributes: {
      ul: { style: 'list-style: disc inside;' },  // Fix for unordered list
      ol: { style: 'list-style: decimal inside;' },  // Fix for ordered list
      p: { style: 'margin: 16px 0; font-size: 16px; line-height: 1.6;' }, // Fix for paragraph spacing and formatting
    },

    askBeforePasteHTML: true,
    processPasteHTML: true,
    defaultActionOnPaste: 'insert_as_html',
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1}
      onBlur={newContent => {
        setContent(newContent);
        if (onChange) {
          onChange(newContent);
        }
      }}
    />
  );
};

export default TextEditor;
