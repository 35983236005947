import React from 'react';

const romanianHolidays2024 = [
    { date: new Date(2024, 0, 1), comment: "Anul Nou" },
    { date: new Date(2024, 0, 2), comment: "Anul Nou" },
    { date: new Date(2024, 0, 6), comment: "Boboteaza" },
    { date: new Date(2024, 0, 7), comment: "Sfantul Ioan Botezatorul" },
    { date: new Date(2024, 0, 24), comment: "Ziua Unirii Principatelor Române" },
    { date: new Date(2024, 3, 3), comment: "Paște ortodox" },
    { date: new Date(2024, 3, 4), comment: "Paște ortodox" },
    { date: new Date(2024, 4, 1), comment: "Ziua Muncii" },
    { date: new Date(2024, 5, 1), comment: "Ziua Copilului" },
    { date: new Date(2024, 5, 23), comment: "Rusalii" },
    { date: new Date(2024, 5, 24), comment: "A doua zi de Rusalii" },
    { date: new Date(2024, 7, 15), comment: "Adormirea Maicii Domnului" },
    { date: new Date(2024, 10, 30), comment: "Sfântul Andrei" },
    { date: new Date(2024, 11, 1), comment: "Ziua Națională a României" },
    { date: new Date(2024, 11, 25), comment: "Crăciunul" },
    { date: new Date(2024, 11, 26), comment: "Crăciunul" },
  ];

  const romanianHolidays2025 = [
    { date: new Date(2025, 0, 1), comment: "Anul Nou" },
    { date: new Date(2025, 0, 2), comment: "Anul Nou" },
    { date: new Date(2025, 0, 6), comment: "Boboteaza" },
    { date: new Date(2025, 0, 7), comment: "Sfantul Ioan Botezatorul" },
    { date: new Date(2025, 0, 24), comment: "Ziua Unirii Principatelor Române" },
    { date: new Date(2025, 3, 20), comment: "Paște ortodox" },
    { date: new Date(2025, 3, 21), comment: "Paște ortodox" },
    { date: new Date(2025, 4, 1), comment: "Ziua Muncii" },
    { date: new Date(2025, 5, 1), comment: "Ziua Copilului" },
    { date: new Date(2025, 5, 8), comment: "Rusalii" },
    { date: new Date(2025, 5, 9), comment: "A doua zi de Rusalii" },
    { date: new Date(2025, 7, 15), comment: "Adormirea Maicii Domnului" },
    { date: new Date(2025, 10, 30), comment: "Sfântul Andrei" },
    { date: new Date(2025, 11, 1), comment: "Ziua Națională a României" },
    { date: new Date(2025, 11, 25), comment: "Crăciunul" },
    { date: new Date(2025, 11, 26), comment: "Crăciunul" },
  ];

  export { romanianHolidays2024, romanianHolidays2025 };