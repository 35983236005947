import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie"; // Import js-cookie
import backend_URL from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery,
  tagTypes: ["Projects"],
  endpoints: (builder) => ({
    getProjects: builder.query({
      providesTags: ["Projects"],
      query() {
        return {
          url: "/projects",
          method: "GET",
        };
      },
    }),
    createProject: builder.mutation({
      query(body) {
        return {
          url: "/projects",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Projects"],
    }),
    updateProjectStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/projects/${id}/status`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: ["Projects"],
    }),
    updateProject: builder.mutation({
      query({ id, body }) {
        return {
          url: `/projects/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Projects"],
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: `/projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Projects"],
    }),
    postCommentToProject: builder.mutation({
      query: (comment) => ({
        url: `/projects/${comment.projectId}/comments`,
        method: "POST",
        body: comment,
      }),
      invalidatesTags: ["Projects"],
    }),
    updateProjectStatusByActive: builder.mutation({
      query: ({ id, status }) => ({
        url: `/projects/${id}/status`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: ["Projects"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectStatusMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  usePostCommentToProjectMutation,
  useUpdateProjectStatusByActiveMutation,
} = projectsApi;
