import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import FuseSpecialDropdown from "./UI/specialDropdown";
import {
  InventoryDeleteIcon,
  InventoryEditIcon,
  InventoryCloseIcon,
  AddRestockIcon,
} from "./svgs";

// Helper function to format date
const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  return date.toLocaleDateString("ro-RO");
};

const productColumnMapping = {
  productName: "Product Name",
  description: "Description",
  status: "Product Status",
  skuNumber: "SKU",
  stock: "Stock",
  expiryDate: "Expiry Date",
  uploadedDate: "Upload Date",
  unitPrice: "Unit Price",
  totalPrice: "Total Price",
};

const restockColumnMapping = {
  productName: "Product Name",
  SKUNumber: "SKU Number",
  priority: "Priority",
  currentStock: "Current Stock",
  restockQuantity: "Restock Quantity",
  supplier: "Supplier",
  orderDate: "Order Date",
  expectedDelivery: "Expected Delivery",
  comment: "Comment",
  status: "Restock Status",
};

const DataTable = ({
  selectedFilters,
  data = [],
  isLoading,
  error,
  selectedItems = [],
  handleSelectAll,
  handleSelectItem,
  onStatusChange,
  onPriorityChange,
  handleEditItems,
  handleDeleteItems,
  // handleRestockItems, // No longer needed to handle restock via modal
  isRestockPage = false,
}) => {
  const [selectOptionsOpen, setSelectOptionsOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate
  // Ensure selectedItems is an array
  useEffect(() => {
    if (Array.isArray(selectedItems) && selectedItems.length > 0) {
      setSelectOptionsOpen(true);
    } else {
      setSelectOptionsOpen(false);
    }
  }, [selectedItems]);

  if (isLoading) {
    return <div>Loading {isRestockPage ? "restocks" : "products"}...</div>;
  }

  if (error) {
    return (
      <div>
        Error loading {isRestockPage ? "restocks" : "products"}: {error.message}
      </div>
    );
  }

  const handleSelectAllChange = (event) => {
    handleSelectAll(event);
  };

  const handleSelectItemChange = (event, itemId) => {
    handleSelectItem(event, itemId);
  };

  const closeOptionsBar = () => {
    setSelectOptionsOpen(false);
  };

  // Handle redirect to /inventory/restock when "Restock" button is clicked
  const handleRedirectToRestock = () => {
    if (isSingleItemSelected) {
      navigate("/inventory/restock");
    }
  };

  const isSingleItemSelected = selectedItems.length === 1;
  const columnMapping = isRestockPage
    ? restockColumnMapping
    : productColumnMapping;

  const filteredColumnMapping = Object.keys(columnMapping)
    .filter((key) => selectedFilters[key]) // Apply the filter based on the selected filters
    .reduce((obj, key) => {
      obj[key] = columnMapping[key];
      return obj;
    }, {});

  return (
    <div className="flex">
      <div className="flex flex-col mx-[12px]">
        <div id="selectAllItems" className="h-[60px] flex items-center">
          <input
            type="checkbox"
            className="w-[23px] h-[23px] bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[2px] focus:ring-0"
            style={{ backgroundColor: "#D9D9D9", border: "none" }}
            checked={selectedItems.length === data.length && data.length > 0}
            onChange={handleSelectAllChange}
            disabled={data.length === 0}
          />
        </div>
        {Array.isArray(data) && data.length > 0 ? (
          data.map((item) => (
            <div key={item._id} className="h-[60px] flex items-center">
              <input
                type="checkbox"
                className="w-[23px] h-[23px] bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[2px] focus:ring-0"
                style={{ backgroundColor: "#D9D9D9", border: "none" }}
                checked={selectedItems.includes(item._id)}
                onChange={(e) => handleSelectItemChange(e, item._id)}
              />
            </div>
          ))
        ) : (
          <div className="py-4 text-center text-[#4A488E] dark:text-[#EDEDFB] text-[16px]"></div>
        )}
      </div>
      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-[#FAFAFE] dark:bg-[#3A3A68] rounded-[15px] overflow-hidden">
          <thead>
            <tr className="h-[50px] text-center">
              {Object.keys(filteredColumnMapping).map((key, index) => (
                <th
                  key={key}
                  className={`py-2 px-4 text-[#4A488E] dark:text-[#EDEDFB] text-[16px] text-center font-normal border-b border-[#CAC9F1] dark:border-[#222147] ${
                    index !== Object.keys(filteredColumnMapping).length - 1
                      ? "border-r border-[#CAC9F1] dark:border-[#222147]"
                      : ""
                  }`}
                >
                  {filteredColumnMapping[key]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((item) => (
                <tr key={item._id} className="h-[50px]">
                  {Object.keys(filteredColumnMapping).map((key, index) => (
                    <td
                      key={key}
                      className={`py-2 px-4 text-[#656565] dark:text-[#EDEDFB] text-[12px] text-center border-b border-[#CAC9F1] dark:border-[#222147] ${
                        index !== Object.keys(filteredColumnMapping).length - 1
                          ? "border-r border-[#CAC9F1] dark:border-[#222147]"
                          : ""
                      }`}
                    >
                      {key === "productName" || key === "name" ? (
                        <div className="flex items-center justify-center gap-2">
                          <img
                            src={
                              isRestockPage
                                ? item.product.image || "/default-image.png"
                                : item.image || "/default-image.png"
                            }
                            alt={item[key]}
                            className="w-8 h-8 object-cover"
                          />
                          <span>{item[key]}</span>
                        </div>
                      ) : key === "status" && isRestockPage ? (
                        <div className="flex justify-center items-center">
                          <FuseSpecialDropdown
                            currentStatus={item[key]}
                            onChange={(newStatus) =>
                              onStatusChange(item._id, newStatus)
                            }
                            type="otherStatus"
                          />
                        </div>
                      ) : key === "priority" && isRestockPage ? (
                        <div className="flex justify-center items-center">
                          <FuseSpecialDropdown
                            currentStatus={item[key]}
                            onChange={(newPriority) =>
                              onPriorityChange(item._id, newPriority)
                            }
                            type="priority"
                          />
                        </div>
                      ) : key === "status" && !isRestockPage ? (
                        <div className="flex justify-center items-center">
                          <FuseSpecialDropdown
                            currentStatus={item[key]}
                            onChange={(newStatus) =>
                              onStatusChange(item._id, newStatus)
                            }
                            type="status"
                          />
                        </div>
                      ) : key === "expiryDate" ||
                        key === "uploadedDate" ||
                        key === "orderDate" ||
                        key === "expectedDelivery" ? (
                        formatDate(item[key])
                      ) : key === "unitPrice" || key === "totalPrice" ? (
                        `${item[key]} RON`
                      ) : (
                        item[key] || "N/A"
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={Object.keys(filteredColumnMapping).length}
                  className="py-4 text-center text-[#4A488E] dark:text-[#EDEDFB] text-[16px]"
                >
                  {isRestockPage ? "No restocks yet" : "No products available"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Edit/Delete/Restock options bar */}
      {selectOptionsOpen && (
        <div className="w-fit h-[93px] px-[22px] py-[13px] bg-[#CAC9F1] dark:bg-[#2B2A49] rounded-md flex-row justify-between items-center gap-2.5 inline-flex absolute bottom-[10%] left-[50%]">
          <div className="flex flex-row gap-[7px]">
            <div
              onClick={handleDeleteItems}
              className="bg-[#FAFAFE] dark:bg-[#3A3A68] h-[67px] rounded-[5px] px-[18px] flex flex-col items-center justify-center gap-[10px] cursor-pointer"
            >
              <InventoryDeleteIcon />
              <div className="text-[#7674C2] dark:text-[#EDEDFB] text-[10px] font-normal">
                Delete
              </div>
            </div>
            <div
              onClick={isSingleItemSelected ? handleEditItems : null}
              className={`${
                isSingleItemSelected
                  ? "cursor-pointer"
                  : "cursor-not-allowed opacity-50"
              } bg-[#FAFAFE] dark:bg-[#3A3A68] h-[67px] rounded-[5px] px-[18px] flex flex-col items-center justify-center gap-[10px]`}
            >
              <InventoryEditIcon />
              <div className="text-[#7674C2] dark:text-[#EDEDFB] text-[10px] font-normal">
                Edit
              </div>
            </div>

            {/* Conditionally render the Restock button */}
            {!isRestockPage && (
              <div
                onClick={isSingleItemSelected ? handleRedirectToRestock : null}
                className={`${
                  isSingleItemSelected
                    ? "cursor-pointer"
                    : "cursor-not-allowed opacity-50"
                } bg-[#FAFAFE] dark:bg-[#3A3A68] h-[67px] rounded-[5px] px-[18px] flex flex-col items-center justify-center gap-[10px]`}
              >
                <AddRestockIcon />
                <div className="text-[#7674C2] dark:text-[#EDEDFB] text-[10px] font-normal">
                  Restock
                </div>
              </div>
            )}
          </div>
          <div onClick={closeOptionsBar}>
            <InventoryCloseIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTable;
