import React, { useContext, useEffect, useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationComponent from "../NotificationComponent";
import Datepicker from "react-tailwindcss-datepicker";
import DropdownReportsProjects from "../Dropdown/DropdownReportsProjects";
import { BarChart } from "@mui/x-charts/BarChart";
import DropdownReportsUsers from "../Dropdown/DropdownReportsUsers";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ProgressBar from "./ProgressBar";
import {
  useGetTeamActivitiesQuery,
  useGetTotalTimeForAllProjectsQuery,
  useGetTotalTimeForDepartmentsQuery,
} from "../../redux/api/reports/reports";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import { PieChart } from "@mui/x-charts";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";
import { transform } from "framer-motion";
dayjs.extend(relativeTime);

const timeAgo = (dateString) => {
  // Convert the date string to a dayjs object
  return dayjs(dateString).fromNow();
};

// Function to format total seconds into a human-readable time string
const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600); // Calculate hours
  const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
  const seconds = totalSeconds % 60; // Calculate remaining seconds

  // Format time values to ensure 2 digits for minutes and seconds
  const hoursFormatted = hours.toLocaleString(); // Add commas for large numbers
  const minutesFormatted = String(minutes).padStart(2, "0");
  const secondsFormatted = String(seconds).padStart(2, "0");

  return `${hoursFormatted}:${minutesFormatted}`;
};

const ReportsDashboard = () => {
  const { data: usersData } = useGetAllUsersQuery();
  const isMobileView = useContext(ViewTypeContext); // true daca e mobil/tableta

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  // Function to handle changes in theme
  const updateTheme = () => {
    const newTheme = localStorage.getItem("theme") || "light";
    setTheme(newTheme);
  };

  useEffect(() => {
    // Update theme on component mount
    updateTheme();

    // Set up a listener to update theme on storage changes
    const handleStorageChange = (event) => {
      if (event.key === "theme") {
        updateTheme();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Handle theme updates manually within the same tab
  useEffect(() => {
    const handleDocumentClick = () => {
      updateTheme();
    };

    // Listen for clicks or other interactions to manually trigger theme updates
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const [selectedProjectIds, setSelectedProjectIds] = useState([]); // Project selected from dropdown
  const [currentUserId, setCurrentUserId] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState("Team");

  // Pagination states for each view
  const [currentPageTotalTime, setCurrentPageTotalTime] = useState(1); // For View 1
  const [currentPageTeamActivities, setCurrentPageTeamActivities] = useState(1); // For View 2
  const [itemsPerPage] = useState(100); // Items per page for pagination

  // Get the current date
  const now = new Date();

  // Get the first and last days of the current month
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Initialize state with the current month's range as ISO strings
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfMonth.toISOString(), // Convert to ISO string
    endDate: endOfMonth.toISOString(), // Convert to ISO string
  });

  // Fetch total time for all projects (View 1)
  const {
    data: totalTimeData,
    error: totalTimeError,
    isLoading: totalTimeLoading,
    refetch: refetchTotalTimeData,
  } = useGetTotalTimeForAllProjectsQuery({
    projectId: selectedProjectIds, // Project filter
    userId: currentUserId, // User filter
    userType: selectedUserType, // Type of user "team" or "onlyMe"
    page: currentPageTotalTime, // Pagination
    limit: itemsPerPage, // Items per page
    startDate: selectedDate?.startDate, // Start date filter
    endDate: selectedDate?.endDate, // End date filter
  });

  // Fetch team activities (View 2)
  const {
    data: teamActivitiesData,
    error: teamActivitiesError,
    isLoading: teamActivitiesLoading,
    refetch: refetchTeamActivities,
  } = useGetTeamActivitiesQuery({
    projectId: selectedProjectIds,
    userId: currentUserId,
    userType: selectedUserType,
    startDate: selectedDate?.startDate,
    endDate: selectedDate?.endDate,
    page: currentPageTeamActivities, // Pass the current page for pagination
    limit: itemsPerPage, // Limit items per page
  });

  const [view, setView] = useState(1); // Toggle view
  const pieChartData =
    totalTimeData?.projects?.map((project, index) => ({
      id: index,
      value: (project.totalSeconds / 3600).toFixed(2),
      label: project.projectName,
    })) || [];

  // Handle date change for the datepicker
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  // Refetch data when the respective page changes
  useEffect(() => {
    if (totalTimeData) {
      refetchTotalTimeData();
    }
  }, [currentPageTotalTime, selectedDate]);

  useEffect(() => {
    if (teamActivitiesData) {
      refetchTeamActivities();
    }
  }, [currentPageTeamActivities, selectedDate]);

  // Total time is fetched from the backend, no need to recalculate it
  const formattedTotalTime = formatTime(totalTimeData?.combinedTotalSeconds);

  // Calculate total pages for each view
  const totalPagesTotalTime = totalTimeData
    ? Math.ceil(totalTimeData?.pagination.totalProjects / itemsPerPage)
    : 1;

  const totalPagesTeamActivities = teamActivitiesData
    ? Math.ceil(teamActivitiesData.totalItems / itemsPerPage)
    : 1;

  // Index calculations for View 1
  const indexOfLastItemTotalTime = currentPageTotalTime * itemsPerPage;
  const indexOfFirstItemTotalTime = indexOfLastItemTotalTime - itemsPerPage;

  // Index calculations for View 2
  const indexOfLastItemTeamActivities =
    currentPageTeamActivities * itemsPerPage;
  const indexOfFirstItemTeamActivities =
    indexOfLastItemTeamActivities - itemsPerPage;

  // Handle pagination view change
  const handleViewChange = (direction) => {
    if (direction === "next") {
      setView((prevView) => {
        const newView = prevView === 1 ? 2 : 1;
        return newView;
      });
    } else if (direction === "prev") {
      setView((prevView) => {
        const newView = prevView === 2 ? 1 : 2;
        return newView;
      });
    }
  };

  const paginateTotalTime = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPagesTotalTime) {
      setCurrentPageTotalTime(pageNumber);
    }
  };

  const paginateTeamActivities = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPagesTeamActivities) {
      setCurrentPageTeamActivities(pageNumber);
    }
  };

  const matchedTeamActivities = teamActivitiesData?.teamActivities?.filter(
    (t) => t?.member === currentUserId
  );

  // Assuming teamActivitiesData.teamActivities is available
  const teamActivitiesToShow = teamActivitiesData?.teamActivities;
  let filteredActivities;
  if (teamActivitiesToShow) {
    // Create a map to keep track of the most recent activity for each member
    const latestActivitiesMap = new Map();

    teamActivitiesToShow.forEach((activity) => {
      // Parse the lastUpdate date
      const activityDate = new Date(activity.lastUpdate);

      // Check if this member is already in the map
      if (!latestActivitiesMap.has(activity.member)) {
        // If not, add this activity
        latestActivitiesMap.set(activity.member, activity);
      } else {
        // Compare dates and keep the most recent one
        const currentStoredActivity = latestActivitiesMap.get(activity.member);
        const currentStoredDate = new Date(currentStoredActivity.lastUpdate);

        if (activityDate > currentStoredDate) {
          // Update with the more recent activity
          latestActivitiesMap.set(activity.member, activity);
        }
      }
    });

    // Convert the map values back into an array
    filteredActivities = Array.from(latestActivitiesMap.values());
  }

  function getTotalTimeForUser(userId, teamActivities) {
    let totalSeconds = 0;

    teamActivities.forEach((activity) => {
      if (activity.member === userId) {
        // Extract the time tracked string and split it into hours, minutes, and seconds
        let timeParts = activity.timeTracked.split(" ");
        let hours = 0,
          minutes = 0,
          seconds = 0;

        // Parse hours, minutes, and seconds
        timeParts.forEach((part) => {
          if (part.includes("h")) hours = parseInt(part);
          if (part.includes("m")) minutes = parseInt(part);
          if (part.includes("s")) seconds = parseInt(part);
        });

        // Convert the tracked time to seconds
        totalSeconds += hours * 3600 + minutes * 60 + seconds;
      }
    });

    // Convert the total time back into hours, minutes, and seconds
    let totalHours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let totalMinutes = Math.floor(totalSeconds / 60);
    let remainingSeconds = totalSeconds % 60;

    // Format the result as a string
    return `${totalHours}h ${totalMinutes}m ${remainingSeconds}s`;
  }

  const getUserDetails = (userId) => {
    const user = usersData?.users?.find((user) => user._id === userId);
    return user
      ? { name: user.name, avatar: user.avatar }
      : { name: "Unknown User", avatar: "/path/to/default/avatar.png" };
  };
  const LoadingSpinner = () => {
    return (
      <div className="w-full min-h-screen flex justify-center items-center mt-0">
        <div className="w-20 h-20 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  };

  if (!totalTimeData) {
    return <LoadingSpinner />;
  }
  return (
    <div className="w-full h-screen pb-48 xl:pb:0 pl-0 p-[30px] dark:bg-[#2B2A49] overflow-scroll scrolling-touch">
      <div className="flex justify-between pb-[25px]">
        <ul className="flex items-center">
          <Breadcrumbs
            aria-label="breadcrumb"
            className="w-[380px] text-[#7674C2] dark:text-[#EDEDFB]"
          >
            <Link
              underline="hover"
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              to="/dashboard"
            >
              Fuse Digital
            </Link>
            <Link
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              underline="hover"
              to={`/reports/dashboard-reports`}
            >
              Reports / <b> Dashboard reports</b>
            </Link>
          </Breadcrumbs>
        </ul>
        <NotificationComponent />
      </div>
      <div className="w-full pb-[45px]">
        <h1 className="dark:text-[#EDEDFB] text-[#4A488E] text-[30px]">
          Dashboard reports
        </h1>
      </div>
      <div className="w-full flex justify-end pb-[35px]">
        <div className=" flex items-center gap-[24px]">
          {/* Pass setSelectedDepartment to DropdownReportsProjects */}
          <DropdownReportsProjects
            setSelectedProjectIds={setSelectedProjectIds} // Pass the correct state setter function
            selectedProjectIds={selectedProjectIds} // Pass the correct state variable
            projectsAll={totalTimeData?.projects.map((p) => p) || []}
          />

          {/* Users Dropdown */}
          <DropdownReportsUsers
            setSelectedUserType={setSelectedUserType}
            selectedUserType={selectedUserType}
            setCurrentUserId={setCurrentUserId}
          />
          <Datepicker
            showShortcuts={true}
            value={selectedDate}
            onChange={handleDateChange}
            asSingle={false} // Ensures range selection
            displayFormat={"YYYY-MM-DD"}
            /*   className={
              isMobileView
                ? "h-[55px] bg-[#E8E8FF] min-w-[500px] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg"
                : "h-[55px] bg-[#E8E8FF] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg"
            } */
            inputClassName={
              isMobileView
                ? "h-[55px] dark:bg-[#1e293b] min-w-[250px] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg"
                : "h-[55px]  dark:bg-[#1e293b] w-[250px] border-none rounded-[52px] placeholder-[#979BE2] text-[#4A488E] text-lg cursor-pointer"
            }
          />
          <div className="flex gap-[24px]">
            <span
              onClick={() => handleViewChange("prev")}
              className={`cursor-pointer ${
                view === 1 ? "text-gray-500" : "text-white"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
              >
                <path
                  d="M8.33189 1.43332C8.40056 1.35222 8.45425 1.25682 8.48988 1.15256C8.52552 1.0483 8.54241 0.937228 8.53958 0.825687C8.53675 0.714146 8.51427 0.604318 8.47341 0.502475C8.43255 0.400632 8.37411 0.308767 8.30144 0.232127C8.22877 0.155486 8.14328 0.0955708 8.04986 0.055801C7.95644 0.0160311 7.85691 -0.00281426 7.75697 0.000341063C7.65702 0.00349639 7.55861 0.0285905 7.46735 0.0741906C7.3761 0.119791 7.29378 0.185004 7.22511 0.266107L0.754941 7.91162C0.621323 8.06934 0.546874 8.27817 0.546874 8.49523C0.546873 8.71228 0.621323 8.92111 0.754941 9.07883L7.2251 16.7252C7.29333 16.8081 7.37562 16.875 7.46721 16.9221C7.55881 16.9692 7.65787 16.9955 7.75865 16.9995C7.85942 17.0035 7.95991 16.9851 8.05427 16.9454C8.14862 16.9057 8.23497 16.8454 8.30828 16.7681C8.3816 16.6909 8.44043 16.5981 8.48136 16.4953C8.52229 16.3924 8.54449 16.2815 8.54669 16.169C8.54889 16.0565 8.53103 15.9446 8.49415 15.8399C8.45728 15.7351 8.40212 15.6396 8.33188 15.5588L2.35497 8.49523L8.33189 1.43332Z"
                  fill="white"
                />
              </svg>
            </span>
            <span
              onClick={() => handleViewChange("next")}
              className={`cursor-pointer ${
                view === 2 ? "text-gray-500" : "text-white"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="17"
                viewBox="0 0 9 17"
                fill="none"
              >
                <path
                  d="M0.761863 15.5667C0.69319 15.6478 0.639502 15.7432 0.603866 15.8474C0.568231 15.9517 0.551344 16.0628 0.554171 16.1743C0.556999 16.2859 0.579484 16.3957 0.620345 16.4975C0.661205 16.5994 0.719639 16.6912 0.792311 16.7679C0.864983 16.8445 0.95047 16.9044 1.04389 16.9442C1.13731 16.984 1.23684 17.0028 1.33678 16.9997C1.43673 16.9965 1.53514 16.9714 1.6264 16.9258C1.71765 16.8802 1.79997 16.815 1.86864 16.7339L8.33881 9.08838C8.47242 8.93066 8.54687 8.72183 8.54687 8.50477C8.54687 8.28772 8.47242 8.07889 8.33881 7.92117L1.86864 0.274804C1.80042 0.191926 1.71812 0.125001 1.62653 0.0779177C1.53494 0.0308348 1.43588 0.00453057 1.3351 0.000534688C1.23432 -0.0034612 1.13384 0.0149314 1.03948 0.0546424C0.945125 0.0943515 0.85878 0.154589 0.785461 0.231856C0.712142 0.309121 0.653311 0.401875 0.612384 0.504731C0.571457 0.607587 0.549252 0.718491 0.547056 0.831006C0.54486 0.943518 0.562718 1.0554 0.599594 1.16014C0.636469 1.26489 0.691627 1.36041 0.761862 1.44117L6.73877 8.50477L0.761863 15.5667Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      {view === 2 ? (
        <>
          <div className="w-full dark:bg-[#3A3A68] bg-[#e7e7f9] rounded-[21px] p-8">
            <h2 className="dark:text-white text-[#4A488E] font-semibold text-lg pb-[21px] border-b border-[#CAC9F1] dark:border-[#e2e2ff52]">
              Team activities
            </h2>
            <table className="w-full text-left text-sm text-white">
              <thead className="dark:bg-[#3A3A68] text-[#AFAFDD] ">
                <tr className="">
                  <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                    Member
                  </th>
                  <th className="py-[20px] text-[20px] text-[#4A488E] dark:text-[#DFDFDF]">
                    Latest activity
                  </th>
                  <th className="py-[20px]"></th>
                  <th className="py-[20px] "></th>

                  <th className="py-[20px] flex justify-end text-[20px] dark:text-[#DFDFDF] text-[#4A488E]">
                    Total tracked
                  </th>
                </tr>
              </thead>
              <tbody className="dark:bg-[#3A3A68] bg-[#e7e7f9] text-white">
                {currentUserId
                  ? matchedTeamActivities
                      ?.slice(
                        indexOfFirstItemTeamActivities,
                        indexOfLastItemTeamActivities
                      )
                      .map((item, index) => (
                        <tr
                          key={index}
                          className="border-t dark:border-[#e2e2ff52] border-[#CAC9F1] py-[25px]"
                        >
                          <td className="py-8 flex items-center gap-4 dark:text-[#DFDFDF] text-[#4A488E] text-[18px]">
                            <div
                              className="w-[66px] h-[66px] rounded-full mr-[17px]"
                              style={{
                                backgroundImage: `url(${
                                  getUserDetails(item?.member)?.avatar?.url
                                })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            ></div>

                            {getUserDetails(item?.member)?.name}
                          </td>
                          <td className="py-4">
                            <div className="flex flex-col gap-[15px]">
                              <div className="dark:text-[#DFDFDF]  text-[#4A4888E] text-[18px]">
                                {item?.ticketName}
                              </div>
                              <div className="text-sm dark:text-[#DFDFDF]  text-[#4A488E] text-[18px]">
                                • {item?.department} -{" "}
                                <span className="dark:text-[#dfdfdf94]  text-[#8D8BDB]">
                                  {item?.projectTitle}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="py-4 ">
                            <div className="h-full flex flex-row gap-2 items-center">
                              <span className="text-[#8D8BDB] dark:text-white">
                                {item?.timeTracked}
                              </span>{" "}
                              {item?.status === "In Progress" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="17"
                                  viewBox="0 0 18 17"
                                  fill="none"
                                >
                                  <circle
                                    cx="8.75"
                                    cy="8.5"
                                    r="8"
                                    stroke="#DDDDDD"
                                    strokeOpacity="0.6"
                                  />
                                  <circle
                                    cx="8.75"
                                    cy="8.5"
                                    r="4.25"
                                    fill="#D90000"
                                  />
                                </svg>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td className="py-4">
                            <div className="text-[#4A488E] dark:text-[#DFDFDF] text-sm">
                              {item?.status === "In Progress"
                                ? "In Progress"
                                : timeAgo(item?.lastUpdate)}
                            </div>
                          </td>
                          <td className="py-4 flex justify-end mb-[20px]">
                            <div className="text-[#4A488E] dark:text-[#DFDFDF] font-medium">
                              {item?.totalProjectTime}
                            </div>
                          </td>
                        </tr>
                      ))
                  : filteredActivities?.map((item, index) => (
                      <tr
                        key={index}
                        className="border-t dark:border-[#e2e2ff52] border-[#CAC9F1] py-[25px]"
                      >
                        <td className="py-8 flex items-center gap-4 dark:text-[#DFDFDF] text-[#4A488E] text-[18px]">
                          <div
                            className="w-[66px] h-[66px] rounded-full mr-[17px]"
                            style={{
                              backgroundImage: `url(${
                                getUserDetails(item?.member)?.avatar?.url
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          ></div>

                          {getUserDetails(item?.member)?.name}
                        </td>
                        <td className="py-4">
                          <div className="flex flex-col gap-[15px]">
                            <div className="dark:text-[#DFDFDF]  text-[#4A488E] text-[18px]">
                              {item?.ticketName}
                            </div>
                            <div className="text-sm dark:text-[#DFDFDF]  text-[#4A488E] text-[18px]">
                              • {item?.department} -{" "}
                              <span className="dark:text-[#dfdfdf94]  text-[#8D8BDB]">
                                {item?.projectTitle}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="py-4">
                          <div className="h-full flex flex-row gap-2 items-center">
                            <span className="text-[#8D8BDB] dark:text-white">
                              {item?.timeTracked}
                            </span>{" "}
                            {item?.status === "In Progress" ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="17"
                                viewBox="0 0 18 17"
                                fill="none"
                              >
                                <circle
                                  cx="8.75"
                                  cy="8.5"
                                  r="8"
                                  stroke="#DDDDDD"
                                  strokeOpacity="0.6"
                                />
                                <circle
                                  cx="8.75"
                                  cy="8.5"
                                  r="4.25"
                                  fill="#D90000"
                                />
                              </svg>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                        <td className="py-4">
                          <div className="text-[#4A488E] dark:text-[#DFDFDF] text-sm">
                            {item?.status === "In Progress"
                              ? "In Progress"
                              : timeAgo(item?.lastUpdate)}
                          </div>
                        </td>
                        <td className="py-4 text-end">
                          <div className="text-[#4A488E] dark:text-[#DFDFDF] font-medium">
                            {getTotalTimeForUser(
                              item?.member,
                              teamActivitiesData?.teamActivities
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="flex w-full justify-end gap-[12px] items-center mt-4 text-[#AFAFDD]">
            {/* <span>
              {indexOfFirstItemTeamActivities + 1} -{" "}
              {Math.min(
                indexOfLastItemTeamActivities,
                teamActivitiesData?.totalItems
              )}{" "}
              of {teamActivitiesData?.totalItems}
            </span> */}
            {/* <div className="flex items-center gap-[7px]">
              <button
                onClick={() =>
                  paginateTeamActivities(currentPageTeamActivities - 1)
                }
                disabled={currentPageTeamActivities === 1} // Disable if already on the first page
                className={`${
                  currentPageTeamActivities === 1
                    ? "text-gray-500 cursor-not-allowed"
                    : "dark:text-white text-[#626199]"
                } focus:outline-none`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="17"
                  viewBox="0 0 9 17"
                  fill="none"
                >
                  <path
                    d="M8.33189 1.43332C8.40056 1.35222 8.45425 1.25682 8.48988 1.15256C8.52552 1.0483 8.54241 0.937228 8.53958 0.825687C8.53675 0.714146 8.51427 0.604318 8.47341 0.502475C8.43255 0.400632 8.37411 0.308767 8.30144 0.232127C8.22877 0.155486 8.14328 0.0955708 8.04986 0.055801C7.95644 0.0160311 7.85691 -0.00281426 7.75697 0.000341063C7.65702 0.00349639 7.55861 0.0285905 7.46735 0.0741906C7.3761 0.119791 7.29378 0.185004 7.22511 0.266107L0.754941 7.91162C0.621323 8.06934 0.546874 8.27817 0.546874 8.49523C0.546873 8.71228 0.621323 8.92111 0.754941 9.07883L7.2251 16.7252C7.29333 16.8081 7.37562 16.875 7.46721 16.9221C7.55881 16.9692 7.65787 16.9955 7.75865 16.9995C7.85942 17.0035 7.95991 16.9851 8.05427 16.9454C8.14862 16.9057 8.23497 16.8454 8.30828 16.7681C8.3816 16.6909 8.44043 16.5981 8.48136 16.4953C8.52229 16.3924 8.54449 16.2815 8.54669 16.169C8.54889 16.0565 8.53103 15.9446 8.49415 15.8399C8.45728 15.7351 8.40212 15.6396 8.33188 15.5588L2.35497 8.49523L8.33189 1.43332Z"
                    fill="currentColor"
                  />
                </svg>
              </button>

              <button
                onClick={() =>
                  paginateTeamActivities(currentPageTeamActivities + 1)
                }
                disabled={
                  currentPageTeamActivities === totalPagesTeamActivities
                } // Disable if on the last page
                className={`${
                  currentPageTeamActivities === totalPagesTeamActivities
                    ? "text-gray-500 cursor-not-allowed"
                    : "dark:text-white text-[#626199]"
                } focus:outline-none`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="17"
                  viewBox="0 0 9 17"
                  fill="none"
                >
                  <path
                    d="M0.761863 15.5667C0.69319 15.6478 0.639502 15.7432 0.603866 15.8474C0.568231 15.9517 0.551344 16.0628 0.554171 16.1743C0.556999 16.2859 0.579484 16.3957 0.620345 16.4975C0.661205 16.5994 0.719639 16.6912 0.792311 16.7679C0.864983 16.8445 0.95047 16.9044 1.04389 16.9442C1.13731 16.984 1.23684 17.0028 1.33678 16.9997C1.43673 16.9965 1.53514 16.9714 1.6264 16.9258C1.71765 16.8802 1.79997 16.815 1.86864 16.7339L8.33881 9.08838C8.47242 8.93066 8.54687 8.72183 8.54687 8.50477C8.54687 8.28772 8.47242 8.07889 8.33881 7.92117L1.86864 0.274804C1.80042 0.191926 1.71812 0.125001 1.62653 0.0779177C1.53494 0.0308348 1.43588 0.00453057 1.3351 0.000534688C1.23432 -0.0034612 1.13384 0.0149314 1.03948 0.0546424C0.945125 0.0943515 0.85878 0.154589 0.785461 0.231856C0.712142 0.309121 0.653311 0.401875 0.612384 0.504731C0.571457 0.607587 0.549252 0.718491 0.547056 0.831006C0.54486 0.943518 0.562718 1.0554 0.599594 1.16014C0.636469 1.26489 0.691627 1.36041 0.761862 1.44117L6.73877 8.50477L0.761863 15.5667Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div> */}
          </div>
        </>
      ) : (
        <>
          <div className="mb-[12px]">
            <div className="w-full py-[33px] px-[28px] dark:bg-[#3A3A68] bg-[#E7E7F9] rounded-[21px] flex gap-[26px]">
              <div className="w-[32%] dark:bg-[#4D4D77] bg-[#FAFAFE] py-[59px]   flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
                <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                  Total time
                </p>
                <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px]">
                  {formattedTotalTime}
                </p>
              </div>
              <div className="w-[32%] dark:bg-[#4D4D77] py-[59px] bg-[#FAFAFE]    flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
                <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                  Top project
                </p>
                <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px] ">
                  {totalTimeData?.maxProject?.projectName}
                </p>
              </div>
              <div className="w-[32%] dark:bg-[#4D4D77] py-[59px] bg-[#FAFAFE]   flex justify-center items-center rounded-[21px] flex-col gap-[32px]">
                <p className="dark:text-[#AFAFDD] text-[#4A488E] text-[23px] font-medium">
                  Top client
                </p>
                <p className="dark:text-[#EDEDFB] text-[#8D8BDB] text-[30px]">
                  {totalTimeData?.maxProject?.client}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full py-[30px] px-[28px] bg-[#E7E7F9] dark:bg-[#3A3A68] rounded-[21px] flex gap-[26px]">
            <div className="min-w-[400px]">
              <PieChart
                className="relative"
                series={[
                  {
                    data: pieChartData,
                    innerRadius: 60,
                    outerRadius: 130,
                    paddingAngle: 0,
                    cornerRadius: 0,
                    border: "none",
                    highlightScope: { fade: "global", highlight: "item" },
                    faded: {
                      innerRadius: 130,
                      additionalRadius: -50,
                      color: "gray",
                    },
                  },
                ]}
                width={400}
                height={300}
                slotProps={{ legend: { hidden: true } }}
              />
              <p
                className={`dark:text-[#EDEDFB] text-[#8D8DB] text-[20px] relative ${
                  isMobileView ? "left-[31.4%]" : "left-[32%]"
                } w-fit top-[-165px]`}
              >
                {formattedTotalTime}
              </p>
            </div>
            <div className="w-full flex flex-col gap-[5px]">
              {totalTimeData?.projects
                .filter((project) => project.totalSeconds > 0)
                .map((project) => (
                  <ProgressBar
                    key={project.projectId}
                    project={project}
                    totalTime={totalTimeData.combinedTotalSeconds}
                  />
                ))}
            </div>
          </div>
          <div className="flex items-center justify-end gap-[12px] mt-4 px-4 py-2 dark:bg-[#2B2A49] bg-[#CAC9F1] rounded-md dark:text-white text-[#626199]">
            {/* Page numbers */}
            {/* <span>
              {indexOfFirstItemTotalTime + 1} -{" "}
              {Math.min(
                indexOfLastItemTotalTime,
                totalTimeData?.projects?.filter(
                  (project) => project.totalSeconds > 0
                ).length
              )}{" "}
              of{" "}
              {
                totalTimeData?.projects?.filter(
                  (project) => project.totalSeconds > 0
                ).length
              }
            </span> */}
            {/* <div className="flex items-center justify-center gap-[7px]">
              <button
                onClick={() => paginateTotalTime(currentPageTotalTime - 1)}
                disabled={currentPageTotalTime === 1}
                className={`${
                  currentPageTotalTime === 1
                    ? "text-gray-500"
                    : "dark:text-white text-[#626199]"
                } focus:outline-none`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="17"
                  viewBox="0 0 9 17"
                  fill="none"
                >
                  <path
                    d="M8.33189 1.43332C8.40056 1.35222 8.45425 1.25682 8.48988 1.15256C8.52552 1.0483 8.54241 0.937228 8.53958 0.825687C8.53675 0.714146 8.51427 0.604318 8.47341 0.502475C8.43255 0.400632 8.37411 0.308767 8.30144 0.232127C8.22877 0.155486 8.14328 0.0955708 8.04986 0.055801C7.95644 0.0160311 7.85691 -0.00281426 7.75697 0.000341063C7.65702 0.00349639 7.55861 0.0285905 7.46735 0.0741906C7.3761 0.119791 7.29378 0.185004 7.22511 0.266107L0.754941 7.91162C0.621323 8.06934 0.546874 8.27817 0.546874 8.49523C0.546873 8.71228 0.621323 8.92111 0.754941 9.07883L7.2251 16.7252C7.29333 16.8081 7.37562 16.875 7.46721 16.9221C7.55881 16.9692 7.65787 16.9955 7.75865 16.9995C7.85942 17.0035 7.95991 16.9851 8.05427 16.9454C8.14862 16.9057 8.23497 16.8454 8.30828 16.7681C8.3816 16.6909 8.44043 16.5981 8.48136 16.4953C8.52229 16.3924 8.54449 16.2815 8.54669 16.169C8.54889 16.0565 8.53103 15.9446 8.49415 15.8399C8.45728 15.7351 8.40212 15.6396 8.33188 15.5588L2.35497 8.49523L8.33189 1.43332Z"
                    fill="currentColor"
                  />
                </svg>
              </button>

              <button
                onClick={() => paginateTotalTime(currentPageTotalTime + 1)}
                disabled={currentPageTotalTime === totalPagesTotalTime}
                className={`${
                  currentPageTotalTime === totalPagesTotalTime
                    ? "text-gray-500"
                    : "dark:text-white text-[#626199]"
                } focus:outline-none`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="17"
                  viewBox="0 0 9 17"
                  fill="none"
                >
                  <path
                    d="M0.761863 15.5667C0.69319 15.6478 0.639502 15.7432 0.603866 15.8474C0.568231 15.9517 0.551344 16.0628 0.554171 16.1743C0.556999 16.2859 0.579484 16.3957 0.620345 16.4975C0.661205 16.5994 0.719639 16.6912 0.792311 16.7679C0.864983 16.8445 0.95047 16.9044 1.04389 16.9442C1.13731 16.984 1.23684 17.0028 1.33678 16.9997C1.43673 16.9965 1.53514 16.9714 1.6264 16.9258C1.71765 16.8802 1.79997 16.815 1.86864 16.7339L8.33881 9.08838C8.47242 8.93066 8.54687 8.72183 8.54687 8.50477C8.54687 8.28772 8.47242 8.07889 8.33881 7.92117L1.86864 0.274804C1.80042 0.191926 1.71812 0.125001 1.62653 0.0779177C1.53494 0.0308348 1.43588 0.00453057 1.3351 0.000534688C1.23432 -0.0034612 1.13384 0.0149314 1.03948 0.0546424C0.945125 0.0943515 0.85878 0.154589 0.785461 0.231856C0.712142 0.309121 0.653311 0.401875 0.612384 0.504731C0.571457 0.607587 0.549252 0.718491 0.547056 0.831006C0.54486 0.943518 0.562718 1.0554 0.599594 1.16014C0.636469 1.26489 0.691627 1.36041 0.761862 1.44117L6.73877 8.50477L0.761863 15.5667Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default ReportsDashboard;
