import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Divider, Button, Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField } from '@mui/material';
import TagInput from './TagInput';
import TextInput from './TextInput';

const AccountForm = ({ open, onClose, onSubmit, usersData, dealsData, initialData }) => {
  const [newAccount, setNewAccount] = useState(initialData);
  const [serviceInputValues, setServiceInputValues] = useState({});

  const handleNewAccountChange = (field, value) => {
    setNewAccount((prevAccount) => ({
      ...prevAccount,
      [field]: value,
    }));
  };

  const handleAddTag = (type, value) => {
    if (value.trim() !== '') {
      setNewAccount((prevAccount) => ({
        ...prevAccount,
        [type]: [...prevAccount[type], value.trim()],
      }));
    }
  };

  const handleDeleteTag = (type, tagToDelete) => {
    setNewAccount((prevAccount) => ({
      ...prevAccount,
      [type]: prevAccount[type].filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleServiceInputChange = (service, value) => {
    setServiceInputValues((prevValues) => ({
      ...prevValues,
      [service]: value,
    }));
  };

  const handleAddServiceTag = (service, value) => {
    if (value.trim() !== '') {
      setNewAccount((prevAccount) => ({
        ...prevAccount,
        services: {
          ...prevAccount.services,
          [service]: [...(prevAccount.services[service] || []), value.trim()],
        },
      }));
      setServiceInputValues((prevValues) => ({
        ...prevValues,
        [service]: '',
      }));
    }
  };

  const handleDeleteServiceTag = (service, tagToDelete) => {
    setNewAccount((prevAccount) => ({
      ...prevAccount,
      services: {
        ...prevAccount.services,
        [service]: prevAccount.services[service].filter((tag) => tag !== tagToDelete),
      },
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl" PaperProps={{ style: { backgroundColor: '#d9d9d9' } }}>
      <DialogTitle>Add New Account</DialogTitle>
      <DialogContent>
        <div className="flex flex-row gap-[57px]">
          <div className="flex flex-col w-[45%] gap-[15px]">
            <TextInput label="Client name" value={newAccount.nameComp} onChange={(value) => handleNewAccountChange('nameComp', value)} />
            <FormControl className="w-[300px] bg-[#A3A3A3] rounded-[4px]">
              <InputLabel>Deal</InputLabel>
              <Select value={newAccount.deal} onChange={(e) => handleNewAccountChange('deal', e.target.value)}>
                {dealsData?.deals.map((deal) => (
                  <MenuItem key={deal._id} value={deal._id}>
                    {deal.dealTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-col w-[45%] gap-[15px]">
            <Autocomplete
              options={usersData?.users || []}
              getOptionLabel={(option) => option.name || ''}
              className="bg-[#A3A3A3] rounded-[4px] w-[300px]"
              value={usersData?.users?.find((user) => user._id === newAccount.accountManager) || null}
              onChange={(event, newValue) => handleNewAccountChange('accountManager', newValue ? newValue.name : '')}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select User" />}
            />
          </div>
        </div>
        <Divider className="my-[35px]" />
        <div className="text-zinc-600 text-lg font-normal">Footprint overview</div>
        <div className="flex flex-row justify-between">
          <TagInput
            label="General results"
            tags={newAccount.generalResults}
            value={newAccount.generalResults.join(', ')}
            onChange={(value) => handleNewAccountChange('generalResults', value.split(', '))}
            onAddTag={() => handleAddTag('generalResults', newAccount.generalResults.join(', '))}
            onDeleteTag={(tag) => handleDeleteTag('generalResults', tag)}
          />
          <TagInput
            label="Activities"
            tags={newAccount.activities}
            value={newAccount.activities.join(', ')}
            onChange={(value) => handleNewAccountChange('activities', value.split(', '))}
            onAddTag={() => handleAddTag('activities', newAccount.activities.join(', '))}
            onDeleteTag={(tag) => handleDeleteTag('activities', tag)}
          />
        </div>
        <Divider />
        <div className="text-zinc-600 text-[15px] font-normal py-[38px]">Services and expectations</div>
        <div className="flex flex-row gap-[87px]">
        <div className="flex flex-col w-[87%] ">
            {Object.keys(newAccount.services).map((serviceKey) => (
              <TagInput
                key={serviceKey}
                label={serviceKey}
                tags={newAccount.services[serviceKey]}
                value={serviceInputValues[serviceKey] || ''}
                onChange={(value) => handleServiceInputChange(serviceKey, value)}
                onAddTag={() => handleAddServiceTag(serviceKey, serviceInputValues[serviceKey])}
                onDeleteTag={(tag) => handleDeleteServiceTag(serviceKey, tag)}
              />
            ))}
          </div>
          <TagInput
            label="Client Expectations"
            tags={newAccount.expectations}
            value={newAccount.expectations.join(', ')}
            onChange={(value) => handleNewAccountChange('expectations', value.split(', '))}
            onAddTag={() => handleAddTag('expectations', newAccount.expectations.join(', '))}
            onDeleteTag={(tag) => handleDeleteTag('expectations', tag)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: 'red' }} onClick={onClose}>
          Cancel
        </Button>
        <Button sx={{ color: 'green' }} onClick={() => onSubmit(newAccount)}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountForm;

