import React from 'react';

const SquareInput = ({ label, value, onChange, type = "text" }) => {
  return (
    <div className="flex flex-row items-center w-full gap-4 mb-4">
      <div
        className="text-[#4A488E] dark:text-[#EDEDFB] text-[18px] font-normal w-[150px]" // Set a fixed width for the label
        style={{ wordWrap: 'break-word' }}
      >
        {label}
      </div>
      {type === "textarea" ? (
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-[600px] h-[150px] resize-none" // Larger height and no resize
          rows={5} // Optional: sets the visible number of lines in the textarea
        />
      ) : (
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="bg-[#E9E9FF] dark:bg-[#3A3A68] border-none text-[#4A488E] dark:text-[#EDEDFB] rounded-[4px] py-[14px] px-[25px] w-[600px]" // Set a consistent width for the input
        />
      )}
    </div>
  );
};

export default SquareInput;
