import React, { useContext, useEffect, useState } from "react";
import { Breadcrumbs, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { IoFilter } from "react-icons/io5"; // Import the filter icon from react-icons
import Drawer from "../../Notification";
import { useGetMeQuery } from "../../../redux/api/userApi";
import { useGetAllUsersQuery } from "../../../redux/api/authApi";
import { UserCard } from "./UserCard";
import { motion } from "framer-motion";
import { ViewTypeContext } from "../../utils/contexts/viewTypeContext";

export const MyEmployees = () => {
  const [openRight, setOpenRight] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("alphabetical");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortedUsers, setSortedUsers] = useState([]);

  const isMobileView = useContext(ViewTypeContext);
  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
  } = useGetMeQuery();
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
    refetch,
  } = useGetAllUsersQuery();

  React.useEffect(() => {
    if (usersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetch();
      }, 5000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [usersData, refetch]);

  useEffect(() => {
    if (usersData && usersData.users) {
      sortAndFilterUsers(usersData.users, searchQuery, sortOption);
    }
  }, [usersData, searchQuery, sortOption]);

  const sortAndFilterUsers = (users, query, option) => {
    let filteredUsers = users.filter((user) =>
      user.name.toLowerCase().includes(query.toLowerCase())
    );

    if (option === "alphabetical") {
      filteredUsers.sort((a, b) => a.name.localeCompare(b.name));
    } else if (option === "creationDate") {
      filteredUsers.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    }

    setSortedUsers(filteredUsers);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setDropdownOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div
      className={
        isMobileView
          ? "pr-[10px]  ml-[-28px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
          : "pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]"
      }
    >
      <div className="w-full px-[40px] md:mb-40">
        <div className="p-4 flex justify-between">
          <ul className="flex items-center ">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-[300px] text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/HR`}
              >
                HR
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/HR/My-Employees`}
              >
                <b>My Employees</b>
              </Link>
            </Breadcrumbs>
          </ul>
          <div className="flex w-full justify-end gap-[10px]">
            <button
              className="bg-[#E8E8FF] dark:bg-[#3A3A68] p-[13px] rounded-[9px]"
              onClick={() => setOpenRight(!openRight)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="ion:notifications">
                  <path
                    className="fill-current dark:text-[#EDEDFB]"
                    id="Vector"
                    d="M17.1902 13.3324C17.1254 13.2543 17.0617 13.1762 16.9992 13.1008C16.1398 12.0613 15.6199 11.434 15.6199 8.49141C15.6199 6.96797 15.2554 5.71797 14.5371 4.78047C14.0074 4.08789 13.2914 3.5625 12.3476 3.17422C12.3355 3.16746 12.3246 3.1586 12.3156 3.14805C11.9761 2.01133 11.0472 1.25 9.99959 1.25C8.95193 1.25 8.02342 2.01133 7.68396 3.14687C7.67492 3.15706 7.66422 3.16564 7.65232 3.17227C5.44998 4.07891 4.37967 5.81836 4.37967 8.49023C4.37967 11.434 3.86052 12.0613 3.00037 13.0996C2.93787 13.175 2.8742 13.2516 2.80935 13.3313C2.64185 13.5333 2.53573 13.779 2.50354 14.0394C2.47135 14.2999 2.51444 14.5641 2.62771 14.8008C2.86873 15.3086 3.3824 15.6238 3.96873 15.6238H16.0347C16.6183 15.6238 17.1285 15.309 17.3703 14.8035C17.4841 14.5668 17.5276 14.3023 17.4956 14.0416C17.4637 13.7809 17.3577 13.5348 17.1902 13.3324ZM9.99959 18.75C10.5641 18.7495 11.1179 18.5963 11.6023 18.3066C12.0867 18.0168 12.4837 17.6014 12.7512 17.1043C12.7637 17.0805 12.77 17.0538 12.7692 17.0269C12.7685 16.9999 12.7607 16.9736 12.7468 16.9506C12.7329 16.9275 12.7133 16.9084 12.6898 16.8952C12.6663 16.8819 12.6398 16.875 12.6129 16.875H7.38709C7.36011 16.8749 7.33356 16.8818 7.31003 16.895C7.2865 16.9082 7.2668 16.9273 7.25284 16.9504C7.23887 16.9735 7.23113 16.9998 7.23035 17.0268C7.22958 17.0537 7.2358 17.0804 7.24842 17.1043C7.51581 17.6013 7.91273 18.0167 8.39709 18.3065C8.88145 18.5962 9.43519 18.7495 9.99959 18.75Z"
                    fill="#4A488E"
                  />
                </g>
              </svg>
            </button>
            <button className="bg-[#E8E8FF] dark:bg-[#3A3A68] p-[13px] rounded-[9px]">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="material-symbols:settings">
                  <path
                    className="fill-current text-[#EDEDFB]"
                    id="Vector"
                    d="M7.70833 18.3333L7.375 15.6667C7.19444 15.5972 7.02444 15.5139 6.865 15.4167C6.70556 15.3194 6.54917 15.2153 6.39583 15.1042L3.91667 16.1458L1.625 12.1875L3.77083 10.5625C3.75694 10.4653 3.75 10.3717 3.75 10.2817V9.71916C3.75 9.6286 3.75694 9.53471 3.77083 9.43749L1.625 7.81249L3.91667 3.85416L6.39583 4.89582C6.54861 4.78471 6.70833 4.68055 6.875 4.58332C7.04167 4.4861 7.20833 4.40277 7.375 4.33332L7.70833 1.66666H12.2917L12.625 4.33332C12.8056 4.40277 12.9758 4.4861 13.1358 4.58332C13.2958 4.68055 13.4519 4.78471 13.6042 4.89582L16.0833 3.85416L18.375 7.81249L16.2292 9.43749C16.2431 9.53471 16.25 9.6286 16.25 9.71916V10.2808C16.25 10.3714 16.2361 10.4653 16.2083 10.5625L18.3542 12.1875L16.0625 16.1458L13.6042 15.1042C13.4514 15.2153 13.2917 15.3194 13.125 15.4167C12.9583 15.5139 12.7917 15.5972 12.625 15.6667L12.2917 18.3333H7.70833ZM10.0417 12.9167C10.8472 12.9167 11.5347 12.6319 12.1042 12.0625C12.6736 11.493 12.9583 10.8055 12.9583 9.99999C12.9583 9.19443 12.6736 8.50693 12.1042 7.93749C11.5347 7.36804 10.8472 7.08332 10.0417 7.08332C9.22222 7.08332 8.53111 7.36804 7.96833 7.93749C7.40556 8.50693 7.12444 9.19443 7.125 9.99999C7.12556 10.8055 7.40694 11.493 7.96917 12.0625C8.53139 12.6319 9.22222 12.9167 10.0417 12.9167Z"
                    fill="#4A488E"
                  />
                </g>
              </svg>
            </button>
            <Drawer open={openRight} side="right" setOpen={setOpenRight} />
          </div>
        </div>
        <p className="text-[30px] text-[#4A488E] dark:text-[#EDEDFB] mt-[39px] mb-[34px] ml-[20px]">
          My Employees
        </p>
        <div className="flex justify-between items-center mb-[20px] mr-[40px]">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              className="w-[411px] h-[55px] bg-[#E7E7F9] border-none rounded-[52px] p-5 relative placeholder-[#979BE2] dark:placeholder-[#8C8CB0] dark:text-[#EDEDFB] dark:bg-[#33335A]"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <img
              src="/images/search.svg"
              alt="Search Icon"
              className="absolute left-[370px] top-[27px] transform -translate-y-1/2"
            />
          </div>
          <div className="relative">
            <Tooltip title="Sort users">
              <button
                className="flex items-center justify-center text-gray-600 dark:text-white"
                onClick={toggleDropdown}
              >
                <IoFilter size={24} />
              </button>
            </Tooltip>
            {dropdownOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute right-0 mt-2 w-48 bg-white dark:bg-[#2B2A49] shadow-lg rounded-md z-10"
              >
                <ul>
                  <li
                    className={`p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 ${
                      sortOption === "alphabetical"
                        ? "font-bold text-gray-800 dark:text-white"
                        : ""
                    }`}
                    onClick={() => handleSortChange("alphabetical")}
                  >
                    Alphabetically
                  </li>
                  <li
                    className={`p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 ${
                      sortOption === "creationDate"
                        ? "font-bold text-gray-800 dark:text-white"
                        : ""
                    }`}
                    onClick={() => handleSortChange("creationDate")}
                  >
                    Creation Date
                  </li>
                </ul>
              </motion.div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-wrap gap-[20px] mt-[20px] ml-[20px]">
          {sortedUsers.map((u) => (
            <UserCard user={u} key={u._id} />
          ))}
        </div>
      </div>
    </div>
  );
};
