import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie"; // Import js-cookie
import backend_URL from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const commentsApi = createApi({
  reducerPath: "commentsApi",
  baseQuery,
  endpoints: (builder) => ({
    postComment: builder.mutation({
      query: (task) => ({
        url: `/comments`,
        method: "PUT",
        body: task,
      }),
    }),
  }),
});

export const { usePostCommentMutation } = commentsApi;
