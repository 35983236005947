import React from "react";

export const FilterTableIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
  <path d="M1.625 6.5V4.75C1.625 4.28587 1.80937 3.84075 2.13756 3.51256C2.46575 3.18437 2.91087 3 3.375 3H15.625C16.0891 3 16.5342 3.18437 16.8624 3.51256C17.1906 3.84075 17.375 4.28587 17.375 4.75V6.5M1.625 6.5V11.75M1.625 6.5H6.875M17.375 6.5V11.75M17.375 6.5H6.875M1.625 11.75V15.25C1.625 15.7141 1.80937 16.1592 2.13756 16.4874C2.46575 16.8156 2.91087 17 3.375 17H6.875M1.625 11.75H6.875M6.875 6.5V11.75M17.375 11.75V15.25C17.375 15.7141 17.1906 16.1592 16.8624 16.4874C16.5342 16.8156 16.0891 17 15.625 17H6.875M17.375 11.75H6.875M6.875 17V11.75M12.125 6.5V17" stroke="#E0E0FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export const FilterIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M17.7077 10.4983H7.41185M3.77768 10.4983H2.29102M3.77768 10.4983C3.77768 10.0165 3.96908 9.55439 4.30977 9.2137C4.65046 8.87301 5.11254 8.68161 5.59435 8.68161C6.07616 8.68161 6.53824 8.87301 6.87893 9.2137C7.21962 9.55439 7.41102 10.0165 7.41102 10.4983C7.41102 10.9801 7.21962 11.4422 6.87893 11.7829C6.53824 12.1235 6.07616 12.3149 5.59435 12.3149C5.11254 12.3149 4.65046 12.1235 4.30977 11.7829C3.96908 11.4422 3.77768 10.9801 3.77768 10.4983ZM17.7077 16.0041H12.9177M12.9177 16.0041C12.9177 16.486 12.7258 16.9486 12.3851 17.2894C12.0443 17.6302 11.5821 17.8216 11.1002 17.8216C10.6184 17.8216 10.1563 17.6294 9.81561 17.2887C9.47491 16.948 9.28352 16.4859 9.28352 16.0041M12.9177 16.0041C12.9177 15.5222 12.7258 15.0604 12.3851 14.7197C12.0443 14.3789 11.5821 14.1874 11.1002 14.1874C10.6184 14.1874 10.1563 14.3788 9.81561 14.7195C9.47491 15.0602 9.28352 15.5223 9.28352 16.0041M9.28352 16.0041H2.29102M17.7077 4.99245H15.1202M11.486 4.99245H2.29102M11.486 4.99245C11.486 4.51064 11.6774 4.04856 12.0181 3.70787C12.3588 3.36718 12.8209 3.17578 13.3027 3.17578C13.5413 3.17578 13.7775 3.22277 13.9979 3.31407C14.2183 3.40536 14.4186 3.53918 14.5873 3.70787C14.756 3.87656 14.8898 4.07683 14.9811 4.29724C15.0724 4.51765 15.1193 4.75388 15.1193 4.99245C15.1193 5.23102 15.0724 5.46725 14.9811 5.68766C14.8898 5.90806 14.756 6.10833 14.5873 6.27702C14.4186 6.44572 14.2183 6.57953 13.9979 6.67083C13.7775 6.76213 13.5413 6.80911 13.3027 6.80911C12.8209 6.80911 12.3588 6.61772 12.0181 6.27702C11.6774 5.93633 11.486 5.47426 11.486 4.99245Z" stroke="#E0E0FF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>
  );

  export const SmallArrowSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clipPath="url(#clip0_252_1223)">
      <path
        d="M8.80546 11.9043C8.90624 11.9043 9.00938 11.8645 9.08672 11.7871C9.24141 11.6324 9.24141 11.3793 9.08672 11.2246L3.79219 5.93008L9.00938 0.71289C9.16406 0.558202 9.16406 0.305078 9.00938 0.15039C8.85469 -0.00429814 8.60156 -0.00429814 8.44688 0.15039L2.94609 5.64883C2.79141 5.80352 2.79141 6.05664 2.94609 6.21133L8.52187 11.7871C8.60156 11.8668 8.70233 11.9043 8.80546 11.9043Z"
        className="fill-current dark:text-[#EDEDFB] text-[#4A488E]"
      />
    </g>
    <defs>
      <clipPath id="clip0_252_1223">
        <rect width="12" height="12" fill="white" transform="translate(0 12) rotate(-90)" />
      </clipPath>
    </defs>
  </svg>
  );


export const BigArrowSvg = () => ( 
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <g clipPath="url(#clip0_252_1221)">
    <path
      d="M3.19454 0.0957029C3.09376 0.0957029 2.99062 0.135547 2.91328 0.21289C2.75859 0.367578 2.75859 0.620703 2.91328 0.77539L8.20781 6.06992L2.99062 11.2871C2.83594 11.4418 2.83594 11.6949 2.99062 11.8496C3.14531 12.0043 3.39844 12.0043 3.55312 11.8496L9.05391 6.35117C9.20859 6.19648 9.20859 5.94336 9.05391 5.78867L3.47813 0.21289C3.39844 0.133203 3.29767 0.0957029 3.19454 0.0957029Z"
      className="fill-current dark:text-[#EDEDFB] text-[#4A488E]"
    />
  </g>
  <defs>
    <clipPath id="clip0_252_1221">
      <rect width="12" height="12" fill="white" transform="translate(12) rotate(90)" />
    </clipPath>
  </defs>
</svg>
);

export const DeleteIcon = () => ( 
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clipPath="url(#clip0_257_3684)">
      <path
        d="M11.1149 -0.0292969C9.30755 -0.0292969 7.85555 1.47255 7.85555 3.28824V3.69163H4.19186C3.68324 3.69163 3.26878 4.10609 3.26878 4.6147V5.53778H2.3457V7.38393H4.19186V21.2301C4.19186 22.7486 5.44263 23.9993 6.96109 23.9993H18.038C19.5565 23.9993 20.8072 22.7486 20.8072 21.2301V7.38393H22.6534V5.53778H21.7303V4.6147C21.7303 4.10609 21.3159 3.69163 20.8072 3.69163H17.1435V3.28732C17.1435 1.47255 15.6915 -0.0292969 13.8842 -0.0292969H11.1149ZM11.1149 1.87409H13.8842C14.6263 1.87409 15.2402 2.47963 15.2402 3.28732V3.69163H9.75801V3.28732C9.75801 2.47963 10.3719 1.87501 11.114 1.87501L11.1149 1.87409ZM6.03801 7.38393H10.7688C10.8832 7.39593 10.9968 7.41255 11.1149 7.41255H13.8842C14.0023 7.41255 14.1149 7.39593 14.2303 7.38393H18.9611V21.2301C18.9611 21.7498 18.5577 22.1532 18.038 22.1532H6.96109C6.4414 22.1532 6.03801 21.7498 6.03801 21.2301V7.38393ZM7.88416 9.23009V20.307H9.73032V9.23009H7.88416ZM11.5765 9.23009V20.307H13.4226V9.23009H11.5765ZM15.2688 9.23009V20.307H17.1149V9.23009H15.2688Z"
        className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
      />
    </g>
    <defs>
      <clipPath id="clip0_257_3684">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);


export const HandleExportPDFIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M13.5 2H5.5C4.4 2 3.5 2.9 3.5 4V20C3.5 21.1 4.4 22 5.5 22H17.5C18.6 22 19.5 21.1 19.5 20V8L13.5 2ZM17.5 20H5.5V4H12.5V9H17.5V20ZM15.5 11V18.1L13.4 16L10.6 18.8L7.8 16L10.6 13.2L8.4 11H15.5Z"
      className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
    />
  </svg>
);


export const CloseOptionIcon = () => ( 
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
  <path
    d="M29.724 28.0888C29.8314 28.1963 29.9166 28.3238 29.9747 28.4642C30.0329 28.6045 30.0628 28.755 30.0628 28.9069C30.0628 29.0588 30.0329 29.2093 29.9747 29.3496C29.9166 29.49 29.8314 29.6175 29.724 29.7249C29.6165 29.8324 29.489 29.9176 29.3486 29.9757C29.2083 30.0339 29.0578 30.0638 28.9059 30.0638C28.754 30.0638 28.6036 30.0339 28.4632 29.9757C28.3228 29.9176 28.1953 29.8324 28.0879 29.7249L18.4997 20.1353L8.91146 29.7249C8.6945 29.9419 8.40024 30.0638 8.09342 30.0638C7.78659 30.0638 7.49233 29.9419 7.27537 29.7249C7.05841 29.508 6.93652 29.2137 6.93652 28.9069C6.93652 28.6001 7.05841 28.3058 7.27537 28.0888L16.865 18.5006L7.27537 8.91244C7.05841 8.69548 6.93652 8.40122 6.93652 8.09439C6.93652 7.78757 7.05841 7.49331 7.27537 7.27635C7.49233 7.05939 7.78659 6.9375 8.09342 6.9375C8.40024 6.9375 8.6945 7.05939 8.91146 7.27635L18.4997 16.866L28.0879 7.27635C28.3048 7.05939 28.5991 6.9375 28.9059 6.9375C29.2127 6.9375 29.507 7.05939 29.724 7.27635C29.9409 7.49331 30.0628 7.78757 30.0628 8.09439C30.0628 8.40122 29.9409 8.69548 29.724 8.91244L20.1343 18.5006L29.724 28.0888Z"
    className="fill-current dark:text-[#EDEDFB] text-[#7674C2]"
  />
</svg>
);

export const ArrowDownIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
<path d="M0.0957031 3.75509C0.0957031 3.65431 0.135547 3.55117 0.212891 3.47383C0.367578 3.31914 0.620703 3.31914 0.775391 3.47383L6.06992 8.76836L11.2871 3.55117C11.4418 3.39648 11.6949 3.39648 11.8496 3.55117C12.0043 3.70586 12.0043 3.95898 11.8496 4.11367L6.35117 9.61445C6.19648 9.76914 5.94336 9.76914 5.78867 9.61445L0.212891 4.03867C0.133203 3.95898 0.0957031 3.85821 0.0957031 3.75509Z" fill="#EDEDFB"/>
</svg>
);

export const CategoryAcordionIcon = () => (
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className=" text-[#4A488E] dark:text-[#EDEDFB]"
  >
    <path
      id="Vector"
      d="M0.62341 20C0.465263 20 0.303421 19.9329 0.182053 19.8025C-0.0606833 19.5419 -0.0606833 19.1154 0.182053 18.8548L8.49025 9.93385L0.30342 1.14325C0.0606834 0.882617 0.0606833 0.456118 0.30342 0.195481C0.546156 -0.0651584 0.943361 -0.0651584 1.1861 0.195481L9.81795 9.45997C10.0607 9.7206 10.0607 10.1471 9.81795 10.4077L1.06841 19.8025C0.943363 19.9368 0.785234 20 0.62341 20Z"
      fill="currentColor"
    />
  </svg>
);


  export const InventoryEditIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="stroke-current text-[#3f3f83] dark:text-white" // Tailwind classes
      fill="none"
    >
      <path
        d="M13.9412 6.55138L17.4706 10.0761M11.5882 23H21M2.17647 18.3004L1 23L5.70588 21.8251L19.3365 8.21269C19.7776 7.77204 20.0254 7.17447 20.0254 6.55138C20.0254 5.9283 19.7776 5.33073 19.3365 4.89007L19.1341 4.68799C18.6929 4.24747 18.0945 4 17.4706 4C16.8467 4 16.2483 4.24747 15.8071 4.68799L2.17647 18.3004Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );


  export const InventoryDeleteIcon = () => (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current stroke-current text-[#3f3f83] dark:text-white" // Tailwind classes
      fill="none"
    >
      <g id="wpf:delete" clipPath="url(#clip0_1422_18988)">
        <path
          id="Vector"
          d="M7.8575 4.19358H8.3575V3.69358V3.29019C8.3575 1.7399 9.59627 0.472656 11.1169 0.472656H13.8861C15.4068 0.472656 16.6455 1.73999 16.6455 3.28927V3.69358V4.19358H17.1455H20.8092C21.0417 4.19358 21.2323 4.38418 21.2323 4.61666V5.53973V6.03973H21.7323H22.1553V6.88589H20.8092H20.3092V7.38589V21.232C20.3092 22.4744 19.2823 23.5013 18.04 23.5013H6.96304C5.72072 23.5013 4.69381 22.4744 4.69381 21.232V7.38589V6.88589H4.19381H2.84766V6.03973H3.27073H3.77073V5.53973V4.61666C3.77073 4.38418 3.96134 4.19358 4.19381 4.19358H7.8575ZM11.1169 1.37604H10.9098L10.8959 1.38993C9.97127 1.49973 9.25996 2.29427 9.25996 3.28927V3.69358V4.19358H9.75996H15.2421H15.7421V3.69358V3.28927C15.7421 2.21707 14.9159 1.37604 13.8861 1.37604H11.1169ZM6.03996 6.88589H5.53996V7.38589V21.232C5.53996 22.0279 6.1672 22.6551 6.96304 22.6551H18.04C18.8358 22.6551 19.463 22.0279 19.463 21.232V7.38589V6.88589H18.963H14.2323H14.2063L14.1805 6.88857C14.1425 6.89253 14.1097 6.89622 14.0806 6.89951C14.0004 6.90854 13.9476 6.9145 13.8861 6.9145H11.1169C11.0548 6.9145 11.0005 6.90837 10.9189 6.89916C10.8907 6.89597 10.8591 6.89241 10.8229 6.88861L10.7969 6.88589H10.7707H6.03996ZM9.23227 19.809H8.38612V9.73204H9.23227V19.809ZM12.9246 19.809H12.0784V9.73204H12.9246V19.809ZM16.6169 19.809H15.7707V9.73204H16.6169V19.809Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1422_18988">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
  

  export const InventoryCloseIcon = () => (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current stroke-current text-[#3f3f83] dark:text-white" // Tailwind classes
      fill="none"
    >
      <g id="ph:x">
        <path
          id="Vector"
          d="M17.2195 18.1471L7.6299 8.55889C7.62989 8.55888 7.62989 8.55887 7.62988 8.55887C7.5067 8.43568 7.4375 8.2686 7.4375 8.09439C7.4375 7.92017 7.50671 7.75309 7.6299 7.6299C7.75309 7.50671 7.92017 7.4375 8.09439 7.4375C8.2686 7.4375 8.43567 7.5067 8.55886 7.62987C8.55887 7.62988 8.55888 7.62989 8.55889 7.6299L18.1471 17.2195L18.5006 17.5732L18.8542 17.2195L28.4424 7.6299C28.4424 7.62989 28.4424 7.62988 28.4424 7.62987C28.5656 7.5067 28.7327 7.4375 28.9069 7.4375C29.0811 7.4375 29.2482 7.50671 29.3714 7.6299L29.7229 7.27843L29.3714 7.6299C29.4946 7.75309 29.5638 7.92017 29.5638 8.0944C29.5638 8.2686 29.4946 8.43567 29.3714 8.55886C29.3714 8.55887 29.3714 8.55888 29.3714 8.55889L19.7818 18.1471L19.4281 18.5006L19.7818 18.8542L29.3714 28.4424C29.3714 28.4424 29.3714 28.4424 29.3714 28.4424C29.4324 28.5034 29.4808 28.5758 29.5138 28.6555C29.5468 28.7352 29.5638 28.8206 29.5638 28.9069C29.5638 28.9932 29.5468 29.0786 29.5138 29.1583C29.4808 29.238 29.4324 29.3104 29.3714 29.3714C29.3104 29.4324 29.238 29.4808 29.1583 29.5138C29.0786 29.5468 28.9932 29.5638 28.9069 29.5638C28.8206 29.5638 28.7352 29.5468 28.6555 29.5138C28.5758 29.4808 28.5034 29.4324 28.4424 29.3714C28.4424 29.3714 28.4424 29.3714 28.4424 29.3714L18.8542 19.7818L18.5006 19.4281L18.1471 19.7818L8.55889 29.3714C8.55888 29.3714 8.55887 29.3714 8.55886 29.3714C8.43567 29.4946 8.2686 29.5638 8.09439 29.5638C7.92017 29.5638 7.75309 29.4946 7.6299 29.3714L7.27843 29.7229L7.6299 29.3714C7.50671 29.2482 7.4375 29.0811 7.4375 28.9069C7.4375 28.7327 7.5067 28.5656 7.62987 28.4424C7.62988 28.4424 7.62989 28.4424 7.6299 28.4424L17.2195 18.8542L17.5732 18.5006L17.2195 18.1471Z"
        />
      </g>
    </svg>
  );
  

export const InventoryArrowRightIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="simple-line-icons:arrow-up" clip-path="url(#clip0_1548_11365)">
<path id="Vector" d="M3.19454 0.097656C3.09376 0.097656 2.99062 0.1375 2.91328 0.214843C2.75859 0.369531 2.75859 0.622656 2.91328 0.777343L8.20781 6.07188L2.99062 11.2891C2.83594 11.4438 2.83594 11.6969 2.99062 11.8516C3.14531 12.0063 3.39844 12.0063 3.55312 11.8516L9.05391 6.35313C9.20859 6.19844 9.20859 5.94531 9.05391 5.79063L3.47813 0.214844C3.39844 0.135156 3.29767 0.097656 3.19454 0.097656Z" fill="#EDEDFB"/>
</g>
<defs>
<clipPath id="clip0_1548_11365">
<rect width="12" height="12" fill="white" transform="translate(12) rotate(90)"/>
</clipPath>
</defs>
</svg>
);

export const InventoryArrowLeftIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="simple-line-icons:arrow-up" clip-path="url(#clip0_1548_11363)">
<path id="Vector" d="M8.80546 11.9023C8.90624 11.9023 9.00938 11.8625 9.08672 11.7852C9.24141 11.6305 9.24141 11.3773 9.08672 11.2227L3.79219 5.92812L9.00938 0.710937C9.16406 0.556249 9.16406 0.303125 9.00938 0.148437C8.85469 -0.00625126 8.60156 -0.00625127 8.44688 0.148437L2.94609 5.64687C2.79141 5.80156 2.79141 6.05469 2.94609 6.20937L8.52187 11.7852C8.60156 11.8648 8.70233 11.9023 8.80546 11.9023Z" fill="#EDEDFB"/>
</g>
<defs>
<clipPath id="clip0_1548_11363">
<rect width="12" height="12" fill="white" transform="translate(0 12) rotate(-90)"/>
</clipPath>
</defs>
</svg>
);	

export const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9828 16.0908C13.5788 17.2126 11.7985 17.7542 10.0077 17.6044C8.21679 17.4546 6.55126 16.6248 5.35313 15.2853C4.15501 13.9459 3.51524 12.1985 3.56522 10.4021C3.61521 8.60567 4.35115 6.89657 5.62191 5.62581C6.89266 4.35506 8.60176 3.61911 10.3982 3.56913C12.1946 3.51914 13.942 4.15891 15.2814 5.35704C16.6209 6.55516 17.4507 8.22069 17.6005 10.0116C17.7503 11.8024 17.2087 13.5827 16.0869 14.9867L21.4578 20.3565C21.5345 20.428 21.5961 20.5142 21.6388 20.6101C21.6815 20.7059 21.7045 20.8094 21.7063 20.9143C21.7082 21.0192 21.6889 21.1234 21.6496 21.2206C21.6103 21.3179 21.5518 21.4063 21.4776 21.4805C21.4034 21.5547 21.315 21.6131 21.2178 21.6524C21.1205 21.6917 21.0163 21.711 20.9114 21.7092C20.8065 21.7073 20.703 21.6844 20.6072 21.6417C20.5114 21.599 20.4251 21.5374 20.3536 21.4606L14.9828 16.0908ZM6.72652 14.4638C5.96198 13.6991 5.44126 12.725 5.23018 11.6646C5.01909 10.6041 5.12712 9.50484 5.54059 8.50574C5.95407 7.50664 6.65444 6.65253 7.5532 6.05136C8.45195 5.45019 9.50875 5.12895 10.59 5.12822C11.6713 5.12749 12.7285 5.44732 13.6281 6.04727C14.5277 6.64723 15.2292 7.50039 15.644 8.49894C16.0588 9.49748 16.1683 10.5966 15.9587 11.6573C15.749 12.7181 15.2296 13.6929 14.4661 14.4585L14.4609 14.4638L14.4557 14.4679C13.4297 15.4915 12.0395 16.066 10.5902 16.0652C9.14101 16.0644 7.75136 15.4884 6.72652 14.4638Z" fill="#979BE2"/>
</svg>
);
export const AddRestockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className="fill-current text-[#3f3f83] dark:text-white" // Tailwind classes
  >
    <path d="M1.1821e-06 16.5918L1.58353e-06 7.40816L12.1212 7.40816L12.1212 -5.19238e-07L24 12L12.1212 24L12.1212 16.5918L1.1821e-06 16.5918Z" />
  </svg>
);
