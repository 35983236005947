import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

// Custom styled progress bar
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 32,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#CAC9F1",
    ...(theme.palette.mode === "dark" && {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...(theme.palette.mode === "dark" && {
      backgroundColor: "#308fe8",
    }),
  },
}));

// Format the time from total seconds into "hh:mm:ss"
const formatTimeFromSeconds = (totalSeconds) => {
  if (!totalSeconds || totalSeconds <= 0) return "0:00:00";

  const hours = Math.floor(totalSeconds / 3600); // Calculate hours
  const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
  const seconds = Math.floor(totalSeconds % 60); // Calculate remaining seconds

  return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const ProgressBar = ({ project, totalTime }) => {
  // Calculate the percentage based on totalTime
  const percentage =
    totalTime > 0 ? Math.min((project.totalSeconds / totalTime) * 100, 100) : 0;

  return (
    <div className="flex w-full py-2 gap-[35px]">
      <div className="w-[50%] flex items-center justify-between gap-[31px]">
        <div className="flex items-center gap-[12px]">
          <p className="dark:text-[#ffffff8c] text-[#8D8BDB]">
            {project?.projectName}
          </p>
        </div>
        <div>
          <p className="dark:text-[#F1F1F1] text-[18px] text-[#4A488E]">
            {formatTimeFromSeconds(project.totalSeconds)}
          </p>
        </div>
      </div>
      <div className="w-full flex items-center gap-[24px]">
        <BorderLinearProgress
          variant="determinate"
          value={percentage} // Set the progress value
          className="w-full"
        />
        <span className="dark:text-[#ffffff8c] text-[#626199]">
          {percentage.toFixed(2)}%
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
