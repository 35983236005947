import React, { useState, useContext } from "react";
import { Breadcrumbs } from "@mui/material";
import { useGetMeQuery } from "../../redux/api/userApi";
import { Link } from "react-router-dom";
import { useGetAllUsersQuery } from "../../redux/api/authApi";
import NotificationComponent from "../NotificationComponent";
import { ViewTypeContext } from "../utils/contexts/viewTypeContext";

export const AdminPanel = () => {
  const isMobileView = useContext(ViewTypeContext);
  const [openRight, setOpenRight] = useState(false);
  const {
    data: meData,
    error: meError,
    isLoading: meLoading,
  } = useGetMeQuery();
  const {
    data: usersData,
    error: usersError,
    isLoading: usersLoading,
  } = useGetAllUsersQuery();

  return (
    <div className="pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49] ">
      <div className="w-full px-[40px] ">
        <div className="p-4 flex justify-between ">
          <ul className="flex items-center">
            <Breadcrumbs
              aria-label="breadcrumb"
              className="w-[300px] text-[#7674C2] dark:text-[#EDEDFB]"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                underline="hover"
                to={`/adminpanel`}
              >
                Admin Panel
              </Link>
            </Breadcrumbs>
          </ul>
          <NotificationComponent />
        </div>
        <p className="text-[30px] text-[#4A488E] dark:text-[#EDEDFB] mt-[39px] mb-[34px] ml-[20px]">
          Admin Panel
        </p>
        <div
          className={`flex md:mb-40 ${
            isMobileView ? "flex-col min-w-[300px]" : "md:flex-row"
          } gap-[20px] ml-[20px]`}
        >
          <div
            className={`w-full ${
              isMobileView ? "min-w-[300px]" : "md:w-[332px]"
            } h-[299px] py-[30px] bg-violet-100 dark:bg-[#3A3A68] rounded-[10px] border-t-4 border-indigo-800 dark:border-[#EDEDFB] flex-col justify-start items-start gap-2.5 inline-flex`}
          >
            <div className="w-full text-indigo-800 dark:text-[#EDEDFB] text-lg font-semibold px-7">
              Users & Authentication
            </div>
            <div className="flex flex-col w-full">
              <Link to="/AdminPanel/UserManagement">
                <div className="py-[12px] border-t text-[#7674C2] dark:text-[#EDEDFB] text-[16px] border-t-indigo-200 px-7 flex flex-row gap-[12px] items-center cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      className="fill-current dark:text-[#EDEDFB]"
                      d="M5.14174 14.5723C5.14174 12.858 8.57031 11.9151 10.2846 11.9151C11.9989 11.9151 15.4275 12.858 15.4275 14.5723V15.4294H5.14174M12.856 7.71512C12.856 8.39711 12.5851 9.05116 12.1029 9.5334C11.6206 10.0156 10.9666 10.2866 10.2846 10.2866C9.60261 10.2866 8.94856 10.0156 8.46632 9.5334C7.98409 9.05116 7.71317 8.39711 7.71317 7.71512C7.71317 7.03314 7.98409 6.37908 8.46632 5.89685C8.94856 5.41461 9.60261 5.14369 10.2846 5.14369C10.9666 5.14369 11.6206 5.41461 12.1029 5.89685C12.5851 6.37908 12.856 7.03314 12.856 7.71512ZM2.57031 4.28655V16.2866C2.57031 16.7412 2.75092 17.1772 3.07242 17.4987C3.39391 17.8202 3.82994 18.0008 4.2846 18.0008H16.2846C16.7393 18.0008 17.1753 17.8202 17.4968 17.4987C17.8183 17.1772 17.9989 16.7412 17.9989 16.2866V4.28655C17.9989 3.83189 17.8183 3.39586 17.4968 3.07437C17.1753 2.75288 16.7393 2.57227 16.2846 2.57227H4.2846C3.82994 2.57227 3.39391 2.75288 3.07242 3.07437C2.75092 3.39586 2.57031 3.83189 2.57031 4.28655Z"
                      fill="#4A488E"
                    />
                  </svg>{" "}
                  User management
                </div>
              </Link>
              <Link to="/AdminPanel/RoleManagement">
                <div className="py-[12px] border-t border-b text-[#7674C2] dark:text-[#EDEDFB] text-[16px] border-indigo-200 px-7 flex flex-row gap-[12px] items-center cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_663_8080)">
                      <path
                        className="fill-current dark:text-[#EDEDFB]"
                        d="M10.625 14.875H9.20833C8.83261 14.875 8.47228 14.7257 8.2066 14.4601C7.94092 14.1944 7.79167 13.8341 7.79167 13.4583C7.79167 13.0826 7.94092 12.7223 8.2066 12.4566C8.47228 12.1909 8.83261 12.0417 9.20833 12.0417H10.625V10.625H9.20833C8.45689 10.625 7.73622 10.9235 7.20486 11.4549C6.67351 11.9862 6.375 12.7069 6.375 13.4583C6.375 14.2098 6.67351 14.9304 7.20486 15.4618C7.73622 15.9932 8.45689 16.2917 9.20833 16.2917H10.625V14.875ZM16.2917 13.4583C16.2917 14.2098 15.9932 14.9304 15.4618 15.4618C14.9304 15.9932 14.2098 16.2917 13.4583 16.2917H12.0417V14.875H13.4583C13.8341 14.875 14.1944 14.7257 14.4601 14.4601C14.7257 14.1944 14.875 13.8341 14.875 13.4583C14.875 13.0826 14.7257 12.7223 14.4601 12.4566C14.1944 12.1909 13.8341 12.0417 13.4583 12.0417H12.0417V10.625H13.4583C14.2098 10.625 14.9304 10.9235 15.4618 11.4549C15.9932 11.9862 16.2917 12.7069 16.2917 13.4583Z"
                        fill="#4A488E"
                      />
                      <path
                        className="fill-current dark:text-[#EDEDFB]"
                        d="M9.91667 12.7501H12.75V14.1668H9.91667V12.7501ZM4.95833 13.4585C4.95976 13.221 4.98108 12.9841 5.02208 12.7501H2.125V11.7585C2.125 10.3418 4.95833 9.56264 6.375 9.56264C6.69558 9.56695 7.01533 9.59655 7.33125 9.65119C7.91432 9.36052 8.55683 9.20898 9.20833 9.20848H12.75V2.83348C12.7489 2.4581 12.5993 2.09841 12.3338 1.83298C12.0684 1.56755 11.7087 1.41793 11.3333 1.41681H8.3725C8.22746 1.00275 7.95733 0.643988 7.59947 0.390165C7.24162 0.136342 6.81373 0 6.375 0C5.93627 0 5.50838 0.136342 5.15052 0.390165C4.79267 0.643988 4.52254 1.00275 4.3775 1.41681H1.41667C1.04129 1.41793 0.681604 1.56755 0.416171 1.83298C0.150737 2.09841 0.0011211 2.4581 0 2.83348L0 12.7501C0.0011211 13.1255 0.150737 13.4852 0.416171 13.7506C0.681604 14.0161 1.04129 14.1657 1.41667 14.1668H5.02208C4.98108 13.9329 4.95976 13.696 4.95833 13.4585ZM6.375 1.41681C6.51509 1.41681 6.65204 1.45835 6.76853 1.53619C6.88501 1.61402 6.9758 1.72464 7.02941 1.85408C7.08303 1.98351 7.09705 2.12593 7.06972 2.26333C7.04239 2.40074 6.97493 2.52695 6.87587 2.62601C6.7768 2.72507 6.65059 2.79254 6.51319 2.81987C6.37579 2.8472 6.23336 2.83317 6.10393 2.77956C5.9745 2.72595 5.86387 2.63516 5.78604 2.51867C5.70821 2.40219 5.66667 2.26524 5.66667 2.12514C5.66723 1.93745 5.74203 1.75761 5.87475 1.6249C6.00747 1.49218 6.18731 1.41737 6.375 1.41681ZM6.375 4.25014C6.79529 4.25014 7.20613 4.37477 7.55559 4.60827C7.90504 4.84177 8.17741 5.17365 8.33824 5.56194C8.49908 5.95023 8.54116 6.3775 8.45917 6.78971C8.37718 7.20192 8.17479 7.58056 7.8776 7.87775C7.58042 8.17493 7.20178 8.37732 6.78957 8.45931C6.37736 8.54131 5.95009 8.49922 5.5618 8.33839C5.1735 8.17755 4.84163 7.90518 4.60813 7.55573C4.37463 7.20628 4.25 6.79543 4.25 6.37514C4.24963 6.09598 4.30434 5.81949 4.411 5.5615C4.51766 5.30351 4.67417 5.06911 4.87157 4.87171C5.06896 4.67431 5.30337 4.5178 5.56136 4.41114C5.81934 4.30448 6.09584 4.24977 6.375 4.25014Z"
                        fill="#4A488E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_663_8080">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>{" "}
                  Role management
                </div>
              </Link>
            </div>
          </div>
          <div
            className={`w-full ${
              isMobileView ? "min-w-[300px]" : "md:w-[332px]"
            } h-[299px] py-[30px] bg-violet-100 dark:bg-[#3A3A68] rounded-[10px] border-t-4 border-indigo-800 dark:border-[#EDEDFB] flex-col justify-start items-start gap-2.5 inline-flex`}
          >
            <div className="w-full text-indigo-800 dark:text-[#EDEDFB] text-lg font-semibold px-7">
              Employees’ settings
            </div>

            <div className="flex flex-col w-full">
              <Link to="/AdminPanel/HR-Details">
                <div className="py-[12px] border-t text-[#7674C2] dark:text-[#EDEDFB] text-[16px] border-t-indigo-200 px-7 flex flex-row gap-[12px] items-center cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      className="fill-current dark:text-[#EDEDFB]"
                      d="M9.2 8.0625C9.2 9.41125 8.128 10.5 6.8 10.5C5.472 10.5 4.4 9.41125 4.4 8.0625C4.4 6.71375 5.472 5.625 6.8 5.625C8.128 5.625 9.2 6.71375 9.2 8.0625ZM11.6 17H2V15.375C2 13.5794 4.152 12.125 6.8 12.125C9.448 12.125 11.6 13.5794 11.6 15.375M18 10.5V12.125H10.8V10.5M18 7.25V8.875H10.8V7.25M18 4V5.625H10.8V4H18Z"
                      fill="#4A488E"
                    />
                  </svg>
                  HR details
                </div>
              </Link>
              <Link to="/AdminPanel/Benefits">
                <div className="py-[12px] border-t text-[#7674C2] dark:text-[#EDEDFB] text-[16px] border-t-indigo-200 px-7 flex flex-row gap-[12px] items-center cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      className="stroke-current dark:text-[#EDEDFB]"
                      d="M8.22639 12.583L7.50469 13.288L2.09193 8.05126C1.73491 7.71188 1.45369 7.30397 1.26598 6.85321C1.07827 6.40245 0.988134 5.91861 1.00125 5.43215C1.01437 4.9457 1.13045 4.46716 1.3422 4.02668C1.55394 3.5862 1.85675 3.19332 2.23157 2.87277C2.60639 2.55223 3.04509 2.31097 3.52004 2.16418C3.995 2.0174 4.49592 1.96827 4.99127 2.01989C5.48662 2.0715 5.96566 2.22275 6.39823 2.46411C6.8308 2.70546 7.20753 3.03169 7.50469 3.42226C7.80314 3.03453 8.1803 2.71115 8.61257 2.47235C9.04485 2.23356 9.52292 2.0845 10.0169 2.03449C10.5108 1.98449 11.0101 2.03462 11.4833 2.18175C11.9565 2.32888 12.3935 2.56983 12.767 2.88954C13.1405 3.20925 13.4424 3.60083 13.6539 4.03976C13.8653 4.4787 13.9818 4.95554 13.9959 5.44046C14.01 5.92537 13.9215 6.40791 13.736 6.85788C13.5504 7.30784 13.2717 7.71555 12.9174 8.05549M10.3915 13.9929L14 10.468M14 13.9929V14M10.3915 10.468V10.475"
                      stroke="#4A488E"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Benefits{" "}
                </div>
              </Link>

              <Link to="/HR/AppraisalAdmin">
                <div className="py-[12px] border-t border-b text-[#7674C2] dark:text-[#EDEDFB] text-[16px] border-indigo-200 px-7 flex flex-row gap-[12px] items-center cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_663_8128)">
                      <path
                        className="fill-current dark:text-[#EDEDFB]"
                        d="M12.6328 6.125C12.8242 6.125 13.002 6.15918 13.166 6.22754C13.3301 6.2959 13.4759 6.38932 13.6035 6.50781C13.7311 6.6263 13.8268 6.76986 13.8906 6.93848C13.9544 7.1071 13.9909 7.28711 14 7.47852C14 7.65625 13.9658 7.82943 13.8975 7.99805C13.8291 8.16667 13.7311 8.31478 13.6035 8.44238L8.70215 13.3438L6.125 13.9863L6.76758 11.4092L11.6689 6.51465C11.8011 6.38249 11.9492 6.28451 12.1133 6.2207C12.2773 6.1569 12.4505 6.125 12.6328 6.125ZM13.125 7.47852C13.125 7.33268 13.0794 7.21647 12.9883 7.12988C12.8971 7.04329 12.7786 7 12.6328 7C12.569 7 12.5075 7.00911 12.4482 7.02734C12.389 7.04557 12.3366 7.07975 12.291 7.12988L7.56055 11.8604L7.32812 12.7832L8.25098 12.5508L12.9814 7.82715C13.0771 7.73145 13.125 7.61523 13.125 7.47852ZM1.75 0.875V13.125H5.20898L4.99707 14H0.875V0H8.49707L12.25 3.75293V5.06543C12.0814 5.08822 11.931 5.1224 11.7988 5.16797C11.6667 5.21354 11.5254 5.28418 11.375 5.37988V4.375H7.875V0.875H1.75ZM8.75 1.49707V3.5H10.7529L8.75 1.49707ZM9.625 6.125V7H3.5V6.125H9.625ZM3.5 10.5V9.625H7.00684L6.13184 10.5H3.5ZM8.75684 7.875L7.88184 8.75H3.5V7.875H8.75684Z"
                        fill="#4A488E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_663_8128">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Appraisal
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
