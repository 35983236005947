import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import backend_URL from "../../config";
import Cookies from "js-cookie"; // Import js-cookie for token management

const baseQuery = fetchBaseQuery({
  baseUrl: backend_URL + "/api/v1",
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const servicesApi = createApi({
  reducerPath: "servicesApi",
  baseQuery,
  endpoints: (builder) => ({
    // Fetch all services
    getServices: builder.query({
      query() {
        return {
          url: "/services",
          method: "GET",
        };
      },
    }),

    // Fetch details of a single service by ID
    getServiceDetails: builder.query({
      query(serviceId) {
        return {
          url: `/services/${serviceId}`,
          method: "GET",
        };
      },
    }),

    // Create a new service
    postService: builder.mutation({
      query(service) {
        return {
          url: "/services",
          method: "POST",
          body: service,
        };
      },
    }),

    // Update an existing service by ID
    updateService: builder.mutation({
      query(service) {
        return {
          url: `/services/${service.id}`,
          method: "PUT",
          body: service,
        };
      },
    }),

    // Delete a service by ID
    deleteService: builder.mutation({
      query(serviceId) {
        return {
          url: `/services/${serviceId}`,
          method: "DELETE",
        };
      },
    }),

    // Upload an image (assuming this is part of the service or related to the service)
    uploadServiceImage: builder.mutation({
      query: (body) => ({
        url: `/services/upload_photo`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServiceDetailsQuery,
  usePostServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useUploadServiceImageMutation,
} = servicesApi;
