import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import backend_URL from "../../../config"; // Assuming backend_URL is defined in your config

// Define the base query
const baseQuery = fetchBaseQuery({
  baseUrl: `${backend_URL}/api/v1`,  // Assuming your API is at /api/v1
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");  // Get token from cookies for authorization
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Define the restocks API
export const restockApi = createApi({
  reducerPath: "restockApi",  // Unique key to mount the API slice in Redux
  baseQuery,
  endpoints: (builder) => ({
    // Fetch all restocks with optional filters
    getRestocks: builder.query({
      query: (filters) => ({
        url: "/restocks",
        method: "GET",
        params: filters,  // Pass filter parameters if needed (e.g., search, status, supplier)
      }),
    }),

    // Fetch a single restock by ID
    getRestockById: builder.query({
      query: (restockId) => ({
        url: `/restocks/${restockId}`,
        method: "GET",
      }),
    }),

    // Create a new restock
    postRestock: builder.mutation({
      query: (restock) => ({
        url: "/restocks",
        method: "POST",
        body: restock,  // Send the restock data in the request body
      }),
    }),

    // Update an existing restock by ID
    updateRestock: builder.mutation({
      query: ({ restockId, restockData }) => ({
        url: `/restocks/${restockId}`,
        method: "PUT",
        body: restockData,  // Send the updated restock data in the request body
      }),
    }),

    // Update restock status (Complete or Cancel a restock)
    updateRestockStatus: builder.mutation({
      query: ({ restockId, status }) => ({
        url: `/restocks/${restockId}/status`,
        method: "PUT",
        body: { status },  // Send the new status for the restock
      }),
    }),

    // Delete one or more restocks (batch deletion)
    deleteRestocks: builder.mutation({
      query: (restockIds) => ({
        url: "/restocks",
        method: "DELETE",
        body: { restockIds },  // Send the restock IDs in the request body for deletion
      }),
    }),
  }),
});

// Export hooks for the restocks API
export const {
  useGetRestocksQuery,
  useGetRestockByIdQuery,
  usePostRestockMutation,
  useUpdateRestockMutation,
  useUpdateRestockStatusMutation,
  useDeleteRestocksMutation,
} = restockApi;
