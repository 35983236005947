import React, { useState } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

export const CertificatePage = () => {
  const certificates = [
    { name: "Annual leave certificate", file: "annual-leave-certificate.pdf" },
    { name: "Maternity certificate", file: "maternity-certificate.pdf" },
    { name: "Sick leave certificate", file: "sick-leave-certificate.pdf" },
  ];

  const [expanded, setExpanded] = useState(null);

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <div className="pr-[54px] ml-[-59px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  bg-[#FFF] dark:bg-[#2B2A49]">
      <div className="w-full px-[40px]">
        <div className="p-4 flex items-center flex-wrap">
          <ul className="flex items-center ">
            <Breadcrumbs
              className="text-[#4A488E] dark:text-[#EDEDFB]"
              aria-label="breadcrumb"
            >
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to="/dashboard"
              >
                Fuse Digital
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/HR`}
              >
                HR
              </Link>
              <Link
                underline="hover"
                className="text-[#4A488E] dark:text-[#EDEDFB]"
                to={`/HR`}
              >
                Certificate upload page
              </Link>
            </Breadcrumbs>
          </ul>
        </div>
        <div className="text-[#4A488E]  dark:text-[#EDEDFB] text-3xl font-normal   mt-[40px] mb-[53px]">
          Certificate upload page
        </div>
        <div className="flex flex-col gap-[40px]">
          {certificates.map((certificate, index) => (
            <div key={index} className="w-[390px]">
              <div
                className="flex flex-row justify-between items-center cursor-pointer py-2"
                onClick={() => handleToggle(index)}
              >
                <div className="text-zinc-500 dark:text-[#EDEDFB] text-xl font-normal  ">
                  {certificate.name}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  className={`transform transition-transform ${
                    expanded === index ? "rotate-180" : ""
                  }`}
                >
                  <path
                    className="fill-current text-[#8A8A8A] dark:text-[#EDEDFB]"
                    d="M0.0957031 3.19454C0.0957031 3.09376 0.135547 2.99062 0.212891 2.91328C0.367578 2.75859 0.620703 2.75859 0.775391 2.91328L6.06992 8.20781L11.2871 2.99062C11.4418 2.83594 11.6949 2.83594 11.8496 2.99062C12.0043 3.14531 12.0043 3.39844 11.8496 3.55312L6.35117 9.05391C6.19648 9.20859 5.94336 9.20859 5.78867 9.05391L0.212891 3.47813C0.133203 3.39844 0.0957031 3.29767 0.0957031 3.19454Z"
                  />
                </svg>
              </div>
              {expanded === index && (
                <>
                  <div className="py-2 border-b-2">
                    <a
                      href={`/path/to/documents/${certificate.file}`} // Replace with the correct path
                      download
                      className="text-[#8D8BDB]"
                    >
                      <div className="w-[74px] h-[74px]rounded  " />
                      <img
                        src="/images/blue-upload.svg"
                        className="mb-[32px]"
                        alt=""
                      />
                      {/*   <svg
                      className="relative top-[-50px] right-[-22px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                    >
                      <path
                        d="M4.83398 20.5416V22.9583C4.83398 23.5992 5.0886 24.2139 5.54181 24.6671C5.99502 25.1203 6.60971 25.3749 7.25065 25.3749H21.7507C22.3916 25.3749 23.0063 25.1203 23.4595 24.6671C23.9127 24.2139 24.1673 23.5992 24.1673 22.9583V20.5416M8.45898 13.2916L14.5007 19.3333M14.5007 19.3333L20.5423 13.2916M14.5007 19.3333V4.83325"
                        stroke="#D2D2D2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg> */}
                      certificate.pdf
                    </a>
                  </div>
                  <div className="w-full flex  mt-[25px] justify-end ">
                    <div className="flex items-center flex-col gap-[10px]">
                      <div className="w-[74px] h-[74px] bg-[#8D8BDB36] flex items-center justify-center">
                        <img src="/images/blue-plus.svg" alt="" />
                      </div>
                      <p className=" text-[11px] text-[#8D8BDB]">
                        Upload files
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
