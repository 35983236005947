import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import backend_URL from "../../../config";

// Define the base query with token handling
const baseQuery = fetchBaseQuery({
  baseUrl: `${backend_URL}/api/v1`, // Adjust the base URL as needed
  prepareHeaders: (headers) => {
    const token = Cookies.get("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Define the revenues API
export const revenueApi = createApi({
  reducerPath: "revenuesApi",
  baseQuery,
  endpoints: (builder) => ({
    // Fetch all revenues with filtering, sorting, and pagination
    getRevenues: builder.query({
      query: ({
        page = 1,
        limit = 10000,
        sort = "name",
        order = "asc",
        search = "",
        status = "",
        ...filters
      }) => {
        let params = new URLSearchParams({
          page,
          limit,
          sort,
          order,
          search,
          status,
        });

        // Add date range filters if provided
        if (filters.startDate) params.append("startDate", filters.startDate);
        if (filters.endDate) params.append("endDate", filters.endDate);

        return {
          url: `/revenues?${params.toString()}`,
          method: "GET",
        };
      },
    }),
    // Fetch total revenue value
    getTotalRevenueValue: builder.query({
      query: () => ({
        url: "/revenues/total",
        method: "GET",
      }),
    }),
    // Create a new revenue
    createRevenue: builder.mutation({
      query: (revenue) => ({
        url: "/revenues",
        method: "POST",
        body: revenue,
      }),
    }),
    // Update an existing revenue by ID
    updateRevenue: builder.mutation({
      query: ({ id, updates }) => ({
        url: `/revenues/${id}`,
        method: "PUT",
        body: updates,
      }),
    }),
    // Delete revenues by IDs
    deleteRevenues: builder.mutation({
      query: (revenueIds) => ({
        url: "/revenues",
        method: "DELETE",
        body: { revenueIds },
      }),
    }),
  }),
});

// Export hooks for the revenues API
export const {
  useGetRevenuesQuery,
  useGetTotalRevenueValueQuery,
  useCreateRevenueMutation,
  useUpdateRevenueMutation,
  useDeleteRevenuesMutation,
} = revenueApi;
