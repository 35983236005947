import React from "react";
import { Toolbar } from "react-big-calendar";
import dayjs from "dayjs";

const CustomToolbar = ({ label, onNavigate, date, setOpen, open }) => {
  // Extract and format the month from the current date
  const formattedMonth = dayjs(date).format("MMMM");

  return (
    <div className="flex items-center justify-between space-x-4 mb-10">
      <div className="flex items-center justify-start space-x-4">
        {" "}
        <div
          onClick={() => onNavigate("PREV")} // Corrected the navigation to PREV for the left arrow
          className="w-[30px] h-[30px] px-[11px] py-[7px] bg-[#E7E7F9] dark:bg-[#706ea8]/60 rounded-[15px] justify-start items-center gap-2.5 inline-flex cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="17"
            viewBox="0 0 8 17"
            fill="none"
          >
            <path
              className="fill-current dark:text-[#EDEDFB] text-[#4A488E]"
              d="M7.50127 0.5C7.62779 0.5 7.75726 0.553708 7.85436 0.657963C8.04855 0.866473 8.04855 1.20767 7.85436 1.41618L1.2078 8.55292L7.75726 15.5854C7.95145 15.7939 7.95145 16.1351 7.75726 16.3436C7.56307 16.5521 7.24531 16.5521 7.05112 16.3436L0.145641 8.93203C-0.0485476 8.72352 -0.0485476 8.38232 0.145641 8.17381L7.14527 0.657963C7.24531 0.550548 7.37181 0.5 7.50127 0.5Z"
              fill-opacity="0.79"
            />
          </svg>
        </div>
        <div className="text-[#4A488E] dark:text-[#ededfb] text-lg font-medium ">
          {formattedMonth}
        </div>
        <div
          onClick={() => onNavigate("NEXT")}
          className="w-[30px] h-[30px] px-[11px] py-[7px] bg-[#E7E7F9] dark:bg-[#706ea8]/60  rounded-[15px] justify-start items-center gap-2.5 inline-flex cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="17"
            viewBox="0 0 8 17"
            fill="none"
          >
            <path
              className="fill-current dark:text-[#EDEDFB] text-[#4A488E]"
              d="M0.498727 16.5C0.372211 16.5 0.242736 16.4463 0.145642 16.342C-0.0485477 16.1335 -0.0485477 15.7923 0.145642 15.5838L6.7922 8.44708L0.242736 1.4146C0.048547 1.20609 0.048547 0.864894 0.242736 0.656383C0.436925 0.447874 0.754689 0.447874 0.948878 0.656383L7.85436 8.06797C8.04855 8.27648 8.04855 8.61768 7.85436 8.82619L0.854726 16.342C0.75469 16.4495 0.628187 16.5 0.498727 16.5Z"
              fill-opacity="0.79"
            />
          </svg>
        </div>{" "}
      </div>
    </div>
  );
};

export default CustomToolbar;
