// settingsDictionary.js
import { Backdrop } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  AccountPreferencesIcon,
  ProfileSettingsIcon,
  NotificationsIcon,
  SecurityIcon,
} from "./svgs";

const settingsDictionary = [
  {
    label: "Account Preferences",
    icon: <AccountPreferencesIcon />,
    path: "/settings/account-preferences",
  },
  {
    label: "Profile Settings",
    icon: <ProfileSettingsIcon />,
    path: "/settings/profile-settings",
  },
  {
    label: "Security",
    icon: <SecurityIcon />,
    path: "/settings/security",
  },
  // {
  //   label: "Notifications",
  //   icon: <NotificationsIcon />,
  //   path: "/settings/notifications",
  // },
  {
    label: "Go Back",
    icon: <IoMdArrowRoundBack />,
    path: "/",
  },
];

export default settingsDictionary;
