import React from 'react';
import parse, { domToReact, Element } from 'html-react-parser';
import DOMPurify from 'dompurify';

const RenderedContent = ({ content }) => {
  // Sanitize the content to avoid XSS attacks
  const sanitizedContent = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: [
      'b', 'i', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p', 'h1', 'h2', 'h3',
      'h4', 'blockquote', 'code', 'br', 'span', 'div'
    ],
    ALLOWED_ATTR: ['href', 'target', 'style', 'class'],
  });

  // Custom parser handler to ensure inline styles are kept and lists render properly
  const options = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs && domNode.attribs.style) {
        const style = domNode.attribs.style;
        return (
          <span style={parseStyleString(style)}>
            {domToReact(domNode.children)}
          </span>
        );
      }
    },
  };

  return (
    <div className="rendered-content text-black dark:text-white">
      {parse(sanitizedContent, options)}
    </div>
  );
};

// Helper function to convert inline CSS style string into React's style object
const parseStyleString = (styleString) => {
  const styleObject = {};
  const styleArray = styleString.split(';').filter(Boolean);
  styleArray.forEach((style) => {
    const [key, value] = style.split(':');
    if (key && value) {
      const camelCaseKey = key.trim().replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      styleObject[camelCaseKey] = value.trim();
    }
  });
  return styleObject;
};

export default RenderedContent;
