import React, { useRef } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

export const NewLeadDialog = ({
  openDialogNew,
  handleCloseDialogNew,
  Transition,
  handlePostDialog,
  isMobileView,
}) => {
  const user = useSelector((state) => state.user.user);

  const leadTitleRef = useRef();
  const companyRef = useRef();
  const nameRef = useRef();
  const roleRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const leadSourceRef = useRef();
  const statusRef = useRef();
  const commentRef = useRef();

  const handleSubmit = () => {
    const leadData = {
      leadTitle: leadTitleRef.current.value,
      Company: companyRef.current.value,
      name: nameRef.current.value,
      role: roleRef.current.value,
      phone: phoneRef.current.value,
      email: emailRef.current.value,
      leadSource: leadSourceRef.current.value,
      status: statusRef.current.value,
      commentsLead: [
        {
          user: user._id,
          comment: commentRef.current.value,
          isEdited: false,

          createdAt: new Date(),
        },
      ],
    };

    handlePostDialog(leadData);
  };

  return (
    <Dialog
      id="newLeadDialog"
      className="py-[36px] px-[55px]"
      fullWidth
      maxWidth="xl"
      open={openDialogNew}
      onClose={handleCloseDialogNew}
      TransitionComponent={Transition}
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: "#FAFAFE",
          borderRadius: "14px",
          "@media (prefers-color-scheme: dark)": {
            backgroundColor: "#2B2A49",
          },
        },
        className: "bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white",
      }}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(217, 217, 217, 0.75)",
          boxShadow: "none",
          "@media (prefers-color-scheme: dark)": {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        },
        className:
          "bg-[rgba(217, 217, 217, 0.75)] dark:bg-[rgba(0, 0, 0, 0.75)]",
      }}
    >
      <AppBar
        elevation={0}
        sx={{
          position: "relative",
          backgroundColor: "#FAFAFE",
          color: "black",
          "@media (prefers-color-scheme: dark)": {
            backgroundColor: "#2B2A49",
            color: "white",
          },
        }}
        className="bg-[#FAFAFE] dark:bg-[#2B2A49] text-black dark:text-white"
      >
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1, color: "#4A488E" }}
            variant="h6"
            component="div"
            className="dark:text-[#EDEDFB]"
          >
            New Lead
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialogNew}
            aria-label="close"
          >
            <CloseIcon sx={{ color: "#4A488E" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent className={`${isMobileView ? "px-[30px]" : "px-[55px]"}`}>
        <div
          className={`flex ${
            isMobileView ? "flex-col" : "flex-row"
          } justify-between`}
        >
          <div
            className={`flex flex-col ${
              isMobileView ? "w-full" : "w-[50%]"
            } gap-[16px]`}
          >
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Name
              </div>
              <input
                ref={leadTitleRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Company name
              </div>
              <input
                ref={companyRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Contact person
              </div>
              <input
                ref={nameRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Role
              </div>
              <input
                ref={roleRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Phone
              </div>
              <input
                ref={phoneRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Email
              </div>
              <input
                ref={emailRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Lead source
              </div>
              <input
                ref={leadSourceRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] w-[70%]"
              />
            </div>
            <div className="flex flex-row items-center justify-between">
              <div
                className="dark:text-[#EDEDFB]"
                style={{ color: "#434343", fontSize: 18 }}
              >
                Status
              </div>
              <div className="relative w-[70%]">
                <select
                  ref={statusRef}
                  className="appearance-none border-none dark:bg-[#3A3A68] dark:text-[#EDEDFB] text-[#4A488E] bg-[#E9E9FF] rounded-[4px] py-[14px] px-[25px] w-full"
                >
                  <option value="DEAL">DEAL</option>
                  <option value="DEAD">DEAD</option>
                  <option value="PROSPECT">PROSPECT</option>
                </select>
              </div>
            </div>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            className="dark:bg-[#EDEDFB]"
          />
          <div
            className={`flex flex-col gap-[24px] ${
              isMobileView ? "w-full" : "w-[40%]"
            }`}
          >
            <div className="text-[#434343] text-lg font-normal dark:text-[#EDEDFB]">
              Comments
            </div>
            <div
              className={` ${
                isMobileView ? "w-full" : "w-[70%]"
              } flex flex-col justify-center items-end`}
            >
              <textarea
                ref={commentRef}
                className="bg-[#E9E9FF] dark:bg-[#3A3A68] dark:text-[#EDEDFB] dark:placeholder-[#EDEDFB] text-[#4A488E] border-none rounded-[4px] py-[14px] px-[25px] h-[268px] w-full resize-none"
                placeholder="Write your comment here..."
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ fontSize: 16 }}
          autoFocus
          color="inherit"
          onClick={handleSubmit}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
