import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

// Define the status and priority options with both label and value
const statusOptions = [
  { label: 'Good Stock', color: '#00DD65', value: 'Good Stock' },
  { label: 'Low Stock', color: '#FFB84F', value: 'Low Stock' },
  { label: 'Moderate Stock', color: '#00B9F3', value: 'Moderate Stock' },
  { label: 'Sold', color: '#FF5555', value: 'Sold' },
  { label: 'Reserved', color: '#875EFF', value: 'Reserved' },
];

const priorityOptions = [
  { label: 'High', color: '#FF5656', value: 'High' },
  { label: 'Medium', color: '#FFB950', value: 'Medium' },
  { label: 'Low', color: '#00DD65', value: 'Low' },
];

const otherStatusOptions = [
  { label: 'Waiting', color: '#5956FF', value: 'Waiting' },
  { label: 'Completed', color: '#FF5656', value: 'Completed' },
  { label: 'Canceled', color: '#00DD65', value: 'Canceled' },
];

const revenueOptions = [
  { label: 'Completed', color: '#00DD65', value: 'Completed' },
  { label: 'Active', color: '#FFB950', value: 'Active' },
  { label: 'Canceled', color: '#FF5555', value: 'Canceled' },
];

const expenseOptions = [
  { label: 'Completed', color: '#FF5656', value: 'Completed' },
  { label: 'Active', color: '#00B9F3', value: 'Active' },
  { label: 'Canceled', color: '#00DD65', value: 'Canceled' },
];

// Function to retrieve the correct options based on type
const getOptionsByType = (type) => {
  switch (type) {
    case 'priority':
      return priorityOptions;
    case 'otherStatus':
      return otherStatusOptions;
    case 'revenue':
      return revenueOptions;
    case 'expense':
      return expenseOptions;
    default:
      return statusOptions;
  }
};

const FuseSpecialDropdown = ({ currentStatus, onChange, type = 'status' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  // Function to handle button click and toggle dropdown
  const handleButtonClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setIsOpen(!isOpen);
  };

  // Function to handle status change
  const handleStatusChange = (newStatus) => {
    onChange(newStatus.value);
    setIsOpen(false);
  };

  // Effect to close dropdown on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false); // Close the dropdown on scroll
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen]);

  // Get the correct options based on the type (status, priority, etc.)
  const options = getOptionsByType(type);

  // Find the current option by matching its value to the current status
  const currentOption = options.find(option => option.value === currentStatus) || options[0];

  return (
    <>
      <div
        className={`w-[140px] h-[49px] px-[21px] py-[15px] flex justify-center items-center gap-2.5 rounded-[58px] cursor-pointer dropdown-toggle`}
        style={{ backgroundColor: currentOption.color }}
        onClick={handleButtonClick}
      >
        <div className="text-white text-xs font-medium text-center w-full truncate">
          {currentOption.label}
        </div>
      </div>

      {isOpen && createPortal(
        <div
          className="absolute z-50 min-w-[140px] bg-[#383757] rounded-bl-md rounded-br-md shadow-md"
          style={{ top: dropdownPosition.top + 'px', left: dropdownPosition.left + 'px' }}
        >
          <div className="flex flex-col gap-2.5 p-2.5">
            {options.map((option) => (
              <div
                key={option.value}
                className={`w-full px-[21px] py-[15px] flex justify-center items-center gap-2.5 rounded-[29px] cursor-pointer`}
                style={{ backgroundColor: option.color }}
                onClick={() => handleStatusChange(option)}
              >
                <div className="text-white text-xs font-medium text-center w-full truncate">
                  {option.label}
                </div>
              </div>
            ))}
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default FuseSpecialDropdown;
