import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useGetClientsQuery } from "../redux/api/clientsApi";
import { useGetProjectsQuery } from "../redux/api/projectsApi";
import { useGetAllUsersQuery } from "../redux/api/authApi";
import {
  useUpdateTaskMutation,
  useSetUserFinishedWithTaskMutation,
} from "../redux/api/tasksApi";
import { toast } from "react-toastify";
import { useCreateNotificationMutation } from "../redux/api/notificationApi";
import { useSelector } from "react-redux";
import { useUpdateProjectStatusByActiveMutation } from "../redux/api/projectsApi";
import { useGetAccountsQuery } from "../redux/api/crm/accountsApi";
import { usePostCommentMutation } from "../redux/api/tasksApi";
import {
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  ListSubheader,
  TextField,
  Checkbox,
} from "@mui/material";

import {
  StarSVG,
  DescriptionSVG,
  ClientSVG,
  DepartmentSVG,
  AssignedUsersSVG,
  PrioritySVG,
  StatusSVG,
  TagsSVG,
  ActivitySVG,
  DueDateSVG,
} from "./utils/forms/TicketEditFormSvgs";
import { notify } from "./utils/notificationManager";

dayjs.extend(advancedFormat);

const departmentsList = [
  { name: "Development", color: "#e6e5ff" },

  { name: "SEO", color: "#e6e5ff" },

  { name: "PPC", color: "#e6e5ff" },

  { name: "SMM", color: "#e6e5ff" },
  { name: "Sales", color: "#e6e5ff" },

  { name: "Graphic Design", color: "#e6e5ff" },

  { name: "Copywriting", color: "#e6e5ff" },
];

const priorityList = [
  { name: "Low", color: "#38BDF8" },

  { name: "Medium", color: "#FACC15" },

  { name: "High", color: "#FF3939" },
];

const statusList = [
  { name: "To do", color: "#2ECC71" },

  { name: "In Progress", color: "#C026D3" },

  { name: "Completed", color: "#F472B6" },
];

const activityList = [
  { name: "Task", color: "#FFA500" },

  { name: "Meeting", color: "#FF6347" },

  { name: "Other", color: "#4682B4" },
];
const tagsListDefault = [
  { name: "Mentenanta", color: "#7E22CE" },

  { name: "E-mail MKT", color: "#7E22CE" },

  { name: "App", color: "#7E22CE" },
  { name: "Website", color: "#7E22CE" },
  { name: "Articole ", color: "#7E22CE" },
];
const getMenuItemClass = (isSelected) =>
  isSelected ? "dark:bg-violet-700" : "";

const TicketEditForm = ({
  ticket,

  ticketId,

  toggleDropdown,

  openNow,

  onClose,
}) => {
  const ITEM_HEIGHT = 48;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

        width: 250,

        backgroundColor: "#E6E5FF",

        paddingTop: ITEM_PADDING_TOP,

        paddingBottom: ITEM_PADDING_TOP,
      },

      sx: {
        backgroundColor: "var(--tw-bg-opacity, #FAFAFE)",

        borderRadius: "14px",

        "@media (prefers-color-scheme: dark)": {
          backgroundColor: "var(--tw-bg-opacity, #2b2a49)",
        },
      },

      className: "bg-[#E6E5FF] dark:bg-[#2B2A49] text-black dark:text-white",
    },
  };
  const { data: clientsData, isLoading: clientsLoading } = useGetClientsQuery();
  const { data: projectsData, isLoading: projectsLoading } =
    useGetProjectsQuery();
  const { data: accountsData } = useGetAccountsQuery();
  const { data: usersData, isLoading: usersLoading } = useGetAllUsersQuery();
  const [updateTask, { isLoading, isSuccess }] = useUpdateTaskMutation();
  const [postNotification] = useCreateNotificationMutation();
  const [title, setTitle] = useState(ticket.nume || "");
  const [selectedProject, setSelectedProject] = useState(ticket.proiect || "");
  const [description, setDescription] = useState(ticket.descriere || "");
  const [client, setClient] = useState(ticket.client || "");
  const [departments, setDepartments] = useState(ticket.department || []);
  const [assignedUsers, setAssignedUsers] = useState(ticket.persoana || []);
  const [selectedPriority, setSelectedPriority] = useState(
    ticket.priority || ""
  );
  const [selectedStatus, setSelectedStatus] = useState(ticket.status || "");
  const [selectedTags, setSelectedTags] = useState(ticket.tags || []);
  const [dueDate, setDueDate] = useState(dayjs(ticket.dataEnd) || dayjs());
  const [selectedActivity, setSelectedActivity] = useState(
    ticket.activity || []
  );
  const [searchText, setSearchText] = useState("");
  const [searchClientText, setSearchClientText] = useState("");
  const [searchProjectText, setSearchProjectText] = useState("");
  const [searchAssignedUsersText, setSearchAssignedUsersText] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [tagsList, setTagsList] = useState(tagsListDefault);
  const [isUserDone, setIsUserDone] = useState(false);
  const [setUserFinishedWithTask] = useSetUserFinishedWithTaskMutation();
  const [isPendingChecked, setIsPendingChecked] = useState(
    ticket.isPending || false
  );

  const handleSetIsPending = (event) => {
    const isChecked = event.target.checked;
    setIsPendingChecked(isChecked);
  };

  useEffect(() => {
    if (ticket?.tags) {
      const combinedTags = [
        ...tagsListDefault,
        ...ticket.tags.map((tag) => ({ name: tag, color: "#7E22CE" })),
      ];

      // Remove duplicates
      const uniqueTags = combinedTags.filter(
        (tag, index, self) =>
          index === self.findIndex((t) => t.name === tag.name)
      );

      setTagsList(uniqueTags);
    }
  }, [ticket]);

  const user = useSelector((state) => state.user);
  const handleOpen = () => {
    setOpen(true);
    setIsCalendarOpen(false); // Ensure calendar is closed when form is opened
  };

  const theme = useTheme();

  const [theme2, setTheme2] = useState(localStorage.getItem("theme"));

  useEffect(() => {
    const handleStorageChange = () => {
      setTheme2(localStorage.getItem("theme"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);

    if (onClose) onClose();
  };

  useEffect(() => {
    if (
      !projectsLoading &&
      !clientsLoading &&
      projectsData &&
      clientsData &&
      accountsData
    ) {
      const project = projectsData.projects.find(
        (proj) => proj._id === ticket.proiect
      );

      if (project) {
        const clientData = accountsData.accounts.find(
          (cli) => cli._id === project.client
        );

        setClient(clientData ? clientData.nameComp : "");
      }
    }
  }, [
    projectsLoading,

    clientsLoading,

    projectsData,

    clientsData,

    accountsData,

    ticket.proiect,
  ]);

  useEffect(() => {
    if (ticket?.isDoneArray) {
      const isDone = ticket.isDoneArray.some(
        (entry) => entry.userId === user.user._id && entry.isFinalized
      );
      setIsUserDone(isDone);
    }
  }, [ticket, user.user._id]);

  const [updateProjectStatusByActive] =
    useUpdateProjectStatusByActiveMutation();

  const [postComment] = usePostCommentMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure that the client ID is correctly set
    const clientId = accountsData?.accounts.find(
      (account) => account._id === client || account.nameComp === client
    )?._id;

    const updatedTicket = {
      id: ticketId,
      nume: title,
      descriere: description,
      client: clientId || client, // Ensure this is an ID
      department: departments,
      persoana: assignedUsers,
      priority: selectedPriority,
      proiect: selectedProject,
      status: selectedStatus,
      tags: selectedTags,
      dataEnd: dueDate.toISOString(),
      activity: selectedActivity,
      isPending: isPendingChecked,
    };

    // Track changes
    let changes = [];

    if (ticket.nume !== title) {
      changes.push(`Title changed from "${ticket.nume}" to "${title}"`);
    }
    if (ticket.descriere !== description) {
      changes.push(`Description changed`);
    }
    if (
      ticket.client !==
        accountsData?.accounts.find((a) => a.nameComp === client)?._id ||
      client !== ticket.client
    ) {
      const oldClientName = accountsData?.accounts.find(
        (account) => account._id === ticket.client
      )?.nameComp;
      const newClientName = accountsData?.accounts.find(
        (account) => account._id === clientId
      )?.nameComp;

      if (oldClientName !== newClientName) {
        changes.push(
          `Client changed from "${oldClientName}" to "${newClientName}"`
        );
      }
    }
    if (ticket.department.join(", ") !== departments.join(", ")) {
      changes.push(
        `Department changed from "${ticket.department.join(
          ", "
        )}" to "${departments.join(", ")}"`
      );
    }
    if (ticket.persoana.join(", ") !== assignedUsers.join(", ")) {
      const oldUsers = usersData?.users
        .filter((user) => ticket.persoana.includes(user._id))
        .map((user) => user.name)
        .join(", ");
      const newUsers = usersData?.users
        .filter((user) => assignedUsers.includes(user._id))
        .map((user) => user.name)
        .join(", ");
      changes.push(`Assigned to changed from "${oldUsers}" to "${newUsers}"`);
    }
    if (ticket.priority !== selectedPriority) {
      changes.push(
        `Priority changed from "${ticket.priority}" to "${selectedPriority}"`
      );
    }
    if (ticket.status !== selectedStatus) {
      changes.push(
        `Status changed from "${ticket.status}" to "${selectedStatus}"`
      );
    }
    if (ticket.tags.join(", ") !== selectedTags.join(", ")) {
      changes.push(
        `Tags changed from "${ticket.tags.join(", ")}" to "${selectedTags.join(
          ", "
        )}"`
      );
    }
    const formattedTicketDate = dayjs(ticket.dataEnd).format("YYYY-MM-DD");
    const formattedDueDate = dueDate.format("YYYY-MM-DD");
    if (formattedTicketDate !== formattedDueDate) {
      changes.push(
        `Due date changed from "${dayjs(ticket.dataEnd).format(
          "dddd, Do MMMM YYYY"
        )}" to "${dueDate.format("dddd, Do MMMM YYYY")}"`
      );
    }
    if (ticket.activity.join(", ") !== selectedActivity.join(", ")) {
      changes.push(
        `Activity changed from "${ticket.activity.join(
          ", "
        )}" to "${selectedActivity.join(", ")}"`
      );
    }
    if (ticket.isPending !== isPendingChecked) {
      changes.push(
        `Pending status changed from "${ticket.isPending ? "Yes" : "No"}" to "${
          isPendingChecked ? "Yes" : "No"
        }"`
      );
    }

    // Check if the "Are you done with this task?" status has changed
    const wasUserDone = ticket.isDoneArray.some(
      (entry) => entry.userId === user.user._id && entry.isFinalized
    );
    if (wasUserDone !== isUserDone) {
      changes.push(
        `Task completion status changed from "${
          wasUserDone ? "Yes" : "No"
        }" to "${isUserDone ? "Yes" : "No"}"`
      );
    }

    if (changes.length > 0) {
      const comment = changes.join("</br>");
      try {
        await postComment({ ticketId, comment }).unwrap();

        const changeSummary = `The following changes were made: ${changes.join(
          " & "
        )}`;
        const receiverIds = usersData.users
          .filter((user) => user.role === "Admin") // Filter users with "Admin" role
          .map((user) => user._id); // Extract the _id field

        await Promise.all(
          receiverIds.map(async (receiverId) => {
            const notification = {
              message: changeSummary,
              toPage: "/taskslist/My-Tasks",
              senderId: user.user._id,
              receiverId: receiverId,
            };

            return postNotification(notification).unwrap();
          })
        );
      } catch (error) {
        console.error("Failed to post comment or notification: ", error);
      }
      // Clear changes after posting the comment and sending the notifications
      changes = [];
    }

    try {
      await updateTask({ id: ticketId, body: updatedTicket }).unwrap();

      if (selectedStatus === "In Progress" && selectedProject) {
        await updateProjectStatusByActive({
          id: selectedProject,
          status: "In Progress",
        }).unwrap();
      }

      if (wasUserDone !== isUserDone) {
        await setUserFinishedWithTask({
          ticketId,
          userId: user.user._id,
          isFinalized: isUserDone,
        }).unwrap();
      }
      const oldUsers = usersData?.users
        .filter((user) => ticket.persoana.includes(user._id))
        .map((user) => user._id);

      const newUsers = usersData?.users
        .filter((user) => assignedUsers.includes(user._id))
        .map((user) => user._id);

      // Convert arrays to sets for easier difference calculation
      const oldUsersSet = new Set(oldUsers);
      const newUsersSet = new Set(newUsers);

      // Compute the difference
      const addedUsers = [...newUsersSet].filter(
        (user) => !oldUsersSet.has(user)
      );
      await Promise.all(
        addedUsers.map(async (userId) => {
          const notification = {
            message: "assigned you a new task " + `${title}`,
            toPage: "/taskslist/My-Tasks",
            senderId: user.user._id,
            receiverId: userId,
          };

          return postNotification(notification).unwrap();
        })
      );

      handleClose();
      toast.success("Task updated successfully");
    } catch (error) {
      console.error("Failed to update ticket: ", error);
    }
  };

  const handleChange = (setter) => (event) => {
    const {
      target: { value },
    } = event;

    setter(typeof value === "string" ? value.split(",") : value);
  };
  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsUserDone(checked);
  };

  const handleAddTag = () => {
    if (newTag) {
      const newTagItem = { name: newTag, color: "#7E22CE" };

      setTagsList([...tagsList, newTagItem]);

      setSelectedTags([...selectedTags, newTag]);

      setNewTag("");

      setIsCreatingTag(false);
    }
  };

  if (clientsLoading || usersLoading || projectsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <svg
        width="21"
        height="24"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleOpen}
      >
        <path
          id="Vector"
          d="M9.64724 0.498616C9.4869 0.338271 9.29654 0.211079 9.08704 0.124301C8.87754 0.0375229 8.653 -0.00714111 8.42624 -0.00714111C8.19948 -0.00714111 7.97494 0.0375229 7.76544 0.124301C7.55594 0.211079 7.36558 0.338271 7.20524 0.498616L1.16324 6.54162C0.972711
 6.732 0.83696 6.97017 0.77024 7.23112L0.16349 9.60237C0.150102 9.65489 0.150607 9.71 0.164957 9.76227C0.179307 9.81455 0.207008 9.86219 0.245338 9.90052C0.283668 9.93885 0.331309 9.96655 0.383582 9.9809C0.435855 9.99525 0.490962 9.99575 0.54349 9.98237L2.91524
 9.37637C3.17637 9.30973 3.41472 9.17397 3.60524 8.98337L9.64724 2.94037C9.97099 2.61655 10.1529 2.17739 10.1529 1.71949C10.1529 1.26159 9.97099 0.822434 9.64724 0.498616ZM7.64724 0.940616C7.85384 0.733979 8.13407 0.617878 8.42628 0.617855C8.71848 0.617831
 8.99873 0.733887 9.20537 0.940491C9.412 1.14709 9.5281 1.42732 9.52813 1.71953C9.52815 2.01173 9.41209 2.29198 9.20549 2.49862L8.65374 3.05037L7.09574 1.49212L7.64724 0.940616ZM6.65374 1.93412L8.21174 3.49237L3.16324 8.54137C3.05206 8.65268 2.91293 8.73196
 2.76049 8.77087L0.89974 9.24637L1.37574 7.38612C1.41474 7.23362 1.49399 7.09462 1.60499 6.98362L6.65374 1.93412Z"
          fill="#8D8BDB"
        />
      </svg>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",

            top: "50%",

            left: "50%",

            transform: "translate(-50%, -50%)",

            width: "100%",

            height: "100%",

            bgcolor: "#CAC9F1",

            boxShadow: 24,

            p: 4,
          }}
          className="bg-[#E6E5FF] dark:bg-[#3A3A68] text-[#4A488E] dark:text-[#EDEDFB] overflow-y-auto scrolling-touch "
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <div className="flex justify-between mb-[34px]">
              <p className="text-[#4A488E] dark:text-[#EDEDFB]">Edit task</p>

              <div className="text-[#4A488E] dark:text-white cursor-pointer">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleClose();
                    if (toggleDropdown) {
                      toggleDropdown();
                    }
                  }}
                  className="fill-current"
                >
                  <g id="ph:x">
                    <path
                      id="Vector"
                      d="M29.724 28.0884C29.8314 28.1958 29.9166 28.3233 29.9747 28.4637C30.0329 28.604 30.0628 28.7545 30.0628 28.9064C30.0628 29.0583 30.0329 29.2088 29.9747 29.3491C29.9166 29.4895 29.8314 29.617 29.724 29.7245C29.6165 29.8319 29.489 29.9171 29.3486 29.9752C29.2083 30.0334 29.0578 30.0633 28.9059 30.0633C28.754 30.0633 28.6036 30.0334 28.4632 29.9752C28.3228 29.9171 28.1953 29.8319 28.0879 29.7245L18.4997 20.1348L8.91146 29.7245C8.6945 29.9414 8.40024 30.0633 8.09342 30.0633C7.78659 30.0633 7.49233 29.9414 7.27537 29.7245C7.05841 29.5075 6.93652 29.2132 6.93652 28.9064C6.93652 28.5996 7.05841 28.3053 7.27537 28.0884L16.865 18.5002L7.27537 8.91195C7.05841 8.69499 6.93652 8.40073 6.93652 8.0939C6.93652 7.78708 7.05841 7.49282 7.27537 7.27586C7.49233 7.0589 7.78659 6.93701 8.09342 6.93701C8.40024 6.93701 8.6945 7.0589 8.91146 7.27586L18.4997 16.8655L28.0879 7.27586C28.3048 7.0589 28.5991 6.93701 28.9059 6.93701C29.2127 6.93701 29.507 7.0589 29.724 7.27586C29.9409 7.49282 30.0628 7.78708 30.0628 8.0939C30.0628 8.40073 29.9409 8.69499 29.724 8.91195L20.1343 18.5002L29.724 28.0884Z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit} className="mb-[-45px]">
              <div className="mb-[34px]">
                <label
                  htmlFor="title"
                  className="text-[#4A488E] text-[18px] flex gap-[5px] mb-[10px] dark:text-[#EDEDFB]"
                >
                  <StarSVG />
                  Task title
                </label>

                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full h-[40px] border-none mt-[10px] rounded-[6px] bg-[#E7E7F9] dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                />
              </div>

              <div className="mb-[34px]">
                <label
                  htmlFor="description"
                  className="text-[#4A488E] text-[18px] flex gap-[5px] mb-[10px] dark:text-[#EDEDFB]"
                >
                  <DescriptionSVG />
                  Task description
                </label>

                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={setDescription}
                  className="h-[250px] overflow-auto mb-[10px] text-black"
                />
              </div>

              <div className="w-full flex gap-[45px]">
                <div className="w-6/12">
                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="client"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <ClientSVG />
                        Client
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          value={client} // This should always be an ID
                          onChange={(e) => setClient(e.target.value)} // e.target.value should be an ID
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            const clientData = accountsData?.accounts.find(
                              (client) => client._id === selected
                            );
                            return clientData ? clientData.nameComp : selected;
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <ListSubheader className="bg-[#E6E5FF] dark:bg-[#2b2a49]">
                            <TextField
                              size="small"
                              className="placeholder-yellow-400"
                              placeholder="Search client"
                              fullWidth
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              onClick={(e) => e.stopPropagation()}
                              InputProps={{
                                sx: {
                                  border: "none",

                                  bgcolor: "white",

                                  dark: { bgcolor: "#383757" },

                                  borderRadius: "52px",

                                  position: "relative",
                                },

                                classes: {
                                  input: "placeholder-indigo-800",
                                },
                              }}
                            />

                            <img
                              src="/images/blue-search.svg"
                              alt=""
                              className="absolute top-2 right-7"
                            />
                          </ListSubheader>
                          {accountsData?.accounts
                            .filter(
                              (clientItem) => clientItem.isActive !== false
                            ) // Filter out inactive accounts
                            .filter(
                              (clientItem) =>
                                clientItem.nameComp
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase()) // Search filter
                            )
                            .slice() // Create a shallow copy to prevent modifying the original array
                            .sort((a, b) =>
                              a.nameComp.localeCompare(b.nameComp)
                            ) // Sort by nameComp
                            .map((clientItem) => (
                              <MenuItem
                                className={`text-[#4A488E] dark:text-[#EDEDFB] ${getMenuItemClass(
                                  clientItem._id === client
                                )}`}
                                key={clientItem._id}
                                value={clientItem._id}
                                style={{
                                  fontWeight: theme.typography.fontWeightMedium,
                                }}
                              >
                                {clientItem.nameComp}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="project"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <ClientSVG />
                        Project
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          value={selectedProject}
                          onChange={(e) => setSelectedProject(e.target.value)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (!selected) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            const projectData = projectsData?.projects.find(
                              (project) => project._id === selected
                            );

                            return projectData ? projectData.nume : selected;
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <ListSubheader className="bg-[#E6E5FF] dark:bg-[#2b2a49]">
                            <TextField
                              size="small"
                              className="placeholder-yellow-400"
                              placeholder="Search project"
                              fullWidth
                              value={searchProjectText}
                              onChange={(e) =>
                                setSearchProjectText(e.target.value)
                              }
                              onClick={(e) => e.stopPropagation()}
                              InputProps={{
                                sx: {
                                  border: "none",

                                  bgcolor: "white",

                                  dark: { bgcolor: "#383757" },

                                  borderRadius: "52px",

                                  position: "relative",
                                },

                                classes: {
                                  input: "placeholder-indigo-800",
                                },
                              }}
                            />

                            <img
                              src="/images/blue-search.svg"
                              alt=""
                              className="absolute top-2 right-7"
                            />
                          </ListSubheader>

                          {projectsData?.projects

                            .filter(
                              (project) =>
                                project.isActive !== false && // Filter out inactive projects
                                project.nume
                                  .toLowerCase()
                                  .includes(searchProjectText.toLowerCase())
                            )

                            .map((project) => (
                              <MenuItem
                                className={`text-[#4A488E] dark:text-[#EDEDFB] ${getMenuItemClass(
                                  project._id === selectedProject
                                )}`}
                                key={project._id}
                                value={project._id}
                                style={{
                                  fontWeight: theme.typography.fontWeightMedium,
                                }}
                              >
                                {project.nume}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="department"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <DepartmentSVG />
                        Department
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          multiple
                          displayEmpty
                          value={departments}
                          onChange={handleChange(setDepartments)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          {departmentsList.map((department) => (
                            <MenuItem
                              className={`text-[#4A488E] dark:text-[#EDEDFB] ${getMenuItemClass(
                                departments.includes(department.name)
                              )}`}
                              key={department.name}
                              value={department.name}
                              style={{
                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {department.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="assignedUsers"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <AssignedUsersSVG />
                        Assigned to
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          multiple
                          displayEmpty
                          value={assignedUsers}
                          onChange={handleChange(setAssignedUsers)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            const selectedUsers = usersData?.users.filter(
                              (user) => selected.includes(user._id)
                            );

                            return selectedUsers
                              ? selectedUsers

                                  .map((user) => user.name)

                                  .join(", ")
                              : selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>
                          {usersData?.users
                            .slice() // Create a shallow copy to prevent modifying the original array
                            .sort((a, b) => {
                              const firstNameA = a.name.split(" ")[0];
                              const firstNameB = b.name.split(" ")[0];
                              return firstNameA.localeCompare(firstNameB);
                            })
                            .map((user) => (
                              <MenuItem
                                className={`text-[#4A488E] dark:text-[#EDEDFB] ${getMenuItemClass(
                                  assignedUsers.includes(user._id)
                                )}`}
                                key={user._id}
                                value={user._id}
                                style={{
                                  fontWeight: theme.typography.fontWeightMedium,
                                }}
                              >
                                {user.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="priority"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <PrioritySVG />
                        Priority
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          value={selectedPriority}
                          onChange={(e) => setSelectedPriority(e.target.value)}
                          input={<OutlinedInput />}
                          renderValue={(selected) =>
                            selected || (
                              <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                Placeholder
                              </em>
                            )
                          }
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>

                          {priorityList.map((priority) => (
                            <MenuItem
                              className={`rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 ${getMenuItemClass(
                                selectedPriority === priority.name
                              )}`}
                              key={priority.name}
                              value={priority.name}
                              style={{
                                marginTop: "10px",

                                backgroundColor: priority.color,

                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {priority.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="w-6/12">
                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="status"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <StatusSVG />
                        Status
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.target.value)}
                          input={<OutlinedInput />}
                          renderValue={(selected) =>
                            selected || (
                              <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                Placeholder
                              </em>
                            )
                          }
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>

                          {statusList.map((status) => (
                            <MenuItem
                              className={`rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 ${getMenuItemClass(
                                selectedStatus === status.name
                              )}`}
                              key={status.name}
                              value={status.name}
                              style={{
                                marginTop: "10px",

                                backgroundColor: status.color,

                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {status.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="tags"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <TagsSVG />
                        Tags
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          multiple
                          displayEmpty
                          value={selectedTags}
                          onChange={handleChange(setSelectedTags)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          <ListSubheader className="bg-[#E6E5FF] dark:bg-[#383757]">
                            {isCreatingTag && (
                              <div className="flex justify-center gap-[5px]  items-center  bg-[#e6e5ff]  dark:text-[#EDEDFB] dark:bg-[#383757]">
                                <TextField
                                  value={newTag}
                                  onChange={(e) => setNewTag(e.target.value)}
                                  placeholder="New tag name"
                                  className=" bg-[#e6e5ff] dark:text-[#EDEDFB] dark:bg-[#383757]"
                                  InputProps={{
                                    className: "dark:text-[#EDEDFB]",
                                  }}
                                />

                                <button
                                  onClick={handleAddTag}
                                  className=" py-1 px-3 bg-[#4A488E] dark:bg-[#383757] text-white rounded text-[20px] "
                                >
                                  +
                                </button>
                              </div>
                            )}
                          </ListSubheader>

                          <MenuItem disabled value="">
                            <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                              Placeholder
                            </em>
                          </MenuItem>

                          {tagsList.map((tag) => (
                            <MenuItem
                              className={`rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 ${getMenuItemClass(
                                selectedTags.includes(tag.name)
                              )}`}
                              key={tag.name}
                              value={tag.name}
                              style={{
                                marginTop: "10px",

                                backgroundColor: tag.color,

                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {tag.name}
                            </MenuItem>
                          ))}

                          <ListSubheader className="bg-[#e6e5ff] dark:bg-[#383757]">
                            <MenuItem
                              onClick={() => setIsCreatingTag(true)}
                              className="bg-[#e6e5ff] dark:bg-[#383757] dark:text-[#EDEDFB]"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 4.5v15m7.5-7.5h-15"
                                />
                              </svg>
                              Add new tag
                            </MenuItem>
                          </ListSubheader>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="activity"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <ActivitySVG />
                        Activity
                      </label>
                    </div>

                    <div className="w-full">
                      <FormControl sx={{ width: 400 }}>
                        <Select
                          multiple
                          displayEmpty
                          value={selectedActivity}
                          onChange={handleChange(setSelectedActivity)}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <em className="text-[#4A488E] dark:text-[#EDEDFB]">
                                  Placeholder
                                </em>
                              );
                            }

                            return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                          className="bg-white dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                        >
                          {activityList.map((activity) => (
                            <MenuItem
                              className={`rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 ${getMenuItemClass(
                                selectedActivity.includes(activity.name)
                              )}`}
                              key={activity.name}
                              value={activity.name}
                              style={{
                                marginTop: "10px",

                                backgroundColor: activity.color,

                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              {activity.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mb-[20px] flex w-full items-center">
                    <div className="w-full">
                      <label
                        htmlFor="dueDate"
                        className="text-[#4A488E] text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                      >
                        <DueDateSVG />
                        Due date
                      </label>
                    </div>

                    <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="ml-5 relative">
                          <p
                            onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                            className="text-[15px] text-[#4A488E] dark:text-[#EDEDFB]"
                            style={{ cursor: "pointer" }}
                          >
                            {dueDate.format("dddd, Do MMMM")}
                          </p>

                          {isCalendarOpen && (
                            <div className="absolute z-10 top-[-342px] left-[-148px] bg-[#4A488E] dark:bg-[#383757]">
                              <DateCalendar
                                value={dueDate}
                                onChange={(newValue) => {
                                  setDueDate(newValue);
                                  setIsCalendarOpen(false); // Auto-close the date picker after selection
                                }}
                                sx={{
                                  "& .MuiPickersDay-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersDay-root.Mui-selected": {
                                    backgroundColor: theme2
                                      ? "#8D8BDB"
                                      : "#0074FD",
                                  },

                                  "& .MuiTypography-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersCalendarHeader-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiIconButton-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersArrowSwitcher-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },

                                  "& .MuiPickersYear-root": {
                                    color: theme2 ? "#EDEDFB" : "#000",
                                  },
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div>
                    <label>
                      Are you done with this task?
                      <Checkbox
                        color="secondary"
                        checked={isUserDone}
                        onChange={handleCheckboxChange}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      Is pending approval?
                      <Checkbox
                        color="secondary"
                        checked={isPendingChecked}
                        onChange={handleSetIsPending}
                      />
                    </label>
                  </div>
                  <div className="flex justify-end gap-[31px] mt-[50px]">
                    <button
                      onClick={() => {
                        handleClose();
                        if (toggleDropdown) {
                          toggleDropdown();
                        }
                      }}
                      className="py-[12px] px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="py-[12px] px-[22px] text-[#4A488E] border  border-[#4A488E] bg-[#E7E7F9] rounded-[6px]"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default TicketEditForm;
