import React, { useEffect, useState } from "react";
import { useGetCategoriesQuery } from "../../../redux/api/inventory/category";
import { useGetProductsQuery } from "../../../redux/api/inventory/products";

const FilterComponent = ({
  selectedFilters,
  setSelectedFilters,
  visbileColumns,
  setVisibleColumns,
  setFilters,
  filters,
  isRestockPage = false, // new prop to differentiate between product and restock
}) => {
  const { data: categories } = useGetCategoriesQuery();

  // State management for dropdown visibility and selected filters
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [isFilterTableDropdownOpen, setIsFilterTableDropdownOpen] =
    useState(false);

  // State for managing which accordion is open
  const [openAccordions, setOpenAccordions] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // State to manage accordion filter data based on the page
  const defaultProductAccordionData = [
    {
      name: "Status",
      fields: [
        { label: "Good stock level", value: false },
        { label: "Running low", value: false },
        { label: "Moderate stock", value: false },
      ],
    },
    {
      name: "Stock",
      fields: [
        { label: "Highest stock", value: false },
        { label: "Lowest stock", value: false },
      ],
    },
    {
      name: "Closest expiry date",
      fields: [
        { label: "Closest expiry date", value: false },
        { label: "Furthest expiry date", value: false },
      ],
    },
    {
      name: "Upload date",
      fields: [
        { label: "Newest", value: false },
        { label: "Oldest", value: false },
      ],
    },
    {
      name: "Product category",
      fields: [], // Initialize with empty fields for categories
    },
  ];

  const defaultRestockAccordionData = [
    {
      name: "Status",
      fields: [
        { label: "Pending", value: false },
        { label: "Completed", value: false },
        { label: "Cancelled", value: false },
      ],
    },
    {
      name: "Priority",
      fields: [
        { label: "High", value: false },
        { label: "Medium", value: false },
        { label: "Low", value: false },
      ],
    },
    {
      name: "Order Date",
      fields: [
        { label: "Newest", value: false },
        { label: "Oldest", value: false },
      ],
    },
    {
      name: "Expected Delivery",
      fields: [
        { label: "Closest delivery date", value: false },
        { label: "Furthest delivery date", value: false },
      ],
    },
  ];

  const [accordionData, setAccordionData] = useState(
    isRestockPage ? defaultRestockAccordionData : defaultProductAccordionData
  );

  useEffect(() => {
    // Update category accordion fields dynamically for product page
    if (categories && categories.categories && !isRestockPage) {
      const productCategoryFields = categories.categories.map((c) => ({
        label: c.name,
        value: false,
      }));

      setAccordionData((prevData) => {
        const updatedData = [...prevData];
        updatedData.find(
          (accordion) => accordion.name === "Product category"
        ).fields = productCategoryFields;
        return updatedData;
      });
    }
  }, [categories, isRestockPage]);

  const getFiltersFromAccordionData = () => {
    const filtersForUpdate = {};

    accordionData.forEach((accordion, index) => {
      accordion?.fields?.forEach((field) => {
        if (field.value) {
          switch (accordion.name) {
            case "Status":
              filtersForUpdate.status = filtersForUpdate.status || [];
              filtersForUpdate.status.push(field.label);
              break;
            case "Stock":
            case "Priority": // For restock page
              filtersForUpdate[accordion.name.toLowerCase()] =
                filtersForUpdate[accordion.name.toLowerCase()] || [];
              filtersForUpdate[accordion.name.toLowerCase()].push(field.label);
              break;
            case "Closest expiry date":
            case "Upload date":
            case "Order Date": // For restock page
            case "Expected Delivery": // For restock page
              filtersForUpdate[accordion.name.toLowerCase().replace(" ", "_")] =
                field.label;
              break;
            case "Product category":
              filtersForUpdate.category = filtersForUpdate.category || [];
              filtersForUpdate.category.push(field.label);
              break;
            // Add other cases as needed
          }
        }
      });
    });
    setFilters(filtersForUpdate);
  };

  const useFilters = () => {
    getFiltersFromAccordionData();
  };

  // Function to toggle the filter dropdown
  const toggleFilterDropdown = () => {
    setIsFilterDropdownOpen((prev) => !prev);
  };

  // Function to toggle the filter table dropdown
  const toggleFilterTableDropdown = () => {
    setIsFilterTableDropdownOpen((prev) => !prev);
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (filter) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
    setVisibleColumns((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  // Function to handle checkbox changes in the accordion
  const handleAccordionCheckboxChange = (accordionIndex, fieldIndex) => {
    setAccordionData((prev) => {
      // Create a copy of the previous accordion data
      const updatedAccordionData = [...prev];
      // Toggle the value of the checkbox at the specified index
      const updatedFields = updatedAccordionData[accordionIndex].fields.map(
        (field, idx) =>
          idx === fieldIndex ? { ...field, value: !field.value } : field
      );
      // Return the new state with updated fields
      return updatedAccordionData.map((accordion, idx) =>
        idx === accordionIndex
          ? { ...accordion, fields: updatedFields }
          : accordion
      );
    });
  };

  // Function to toggle the accordion open/close state
  const toggleAccordion = (index) => {
    setOpenAccordions((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className="h-[34px] flex items-center gap-[15px]">
      {/* Filter Dropdown Button */}
      <div className="relative flex flex-row gap-2">
        <div className="w-5 h-5 cursor-pointer" onClick={toggleFilterDropdown}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              className="stroke-current text-[#4A488E] dark:text-white"
              d="M17.7087 10.5002H7.41283M3.77866 10.5002H2.29199M3.77866 10.5002C3.77866 10.0184 3.97006 9.55635 4.31075 9.21566C4.65144 8.87497 5.11352 8.68357 5.59533 8.68357C6.07714 8.68357 6.53921 8.87497 6.8799 9.21566C7.22059 9.55635 7.41199 10.0184 7.41199 10.5002C7.41199 10.982 7.22059 11.4441 6.8799 11.7848C6.53921 12.1255 6.07714 12.3169 5.59533 12.3169C5.11352 12.3169 4.65144 12.1255 4.31075 11.7848C3.97006 11.4441 3.77866 10.982 3.77866 10.5002ZM17.7087 16.0061H12.9187M12.9187 16.0061C12.9187 16.488 12.7268 16.9506 12.386 17.2914C12.0453 17.6321 11.5831 17.8236 11.1012 17.8236C10.6193 17.8236 10.1573 17.6313 9.81658 17.2906C9.47589 16.95 9.28449 16.4879 9.28449 16.0061M12.9187 16.0061C12.9187 15.5241 12.7268 15.0624 12.386 14.7216C12.0453 14.3808 11.5831 14.1894 11.1012 14.1894C10.6193 14.1894 10.1573 14.3808 9.81658 14.7215C9.47589 15.0622 9.28449 15.5243 9.28449 16.0061M9.28449 16.0061H2.29199M17.7087 4.9944H15.1212M11.487 4.9944H2.29199M11.487 4.9944C11.487 4.51259 11.6784 4.05051 12.0191 3.70982C12.3598 3.36913 12.8218 3.17773 13.3037 3.17773C13.5422 3.17773 13.7785 3.22472 13.9989 3.31602C14.2193 3.40732 14.4195 3.54113 14.5882 3.70982C14.7569 3.87852 14.8907 4.07878 14.982 4.29919C15.0733 4.5196 15.1203 4.75583 15.1203 4.9944C15.1203 5.23297 15.0733 5.4692 14.982 5.68961C14.8907 5.91002 14.7569 6.11028 14.5882 6.27898C14.4195 6.44767 14.2193 6.58149 13.9989 6.67278C13.7785 6.76408 13.5422 6.81107 13.3037 6.81107C12.8218 6.81107 12.3598 6.61967 12.0191 6.27898C11.6784 5.93829 11.487 5.47621 11.487 4.9944Z"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <div className="text-indigo-800 dark:text-[#EDEDFB] text-[15px] font-normal">
          Filter
        </div>
        {isFilterDropdownOpen && (
          <div className="absolute right-0 top-12 w-56 h-fit dark:bg-[#383757] shadow-lg rounded-[3px]">
            <div className="w-full flex justify-end p-4">
              <svg
                onClick={toggleFilterDropdown}
                className="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M9.85403 9.14653C9.90048 9.19298 9.93733 9.24813 9.96247 9.30883C9.98762 9.36953 10.0006 9.43458 10.0006 9.50028C10.0006 9.56598 9.98762 9.63103 9.96247 9.69173C9.93733 9.75242 9.90048 9.80757 9.85403 9.85403C9.80757 9.90048 9.75242 9.93733 9.69173 9.96247C9.63103 9.98762 9.56598 10.0006 9.50028 10.0006C9.43458 10.0006 9.36953 9.98762 9.30883 9.96247C9.24813 9.93733 9.19298 9.90048 9.14653 9.85403L5.00028 5.70715L0.854028 9.85403C0.760208 9.94785 0.63296 10.0006 0.500278 10.0006C0.367596 10.0006 0.240348 9.94785 0.146528 9.85403C0.0527077 9.76021 2.61548e-09 9.63296 0 9.50028C-2.61548e-09 9.3676 0.0527077 9.24035 0.146528 9.14653L4.2934 5.00028L0.146528 0.854028C0.0527077 0.760208 -9.88558e-10 0.63296 0 0.500278C9.88558e-10 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527077 0.367596 9.88558e-10 0.500278 0C0.63296 -9.88558e-10 0.760208 0.0527077 0.854028 0.146528L5.00028 4.2934L9.14653 0.146528C9.24035 0.0527077 9.3676 -2.61548e-09 9.50028 0C9.63296 2.61548e-09 9.76021 0.0527077 9.85403 0.146528C9.94785 0.240348 10.0006 0.367596 10.0006 0.500278C10.0006 0.63296 9.94785 0.760208 9.85403 0.854028L5.70715 5.00028L9.85403 9.14653Z"
                  fill="#EDEDFB"
                />
              </svg>
            </div>
            <div className="h-[23px] mb-6 text-[#ededfb] text-[15px] font-normal p-4">
              Filter by
            </div>
            {/* Accordions */}
            {accordionData.map((accordion, index) => (
              <div
                key={index}
                className={`mb-2 cursor-pointer border ${
                  !openAccordions[index]
                    ? " border-none text-white"
                    : " border-none text-[#383757] font-semibold"
                }`}
              >
                <div
                  onClick={() => toggleAccordion(index)}
                  className={`w-full flex items-center justify-between p-4 ${
                    !openAccordions[index]
                      ? "bg-[#383757] border-none text-white "
                      : "bg-gray-200 border-none text-[#383757] font-semibold"
                  }`}
                >
                  <span>{accordion.name} </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                    className={`transition-transform duration-300 ${
                      !openAccordions[index] ? "rotate-180" : ""
                    }`}
                  >
                    <path
                      className={`fill-current ${
                        !openAccordions[index] ? "text-white" : "text-[#383757]"
                      }`}
                      d="M8.90234 5.09091C8.90234 5.1665 8.87246 5.24385 8.81445 5.30186C8.69844 5.41787 8.50859 5.41787 8.39258 5.30186L4.42168 1.33096L0.508789 5.24385C0.392773 5.35986 0.202929 5.35986 0.0869137 5.24385C-0.0291018 5.12783 -0.0291018 4.93799 0.0869137 4.82197L4.21074 0.696386C4.32676 0.580371 4.5166 0.580371 4.63262 0.696386L8.81445 4.87822C8.87422 4.93799 8.90234 5.01357 8.90234 5.09091Z"
                    />
                  </svg>
                </div>
                {openAccordions[index] && (
                  <div className="pl-4 pt-2">
                    {/* Render fields inside accordion */}
                    {accordion.fields?.map((field, idx) => (
                      <div
                        key={idx}
                        className="flex items-center justify-between px-4 py-2"
                      >
                        <div className="ml-2 text-[#ededfb] font-normal text-[15px]">
                          {field.label}
                        </div>
                        <input
                          type="checkbox"
                          checked={field.value}
                          onChange={() =>
                            handleAccordionCheckboxChange(index, idx)
                          }
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div className="w-full flex items-center justify-center">
              <button
                onClick={useFilters}
                className="h-8 px-10 py-2 bg-[#ededfb] rounded-[3px] justify-center items-center gap-2.5 inline-flex"
              >
                <div className="text-[#383757] text-[13px] font-normal font-['Inter']">
                  See all options
                </div>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Filter Table Dropdown Button */}
      <div className="relative ml-4 flex flex-row gap-2">
        <div
          className="w-5 h-5 cursor-pointer"
          onClick={toggleFilterTableDropdown}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              className="stroke-current text-[#4A488E] dark:text-white"
              d="M3.64551 9.21875H15.3779M3.64551 9.21875L8.57272 3.75639M3.64551 9.21875L8.57272 14.6811M15.3779 9.21875L10.4507 14.6811M15.3779 9.21875L10.4507 3.75639"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="text-indigo-800 dark:text-[#EDEDFB] text-[15px] font-normal">
          Filter Table
        </div>
        {isFilterTableDropdownOpen && (
          <div className="absolute right-0 top-12 w-56 h-fit dark:bg-[#383757] shadow-lg rounded-[3px] p-4">
            <div className="w-full flex justify-end">
              <svg
                onClick={toggleFilterTableDropdown}
                className="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M9.85403 9.14653C9.90048 9.19298 9.93733 9.24813 9.96247 9.30883C9.98762 9.36953 10.0006 9.43458 10.0006 9.50028C10.0006 9.56598 9.98762 9.63103 9.96247 9.69173C9.93733 9.75242 9.90048 9.80757 9.85403 9.85403C9.80757 9.90048 9.75242 9.93733 9.69173 9.96247C9.63103 9.98762 9.56598 10.0006 9.50028 10.0006C9.43458 10.0006 9.36953 9.98762 9.30883 9.96247C9.24813 9.93733 9.19298 9.90048 9.14653 9.85403L5.00028 5.70715L0.854028 9.85403C0.760208 9.94785 0.63296 10.0006 0.500278 10.0006C0.367596 10.0006 0.240348 9.94785 0.146528 9.85403C0.0527077 9.76021 2.61548e-09 9.63296 0 9.50028C-2.61548e-09 9.3676 0.0527077 9.24035 0.146528 9.14653L4.2934 5.00028L0.146528 0.854028C0.0527077 0.760208 -9.88558e-10 0.63296 0 0.500278C9.88558e-10 0.367596 0.0527077 0.240348 0.146528 0.146528C0.240348 0.0527077 0.367596 9.88558e-10 0.500278 0C0.63296 -9.88558e-10 0.760208 0.0527077 0.854028 0.146528L5.00028 4.2934L9.14653 0.146528C9.24035 0.0527077 9.3676 -2.61548e-09 9.50028 0C9.63296 2.61548e-09 9.76021 0.0527077 9.85403 0.146528C9.94785 0.240348 10.0006 0.367596 10.0006 0.500278C10.0006 0.63296 9.94785 0.760208 9.85403 0.854028L5.70715 5.00028L9.85403 9.14653Z"
                  fill="#EDEDFB"
                />
              </svg>
            </div>

            {/* Filter options as checkboxes */}
            {Object.keys(selectedFilters).map((filter) => (
              <div key={filter} className="mb-2">
                <input
                  type="checkbox"
                  id={filter}
                  checked={selectedFilters[filter]}
                  onChange={() => handleCheckboxChange(filter)}
                />
                <label htmlFor={filter} className="ml-2 text-[#ededfb]">
                  {filter}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterComponent;
