import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Modal from "@mui/material/Modal";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import { useGetAccountsQuery } from "../redux/api/crm/accountsApi";

import { useGetProjectsQuery } from "../redux/api/projectsApi";

import { useGetAllUsersQuery } from "../redux/api/authApi";

import { usePostTaskMutation } from "../redux/api/tasksApi";

import dayjs from "dayjs";

import advancedFormat from "dayjs/plugin/advancedFormat";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { useCreateNotificationMutation } from "../redux/api/notificationApi";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { useUpdateProjectStatusMutation } from "../redux/api/projectsApi";
import SelectInput from "./utils/forms/TicketSelectInput";

import {
  ActivitySVG,
  AssignedUsersSVG,
  ClientSVG,
  DepartmentSVG,
  DescriptionSVG,
  DueDateSVG,
  PrioritySVG,
  StarSVG,
  StatusSVG,
  TagsSVG,
} from "./utils/forms/TicketEditFormSvgs";
import { useTheme } from "@emotion/react";

dayjs.extend(advancedFormat);

const style = {
  position: "absolute",

  top: "50%",

  left: "50%",

  transform: "translate(-50%, -50%)",

  width: "100%",

  height: "100%",

  boxShadow: 24,

  p: 4,
};

const departmentsList = [
  { name: "Development", color: "#e6e5ff" },

  { name: "SEO", color: "#e6e5ff" },

  { name: "PPC", color: "#e6e5ff" },

  { name: "SMM", color: "#e6e5ff" },
  { name: "Sales", color: "#e6e5ff" },

  { name: "Graphic Design", color: "#e6e5ff" },

  { name: "Copywriting", color: "#e6e5ff" },
];

const priorityList = [
  { name: "Low", color: "#38BDF8" },

  { name: "Medium", color: "#FACC15" },

  { name: "High", color: "#FF3939" },
];

const statusList = [
  { name: "To do", color: "#2ECC71" },

  { name: "In Progress", color: "#C026D3" },

  { name: "Completed", color: "#F472B6" },
];

const tagsList = [
  { name: "Mentenanta", color: "#7E22CE" },

  { name: "E-mail MKT", color: "#7E22CE" },

  { name: "App", color: "#7E22CE" },
  { name: "Website", color: "#7E22CE" },
  { name: "Articole ", color: "#7E22CE" },
];
const activityList = [
  { name: "Task", color: "#FFA500" },

  { name: "Meeting", color: "#FF6347" },

  { name: "Other", color: "#4682B4" },
];

const TicketForm = ({
  initialStatus = "",
  open,
  onClose,
  projectWithTasks,
}) => {
  const user = useSelector((state) => state.user.user);

  const { data: accountsData, refetch: refetchAccounts } =
    useGetAccountsQuery();

  const { data: projectsData, refetch: refetchProjects } =
    useGetProjectsQuery();

  const { data: usersData, refetch: refetchUsers } = useGetAllUsersQuery();

  useEffect(() => {
    if (accountsData && projectsData && usersData) {
      // Only refetch if the initial query has been successfully loaded
      const intervalId = setInterval(() => {
        refetchAccounts();
        refetchProjects();
        refetchUsers();
      }, 5000);

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [
    accountsData,
    projectsData,
    usersData,
    refetchAccounts,
    refetchProjects,
    refetchUsers,
  ]);

  const [postTask, { isLoading, error }] = usePostTaskMutation();

  const [postNotification] = useCreateNotificationMutation();

  const [value, setValue] = useState(dayjs());

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [selectedClient, setSelectedClient] = useState("");

  const [selectedProject, setSelectedProject] = useState("");

  const [departments, setDepartments] = useState([]);

  const [assignedUsers, setAssignedUsers] = useState([]);

  const [selectedPriority, setSelectedPriority] = useState([]);

  const [createTags, setCreateTags] = useState([]);

  const [selectedAccount, setSelectedAccount] = useState("");

  const [selectedStatus, setSelectedStatus] = useState(
    initialStatus ? [initialStatus] : []
  );

  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedActivity, setSelectedActivity] = useState([]);

  const [description, setDescription] = useState("");

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (initialStatus) {
      setSelectedStatus([initialStatus]);
    }
    if (projectWithTasks) {
      const selectedProjectId =
        typeof projectWithTasks === "object"
          ? projectWithTasks._id || projectWithTasks.name // Adjust based on your data structure
          : projectWithTasks;
      setSelectedProject([selectedProjectId]); // Set the correct ID or name
    }
  }, [initialStatus, projectWithTasks]);

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);

  const resetForm = () => {
    setTitle("");

    setSelectedAccount("");

    setSelectedProject("");

    setDepartments([]);

    setAssignedUsers([]);

    setSelectedPriority([]);

    setSelectedStatus(initialStatus ? [initialStatus] : []);

    setSelectedTags([]);

    setSelectedActivity([]);

    setDescription("");

    setValue(dayjs());
  };

  const [updateProjectStatus] = useUpdateProjectStatusMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newTicket = {
      nume: title,
      client: selectedAccount,
      proiect: selectedProject,
      descriere: description,
      department: departments,
      tags: selectedTags,
      status: selectedStatus[0],
      activity: selectedActivity[0],
      priority: selectedPriority[0],
      persoana: assignedUsers,
      dataEnd: value.toISOString(),
      comentarii: [],
    };

    try {
      await postTask(newTicket).unwrap();

      // Update project status if the task is set to "In Progress"
      if (selectedStatus[0] === "In Progress" && selectedProject) {
        await updateProjectStatus({
          id: selectedProject,
          status: "In Progress",
        }).unwrap();
      }

      await Promise.all(
        assignedUsers.map(async (userId) => {
          const notification = {
            message: "assigned you a new task " + `${title}`,
            toPage: "/taskslist/My-Tasks",
            senderId: user._id,
            receiverId: userId,
          };

          toast.success("Task created successfully");

          return postNotification(notification).unwrap();
        })
      );

      resetForm();
      onClose();
    } catch (error) {
      console.error("Failed to create ticket: ", error);
    }
  };

  const handleClose = () => {
    resetForm();

    onClose();
  };
  const sortedAccounts = [...(accountsData?.accounts || [])]
    .filter((account) => account.isActive !== false) // Filter out inactive accounts
    .sort((a, b) => a.nameComp.localeCompare(b.nameComp)); // Sort by nameComp

  const sortedProjects = projectsData?.projects
    ? [...projectsData.projects]
        .filter((project) => project.isActive !== false) // Filter out inactive projects
        .sort((a, b) => a.nume.localeCompare(b.nume)) // Sort by 'nume'
    : [];

  const sortByFirstName = (users) => {
    // Create a shallow copy and sort the users by first name
    return [...users].sort((a, b) => {
      const [firstNameA] = a.name.split(" ");
      const [firstNameB] = b.name.split(" ");
      return firstNameA.localeCompare(firstNameB);
    });
  };
  const theme = useTheme();

  const [theme2, setTheme2] = useState(localStorage.getItem("theme"));
  // Create a shallow copy and sort the usersData by the first name
  const sortedUsers = sortByFirstName(usersData?.users || []);

  // Calendar open and close logic
  const openCalendar = () => setIsCalendarOpen(true);
  const closeCalendar = () => setIsCalendarOpen(false);

  // Handle modal close
  const handleFormClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="bg-[#CAC9F1] dark:bg-[#2B2A49] text-[#4A488E] dark:text-[#EDEDFB] overflow-y-auto scrolling-touch "
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="flex justify-between mb-[34px]">
            <p className="text-[#4A488E] dark:text-[#EDEDFB]">New task</p>

            <img
              className="cursor-pointer icon-white"
              src="/images/blue-close.svg"
              width={30}
              height={30}
              alt=""
              onClick={handleClose}
            />
          </div>
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <form onSubmit={handleSubmit}>
            <div className="mb-[34px]">
              <label
                htmlFor="title"
                className="text-[#4A488E] dark:text-[#EDEDFB]   text-[18px] flex gap-[5px] mb-[10px]"
              >
                <StarSVG />
                Task title
              </label>

              <input
                type="text"
                id="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full h-[40px] border-none mt-[10px] rounded-[6px] bg-[#E7E7F9] dark:bg-[#545492] text-[#4A488E] dark:text-[#EDEDFB]"
                required
              />
            </div>

            <div className="mb-[34px]">
              <label
                htmlFor="description"
                className="text-[#4A488E]   text-[18px] flex gap-[5px] mb-[10px] dark:text-[#EDEDFB]"
              >
                <DescriptionSVG />
                Task description
              </label>

              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                className="h-[250px] overflow-auto mb-[10px] bg-white dark:bg-[#545492] text-black"
              />
            </div>

            <div className="w-full flex gap-[45px]">
              <div className="w-6/12">
                <div className="mb-[5px] flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="client"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ClientSVG />
                      Client
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Client"
                      items={sortedAccounts}
                      selected={selectedAccount}
                      setSelected={(val) => setSelectedAccount(val)}
                      getName={(account) => account.nameComp}
                      isMultiple={false}
                      customMenuItemClasses="dark:bg-[#2B2A49]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="project"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ClientSVG />
                      Project
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Project"
                      items={sortedProjects || []}
                      selected={selectedProject}
                      setSelected={(val) => setSelectedProject(val)}
                      getName={(project) => project.nume}
                      isMultiple={false}
                      customMenuItemClasses="dark:bg-[#2B2A49]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="department"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <DepartmentSVG />
                      Department
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Department"
                      items={departmentsList}
                      selected={departments}
                      setSelected={setDepartments}
                      getName={(dept) => dept.name}
                      getColor={(dept) => dept.color}
                      customMenuItemClasses="dark:bg-[#2B2A49]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="assignedUsers"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <AssignedUsersSVG />
                      Assigned to
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Assigned to"
                      items={sortedUsers}
                      selected={assignedUsers}
                      setSelected={setAssignedUsers}
                      getName={(user) => user.name}
                      customMenuItemClasses="dark:bg-[#2B2A49]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="priority"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <PrioritySVG />
                      Priority
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Priority"
                      items={priorityList}
                      selected={selectedPriority}
                      setSelected={setSelectedPriority}
                      getName={(priority) => priority.name}
                      getColor={(priority) => priority.color}
                      customMenuItemClasses="rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 mb-[5px]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>
              </div>

              <div className="w-6/12">
                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="status"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <StatusSVG />
                      Status
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Status"
                      items={statusList}
                      selected={selectedStatus}
                      setSelected={setSelectedStatus}
                      getName={(status) => status.name}
                      getColor={(status) => status.color}
                      isMultiple={false}
                      customMenuItemClasses="rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 mb-[5px]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="tags"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <TagsSVG />
                      Tags
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Tags"
                      items={tagsList}
                      selected={selectedTags}
                      setSelected={setSelectedTags}
                      getName={(tag) => tag.name}
                      getColor={(tag) => tag.color}
                      customMenuItemClasses="rounded-[20px]  flex justify-center items-center text-white w-fit mx-auto gap-10 mb-[5px]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                      createTags={createTags} // Pass new tags to SelectInput
                      setCreateTags={setCreateTags} // Pass the function to update new tags
                    />
                  </div>
                </div>

                <div className=" flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="activity"
                      className="text-[#4A488E]   text-[18px] flex gap-[14px] items-center dark:text-[#EDEDFB]"
                    >
                      <ActivitySVG />
                      Activity
                    </label>
                  </div>

                  <div className="w-full">
                    <SelectInput
                      label="Activity"
                      items={activityList}
                      selected={selectedActivity}
                      setSelected={setSelectedActivity}
                      getName={(activity) => activity.name}
                      getColor={(activity) => activity.color}
                      customMenuItemClasses="rounded-[20px] w-[135px] flex justify-center items-center text-white mx-auto gap-10 mb-[5px]"
                      className="text-[#4A488E] dark:text-[#EDEDFB]"
                    />
                  </div>
                </div>

                <div className="flex w-full items-center">
                  <div className="w-full">
                    <label
                      htmlFor="dueDate"
                      className="text-[#4A488E] dark:text-[#EDEDFB] text-[18px] flex gap-[14px] items-center"
                    >
                      <DueDateSVG />
                      Due date
                    </label>
                  </div>

                  <div className="w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="ml-5 relative">
                        <p
                          onClick={openCalendar}
                          className="text-[15px] bg-color text-[#4A488E] dark:text-[#EDEDFB]"
                          style={{ cursor: "pointer" }}
                        >
                          {value.format("dddd, Do MMMM")}
                        </p>

                        {isCalendarOpen && (
                          <div className="absolute z-10 top-[-342px] left-[-148px] bg-[#4A488E] dark:bg-[#383757]">
                            <DateCalendar
                              value={value}
                              onChange={(newValue) => {
                                setValue(newValue);
                                closeCalendar(); // Auto-close the date picker after selection
                              }}
                              sx={{
                                "& .MuiPickersDay-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: theme2
                                    ? "#8D8BDB"
                                    : "#0074FD",
                                },

                                "& .MuiTypography-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersCalendarHeader-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiIconButton-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersArrowSwitcher-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },

                                "& .MuiPickersYear-root": {
                                  color: theme2 ? "#EDEDFB" : "#000",
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="flex justify-end gap-[31px] mt-[55px]">
                  <button
                    type="submit"
                    className="py-[12px] px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
                  >
                    Create ticket
                  </button>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="py-[12px] px-[22px] text-[#4A488E] dark:text-[#EDEDFB] border border-[#4A488E] dark:border-[#EDEDFB] bg-[#E7E7F9] dark:bg-[#545492] rounded-[6px]"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Typography>
      </Box>
    </Modal>
  );
};

export default TicketForm;
