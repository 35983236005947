import React, { useState, useEffect } from "react";
import { Breadcrumbs, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationComponent from "../../NotificationComponent";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SearchBar from "../../utils/SearchBar";
// import SquareIcon from "@mui/icons-material/Square";
import DataTable from "../DataTable";
import ProductModal from "./ProdDashboardForm";
import RestockModal from "../Restock/RestockDashboardForm";
import { useGetCategoriesQuery } from "../../../redux/api/inventory/category";
import {
  useGetProductsQuery,
  usePostProductMutation,
  useUpdateProductMutation,
  useDeleteProductsMutation,
} from "../../../redux/api/inventory/products";
import {
  usePostRestockMutation, // Add mutation to handle restocking
} from "../../../redux/api/inventory/restock";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { InventoryArrowLeftIcon, InventoryArrowRightIcon } from "../svgs";
import BackendSearchBar from "../UI/backendSearchBar";
import FilterComponent from "./FilterDropdown";

const columnMapping = {
  name: "Product Name",
  description: "Description",
  status: "Status",
  SKUNumber: "SKU",
  stock: "Stock",
  expiryDate: "Expiry Date",
  uploadedDate: "Upload Date",
  unitPrice: "Unit Price",
  totalPrice: "Total Price",
};

// Loading spinner component using Tailwind CSS
const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center mt-4">
      <div className="w-8 h-8 border-4 border-[#4A488E] border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
};

export const ProductDashboard = ({ isLowStockPage = false }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [dialogProduct, setDialogProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(5); // Number of products per page
  const [isUpdating, setIsUpdating] = useState(false); // Track updating status
  const [isDeleting, setIsDeleting] = useState(false); // Track deleting status
  const [visibleColumns, setVisibleColumns] = useState({
    productName: true,
    status: true,
    description: true,
    skuNumber: true,
    stock: true,
    expiryDate: true,
    uploadDate: true,
    unitPrice: true,
    totalPrice: true,
  });
  // State to handle restocking
  const [openRestockDialog, setOpenRestockDialog] = useState(false); // Control restock modal
  const [currentProduct, setCurrentProduct] = useState(null); // Store product for restock
  const [filters, setFilters] = useState(null);
  // API queries and mutations
  const {
    data: productsData,
    error: productError,
    isLoading: isProductLoading,
    refetch,
  } = useGetProductsQuery({
    page: currentPage,
    limit,
    search: searchQuery,
    isLowStockPage,
    filters, // Ensure filters are included here
  });
  const [createProduct] = usePostProductMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [deleteProducts] = useDeleteProductsMutation();
  const { data: categoriesData } = useGetCategoriesQuery();

  // Mutation for restocking
  const [createRestock] = usePostRestockMutation();

  // Refetch data whenever filters change
  useEffect(() => {
    refetch();
  }, [filters, currentPage, searchQuery, isLowStockPage]); // Add dependencies as needed

  useEffect(() => {
    if (productsData) {
      setTotalPages(productsData.totalPages); // Set total pages from API response
    }
  }, [productsData]);

  const products = productsData?.products || [];

  const handleStatusChange = async (id, newStatus) => {
    const productToUpdate = products.find((product) => product._id === id);
    if (productToUpdate) {
      const updatedProduct = {
        ...productToUpdate,
        status: newStatus,
        category: productToUpdate.category.name,
      };
      setIsUpdating(true); // Start update spinner
      try {
        await updateProduct({
          productId: id,
          productData: updatedProduct,
        }).unwrap();
        refetch();
      } catch (error) {
        console.error("Error updating product:", error);
      } finally {
        setIsUpdating(false); // Stop update spinner
      }
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allProductIds = products.map((product) => product._id);
      setSelectedProducts(allProductIds);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleSelectItem = (event, productId) => {
    if (event.target.checked) {
      setSelectedProducts((prevSelected) => [...prevSelected, productId]);
    } else {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((id) => id !== productId)
      );
    }
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("landscape");
    const selectedProductData = products.filter((product) =>
      selectedProducts.includes(product._id)
    );
    const tableColumn = Object.keys(products[0]).map(
      (key) => columnMapping[key] || key
    );
    const tableRows = [];

    selectedProductData.forEach((product) => {
      const productData = Object.values(product);
      tableRows.push(productData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      theme: "grid",
      headStyles: { fillColor: [22, 160, 133] },
      margin: { top: 20 },
      styles: { overflow: "linebreak" },
    });
    doc.save("products.pdf");
  };

  const handleEditProducts = () => {
    if (selectedProducts.length === 1) {
      const productToEdit = products.find(
        (product) => product._id === selectedProducts[0]
      );
      setDialogProduct({
        ...productToEdit,
        category:
          categoriesData?.categories.find(
            (cat) => cat._id === productToEdit.category._id
          ) || {},
      });
      setOpenDialogNew(true);
    }
  };

  const handleDeleteProducts = async () => {
    setIsDeleting(true); // Start delete spinner
    try {
      await deleteProducts({ ids: selectedProducts }).unwrap();
      setSelectedProducts([]);
      refetch();
    } catch (error) {
      console.error("Error deleting products:", error);
    } finally {
      setIsDeleting(false); // Stop delete spinner
    }
  };

  const handleSaveProduct = async (newProduct) => {
    try {
      const updatedProduct = {
        ...newProduct,
        category: {
          _id: newProduct.category._id,
          name: newProduct.category.name,
        },
      };

      if (newProduct._id) {
        await updateProduct({
          productId: newProduct._id,
          productData: updatedProduct,
        }).unwrap();
      } else {
        await createProduct(updatedProduct).unwrap();
      }

      setOpenDialogNew(false);
      setDialogProduct(null);
      refetch();
    } catch (error) {
      console.error("Failed to save product:", error);
    }
  };

  // Handle restock logic
  const handleRestockProduct = () => {
    if (selectedProducts.length === 1) {
      const productToRestock = products.find(
        (product) => product._id === selectedProducts[0]
      );
      setCurrentProduct(productToRestock); // Set the selected product for restocking
      setOpenRestockDialog(true); // Open restock modal
    }
  };

  const handleSaveRestock = async (newRestockData) => {
    try {
      const restockPayload = {
        productName: currentProduct.name,
        SKUNumber: currentProduct.SKUNumber,
        restockQuantity: newRestockData.restockQuantity,
        supplier: newRestockData.supplier,
        orderDate: newRestockData.orderDate,
        expectedDelivery: newRestockData.expectedDelivery,
        comment: newRestockData.comment,
        status: "Pending", // Set default status
        product: currentProduct._id, // Associate with the product
      };

      await createRestock(restockPayload).unwrap(); // Call the API to create the restock
      setOpenRestockDialog(false); // Close restock modal after saving
      refetch(); // Refetch products to update stock
    } catch (error) {
      console.error("Failed to create restock:", error);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1); // This automatically triggers a new fetch due to useGetProductsQuery
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1); // This also triggers a new fetch
    }
  };

  // Define default filters based on the page type
  const defaultProductFilters = {
    productName: true,
    status: true,
    description: true,
    skuNumber: true,
    stock: true,
    expiryDate: true,
    uploadDate: true,
    unitPrice: true,
    totalPrice: true,
  };

  const defaultRestockFilters = {
    productName: true,
    skuNumber: true,
    priority: true,
    currentStock: true,
    restockQuantity: true,
    supplier: true,
    orderDate: true,
    expectedDelivery: true,
    comment: true,
    status: true,
  };

  const [selectedFilters, setSelectedFilters] = useState(defaultProductFilters);

  return (
    <div className="pr-[54px] pt-[25px] h-[100vh] overflow-y-auto scrolling-touch  pb-[20px] dark:bg-[#2B2A49]">
      <div className="flex justify-between items-center">
        <Breadcrumbs
          className="text-[#4A488E] dark:text-[#EDEDFB]"
          aria-label="breadcrumb"
        >
          <Link className="text-[#4A488E] dark:text-[#EDEDFB]" to="/dashboard">
            Fuse Digital
          </Link>
          <Link
            className="text-[#4A488E] dark:text-[#EDEDFB]"
            to={`/inventory/product-dashboard`}
          >
            Inventory
          </Link>
          <Link
            className="text-[#4A488E] dark:text-[#EDEDFB]"
            to={`/inventory/product-dashboard`}
          >
            <b> Product Dashboard </b>
          </Link>
        </Breadcrumbs>
        <NotificationComponent />
      </div>

      <div className="text-[#4A488E] dark:text-[#EDEDFB] text-3xl font-normal mt-[40px] mb-[53px]">
        Products
      </div>

      <div className="flex items-center justify-between mb-4">
        <div className="relative">
          <BackendSearchBar onSearchQuery={(query) => setSearchQuery(query)} />
        </div>
        {!isLowStockPage && (
          <>
            <div className="h-[34px] flex items-center gap-[15px]">
              <div
                className="w-fit h-[38px] px-[15px] py-2.5 bg-violet-100 rounded-md border border-indigo-500 justify-center items-center gap-2.5 flex cursor-pointer"
                onClick={() => setOpenDialogNew(true)}
              >
                <div className="text-[#4A488E] text-[15px] font-medium">
                  + New product
                </div>
              </div>
              <FilterComponent
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filters={filters}
                setFilters={setFilters}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
              />
            </div>
          </>
        )}
      </div>

      {isProductLoading || isUpdating || isDeleting ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          selectedFilters={selectedFilters}
          visibleColumns={visibleColumns}
          data={products.map((product) => ({
            ...product,
            category: product.category?.name || "Unknown Category",
          }))}
          filters={filters}
          isLoading={isProductLoading}
          error={productError}
          selectedItems={selectedProducts} // Make sure the prop is 'selectedItems'
          handleSelectAll={handleSelectAll}
          handleSelectItem={handleSelectItem} // Rename this to handleSelectItem
          onStatusChange={handleStatusChange}
          handleEditItems={handleEditProducts} // Update this too if needed
          handleDeleteItems={handleDeleteProducts} // Update this too if needed
          handleRestockItems={handleRestockProduct} // Trigger restock modal
        />
      )}

      {/* Pagination Controls */}
      <div className="flex justify-end items-center mt-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="px-4 dark:text-white"
        >
          <InventoryArrowLeftIcon />
        </button>
        <span className="dark:text-white">
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="dark:text-white px-4"
        >
          <InventoryArrowRightIcon />
        </button>
      </div>

      <ProductModal
        isOpen={openDialogNew || !!dialogProduct}
        onClose={() => {
          setOpenDialogNew(false);
          setDialogProduct(null);
        }}
        onSave={handleSaveProduct}
        product={dialogProduct}
        categories={categoriesData?.categories || []}
      />

      {/* Add RestockModal */}
      <RestockModal
        isOpen={openRestockDialog}
        onClose={() => setOpenRestockDialog(false)}
        onSave={handleSaveRestock}
        restock={null} // New restock, so no existing data
        filteredProducts={[currentProduct]} // Pass the selected product
      />
    </div>
  );
};

export default ProductDashboard;
