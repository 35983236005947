// menuDictionary.js
import {
  DashboardIcon,
  ProjectsIcon,
  TaskListIcon,
  ClientsIcon,
  HRIcon,
  FinanceIcon,
  ERPIcon,
  AppointmentIcon,
  AdminPanelIcon,
  CRMIcon,
  ReportsIcon,
} from "./svgs";

const menuDictionary = [
  {
    label: "Dashboard",
    icon: <DashboardIcon />,
    path: "/dashboard",
    disabled: false,
  },
  {
    label: "Projects",
    icon: <ProjectsIcon />,
    path: "/projects",
    children: [
      {
        label: "All Projects",
        path: "/projects/All-Projects",
        disabled: false,
        isAdmin: true,
      },
      { label: "My Projects", path: "/projects/My-Projects", disabled: false },
      {
        label: "SMM & Graphic Design",
        path: "/projects/SMM-Graphic-Design",
        disabled: false,
        departments: ["SMM"],
      },
      {
        label: "Development",
        path: "/projects/Development",
        disabled: false,
        departments: ["DEV"],
      },
      {
        label: "SEO",
        path: "/projects/SEO",
        disabled: false,
        departments: ["SEO"],
      },
      {
        label: "PPC",
        path: "/projects/PPC",
        disabled: false,
        departments: ["PPC"],
      },
    ],
  },
  {
    label: "Task list",
    icon: <TaskListIcon />,
    path: "/taskslist",
    children: [
      {
        label: "All Tasks",
        path: "/taskslist/All-Tasks",
        disabled: false,
        isAdmin: true,
      },
      { label: "My Tasks", path: "/taskslist/My-Tasks", disabled: false },
      {
        label: "SMM & Graphic Design",
        path: "/taskslist/SMM-Graphic-Design",
        disabled: false,
        departments: ["SMM"],
      },
      {
        label: "Development",
        path: "/taskslist/Development",
        disabled: false,
        departments: ["DEV"],
      },
      {
        label: "SEO",
        path: "/taskslist/SEO",
        disabled: false,
        departments: ["SEO"],
      },
      {
        label: "PPC",
        path: "/taskslist/PPC",
        disabled: false,
        departments: ["PPC"],
      },
    ],
  },
  {
    label: "Clients",
    icon: <ClientsIcon />,
    path: "/clients",
    disabled: false,
    permission: "access_clients",
  },
  {
    label: "HR",
    icon: <HRIcon />,
    path: "/hr",
    disabled: false,
  },
  {
    label: "CRM",
    icon: <CRMIcon />,
    path: "/CRM/Leads",
    children: [
      { label: "Leads", path: "/CRM/Leads", disabled: false },
      { label: "Deals", path: "/CRM/Deals", disabled: false },
      { label: "Accounts", path: "/CRM/Accounts", disabled: false },
      { label: "Contacts", path: "/CRM/Contacts", disabled: false },
    ],
    permission: "access_crm",
  },
  {
    label: "Finance",
    icon: <FinanceIcon />,
    path: "/finance",
    children: [
      {
        label: "Dashboard",
        path: "/finance/finance-dashboard",
        disabled: false,
      },
      { label: "Revenue", path: "/finance/revenue", disabled: false },
      { label: "Expenses", path: "/finance/expenses", disabled: false },
    ],
    permission: "access_finance",
  },
  {
    label: "Inventory",
    icon: <ERPIcon />,
    path: "/inventory",
    children: [
      {
        label: "Product Dahsboard",
        path: "/inventory/product-dashboard",
        disabled: false,
      },
      {
        label: "Product categories",
        path: "/inventory/product-categories",
        disabled: false,
      },
      { label: "Low stock", path: "/inventory/low-stock", disabled: false },
      { label: "Restock", path: "/inventory/restock", disabled: false },
    ],
    disabled: false,
    permission: "access_erp",
  },
  {
    label: "Appointments",
    icon: <AppointmentIcon />,
    path: "/appointment",
    children: [
      { label: "Dashboard", path: "/appointments", disabled: false },
      { label: "Services", path: "/appointments/services", disabled: false },
    ],
    disabled: false,
    permission: "access_appointments",
  },
  {
    label: "Reports",
    icon: <ReportsIcon />,
    path: "/reports",
    children: [
      {
        label: "Dashboard reports",
        path: "/reports/dashboard-reports",
        disabled: false,
      },
      {
        label: "Operational reports",
        path: "/reports/operational-reports",
        disabled: false,
      },
    ],
    disabled: false,
    permission: "",
  },
  {
    label: "Admin Panel",
    icon: <AdminPanelIcon />,
    path: "/adminpanel",
    disabled: false,
    isAdmin: true,
  },
];

export default menuDictionary;
