import React, { useState } from "react";

const DropdownDepartments = ({ setDepartment, department }) => {
  const [isOpen, setIsOpen] = useState(false);
  const departmentsList = ["DEV", "SEO", "PPC", "SMM", "SALES"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setDepartment(option);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        className="justify-center w-full rounded-md flex items-center gap-2 px-4 py-2 font-medium text-black bg-[#E9E9FF]  dark:bg-[#3A3A68] dark:text-[#EDEDFB]"
        onClick={toggleDropdown}
      >
        {department || "Select Department"}
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-[200px] rounded-md shadow-lg bg-[#E9E9FF] z-50 dark:bg-[#3A3A68]">
          <ul className="py-1">
            {departmentsList.map((option) => (
              <li
                key={option}
                className="px-4 py-2 cursor-pointer hover:bg-[#404074] "
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownDepartments;
